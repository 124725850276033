import React from 'react';
import classes from './index.module.scss';
import { Link } from 'react-router-dom';
import moment from 'moment';

const OppTableBody = ({ list, dashboard = false, handleClick, isCheck }) => {
  const viewPathRefactor = entity => {
    let newEntity;

    switch(entity) {
      case "contract_opportunities":
        newEntity = "purchase";
        break;
      case "purchases":
        newEntity = "b2b";
        break;
      case "opportunities":
        newEntity = "opportunity";
        break;
      case "manual_opportunities":
        newEntity = "manual-opportunity";
        break;
      case "grants":
        newEntity = "grant";
        break;
    }

    return newEntity;
  }

  const entityNameRefactor = entity => {
    let newEntity;

    switch(entity) {
      case "contract_opportunities":
        newEntity = "Micro Purchase";
        break;
      case "purchases":
        newEntity = "B2B Opportunity";
        break;
      case "opportunities":
        newEntity = "Contract Opportunity";
        break;
      case "manual_opportunities":
        newEntity = "Manual Opportunity";
        break;
      case "grants":
        newEntity = "Grant";
        break;
    }

    return newEntity;
  }

  return (
    <>
      {
        list.map(item => {
          const { created, entity_created, reference, entity_name, entity, id } = item;

          return (
            <tr 
              key={id}
            >
              {dashboard && (
                <td className={classes.Vertical_Middle}>
                  <div className="Checkbox_Wrap">
                    <input
                      label="check"
                      type="checkbox"
                      name={entity_name}
                      checked={isCheck[entity].includes(reference)}
                      id={reference} 
                      onChange={(event) => handleClick(event, entity)}
                    />
                    <label htmlFor={reference}></label>
                  </div>
                </td>
              )}
              <td className={`${classes.Title_Normal} ${classes.Vertical_Middle}`}>{created !== null ? moment(created).format('YYYY-MM-DD') : "N/A"}</td>
              <td className={`${classes.Title_Normal} ${classes.Vertical_Middle}`}>{entity_created !== null ? moment(entity_created).format('YYYY-MM-DD') : "N/A"}</td>
              <td className={`${classes.Title_Normal} ${classes.Vertical_Middle}`}>{reference}</td>
              <td className={`${classes.Title_Normal} ${classes.Vertical_Middle}`}>
                <p className="mb-0">
                  {entity_name || "N/A"}
                </p>
                
                <span className={classes.Entity}>
                  {entityNameRefactor(entity)}
                </span>
              </td>
              <td className={classes.Vertical_Middle}>
                <Link
                  to={`/${viewPathRefactor(entity)}?id=${reference}`}
                  className="btnSizes transparentBtn"
                >
                  View
                </Link>
              </td>
            </tr>
          )
        })
      }
    </>
  )
}

export default OppTableBody;