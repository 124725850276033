import React from 'react';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import { Table } from 'react-bootstrap';
import classes from '../Dashboard/index.module.scss'

const TableContainer = ({ data, selectedColums, handleDefaultStatusCodes, setLoading }) => {
  return (
    <Table striped responsive className={`${classes.Table} mb-4`} style={{ background: '#ffffff' }}>
      <thead className={classes.Table_Head}>
        <TableHeader selectedColums={ selectedColums } />
      </thead>
      <tbody className={classes.Table_Body}>
        <TableBody 
          list={ data }
          selectedColums={ selectedColums }
          handleDefaultStatusCodes={ handleDefaultStatusCodes }
          setLoading={ setLoading }
        />
      </tbody>
    </Table>
  )
}

export default TableContainer;