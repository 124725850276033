import React, { useContext } from "react";
import TextInput from "../UI/TextInput";
import EmailInput from "../UI/EmailInput";
import MultiSelect from "../UI/MultiSelect";
import CreatableSelect from "../UI/CreatableSelect";
import DateRange from "../UI/DateRange";
import PasswordInput from "../UI/PasswordInput";
import TextArea from "../UI/TextArea";
import { TabContext } from "../../context/TabContext";
import Radio from "../UI/Radio";
import Select from "../UI/Select";
import { CITY_INSTALLATION_LIST, RELATIONSHIP_LIST } from "../../constans";
import Autocomplete from "react-google-autocomplete";
import GeoFencingMap from "components/GeoFencingMap";

const BUSINESS_TYPE_LIST2 = [
  { value: "b", label: "Business Entity", type: "business_type" },
  { value: "g", label: "Government Entity", type: "business_type" },
  { value: "i", label: "Investor Entity", type: "business_type" },
  { value: "e", label: "Employment Seeker", type: "business_type" },
];

const NOTIFICATION_TYPE_LIST = [
  { value: "d", label: "Daily", type: "notifications_type" },
  { value: "w", label: "Weekly", type: "notifications_type" },
];

const GOV_RELATIONS_LIST = [
  [
    { value: "Business_Organization", label: "Business Organization", type: "gov_relations" },
    { value: "Government_Entity", label: "Government Entity", type: "gov_relations" },
    { value: "Contracting_Consultant", label: "Contracting Consultant", type: "gov_relations" },
    { value: "Lobbyist_Group", label: "Lobbyist Group", type: "gov_relations" },
    { value: "Lender_Institution", label: "Lender Institution", type: "gov_relations" },
    { value: "Financial_Investor", label: "Financial Investor", type: "gov_relations" },
    { value: "Individual_Employee", label: "Individual Employee", type: "gov_relations" },
    { value: "Media_Entity", label: "Media Entity", type: "gov_relations" },
    { value: "Elected_Official", label: "Elected Official", type: "gov_relations" },
  ]
]

const EditProfileTabOption = (props) => {
  const {
    register,
    onChangeHandler,
    handleMultiChange,
    handleCreatableChange,
    inputValue,
    list,
    selectValue,
    handleTimeCallback,
    setInputValue,
    onChangeSingleValue,
    transformLocation,
    transformAddress,
    loc,
    isLoaded,
    loadError,
    handleFilterUpdateLocation,
    handleFilterZip,
    errors
  } = useContext(TabContext);

  const isEmpty =
    !inputValue[props.info_type][props.name] ||
    (Array.isArray(inputValue[props.info_type][props.name]) &&
      inputValue[props.info_type][props.name].length === 0);

  return (
    <div className="advanced-option">
      <h6>{props.label}</h6>
      {props.type === "text" && (
        <TextInput
          name={props.name}
          type={props.type}
          register={register()}
          value={inputValue[props.info_type][props.name] || ""}
          onChange={onChangeSingleValue}
          placeholder="Type something..."
          setInputValue={setInputValue}
          inputValue={inputValue}
          info_type={props.info_type}
          className={
            loc && loc.state && isEmpty && props.name === "uei" ? "filled" : ""
          }
        />
      )}
      {props.type === "textarea" && (
        <TextArea
          name={props.name}
          register={register()}
          value={inputValue[props.info_type][props.name]}
          onChange={onChangeSingleValue}
          placeholder="Up To 600 characters"
          info_type={props.info_type}
          className={
            loc.state && isEmpty && props.name === "keywords" ? "filled" : ""
          }
        />
      )}
      {props.type === "email" && (
        <EmailInput
          name={props.name}
          type={props.type}
          register={register()}
          value={inputValue[props.info_type][props.name]}
          onChange={onChangeSingleValue}
          placeholder="Type something..."
          info_type={props.info_type}
        />
      )}
      {props.type === "password" && (
        <div className='password-input-wrapper'>
          <PasswordInput
            name={props.name}
            tabs={true}
            type={props.type}
            register={register({
              pattern: {
                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9])[A-Za-z\d@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*.{8,}$/,
                message: "Password must contain at least 8 characters, 1 uppercase, 1 lowercase, 1 number, and 1 special character"
              }
            })}
            value={inputValue[props.info_type]}
            onChange={onChangeSingleValue}
            placeholder="Type something..."
            info_type={props.info_type}
          />
          { errors.password && <p>{errors.password.message}</p> }
        </div>
      )}
      {props.type === "tel" && (
        <input
          name={props.name}
          id={props.name}
          ref={register()}
          placeholder="Type something..."
          onChange={
            onChangeSingleValue &&
            ((event) => onChangeSingleValue(event, props.name, props.info_type))
          }
          value={(inputValue && inputValue[props.info_type][props.name]) || ""}
          type={props.type}
        />
      )}
      {props.type === "date" && (
        <DateRange
          handleTimeCallback={handleTimeCallback}
          name={props.name}
          id={props.name}
          wrapStyle="advanced-picker-wrap"
          cancelCallback={() => {
            setInputValue({
              ...inputValue,
              [props.name]: {},
            });
          }}
          isPicked={() =>
            Object.keys(inputValue[props.info_type][props.name]).length === 0
          }
        />
      )}
      {props.type === "select" && props.name !== "geo_fencing_zips" && (
        <MultiSelect
          onChangeHandler={onChangeHandler}
          handleMultiChange={handleMultiChange}
          name={props.name}
          list={props.name === "business_type" ? BUSINESS_TYPE_LIST2 : list}
          selectValue={selectValue.filter((item) => item.type === props.name)}
          // styles="react-select-container advanced"
          styles={
            loc.state &&
            isEmpty &&
            (props.name === "naics" || props.name === "psc_code")
              ? "react-select-container advanced filled"
              : "react-select-container advanced"
          }
          prefixStyles="react-select"
          indicator={false}
          info_type={props.info_type}
        />
      )}
      {/* {props.type === "select" && props.name === "geo_fencing_zips" && (
        <CreatableSelect
          onChangeHandler={onChangeHandler}
          handleMultiChange={handleCreatableChange}
          name={props.name}
          list={list}
          selectValue={selectValue.filter((item) => item.type === props.name)}
          // styles="react-select-container advanced"
          styles="react-select-container advanced"
          prefixStyles="react-select"
          indicator={false}
          info_type={props.info_type}
        />
      )} */}
      {props.type === "single_select" && props.name === "installation" && (
        <Select
          handleMultiChange={onChangeHandler}
          name={props.name}
          inputValue={inputValue.officer.name}
          list={CITY_INSTALLATION_LIST}
          styles="react-select-input-container advanced"
          prefixStyles="react-select-input"
          indicator={false}
          info_type={props.info_type}
          isClear={false}
          defaultValue={{
            value: "Fort Bragg",
            label: "Fort Bragg",
            type: "installation",
          }}
        />
      )}
      {props.type === "single_select" && props.name === "gov_relations" && (
        <Select
          handleMultiChange={handleMultiChange}
          onChangeHandler={onChangeHandler}
          name={props.name}
          list={RELATIONSHIP_LIST}
          styles="react-select-input-container advanced"
          prefixStyles="react-select-input"
          indicator={false}
          isSearchable={false}
          info_type={props.info_type}
          isClear={false}
          placeholder="I am a..."
          selectValue={selectValue.filter(item => item.type === 'gov_relations')}
          defaultValue={selectValue.filter(item => item.type === 'gov_relations')}
        />
      )}
      {props.type === "single_select" &&
        props.name === "notifications_type" && (
          <Select
            handleMultiChange={handleMultiChange}
            onChangeHandler={onChangeHandler}
            name={props.name}
            list={NOTIFICATION_TYPE_LIST}
            styles="react-select-input-container advanced"
            prefixStyles="react-select-input"
            indicator={false}
            isSearchable={false}
            info_type={props.info_type}
            isClear={false}
            defaultValue={NOTIFICATION_TYPE_LIST.filter(
              (item) => item.value === inputValue[props.info_type][props.name]
            )}
          />
        )}
      {props.type === "radio" && (
        <Radio
          register={register()}
          props={props}
          onChangeSingleValue={onChangeSingleValue}
          value={inputValue[props.info_type][props.name]}
          info_type={props.info_type}
        />
      )}
      {!loadError && isLoaded && props.type === "address" && (
        <Autocomplete
          placeholder="Add your address here..."
          options={{
            types: ["geocode", "establishment"],
            componentRestrictions: { country: "usa" },
          }}
          language={"en"}
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_DROPDOWN_API_KEY}
          onPlaceSelected={(place, inputRef, autocomplete) => {
            transformAddress(place);
          }}
          defaultValue={inputValue[props.info_type][props.name]}
        />
      )}
      {!loadError && isLoaded && props.type === "location" && (
        <Autocomplete
          placeholder="Add your location here..."
          options={{
            types: ["geocode", "establishment"],
            componentRestrictions: { country: "usa" },
          }}
          language={"en"}
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_DROPDOWN_API_KEY}
          onPlaceSelected={(place, inputRef, autocomplete) => {
            transformLocation(place);
          }}
          defaultValue={inputValue[props.info_type][props.name]}
        />
      )}
      {!loadError && isLoaded && props.type === "map" && (
        <div style={{ display: "flex", flexDirection: "column", width: "60%" }}>
          <GeoFencingMap
            geoFencingLocations={inputValue[props.info_type][props.name]}
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_DROPDOWN_API_KEY}
            handleFilterUpdateLocation={handleFilterUpdateLocation}
            handleFilterZip={handleFilterZip}
          />
        </div>
      )}
    </div>
  );
};

export default EditProfileTabOption;
