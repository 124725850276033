import React from 'react';
import TextInput from '../UI/TextInput';
import EmailInput from '../UI/EmailInput';
import MultySelect from '../UI/MultiSelect';
import { useForm } from 'react-hook-form';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow-right.svg';
import classes from './index.module.scss'

const FrameOne = ({ onSubmit, next, setStep }) => {
  const { register, handleSubmit, errors } = useForm();

  return (
    <div className={classes.Step_Wrapper}>
      <div className={classes.Step_Header}>
        <h3 className='mb-0'>Welcome to Govtide!</h3>
        <div className={classes.Arrow_Wrapper}  onClick={next}>
          <ArrowIcon className={classes.Arrow_Icon} />
        </div>
      </div>
      <div className={classes.Step_Content}>
        <p>Starting now, we connect you to government and business entities in real time through our Economic Media Platform so you can share, discuss, solicit, post, hire, bid, negotiate, 
          collaborate, raise capital, invest, and team up to win Federal and Local Contracting Opportunities and Grants, based upon the profile you create using our sign-up wizard.</p>
      </div>
      <div className={classes.Step_Footer}>
        <span className={classes.Prev}></span>
        <div className={classes.Dots_Container}>
          <div className={classes.Active_Dot}></div>
          <div className={classes.Inactive_Dot}></div>
          <div className={classes.Inactive_Dot}></div>
          <div className={classes.Inactive_Dot}></div>
        </div>
        <span className={classes.Next} onClick={next}>Next</span>
      </div>
    </div>
    // <form onSubmit={handleSubmit(onSubmit)}>
    //   {BUSINESS_INPUT_LIST.map(item => {
    //     const { name } = item;
    //     if (item.type === 'text') {
    //       return (
    //         <fieldset key={item.label}>
    //           <label htmlFor={item.name}>{item.label} {item.required && <span>*</span>}</label>
    //           <div className="tooltip-container">
    //             <TextInput 
    //               name={item.name}
    //               type={item.type}
    //               register={register(item.required && { required: true })}
    //             />
    //             <OverlayTrigger
    //               placement="right"
    //               delay={{ show: 250, hide: 400 }}
    //               overlay={
    //                 <Tooltip id="button-tooltip">
    //                   {item.tooltip}
    //                 </Tooltip>
    //               }
    //             >
    //               <TooltipIcon />
    //             </OverlayTrigger>
    //           </div>
    //           {errors[name] && (
    //             <p className="warnMessage">This field is required</p>
    //           )}
    //         </fieldset>
    //       )
    //     } else if (item.type === 'email') {
    //       return (
    //         <fieldset key={item.label}>
    //           <label htmlFor={item.name}>{item.label} {item.required && <span>*</span>}</label>
    //           <div className="tooltip-container">
    //             <EmailInput 
    //               name={item.name}
    //               type={item.type}
    //               register={register(item.required && { required: true })}
    //             />
    //             <OverlayTrigger
    //               placement="right"
    //               delay={{ show: 250, hide: 400 }}
    //               overlay={
    //                 <Tooltip id="button-tooltip">
    //                   {item.tooltip}
    //                 </Tooltip>
    //               }
    //             >
    //               <TooltipIcon />
    //             </OverlayTrigger>
    //           </div>
    //           {errors[name] && (
    //             <p className="warnMessage">This field is required</p>
    //           )}
    //         </fieldset>
    //       )
    //     } else if (item.type === "tel") {
    //       return (
    //         <fieldset key={item.label}>
    //           <label htmlFor={item.name}>{item.label} {item.required && <span>*</span>}</label>
    //           <div className="tooltip-container">
    //             <input
    //               name={item.name}
    //               type={item.type}
    //               ref={register(item.required && { required: true })}
    //               id={item.name}
    //             />
    //             <OverlayTrigger
    //               placement="right"
    //               delay={{ show: 250, hide: 400 }}
    //               overlay={
    //                 <Tooltip id="button-tooltip">
    //                   {item.tooltip}
    //                 </Tooltip>
    //               }
    //             >
    //               <TooltipIcon />
    //             </OverlayTrigger>
    //           </div>
    //           {errors[name] && (
    //             <p className="warnMessage">This field is required</p>
    //           )}
    //         </fieldset>
    //       )
    //     } else if (item.type === "select") {
    //       return (
    //         <fieldset>
    //           <label htmlFor="payment_method">Business Type <span>*</span></label>
    //           <div className="tooltip-container-select">
    //             <MultySelect 
    //               onChangeHandler={onChangeHandler}
    //               handleMultiChange={handleMultiChange}
    //               name="payment_method"
    //               list={[
    //                 { value: "b", label: "Business Entity", type: "business_type" },
    //                 { value: "g", label: "Government Entity", type: "business_type" },
    //                 { value: "i", label: "Investor Entity", type: "business_type" },
    //                 { value: "e", label: "Employment Seeker", type: "business_type" }
    //               ]}
    //               inputValue={inputValue.business_type}
    //               selectValue={selectValue.filter(item => item.type === 'business_type')}
    //               styles="react-select-container"
    //               prefixStyles="react-select"
    //               indicator={false}
    //               check={true}
    //             />
    //             <OverlayTrigger
    //                 placement="right"
    //                 delay={{ show: 250, hide: 400 }}
    //                 overlay={
    //                   <Tooltip id="button-tooltip">
    //                     Select business type.
    //                   </Tooltip>
    //                 }
    //               >
    //                 <TooltipIcon />
    //               </OverlayTrigger>
    //           </div>
    //         </fieldset>
    //       )
    //     }
    //   })}
    //   <button 
    //     type="submit"
    //     className="filledBtn btnSizes"
    //   >
    //     Save & Continue
    //   </button>
    // </form>
  )
}

export default FrameOne;

// const BUSINESS_INPUT_LIST = [
//   {
//     label: "Business name",
//     name: "name",
//     required: true,
//     type: "text",
//     tooltip: "Type the name of your business"
//   },
//   {
//     label: "Business type",
//     name: "business_type",
//     required: true,
//     type: "select",
//     tooltip: "Select the type of your business"
//   },
//   {
//     label: "Web address",
//     name: "url",
//     required: false,
//     type: "text",
//     tooltip: "If you have a website, type your web site address. (for example www.ourcompany.com)"
//   },
//   {
//     label: "Contact",
//     name: "contact",
//     required: true,
//     type: "text",
//     tooltip: "Type the name of the contact person at your business"
//   },
//   {
//     label: "Address",
//     name: "address1",
//     required: true,
//     type: "text",
//     tooltip: "123 Maple Street"
//   },
//   {
//     label: "Address 2",
//     name: "address2",
//     required: false,
//     type: "text",
//     tooltip: "Apartment Number 123 / Suite 123"
//   },
//   {
//     label: "City",
//     name: "city",
//     required: true,
//     type: "text",
//     tooltip: "The City in which you live or work."
//   },
//   {
//     label: "State",
//     name: "state",
//     required: true,
//     type: "text",
//     tooltip: "MatchForce is intended for businesses located in North Carolina.  If you are not located in NC, please register as an Associate Business."
//   },
//   {
//     label: "County",
//     name: "county",
//     required: false,
//     type: "text",
//     tooltip: "NC County in which you live or work"
//   },
//   {
//     label: "ZIP Code",
//     name: "zip",
//     required: true,
//     type: "text",
//     tooltip: "Zip code in which you live or work"
//   },
//   {
//     label: "Phone",
//     name: "phone",
//     required: true,
//     type: "tel",
//     tooltip: "Please provide your phone number"
//   },
//   {
//     label: "Fax",
//     name: "fax",
//     required: false,
//     type: "tel",
//     tooltip: "In the event that you have a fax number, please provide it. This field is not required."
//   },
//   {
//     label: "Alternate Email address",
//     name: "email",
//     required: false,
//     type: "email",
//     tooltip: "Please provide a valid email address, such as joe@gmail.com. This will not be used at all times, however can be used in the event you forget your password."
//   }
// ]