import React from 'react';

const TextInput = ({ 
  name, 
  type, 
  onChange,
  placeholder,
  value,
  register,
  check = true,
  info_type,
  required = false,
  className = ""
}) => {
  return (
    <>
      <input 
        name={name}
        type={type}
        id={name}
        placeholder={placeholder}
        onChange={onChange && ((event) => onChange(event, name, info_type))}
        value={value && value}
        ref={register}
        disabled={!check ? "disabled" : ""}
        required={required}
        className={className}
      />
    </>
  )
}

export default TextInput;