import React from 'react';
import classes from './index.module.scss';

const YouTubeEmbed = ({ embedId }) => (
  <div className={classes.Youtube_Container}>
    <iframe
      src={`https://www.youtube.com/embed/${embedId}?modestbranding=1&showinfo=0&rel=0`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
      className={classes.Youtube_Video}
    />
  </div>
);

export default YouTubeEmbed;
