import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { UserContext } from '../../context/User';
import { useLocation, useHistory } from "react-router-dom";
import { manualOpportunityGet } from '../../api';
import Loading from '../Loading';
import { somethingWentWrongNotice } from '../UI/Notifications';
import SinglePageItemText from '../SinglePageItemTypes/SinglePageItemText';
import SinglePageItemArray from '../SinglePageItemTypes/SinglePageItemArray';
import SinglePageItemBool from '../SinglePageItemTypes/SinglePageItemBool';

const ManualOpportunitySingle = () => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const id = query.get("id");
  const [businessData, setBusinessData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setUser, setIsLoggedIn } = useContext(UserContext);
  const history = useHistory();

  const logout = () => {
    setIsLoggedIn(false);
    setUser({});
    history.push('/');
  }

  const handleDefaultStatusCodes = (status_code) => {
    if (status_code === 440 || status_code === 401) {
      logout()
    } else {
      somethingWentWrongNotice("Search");
    }

    setLoading(false);
  }

  useEffect(() => {
    if (id) {
      setLoading(true);
      manualOpportunityGet({id})
        .then(response => {
          setBusinessData(response.data);
          setLoading(false);
        })
        .catch(error => {
          handleDefaultStatusCodes(error.response.status);
          setLoading(false);
        })
    }
  }, [])

  const convertDate = (str) => {
    const date = str.slice(0, 10);
    return date
  }

  return (
    <section className="section-spaces">
       <Container>
        <Row>
          <Col>
            <div className="single-page-wrap">
              <h3>{businessData.name}</h3>
              <SinglePageItemText 
                title="Organization"
                value={businessData.agency_name}
              />
              <SinglePageItemText 
                title="Address"
                value={businessData.city}
              />
              <SinglePageItemText 
                title="State"
                value={businessData.state}
              />
              <SinglePageItemText 
                title="ZIP Code"
                value={businessData.zip}
              />
              <SinglePageItemText 
                title="Product or Service required"
                value={businessData.description}
              />
              <SinglePageItemText 
                title="Comments"
                value={businessData.comment}
              />
              {/* <SinglePageItemArray 
                title="NAICS Code"
                type="pm"
                value={businessData.naics}
              /> */}
              {/* <SinglePageItemText 
                title="Part Number"
                value={businessData.part_number}
              /> */}
              { businessData.posted_date && (<SinglePageItemText 
                title="Posted Date"
                value={convertDate(businessData.posted_date)}
              /> )}
              <SinglePageItemText 
                title="Quantity"
                value={businessData.quantity}
              />
              {/* <SinglePageItemText 
                title="Requestor"
                value={businessData.requestor_name}
              /> */}
              <SinglePageItemText 
                title="Deadline"
                value={businessData.required_date}
              />
              {/* <SinglePageItemArray 
                title="Set-Aside"
                type="pm"
                value={businessData.setaside_code}
              /> */}
              {businessData.link && (
              <div className="single-page-item align-items-center">
                <h4>
                  Applicable Link
                </h4>
                <a className="btnSizes filledBtn" target="_blank" rel="noreferrer" href={businessData.link}>Link</a>
              </div>
              )}
            </div>
          </Col>
        </Row>
        {loading && <Loading />}
      </Container>    
    </section>
  )
}

export default ManualOpportunitySingle;