import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import TabBar from '../TabBar';
import ActionContainer from './ActionContainer';
import AdvancedTabOptions from './AdvancedTabOptions';
import Loading from '../Loading';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { TabContext } from '../../context/TabContext';
import date from 'date-and-time';
import { 
  NAICS_LIST, 
  CITY_LIST, 
  COUNTY_LIST, 
  SETASIDE_LIST, 
  BUSINESS_TYPE_LIST,
  BUSINESSES_OPTIONS_LIST,
  CONTRACT_OPTIONS_LIST,
  PURCHASE_OPTIONS_LIST,
  ZIP_LIST,
  SETASIDE_CODE_LIST,
  OPP_CLASS_LIST
} from '../../constans';

const TITLE_LIST = {
  businesses: "Business",
  contract_opportunities: "Contract",
  b2b_opportunities: "B2B Opportunities"
};

const ADVANCED_TAB_BAR_ITEMS = [
  {
    label: "Businesses",
    value: "businesses"
  },
  {
    label: "Contracts",
    value: "contract_opportunities"
  },
  {
    label: "B2B Opportunities",
    value: "b2b_opportunities"
  }
];

const DEFAULT_INPUT_VALUES = {
  name: "",
  url: "",
  county: "",
  set_aside: "",
  zip: "",
  type: "",
  keywords: "",
  contract_number: "",
  contract: "",
  registered_in_sam: "",
  class_code: "",
  opp_id: "",
  setaside_code: "",
  naics: "",
  deadline: {},
  search: "",
  city: "",
  zip_code: "",
  state: "",
  sol_no: "",
  required_date_range: {},
  comment: "",
}

const AdvancedSearch = () => {
  const [active, setActive] = useState('businesses');
  const [loading, setLoading] = useState(false);
  const [resetTrigger, setresetTrigger] = useState(false);
  const { register, handleSubmit } = useForm();
  const history = useHistory();
  const [inputValue, setInputValue] = useState(DEFAULT_INPUT_VALUES)
  const [selectValue, setSelectValue] = useState([]);
  const [list, setList] = useState([]);
  const qs = require('qs');

  const handleMultiDelete = (name, valuesFunc, valuesValue) => {
    switch (name.action) {
      case 'remove-value':
        valuesFunc([...valuesValue.filter(el => el.value !== name.removedValue.value)]);
        break;
      case 'clear':
        valuesFunc([...valuesValue.filter(el => el.type !== name.name)]);
        break;
      default:
    }
  }

  const handleMultiAdd = (item, valuesFunc, valuesValue) => {
    switch (item.type) {
      case 'city':
      case 'naics':
      case 'county':
      case 'set_aside':
      case 'setaside_code':
      case 'type':
      case 'zip':
      case 'opp_type':
        valuesFunc([...valuesValue, item]);
        break;
      default:
        valuesFunc([...valuesValue.filter(i => i.type !== item.type), item]);
    }
  }

  const setLimitSelectValues = (data, value, blackList = null) => {
    let i = 0;

    if (blackList === null) blackList = [];

    const newList = data.filter(item => {
      if (item.label.toLowerCase().includes(value.toLowerCase()) && i < 10) {
        if (!blackList.includes(item.value)) i++;
        return item
      }
    })

    setList(newList);
  }

  const onChangeHandler = (value, name ,action) => {
    switch (name) {
      case 'city':
        setList(CITY_LIST);
        break;
      case 'county':
        setList(COUNTY_LIST);
        break;
      case 'set_aside':
        setList(SETASIDE_LIST);
        break;
      case 'setaside_code':
        setList(SETASIDE_CODE_LIST);
        break;
      case 'type':
        setList(BUSINESS_TYPE_LIST);
        break;
      case 'opp_type':
        setList(OPP_CLASS_LIST);
        break;
      case 'naics':
        setLimitSelectValues(NAICS_LIST, value);
        break;
      case 'zip':
        setLimitSelectValues(ZIP_LIST, value);
        break;
    }

    if (action && action.action === "input-change") {
      setInputValue({ ...inputValue, [name]: value });
    }

    if (!value) setList([]);
  }
  
  const str2bool = (value) => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
    }
    return value;
  }

  const onChangeSingleValue = (event, name) => {
    setInputValue({
      ...inputValue,
      [name]: str2bool(event.target.value)
    })
  }

  const handleMultiChange = (event, name) => {
    const item = Array.isArray(event) ? event[event.length - 1] : "";

    if (name.removedValue || name.removedValues) {
      handleMultiDelete(name, setSelectValue, selectValue);
    } else if (item) {
      handleMultiAdd(item, setSelectValue, selectValue);
    } else if (event && event.type) {
      setSelectValue([...selectValue, event]);
    }
  }

  const onSubmit = (data, e) => {
    e.preventDefault();
    setLoading(true);

    const obj = {
      city: [],
      naics: [],
      county: [],
      set_aside: [],
      type: [],
      zip: [],
      setaside_code: [],
      opp_type: []
    };

    selectValue.forEach((item) => {
      obj[item.type].push(item.value);
    })

    const params = {
      ...data,
      ...obj,
      entity: active,
      deadline: inputValue.deadline,
      purchase_date: inputValue.purchase_date,
      required_date_range: inputValue.required_date_range
    }

    for (let prop in params) {
      if (
        params[prop] === null || 
        params[prop] === undefined || 
        params[prop] === "" || 
        params[prop].length === 0 || 
        Object.keys(params[prop]).length === 0
      ) {
        delete params[prop];
      }

      if (params[prop] === 'false') {
        params[prop] = false
      } else if (params[prop] === 'true') {
        params[prop] = true
      }

      if (params[prop] && prop === "keywords" || params[prop] && prop === "purchase_keywords") {
        params[prop] = params[prop].split(',').map(item => item.trim());
      }
    }

    const queryString = qs.stringify(params);

    history.push(`/advanced-search/results?${queryString}`)
  };

  const handleTimeCallback = (start, end, name) => {
    const from = date.format(start._d, 'YYYY-MM-DD');
    const to = date.format(end._d, 'YYYY-MM-DD');
   
    setInputValue({
      ...inputValue,
      [name]: {
        from,
        to
      }
    })
  }

  const resetHandler = () => {
    setresetTrigger(!resetTrigger)
    setSelectValue([]);
    setList([]);
    setInputValue(DEFAULT_INPUT_VALUES);
  }

  return (
    <section className="section-spaces">
      <Container>
        <Row>
          <Col>
            <h3 className="mb-5">
              Advanced {TITLE_LIST[active]} Search
            </h3>
            <p className="mb-5">
              By registering on this website, your organization will receive contract and grant opportunities, and the ability 
              to search for business partners, employees and investor opportunities.
              <br /><br />
              In order to use most of Govtide's capabilities, including receiving purchase card holder opportunities, businesses should be prepared
              to provide the following information during registration:
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <TabBar
              active={active}
              setActive={setActive}
              resetHandler={resetHandler}
              tabItems={ADVANCED_TAB_BAR_ITEMS}
            />
          </Col>
        </Row>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <TabContext.Provider value={{
                register, 
                resetTrigger, 
                handleMultiChange, 
                onChangeHandler, 
                list, 
                inputValue, 
                selectValue, 
                setInputValue, 
                handleTimeCallback, 
                setSelectValue,
                onChangeSingleValue,
              }}>
                {active === 'businesses' && <AdvancedTabOptions inputList={BUSINESSES_OPTIONS_LIST} />}
                {active === 'contract_opportunities' && <AdvancedTabOptions inputList={CONTRACT_OPTIONS_LIST} />}
                {active === 'b2b_opportunities' && <AdvancedTabOptions inputList={PURCHASE_OPTIONS_LIST} />}
              </TabContext.Provider>
            </Col>
          </Row>
          <Row>
            <Col>
              <ActionContainer 
                resetHandler={resetHandler}
              />
            </Col>
          </Row>
        </form>
      </Container>
      {loading && <Loading />}
    </section>
  )
}

export default AdvancedSearch;