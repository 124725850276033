import React, { useEffect, useState, useContext } from 'react';
import Loading from '../Loading';
import { useForm } from 'react-hook-form';
import { Container, Row, Col } from 'react-bootstrap';
import TabBar from '../TabBar';
import EditProfileTabOptions from './EditProfileTabOptions';
import { useHistory, useLocation } from 'react-router-dom';
import {
  EDIT_USER_INFO_LIST,
  EDIT_BUSINESS_INFO_LIST,
  EDIT_BUSINESS_ADDITIONAL_LIST,
  NAICS_LIST,
  OPP_CLASS_LIST,
  SETASIDE_LIST,
  PAYMENT_TYPES,
  BUSINESS_TYPE_LIST,
  SHIPPING_TERMS_LIST,
  STATE_LIST,
  PSC_LIST,
  RELATIONSHIP_LIST
} from '../../constans';
import { TabContext } from '../../context/TabContext';
import ActionContainer from './ActionContainer';
import AlertMessage from '../AlertMessage';
import Modal from '../UI/Modal';
import ModalContent from './ModalContent';
import {
  profileGet,
  userPost,
  businessPut,
  matchSettingsGet,
  matchSettingsPut
} from '../../api';
import { UserContext } from '../../context/User';
import { editedNotice, somethingWentWrongNotice } from '../UI/Notifications';
import { useLoadScript } from '@react-google-maps/api';

const BUSINESS_EDIT_PROFILE_TAB_BAR_ITEMS = [
  {
    label: "User Info",
    value: "user_info"
  },
  {
    label: "Business Info",
    value: "business_info"
  },
  {
    label: "Match Info",
    value: "match_info"
  }
];

const EDIT_MATCHES_ALERT = `
  <div>
    <p class="mb-0">
      The Govtide system will run it's matching algorithm every 2 hours. If you make changes to your profile<br />
      information and are expecting new matches to appear, please be sure to wait the 2 hours before looking again
    </p>
  </div>
`;

const DEFAULT_INPUT_VALUES = {
  user: {
    username: "",
    email: "",
    password: "",
    user_message: false,
    user_contact: false
  },
  business: {
    name: "",
    url: "",
    contact: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    county: "",
    zip: "",
    phone: "",
    fax: "",
    type: "",
    description: "",
    keywords: "",
    contract_number: "",
    duns_number: "",
    cage: "",
    tax: "",
    naics: "",
    payment_method: "",
    shipping_terms: "",
    email: "",
    set_aside: "",
    psc_code: "",
    business_type: "",
    notifications_type: "",
    geo_fencing_zips: "",
    geo_fencing_locations: "",
  },
  settings: {
    custom_states: [],
    match_keywords: false,
    match_naicses: false,
    option: "enabled"
  }
}

const REQUEST_TYPE = {
  user_info: userPost,
  business_info: businessPut,
  match_info: businessPut
}

const BUSINESS_TYPE_LIST2 = [
  { value: "b", label: "Business Entity", type: "business_type" },
  { value: "g", label: "Government Entity", type: "business_type" },
  { value: "i", label: "Investor Entity", type: "business_type" },
  { value: "e", label: "Employment Seeker", type: "business_type" }
]

const NOTIFICATION_TYPE_LIST = [
  { value: "d", label: "Daily", type: "notifications_type" },
  { value: "w", label: "Weekly", type: "notifications_type" },
]

const GOV_RELATIONS_LIST = [
  { value: "Business_Organization", label: "Business Organization", type: "gov_relations" },
  { value: "Government_Entity", label: "Government Entity", type: "gov_relations" },
  { value: "Contracting_Consultant", label: "Contracting Consultant", type: "gov_relations" },
  { value: "Lobbyist_Group", label: "Lobbyist Group", type: "gov_relations" },
  { value: "Lender_Institution", label: "Lender Institution", type: "gov_relations" },
  { value: "Financial_Investor", label: "Financial Investor", type: "gov_relations" },
  { value: "Individual_Employee", label: "Individual Employee", type: "gov_relations" },
  { value: "Media_Entity", label: "Media Entity", type: "gov_relations" },
  { value: "Elected_Official", label: "Elected Official", type: "gov_relations" }
]

const libraries = ["places"]

const BusinessEditProfile = () => {
  const loc = useLocation();
  const [active, setActive] = useState(loc.state ? loc.state.tab : "user_info");
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const { register: register2, handleSubmit: handleSubmit2 } = useForm();
  const [inputValue, setInputValue] = useState(DEFAULT_INPUT_VALUES)
  const [selectValue, setSelectValue] = useState([]);
  const [list, setList] = useState([]);
  const [show, setShow] = useState(false);
  const [advancedMatchSettings, setAdvancedMatchSettings] = useState(false);
  const { user, setUser, setIsLoggedIn } = useContext(UserContext);
  const history = useHistory();
  const [currentInfoTrigger, setCurrentInfoTrigger] = useState(false);
  const [settingId, setSettingId] = useState(null);
  const [address, setAddress] = useState({});
  const [location, setLocation] = useState({});
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_DROPDOWN_API_KEY,
    libraries,
  });

  const logout = () => {
    setIsLoggedIn(false);
    setUser({});
    history.push('/');
  }

  const handleDefaultStatusCodes = (status_code) => {
    if (status_code === 440 || status_code === 401) {
      logout()
    } else {
      somethingWentWrongNotice("Search");
    }

    setLoading(false);
  }

  const getInputCurrentUserData = (response) => {
    setInputValue({
      ...inputValue,
      user: {
        ...inputValue.user,
        username: response.username,
        user_contact: response.user_contact,
        email: response.email,
        user_message: response.user_message
      },
      business: {
        ...inputValue.business,
        ...response.business
      }
    })
  }

  const getLabel = (value, options) => {
    for (let index in options) {
      if (options[index].value === value) {
        return options[index].label
      }
    }
  }

  const getSingleLabel = (value, options) => {
    for (let index in options) {
      if (options[index].label === value) {
        return options[index].label
      }
    }
  }

  const getSelectCurrentData = (response) => {
    let select = [];
    if (response.gov_relations) {
      select = [
        ...select,
        {
          label: getSingleLabel(response.gov_relations, GOV_RELATIONS_LIST),
          value: response.gov_relations,
          type: 'gov_relations',
        }
      ];
    }
    for (let key in response) {
      if (Array.isArray(response[key])) {
        switch (key) {
          case 'naics':
            select = [
              ...select,
              ...response[key].map((item) => {
                return {
                  label: getLabel(item, NAICS_LIST),
                  value: item,
                  type: 'naics',
                };
              }),
            ];
            break;
          case 'psc_code':
            select = [
              ...select,
              ...response[key].map((item) => {
                return {
                  label: getLabel(item, PSC_LIST),
                  value: item,
                  type: 'psc_code',
                };
              }),
            ];
            break;
          case 'business_type':
            select = [
              ...select,
              ...response[key].map((item) => {
                return {
                  label: getLabel(item, BUSINESS_TYPE_LIST2),
                  value: item,
                  type: 'business_type',
                };
              }),
            ];
            break;
          case 'type':
            select = [
              ...select,
              ...response[key].map((item) => {
                return {
                  label: getLabel(item, BUSINESS_TYPE_LIST),
                  value: item,
                  type: 'type',
                };
              }),
            ];
            break;
          case 'set_aside':
            select = [
              ...select,
              ...response[key].map((item) => {
                return {
                  label: getLabel(item, SETASIDE_LIST),
                  value: item,
                  type: 'set_aside',
                };
              }),
            ];
            break;
          case 'payment_method':
            select = [
              ...select,
              ...response[key].map((item) => {
                return {
                  label: getLabel(item, PAYMENT_TYPES),
                  value: item,
                  type: 'payment_method',
                };
              }),
            ];
            break;
          case 'shipping_terms':
            select = [
              ...select,
              ...response[key].map((item) => {
                return {
                  label: getLabel(item, SHIPPING_TERMS_LIST),
                  value: item,
                  type: 'shipping_terms',
                };
              }),
            ];
            break;
          case 'custom_states':
            select = [
              ...select,
              ...response[key].map((item) => {
                return {
                  label: getLabel(item, STATE_LIST),
                  value: item,
                  type: 'state',
                };
              }),
            ];
            break;
          case 'notifications_type':
            select = [
              ...select,
              ...response[key].map((item) => {
                return {
                  label: getLabel(item, NOTIFICATION_TYPE_LIST),
                  value: item,
                  type: 'notifications_type',
                };
              }),
            ];
            break;
          case 'geo_fencing_zips':
            select = [
              ...select,
              ...response[key].map((item) => {
                return { label: item, value: item, type: 'geo_fencing_zips' };
              }),
            ];
          break;
          case 'geo_fencing_locations':
            select = [
              ...select,
              ...response[key].map((item) => {
                return {
                  label: item.label,
                  value: item,
                  type: 'geo_fencing_locations',
                };
              }),
            ];
            break;  
          default:
        }
      }
    }

    setSelectValue([...selectValue, ...select]);
  };

  const currentInfoRequest = request => {
    setLoading(true)
    const user_id = user.id;

    request({ user_id })
      .then((response) => {
        getInputCurrentUserData(response.data);
        getSelectCurrentData(response.data.business);
        setLoading(false);
      })
      .catch(error => {
        handleDefaultStatusCodes(error.response.status);
      })
  }

  const getMatchSettings = () => {
    matchSettingsGet()
      .then(response => {
        getSelectCurrentData(response.data.settings);
        setInputValue({
          ...inputValue,
          settings: {
            ...response.data.settings
          }
        })
        setSettingId(response.data.id);
        setLoading(false);
      })
      .catch(error => {
        handleDefaultStatusCodes(error.response.status);
      })
  }

  useEffect(() => {
    currentInfoRequest(profileGet)
  }, [currentInfoTrigger]);

  const handleMultiDelete = (name, valuesFunc, valuesValue) => {
    switch (name.action) {
      case 'remove-value':
        valuesFunc([
          ...valuesValue.filter((el) => el.value !== name.removedValue.value),
        ]);
        break;
      case 'clear':
        valuesFunc([...valuesValue.filter((el) => el.type !== name.name)]);
        break;
      default:
    }
  }

  const handleMultiAdd = (item, valuesFunc, valuesValue) => {
    switch (item.type) {
      case 'payment_method':
      case 'naics':
      case 'type':
      case 'shipping_terms':
      case 'set_aside':
      case 'state':
      case 'psc_code':
      case 'business_type':
        valuesFunc([...valuesValue, item]);
        break;
      case 'geo_fencing_zips':
        valuesFunc([...valuesValue, item]); 
      default:
        valuesFunc([...valuesValue.filter((i) => i.type !== item.type), item]);
    }
  }

  const setLimitSelectValues = (data, value, blackList = null) => {
    let i = 0;

    if (blackList === null) blackList = [];

    const newList = data.filter((item) => {
      if (item.label.toLowerCase().includes(value.toLowerCase()) && i < 10) {
        if (!blackList.includes(item.value)) i++;
        return item
      }
    })

    setList(newList);
  }

  const onChangeHandler = (value, name, action) => {
    switch (name) {
      case 'opp_type':
        setList(OPP_CLASS_LIST);
        break;
      case 'type':
        setList(BUSINESS_TYPE_LIST);
        break;
      case 'set_aside':
        setList(SETASIDE_LIST);
        break;
      case 'business_type':
        setList(BUSINESS_TYPE_LIST2);
        break;
      case 'naics':
        setLimitSelectValues(NAICS_LIST, value);
        break;
      case 'psc_code':
        setLimitSelectValues(PSC_LIST, value);
        break;
      case 'payment_method':
        setList(PAYMENT_TYPES);
        break;
      case 'shipping_terms':
        setList(SHIPPING_TERMS_LIST);
        break;
    }

    if (action && action.action === "input-change") {
      setInputValue({ ...inputValue, [name]: value });
    }

    if (!value) setList([]);
  }

  const str2bool = (value) => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
    }
    return value;
  }

  const onChangeSingleValue = (event, name, infoType) => {
    setInputValue({
      ...inputValue,
      [infoType]: {
        ...inputValue[infoType],
        [name]: str2bool(event.target.value)
      }
    })
  }

  const handleMultiChange = (event, name, infoType) => {
    const item = Array.isArray(event) ? event[event.length - 1] : "";

    if (name.removedValue || name.removedValues) {
      handleMultiDelete(name, setSelectValue, selectValue);
    } else if (item) {
      handleMultiAdd(item, setSelectValue, selectValue);
    } else if (event && event.type) {
      setSelectValue([...selectValue, event]);
      if (event.type === 'notifications_type' || event.type === 'gov_relations') {
        const transformedSelectValue = selectValue.filter(item => item.type !== 'gov_relations')
        setSelectValue([...transformedSelectValue, event]);
        setInputValue({
          ...inputValue,
          [infoType]: {
            ...inputValue[infoType],
            [name]: event.value
          }
        })
      }
    }
  } 

  const handleCreatableChange = (selectedOptions, { action }) => {
    if (action === 'create-option') {
      // Handle the creation of a new option
      const newOption = selectedOptions[selectedOptions.length - 1];
      setSelectValue([
        ...selectValue,
        {
          label: newOption.value,
          value: newOption.value,
          type: 'geo_fencing_zips',
        },
      ]);
    } else {
      // Update the selectValue state with the new selected options for other actions
      setSelectValue(selectedOptions);
    }
  };

  const handleFilterUpdateLocation = (locations) => {
    const locationValues = locations.map(location => ({
      coordinates: {
        lat: location.coordinates.lat.toString(),
        lng: location.coordinates.lng.toString()
      },
      label: location.label,
      placeId: location.value, // use value as placeId
      radius: Number(location.radius),
      value: location.value,
    }));

    setInputValue({
      ...inputValue,
      business: {
        ...inputValue.business,
        geo_fencing_locations: locationValues
      }
    })
  };

  const handleFilterZip = (zipCodes) => {
    if (!zipCodes) return;
    console.log('zipCodes', zipCodes);
    const zipValues = zipCodes.map(zip => zip.zip);  
    setInputValue(prevValue => ({
      ...prevValue,
      business: {
        ...prevValue.business,
        geo_fencing_zips: zipValues
      }
    }));
  }

  const dataTransformation = params => {
    for (let prop in params) {
      if (
        params[prop] === null ||
        params[prop] === undefined ||
        (prop === 'password' && !params[prop])
      ) {
        delete params[prop];
      }

      if (params[prop] === 'false') {
        params[prop] = false;
      } else if (params[prop] === 'true') {
        params[prop] = true;
      }

      if (prop === 'keywords') {
        params[prop] = params[prop]
          .split(',')
          .map((item) => item.trim())
          .filter((item) => item);
      }
    }

    return params;
  }

  const onSubmit = (data, e) => {
    e.preventDefault();
    setLoading(true);

    let obj = {
      naics: [],
      type: [],
      payment_method: [],
      shipping_terms: [],
      set_aside: [],
      psc_code: [],
      business_type: [],
      geo_fencing_zips: [],
      geo_fencing_locations: [],
    };

    selectValue.forEach((item) => {
      if (item.type === 'gov_relations' || item.type === 'notifications_type') {
        obj[item.type] = item.value
      } else {
        obj[item.type].push(item.value);
      }
    })

    if (active === 'user_info') {
      obj = {}
    }

    const params = {
      ...data,
      ...obj,
      ...address,
      ...location,
      geo_fencing_locations: inputValue.business.geo_fencing_locations || [],
      geo_fencing_zips: inputValue.business.geo_fencing_zips || [],
    } 

    if (active === 'user_info') {
      delete params.geo_fencing_locations
      delete params.geo_fencing_zips
    }

    const transformedParams = dataTransformation(params);
    request(active, transformedParams)
  };

  const request = (active, transformedParams) => {
    REQUEST_TYPE[active](transformedParams)
      .then((response) => {
        editedNotice();
        if (active === 'user_info') setUser(response.data);
        setLoading(false);
      })
      .catch((error) => {
        handleDefaultStatusCodes(error.response.status);
        setCurrentInfoTrigger(!currentInfoTrigger);
        setLoading(false);
      })
  }

  const transformAddress = (place) => {
    const obj = {
      address1: "",
      city: "",
      state: "",
      zip: "",
      country: ""
    }

    const compIsType = (t, s) => {
      for (let z = 0; z < t.length; ++z) {
        if (t[z] == s) {
          return true;
        }

        return false;
      }
    };

    if (place.address_components !== undefined) {
      let addrComp = place.address_components;
      for (let i = 0; i < addrComp.length; ++i) {
        var typ = addrComp[i].types;
        if (compIsType(typ, 'administrative_area_level_1')) {
          obj.state = addrComp[i].long_name; //store the state
        } else if (compIsType(typ, 'locality')) {
          obj.city = addrComp[i].long_name; //store the city
        } else if (compIsType(typ, 'postal_code')) {
          obj.zip = addrComp[i].long_name; //store the country
        } else if (compIsType(typ, 'street_number')) {
          obj.address1 = addrComp[i].long_name;
        } else if (compIsType(typ, 'route')) {
          obj.address1 += ` ${addrComp[i].long_name}`;
        } else if (compIsType(typ, 'country')) {
          obj.country = `${addrComp[i].short_name}`;
        }
      }
    }
    setAddress(obj);
  };

  const transformLocation = (place) => {
    const obj = {
      location_address: "",
      location_country: "",
      location_state: "",
      location_zip: "",
      location_city: ""
    }
    const compIsType = (t, s) => {
      for (let z = 0; z < t.length; ++z) {
        if (t[z] == s) {
          return true;
        }

        return false;
      }
    }

    if (place.address_components !== undefined) {
      let addrComp = place.address_components;
      for (let i = 0; i < addrComp.length; ++i) {
        var typ = addrComp[i].types;
        if (compIsType(typ, 'administrative_area_level_1')) {
          obj.location_state = addrComp[i].long_name; //store the state
        } else if (compIsType(typ, 'country')) {
          obj.location_country = addrComp[i].short_name; //store the city
        } else if (compIsType(typ, 'postal_code')) {
          obj.location_zip = addrComp[i].long_name; //store the country
        } else if (compIsType(typ, 'street_number')) {
          obj.location_address = addrComp[i].long_name;
        } else if (compIsType(typ, 'route')) {
          obj.location_address += ` ${addrComp[i].long_name}`;
        } else if (compIsType(typ, 'locality')) {
          obj.location_city = `${addrComp[i].long_name}`;
        }
      }
    }
    setLocation(obj);
  }

  return (
    <section className="section-spaces">
      <Container>
        <Row>
          <Col>
            <h3 className="mb-5">Edit Profile</h3>
          </Col>
        </Row>
        {active === 'match_info' && (
          <Row>
            <Col>
              <AlertMessage 
                alertMessage={EDIT_MATCHES_ALERT}
                spaces="mt-0 mb-5"
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <TabBar
              active={active}
              setActive={setActive}
              tabItems={BUSINESS_EDIT_PROFILE_TAB_BAR_ITEMS}
            />
          </Col>
        </Row>
        <TabContext.Provider
          value={{
            register,
            register2,
            handleMultiChange,
            handleCreatableChange,
            onChangeHandler,
            list,
            inputValue,
            selectValue,
            setInputValue,
            setSelectValue,
            onChangeSingleValue,
            getMatchSettings,
            setLoading,
            transformAddress,
            transformLocation,
            loc,
            isLoaded,
            loadError,
            handleFilterUpdateLocation,
            handleFilterZip,
            errors
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col>
                {active === 'user_info' && (
                  <EditProfileTabOptions inputList={EDIT_USER_INFO_LIST} />
                )}
                {active === 'business_info' && (
                  <EditProfileTabOptions inputList={EDIT_BUSINESS_INFO_LIST} />
                )}
                {active === 'match_info' && (
                  <EditProfileTabOptions
                    inputList={EDIT_BUSINESS_ADDITIONAL_LIST}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <ActionContainer active={active} />
              </Col>
            </Row>
          </form>
          {/* <Modal 
            handleClose={handleClose} 
            show={active === "match_info" && show}
            handleSubmit={handleSubmit2}
            onSubmitAdvanced={onSubmitAdvanced}
          >
            <ModalContent />
          </Modal> */}
        </TabContext.Provider>
      </Container>
      {loading && <Loading />}
    </section>
  )
}

export default BusinessEditProfile;