import React, { useContext } from 'react';
import LoginHeader from '../../components/Headers/LoginHeader';
import LoginFooter from '../../components/Footers/LoginFooter';
import BusinessPost from '../../components/BusinessPost';
import OfficerPost from '../../components/OfficerPost';
import AdminPost from '../../components/AdminPost';
import { UserContext } from '../../context/User';
import AdminHeader from '../../components/Headers/AdminHeader';

const PostPage = () => {
  const { user } = useContext(UserContext);
  const role = user && user.roles && user.roles[0].role_description;

  return (
    <>  
      {role === "admin" ? <AdminHeader /> : <LoginHeader />}
      <main>
        {role === "business" && <BusinessPost />}
        {role === "officer" && <OfficerPost />}
        {role === "admin" && <AdminPost />}
      </main>
      <LoginFooter />
    </>
  )
}

export default PostPage;