import React from 'react';
import { useForm } from 'react-hook-form';
import MultySelect from '../UI/MultiSelect';
import classes from './index.module.scss';
// import { OverlayTrigger, Tooltip } from 'react-bootstrap'
// import { ReactComponent as TooltipIcon } from '../../assets/icons/tooltip.svg';

const FrameFour = ({ onSubmit, onChangeHandler, handleMultiChange, prev, setStep, list, inputValue, selectValue }) => {
  const { register, handleSubmit } = useForm();

  return (
    <div className={classes.Step_Wrapper}>
      <div className={classes.Step_Header}>
        <h3 className='mb-0'>Find your NAICS</h3>
        <div className={classes.Finish_Wrapper}  onClick={handleSubmit(onSubmit)}>
          Finish
        </div>
      </div>
      <div className={classes.Step_Content}>
        <p>
          Please select your NAICS codes. The NAICS codes you select will determine which industry groups you will be added to and the opportunities you will receive in 
          your dashboard. These groups receive solicitation posts from Federal Agencies for Federal Contract Opportunities, Federal Grants, B2B Opportunities and more. Make 
          sure you choose the most relevant NAICS codes in order to receive relevant opportunities for your business or organization.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset>
           <label htmlFor="naics">NAICS Codes for Your Business (choose as many keywords as you need)</label>
            <div className="tooltip-container-select">
              <MultySelect 
                onChangeHandler={onChangeHandler}
                handleMultiChange={handleMultiChange}
                name="naics"
                list={list}
                inputValue={inputValue.naics}
                selectValue={selectValue.filter(item => item.type === 'naics')}
                styles="react-select-container"
                prefixStyles="react-select"
                indicator={false}
                check={true}
              />
            </div>
          </fieldset>
        </form>
      </div>
      <div className={classes.Step_Footer}>
        <span className={classes.Prev} onClick={prev}>Prev</span>
        <div className={classes.Dots_Container}>
          <div className={classes.Inactive_Dot}></div>
          <div className={classes.Inactive_Dot}></div>
          <div className={classes.Inactive_Dot}></div>
          <div className={classes.Active_Dot}></div>
        </div>
        <button 
          className={classes.Next}
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          Finish
        </button>
      </div>
    </div>
  )
    // <form onSubmit={handleSubmit(onSubmit)}>
      /* <fieldset>
        <div className="tooltip-container-label">
          <label htmlFor="contract_num">Do you have a GSA contract? <span>*</span></label>
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id="button-tooltip">
                A GSA Contract is a contract with the Federal General Services Administration.  If you do not have one, or for more information about becoming 
                a GSA Schedule Contractor, please visit:  http://www.gsa.gov/
              </Tooltip>
            }
          >
            <TooltipIcon />
          </OverlayTrigger>
        </div>
        <div className="d-flex">
          <div className="radio-wrapper">
            <input ref={register({ required: true })} type="radio" value={true} name="contract" id="gsa_contract_num-yes" />
            <label htmlFor="gsa_contract_num-yes">Yes</label>
          </div>
          <div className="radio-wrapper">
            <input ref={register({ required: true })} type="radio" value={false} name="contract" id="gsa_contract_num-no" defaultChecked />
            <label htmlFor="gsa_contract_num-no">No</label>
          </div>
        </div>
      </fieldset>
      <fieldset>
        <label htmlFor="contract_number">GSA contract number</label>
        <div className="tooltip-container">
          <TextInput 
            name="contract_number"
            type="text"
            register={register()}
          />
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id="button-tooltip">
                Provide your GSA Contract Number.  Example:  GS-xxx-xxxxx
              </Tooltip>
            }
          >
            <TooltipIcon />
          </OverlayTrigger>
        </div>
      </fieldset>
      <fieldset>
        <label htmlFor="duns_number">DUNS Unique Identifier ID</label>
        <div className="tooltip-container">
          <TextInput 
            name="duns_number"
            type="text"
            register={register()}
          />
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id="button-tooltip">
                DUNS (Data Universal Numbering System) is obtained through Dun & Bradstreet at https://eupdate.dnb.com/requestoptions/government/ccrreg/  This is a free registration.
              </Tooltip>
            }
          >
            <TooltipIcon />
          </OverlayTrigger>
        </div>
      </fieldset>
      <fieldset>
        <label htmlFor="uei">UEI</label>
        <div className="tooltip-container">
          <TextInput 
            name="uei"
            type="text"
            register={register()}
          />
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id="button-tooltip">
                Unique Entity Identifier
              </Tooltip>
            }
          >
            <TooltipIcon />
          </OverlayTrigger>
        </div>
      </fieldset>
      <fieldset>
        <label htmlFor="cage_code">CAGE code</label>
        <div className="tooltip-container">
          <TextInput 
            name="cage_code"
            type="text"
            register={register()}
          />
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id="button-tooltip">
                CAGE (Commerical and Government Entity) is assigned during SAM registration.  For more information got to http://www.sam.gov/
              </Tooltip>
            }
          >
            <TooltipIcon />
          </OverlayTrigger>
        </div>
      </fieldset>
      <fieldset>
        <label htmlFor="tax_number">Tax ID number</label>
        <div className="tooltip-container">
          <TextInput 
            name="tax_number"
            type="text"
            register={register()}
          />
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id="button-tooltip">
                (Input your federal tax id number)
              </Tooltip>
            }
          >
            <TooltipIcon />
          </OverlayTrigger>
        </div>
      </fieldset>
      <fieldset>
        <label htmlFor="sam_id">SAM Unique Identifier ID</label>
        <div className="tooltip-container">
          <TextInput 
            name="sam_id"
            type="text"
            register={register()}
          />
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id="button-tooltip">
                (Input your Sam unique identifier ID)
              </Tooltip>
            }
          >
            <TooltipIcon />
          </OverlayTrigger>
        </div>
      </fieldset>
      <fieldset>
        <div className="tooltip-container-label">
          <label htmlFor="contract_num">Registered in <a rel="noreferrer" target="_blank" href="https://sam.gov/content/home/">sam.gov</a>?* <span>*</span></label>
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id="button-tooltip">
                SAM is the Federal Systems for Award Management interface (formerly known as CCR - Central Contractor Registration).  
                You must be registered at www.sam.gov in order to bid on or win contracts over $2500.  This registration is free and takes 
                24-48 hours to process.
              </Tooltip>
            }
          >
            <TooltipIcon />
          </OverlayTrigger>
        </div>
        <div className="d-flex">
          <div className="radio-wrapper">
            <input ref={register({ required: true })} type="radio" value={true} name="registered_in_sam" id="samgov-yes" />
            <label htmlFor="samgov-yes">Yes</label>
          </div>
          <div className="radio-wrapper">
            <input ref={register({ required: true })} type="radio" value={false} name="registered_in_sam" id="samgov-no" defaultChecked />
            <label htmlFor="samgov-no">No</label>
          </div>
        </div>
      </fieldset>
      <button 
        type="submit"
        className="filledBtn btnSizes"
      >
        Save & Continue
      </button> */
    // </form>
}

export default FrameFour;