// import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
// import { ReactComponent as NotCheckIcon } from '../../assets/icons/not-check.svg';
import React from 'react';
import { useForm } from 'react-hook-form';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow-right.svg';
import TextInput from '../UI/TextInput';
import classes from './index.module.scss';
import MultySelect from '../UI/MultiSelect';
import Autocomplete from "react-google-autocomplete";
import Select from 'components/UI/Select';
import LocationRadiusFilter from 'components/LocationRadiusFilter';

const FrameThree = ({ onSubmit, inputValue, selectValue, onChangeSingleValue, transformLocation, prev, setStep, onChangeHandler, handleMultiChange, handleFilterUpdate, handleFilterUpdateLocation, list }) => {
  // const checkSelectedValue = (selectValue, valueType) => {
  //   const existingSelectValue = selectValue.filter(item => item.type === valueType)

  //   return existingSelectValue.length
  // }

  const { register, handleSubmit, errors } = useForm();

  return (
    <div className={classes.Step_Wrapper}>
    <div className={classes.Step_Header}>
      <h3 className='mb-0'>Government Contracting Profile</h3>
      <div className={classes.Arrow_Wrapper}  onClick={handleSubmit(onSubmit)}>
        <ArrowIcon className={classes.Arrow_Icon} />
      </div>
    </div>
    <div className={classes.Step_Content}>
      <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset>
        <label htmlFor="business_type">What kind of organization are you? <span>*</span></label>
          <MultySelect 
            onChangeHandler={onChangeHandler}
            handleMultiChange={handleMultiChange}
            name="business_type"
            list={[
              { value: "b", label: "Business Entity", type: "business_type" },
              { value: "g", label: "Government Entity", type: "business_type" },
              { value: "i", label: "Investor Entity", type: "business_type" },
              { value: "e", label: "Employment Seeker", type: "business_type" }
            ]}
            inputValue={inputValue.business_type}
            selectValue={selectValue.filter(item => item.type === 'business_type')}
            styles="react-select-container"
            prefixStyles="react-select"
            indicator={false}
            check={true}
          />
        </fieldset>
        <fieldset>
          <label htmlFor="gov_relations">I am a:<span>*</span></label>
          <Select
            handleMultiChange={handleMultiChange}
            onChangeHandler={onChangeHandler}
            name="gov_relations"
            list={list}
            styles="react-select-input-container"
            prefixStyles="react-select-input"
            indicator={false}
            isSearchable={false}
            isClear={false}
            placeholder="I am a..."
            defaultValue={selectValue.filter(item => item.type === 'gov_relations')}
          />
        </fieldset>
            <fieldset>
              <label htmlFor="keywords">Keywords that describe what you sell</label>
              <TextInput 
                name="keywords" 
                type="text" 
                register={register()}
                value={inputValue.keywords}
                onChange={onChangeSingleValue}
                placeholder="Add your keywords..."
              />
            </fieldset>
        {/* <fieldset>
          <label htmlFor="address1">What are the locations that you operated in?</label>
          <Autocomplete
            placeholder='Add your address here...'
            options={{
              types: ["geocode", "establishment"],
              componentRestrictions: { country: "usa" }
            }}
            language={ "en" }
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_DROPDOWN_API_KEY}
            onPlaceSelected={(place, inputRef, autocomplete) => {
              transformLocation(place)
            }}
          />
        </fieldset> */}
        <fieldset>
          <label htmlFor="filters">Locations where you do business</label>
          <LocationRadiusFilter onFilterUpdate={handleFilterUpdate} onFilterUpdateLocation={handleFilterUpdateLocation} />
        </fieldset>
      </form>
    </div>
    <div className={classes.Step_Footer}>
      <span className={classes.Prev} onClick={prev}>Prev</span>
      <div className={classes.Dots_Container}>
        <div className={classes.Inactive_Dot}></div>
        <div className={classes.Inactive_Dot}></div>
        <div className={classes.Active_Dot}></div>
        <div className={classes.Inactive_Dot}></div>
      </div>
      <button 
        className={classes.Next}
        type="submit"
        onClick={handleSubmit(onSubmit)}
      >
        Next
      </button>
    </div>
  </div>
    // <div className={classes.Frame_Three}>
    //   <h3 className="mb-5">
    //     Please read for better understanding of matching to contracts
    //   </h3>
    //   <p className="mb-5">
    //     Your current matching criteria: 
    //   </p>
    //   <ul className={classes.Registration_Check_List}>
    //     <li>
    //       <p>Keywords</p>
    //       {inputValue.keywords ? <CheckIcon /> : <NotCheckIcon />}
    //     </li>
    //     <li>
    //       <p>NAICS</p>
    //       {checkSelectedValue(selectValue, 'naics') ? <CheckIcon /> : <NotCheckIcon />}
    //     </li>
    //     <li>
    //       <p>PSC</p>
    //       {checkSelectedValue(selectValue, 'psc_code') ? <CheckIcon /> : <NotCheckIcon />}
    //     </li>
    //     <li>
    //       <p>State(s)</p>
    //       <CheckIcon />
    //     </li>
    //   </ul>
    //   <p>
    //     NAICS CODES<br />
    //     NAICS codes are extremely important, <span>matches are generated using NAICS or PSC codes</span>. If you are utilizing ONLY PSC matching then feel 
    //     free to leave this field blank, if not then please include at least one NAICS code.
    //   </p>
    //   <p>
    //     PSC CODES<br />
    //     PSC codes are extremely important, <span>matches are generated using PSC or NAICS codes</span>. If you are utilizing ONLY NAICS matching 
    //     then feel free to leave this field blank, if not then please include at least one PSC code.
    //   </p>
    //   <p>
    //     KEYWORDS<br />
    //     Micro purchase opportunities are matched based on keywords.
    //   </p>
    //   <p>
    //     Micro purchase opportunities are posted to your dashboard based on your matching keywords. For example, if you are a web designer you 
    //     would want to put keywords such as web design, computer graphics, web development, website maintenance, etc. <span>The more specific the keywords, 
    //     the better the matches will be.</span> If you simply put the keyword 'design', you will get more matches that deal with design rather than web design 
    //     such as building designs, clothing designs, and so on.
    //   </p>
    //   <p>
    //     <span>Also, be sure to separate keywords with commas.</span> Matching is based on keywords/phrases. If you do not use a comma it will consider 
    //     it as a phrase until it has reached a comma. For example, if you put (web design, web maintenance, web development computer graphics) 
    //     it will consider 'web development computer graphics' as a phrase and only match three keywords/phrases. Choose your keywords carefully 
    //     by making them closely related to your business
    //   </p>
    //   <button 
    //     type="submit"
    //     className="filledBtn btnSizes"
    //     onClick={event => onSubmit()}
    //   >
    //     Save & Continue
    //   </button>
    // </div>
  )
}

export default FrameThree;