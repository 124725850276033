import React, { useEffect, useState } from 'react';
import MultiSelect from '../UI/MultiSelect';

const EXCLUDED_MATCHING_SOURCES_TEMPLATE = {
  "b2b": {
    value: "b2b",
    label: "B2B",
    type: "excluded_matching_sources"
  },
  "g2b": {
    value: "g2b",
    label: "G2B",
    type: "excluded_matching_sources"
  },
  "local": {
    value: "local",
    label: "Local",
    type: "excluded_matching_sources"
  },
  "admin": {
    value: "admin",
    label: "Admin",
    type: "excluded_matching_sources"
  },
  "grants": {
    value: "grants",
    label: "Grants",
    type: "excluded_matching_sources"
  }
}

const EXCLUDED_MATCHING_SOURCES_LIST = [
  {
    value: "b2b",
    label: "B2B",
    type: "excluded_matching_sources"
  },
  {
    value: "g2b",
    label: "G2B",
    type: "excluded_matching_sources"
  },
  {
    value: "local",
    label: "Local",
    type: "excluded_matching_sources"
  },
  {
    value: "admin",
    label: "Admin",
    type: "excluded_matching_sources"
  },
  {
    value: "grants",
    label: "Grants",
    type: "excluded_matching_sources"
  }
]

const DEFAULT_INPUT_VALUES = {
  excluded_matching_sources: ""
}

const ExludeEntityModalContent = ({ data = [], exludedSelectValue, setExludedSelectValue }) => {
  const [inputValue, setInputValue] = useState(DEFAULT_INPUT_VALUES);

  useEffect(() => {
    setExludedSelectValue(gerCurrentSelectValue({ data }))
  }, [])

  const gerCurrentSelectValue = ({ data }) => {
    return data.map(item => {
      return EXCLUDED_MATCHING_SOURCES_TEMPLATE[item]
    })
  }

  const handleMultiDelete = (name, valuesFunc, valuesValue) => {
    switch (name.action) {
      case 'remove-value':
        valuesFunc([...valuesValue.filter(el => el.value !== name.removedValue.value)]);
        break;
      case 'clear':
        valuesFunc([...valuesValue.filter(el => el.type !== name.name)]);
        break;
      default:
    }
  }

  const handleMultiAdd = (item, valuesFunc, valuesValue) => {
    switch (item.type) {
      case 'excluded_matching_sources':
        valuesFunc([...valuesValue, item]);
        break;
      default:
        valuesFunc([...valuesValue.filter(i => i.type !== item.type), item]);
    }
  }

  const onChangeHandler = (value, name, action) => {
    if (action && action.action === "input-change") {
      setInputValue({ ...inputValue, [name]: value });
    }
  }

  const handleMultiChange = (event, name) => {
    const item = Array.isArray(event) ? event[event.length - 1] : "";

    if (name.removedValue || name.removedValues) {
      handleMultiDelete(name, setExludedSelectValue, exludedSelectValue);
    } else if (item) {
      handleMultiAdd(item, setExludedSelectValue, exludedSelectValue);
    } else if (event && event.type) {
      setExludedSelectValue([...exludedSelectValue, event]);
    }
  }

  return (
    <>
      <h5 className="mb-4">Excluded Matching Sources</h5>
      <MultiSelect 
        onChangeHandler={onChangeHandler}
        handleMultiChange={handleMultiChange}
        name="excluded_matching_sources"
        list={EXCLUDED_MATCHING_SOURCES_LIST}
        selectValue={exludedSelectValue}
        styles="react-select-container advanced w-100 mb-3"
        prefixStyles="react-select"
        indicator={false}
      />
    </>
  )
}

export default ExludeEntityModalContent;