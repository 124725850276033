import React, { useState } from 'react';
import MultiSelect from '../UI/MultiSelect';
import { 
  NAICS_LIST, 
  PSC_LIST,
  ZIP_LIST
} from '../../constans';
 
const AddEntityModalContent = ({entity, inputValue, setInputValue, selectValue, setSelectValue, inputName}) => {
  const [list, setList] = useState([]);

  const handleMultiDelete = (name, valuesFunc, valuesValue) => {
    switch (name.action) {
      case 'remove-value':
        valuesFunc([...valuesValue.filter(el => el.value !== name.removedValue.value)]);
        break;
      case 'clear':
        valuesFunc([...valuesValue.filter(el => el.type !== name.name)]);
        break;
      default:
    }
  }
  
  const handleMultiAdd = (item, valuesFunc, valuesValue) => {
    switch (item.type) {
      case 'naics':
      case 'psc_code':
      case 'zip':
        valuesFunc([...valuesValue, item]);
        break;
      default:
        valuesFunc([...valuesValue.filter(i => i.type !== item.type), item]);
    }
  }

  const setLimitSelectValues = (data, value, blackList = null) => {
    let i = 0;

    if (blackList === null) blackList = [];

    const newList = data.filter(item => {
      if (item.label.toLowerCase().includes(value.toLowerCase()) && i < 10) {
        if (!blackList.includes(item.value)) i++;
        return item
      }
    })

    setList(newList);
  }

  const onChangeHandler = (value, name, action) => {
    switch (name) {
      case 'naics':
        setLimitSelectValues(NAICS_LIST, value);
        break;
      case 'psc_code':
        setLimitSelectValues(PSC_LIST, value);
        break;
      case 'zip':
        setLimitSelectValues(ZIP_LIST, value);
        break;
    }

    if (action && action.action === "input-change") {
      setInputValue({ ...inputValue, [name]: value });
    }

    if (!value) setList([]);
  }

  const handleMultiChange = (event, name) => {
    const item = Array.isArray(event) ? event[event.length - 1] : "";

    if (name.removedValue || name.removedValues) {
      handleMultiDelete(name, setSelectValue, selectValue);
    } else if (item) {
      handleMultiAdd(item, setSelectValue, selectValue);
    } else if (event && event.type) {
      setSelectValue([...selectValue, event]);
    }
  }

  return (
    <>
      <h5 className="mb-4">Add {entity}</h5>
      <MultiSelect 
        onChangeHandler={onChangeHandler}
        handleMultiChange={handleMultiChange}
        name={inputName}
        list={list}
        selectValue={selectValue.filter(item => item.type === inputName)}
        styles="react-select-container advanced w-100"
        prefixStyles="react-select"
        indicator={false}
      />
    </>
  )
}

export default AddEntityModalContent;