import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Loading from '../Loading';
import Select from 'components/UI/Select';
import { useHistory } from 'react-router';
import ReportsTable from './ReportsTable';
import { useForm } from 'react-hook-form';
import { TabContext } from '../../context/TabContext';
import { 
  CONTRACT_OPP_SELECT_REPORTS,
  CONTRACT_OPP_INPUT_REPORTS,
  B2B_OPPORTUNITIES_SELECT_REPORTS,
  B2B_OPP_INPUT_REPORTS,
  PURCHASES_SELECT_REPORTS,
  PURCHASES_INPUT_REPORTS,
  BUSINESS_SELECT_REPORTS,
  BUSINESSES_OPTIONS_LIST,
  OFFICER_SELECT_REPORTS,
  OFFICER_INPUT_REPORTS,
  CITY_INSTALLATION_LIST,
  USER_SELECT_REPORTS,
  USER_INPUT_REPORTS,
  NAICS_LIST, 
  CITY_LIST, 
  COUNTY_LIST, 
  SETASIDE_LIST, 
  BUSINESS_TYPE_LIST,
  ZIP_LIST,
  SETASIDE_CODE_LIST,
  OPP_CLASS_LIST,
  ROLES_LIST
} from '../../constans';
import date from 'date-and-time';
import { reportsFields } from '../UI/Notifications';

const DEFAULT_INPUT_VALUES = {
  name: "",
  url: "",
  county: "",
  set_aside: "",
  zip: "",
  type: "",
  keywords: "",
  contract_number: "",
  contract: "",
  registered_in_sam: "",
  env_products: "",
  class_code: "",
  opp_id: "",
  setaside_code: "",
  naics: "",
  deadline: {},
  required_date: {},
  search: "",
  city: "",
  zip_code: "",
  state: "",
  sol_no: "",
  required_date_range: {},
  comment: "",
  installation: "",
  registered: {},
  registration_date: {},
  username: "",
  email: "",
  role_description: ""
}

const Reports = () => {
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState("contract_opportunities_reports");
  const history = useHistory();
  const { register, handleSubmit } = useForm();
  const [list, setList] = useState([]);
  const [inputValue, setInputValue] = useState(DEFAULT_INPUT_VALUES);
  const [selectValue, setSelectValue] = useState([]);
  const qs = require('qs');

  const onChangeCategoryHandler = value => {
    setCategory(value.value);
  }

  const onSubmit = data => {
    setLoading(true);

    const obj = {
      city: [],
      naics: [],
      county: [],
      set_aside: [],
      type: [],
      zip: [],
      setaside_code: [],
      opp_type: [],
      installation: [],
      role_description: []
    };

    selectValue.forEach((item) => {
      obj[item.type].push(item.value);
    })

    const params = {
      ...data,
      ...obj,
      entity: category,
      deadline: inputValue.deadline,
      purchase_date: inputValue.purchase_date,
      required_date_range: inputValue.required_date_range
    }

    for (let prop in params) {
      if (
        params[prop] === null || 
        params[prop] === undefined || 
        params[prop] === "" || 
        params[prop].length === 0 || 
        Object.keys(params[prop]).length === 0
      ) {
        delete params[prop];
      }

      if (params[prop] === 'false') {
        params[prop] = false
      } else if (params[prop] === 'true') {
        params[prop] = true
      }

      if (params[prop] && prop === "keywords" || params[prop] && prop === "purchase_keywords") {
        params[prop] = params[prop].split(',').map(item => item.trim());
      }
    }

    const queryString = qs.stringify(params);
    if (!data.select.length) {
      reportsFields("Reports");
      setLoading(false);
    } else {
      history.push(`/report-result?${queryString}`)
    }
  }

  const handleMultiDelete = (name, valuesFunc, valuesValue) => {
    switch (name.action) {
      case 'remove-value':
        valuesFunc([...valuesValue.filter(el => el.value !== name.removedValue.value)]);
        break;
      case 'clear':
        valuesFunc([...valuesValue.filter(el => el.type !== name.name)]);
        break;
      default:
    }
  }

  const handleMultiAdd = (item, valuesFunc, valuesValue) => {
    switch (item.type) {
      case 'city':
      case 'naics':
      case 'county':
      case 'set_aside':
      case 'setaside_code':
      case 'type':
      case 'zip':
      case 'opp_type':
      case 'installation':
      case 'role_description':
        valuesFunc([...valuesValue, item]);
        break;
      default:
        valuesFunc([...valuesValue.filter(i => i.type !== item.type), item]);
    }
  }

  const setLimitSelectValues = (data, value, blackList = null) => {
    let i = 0;

    if (blackList === null) blackList = [];

    const newList = data.filter(item => {
      if (item.label.toLowerCase().includes(value.toLowerCase()) && i < 10) {
        if (!blackList.includes(item.value)) i++;
        return item
      }
    })

    setList(newList);
  }

  const onChangeHandler = (value, name ,action) => {
    switch (name) {
      case 'city':
        setList(CITY_LIST);
        break;
      case 'county':
        setList(COUNTY_LIST);
        break;
      case 'set_aside':
        setList(SETASIDE_LIST);
        break;
      case 'setaside_code':
        setList(SETASIDE_CODE_LIST);
        break;
      case 'type':
        setList(BUSINESS_TYPE_LIST);
        break;
      case 'opp_type':
        setList(OPP_CLASS_LIST);
        break;
      case 'naics':
        setLimitSelectValues(NAICS_LIST, value);
        break;
      case 'zip':
        setLimitSelectValues(ZIP_LIST, value);
        break;
      case 'installation':
        setList(CITY_INSTALLATION_LIST);
        break;
      case 'role_description':
        setList(ROLES_LIST);
        break;
    }

    if (action && action.action === "input-change") {
      setInputValue({ ...inputValue, [name]: value });
    }

    if (!value) setList([]);
  }
  
  const str2bool = (value) => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
    }
    return value;
  }

  const onChangeSingleValue = (event, name) => {
    setInputValue({
      ...inputValue,
      [name]: str2bool(event.target.value)
    })
  }

  const handleMultiChange = (event, name) => {
    const item = Array.isArray(event) ? event[event.length - 1] : "";

    if (name.removedValue || name.removedValues) {
      handleMultiDelete(name, setSelectValue, selectValue);
    } else if (item) {
      handleMultiAdd(item, setSelectValue, selectValue);
    } else if (event && event.type) {
      setSelectValue([...selectValue, event]);
    }
  }

  const handleTimeCallback = (start, end, name) => {
    const from = date.format(start._d, 'YYYY-MM-DD');
    const to = date.format(end._d, 'YYYY-MM-DD');
   
    setInputValue({
      ...inputValue,
      [name]: {
        from,
        to
      }
    })
  }

  return (
    <section className="section-spaces">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container>
          <Row>
            <Col>
              <h3 className="mb-5">
                Administrative Reports
              </h3>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col lg={6}>
              <fieldset>
                <label htmlFor="category">Please Select Category</label>
                <Select 
                  handleMultiChange={onChangeCategoryHandler}
                  name="category"
                  list={
                    [
                      { value: "contract_opportunities_reports", label: "Contract Opportunities" }, 
                      { value: "b2b_opportunities_reports", label: "B2B Opportunities" }, 
                      { value: "purchases_reports", label: "Local Opportunities" }, 
                      { value: "businesses_reports", label: "Businesses" }, 
                      { value: "contract_officers", label: "Contract Officers" },
                      { value: "users", label: "Users" }
                    ]
                  }
                  styles="react-select-input-container"
                  prefixStyles="react-select-input"
                  indicator={false}
                  isSearchable={false}
                  isClear={false}
                  defaultValue={{ value: "contract_opportunities_reports", label: "Contract Opportunities" }}
                  components={{
                    IndicatorSeparator: () => null
                  }}
                />
              </fieldset>
            </Col>
          </Row>
          <Row>
            <Col>
              <TabContext.Provider value={{
                register,  
                handleMultiChange, 
                onChangeHandler, 
                list, 
                inputValue, 
                selectValue, 
                setInputValue, 
                handleTimeCallback, 
                setSelectValue,
                onChangeSingleValue,
              }}>
                {category === "contract_opportunities_reports" && <ReportsTable register={register} list={CONTRACT_OPP_SELECT_REPORTS} inputList={CONTRACT_OPP_INPUT_REPORTS} />}
                {category === "b2b_opportunities_reports" && <ReportsTable register={register} list={B2B_OPPORTUNITIES_SELECT_REPORTS} inputList={B2B_OPP_INPUT_REPORTS} />}
                {category === "purchases_reports" && <ReportsTable register={register} list={PURCHASES_SELECT_REPORTS} inputList={PURCHASES_INPUT_REPORTS} />}
                {category === "businesses_reports" && <ReportsTable register={register} list={BUSINESS_SELECT_REPORTS} inputList={BUSINESSES_OPTIONS_LIST} />}
                {category === "contract_officers" && <ReportsTable register={register} list={OFFICER_SELECT_REPORTS} inputList={OFFICER_INPUT_REPORTS} />}
                {category === "users" && <ReportsTable register={register} list={USER_SELECT_REPORTS} inputList={USER_INPUT_REPORTS} />}
              </TabContext.Provider>
            </Col>
          </Row>
          <Row>
            <Col className="text-right">
              <button
                className="btnSizes filledBtn"
              >
                Run Report
              </button>
            </Col>
          </Row>
        </Container>
      </form>
      {loading && <Loading />}
    </section>    
  )
}

export default Reports;