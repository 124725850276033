import React, { useState, useRef, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Button from '../UI/Button';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { recoverPassPost, recoverPassGet } from '../../api';
import Loading from '../Loading';
import PasswordInput from "../UI/PasswordInput";
import { successPasswordChangeNotice, invalidKeyNotice, somethingWentWrongNotice, sendAgainPhoneNotice, userNotFoundNotice } from '../UI/Notifications';

const RecoverPassword = () => {
  const [loading, setLoading] = useState(false);
  const [queryData, setQueryData] = useState({});
  const [sendAgain, setSendAgain] = useState(false);
  const { register, handleSubmit, errors, watch } = useForm();
  const password = useRef({});
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const queryParams = {};
  const history = useHistory();
  const querystring = require('querystring');

  password.current = watch("password", "");

  useEffect(() => {
    query.forEach((value, key) => {
      queryParams[key] = value;
    });
    setQueryData(queryParams)
  }, [sendAgain]);

  const onSubmit = (data, e) => {
    setLoading(true);
    e.preventDefault();
    e.target.reset();

    delete data.pwconfirm;

    data.hash_data = queryData.hash_data;
    data.user_id = queryData.user_id;
    if (queryData.token) data.token = queryData.token

    recoverPassPost(data)
      .then(() => {
        successPasswordChangeNotice("Recover Password");
        setLoading(false);
        history.push('/');
      })
      .catch(error => {
        if (error.response.status === 400) {
          invalidKeyNotice("Recover Password");
          setLoading(false);
          history.push('/');
        } else {
          setLoading(false);
          somethingWentWrongNotice("Recover Password");
        }
      })
  };

  const sendAgainHandler = () => {
    setLoading(true);

    const link = `/recover-password/set-password`;
    recoverPassGet({phone_email: queryData.phone, link})
      .then(response => {
        if (response.status === 200) {
          if (!Object.keys(response.data).includes('email')) {
            const queryString = querystring.stringify({...response.data, ...{phone: queryData.phone}});
            sendAgainPhoneNotice();
            setLoading(false);
            history.push(`${link}?${queryString}`)
            setSendAgain(!sendAgain);
          }
        }
      })
      .catch(error => {
        error.responce.status === 404 ?
        userNotFoundNotice() :
        somethingWentWrongNotice("Recover Password");
        setLoading(false);
      })
  }

  return (
    <div className="form-wrap">
      <h2 className="text-center mb-5">Please enter new password.</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <label htmlFor="pwconfirm">New Password <span>*</span></label>
          <PasswordInput 
            name="password"
            type="password"
            register={register({ 
              required: "Password is required", 
              pattern: {
                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9])[A-Za-z\d@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*.{8,}$/, 
                message: "Password must contain at least 8 characters, 1 uppercase, 1 lowercase, 1 number, and 1 special character"
              }
            })}
            label="Password"
          />
          { errors.password && <p className="warnMessage">{errors.password.message}</p> }
        </fieldset>
        <fieldset>
          <label htmlFor="pwconfirm">Repeat Password <span>*</span></label>
          <input
            id="pwconfirm"
            name="pwconfirm"
            type="password"
            ref={register({
              validate: value =>
                value === password.current || "The passwords do not match"
            })}
          />
          {errors.pwconfirm && <p className="warnMessage">{errors.pwconfirm.message}</p>}
        </fieldset>
        {queryData.phone && (
          <fieldset>
           <label htmlFor="f">Token</label>
           <input
             id="token"
             name="token"
             type="text"
             ref={register({ required: true })}
           />
            {errors.token && 
              <p className="warnMessage">This field is required</p>
            }
         </fieldset>
        )}
        {loading && <Loading />}
        <Row>
          <Col className="text-right">
            {queryData.phone && (
              <span
                className="cancelBtn mr-5"
                onClick={sendAgainHandler}
                >
                Send Again
              </span>
            )}
            <Button 
              type="submit"
              label="Submit"
            />
          </Col>
        </Row>
      </form>
    </div>
  )
}

export default RecoverPassword;