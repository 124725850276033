export const NAICS_LIST = [
   {
      "type": "naics",
      "value": "111110",
      "label": "111110 - Soybean Farming"
   },
   {
      "type": "naics",
      "value": "111120",
      "label": "111120 - Oilseed (except Soybean) Farming"
   },
   {
      "type": "naics",
      "value": "111130",
      "label": "111130 - Dry Pea and Bean Farming"
   },
   {
      "type": "naics",
      "value": "111140",
      "label": "111140 - Wheat Farming"
   },
   {
      "type": "naics",
      "value": "111150",
      "label": "111150 - Corn Farming"
   },
   {
      "type": "naics",
      "value": "111160",
      "label": "111160 - Rice Farming"
   },
   {
      "type": "naics",
      "value": "111191",
      "label": "111191 - Oilseed and Grain Combination Farming"
   },
   {
      "type": "naics",
      "value": "111199",
      "label": "111199 - All Other Grain Farming"
   },
   {
      "type": "naics",
      "value": "111211",
      "label": "111211 - Potato Farming"
   },
   {
      "type": "naics",
      "value": "111219",
      "label": "111219 - Other Vegetable (except Potato) and Melon Farming"
   },
   {
      "type": "naics",
      "value": "111310",
      "label": "111310 - Orange Groves"
   },
   {
      "type": "naics",
      "value": "111320",
      "label": "111320 - Citrus (except Orange) Groves"
   },
   {
      "type": "naics",
      "value": "111331",
      "label": "111331 - Apple Orchards"
   },
   {
      "type": "naics",
      "value": "111332",
      "label": "111332 - Grape Vineyards"
   },
   {
      "type": "naics",
      "value": "111333",
      "label": "111333 - Strawberry Farming"
   },
   {
      "type": "naics",
      "value": "111334",
      "label": "111334 - Berry (except Strawberry) Farming"
   },
   {
      "type": "naics",
      "value": "111335",
      "label": "111335 - Tree Nut Farming"
   },
   {
      "type": "naics",
      "value": "111336",
      "label": "111336 - Fruit and Tree Nut Combination Farming"
   },
   {
      "type": "naics",
      "value": "111339",
      "label": "111339 - Other Noncitrus Fruit Farming"
   },
   {
      "type": "naics",
      "value": "111411",
      "label": "111411 - Mushroom Production"
   },
   {
      "type": "naics",
      "value": "111419",
      "label": "111419 - Other Food Crops Grown Under Cover"
   },
   {
      "type": "naics",
      "value": "111421",
      "label": "111421 - Nursery and Tree Production"
   },
   {
      "type": "naics",
      "value": "111422",
      "label": "111422 - Floriculture Production"
   },
   {
      "type": "naics",
      "value": "111910",
      "label": "111910 - Tobacco Farming"
   },
   {
      "type": "naics",
      "value": "111920",
      "label": "111920 - Cotton Farming"
   },
   {
      "type": "naics",
      "value": "111930",
      "label": "111930 - Sugarcane Farming"
   },
   {
      "type": "naics",
      "value": "111940",
      "label": "111940 - Hay Farming"
   },
   {
      "type": "naics",
      "value": "111991",
      "label": "111991 - Sugar Beet Farming"
   },
   {
      "type": "naics",
      "value": "111992",
      "label": "111992 - Peanut Farming"
   },
   {
      "type": "naics",
      "value": "111998",
      "label": "111998 - All Other Miscellaneous Crop Farming"
   },
   {
      "type": "naics",
      "value": "112111",
      "label": "112111 - Beef Cattle Ranching and Farming"
   },
   {
      "type": "naics",
      "value": "112112",
      "label": "112112 - Cattle Feedlots"
   },
   {
      "type": "naics",
      "value": "112120",
      "label": "112120 - Dairy Cattle and Milk Production"
   },
   {
      "type": "naics",
      "value": "112130",
      "label": "112130 - Dual-Purpose Cattle Ranching and Farming"
   },
   {
      "type": "naics",
      "value": "112210",
      "label": "112210 - Hog and Pig Farming"
   },
   {
      "type": "naics",
      "value": "112310",
      "label": "112310 - Chicken Egg Production"
   },
   {
      "type": "naics",
      "value": "112320",
      "label": "112320 - Broilers and Other Meat Type Chicken Production"
   },
   {
      "type": "naics",
      "value": "112330",
      "label": "112330 - Turkey Production"
   },
   {
      "type": "naics",
      "value": "112340",
      "label": "112340 - Poultry Hatcheries"
   },
   {
      "type": "naics",
      "value": "112390",
      "label": "112390 - Other Poultry Production"
   },
   {
      "type": "naics",
      "value": "112410",
      "label": "112410 - Sheep Farming"
   },
   {
      "type": "naics",
      "value": "112420",
      "label": "112420 - Goat Farming"
   },
   {
      "type": "naics",
      "value": "112511",
      "label": "112511 - Finfish Farming and Fish Hatcheries"
   },
   {
      "type": "naics",
      "value": "112512",
      "label": "112512 - Shellfish Farming"
   },
   {
      "type": "naics",
      "value": "112519",
      "label": "112519 - Other Animal Aquaculture"
   },
   {
      "type": "naics",
      "value": "112910",
      "label": "112910 - Apiculture"
   },
   {
      "type": "naics",
      "value": "112920",
      "label": "112920 - Horses and Other Equine Production"
   },
   {
      "type": "naics",
      "value": "112930",
      "label": "112930 - Fur-Bearing Animal and Rabbit Production"
   },
   {
      "type": "naics",
      "value": "112990",
      "label": "112990 - All Other Animal Production"
   },
   {
      "type": "naics",
      "value": "113110",
      "label": "113110 - Timber Tract Operations"
   },
   {
      "type": "naics",
      "value": "113210",
      "label": "113210 - Forest Nurseries and Gathering of Forest Products"
   },
   {
      "type": "naics",
      "value": "113310",
      "label": "113310 - Logging"
   },
   {
      "type": "naics",
      "value": "114111",
      "label": "114111 - Finfish Fishing"
   },
   {
      "type": "naics",
      "value": "114112",
      "label": "114112 - Shellfish Fishing"
   },
   {
      "type": "naics",
      "value": "114119",
      "label": "114119 - Other Marine Fishing"
   },
   {
      "type": "naics",
      "value": "114210",
      "label": "114210 - Hunting and Trapping"
   },
   {
      "type": "naics",
      "value": "115111",
      "label": "115111 - Cotton Ginning"
   },
   {
      "type": "naics",
      "value": "115112",
      "label": "115112 - Soil Preparation"
   },
   {
      "type": "naics",
      "value": "115113",
      "label": "115113 - Crop Harvesting"
   },
   {
      "type": "naics",
      "value": "115114",
      "label": "115114 - Postharvest Crop Activities (except Cotton Ginning)"
   },
   {
      "type": "naics",
      "value": "115115",
      "label": "115115 - Farm Labor Contractors and Crew Leaders"
   },
   {
      "type": "naics",
      "value": "115116",
      "label": "115116 - Farm Management Services"
   },
   {
      "type": "naics",
      "value": "115210",
      "label": "115210 - Support Activities for Animal Production"
   },
   {
      "type": "naics",
      "value": "115310",
      "label": "115310 - Support Activities for Forestry"
   },
   {
      "type": "naics",
      "value": "211111",
      "label": "211111 - Crude Petroleum and Natural Gas Extraction"
   },
   {
      "type": "naics",
      "value": "211112",
      "label": "211112 - Natural Gas Liquid Extraction"
   },
   {
      "type": "naics",
      "value": "212111",
      "label": "212111 - Bituminous Coal and Lignite Surface Mining"
   },
   {
      "type": "naics",
      "value": "212112",
      "label": "212112 - Bituminous Coal Underground Mining"
   },
   {
      "type": "naics",
      "value": "212113",
      "label": "212113 - Anthracite Mining"
   },
   {
      "type": "naics",
      "value": "212210",
      "label": "212210 - Iron Ore Mining"
   },
   {
      "type": "naics",
      "value": "212221",
      "label": "212221 - Gold Ore Mining"
   },
   {
      "type": "naics",
      "value": "212222",
      "label": "212222 - Silver Ore Mining"
   },
   {
      "type": "naics",
      "value": "212231",
      "label": "212231 - Lead Ore and Zinc Ore Mining"
   },
   {
      "type": "naics",
      "value": "212234",
      "label": "212234 - Copper Ore and Nickel Ore Mining"
   },
   {
      "type": "naics",
      "value": "212291",
      "label": "212291 - Uranium-Radium-Vanadium Ore Mining"
   },
   {
      "type": "naics",
      "value": "212299",
      "label": "212299 - All Other Metal Ore Mining"
   },
   {
      "type": "naics",
      "value": "212311",
      "label": "212311 - Dimension Stone Mining and Quarrying"
   },
   {
      "type": "naics",
      "value": "212312",
      "label": "212312 - Crushed and Broken Limestone Mining and Quarrying"
   },
   {
      "type": "naics",
      "value": "212313",
      "label": "212313 - Crushed and Broken Granite Mining and Quarrying"
   },
   {
      "type": "naics",
      "value": "212319",
      "label": "212319 - Other Crushed and Broken Stone Mining and Quarrying"
   },
   {
      "type": "naics",
      "value": "212321",
      "label": "212321 - Construction Sand and Gravel Mining"
   },
   {
      "type": "naics",
      "value": "212322",
      "label": "212322 - Industrial Sand Mining"
   },
   {
      "type": "naics",
      "value": "212324",
      "label": "212324 - Kaolin and Ball Clay Mining"
   },
   {
      "type": "naics",
      "value": "212325",
      "label": "212325 - Clay and Ceramic and Refractory Minerals Mining"
   },
   {
      "type": "naics",
      "value": "212391",
      "label": "212391 - Potash"
   },
   {
      "type": "naics",
      "value": "212392",
      "label": "212392 - Phosphate Rock Mining"
   },
   {
      "type": "naics",
      "value": "212393",
      "label": "212393 - Other Chemical and Fertilizer Mineral Mining"
   },
   {
      "type": "naics",
      "value": "212399",
      "label": "212399 - All Other Nonmetallic Mineral Mining"
   },
   {
      "type": "naics",
      "value": "213111",
      "label": "213111 - Drilling Oil and Gas Wells"
   },
   {
      "type": "naics",
      "value": "213112",
      "label": "213112 - Support Activities for Oil and Gas Operations"
   },
   {
      "type": "naics",
      "value": "213113",
      "label": "213113 - Support Activities for Coal Mining"
   },
   {
      "type": "naics",
      "value": "213114",
      "label": "213114 - Support Activities for Metal Mining"
   },
   {
      "type": "naics",
      "value": "213115",
      "label": "213115 - Support Activities for Nonmetallic Minerals (except Fuels)"
   },
   {
      "type": "naics",
      "value": "221111",
      "label": "221111 - Hydroelectric Power Generation"
   },
   {
      "type": "naics",
      "value": "221112",
      "label": "221112 - Fossil Fuel Electric Power Generation"
   },
   {
      "type": "naics",
      "value": "221113",
      "label": "221113 - Nuclear Electric Power Generation"
   },
   {
      "type": "naics",
      "value": "221119",
      "label": "221119 - Other Electric Power Generation"
   },
   {
      "type": "naics",
      "value": "221121",
      "label": "221121 - Electric Bulk Power Transmission and Control"
   },
   {
      "type": "naics",
      "value": "221122",
      "label": "221122 - Electric Power Distribution"
   },
   {
      "type": "naics",
      "value": "221210",
      "label": "221210 - Natural Gas Distribution"
   },
   {
      "type": "naics",
      "value": "221310",
      "label": "221310 - Water Supply and Irrigation Systems"
   },
   {
      "type": "naics",
      "value": "221320",
      "label": "221320 - Sewage Treatment Facilities"
   },
   {
      "type": "naics",
      "value": "221330",
      "label": "221330 - Steam and Air-Conditioning Supply"
   },
   {
      "type": "naics",
      "value": "236115",
      "label": "236115 - New Single-Family Housing Construction (except Operative Builders)"
   },
   {
      "type": "naics",
      "value": "236116",
      "label": "236116 - New Multifamily Housing Construction (except Operative Builders)"
   },
   {
      "type": "naics",
      "value": "236117",
      "label": "236117 - New Housing Operative Builders"
   },
   {
      "type": "naics",
      "value": "236118",
      "label": "236118 - Residential Remodelers"
   },
   {
      "type": "naics",
      "value": "236210",
      "label": "236210 - Industrial Building Construction"
   },
   {
      "type": "naics",
      "value": "236220",
      "label": "236220 - Commercial and Institutional Building Construction"
   },
   {
      "type": "naics",
      "value": "237110",
      "label": "237110 - Water and Sewer Line and Related Structures Construction"
   },
   {
      "type": "naics",
      "value": "237120",
      "label": "237120 - Oil and Gas Pipeline and Related Structures Construction"
   },
   {
      "type": "naics",
      "value": "237130",
      "label": "237130 - Power and Communication Line and Related Structures Construction"
   },
   {
      "type": "naics",
      "value": "237210",
      "label": "237210 - Land Subdivision"
   },
   {
      "type": "naics",
      "value": "237310",
      "label": "237310 - Highway"
   },
   {
      "type": "naics",
      "value": "237990",
      "label": "237990 - Other Heavy and Civil Engineering Construction"
   },
   {
      "type": "naics",
      "value": "238110",
      "label": "238110 - Poured Concrete Foundation and Structure Contractors"
   },
   {
      "type": "naics",
      "value": "238120",
      "label": "238120 - Structural Steel and Precast Concrete Contractors"
   },
   {
      "type": "naics",
      "value": "238130",
      "label": "238130 - Framing Contractors"
   },
   {
      "type": "naics",
      "value": "238140",
      "label": "238140 - Masonry Contractors"
   },
   {
      "type": "naics",
      "value": "238150",
      "label": "238150 - Glass and Glazing Contractors"
   },
   {
      "type": "naics",
      "value": "238160",
      "label": "238160 - Roofing Contractors"
   },
   {
      "type": "naics",
      "value": "238170",
      "label": "238170 - Siding Contractors"
   },
   {
      "type": "naics",
      "value": "238190",
      "label": "238190 - Other Foundation"
   },
   {
      "type": "naics",
      "value": "238210",
      "label": "238210 - Electrical Contractors"
   },
   {
      "type": "naics",
      "value": "238220",
      "label": "238220 - Plumbing"
   },
   {
      "type": "naics",
      "value": "238290",
      "label": "238290 - Other Building Equipment Contractors"
   },
   {
      "type": "naics",
      "value": "238310",
      "label": "238310 - Drywall and Insulation Contractors"
   },
   {
      "type": "naics",
      "value": "238320",
      "label": "238320 - Painting and Wall Covering Contractors"
   },
   {
      "type": "naics",
      "value": "238330",
      "label": "238330 - Flooring Contractors"
   },
   {
      "type": "naics",
      "value": "238340",
      "label": "238340 - Tile and Terrazzo Contractors"
   },
   {
      "type": "naics",
      "value": "238350",
      "label": "238350 - Finish Carpentry Contractors"
   },
   {
      "type": "naics",
      "value": "238390",
      "label": "238390 - Other Building Finishing Contractors"
   },
   {
      "type": "naics",
      "value": "238910",
      "label": "238910 - Site Preparation Contractors"
   },
   {
      "type": "naics",
      "value": "238990",
      "label": "238990 - All Other Specialty Trade Contractors"
   },
   {
      "type": "naics",
      "value": "311111",
      "label": "311111 - Dog and Cat Food Manufacturing"
   },
   {
      "type": "naics",
      "value": "311119",
      "label": "311119 - Other Animal Food Manufacturing"
   },
   {
      "type": "naics",
      "value": "311211",
      "label": "311211 - Flour Milling"
   },
   {
      "type": "naics",
      "value": "311212",
      "label": "311212 - Rice Milling"
   },
   {
      "type": "naics",
      "value": "311213",
      "label": "311213 - Malt Manufacturing"
   },
   {
      "type": "naics",
      "value": "311221",
      "label": "311221 - Wet Corn Milling"
   },
   {
      "type": "naics",
      "value": "311222",
      "label": "311222 - Soybean Processing"
   },
   {
      "type": "naics",
      "value": "311223",
      "label": "311223 - Other Oilseed Processing"
   },
   {
      "type": "naics",
      "value": "311225",
      "label": "311225 - Fats and Oils Refining and Blending"
   },
   {
      "type": "naics",
      "value": "311230",
      "label": "311230 - Breakfast Cereal Manufacturing"
   },
   {
      "type": "naics",
      "value": "311311",
      "label": "311311 - Sugarcane Mills"
   },
   {
      "type": "naics",
      "value": "311312",
      "label": "311312 - Cane Sugar Refining"
   },
   {
      "type": "naics",
      "value": "311313",
      "label": "311313 - Beet Sugar Manufacturing"
   },
   {
      "type": "naics",
      "value": "311320",
      "label": "311320 - Chocolate and Confectionery Manufacturing from Cacao Beans"
   },
   {
      "type": "naics",
      "value": "311330",
      "label": "311330 - Confectionery Manufacturing from Purchased Chocolate"
   },
   {
      "type": "naics",
      "value": "311340",
      "label": "311340 - Nonchocolate Confectionery Manufacturing"
   },
   {
      "type": "naics",
      "value": "311411",
      "label": "311411 - Frozen Fruit"
   },
   {
      "type": "naics",
      "value": "311412",
      "label": "311412 - Frozen Specialty Food Manufacturing"
   },
   {
      "type": "naics",
      "value": "311421",
      "label": "311421 - Fruit and Vegetable Canning"
   },
   {
      "type": "naics",
      "value": "311422",
      "label": "311422 - Specialty Canning"
   },
   {
      "type": "naics",
      "value": "311423",
      "label": "311423 - Dried and Dehydrated Food Manufacturing"
   },
   {
      "type": "naics",
      "value": "311511",
      "label": "311511 - Fluid Milk Manufacturing"
   },
   {
      "type": "naics",
      "value": "311512",
      "label": "311512 - Creamery Butter Manufacturing"
   },
   {
      "type": "naics",
      "value": "311513",
      "label": "311513 - Cheese Manufacturing"
   },
   {
      "type": "naics",
      "value": "311514",
      "label": "311514 - Dry"
   },
   {
      "type": "naics",
      "value": "311520",
      "label": "311520 - Ice Cream and Frozen Dessert Manufacturing"
   },
   {
      "type": "naics",
      "value": "311611",
      "label": "311611 - Animal (except Poultry) Slaughtering"
   },
   {
      "type": "naics",
      "value": "311612",
      "label": "311612 - Meat Processed from Carcasses"
   },
   {
      "type": "naics",
      "value": "311613",
      "label": "311613 - Rendering and Meat Byproduct Processing"
   },
   {
      "type": "naics",
      "value": "311615",
      "label": "311615 - Poultry Processing"
   },
   {
      "type": "naics",
      "value": "311711",
      "label": "311711 - Seafood Canning"
   },
   {
      "type": "naics",
      "value": "311712",
      "label": "311712 - Fresh and Frozen Seafood Processing"
   },
   {
      "type": "naics",
      "value": "311811",
      "label": "311811 - Retail Bakeries"
   },
   {
      "type": "naics",
      "value": "311812",
      "label": "311812 - Commercial Bakeries"
   },
   {
      "type": "naics",
      "value": "311813",
      "label": "311813 - Frozen Cakes"
   },
   {
      "type": "naics",
      "value": "311821",
      "label": "311821 - Cookie and Cracker Manufacturing"
   },
   {
      "type": "naics",
      "value": "311822",
      "label": "311822 - Flour Mixes and Dough Manufacturing from Purchased Flour"
   },
   {
      "type": "naics",
      "value": "311823",
      "label": "311823 - Dry Pasta Manufacturing"
   },
   {
      "type": "naics",
      "value": "311830",
      "label": "311830 - Tortilla Manufacturing"
   },
   {
      "type": "naics",
      "value": "311911",
      "label": "311911 - Roasted Nuts and Peanut Butter Manufacturing"
   },
   {
      "type": "naics",
      "value": "311919",
      "label": "311919 - Other Snack Food Manufacturing"
   },
   {
      "type": "naics",
      "value": "311920",
      "label": "311920 - Coffee and Tea Manufacturing"
   },
   {
      "type": "naics",
      "value": "311930",
      "label": "311930 - Flavoring Syrup and Concentrate Manufacturing"
   },
   {
      "type": "naics",
      "value": "311941",
      "label": "311941 - Mayonnaise"
   },
   {
      "type": "naics",
      "value": "311942",
      "label": "311942 - Spice and Extract Manufacturing"
   },
   {
      "type": "naics",
      "value": "311991",
      "label": "311991 - Perishable Prepared Food Manufacturing"
   },
   {
      "type": "naics",
      "value": "311999",
      "label": "311999 - All Other Miscellaneous Food Manufacturing"
   },
   {
      "type": "naics",
      "value": "312111",
      "label": "312111 - Soft Drink Manufacturing"
   },
   {
      "type": "naics",
      "value": "312112",
      "label": "312112 - Bottled Water Manufacturing"
   },
   {
      "type": "naics",
      "value": "312113",
      "label": "312113 - Ice Manufacturing"
   },
   {
      "type": "naics",
      "value": "312120",
      "label": "312120 - Breweries"
   },
   {
      "type": "naics",
      "value": "312130",
      "label": "312130 - Wineries"
   },
   {
      "type": "naics",
      "value": "312140",
      "label": "312140 - Distilleries"
   },
   {
      "type": "naics",
      "value": "312210",
      "label": "312210 - Tobacco Stemming and Redrying"
   },
   {
      "type": "naics",
      "value": "312221",
      "label": "312221 - Cigarette Manufacturing"
   },
   {
      "type": "naics",
      "value": "312229",
      "label": "312229 - Other Tobacco Product Manufacturing"
   },
   {
      "type": "naics",
      "value": "313111",
      "label": "313111 - Yarn Spinning Mills"
   },
   {
      "type": "naics",
      "value": "313112",
      "label": "313112 - Yarn Texturizing"
   },
   {
      "type": "naics",
      "value": "313113",
      "label": "313113 - Thread Mills"
   },
   {
      "type": "naics",
      "value": "313210",
      "label": "313210 - Broadwoven Fabric Mills"
   },
   {
      "type": "naics",
      "value": "313221",
      "label": "313221 - Narrow Fabric Mills"
   },
   {
      "type": "naics",
      "value": "313222",
      "label": "313222 - Schiffli Machine Embroidery"
   },
   {
      "type": "naics",
      "value": "313230",
      "label": "313230 - Nonwoven Fabric Mills"
   },
   {
      "type": "naics",
      "value": "313241",
      "label": "313241 - Weft Knit Fabric Mills"
   },
   {
      "type": "naics",
      "value": "313249",
      "label": "313249 - Other Knit Fabric and Lace Mills"
   },
   {
      "type": "naics",
      "value": "313311",
      "label": "313311 - Broadwoven Fabric Finishing Mills"
   },
   {
      "type": "naics",
      "value": "313312",
      "label": "313312 - Textile and Fabric Finishing (except Broadwoven Fabric) Mills"
   },
   {
      "type": "naics",
      "value": "313320",
      "label": "313320 - Fabric Coating Mills"
   },
   {
      "type": "naics",
      "value": "314110",
      "label": "314110 - Carpet and Rug Mills"
   },
   {
      "type": "naics",
      "value": "314121",
      "label": "314121 - Curtain and Drapery Mills"
   },
   {
      "type": "naics",
      "value": "314129",
      "label": "314129 - Other Household Textile Product Mills"
   },
   {
      "type": "naics",
      "value": "314911",
      "label": "314911 - Textile Bag Mills"
   },
   {
      "type": "naics",
      "value": "314912",
      "label": "314912 - Canvas and Related Product Mills"
   },
   {
      "type": "naics",
      "value": "314991",
      "label": "314991 - Rope"
   },
   {
      "type": "naics",
      "value": "314992",
      "label": "314992 - Tire Cord and Tire Fabric Mills"
   },
   {
      "type": "naics",
      "value": "314999",
      "label": "314999 - All Other Miscellaneous Textile Product Mills"
   },
   {
      "type": "naics",
      "value": "315111",
      "label": "315111 - Sheer Hosiery Mills"
   },
   {
      "type": "naics",
      "value": "315119",
      "label": "315119 - Other Hosiery and Sock Mills"
   },
   {
      "type": "naics",
      "value": "315191",
      "label": "315191 - Outerwear Knitting Mills"
   },
   {
      "type": "naics",
      "value": "315192",
      "label": "315192 - Underwear and Nightwear Knitting Mills"
   },
   {
      "type": "naics",
      "value": "315211",
      "label": "315211 - Men's and Boys' Cut and Sew Apparel Contractors"
   },
   {
      "type": "naics",
      "value": "315212",
      "label": "315212 - Women's"
   },
   {
      "type": "naics",
      "value": "315221",
      "label": "315221 - Men's and Boys' Cut and Sew Underwear and Nightwear Manufacturing"
   },
   {
      "type": "naics",
      "value": "315222",
      "label": "315222 - Men's and Boys' Cut and Sew Suit"
   },
   {
      "type": "naics",
      "value": "315223",
      "label": "315223 - Men's and Boys' Cut and Sew Shirt (except Work Shirt) Manufacturing"
   },
   {
      "type": "naics",
      "value": "315224",
      "label": "315224 - Men's and Boys' Cut and Sew Trouser"
   },
   {
      "type": "naics",
      "value": "315225",
      "label": "315225 - Men's and Boys' Cut and Sew Work Clothing Manufacturing"
   },
   {
      "type": "naics",
      "value": "315228",
      "label": "315228 - Men's and Boys' Cut and Sew Other Outerwear Manufacturing"
   },
   {
      "type": "naics",
      "value": "315231",
      "label": "315231 - Women's and Girls' Cut and Sew Lingerie"
   },
   {
      "type": "naics",
      "value": "315232",
      "label": "315232 - Women's and Girls' Cut and Sew Blouse and Shirt Manufacturing"
   },
   {
      "type": "naics",
      "value": "315233",
      "label": "315233 - Women's and Girls' Cut and Sew Dress Manufacturing"
   },
   {
      "type": "naics",
      "value": "315234",
      "label": "315234 - Women's and Girls' Cut and Sew Suit"
   },
   {
      "type": "naics",
      "value": "315239",
      "label": "315239 - Women's and Girls' Cut and Sew Other Outerwear Manufacturing"
   },
   {
      "type": "naics",
      "value": "315291",
      "label": "315291 - Infants' Cut and Sew Apparel Manufacturing"
   },
   {
      "type": "naics",
      "value": "315292",
      "label": "315292 - Fur and Leather Apparel Manufacturing"
   },
   {
      "type": "naics",
      "value": "315299",
      "label": "315299 - All Other Cut and Sew Apparel Manufacturing"
   },
   {
      "type": "naics",
      "value": "315991",
      "label": "315991 - Hat"
   },
   {
      "type": "naics",
      "value": "315992",
      "label": "315992 - Glove and Mitten Manufacturing"
   },
   {
      "type": "naics",
      "value": "315993",
      "label": "315993 - Men's and Boys' Neckwear Manufacturing"
   },
   {
      "type": "naics",
      "value": "315999",
      "label": "315999 - Other Apparel Accessories and Other Apparel Manufacturing"
   },
   {
      "type": "naics",
      "value": "316110",
      "label": "316110 - Leather and Hide Tanning and Finishing"
   },
   {
      "type": "naics",
      "value": "316211",
      "label": "316211 - Rubber and Plastics Footwear Manufacturing"
   },
   {
      "type": "naics",
      "value": "316212",
      "label": "316212 - House Slipper Manufacturing"
   },
   {
      "type": "naics",
      "value": "316213",
      "label": "316213 - Men's Footwear (except Athletic) Manufacturing"
   },
   {
      "type": "naics",
      "value": "316214",
      "label": "316214 - Women's Footwear (except Athletic) Manufacturing"
   },
   {
      "type": "naics",
      "value": "316219",
      "label": "316219 - Other Footwear Manufacturing"
   },
   {
      "type": "naics",
      "value": "316991",
      "label": "316991 - Luggage Manufacturing"
   },
   {
      "type": "naics",
      "value": "316992",
      "label": "316992 - Women's Handbag and Purse Manufacturing"
   },
   {
      "type": "naics",
      "value": "316993",
      "label": "316993 - Personal Leather Good (except Women's Handbag and Purse) Manufacturing"
   },
   {
      "type": "naics",
      "value": "316999",
      "label": "316999 - All Other Leather Good Manufacturing"
   },
   {
      "type": "naics",
      "value": "321113",
      "label": "321113 - Sawmills"
   },
   {
      "type": "naics",
      "value": "321114",
      "label": "321114 - Wood Preservation"
   },
   {
      "type": "naics",
      "value": "321211",
      "label": "321211 - Hardwood Veneer and Plywood Manufacturing"
   },
   {
      "type": "naics",
      "value": "321212",
      "label": "321212 - Softwood Veneer and Plywood Manufacturing"
   },
   {
      "type": "naics",
      "value": "321213",
      "label": "321213 - Engineered Wood Member (except Truss) Manufacturing"
   },
   {
      "type": "naics",
      "value": "321214",
      "label": "321214 - Truss Manufacturing"
   },
   {
      "type": "naics",
      "value": "321219",
      "label": "321219 - Reconstituted Wood Product Manufacturing"
   },
   {
      "type": "naics",
      "value": "321911",
      "label": "321911 - Wood Window and Door Manufacturing"
   },
   {
      "type": "naics",
      "value": "321912",
      "label": "321912 - Cut Stock"
   },
   {
      "type": "naics",
      "value": "321918",
      "label": "321918 - Other Millwork (including Flooring)"
   },
   {
      "type": "naics",
      "value": "321920",
      "label": "321920 - Wood Container and Pallet Manufacturing"
   },
   {
      "type": "naics",
      "value": "321991",
      "label": "321991 - Manufactured Home (Mobile Home) Manufacturing"
   },
   {
      "type": "naics",
      "value": "321992",
      "label": "321992 - Prefabricated Wood Building Manufacturing"
   },
   {
      "type": "naics",
      "value": "321999",
      "label": "321999 - All Other Miscellaneous Wood Product Manufacturing"
   },
   {
      "type": "naics",
      "value": "322110",
      "label": "322110 - Pulp Mills"
   },
   {
      "type": "naics",
      "value": "322121",
      "label": "322121 - Paper (except Newsprint) Mills"
   },
   {
      "type": "naics",
      "value": "322122",
      "label": "322122 - Newsprint Mills"
   },
   {
      "type": "naics",
      "value": "322130",
      "label": "322130 - Paperboard Mills"
   },
   {
      "type": "naics",
      "value": "322211",
      "label": "322211 - Corrugated and Solid Fiber Box Manufacturing"
   },
   {
      "type": "naics",
      "value": "322212",
      "label": "322212 - Folding Paperboard Box Manufacturing"
   },
   {
      "type": "naics",
      "value": "322213",
      "label": "322213 - Setup Paperboard Box Manufacturing"
   },
   {
      "type": "naics",
      "value": "322214",
      "label": "322214 - Fiber Can"
   },
   {
      "type": "naics",
      "value": "322215",
      "label": "322215 - Nonfolding Sanitary Food Container Manufacturing"
   },
   {
      "type": "naics",
      "value": "322221",
      "label": "322221 - Coated and Laminated Packaging Paper and Plastics Film Manufacturing"
   },
   {
      "type": "naics",
      "value": "322222",
      "label": "322222 - Coated and Laminated Paper Manufacturing"
   },
   {
      "type": "naics",
      "value": "322223",
      "label": "322223 - Plastics"
   },
   {
      "type": "naics",
      "value": "322224",
      "label": "322224 - Uncoated Paper and Multiwall Bag Manufacturing"
   },
   {
      "type": "naics",
      "value": "322225",
      "label": "322225 - Laminated Aluminum Foil Manufacturing for Flexible Packaging Uses"
   },
   {
      "type": "naics",
      "value": "322226",
      "label": "322226 - Surface-Coated Paperboard Manufacturing"
   },
   {
      "type": "naics",
      "value": "322231",
      "label": "322231 - Die-Cut Paper and Paperboard Office Supplies Manufacturing"
   },
   {
      "type": "naics",
      "value": "322232",
      "label": "322232 - Envelope Manufacturing"
   },
   {
      "type": "naics",
      "value": "322233",
      "label": "322233 - Stationery"
   },
   {
      "type": "naics",
      "value": "322291",
      "label": "322291 - Sanitary Paper Product Manufacturing"
   },
   {
      "type": "naics",
      "value": "322299",
      "label": "322299 - All Other Converted Paper Product Manufacturing"
   },
   {
      "type": "naics",
      "value": "323110",
      "label": "323110 - Commercial Lithographic Printing"
   },
   {
      "type": "naics",
      "value": "323111",
      "label": "323111 - Commercial Gravure Printing"
   },
   {
      "type": "naics",
      "value": "323112",
      "label": "323112 - Commercial Flexographic Printing"
   },
   {
      "type": "naics",
      "value": "323113",
      "label": "323113 - Commercial Screen Printing"
   },
   {
      "type": "naics",
      "value": "323114",
      "label": "323114 - Quick Printing"
   },
   {
      "type": "naics",
      "value": "323115",
      "label": "323115 - Digital Printing"
   },
   {
      "type": "naics",
      "value": "323116",
      "label": "323116 - Manifold Business Forms Printing"
   },
   {
      "type": "naics",
      "value": "323117",
      "label": "323117 - Books Printing"
   },
   {
      "type": "naics",
      "value": "323118",
      "label": "323118 - Blankbook"
   },
   {
      "type": "naics",
      "value": "323119",
      "label": "323119 - Other Commercial Printing"
   },
   {
      "type": "naics",
      "value": "323121",
      "label": "323121 - Tradebinding and Related Work"
   },
   {
      "type": "naics",
      "value": "323122",
      "label": "323122 - Prepress Services"
   },
   {
      "type": "naics",
      "value": "324110",
      "label": "324110 - Petroleum Refineries"
   },
   {
      "type": "naics",
      "value": "324121",
      "label": "324121 - Asphalt Paving Mixture and Block Manufacturing"
   },
   {
      "type": "naics",
      "value": "324122",
      "label": "324122 - Asphalt Shingle and Coating Materials Manufacturing"
   },
   {
      "type": "naics",
      "value": "324191",
      "label": "324191 - Petroleum Lubricating Oil and Grease Manufacturing"
   },
   {
      "type": "naics",
      "value": "324199",
      "label": "324199 - All Other Petroleum and Coal Products Manufacturing"
   },
   {
      "type": "naics",
      "value": "325110",
      "label": "325110 - Petrochemical Manufacturing"
   },
   {
      "type": "naics",
      "value": "325120",
      "label": "325120 - Industrial Gas Manufacturing"
   },
   {
      "type": "naics",
      "value": "325131",
      "label": "325131 - Inorganic Dye and Pigment Manufacturing"
   },
   {
      "type": "naics",
      "value": "325132",
      "label": "325132 - Synthetic Organic Dye and Pigment Manufacturing"
   },
   {
      "type": "naics",
      "value": "325181",
      "label": "325181 - Alkalies and Chlorine Manufacturing"
   },
   {
      "type": "naics",
      "value": "325182",
      "label": "325182 - Carbon Black Manufacturing"
   },
   {
      "type": "naics",
      "value": "325188",
      "label": "325188 - All Other Basic Inorganic Chemical Manufacturing"
   },
   {
      "type": "naics",
      "value": "325191",
      "label": "325191 - Gum and Wood Chemical Manufacturing"
   },
   {
      "type": "naics",
      "value": "325192",
      "label": "325192 - Cyclic Crude and Intermediate Manufacturing"
   },
   {
      "type": "naics",
      "value": "325193",
      "label": "325193 - Ethyl Alcohol Manufacturing"
   },
   {
      "type": "naics",
      "value": "325199",
      "label": "325199 - All Other Basic Organic Chemical Manufacturing"
   },
   {
      "type": "naics",
      "value": "325211",
      "label": "325211 - Plastics Material and Resin Manufacturing"
   },
   {
      "type": "naics",
      "value": "325212",
      "label": "325212 - Synthetic Rubber Manufacturing"
   },
   {
      "type": "naics",
      "value": "325221",
      "label": "325221 - Cellulosic Organic Fiber Manufacturing"
   },
   {
      "type": "naics",
      "value": "325222",
      "label": "325222 - Noncellulosic Organic Fiber Manufacturing"
   },
   {
      "type": "naics",
      "value": "325311",
      "label": "325311 - Nitrogenous Fertilizer Manufacturing"
   },
   {
      "type": "naics",
      "value": "325312",
      "label": "325312 - Phosphatic Fertilizer Manufacturing"
   },
   {
      "type": "naics",
      "value": "325314",
      "label": "325314 - Fertilizer (Mixing Only) Manufacturing"
   },
   {
      "type": "naics",
      "value": "325320",
      "label": "325320 - Pesticide and Other Agricultural Chemical Manufacturing"
   },
   {
      "type": "naics",
      "value": "325411",
      "label": "325411 - Medicinal and Botanical Manufacturing"
   },
   {
      "type": "naics",
      "value": "325412",
      "label": "325412 - Pharmaceutical Preparation Manufacturing"
   },
   {
      "type": "naics",
      "value": "325413",
      "label": "325413 - In-Vitro Diagnostic Substance Manufacturing"
   },
   {
      "type": "naics",
      "value": "325414",
      "label": "325414 - Biological Product (except Diagnostic) Manufacturing"
   },
   {
      "type": "naics",
      "value": "325510",
      "label": "325510 - Paint and Coating Manufacturing"
   },
   {
      "type": "naics",
      "value": "325520",
      "label": "325520 - Adhesive Manufacturing"
   },
   {
      "type": "naics",
      "value": "325611",
      "label": "325611 - Soap and Other Detergent Manufacturing"
   },
   {
      "type": "naics",
      "value": "325612",
      "label": "325612 - Polish and Other Sanitation Good Manufacturing"
   },
   {
      "type": "naics",
      "value": "325613",
      "label": "325613 - Surface Active Agent Manufacturing"
   },
   {
      "type": "naics",
      "value": "325620",
      "label": "325620 - Toilet Preparation Manufacturing"
   },
   {
      "type": "naics",
      "value": "325910",
      "label": "325910 - Printing Ink Manufacturing"
   },
   {
      "type": "naics",
      "value": "325920",
      "label": "325920 - Explosives Manufacturing"
   },
   {
      "type": "naics",
      "value": "325991",
      "label": "325991 - Custom Compounding of Purchased Resins"
   },
   {
      "type": "naics",
      "value": "325992",
      "label": "325992 - Photographic Film"
   },
   {
      "type": "naics",
      "value": "325998",
      "label": "325998 - All Other Miscellaneous Chemical Product and Preparation Manufacturing"
   },
   {
      "type": "naics",
      "value": "326111",
      "label": "326111 - Plastics Bag Manufacturing"
   },
   {
      "type": "naics",
      "value": "326112",
      "label": "326112 - Plastics Packaging Film and Sheet (including Laminated) Manufacturing"
   },
   {
      "type": "naics",
      "value": "326113",
      "label": "326113 - Unlaminated Plastics Film and Sheet (except Packaging) Manufacturing"
   },
   {
      "type": "naics",
      "value": "326121",
      "label": "326121 - Unlaminated Plastics Profile Shape Manufacturing"
   },
   {
      "type": "naics",
      "value": "326122",
      "label": "326122 - Plastics Pipe and Pipe Fitting Manufacturing"
   },
   {
      "type": "naics",
      "value": "326130",
      "label": "326130 - Laminated Plastics Plate"
   },
   {
      "type": "naics",
      "value": "326140",
      "label": "326140 - Polystyrene Foam Product Manufacturing"
   },
   {
      "type": "naics",
      "value": "326150",
      "label": "326150 - Urethane and Other Foam Product (except Polystyrene) Manufacturing"
   },
   {
      "type": "naics",
      "value": "326160",
      "label": "326160 - Plastics Bottle Manufacturing"
   },
   {
      "type": "naics",
      "value": "326191",
      "label": "326191 - Plastics Plumbing Fixture Manufacturing"
   },
   {
      "type": "naics",
      "value": "326192",
      "label": "326192 - Resilient Floor Covering Manufacturing"
   },
   {
      "type": "naics",
      "value": "326199",
      "label": "326199 - All Other Plastics Product Manufacturing"
   },
   {
      "type": "naics",
      "value": "326211",
      "label": "326211 - Tire Manufacturing (except Retreading)"
   },
   {
      "type": "naics",
      "value": "326212",
      "label": "326212 - Tire Retreading"
   },
   {
      "type": "naics",
      "value": "326220",
      "label": "326220 - Rubber and Plastics Hoses and Belting Manufacturing"
   },
   {
      "type": "naics",
      "value": "326291",
      "label": "326291 - Rubber Product Manufacturing for Mechanical Use"
   },
   {
      "type": "naics",
      "value": "326299",
      "label": "326299 - All Other Rubber Product Manufacturing"
   },
   {
      "type": "naics",
      "value": "327110",
      "label": "327110 - Pottery, Ceramics, and Plumbing Fixture Manufacturing"
   },
   {
      "type": "naics",
      "value": "327111",
      "label": "327111 - Vitreous China Plumbing Fixture and China and Earthenware Bathroom Accessories Manufacturing"
   },
   {
      "type": "naics",
      "value": "327112",
      "label": "327112 - Vitreous China"
   },
   {
      "type": "naics",
      "value": "327113",
      "label": "327113 - Porcelain Electrical Supply Manufacturing"
   },
   {
      "type": "naics",
      "value": "327121",
      "label": "327121 - Brick and Structural Clay Tile Manufacturing"
   },
   {
      "type": "naics",
      "value": "327122",
      "label": "327122 - Ceramic Wall and Floor Tile Manufacturing"
   },
   {
      "type": "naics",
      "value": "327123",
      "label": "327123 - Other Structural Clay Product Manufacturing"
   },
   {
      "type": "naics",
      "value": "327124",
      "label": "327124 - Clay Refractory Manufacturing"
   },
   {
      "type": "naics",
      "value": "327125",
      "label": "327125 - Nonclay Refractory Manufacturing"
   },
   {
      "type": "naics",
      "value": "327211",
      "label": "327211 - Flat Glass Manufacturing"
   },
   {
      "type": "naics",
      "value": "327212",
      "label": "327212 - Other Pressed and Blown Glass and Glassware Manufacturing"
   },
   {
      "type": "naics",
      "value": "327213",
      "label": "327213 - Glass Container Manufacturing"
   },
   {
      "type": "naics",
      "value": "327215",
      "label": "327215 - Glass Product Manufacturing Made of Purchased Glass"
   },
   {
      "type": "naics",
      "value": "327310",
      "label": "327310 - Cement Manufacturing"
   },
   {
      "type": "naics",
      "value": "327320",
      "label": "327320 - Ready-Mix Concrete Manufacturing"
   },
   {
      "type": "naics",
      "value": "327331",
      "label": "327331 - Concrete Block and Brick Manufacturing"
   },
   {
      "type": "naics",
      "value": "327332",
      "label": "327332 - Concrete Pipe Manufacturing"
   },
   {
      "type": "naics",
      "value": "327390",
      "label": "327390 - Other Concrete Product Manufacturing"
   },
   {
      "type": "naics",
      "value": "327410",
      "label": "327410 - Lime Manufacturing"
   },
   {
      "type": "naics",
      "value": "327420",
      "label": "327420 - Gypsum Product Manufacturing"
   },
   {
      "type": "naics",
      "value": "327910",
      "label": "327910 - Abrasive Product Manufacturing"
   },
   {
      "type": "naics",
      "value": "327991",
      "label": "327991 - Cut Stone and Stone Product Manufacturing"
   },
   {
      "type": "naics",
      "value": "327992",
      "label": "327992 - Ground or Treated Mineral and Earth Manufacturing"
   },
   {
      "type": "naics",
      "value": "327993",
      "label": "327993 - Mineral Wool Manufacturing"
   },
   {
      "type": "naics",
      "value": "327999",
      "label": "327999 - All Other Miscellaneous Nonmetallic Mineral Product Manufacturing"
   },
   {
      "type": "naics",
      "value": "331111",
      "label": "331111 - Iron and Steel Mills"
   },
   {
      "type": "naics",
      "value": "331112",
      "label": "331112 - Electrometallurgical Ferroalloy Product Manufacturing"
   },
   {
      "type": "naics",
      "value": "331210",
      "label": "331210 - Iron and Steel Pipe and Tube Manufacturing from Purchased Steel"
   },
   {
      "type": "naics",
      "value": "331221",
      "label": "331221 - Rolled Steel Shape Manufacturing"
   },
   {
      "type": "naics",
      "value": "331222",
      "label": "331222 - Steel Wire Drawing"
   },
   {
      "type": "naics",
      "value": "331311",
      "label": "331311 - Alumina Refining"
   },
   {
      "type": "naics",
      "value": "331312",
      "label": "331312 - Primary Aluminum Production"
   },
   {
      "type": "naics",
      "value": "331314",
      "label": "331314 - Secondary Smelting and Alloying of Aluminum"
   },
   {
      "type": "naics",
      "value": "331315",
      "label": "331315 - Aluminum Sheet"
   },
   {
      "type": "naics",
      "value": "331316",
      "label": "331316 - Aluminum Extruded Product Manufacturing"
   },
   {
      "type": "naics",
      "value": "331319",
      "label": "331319 - Other Aluminum Rolling and Drawing"
   },
   {
      "type": "naics",
      "value": "331411",
      "label": "331411 - Primary Smelting and Refining of Copper"
   },
   {
      "type": "naics",
      "value": "331419",
      "label": "331419 - Primary Smelting and Refining of Nonferrous Metal (except Copper and Aluminum)"
   },
   {
      "type": "naics",
      "value": "331421",
      "label": "331421 - Copper Rolling"
   },
   {
      "type": "naics",
      "value": "331422",
      "label": "331422 - Copper Wire (except Mechanical) Drawing"
   },
   {
      "type": "naics",
      "value": "331423",
      "label": "331423 - Secondary Smelting"
   },
   {
      "type": "naics",
      "value": "331491",
      "label": "331491 - Nonferrous Metal (except Copper and Aluminum) Rolling"
   },
   {
      "type": "naics",
      "value": "331492",
      "label": "331492 - Secondary Smelting"
   },
   {
      "type": "naics",
      "value": "331511",
      "label": "331511 - Iron Foundries"
   },
   {
      "type": "naics",
      "value": "331512",
      "label": "331512 - Steel Investment Foundries"
   },
   {
      "type": "naics",
      "value": "331513",
      "label": "331513 - Steel Foundries (except Investment)"
   },
   {
      "type": "naics",
      "value": "331521",
      "label": "331521 - Aluminum Die-Casting Foundries"
   },
   {
      "type": "naics",
      "value": "331522",
      "label": "331522 - Nonferrous (except Aluminum) Die-Casting Foundries"
   },
   {
      "type": "naics",
      "value": "331524",
      "label": "331524 - Aluminum Foundries (except Die-Casting)"
   },
   {
      "type": "naics",
      "value": "331525",
      "label": "331525 - Copper Foundries (except Die-Casting)"
   },
   {
      "type": "naics",
      "value": "331528",
      "label": "331528 - Other Nonferrous Foundries (except Die-Casting)"
   },
   {
      "type": "naics",
      "value": "332111",
      "label": "332111 - Iron and Steel Forging"
   },
   {
      "type": "naics",
      "value": "332112",
      "label": "332112 - Nonferrous Forging"
   },
   {
      "type": "naics",
      "value": "332114",
      "label": "332114 - Custom Roll Forming"
   },
   {
      "type": "naics",
      "value": "332115",
      "label": "332115 - Crown and Closure Manufacturing"
   },
   {
      "type": "naics",
      "value": "332116",
      "label": "332116 - Metal Stamping"
   },
   {
      "type": "naics",
      "value": "332117",
      "label": "332117 - Powder Metallurgy Part Manufacturing"
   },
   {
      "type": "naics",
      "value": "332211",
      "label": "332211 - Cutlery and Flatware (except Precious) Manufacturing"
   },
   {
      "type": "naics",
      "value": "332212",
      "label": "332212 - Hand and Edge Tool Manufacturing"
   },
   {
      "type": "naics",
      "value": "332213",
      "label": "332213 - Saw Blade and Handsaw Manufacturing"
   },
   {
      "type": "naics",
      "value": "332214",
      "label": "332214 - Kitchen Utensil"
   },
   {
      "type": "naics",
      "value": "332311",
      "label": "332311 - Prefabricated Metal Building and Component Manufacturing"
   },
   {
      "type": "naics",
      "value": "332312",
      "label": "332312 - Fabricated Structural Metal Manufacturing"
   },
   {
      "type": "naics",
      "value": "332313",
      "label": "332313 - Plate Work Manufacturing"
   },
   {
      "type": "naics",
      "value": "332321",
      "label": "332321 - Metal Window and Door Manufacturing"
   },
   {
      "type": "naics",
      "value": "332322",
      "label": "332322 - Sheet Metal Work Manufacturing"
   },
   {
      "type": "naics",
      "value": "332323",
      "label": "332323 - Ornamental and Architectural Metal Work Manufacturing"
   },
   {
      "type": "naics",
      "value": "332410",
      "label": "332410 - Power Boiler and Heat Exchanger Manufacturing"
   },
   {
      "type": "naics",
      "value": "332420",
      "label": "332420 - Metal Tank (Heavy Gauge) Manufacturing"
   },
   {
      "type": "naics",
      "value": "332431",
      "label": "332431 - Metal Can Manufacturing"
   },
   {
      "type": "naics",
      "value": "332439",
      "label": "332439 - Other Metal Container Manufacturing"
   },
   {
      "type": "naics",
      "value": "332510",
      "label": "332510 - Hardware Manufacturing"
   },
   {
      "type": "naics",
      "value": "332611",
      "label": "332611 - Spring (Heavy Gauge) Manufacturing"
   },
   {
      "type": "naics",
      "value": "332612",
      "label": "332612 - Spring (Light Gauge) Manufacturing"
   },
   {
      "type": "naics",
      "value": "332618",
      "label": "332618 - Other Fabricated Wire Product Manufacturing"
   },
   {
      "type": "naics",
      "value": "332710",
      "label": "332710 - Machine Shops"
   },
   {
      "type": "naics",
      "value": "332721",
      "label": "332721 - Precision Turned Product Manufacturing"
   },
   {
      "type": "naics",
      "value": "332722",
      "label": "332722 - Bolt"
   },
   {
      "type": "naics",
      "value": "332811",
      "label": "332811 - Metal Heat Treating"
   },
   {
      "type": "naics",
      "value": "332812",
      "label": "332812 - Metal Coating"
   },
   {
      "type": "naics",
      "value": "332813",
      "label": "332813 - Electroplating"
   },
   {
      "type": "naics",
      "value": "332911",
      "label": "332911 - Industrial Valve Manufacturing"
   },
   {
      "type": "naics",
      "value": "332912",
      "label": "332912 - Fluid Power Valve and Hose Fitting Manufacturing"
   },
   {
      "type": "naics",
      "value": "332913",
      "label": "332913 - Plumbing Fixture Fitting and Trim Manufacturing"
   },
   {
      "type": "naics",
      "value": "332919",
      "label": "332919 - Other Metal Valve and Pipe Fitting Manufacturing"
   },
   {
      "type": "naics",
      "value": "332991",
      "label": "332991 - Ball and Roller Bearing Manufacturing"
   },
   {
      "type": "naics",
      "value": "332992",
      "label": "332992 - Small Arms Ammunition Manufacturing"
   },
   {
      "type": "naics",
      "value": "332993",
      "label": "332993 - Ammunition (except Small Arms) Manufacturing"
   },
   {
      "type": "naics",
      "value": "332994",
      "label": "332994 - Small Arms Manufacturing"
   },
   {
      "type": "naics",
      "value": "332995",
      "label": "332995 - Other Ordnance and Accessories Manufacturing"
   },
   {
      "type": "naics",
      "value": "332996",
      "label": "332996 - Fabricated Pipe and Pipe Fitting Manufacturing"
   },
   {
      "type": "naics",
      "value": "332997",
      "label": "332997 - Industrial Pattern Manufacturing"
   },
   {
      "type": "naics",
      "value": "332998",
      "label": "332998 - Enameled Iron and Metal Sanitary Ware Manufacturing"
   },
   {
      "type": "naics",
      "value": "332999",
      "label": "332999 - All Other Miscellaneous Fabricated Metal Product Manufacturing"
   },
   {
      "type": "naics",
      "value": "333111",
      "label": "333111 - Farm Machinery and Equipment Manufacturing"
   },
   {
      "type": "naics",
      "value": "333112",
      "label": "333112 - Lawn and Garden Tractor and Home Lawn and Garden Equipment Manufacturing"
   },
   {
      "type": "naics",
      "value": "333120",
      "label": "333120 - Construction Machinery Manufacturing"
   },
   {
      "type": "naics",
      "value": "333131",
      "label": "333131 - Mining Machinery and Equipment Manufacturing"
   },
   {
      "type": "naics",
      "value": "333132",
      "label": "333132 - Oil and Gas Field Machinery and Equipment Manufacturing"
   },
   {
      "type": "naics",
      "value": "333210",
      "label": "333210 - Sawmill and Woodworking Machinery Manufacturing"
   },
   {
      "type": "naics",
      "value": "333220",
      "label": "333220 - Plastics and Rubber Industry Machinery Manufacturing"
   },
   {
      "type": "naics",
      "value": "333291",
      "label": "333291 - Paper Industry Machinery Manufacturing"
   },
   {
      "type": "naics",
      "value": "333292",
      "label": "333292 - Textile Machinery Manufacturing"
   },
   {
      "type": "naics",
      "value": "333293",
      "label": "333293 - Printing Machinery and Equipment Manufacturing"
   },
   {
      "type": "naics",
      "value": "333294",
      "label": "333294 - Food Product Machinery Manufacturing"
   },
   {
      "type": "naics",
      "value": "333295",
      "label": "333295 - Semiconductor Machinery Manufacturing"
   },
   {
      "type": "naics",
      "value": "333298",
      "label": "333298 - All Other Industrial Machinery Manufacturing"
   },
   {
      "type": "naics",
      "value": "333311",
      "label": "333311 - Automatic Vending Machine Manufacturing"
   },
   {
      "type": "naics",
      "value": "333312",
      "label": "333312 - Commercial Laundry"
   },
   {
      "type": "naics",
      "value": "333313",
      "label": "333313 - Office Machinery Manufacturing"
   },
   {
      "type": "naics",
      "value": "333314",
      "label": "333314 - Optical Instrument and Lens Manufacturing"
   },
   {
      "type": "naics",
      "value": "333315",
      "label": "333315 - Photographic and Photocopying Equipment Manufacturing"
   },
   {
      "type": "naics",
      "value": "333319",
      "label": "333319 - Other Commercial and Service Industry Machinery Manufacturing"
   },
   {
      "type": "naics",
      "value": "333411",
      "label": "333411 - Air Purification Equipment Manufacturing"
   },
   {
      "type": "naics",
      "value": "333412",
      "label": "333412 - Industrial and Commercial Fan and Blower Manufacturing"
   },
   {
      "type": "naics",
      "value": "333414",
      "label": "333414 - Heating Equipment (except Warm Air Furnaces) Manufacturing"
   },
   {
      "type": "naics",
      "value": "333415",
      "label": "333415 - Air-Conditioning and Warm Air Heating Equipment and Commercial and Industrial Refrigeration Equipment Manufacturing"
   },
   {
      "type": "naics",
      "value": "333511",
      "label": "333511 - Industrial Mold Manufacturing"
   },
   {
      "type": "naics",
      "value": "333512",
      "label": "333512 - Machine Tool (Metal Cutting Types) Manufacturing"
   },
   {
      "type": "naics",
      "value": "333513",
      "label": "333513 - Machine Tool (Metal Forming Types) Manufacturing"
   },
   {
      "type": "naics",
      "value": "333514",
      "label": "333514 - Special Die and Tool"
   },
   {
      "type": "naics",
      "value": "333515",
      "label": "333515 - Cutting Tool and Machine Tool Accessory Manufacturing"
   },
   {
      "type": "naics",
      "value": "333516",
      "label": "333516 - Rolling Mill Machinery and Equipment Manufacturing"
   },
   {
      "type": "naics",
      "value": "333518",
      "label": "333518 - Other Metalworking Machinery Manufacturing"
   },
   {
      "type": "naics",
      "value": "333611",
      "label": "333611 - Turbine and Turbine Generator Set Units Manufacturing"
   },
   {
      "type": "naics",
      "value": "333612",
      "label": "333612 - Speed Changer"
   },
   {
      "type": "naics",
      "value": "333613",
      "label": "333613 - Mechanical Power Transmission Equipment Manufacturing"
   },
   {
      "type": "naics",
      "value": "333618",
      "label": "333618 - Other Engine Equipment Manufacturing"
   },
   {
      "type": "naics",
      "value": "333911",
      "label": "333911 - Pump and Pumping Equipment Manufacturing"
   },
   {
      "type": "naics",
      "value": "333912",
      "label": "333912 - Air and Gas Compressor Manufacturing"
   },
   {
      "type": "naics",
      "value": "333913",
      "label": "333913 - Measuring and Dispensing Pump Manufacturing"
   },
   {
      "type": "naics",
      "value": "333921",
      "label": "333921 - Elevator and Moving Stairway Manufacturing"
   },
   {
      "type": "naics",
      "value": "333922",
      "label": "333922 - Conveyor and Conveying Equipment Manufacturing"
   },
   {
      "type": "naics",
      "value": "333923",
      "label": "333923 - Overhead Traveling Crane"
   },
   {
      "type": "naics",
      "value": "333924",
      "label": "333924 - Industrial Truck"
   },
   {
      "type": "naics",
      "value": "333991",
      "label": "333991 - Power-Driven Handtool Manufacturing"
   },
   {
      "type": "naics",
      "value": "333992",
      "label": "333992 - Welding and Soldering Equipment Manufacturing"
   },
   {
      "type": "naics",
      "value": "333993",
      "label": "333993 - Packaging Machinery Manufacturing"
   },
   {
      "type": "naics",
      "value": "333994",
      "label": "333994 - Industrial Process Furnace and Oven Manufacturing"
   },
   {
      "type": "naics",
      "value": "333995",
      "label": "333995 - Fluid Power Cylinder and Actuator Manufacturing"
   },
   {
      "type": "naics",
      "value": "333996",
      "label": "333996 - Fluid Power Pump and Motor Manufacturing"
   },
   {
      "type": "naics",
      "value": "333997",
      "label": "333997 - Scale and Balance (except Laboratory) Manufacturing"
   },
   {
      "type": "naics",
      "value": "333999",
      "label": "333999 - All Other Miscellaneous General Purpose Machinery Manufacturing"
   },
   {
      "type": "naics",
      "value": "334111",
      "label": "334111 - Electronic Computer Manufacturing"
   },
   {
      "type": "naics",
      "value": "334112",
      "label": "334112 - Computer Storage Device Manufacturing"
   },
   {
      "type": "naics",
      "value": "334113",
      "label": "334113 - Computer Terminal Manufacturing"
   },
   {
      "type": "naics",
      "value": "334119",
      "label": "334119 - Other Computer Peripheral Equipment Manufacturing"
   },
   {
      "type": "naics",
      "value": "334210",
      "label": "334210 - Telephone Apparatus Manufacturing"
   },
   {
      "type": "naics",
      "value": "334220",
      "label": "334220 - Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing"
   },
   {
      "type": "naics",
      "value": "334290",
      "label": "334290 - Other Communications Equipment Manufacturing"
   },
   {
      "type": "naics",
      "value": "334310",
      "label": "334310 - Audio and Video Equipment Manufacturing"
   },
   {
      "type": "naics",
      "value": "334411",
      "label": "334411 - Electron Tube Manufacturing"
   },
   {
      "type": "naics",
      "value": "334412",
      "label": "334412 - Bare Printed Circuit Board Manufacturing"
   },
   {
      "type": "naics",
      "value": "334413",
      "label": "334413 - Semiconductor and Related Device Manufacturing"
   },
   {
      "type": "naics",
      "value": "334414",
      "label": "334414 - Electronic Capacitor Manufacturing"
   },
   {
      "type": "naics",
      "value": "334415",
      "label": "334415 - Electronic Resistor Manufacturing"
   },
   {
      "type": "naics",
      "value": "334416",
      "label": "334416 - Electronic Coil"
   },
   {
      "type": "naics",
      "value": "334417",
      "label": "334417 - Electronic Connector Manufacturing"
   },
   {
      "type": "naics",
      "value": "334418",
      "label": "334418 - Printed Circuit Assembly (Electronic Assembly) Manufacturing"
   },
   {
      "type": "naics",
      "value": "334419",
      "label": "334419 - Other Electronic Component Manufacturing"
   },
   {
      "type": "naics",
      "value": "334510",
      "label": "334510 - Electromedical and Electrotherapeutic Apparatus Manufacturing"
   },
   {
      "type": "naics",
      "value": "334511",
      "label": "334511 - Search"
   },
   {
      "type": "naics",
      "value": "334512",
      "label": "334512 - Automatic Environmental Control Manufacturing for Residential"
   },
   {
      "type": "naics",
      "value": "334513",
      "label": "334513 - Instruments and Related Products Manufacturing for Measuring"
   },
   {
      "type": "naics",
      "value": "334514",
      "label": "334514 - Totalizing Fluid Meter and Counting Device Manufacturing"
   },
   {
      "type": "naics",
      "value": "334515",
      "label": "334515 - Instrument Manufacturing for Measuring and Testing Electricity and Electrical Signals"
   },
   {
      "type": "naics",
      "value": "334516",
      "label": "334516 - Analytical Laboratory Instrument Manufacturing"
   },
   {
      "type": "naics",
      "value": "334517",
      "label": "334517 - Irradiation Apparatus Manufacturing"
   },
   {
      "type": "naics",
      "value": "334518",
      "label": "334518 - Watch"
   },
   {
      "type": "naics",
      "value": "334519",
      "label": "334519 - Other Measuring and Controlling Device Manufacturing"
   },
   {
      "type": "naics",
      "value": "334611",
      "label": "334611 - Software Reproducing"
   },
   {
      "type": "naics",
      "value": "334612",
      "label": "334612 - Prerecorded Compact Disc (except Software)"
   },
   {
      "type": "naics",
      "value": "334613",
      "label": "334613 - Magnetic and Optical Recording Media Manufacturing"
   },
   {
      "type": "naics",
      "value": "335110",
      "label": "335110 - Electric Lamp Bulb and Part Manufacturing"
   },
   {
      "type": "naics",
      "value": "335121",
      "label": "335121 - Residential Electric Lighting Fixture Manufacturing"
   },
   {
      "type": "naics",
      "value": "335122",
      "label": "335122 - Commercial"
   },
   {
      "type": "naics",
      "value": "335129",
      "label": "335129 - Other Lighting Equipment Manufacturing"
   },
   {
      "type": "naics",
      "value": "335211",
      "label": "335211 - Electric Housewares and Household Fan Manufacturing"
   },
   {
      "type": "naics",
      "value": "335212",
      "label": "335212 - Household Vacuum Cleaner Manufacturing"
   },
   {
      "type": "naics",
      "value": "335221",
      "label": "335221 - Household Cooking Appliance Manufacturing"
   },
   {
      "type": "naics",
      "value": "335222",
      "label": "335222 - Household Refrigerator and Home Freezer Manufacturing"
   },
   {
      "type": "naics",
      "value": "335224",
      "label": "335224 - Household Laundry Equipment Manufacturing"
   },
   {
      "type": "naics",
      "value": "335228",
      "label": "335228 - Other Major Household Appliance Manufacturing"
   },
   {
      "type": "naics",
      "value": "335311",
      "label": "335311 - Power"
   },
   {
      "type": "naics",
      "value": "335312",
      "label": "335312 - Motor and Generator Manufacturing"
   },
   {
      "type": "naics",
      "value": "335313",
      "label": "335313 - Switchgear and Switchboard Apparatus Manufacturing"
   },
   {
      "type": "naics",
      "value": "335314",
      "label": "335314 - Relay and Industrial Control Manufacturing"
   },
   {
      "type": "naics",
      "value": "335911",
      "label": "335911 - Storage Battery Manufacturing"
   },
   {
      "type": "naics",
      "value": "335912",
      "label": "335912 - Primary Battery Manufacturing"
   },
   {
      "type": "naics",
      "value": "335921",
      "label": "335921 - Fiber Optic Cable Manufacturing"
   },
   {
      "type": "naics",
      "value": "335929",
      "label": "335929 - Other Communication and Energy Wire Manufacturing"
   },
   {
      "type": "naics",
      "value": "335931",
      "label": "335931 - Current-Carrying Wiring Device Manufacturing"
   },
   {
      "type": "naics",
      "value": "335932",
      "label": "335932 - Noncurrent-Carrying Wiring Device Manufacturing"
   },
   {
      "type": "naics",
      "value": "335991",
      "label": "335991 - Carbon and Graphite Product Manufacturing"
   },
   {
      "type": "naics",
      "value": "335999",
      "label": "335999 - All Other Miscellaneous Electrical Equipment and Component Manufacturing"
   },
   {
      "type": "naics",
      "value": "336111",
      "label": "336111 - Automobile Manufacturing"
   },
   {
      "type": "naics",
      "value": "336112",
      "label": "336112 - Light Truck and Utility Vehicle Manufacturing"
   },
   {
      "type": "naics",
      "value": "336120",
      "label": "336120 - Heavy Duty Truck Manufacturing"
   },
   {
      "type": "naics",
      "value": "336211",
      "label": "336211 - Motor Vehicle Body Manufacturing"
   },
   {
      "type": "naics",
      "value": "336212",
      "label": "336212 - Truck Trailer Manufacturing"
   },
   {
      "type": "naics",
      "value": "336213",
      "label": "336213 - Motor Home Manufacturing"
   },
   {
      "type": "naics",
      "value": "336214",
      "label": "336214 - Travel Trailer and Camper Manufacturing"
   },
   {
      "type": "naics",
      "value": "336311",
      "label": "336311 - Carburetor"
   },
   {
      "type": "naics",
      "value": "336312",
      "label": "336312 - Gasoline Engine and Engine Parts Manufacturing"
   },
   {
      "type": "naics",
      "value": "336321",
      "label": "336321 - Vehicular Lighting Equipment Manufacturing"
   },
   {
      "type": "naics",
      "value": "336322",
      "label": "336322 - Other Motor Vehicle Electrical and Electronic Equipment Manufacturing"
   },
   {
      "type": "naics",
      "value": "336330",
      "label": "336330 - Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing"
   },
   {
      "type": "naics",
      "value": "336340",
      "label": "336340 - Motor Vehicle Brake System Manufacturing"
   },
   {
      "type": "naics",
      "value": "336350",
      "label": "336350 - Motor Vehicle Transmission and Power Train Parts Manufacturing"
   },
   {
      "type": "naics",
      "value": "336360",
      "label": "336360 - Motor Vehicle Seating and Interior Trim Manufacturing"
   },
   {
      "type": "naics",
      "value": "336370",
      "label": "336370 - Motor Vehicle Metal Stamping"
   },
   {
      "type": "naics",
      "value": "336391",
      "label": "336391 - Motor Vehicle Air-Conditioning Manufacturing"
   },
   {
      "type": "naics",
      "value": "336399",
      "label": "336399 - All Other Motor Vehicle Parts Manufacturing"
   },
   {
      "type": "naics",
      "value": "336411",
      "label": "336411 - Aircraft Manufacturing"
   },
   {
      "type": "naics",
      "value": "336412",
      "label": "336412 - Aircraft Engine and Engine Parts Manufacturing"
   },
   {
      "type": "naics",
      "value": "336413",
      "label": "336413 - Other Aircraft Parts and Auxiliary Equipment Manufacturing"
   },
   {
      "type": "naics",
      "value": "336414",
      "label": "336414 - Guided Missile and Space Vehicle Manufacturing"
   },
   {
      "type": "naics",
      "value": "336415",
      "label": "336415 - Guided Missile and Space Vehicle Propulsion Unit and Propulsion Unit Parts Manufacturing"
   },
   {
      "type": "naics",
      "value": "336419",
      "label": "336419 - Other Guided Missile and Space Vehicle Parts and Auxiliary Equipment Manufacturing"
   },
   {
      "type": "naics",
      "value": "336510",
      "label": "336510 - Railroad Rolling Stock Manufacturing"
   },
   {
      "type": "naics",
      "value": "336611",
      "label": "336611 - Ship Building and Repairing"
   },
   {
      "type": "naics",
      "value": "336612",
      "label": "336612 - Boat Building"
   },
   {
      "type": "naics",
      "value": "336991",
      "label": "336991 - Motorcycle"
   },
   {
      "type": "naics",
      "value": "336992",
      "label": "336992 - Military Armored Vehicle"
   },
   {
      "type": "naics",
      "value": "336999",
      "label": "336999 - All Other Transportation Equipment Manufacturing"
   },
   {
      "type": "naics",
      "value": "337110",
      "label": "337110 - Wood Kitchen Cabinet and Countertop Manufacturing"
   },
   {
      "type": "naics",
      "value": "337121",
      "label": "337121 - Upholstered Household Furniture Manufacturing"
   },
   {
      "type": "naics",
      "value": "337122",
      "label": "337122 - Nonupholstered Wood Household Furniture Manufacturing"
   },
   {
      "type": "naics",
      "value": "337124",
      "label": "337124 - Metal Household Furniture Manufacturing"
   },
   {
      "type": "naics",
      "value": "337125",
      "label": "337125 - Household Furniture (except Wood and Metal) Manufacturing"
   },
   {
      "type": "naics",
      "value": "337127",
      "label": "337127 - Institutional Furniture Manufacturing"
   },
   {
      "type": "naics",
      "value": "337129",
      "label": "337129 - Wood Television"
   },
   {
      "type": "naics",
      "value": "337211",
      "label": "337211 - Wood Office Furniture Manufacturing"
   },
   {
      "type": "naics",
      "value": "337212",
      "label": "337212 - Custom Architectural Woodwork and Millwork Manufacturing"
   },
   {
      "type": "naics",
      "value": "337214",
      "label": "337214 - Office Furniture (except Wood) Manufacturing"
   },
   {
      "type": "naics",
      "value": "337215",
      "label": "337215 - Showcase"
   },
   {
      "type": "naics",
      "value": "337910",
      "label": "337910 - Mattress Manufacturing"
   },
   {
      "type": "naics",
      "value": "337920",
      "label": "337920 - Blind and Shade Manufacturing"
   },
   {
      "type": "naics",
      "value": "339111",
      "label": "339111 - Laboratory Apparatus and Furniture Manufacturing"
   },
   {
      "type": "naics",
      "value": "339112",
      "label": "339112 - Surgical and Medical Instrument Manufacturing"
   },
   {
      "type": "naics",
      "value": "339113",
      "label": "339113 - Surgical Appliance and Supplies Manufacturing"
   },
   {
      "type": "naics",
      "value": "339114",
      "label": "339114 - Dental Equipment and Supplies Manufacturing"
   },
   {
      "type": "naics",
      "value": "339115",
      "label": "339115 - Ophthalmic Goods Manufacturing"
   },
   {
      "type": "naics",
      "value": "339116",
      "label": "339116 - Dental Laboratories"
   },
   {
      "type": "naics",
      "value": "339911",
      "label": "339911 - Jewelry (except Costume) Manufacturing"
   },
   {
      "type": "naics",
      "value": "339912",
      "label": "339912 - Silverware and Hollowware Manufacturing"
   },
   {
      "type": "naics",
      "value": "339913",
      "label": "339913 - Jewelers' Material and Lapidary Work Manufacturing"
   },
   {
      "type": "naics",
      "value": "339914",
      "label": "339914 - Costume Jewelry and Novelty Manufacturing"
   },
   {
      "type": "naics",
      "value": "339920",
      "label": "339920 - Sporting and Athletic Goods Manufacturing"
   },
   {
      "type": "naics",
      "value": "339931",
      "label": "339931 - Doll and Stuffed Toy Manufacturing"
   },
   {
      "type": "naics",
      "value": "339932",
      "label": "339932 - Game"
   },
   {
      "type": "naics",
      "value": "339941",
      "label": "339941 - Pen and Mechanical Pencil Manufacturing"
   },
   {
      "type": "naics",
      "value": "339942",
      "label": "339942 - Lead Pencil and Art Good Manufacturing"
   },
   {
      "type": "naics",
      "value": "339943",
      "label": "339943 - Marking Device Manufacturing"
   },
   {
      "type": "naics",
      "value": "339944",
      "label": "339944 - Carbon Paper and Inked Ribbon Manufacturing"
   },
   {
      "type": "naics",
      "value": "339950",
      "label": "339950 - Sign Manufacturing"
   },
   {
      "type": "naics",
      "value": "339991",
      "label": "339991 - Gasket"
   },
   {
      "type": "naics",
      "value": "339992",
      "label": "339992 - Musical Instrument Manufacturing"
   },
   {
      "type": "naics",
      "value": "339993",
      "label": "339993 - Fastener"
   },
   {
      "type": "naics",
      "value": "339994",
      "label": "339994 - Broom"
   },
   {
      "type": "naics",
      "value": "339995",
      "label": "339995 - Burial Casket Manufacturing"
   },
   {
      "type": "naics",
      "value": "339999",
      "label": "339999 - All Other Miscellaneous Manufacturing"
   },
   {
      "type": "naics",
      "value": "423110",
      "label": "423110 - Automobile and Other Motor Vehicle Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "423120",
      "label": "423120 - Motor Vehicle Supplies and New Parts Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "423130",
      "label": "423130 - Tire and Tube Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "423140",
      "label": "423140 - Motor Vehicle Parts (Used) Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "423210",
      "label": "423210 - Furniture Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "423220",
      "label": "423220 - Home Furnishing Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "423310",
      "label": "423310 - Lumber"
   },
   {
      "type": "naics",
      "value": "423320",
      "label": "423320 - Brick"
   },
   {
      "type": "naics",
      "value": "423330",
      "label": "423330 - Roofing"
   },
   {
      "type": "naics",
      "value": "423390",
      "label": "423390 - Other Construction Material Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "423410",
      "label": "423410 - Photographic Equipment and Supplies Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "423420",
      "label": "423420 - Office Equipment Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "423430",
      "label": "423430 - Computer and Computer Peripheral Equipment and Software Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "423440",
      "label": "423440 - Other Commercial Equipment Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "423450",
      "label": "423450 - Medical"
   },
   {
      "type": "naics",
      "value": "423460",
      "label": "423460 - Ophthalmic Goods Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "423490",
      "label": "423490 - Other Professional Equipment and Supplies Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "423510",
      "label": "423510 - Metal Service Centers and Other Metal Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "423520",
      "label": "423520 - Coal and Other Mineral and Ore Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "423610",
      "label": "423610 - Electrical Apparatus and Equipment"
   },
   {
      "type": "naics",
      "value": "423620",
      "label": "423620 - Electrical and Electronic Appliance"
   },
   {
      "type": "naics",
      "value": "423690",
      "label": "423690 - Other Electronic Parts and Equipment Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "423710",
      "label": "423710 - Hardware Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "423720",
      "label": "423720 - Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "423730",
      "label": "423730 - Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "423740",
      "label": "423740 - Refrigeration Equipment and Supplies Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "423810",
      "label": "423810 - Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "423820",
      "label": "423820 - Farm and Garden Machinery and Equipment Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "423830",
      "label": "423830 - Industrial Machinery and Equipment Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "423840",
      "label": "423840 - Industrial Supplies Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "423850",
      "label": "423850 - Service Establishment Equipment and Supplies Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "423860",
      "label": "423860 - Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "423910",
      "label": "423910 - Sporting and Recreational Goods and Supplies Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "423920",
      "label": "423920 - Toy and Hobby Goods and Supplies Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "423930",
      "label": "423930 - Recyclable Material Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "423940",
      "label": "423940 - Jewelry"
   },
   {
      "type": "naics",
      "value": "423990",
      "label": "423990 - Other Miscellaneous Durable Goods Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "424110",
      "label": "424110 - Printing and Writing Paper Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "424120",
      "label": "424120 - Stationery and Office Supplies Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "424130",
      "label": "424130 - Industrial and Personal Service Paper Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "424210",
      "label": "424210 - Drugs and Druggists' Sundries Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "424310",
      "label": "424310 - Piece Goods"
   },
   {
      "type": "naics",
      "value": "424320",
      "label": "424320 - Men's and Boys' Clothing and Furnishings Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "424330",
      "label": "424330 - Women's"
   },
   {
      "type": "naics",
      "value": "424340",
      "label": "424340 - Footwear Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "424410",
      "label": "424410 - General Line Grocery Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "424420",
      "label": "424420 - Packaged Frozen Food Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "424430",
      "label": "424430 - Dairy Product (except Dried or Canned) Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "424440",
      "label": "424440 - Poultry and Poultry Product Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "424450",
      "label": "424450 - Confectionery Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "424460",
      "label": "424460 - Fish and Seafood Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "424470",
      "label": "424470 - Meat and Meat Product Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "424480",
      "label": "424480 - Fresh Fruit and Vegetable Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "424490",
      "label": "424490 - Other Grocery and Related Products Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "424510",
      "label": "424510 - Grain and Field Bean Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "424520",
      "label": "424520 - Livestock Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "424590",
      "label": "424590 - Other Farm Product Raw Material Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "424610",
      "label": "424610 - Plastics Materials and Basic Forms and Shapes Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "424690",
      "label": "424690 - Other Chemical and Allied Products Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "424710",
      "label": "424710 - Petroleum Bulk Stations and Terminals"
   },
   {
      "type": "naics",
      "value": "424720",
      "label": "424720 - Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals)"
   },
   {
      "type": "naics",
      "value": "424810",
      "label": "424810 - Beer and Ale Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "424820",
      "label": "424820 - Wine and Distilled Alcoholic Beverage Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "424910",
      "label": "424910 - Farm Supplies Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "424920",
      "label": "424920 - Book"
   },
   {
      "type": "naics",
      "value": "424930",
      "label": "424930 - Flower"
   },
   {
      "type": "naics",
      "value": "424940",
      "label": "424940 - Tobacco and Tobacco Product Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "424950",
      "label": "424950 - Paint"
   },
   {
      "type": "naics",
      "value": "424990",
      "label": "424990 - Other Miscellaneous Nondurable Goods Merchant Wholesalers"
   },
   {
      "type": "naics",
      "value": "425110",
      "label": "425110 - Business to Business Electronic Markets"
   },
   {
      "type": "naics",
      "value": "425120",
      "label": "425120 - Wholesale Trade Agents and Brokers"
   },
   {
      "type": "naics",
      "value": "441110",
      "label": "441110 - New Car Dealers"
   },
   {
      "type": "naics",
      "value": "441120",
      "label": "441120 - Used Car Dealers"
   },
   {
      "type": "naics",
      "value": "441210",
      "label": "441210 - Recreational Vehicle Dealers"
   },
   {
      "type": "naics",
      "value": "441221",
      "label": "441221 - Motorcycle Dealers"
   },
   {
      "type": "naics",
      "value": "441222",
      "label": "441222 - Boat Dealers"
   },
   {
      "type": "naics",
      "value": "441229",
      "label": "441229 - All Other Motor Vehicle Dealers"
   },
   {
      "type": "naics",
      "value": "441310",
      "label": "441310 - Automotive Parts and Accessories Stores"
   },
   {
      "type": "naics",
      "value": "441320",
      "label": "441320 - Tire Dealers"
   },
   {
      "type": "naics",
      "value": "442110",
      "label": "442110 - Furniture Stores"
   },
   {
      "type": "naics",
      "value": "442210",
      "label": "442210 - Floor Covering Stores"
   },
   {
      "type": "naics",
      "value": "442291",
      "label": "442291 - Window Treatment Stores"
   },
   {
      "type": "naics",
      "value": "442299",
      "label": "442299 - All Other Home Furnishings Stores"
   },
   {
      "type": "naics",
      "value": "443111",
      "label": "443111 - Household Appliance Stores"
   },
   {
      "type": "naics",
      "value": "443112",
      "label": "443112 - Radio"
   },
   {
      "type": "naics",
      "value": "443120",
      "label": "443120 - Computer and Software Stores"
   },
   {
      "type": "naics",
      "value": "443130",
      "label": "443130 - Camera and Photographic Supplies Stores"
   },
   {
      "type": "naics",
      "value": "444110",
      "label": "444110 - Home Centers"
   },
   {
      "type": "naics",
      "value": "444120",
      "label": "444120 - Paint and Wallpaper Stores"
   },
   {
      "type": "naics",
      "value": "444130",
      "label": "444130 - Hardware Stores"
   },
   {
      "type": "naics",
      "value": "444190",
      "label": "444190 - Other Building Material Dealers"
   },
   {
      "type": "naics",
      "value": "444210",
      "label": "444210 - Outdoor Power Equipment Stores"
   },
   {
      "type": "naics",
      "value": "444220",
      "label": "444220 - Nursery"
   },
   {
      "type": "naics",
      "value": "445110",
      "label": "445110 - Supermarkets and Other Grocery (except Convenience) Stores"
   },
   {
      "type": "naics",
      "value": "445120",
      "label": "445120 - Convenience Stores"
   },
   {
      "type": "naics",
      "value": "445210",
      "label": "445210 - Meat Markets"
   },
   {
      "type": "naics",
      "value": "445220",
      "label": "445220 - Fish and Seafood Markets"
   },
   {
      "type": "naics",
      "value": "445230",
      "label": "445230 - Fruit and Vegetable Markets"
   },
   {
      "type": "naics",
      "value": "445291",
      "label": "445291 - Baked Goods Stores"
   },
   {
      "type": "naics",
      "value": "445292",
      "label": "445292 - Confectionery and Nut Stores"
   },
   {
      "type": "naics",
      "value": "445299",
      "label": "445299 - All Other Specialty Food Stores"
   },
   {
      "type": "naics",
      "value": "445310",
      "label": "445310 - Beer"
   },
   {
      "type": "naics",
      "value": "446110",
      "label": "446110 - Pharmacies and Drug Stores"
   },
   {
      "type": "naics",
      "value": "446120",
      "label": "446120 - Cosmetics"
   },
   {
      "type": "naics",
      "value": "446130",
      "label": "446130 - Optical Goods Stores"
   },
   {
      "type": "naics",
      "value": "446191",
      "label": "446191 - Food (Health) Supplement Stores"
   },
   {
      "type": "naics",
      "value": "446199",
      "label": "446199 - All Other Health and Personal Care Stores"
   },
   {
      "type": "naics",
      "value": "447110",
      "label": "447110 - Gasoline Stations with Convenience Stores"
   },
   {
      "type": "naics",
      "value": "447190",
      "label": "447190 - Other Gasoline Stations"
   },
   {
      "type": "naics",
      "value": "448110",
      "label": "448110 - Men's Clothing Stores"
   },
   {
      "type": "naics",
      "value": "448120",
      "label": "448120 - Women's Clothing Stores"
   },
   {
      "type": "naics",
      "value": "448130",
      "label": "448130 - Children's and Infants' Clothing Stores"
   },
   {
      "type": "naics",
      "value": "448140",
      "label": "448140 - Family Clothing Stores"
   },
   {
      "type": "naics",
      "value": "448150",
      "label": "448150 - Clothing Accessories Stores"
   },
   {
      "type": "naics",
      "value": "448190",
      "label": "448190 - Other Clothing Stores"
   },
   {
      "type": "naics",
      "value": "448210",
      "label": "448210 - Shoe Stores"
   },
   {
      "type": "naics",
      "value": "448310",
      "label": "448310 - Jewelry Stores"
   },
   {
      "type": "naics",
      "value": "448320",
      "label": "448320 - Luggage and Leather Goods Stores"
   },
   {
      "type": "naics",
      "value": "451110",
      "label": "451110 - Sporting Goods Stores"
   },
   {
      "type": "naics",
      "value": "451120",
      "label": "451120 - Hobby"
   },
   {
      "type": "naics",
      "value": "451130",
      "label": "451130 - Sewing"
   },
   {
      "type": "naics",
      "value": "451140",
      "label": "451140 - Musical Instrument and Supplies Stores"
   },
   {
      "type": "naics",
      "value": "451211",
      "label": "451211 - Book Stores"
   },
   {
      "type": "naics",
      "value": "451212",
      "label": "451212 - News Dealers and Newsstands"
   },
   {
      "type": "naics",
      "value": "451220",
      "label": "451220 - Prerecorded Tape"
   },
   {
      "type": "naics",
      "value": "452111",
      "label": "452111 - Department Stores (except Discount Department Stores)"
   },
   {
      "type": "naics",
      "value": "452112",
      "label": "452112 - Discount Department Stores"
   },
   {
      "type": "naics",
      "value": "452910",
      "label": "452910 - Warehouse Clubs and Supercenters"
   },
   {
      "type": "naics",
      "value": "452990",
      "label": "452990 - All Other General Merchandise Stores"
   },
   {
      "type": "naics",
      "value": "453110",
      "label": "453110 - Florists"
   },
   {
      "type": "naics",
      "value": "453210",
      "label": "453210 - Office Supplies and Stationery Stores"
   },
   {
      "type": "naics",
      "value": "453220",
      "label": "453220 - Gift"
   },
   {
      "type": "naics",
      "value": "453310",
      "label": "453310 - Used Merchandise Stores"
   },
   {
      "type": "naics",
      "value": "453910",
      "label": "453910 - Pet and Pet Supplies Stores"
   },
   {
      "type": "naics",
      "value": "453920",
      "label": "453920 - Art Dealers"
   },
   {
      "type": "naics",
      "value": "453930",
      "label": "453930 - Manufactured (Mobile) Home Dealers"
   },
   {
      "type": "naics",
      "value": "453991",
      "label": "453991 - Tobacco Stores"
   },
   {
      "type": "naics",
      "value": "453998",
      "label": "453998 - All Other Miscellaneous Store Retailers (except Tobacco Stores)"
   },
   {
      "type": "naics",
      "value": "454111",
      "label": "454111 - Electronic Shopping"
   },
   {
      "type": "naics",
      "value": "454112",
      "label": "454112 - Electronic Auctions"
   },
   {
      "type": "naics",
      "value": "454113",
      "label": "454113 - Mail-Order Houses"
   },
   {
      "type": "naics",
      "value": "454210",
      "label": "454210 - Vending Machine Operators"
   },
   {
      "type": "naics",
      "value": "454311",
      "label": "454311 - Heating Oil Dealers"
   },
   {
      "type": "naics",
      "value": "454312",
      "label": "454312 - Liquefied Petroleum Gas (Bottled Gas) Dealers"
   },
   {
      "type": "naics",
      "value": "454319",
      "label": "454319 - Other Fuel Dealers"
   },
   {
      "type": "naics",
      "value": "454390",
      "label": "454390 - Other Direct Selling Establishments"
   },
   {
      "type": "naics",
      "value": "481111",
      "label": "481111 - Scheduled Passenger Air Transportation"
   },
   {
      "type": "naics",
      "value": "481112",
      "label": "481112 - Scheduled Freight Air Transportation"
   },
   {
      "type": "naics",
      "value": "481211",
      "label": "481211 - Nonscheduled Chartered Passenger Air Transportation"
   },
   {
      "type": "naics",
      "value": "481212",
      "label": "481212 - Nonscheduled Chartered Freight Air Transportation"
   },
   {
      "type": "naics",
      "value": "481219",
      "label": "481219 - Other Nonscheduled Air Transportation"
   },
   {
      "type": "naics",
      "value": "482111",
      "label": "482111 - Line-Haul Railroads"
   },
   {
      "type": "naics",
      "value": "482112",
      "label": "482112 - Short Line Railroads"
   },
   {
      "type": "naics",
      "value": "483111",
      "label": "483111 - Deep Sea Freight Transportation"
   },
   {
      "type": "naics",
      "value": "483112",
      "label": "483112 - Deep Sea Passenger Transportation"
   },
   {
      "type": "naics",
      "value": "483113",
      "label": "483113 - Coastal and Great Lakes Freight Transportation"
   },
   {
      "type": "naics",
      "value": "483114",
      "label": "483114 - Coastal and Great Lakes Passenger Transportation"
   },
   {
      "type": "naics",
      "value": "483211",
      "label": "483211 - Inland Water Freight Transportation"
   },
   {
      "type": "naics",
      "value": "483212",
      "label": "483212 - Inland Water Passenger Transportation"
   },
   {
      "type": "naics",
      "value": "484110",
      "label": "484110 - General Freight Trucking"
   },
   {
      "type": "naics",
      "value": "484121",
      "label": "484121 - General Freight Trucking"
   },
   {
      "type": "naics",
      "value": "484122",
      "label": "484122 - General Freight Trucking"
   },
   {
      "type": "naics",
      "value": "484210",
      "label": "484210 - Used Household and Office Goods Moving"
   },
   {
      "type": "naics",
      "value": "484220",
      "label": "484220 - Specialized Freight (except Used Goods) Trucking"
   },
   {
      "type": "naics",
      "value": "484230",
      "label": "484230 - Specialized Freight (except Used Goods) Trucking"
   },
   {
      "type": "naics",
      "value": "485111",
      "label": "485111 - Mixed Mode Transit Systems"
   },
   {
      "type": "naics",
      "value": "485112",
      "label": "485112 - Commuter Rail Systems"
   },
   {
      "type": "naics",
      "value": "485113",
      "label": "485113 - Bus and Other Motor Vehicle Transit Systems"
   },
   {
      "type": "naics",
      "value": "485119",
      "label": "485119 - Other Urban Transit Systems"
   },
   {
      "type": "naics",
      "value": "485210",
      "label": "485210 - Interurban and Rural Bus Transportation"
   },
   {
      "type": "naics",
      "value": "485310",
      "label": "485310 - Taxi Service"
   },
   {
      "type": "naics",
      "value": "485320",
      "label": "485320 - Limousine Service"
   },
   {
      "type": "naics",
      "value": "485410",
      "label": "485410 - School and Employee Bus Transportation"
   },
   {
      "type": "naics",
      "value": "485510",
      "label": "485510 - Charter Bus Industry"
   },
   {
      "type": "naics",
      "value": "485991",
      "label": "485991 - Special Needs Transportation"
   },
   {
      "type": "naics",
      "value": "485999",
      "label": "485999 - All Other Transit and Ground Passenger Transportation"
   },
   {
      "type": "naics",
      "value": "486110",
      "label": "486110 - Pipeline Transportation of Crude Oil"
   },
   {
      "type": "naics",
      "value": "486210",
      "label": "486210 - Pipeline Transportation of Natural Gas"
   },
   {
      "type": "naics",
      "value": "486910",
      "label": "486910 - Pipeline Transportation of Refined Petroleum Products"
   },
   {
      "type": "naics",
      "value": "486990",
      "label": "486990 - All Other Pipeline Transportation"
   },
   {
      "type": "naics",
      "value": "487110",
      "label": "487110 - Scenic and Sightseeing Transportation"
   },
   {
      "type": "naics",
      "value": "487210",
      "label": "487210 - Scenic and Sightseeing Transportation"
   },
   {
      "type": "naics",
      "value": "487990",
      "label": "487990 - Scenic and Sightseeing Transportation"
   },
   {
      "type": "naics",
      "value": "488111",
      "label": "488111 - Air Traffic Control"
   },
   {
      "type": "naics",
      "value": "488119",
      "label": "488119 - Other Airport Operations"
   },
   {
      "type": "naics",
      "value": "488190",
      "label": "488190 - Other Support Activities for Air Transportation"
   },
   {
      "type": "naics",
      "value": "488210",
      "label": "488210 - Support Activities for Rail Transportation"
   },
   {
      "type": "naics",
      "value": "488310",
      "label": "488310 - Port and Harbor Operations"
   },
   {
      "type": "naics",
      "value": "488320",
      "label": "488320 - Marine Cargo Handling"
   },
   {
      "type": "naics",
      "value": "488330",
      "label": "488330 - Navigational Services to Shipping"
   },
   {
      "type": "naics",
      "value": "488390",
      "label": "488390 - Other Support Activities for Water Transportation"
   },
   {
      "type": "naics",
      "value": "488410",
      "label": "488410 - Motor Vehicle Towing"
   },
   {
      "type": "naics",
      "value": "488490",
      "label": "488490 - Other Support Activities for Road Transportation"
   },
   {
      "type": "naics",
      "value": "488510",
      "label": "488510 - Freight Transportation Arrangement"
   },
   {
      "type": "naics",
      "value": "488991",
      "label": "488991 - Packing and Crating"
   },
   {
      "type": "naics",
      "value": "488999",
      "label": "488999 - All Other Support Activities for Transportation"
   },
   {
      "type": "naics",
      "value": "491110",
      "label": "491110 - Postal Service"
   },
   {
      "type": "naics",
      "value": "492110",
      "label": "492110 - Couriers"
   },
   {
      "type": "naics",
      "value": "492210",
      "label": "492210 - Local Messengers and Local Delivery"
   },
   {
      "type": "naics",
      "value": "493110",
      "label": "493110 - General Warehousing and Storage"
   },
   {
      "type": "naics",
      "value": "493120",
      "label": "493120 - Refrigerated Warehousing and Storage"
   },
   {
      "type": "naics",
      "value": "493130",
      "label": "493130 - Farm Product Warehousing and Storage"
   },
   {
      "type": "naics",
      "value": "493190",
      "label": "493190 - Other Warehousing and Storage"
   },
   {
      "type": "naics",
      "value": "511110",
      "label": "511110 - Newspaper Publishers"
   },
   {
      "type": "naics",
      "value": "511120",
      "label": "511120 - Periodical Publishers"
   },
   {
      "type": "naics",
      "value": "511130",
      "label": "511130 - Book Publishers"
   },
   {
      "type": "naics",
      "value": "511140",
      "label": "511140 - Directory and Mailing List Publishers"
   },
   {
      "type": "naics",
      "value": "511191",
      "label": "511191 - Greeting Card Publishers"
   },
   {
      "type": "naics",
      "value": "511199",
      "label": "511199 - All Other Publishers"
   },
   {
      "type": "naics",
      "value": "511210",
      "label": "511210 - Software Publishers"
   },
   {
      "type": "naics",
      "value": "512110",
      "label": "512110 - Motion Picture and Video Production"
   },
   {
      "type": "naics",
      "value": "512120",
      "label": "512120 - Motion Picture and Video Distribution"
   },
   {
      "type": "naics",
      "value": "512131",
      "label": "512131 - Motion Picture Theaters (except Drive-Ins)"
   },
   {
      "type": "naics",
      "value": "512132",
      "label": "512132 - Drive-In Motion Picture Theaters"
   },
   {
      "type": "naics",
      "value": "512191",
      "label": "512191 - Teleproduction and Other Postproduction Services"
   },
   {
      "type": "naics",
      "value": "512199",
      "label": "512199 - Other Motion Picture and Video Industries"
   },
   {
      "type": "naics",
      "value": "512210",
      "label": "512210 - Record Production"
   },
   {
      "type": "naics",
      "value": "512220",
      "label": "512220 - Integrated Record Production/Distribution"
   },
   {
      "type": "naics",
      "value": "512230",
      "label": "512230 - Music Publishers"
   },
   {
      "type": "naics",
      "value": "512240",
      "label": "512240 - Sound Recording Studios"
   },
   {
      "type": "naics",
      "value": "512290",
      "label": "512290 - Other Sound Recording Industries"
   },
   {
      "type": "naics",
      "value": "515111",
      "label": "515111 - Radio Networks"
   },
   {
      "type": "naics",
      "value": "515112",
      "label": "515112 - Radio Stations"
   },
   {
      "type": "naics",
      "value": "515120",
      "label": "515120 - Television Broadcasting"
   },
   {
      "type": "naics",
      "value": "515210",
      "label": "515210 - Cable and Other Subscription Programming"
   },
   {
      "type": "naics",
      "value": "516110",
      "label": "516110 - Internet Publishing and Broadcasting"
   },
   {
      "type": "naics",
      "value": "517110",
      "label": "517110 - Wired Telecommunications Carriers"
   },
   {
      "type": "naics",
      "value": "517211",
      "label": "517211 - Paging"
   },
   {
      "type": "naics",
      "value": "517212",
      "label": "517212 - Cellular and Other Wireless Telecommunications"
   },
   {
      "type": "naics",
      "value": "517310",
      "label": "517310 - Telecommunications Resellers"
   },
   {
      "type": "naics",
      "value": "517410",
      "label": "517410 - Satellite Telecommunications"
   },
   {
      "type": "naics",
      "value": "517510",
      "label": "517510 - Cable and Other Program Distribution"
   },
   {
      "type": "naics",
      "value": "517910",
      "label": "517910 - Other Telecommunications"
   },
   {
      "type": "naics",
      "value": "518111",
      "label": "518111 - Internet Service Providers"
   },
   {
      "type": "naics",
      "value": "518112",
      "label": "518112 - Web Search Portals"
   },
   {
      "type": "naics",
      "value": "518210",
      "label": "518210 - Data Processing"
   },
   {
      "type": "naics",
      "value": "519110",
      "label": "519110 - News Syndicates"
   },
   {
      "type": "naics",
      "value": "519120",
      "label": "519120 - Libraries and Archives"
   },
   {
      "type": "naics",
      "value": "519190",
      "label": "519190 - All Other Information Services"
   },
   {
      "type": "naics",
      "value": "521110",
      "label": "521110 - Monetary Authorities - Central Bank"
   },
   {
      "type": "naics",
      "value": "522110",
      "label": "522110 - Commercial Banking"
   },
   {
      "type": "naics",
      "value": "522120",
      "label": "522120 - Savings Institutions"
   },
   {
      "type": "naics",
      "value": "522130",
      "label": "522130 - Credit Unions"
   },
   {
      "type": "naics",
      "value": "522190",
      "label": "522190 - Other Depository Credit Intermediation"
   },
   {
      "type": "naics",
      "value": "522210",
      "label": "522210 - Credit Card Issuing"
   },
   {
      "type": "naics",
      "value": "522220",
      "label": "522220 - Sales Financing"
   },
   {
      "type": "naics",
      "value": "522291",
      "label": "522291 - Consumer Lending"
   },
   {
      "type": "naics",
      "value": "522292",
      "label": "522292 - Real Estate Credit"
   },
   {
      "type": "naics",
      "value": "522293",
      "label": "522293 - International Trade Financing"
   },
   {
      "type": "naics",
      "value": "522294",
      "label": "522294 - Secondary Market Financing"
   },
   {
      "type": "naics",
      "value": "522298",
      "label": "522298 - All Other Nondepository Credit Intermediation"
   },
   {
      "type": "naics",
      "value": "522310",
      "label": "522310 - Mortgage and Nonmortgage Loan Brokers"
   },
   {
      "type": "naics",
      "value": "522320",
      "label": "522320 - Financial Transactions Processing"
   },
   {
      "type": "naics",
      "value": "522390",
      "label": "522390 - Other Activities Related to Credit Intermediation"
   },
   {
      "type": "naics",
      "value": "523110",
      "label": "523110 - Investment Banking and Securities Dealing"
   },
   {
      "type": "naics",
      "value": "523120",
      "label": "523120 - Securities Brokerage"
   },
   {
      "type": "naics",
      "value": "523130",
      "label": "523130 - Commodity Contracts Dealing"
   },
   {
      "type": "naics",
      "value": "523140",
      "label": "523140 - Commodity Contracts Brokerage"
   },
   {
      "type": "naics",
      "value": "523210",
      "label": "523210 - Securities and Commodity Exchanges"
   },
   {
      "type": "naics",
      "value": "523910",
      "label": "523910 - Miscellaneous Intermediation"
   },
   {
      "type": "naics",
      "value": "523920",
      "label": "523920 - Portfolio Management"
   },
   {
      "type": "naics",
      "value": "523930",
      "label": "523930 - Investment Advice"
   },
   {
      "type": "naics",
      "value": "523991",
      "label": "523991 - Trust"
   },
   {
      "type": "naics",
      "value": "523999",
      "label": "523999 - Miscellaneous Financial Investment Activities"
   },
   {
      "type": "naics",
      "value": "524113",
      "label": "524113 - Direct Life Insurance Carriers"
   },
   {
      "type": "naics",
      "value": "524114",
      "label": "524114 - Direct Health and Medical Insurance Carriers"
   },
   {
      "type": "naics",
      "value": "524126",
      "label": "524126 - Direct Property and Casualty Insurance Carriers"
   },
   {
      "type": "naics",
      "value": "524127",
      "label": "524127 - Direct Title Insurance Carriers"
   },
   {
      "type": "naics",
      "value": "524128",
      "label": "524128 - Other Direct Insurance (except Life"
   },
   {
      "type": "naics",
      "value": "524130",
      "label": "524130 - Reinsurance Carriers"
   },
   {
      "type": "naics",
      "value": "524210",
      "label": "524210 - Insurance Agencies and Brokerages"
   },
   {
      "type": "naics",
      "value": "524291",
      "label": "524291 - Claims Adjusting"
   },
   {
      "type": "naics",
      "value": "524292",
      "label": "524292 - Third Party Administration of Insurance and Pension Funds"
   },
   {
      "type": "naics",
      "value": "524298",
      "label": "524298 - All Other Insurance Related Activities"
   },
   {
      "type": "naics",
      "value": "525110",
      "label": "525110 - Pension Funds"
   },
   {
      "type": "naics",
      "value": "525120",
      "label": "525120 - Health and Welfare Funds"
   },
   {
      "type": "naics",
      "value": "525190",
      "label": "525190 - Other Insurance Funds"
   },
   {
      "type": "naics",
      "value": "525910",
      "label": "525910 - Open-End Investment Funds"
   },
   {
      "type": "naics",
      "value": "525920",
      "label": "525920 - Trusts"
   },
   {
      "type": "naics",
      "value": "525930",
      "label": "525930 - Real Estate Investment Trusts"
   },
   {
      "type": "naics",
      "value": "525990",
      "label": "525990 - Other Financial Vehicles"
   },
   {
      "type": "naics",
      "value": "531110",
      "label": "531110 - Lessors of Residential Buildings and Dwellings"
   },
   {
      "type": "naics",
      "value": "531120",
      "label": "531120 - Lessors of Nonresidential Buildings (except Miniwarehouses)"
   },
   {
      "type": "naics",
      "value": "531130",
      "label": "531130 - Lessors of Miniwarehouses and Self-Storage Units"
   },
   {
      "type": "naics",
      "value": "531190",
      "label": "531190 - Lessors of Other Real Estate Property"
   },
   {
      "type": "naics",
      "value": "531210",
      "label": "531210 - Offices of Real Estate Agents and Brokers"
   },
   {
      "type": "naics",
      "value": "531311",
      "label": "531311 - Residential Property Managers"
   },
   {
      "type": "naics",
      "value": "531312",
      "label": "531312 - Nonresidential Property Managers"
   },
   {
      "type": "naics",
      "value": "531320",
      "label": "531320 - Offices of Real Estate Appraisers"
   },
   {
      "type": "naics",
      "value": "531390",
      "label": "531390 - Other Activities Related to Real Estate"
   },
   {
      "type": "naics",
      "value": "532111",
      "label": "532111 - Passenger Car Rental"
   },
   {
      "type": "naics",
      "value": "532112",
      "label": "532112 - Passenger Car Leasing"
   },
   {
      "type": "naics",
      "value": "532120",
      "label": "532120 - Truck"
   },
   {
      "type": "naics",
      "value": "532210",
      "label": "532210 - Consumer Electronics and Appliances Rental"
   },
   {
      "type": "naics",
      "value": "532220",
      "label": "532220 - Formal Wear and Costume Rental"
   },
   {
      "type": "naics",
      "value": "532230",
      "label": "532230 - Video Tape and Disc Rental"
   },
   {
      "type": "naics",
      "value": "532291",
      "label": "532291 - Home Health Equipment Rental"
   },
   {
      "type": "naics",
      "value": "532292",
      "label": "532292 - Recreational Goods Rental"
   },
   {
      "type": "naics",
      "value": "532299",
      "label": "532299 - All Other Consumer Goods Rental"
   },
   {
      "type": "naics",
      "value": "532310",
      "label": "532310 - General Rental Centers"
   },
   {
      "type": "naics",
      "value": "532411",
      "label": "532411 - Commercial Air"
   },
   {
      "type": "naics",
      "value": "532412",
      "label": "532412 - Construction"
   },
   {
      "type": "naics",
      "value": "532420",
      "label": "532420 - Office Machinery and Equipment Rental and Leasing"
   },
   {
      "type": "naics",
      "value": "532490",
      "label": "532490 - Other Commercial and Industrial Machinery and Equipment Rental and Leasing"
   },
   {
      "type": "naics",
      "value": "533110",
      "label": "533110 - Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)"
   },
   {
      "type": "naics",
      "value": "541110",
      "label": "541110 - Offices of Lawyers"
   },
   {
      "type": "naics",
      "value": "541120",
      "label": "541120 - Offices of Notaries"
   },
   {
      "type": "naics",
      "value": "541191",
      "label": "541191 - Title Abstract and Settlement Offices"
   },
   {
      "type": "naics",
      "value": "541199",
      "label": "541199 - All Other Legal Services"
   },
   {
      "type": "naics",
      "value": "541211",
      "label": "541211 - Offices of Certified Public Accountants"
   },
   {
      "type": "naics",
      "value": "541213",
      "label": "541213 - Tax Preparation Services"
   },
   {
      "type": "naics",
      "value": "541214",
      "label": "541214 - Payroll Services"
   },
   {
      "type": "naics",
      "value": "541219",
      "label": "541219 - Other Accounting Services"
   },
   {
      "type": "naics",
      "value": "541310",
      "label": "541310 - Architectural Services"
   },
   {
      "type": "naics",
      "value": "541320",
      "label": "541320 - Landscape Architectural Services"
   },
   {
      "type": "naics",
      "value": "541330",
      "label": "541330 - Engineering Services"
   },
   {
      "type": "naics",
      "value": "541340",
      "label": "541340 - Drafting Services"
   },
   {
      "type": "naics",
      "value": "541350",
      "label": "541350 - Building Inspection Services"
   },
   {
      "type": "naics",
      "value": "541360",
      "label": "541360 - Geophysical Surveying and Mapping Services"
   },
   {
      "type": "naics",
      "value": "541370",
      "label": "541370 - Surveying and Mapping (except Geophysical) Services"
   },
   {
      "type": "naics",
      "value": "541380",
      "label": "541380 - Testing Laboratories"
   },
   {
      "type": "naics",
      "value": "541410",
      "label": "541410 - Interior Design Services"
   },
   {
      "type": "naics",
      "value": "541420",
      "label": "541420 - Industrial Design Services"
   },
   {
      "type": "naics",
      "value": "541430",
      "label": "541430 - Graphic Design Services"
   },
   {
      "type": "naics",
      "value": "541490",
      "label": "541490 - Other Specialized Design Services"
   },
   {
      "type": "naics",
      "value": "541511",
      "label": "541511 - Custom Computer Programming Services"
   },
   {
      "type": "naics",
      "value": "541512",
      "label": "541512 - Computer Systems Design Services"
   },
   {
      "type": "naics",
      "value": "541513",
      "label": "541513 - Computer Facilities Management Services"
   },
   {
      "type": "naics",
      "value": "541519",
      "label": "541519 - Other Computer Related Services"
   },
   {
      "type": "naics",
      "value": "541611",
      "label": "541611 - Administrative Management and General Management Consulting Services"
   },
   {
      "type": "naics",
      "value": "541612",
      "label": "541612 - Human Resources and Executive Search Consulting Services"
   },
   {
      "type": "naics",
      "value": "541613",
      "label": "541613 - Marketing Consulting Services"
   },
   {
      "type": "naics",
      "value": "541614",
      "label": "541614 - Process"
   },
   {
      "type": "naics",
      "value": "541618",
      "label": "541618 - Other Management Consulting Services"
   },
   {
      "type": "naics",
      "value": "541620",
      "label": "541620 - Environmental Consulting Services"
   },
   {
      "type": "naics",
      "value": "541690",
      "label": "541690 - Other Scientific and Technical Consulting Services"
   },
   {
      "type": "naics",
      "value": "541710",
      "label": "541710 - Research and Development in the Physical"
   },
   {
      "type": "naics",
      "value": "541720",
      "label": "541720 - Research and Development in the Social Sciences and Humanities"
   },
   {
      "type": "naics",
      "value": "541810",
      "label": "541810 - Advertising Agencies"
   },
   {
      "type": "naics",
      "value": "541820",
      "label": "541820 - Public Relations Agencies"
   },
   {
      "type": "naics",
      "value": "541830",
      "label": "541830 - Media Buying Agencies"
   },
   {
      "type": "naics",
      "value": "541840",
      "label": "541840 - Media Representatives"
   },
   {
      "type": "naics",
      "value": "541850",
      "label": "541850 - Display Advertising"
   },
   {
      "type": "naics",
      "value": "541860",
      "label": "541860 - Direct Mail Advertising"
   },
   {
      "type": "naics",
      "value": "541870",
      "label": "541870 - Advertising Material Distribution Services"
   },
   {
      "type": "naics",
      "value": "541890",
      "label": "541890 - Other Services Related to Advertising"
   },
   {
      "type": "naics",
      "value": "541910",
      "label": "541910 - Marketing Research and Public Opinion Polling"
   },
   {
      "type": "naics",
      "value": "541921",
      "label": "541921 - Photography Studios"
   },
   {
      "type": "naics",
      "value": "541922",
      "label": "541922 - Commercial Photography"
   },
   {
      "type": "naics",
      "value": "541930",
      "label": "541930 - Translation and Interpretation Services"
   },
   {
      "type": "naics",
      "value": "541940",
      "label": "541940 - Veterinary Services"
   },
   {
      "type": "naics",
      "value": "541990",
      "label": "541990 - All Other Professional"
   },
   {
      "type": "naics",
      "value": "551111",
      "label": "551111 - Offices of Bank Holding Companies"
   },
   {
      "type": "naics",
      "value": "551112",
      "label": "551112 - Offices of Other Holding Companies"
   },
   {
      "type": "naics",
      "value": "551114",
      "label": "551114 - Corporate"
   },
   {
      "type": "naics",
      "value": "561110",
      "label": "561110 - Office Administrative Services"
   },
   {
      "type": "naics",
      "value": "561210",
      "label": "561210 - Facilities Support Services"
   },
   {
      "type": "naics",
      "value": "561310",
      "label": "561310 - Employment Placement Agencies"
   },
   {
      "type": "naics",
      "value": "561320",
      "label": "561320 - Temporary Help Services"
   },
   {
      "type": "naics",
      "value": "561330",
      "label": "561330 - Professional Employer Organizations"
   },
   {
      "type": "naics",
      "value": "561410",
      "label": "561410 - Document Preparation Services"
   },
   {
      "type": "naics",
      "value": "561421",
      "label": "561421 - Telephone Answering Services"
   },
   {
      "type": "naics",
      "value": "561422",
      "label": "561422 - Telemarketing Bureaus"
   },
   {
      "type": "naics",
      "value": "561431",
      "label": "561431 - Private Mail Centers"
   },
   {
      "type": "naics",
      "value": "561439",
      "label": "561439 - Other Business Service Centers (including Copy Shops)"
   },
   {
      "type": "naics",
      "value": "561440",
      "label": "561440 - Collection Agencies"
   },
   {
      "type": "naics",
      "value": "561450",
      "label": "561450 - Credit Bureaus"
   },
   {
      "type": "naics",
      "value": "561491",
      "label": "561491 - Repossession Services"
   },
   {
      "type": "naics",
      "value": "561492",
      "label": "561492 - Court Reporting and Stenotype Services"
   },
   {
      "type": "naics",
      "value": "561499",
      "label": "561499 - All Other Business Support Services"
   },
   {
      "type": "naics",
      "value": "561510",
      "label": "561510 - Travel Agencies"
   },
   {
      "type": "naics",
      "value": "561520",
      "label": "561520 - Tour Operators"
   },
   {
      "type": "naics",
      "value": "561591",
      "label": "561591 - Convention and Visitors Bureaus"
   },
   {
      "type": "naics",
      "value": "561599",
      "label": "561599 - All Other Travel Arrangement and Reservation Services"
   },
   {
      "type": "naics",
      "value": "561611",
      "label": "561611 - Investigation Services"
   },
   {
      "type": "naics",
      "value": "561612",
      "label": "561612 - Security Guards and Patrol Services"
   },
   {
      "type": "naics",
      "value": "561613",
      "label": "561613 - Armored Car Services"
   },
   {
      "type": "naics",
      "value": "561621",
      "label": "561621 - Security Systems Services (except Locksmiths)"
   },
   {
      "type": "naics",
      "value": "561622",
      "label": "561622 - Locksmiths"
   },
   {
      "type": "naics",
      "value": "561710",
      "label": "561710 - Exterminating and Pest Control Services"
   },
   {
      "type": "naics",
      "value": "561720",
      "label": "561720 - Janitorial Services"
   },
   {
      "type": "naics",
      "value": "561730",
      "label": "561730 - Landscaping Services"
   },
   {
      "type": "naics",
      "value": "561740",
      "label": "561740 - Carpet and Upholstery Cleaning Services"
   },
   {
      "type": "naics",
      "value": "561790",
      "label": "561790 - Other Services to Buildings and Dwellings"
   },
   {
      "type": "naics",
      "value": "561910",
      "label": "561910 - Packaging and Labeling Services"
   },
   {
      "type": "naics",
      "value": "561920",
      "label": "561920 - Convention and Trade Show Organizers"
   },
   {
      "type": "naics",
      "value": "561990",
      "label": "561990 - All Other Support Services"
   },
   {
      "type": "naics",
      "value": "562111",
      "label": "562111 - Solid Waste Collection"
   },
   {
      "type": "naics",
      "value": "562112",
      "label": "562112 - Hazardous Waste Collection"
   },
   {
      "type": "naics",
      "value": "562119",
      "label": "562119 - Other Waste Collection"
   },
   {
      "type": "naics",
      "value": "562211",
      "label": "562211 - Hazardous Waste Treatment and Disposal"
   },
   {
      "type": "naics",
      "value": "562212",
      "label": "562212 - Solid Waste Landfill"
   },
   {
      "type": "naics",
      "value": "562213",
      "label": "562213 - Solid Waste Combustors and Incinerators"
   },
   {
      "type": "naics",
      "value": "562219",
      "label": "562219 - Other Nonhazardous Waste Treatment and Disposal"
   },
   {
      "type": "naics",
      "value": "562910",
      "label": "562910 - Remediation Services"
   },
   {
      "type": "naics",
      "value": "562920",
      "label": "562920 - Materials Recovery Facilities"
   },
   {
      "type": "naics",
      "value": "562991",
      "label": "562991 - Septic Tank and Related Services"
   },
   {
      "type": "naics",
      "value": "562998",
      "label": "562998 - All Other Miscellaneous Waste Management Services"
   },
   {
      "type": "naics",
      "value": "611110",
      "label": "611110 - Elementary and Secondary Schools"
   },
   {
      "type": "naics",
      "value": "611210",
      "label": "611210 - Junior Colleges"
   },
   {
      "type": "naics",
      "value": "611310",
      "label": "611310 - Colleges"
   },
   {
      "type": "naics",
      "value": "611410",
      "label": "611410 - Business and Secretarial Schools"
   },
   {
      "type": "naics",
      "value": "611420",
      "label": "611420 - Computer Training"
   },
   {
      "type": "naics",
      "value": "611430",
      "label": "611430 - Professional and Management Development Training"
   },
   {
      "type": "naics",
      "value": "611511",
      "label": "611511 - Cosmetology and Barber Schools"
   },
   {
      "type": "naics",
      "value": "611512",
      "label": "611512 - Flight Training"
   },
   {
      "type": "naics",
      "value": "611513",
      "label": "611513 - Apprenticeship Training"
   },
   {
      "type": "naics",
      "value": "611519",
      "label": "611519 - Other Technical and Trade Schools"
   },
   {
      "type": "naics",
      "value": "611610",
      "label": "611610 - Fine Arts Schools"
   },
   {
      "type": "naics",
      "value": "611620",
      "label": "611620 - Sports and Recreation Instruction"
   },
   {
      "type": "naics",
      "value": "611630",
      "label": "611630 - Language Schools"
   },
   {
      "type": "naics",
      "value": "611691",
      "label": "611691 - Exam Preparation and Tutoring"
   },
   {
      "type": "naics",
      "value": "611692",
      "label": "611692 - Automobile Driving Schools"
   },
   {
      "type": "naics",
      "value": "611699",
      "label": "611699 - All Other Miscellaneous Schools and Instruction"
   },
   {
      "type": "naics",
      "value": "611710",
      "label": "611710 - Educational Support Services"
   },
   {
      "type": "naics",
      "value": "621111",
      "label": "621111 - Offices of Physicians (except Mental Health Specialists)"
   },
   {
      "type": "naics",
      "value": "621112",
      "label": "621112 - Offices of Physicians"
   },
   {
      "type": "naics",
      "value": "621210",
      "label": "621210 - Offices of Dentists"
   },
   {
      "type": "naics",
      "value": "621310",
      "label": "621310 - Offices of Chiropractors"
   },
   {
      "type": "naics",
      "value": "621320",
      "label": "621320 - Offices of Optometrists"
   },
   {
      "type": "naics",
      "value": "621330",
      "label": "621330 - Offices of Mental Health Practitioners (except Physicians)"
   },
   {
      "type": "naics",
      "value": "621340",
      "label": "621340 - Offices of Physical"
   },
   {
      "type": "naics",
      "value": "621391",
      "label": "621391 - Offices of Podiatrists"
   },
   {
      "type": "naics",
      "value": "621399",
      "label": "621399 - Offices of All Other Miscellaneous Health Practitioners"
   },
   {
      "type": "naics",
      "value": "621410",
      "label": "621410 - Family Planning Centers"
   },
   {
      "type": "naics",
      "value": "621420",
      "label": "621420 - Outpatient Mental Health and Substance Abuse Centers"
   },
   {
      "type": "naics",
      "value": "621491",
      "label": "621491 - HMO Medical Centers"
   },
   {
      "type": "naics",
      "value": "621492",
      "label": "621492 - Kidney Dialysis Centers"
   },
   {
      "type": "naics",
      "value": "621493",
      "label": "621493 - Freestanding Ambulatory Surgical and Emergency Centers"
   },
   {
      "type": "naics",
      "value": "621498",
      "label": "621498 - All Other Outpatient Care Centers"
   },
   {
      "type": "naics",
      "value": "621511",
      "label": "621511 - Medical Laboratories"
   },
   {
      "type": "naics",
      "value": "621512",
      "label": "621512 - Diagnostic Imaging Centers"
   },
   {
      "type": "naics",
      "value": "621610",
      "label": "621610 - Home Health Care Services"
   },
   {
      "type": "naics",
      "value": "621910",
      "label": "621910 - Ambulance Services"
   },
   {
      "type": "naics",
      "value": "621991",
      "label": "621991 - Blood and Organ Banks"
   },
   {
      "type": "naics",
      "value": "621999",
      "label": "621999 - All Other Miscellaneous Ambulatory Health Care Services"
   },
   {
      "type": "naics",
      "value": "622110",
      "label": "622110 - General Medical and Surgical Hospitals"
   },
   {
      "type": "naics",
      "value": "622210",
      "label": "622210 - Psychiatric and Substance Abuse Hospitals"
   },
   {
      "type": "naics",
      "value": "622310",
      "label": "622310 - Specialty (except Psychiatric and Substance Abuse) Hospitals"
   },
   {
      "type": "naics",
      "value": "623110",
      "label": "623110 - Nursing Care Facilities"
   },
   {
      "type": "naics",
      "value": "623210",
      "label": "623210 - Residential Mental Retardation Facilities"
   },
   {
      "type": "naics",
      "value": "623220",
      "label": "623220 - Residential Mental Health and Substance Abuse Facilities"
   },
   {
      "type": "naics",
      "value": "623311",
      "label": "623311 - Continuing Care Retirement Communities"
   },
   {
      "type": "naics",
      "value": "623312",
      "label": "623312 - Homes for the Elderly"
   },
   {
      "type": "naics",
      "value": "623990",
      "label": "623990 - Other Residential Care Facilities"
   },
   {
      "type": "naics",
      "value": "624110",
      "label": "624110 - Child and Youth Services"
   },
   {
      "type": "naics",
      "value": "624120",
      "label": "624120 - Services for the Elderly and Persons with Disabilities"
   },
   {
      "type": "naics",
      "value": "624190",
      "label": "624190 - Other Individual and Family Services"
   },
   {
      "type": "naics",
      "value": "624210",
      "label": "624210 - Community Food Services"
   },
   {
      "type": "naics",
      "value": "624221",
      "label": "624221 - Temporary Shelters"
   },
   {
      "type": "naics",
      "value": "624229",
      "label": "624229 - Other Community Housing Services"
   },
   {
      "type": "naics",
      "value": "624230",
      "label": "624230 - Emergency and Other Relief Services"
   },
   {
      "type": "naics",
      "value": "624310",
      "label": "624310 - Vocational Rehabilitation Services"
   },
   {
      "type": "naics",
      "value": "624410",
      "label": "624410 - Child Day Care Services"
   },
   {
      "type": "naics",
      "value": "711110",
      "label": "711110 - Theater Companies and Dinner Theaters"
   },
   {
      "type": "naics",
      "value": "711120",
      "label": "711120 - Dance Companies"
   },
   {
      "type": "naics",
      "value": "711130",
      "label": "711130 - Musical Groups and Artists"
   },
   {
      "type": "naics",
      "value": "711190",
      "label": "711190 - Other Performing Arts Companies"
   },
   {
      "type": "naics",
      "value": "711211",
      "label": "711211 - Sports Teams and Clubs"
   },
   {
      "type": "naics",
      "value": "711212",
      "label": "711212 - Racetracks"
   },
   {
      "type": "naics",
      "value": "711219",
      "label": "711219 - Other Spectator Sports"
   },
   {
      "type": "naics",
      "value": "711310",
      "label": "711310 - Promoters of Performing Arts"
   },
   {
      "type": "naics",
      "value": "711320",
      "label": "711320 - Promoters of Performing Arts"
   },
   {
      "type": "naics",
      "value": "711410",
      "label": "711410 - Agents and Managers for Artists"
   },
   {
      "type": "naics",
      "value": "711510",
      "label": "711510 - Independent Artists"
   },
   {
      "type": "naics",
      "value": "712110",
      "label": "712110 - Museums"
   },
   {
      "type": "naics",
      "value": "712120",
      "label": "712120 - Historical Sites"
   },
   {
      "type": "naics",
      "value": "712130",
      "label": "712130 - Zoos and Botanical Gardens"
   },
   {
      "type": "naics",
      "value": "712190",
      "label": "712190 - Nature Parks and Other Similar Institutions"
   },
   {
      "type": "naics",
      "value": "713110",
      "label": "713110 - Amusement and Theme Parks"
   },
   {
      "type": "naics",
      "value": "713120",
      "label": "713120 - Amusement Arcades"
   },
   {
      "type": "naics",
      "value": "713210",
      "label": "713210 - Casinos (except Casino Hotels)"
   },
   {
      "type": "naics",
      "value": "713290",
      "label": "713290 - Other Gambling Industries"
   },
   {
      "type": "naics",
      "value": "713910",
      "label": "713910 - Golf Courses and Country Clubs"
   },
   {
      "type": "naics",
      "value": "713920",
      "label": "713920 - Skiing Facilities"
   },
   {
      "type": "naics",
      "value": "713930",
      "label": "713930 - Marinas"
   },
   {
      "type": "naics",
      "value": "713940",
      "label": "713940 - Fitness and Recreational Sports Centers"
   },
   {
      "type": "naics",
      "value": "713950",
      "label": "713950 - Bowling Centers"
   },
   {
      "type": "naics",
      "value": "713990",
      "label": "713990 - All Other Amusement and Recreation Industries"
   },
   {
      "type": "naics",
      "value": "721110",
      "label": "721110 - Hotels (except Casino Hotels) and Motels"
   },
   {
      "type": "naics",
      "value": "721120",
      "label": "721120 - Casino Hotels"
   },
   {
      "type": "naics",
      "value": "721191",
      "label": "721191 - Bed-and-Breakfast Inns"
   },
   {
      "type": "naics",
      "value": "721199",
      "label": "721199 - All Other Traveler Accommodation"
   },
   {
      "type": "naics",
      "value": "721211",
      "label": "721211 - RV (Recreational Vehicle) Parks and Campgrounds"
   },
   {
      "type": "naics",
      "value": "721214",
      "label": "721214 - Recreational and Vacation Camps (except Campgrounds)"
   },
   {
      "type": "naics",
      "value": "721310",
      "label": "721310 - Rooming and Boarding Houses"
   },
   {
      "type": "naics",
      "value": "722110",
      "label": "722110 - Full-Service Restaurants"
   },
   {
      "type": "naics",
      "value": "722211",
      "label": "722211 - Limited-Service Restaurants"
   },
   {
      "type": "naics",
      "value": "722212",
      "label": "722212 - Cafeterias"
   },
   {
      "type": "naics",
      "value": "722213",
      "label": "722213 - Snack and Nonalcoholic Beverage Bars"
   },
   {
      "type": "naics",
      "value": "722310",
      "label": "722310 - Food Service Contractors"
   },
   {
      "type": "naics",
      "value": "722320",
      "label": "722320 - Caterers"
   },
   {
      "type": "naics",
      "value": "722330",
      "label": "722330 - Mobile Food Services"
   },
   {
      "type": "naics",
      "value": "722410",
      "label": "722410 - Drinking Places (Alcoholic Beverages)"
   },
   {
      "type": "naics",
      "value": "811111",
      "label": "811111 - General Automotive Repair"
   },
   {
      "type": "naics",
      "value": "811112",
      "label": "811112 - Automotive Exhaust System Repair"
   },
   {
      "type": "naics",
      "value": "811113",
      "label": "811113 - Automotive Transmission Repair"
   },
   {
      "type": "naics",
      "value": "811118",
      "label": "811118 - Other Automotive Mechanical and Electrical Repair and Maintenance"
   },
   {
      "type": "naics",
      "value": "811121",
      "label": "811121 - Automotive Body"
   },
   {
      "type": "naics",
      "value": "811122",
      "label": "811122 - Automotive Glass Replacement Shops"
   },
   {
      "type": "naics",
      "value": "811191",
      "label": "811191 - Automotive Oil Change and Lubrication Shops"
   },
   {
      "type": "naics",
      "value": "811192",
      "label": "811192 - Car Washes"
   },
   {
      "type": "naics",
      "value": "811198",
      "label": "811198 - All Other Automotive Repair and Maintenance"
   },
   {
      "type": "naics",
      "value": "811211",
      "label": "811211 - Consumer Electronics Repair and Maintenance"
   },
   {
      "type": "naics",
      "value": "811212",
      "label": "811212 - Computer and Office Machine Repair and Maintenance"
   },
   {
      "type": "naics",
      "value": "811213",
      "label": "811213 - Communication Equipment Repair and Maintenance"
   },
   {
      "type": "naics",
      "value": "811219",
      "label": "811219 - Other Electronic and Precision Equipment Repair and Maintenance"
   },
   {
      "type": "naics",
      "value": "811310",
      "label": "811310 - Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance"
   },
   {
      "type": "naics",
      "value": "811411",
      "label": "811411 - Home and Garden Equipment Repair and Maintenance"
   },
   {
      "type": "naics",
      "value": "811412",
      "label": "811412 - Appliance Repair and Maintenance"
   },
   {
      "type": "naics",
      "value": "811420",
      "label": "811420 - Reupholstery and Furniture Repair"
   },
   {
      "type": "naics",
      "value": "811430",
      "label": "811430 - Footwear and Leather Goods Repair"
   },
   {
      "type": "naics",
      "value": "811490",
      "label": "811490 - Other Personal and Household Goods Repair and Maintenance"
   },
   {
      "type": "naics",
      "value": "812111",
      "label": "812111 - Barber Shops"
   },
   {
      "type": "naics",
      "value": "812112",
      "label": "812112 - Beauty Salons"
   },
   {
      "type": "naics",
      "value": "812113",
      "label": "812113 - Nail Salons"
   },
   {
      "type": "naics",
      "value": "812191",
      "label": "812191 - Diet and Weight Reducing Centers"
   },
   {
      "type": "naics",
      "value": "812199",
      "label": "812199 - Other Personal Care Services"
   },
   {
      "type": "naics",
      "value": "812210",
      "label": "812210 - Funeral Homes and Funeral Services"
   },
   {
      "type": "naics",
      "value": "812220",
      "label": "812220 - Cemeteries and Crematories"
   },
   {
      "type": "naics",
      "value": "812310",
      "label": "812310 - Coin-Operated Laundries and Drycleaners"
   },
   {
      "type": "naics",
      "value": "812320",
      "label": "812320 - Drycleaning and Laundry Services (except Coin-Operated)"
   },
   {
      "type": "naics",
      "value": "812331",
      "label": "812331 - Linen Supply"
   },
   {
      "type": "naics",
      "value": "812332",
      "label": "812332 - Industrial Launderers"
   },
   {
      "type": "naics",
      "value": "812910",
      "label": "812910 - Pet Care (except Veterinary) Services"
   },
   {
      "type": "naics",
      "value": "812921",
      "label": "812921 - Photofinishing Laboratories (except One-Hour)"
   },
   {
      "type": "naics",
      "value": "812922",
      "label": "812922 - One-Hour Photofinishing"
   },
   {
      "type": "naics",
      "value": "812930",
      "label": "812930 - Parking Lots and Garages"
   },
   {
      "type": "naics",
      "value": "812990",
      "label": "812990 - All Other Personal Services"
   },
   {
      "type": "naics",
      "value": "813110",
      "label": "813110 - Religious Organizations"
   },
   {
      "type": "naics",
      "value": "813211",
      "label": "813211 - Grantmaking Foundations"
   },
   {
      "type": "naics",
      "value": "813212",
      "label": "813212 - Voluntary Health Organizations"
   },
   {
      "type": "naics",
      "value": "813219",
      "label": "813219 - Other Grantmaking and Giving Services"
   },
   {
      "type": "naics",
      "value": "813311",
      "label": "813311 - Human Rights Organizations"
   },
   {
      "type": "naics",
      "value": "813312",
      "label": "813312 - Environment"
   },
   {
      "type": "naics",
      "value": "813319",
      "label": "813319 - Other Social Advocacy Organizations"
   },
   {
      "type": "naics",
      "value": "813410",
      "label": "813410 - Civic and Social Organizations"
   },
   {
      "type": "naics",
      "value": "813910",
      "label": "813910 - Business Associations"
   },
   {
      "type": "naics",
      "value": "813920",
      "label": "813920 - Professional Organizations"
   },
   {
      "type": "naics",
      "value": "813930",
      "label": "813930 - Labor Unions and Similar Labor Organizations"
   },
   {
      "type": "naics",
      "value": "813940",
      "label": "813940 - Political Organizations"
   },
   {
      "type": "naics",
      "value": "813990",
      "label": "813990 - Other Similar Organizations (except Business"
   },
   {
      "type": "naics",
      "value": "814110",
      "label": "814110 - Private Households"
   },
   {
      "type": "naics",
      "value": "921110",
      "label": "921110 - Executive Offices"
   },
   {
      "type": "naics",
      "value": "921120",
      "label": "921120 - Legislative Bodies"
   },
   {
      "type": "naics",
      "value": "921130",
      "label": "921130 - Public Finance Activities"
   },
   {
      "type": "naics",
      "value": "921140",
      "label": "921140 - Executive and Legislative Offices"
   },
   {
      "type": "naics",
      "value": "921150",
      "label": "921150 - American Indian and Alaska Native Tribal Governments"
   },
   {
      "type": "naics",
      "value": "921190",
      "label": "921190 - Other General Government Support"
   },
   {
      "type": "naics",
      "value": "922110",
      "label": "922110 - Courts"
   },
   {
      "type": "naics",
      "value": "922120",
      "label": "922120 - Police Protection"
   },
   {
      "type": "naics",
      "value": "922130",
      "label": "922130 - Legal Counsel and Prosecution"
   },
   {
      "type": "naics",
      "value": "922140",
      "label": "922140 - Correctional Institutions"
   },
   {
      "type": "naics",
      "value": "922150",
      "label": "922150 - Parole Offices and Probation Offices"
   },
   {
      "type": "naics",
      "value": "922160",
      "label": "922160 - Fire Protection"
   },
   {
      "type": "naics",
      "value": "922190",
      "label": "922190 - Other Justice"
   },
   {
      "type": "naics",
      "value": "923110",
      "label": "923110 - Administration of Education Programs"
   },
   {
      "type": "naics",
      "value": "923120",
      "label": "923120 - Administration of Public Health Programs"
   },
   {
      "type": "naics",
      "value": "923130",
      "label": "923130 - Administration of Human Resource Programs (except Education"
   },
   {
      "type": "naics",
      "value": "923140",
      "label": "923140 - Administration of Veterans' Affairs"
   },
   {
      "type": "naics",
      "value": "924110",
      "label": "924110 - Administration of Air and Water Resource and Solid Waste Management Programs"
   },
   {
      "type": "naics",
      "value": "924120",
      "label": "924120 - Administration of Conservation Programs"
   },
   {
      "type": "naics",
      "value": "925110",
      "label": "925110 - Administration of Housing Programs"
   },
   {
      "type": "naics",
      "value": "925120",
      "label": "925120 - Administration of Urban Planning and Community and Rural Development"
   },
   {
      "type": "naics",
      "value": "926110",
      "label": "926110 - Administration of General Economic Programs"
   },
   {
      "type": "naics",
      "value": "926120",
      "label": "926120 - Regulation and Administration of Transportation Programs"
   },
   {
      "type": "naics",
      "value": "926130",
      "label": "926130 - Regulation and Administration of Communications"
   },
   {
      "type": "naics",
      "value": "926140",
      "label": "926140 - Regulation of Agricultural Marketing and Commodities"
   },
   {
      "type": "naics",
      "value": "926150",
      "label": "926150 - Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors "
   },
   {
      "type": "naics",
      "value": "927110",
      "label": "927110 - Space Research and Technology"
   },
   {
      "type": "naics",
      "value": "928110",
      "label": "928110 - National Security"
   },
   {
      "type": "naics",
      "value": "928120",
      "label": "928120 - International Affairs"
   },
   {
      "type": "naics",
      "value": "335220",
      "label": "335220 - Major Household Appliance Manufacturing "
   },
   {
      "type": "naics",
      "value": "332215",
      "label": "332215 - Metal Kitchen Cookware, Utensil, Cutlery, and Flatware (except Precious) Manufacturing "
   },
   {
      "type": "naics",
      "value": "333318",
      "label": "333318 - Other Commercial and Service Industry Machinery Manufacturing "
   },
   {
      "type": "naics",
      "value": "333241",
      "label": "333241 - Food Product Machinery Manufacturing "
   }
];

export const SETASIDE_LIST = [
   { "value": "WOSB", "label": "WOSB - Women Owned Small Business", "type": "set_aside" },
   { "value": "EDWOSB", "label": "EDWOSB - Economically Disadvantaged Women Owned Small Business", "type": "set_aside" },
   { "value": "SBP", "label": "SBP - Small Business Set-Aside -- Partial", "type": "set_aside" },
   { "value": "ISBEE", "label": "ISBEE - Indian Small Business Economic Enterprise", "type": "set_aside" },
   { "value": "8AN", "label": "8AN - 8(a) Sole Source", "type": "set_aside" },
   { "value": "VSA", "label": "VSA - Veteran Set Aside", "type": "set_aside" },
   { "value": "HZS", "label": "HZS - HUBZone Sole Source", "type": "set_aside" },
   { "value": "SDVOSBS", "label": "SDVOSBS - Service Disabled Veteran Business Sole Source", "type": "set_aside" },
   { "value": "BICiv", "label": "BICiv - Kayenta Health Center Facility - Cargo Trailers", "type": "set_aside" },
   { "value": "8A", "label": "8A - 8A Competed", "type": "set_aside" },
   { "value": "IEE", "label": "IEE - Indian economic enterprises", "type": "set_aside" },
   { "value": "HZC", "label": "HZC - HUBZone Set-Aside", "type": "set_aside" },
   { "value": "WOSBSS", "label": "WOSBSS - Women-Owned Small Business (WOSB) Program Sole Source", "type": "set_aside" },
   { "value": "SBA", "label": "SBA - Small Business Set-Aside -- Total", "type": "set_aside" },
   { "value": "LAS", "label": "LAS - Local Area Set-Aside", "type": "set_aside" },
   { "value": "SDVOSBC", "label": "SDVOSBC - Service Disabled Veteran Business Set-Aside", "type": "set_aside" }
];

export const SETASIDE_CODE_LIST = [
   { "value": "WOSB", "label": "WOSB - Women Owned Small Business", "type": "setaside_code" },
   { "value": "EDWOSB", "label": "EDWOSB - Economically Disadvantaged Women Owned Small Business", "type": "setaside_code" },
   { "value": "SBP", "label": "SBP - Small Business Set-Aside -- Partial", "type": "setaside_code" },
   { "value": "ISBEE", "label": "ISBEE - Indian Small Business Economic Enterprise", "type": "setaside_code" },
   { "value": "8AN", "label": "8AN - 8(a) Sole Source", "type": "setaside_code" },
   { "value": "VSA", "label": "VSA - Veteran Set Aside", "type": "setaside_code" },
   { "value": "HZS", "label": "HZS - HUBZone Sole Source", "type": "setaside_code" },
   { "value": "SDVOSBS", "label": "SDVOSBS - Service Disabled Veteran Business Sole Source", "type": "setaside_code" },
   { "value": "BICiv", "label": "BICiv - Kayenta Health Center Facility - Cargo Trailers", "type": "setaside_code" },
   { "value": "8A", "label": "8A - 8A Competed", "type": "setaside_code" },
   { "value": "IEE", "label": "IEE - Indian economic enterprises", "type": "setaside_code" },
   { "value": "HZC", "label": "HZC - HUBZone Set-Aside", "type": "setaside_code" },
   { "value": "WOSBSS", "label": "WOSBSS - Women-Owned Small Business (WOSB) Program Sole Source", "type": "setaside_code" },
   { "value": "SBA", "label": "SBA - Small Business Set-Aside -- Total", "type": "setaside_code" },
   { "value": "LAS", "label": "LAS - Local Area Set-Aside", "type": "setaside_code" },
   { "value": "SDVOSBC", "label": "SDVOSBC - Service Disabled Veteran Business Set-Aside", "type": "setaside_code" }
];

export const SETASIDE_CODE_FILTER_LIST = [
   { "value": "WOSB", "label": "WOSB - Women Owned Small Business", "type": "filters_set_aside" },
   { "value": "8AN", "label": "8AN - 8(a) Sole Source", "type": "filters_set_aside" },
   { "value": "EDWOSB", "label": "EDWOSB - Economically Disadvantaged Women Owned Small Business", "type": "filters_set_aside" },
   { "value": "SBP", "label": "SBP - Small Business Set-Aside -- Partial", "type": "filters_set_aside" },
   { "value": "ISBEE", "label": "ISBEE - Indian Small Business Economic Enterprise", "type": "filters_set_aside" },
   { "value": "VSA", "label": "VSA - Veteran Set Aside", "type": "filters_set_aside" },
   { "value": "HZS", "label": "HZS - HUBZone Sole Source", "type": "filters_set_aside" },
   { "value": "SDVOSBS", "label": "SDVOSBS - Service Disabled Veteran Business Sole Source", "type": "filters_set_aside" },
   { "value": "BICiv", "label": "BICiv - Kayenta Health Center Facility - Cargo Trailers", "type": "filters_set_aside" },
   { "value": "8A", "label": "8A - 8A Competed", "type": "filters_set_aside" },
   { "value": "IEE", "label": "IEE - Indian economic enterprises", "type": "filters_set_aside" },
   { "value": "HZC", "label": "HZC - HUBZone Set-Aside", "type": "filters_set_aside" },
   { "value": "WOSBSS", "label": "WOSBSS - Women-Owned Small Business (WOSB) Program Sole Source", "type": "filters_set_aside" },
   { "value": "SBA", "label": "SBA - Small Business Set-Aside -- Total", "type": "filters_set_aside" },
   { "value": "LAS", "label": "LAS - Local Area Set-Aside", "type": "filters_set_aside" },
   { "value": "SDVOSBC", "label": "SDVOSBC - Service Disabled Veteran Business Set-Aside", "type": "filters_set_aside" },
   { "value": "EDWOSBSS", "label": "Economically Disadvantaged WOSB (EDWOSB) Program Sole Source", "type": "filters_set_aside" },
   { "value": "VSS", "label": "Veteran-Owned Small Business Sole source", "type": "filters_set_aside" }
];

export const loginRoutes = [
   '/dashboard',
   '/business-registration',
   '/government-registration',
   '/advanced-search',
   '/business',
   '/opportunity',
   '/advanced-search/results',
   '/business',
   '/edit-profile',
   '/post',
   '/purchase',
   '/future',
   '/b2b',
   '/users',
   '/reports',
   '/user-feedback',
   '/communication',
   '/inbox',
   '/message',
   '/update-dashboard-message',
   '/system-message',
   '/whats-new-message',
   '/specific-message',
   '/reports',
   '/report-result',
   '/matching-reports',
   '/contracting-officer',
   '/business-reports',
   '/opportunity-reports',
   '/manual-opportunity',
   '/grant',
   '/admin-posts',
   '/excluded-entity',
   '/awards-details'
];

export const FEDERAL_OPPORTUNITIES_GET_SELECT = [
   'id',
   'title',
   'posted_date',
   'agency',
   'sol_no'
];

export const CONTRACT_OPPORTUNITIES_POST_SELECT = [
   'id',
   'name',
   'posted_date',
   'agency_name',
   'user_id'
];

export const FUTURE_OPPORTUNITIES_GET_SELECT = [
   'title',
   'naics',
   'id',
   'agency'
]

export const GRANTS_GET_SELECT = [
   'title',
   'id',
   'open_date',
   'agency'
]

export const BUSINESSES_GET_SELECT = [
   'name',
   'email',
   'id',
   'user_id'
]

export const PURCHASES_GET_SELECT = [
   'name',
   'id',
   'user_id'
]

export const CONTRACTING_OFFICER_POST_SELECT = [
   'name',
   'office',
   'id',
   'user_id'
]

export const CITY_LIST = [
   {
      value: "Aberdeen",
      label: "Aberdeen",
      type: "city"
   },
   {
      value: "Ahoskie",
      label: "Ahoskie",
      type: "city"
   },
   {
      value: "Albemarle",
      label: "Albemarle",
      type: "city"
   },
   {
      value: "Andrews",
      label: "Andrews",
      type: "city"
   },
   {
      value: "Angier",
      label: "Angier",
      type: "city"
   },
   {
      value: "Apex",
      label: "Apex",
      type: "city"
   },
   {
      value: "Archdale",
      label: "Archdale",
      type: "city"
   },
   {
      value: "Asheboro",
      label: "Asheboro",
      type: "city"
   },
   {
      value: "Asheville",
      label: "Asheville",
      type: "city"
   },
   {
      value: "Atlantic Beach",
      label: "Atlantic Beach",
      type: "city"
   },
   {
      value: "Ayden",
      label: "Ayden",
      type: "city"
   },
   {
      value: "Badin",
      label: "Badin",
      type: "city"
   },
   {
      value: "Bakersville",
      label: "Bakersville",
      type: "city"
   },
   {
      value: "Bald Head Island",
      label: "Bald Head Island",
      type: "city"
   },
   {
      value: "Banner Elk",
      label: "Banner Elk",
      type: "city"
   },
   {
      value: "Bayboro",
      label: "Bayboro",
      type: "city"
   },
   {
      value: "Beaufort",
      label: "Beaufort",
      type: "city"
   },
   {
      value: "Beech Mountain",
      label: "Beech Mountain",
      type: "city"
   },
   {
      value: "Belhaven",
      label: "Belhaven",
      type: "city"
   },
   {
      value: "Belmont",
      label: "Belmont",
      type: "city"
   },
   {
      value: "Belville",
      label: "Belville",
      type: "city"
   },
   {
      value: "Belwood",
      label: "Belwood",
      type: "city"
   },
   {
      value: "Bennett",
      label: "Bennett",
      type: "city"
   },
   {
      value: "Benson",
      label: "Benson",
      type: "city"
   },
   {
      value: "Bermuda Run ",
      label: "Bermuda Run ",
      type: "city"
   },
   {
      value: "Bessemer City",
      label: "Bessemer City",
      type: "city"
   },
   {
      value: "Bethel",
      label: "Bethel",
      type: "city"
   },
   {
      value: "Beulaville",
      label: "Beulaville",
      type: "city"
   },
   {
      value: "Biltmore Forest",
      label: "Biltmore Forest",
      type: "city"
   },
   {
      value: "Biscoe",
      label: "Biscoe",
      type: "city"
   },
   {
      value: "Black Mountain",
      label: "Black Mountain",
      type: "city"
   },
   {
      value: "Blowing Rock",
      label: "Blowing Rock",
      type: "city"
   },
   {
      value: "Boiling Spring Lakes",
      label: "Boiling Spring Lakes",
      type: "city"
   },
   {
      value: "Boiling Springs",
      label: "Boiling Springs",
      type: "city"
   },
   {
      value: "Bolivia",
      label: "Bolivia",
      type: "city"
   },
   {
      value: "Boone",
      label: "Boone",
      type: "city"
   },
   {
      value: "Brevard",
      label: "Brevard",
      type: "city"
   },
   {
      value: "Broadway",
      label: "Broadway",
      type: "city"
   },
   {
      value: "Bryson City",
      label: "Bryson City",
      type: "city"
   },
   {
      value: "Burgaw",
      label: "Burgaw",
      type: "city"
   },
   {
      value: "Burlington",
      label: "Burlington",
      type: "city"
   },
   {
      value: "Burnsville",
      label: "Burnsville",
      type: "city"
   },
   {
      value: "Butner",
      label: "Butner",
      type: "city"
   },
   {
      value: "Calabash",
      label: "Calabash",
      type: "city"
   },
   {
      value: "Cameron",
      label: "Cameron",
      type: "city"
   },
   {
      value: "Canton",
      label: "Canton",
      type: "city"
   },
   {
      value: "Carolina Beach",
      label: "Carolina Beach",
      type: "city"
   },
   {
      value: "Carolina Shores",
      label: "Carolina Shores",
      type: "city"
   },
   {
      value: "Carrboro",
      label: "Carrboro",
      type: "city"
   },
   {
      value: "Carthage",
      label: "Carthage",
      type: "city"
   },
   {
      value: "Cary",
      label: "Cary",
      type: "city"
   },
   {
      value: "Casar",
      label: "Casar",
      type: "city"
   },
   {
      value: "Cashiers",
      label: "Cashiers",
      type: "city"
   },
   {
      value: "Caswell Beach",
      label: "Caswell Beach",
      type: "city"
   },
   {
      value: "Chapel Hill",
      label: "Chapel Hill",
      type: "city"
   },
   {
      value: "Charlotte",
      label: "Charlotte",
      type: "city"
   },
   {
      value: "Cherokee",
      label: "Cherokee",
      type: "city"
   },
   {
      value: "Cherryville",
      label: "Cherryville",
      type: "city"
   },
   {
      value: "Chimney Rock",
      label: "Chimney Rock",
      type: "city"
   },
   {
      value: "China Grove",
      label: "China Grove",
      type: "city"
   },
   {
      value: "Claremont",
      label: "Claremont",
      type: "city"
   },
   {
      value: "Clayton",
      label: "Clayton",
      type: "city"
   },
   {
      value: "Clemmons",
      label: "Clemmons",
      type: "city"
   },
   {
      value: "Clinton",
      label: "Clinton",
      type: "city"
   },
   {
      value: "Clyde",
      label: "Clyde",
      type: "city"
   },
   {
      value: "Coats",
      label: "Coats",
      type: "city"
   },
   {
      value: "Columbia",
      label: "Columbia",
      type: "city"
   },
   {
      value: "Concord",
      label: "Concord",
      type: "city"
   },
   {
      value: "Conover",
      label: "Conover",
      type: "city"
   },
   {
      value: "Cooleemee",
      label: "Cooleemee",
      type: "city"
   },
   {
      value: "Cornelius",
      label: "Cornelius",
      type: "city"
   },
   {
      value: "Creedmoor",
      label: "Creedmoor",
      type: "city"
   },
   {
      value: "Danbury",
      label: "Danbury",
      type: "city"
   },
   {
      value: "Davidson",
      label: "Davidson",
      type: "city"
   },
   {
      value: "Denton",
      label: "Denton",
      type: "city"
   },
   {
      value: "Denver",
      label: "Denver",
      type: "city"
   },
   {
      value: "Dillsboro",
      label: "Dillsboro",
      type: "city"
   },
   {
      value: "Dobson",
      label: "Dobson",
      type: "city"
   },
   {
      value: "Duck",
      label: "Duck",
      type: "city"
   },
   {
      value: "Dunn",
      label: "Dunn",
      type: "city"
   },
   {
      value: "Durham",
      label: "Durham",
      type: "city"
   },
   {
      value: "Earl",
      label: "Earl",
      type: "city"
   },
   {
      value: "Eden",
      label: "Eden",
      type: "city"
   },
   {
      value: "Edenton",
      label: "Edenton",
      type: "city"
   },
   {
      value: "Elizabeth City",
      label: "Elizabeth City",
      type: "city"
   },
   {
      value: "Elizabethtown",
      label: "Elizabethtown",
      type: "city"
   },
   {
      value: "Elkin",
      label: "Elkin",
      type: "city"
   },
   {
      value: "Elm City",
      label: "Elm City",
      type: "city"
   },
   {
      value: "Elon",
      label: "Elon",
      type: "city"
   },
   {
      value: "Elon College",
      label: "Elon College",
      type: "city"
   },
   {
      value: "Emerald Isle",
      label: "Emerald Isle",
      type: "city"
   },
   {
      value: "Enfield",
      label: "Enfield",
      type: "city"
   },
   {
      value: "Erwin",
      label: "Erwin",
      type: "city"
   },
   {
      value: "Fair Bluff",
      label: "Fair Bluff",
      type: "city"
   },
   {
      value: "Fairmont",
      label: "Fairmont",
      type: "city"
   },
   {
      value: "Fallston",
      label: "Fallston",
      type: "city"
   },
   {
      value: "Farmville",
      label: "Farmville",
      type: "city"
   },
   {
      value: "Fayetteville",
      label: "Fayetteville",
      type: "city"
   },
   {
      value: "Flat Rock",
      label: "Flat Rock",
      type: "city"
   },
   {
      value: "Fletcher",
      label: "Fletcher",
      type: "city"
   },
   {
      value: "Forest City",
      label: "Forest City",
      type: "city"
   },
   {
      value: "Forest Hills",
      label: "Forest Hills",
      type: "city"
   },
   {
      value: "Foxfire Village",
      label: "Foxfire Village",
      type: "city"
   },
   {
      value: "Franklin",
      label: "Franklin",
      type: "city"
   },
   {
      value: "Ft.Bragg",
      label: "Ft.Bragg",
      type: "city"
   },
   {
      value: "Fuquay-Varina",
      label: "Fuquay-Varina",
      type: "city"
   },
   {
      value: "Garner",
      label: "Garner",
      type: "city"
   },
   {
      value: "Gastonia",
      label: "Gastonia",
      type: "city"
   },
   {
      value: "Gatesville",
      label: "Gatesville",
      type: "city"
   },
   {
      value: "Gibsonville",
      label: "Gibsonville",
      type: "city"
   },
   {
      value: "Glen Alpine",
      label: "Glen Alpine",
      type: "city"
   },
   {
      value: "Goldsboro",
      label: "Goldsboro",
      type: "city"
   },
   {
      value: "Graham",
      label: "Graham",
      type: "city"
   },
   {
      value: "Granite Falls",
      label: "Granite Falls",
      type: "city"
   },
   {
      value: "Green Level",
      label: "Green Level",
      type: "city"
   },
   {
      value: "Greensboro",
      label: "Greensboro",
      type: "city"
   },
   {
      value: "Greenville",
      label: "Greenville",
      type: "city"
   },
   {
      value: "Grifton",
      label: "Grifton",
      type: "city"
   },
   {
      value: "Grover",
      label: "Grover",
      type: "city"
   },
   {
      value: "Half Moon",
      label: "Half Moon",
      type: "city"
   },
   {
      value: "Hamlet",
      label: "Hamlet",
      type: "city"
   },
   {
      value: "Hampstead",
      label: "Hampstead",
      type: "city"
   },
   {
      value: "Harrisburg",
      label: "Harrisburg",
      type: "city"
   },
   {
      value: "Havelock",
      label: "Havelock",
      type: "city"
   },
   {
      value: "Hayesville",
      label: "Hayesville",
      type: "city"
   },
   {
      value: "Hemby Bridge",
      label: "Hemby Bridge",
      type: "city"
   },
   {
      value: "Hendersonville",
      label: "Hendersonville",
      type: "city"
   },
   {
      value: "Hickory",
      label: "Hickory",
      type: "city"
   },
   {
      value: "High Point",
      label: "High Point",
      type: "city"
   },
   {
      value: "Highfalls",
      label: "Highfalls",
      type: "city"
   },
   {
      value: "Highlands",
      label: "Highlands",
      type: "city"
   },
   {
      value: "Hillsborough",
      label: "Hillsborough",
      type: "city"
   },
   {
      value: "Hobgood",
      label: "Hobgood",
      type: "city"
   },
   {
      value: "Holden Beach",
      label: "Holden Beach",
      type: "city"
   },
   {
      value: "Holly Ridge",
      label: "Holly Ridge",
      type: "city"
   },
   {
      value: "Holly Springs",
      label: "Holly Springs",
      type: "city"
   },
   {
      value: "Hookerton",
      label: "Hookerton",
      type: "city"
   },
   {
      value: "Hope Mills",
      label: "Hope Mills",
      type: "city"
   },
   {
      value: "Hudson",
      label: "Hudson",
      type: "city"
   },
   {
      value: "Huntersville",
      label: "Huntersville",
      type: "city"
   },
   {
      value: "Indian Beach",
      label: "Indian Beach",
      type: "city"
   },
   {
      value: "Indian Trail",
      label: "Indian Trail",
      type: "city"
   },
   {
      value: "Jacksonville",
      label: "Jacksonville",
      type: "city"
   },
   {
      value: "Jamestown",
      label: "Jamestown",
      type: "city"
   },
   {
      value: "Jonesville",
      label: "Jonesville",
      type: "city"
   },
   {
      value: "Kannapolis",
      label: "Kannapolis",
      type: "city"
   },
   {
      value: "Kenansville",
      label: "Kenansville",
      type: "city"
   },
   {
      value: "Kenly ",
      label: "Kenly ",
      type: "city"
   },
   {
      value: "Kernersville",
      label: "Kernersville",
      type: "city"
   },
   {
      value: "Kill Devil Hills",
      label: "Kill Devil Hills",
      type: "city"
   },
   {
      value: "King",
      label: "King",
      type: "city"
   },
   {
      value: "Kings Grant",
      label: "Kings Grant",
      type: "city"
   },
   {
      value: "Kings Mountain",
      label: "Kings Mountain",
      type: "city"
   },
   {
      value: "Kinston",
      label: "Kinston",
      type: "city"
   },
   {
      value: "Kitty Hawk",
      label: "Kitty Hawk",
      type: "city"
   },
   {
      value: "Knightdale",
      label: "Knightdale",
      type: "city"
   },
   {
      value: "Kure Beach",
      label: "Kure Beach",
      type: "city"
   },
   {
      value: "Lake Gaston",
      label: "Lake Gaston",
      type: "city"
   },
   {
      value: "Lake Lure",
      label: "Lake Lure",
      type: "city"
   },
   {
      value: "Lake Norman",
      label: "Lake Norman",
      type: "city"
   },
   {
      value: "Lake Park",
      label: "Lake Park",
      type: "city"
   },
   {
      value: "Lake Santeetlah",
      label: "Lake Santeetlah",
      type: "city"
   },
   {
      value: "Lake Waccamaw",
      label: "Lake Waccamaw",
      type: "city"
   },
   {
      value: "Lattimore",
      label: "Lattimore",
      type: "city"
   },
   {
      value: "Laurel Park",
      label: "Laurel Park",
      type: "city"
   },
   {
      value: "Laurinburg",
      label: "Laurinburg",
      type: "city"
   },
   {
      value: "Lawndale",
      label: "Lawndale",
      type: "city"
   },
   {
      value: "Leland",
      label: "Leland",
      type: "city"
   },
   {
      value: "Lenoir",
      label: "Lenoir",
      type: "city"
   },
   {
      value: "Lewisville",
      label: "Lewisville",
      type: "city"
   },
   {
      value: "Lexington",
      label: "Lexington",
      type: "city"
   },
   {
      value: "Liberty",
      label: "Liberty",
      type: "city"
   },
   {
      value: "Lillington",
      label: "Lillington",
      type: "city"
   },
   {
      value: "Lincolnton",
      label: "Lincolnton",
      type: "city"
   },
   {
      value: "Locust",
      label: "Locust",
      type: "city"
   },
   {
      value: "Louisburg",
      label: "Louisburg",
      type: "city"
   },
   {
      value: "Lucama",
      label: "Lucama",
      type: "city"
   },
   {
      value: "Lumberton",
      label: "Lumberton",
      type: "city"
   },
   {
      value: "Macclesfield",
      label: "Macclesfield",
      type: "city"
   },
   {
      value: "Madison",
      label: "Madison",
      type: "city"
   },
   {
      value: "Maggie Valley",
      label: "Maggie Valley",
      type: "city"
   },
   {
      value: "Maiden",
      label: "Maiden",
      type: "city"
   },
   {
      value: "Manteo",
      label: "Manteo",
      type: "city"
   },
   {
      value: "Marion",
      label: "Marion",
      type: "city"
   },
   {
      value: "Mars Hill",
      label: "Mars Hill",
      type: "city"
   },
   {
      value: "Marshall",
      label: "Marshall",
      type: "city"
   },
   {
      value: "Marshville",
      label: "Marshville",
      type: "city"
   },
   {
      value: "Marvin",
      label: "Marvin",
      type: "city"
   },
   {
      value: "Masonboro",
      label: "Masonboro",
      type: "city"
   },
   {
      value: "Matthews",
      label: "Matthews",
      type: "city"
   },
   {
      value: "Maury",
      label: "Maury",
      type: "city"
   },
   {
      value: "Mayodan",
      label: "Mayodan",
      type: "city"
   },
   {
      value: "McAdenville",
      label: "McAdenville",
      type: "city"
   },
   {
      value: "Mebane",
      label: "Mebane",
      type: "city"
   },
   {
      value: "Midland",
      label: "Midland",
      type: "city"
   },
   {
      value: "Mills River ",
      label: "Mills River ",
      type: "city"
   },
   {
      value: "Mineral Springs",
      label: "Mineral Springs",
      type: "city"
   },
   {
      value: "Mint Hill",
      label: "Mint Hill",
      type: "city"
   },
   {
      value: "Mocksville",
      label: "Mocksville",
      type: "city"
   },
   {
      value: "Monroe",
      label: "Monroe",
      type: "city"
   },
   {
      value: "Montreat",
      label: "Montreat",
      type: "city"
   },
   {
      value: "Mooresboro",
      label: "Mooresboro",
      type: "city"
   },
   {
      value: "Mooresville",
      label: "Mooresville",
      type: "city"
   },
   {
      value: "Morehead City",
      label: "Morehead City",
      type: "city"
   },
   {
      value: "Morganton",
      label: "Morganton",
      type: "city"
   },
   {
      value: "Morrisville",
      label: "Morrisville",
      type: "city"
   },
   {
      value: "Mount Airy",
      label: "Mount Airy",
      type: "city"
   },
   {
      value: "Mount Gilead",
      label: "Mount Gilead",
      type: "city"
   },
   {
      value: "Mount Holly",
      label: "Mount Holly",
      type: "city"
   },
   {
      value: "Mount Olive",
      label: "Mount Olive",
      type: "city"
   },
   {
      value: "Mount Pleasant",
      label: "Mount Pleasant",
      type: "city"
   },
   {
      value: "Mt.Olive",
      label: "Mt.Olive",
      type: "city"
   },
   {
      value: "Murphy",
      label: "Murphy",
      type: "city"
   },
   {
      value: "Murraysville",
      label: "Murraysville",
      type: "city"
   },
   {
      value: "Myrtle Grove",
      label: "Myrtle Grove",
      type: "city"
   },
   {
      value: "Nags Head",
      label: "Nags Head",
      type: "city"
   },
   {
      value: "Nashville",
      label: "Nashville",
      type: "city"
   },
   {
      value: "New Bern",
      label: "New Bern",
      type: "city"
   },
   {
      value: "New London",
      label: "New London",
      type: "city"
   },
   {
      value: "Newland",
      label: "Newland",
      type: "city"
   },
   {
      value: "Newport",
      label: "Newport",
      type: "city"
   },
   {
      value: "Newton",
      label: "Newton",
      type: "city"
   },
   {
      value: "Norlina",
      label: "Norlina",
      type: "city"
   },
   {
      value: "North Wilkesboro",
      label: "North Wilkesboro",
      type: "city"
   },
   {
      value: "NorthTopsail Beach",
      label: "NorthTopsail Beach",
      type: "city"
   },
   {
      value: "Norwood",
      label: "Norwood",
      type: "city"
   },
   {
      value: "Oak Island",
      label: "Oak Island",
      type: "city"
   },
   {
      value: "Oak Ridge",
      label: "Oak Ridge",
      type: "city"
   },
   {
      value: "Oakboro",
      label: "Oakboro",
      type: "city"
   },
   {
      value: "Ocean Isle Beach",
      label: "Ocean Isle Beach",
      type: "city"
   },
   {
      value: "Ocracoke Island",
      label: "Ocracoke Island",
      type: "city"
   },
   {
      value: "Old Fort",
      label: "Old Fort",
      type: "city"
   },
   {
      value: "Oriental",
      label: "Oriental",
      type: "city"
   },
   {
      value: "Other",
      label: "Other",
      type: "city"
   },
   {
      value: "Outer Banks",
      label: "Outer Banks",
      type: "city"
   },
   {
      value: "Oxford",
      label: "Oxford",
      type: "city"
   },
   {
      value: "Patterson Springs",
      label: "Patterson Springs",
      type: "city"
   },
   {
      value: "Pembroke",
      label: "Pembroke",
      type: "city"
   },
   {
      value: "Pikeville",
      label: "Pikeville",
      type: "city"
   },
   {
      value: "Pilot Mountain ",
      label: "Pilot Mountain ",
      type: "city"
   },
   {
      value: "Pine Knoll Shores",
      label: "Pine Knoll Shores",
      type: "city"
   },
   {
      value: "Pinebluff",
      label: "Pinebluff",
      type: "city"
   },
   {
      value: "Pinehurst",
      label: "Pinehurst",
      type: "city"
   },
   {
      value: "Pineville",
      label: "Pineville",
      type: "city"
   },
   {
      value: "Piney Green",
      label: "Piney Green",
      type: "city"
   },
   {
      value: "Pittsboro",
      label: "Pittsboro",
      type: "city"
   },
   {
      value: "Pleasant Garden ",
      label: "Pleasant Garden ",
      type: "city"
   },
   {
      value: "Plymouth",
      label: "Plymouth",
      type: "city"
   },
   {
      value: "Polkville",
      label: "Polkville",
      type: "city"
   },
   {
      value: "Raeford",
      label: "Raeford",
      type: "city"
   },
   {
      value: "Raleigh",
      label: "Raleigh",
      type: "city"
   },
   {
      value: "Ramseur",
      label: "Ramseur",
      type: "city"
   },
   {
      value: "Randleman",
      label: "Randleman",
      type: "city"
   },
   {
      value: "Red Springs",
      label: "Red Springs",
      type: "city"
   },
   {
      value: "Reidsville",
      label: "Reidsville",
      type: "city"
   },
   {
      value: "River Bend",
      label: "River Bend",
      type: "city"
   },
   {
      value: "Roanoke Rapids",
      label: "Roanoke Rapids",
      type: "city"
   },
   {
      value: "Robbins",
      label: "Robbins",
      type: "city"
   },
   {
      value: "Robbinsville",
      label: "Robbinsville",
      type: "city"
   },
   {
      value: "Rockingham",
      label: "Rockingham",
      type: "city"
   },
   {
      value: "Rocky Mount",
      label: "Rocky Mount",
      type: "city"
   },
   {
      value: "Rodanthe",
      label: "Rodanthe",
      type: "city"
   },
   {
      value: "Rolesville",
      label: "Rolesville",
      type: "city"
   },
   {
      value: "Roper",
      label: "Roper",
      type: "city"
   },
   {
      value: "Rowland",
      label: "Rowland",
      type: "city"
   },
   {
      value: "Roxboro",
      label: "Roxboro",
      type: "city"
   },
   {
      value: "Rural Hall",
      label: "Rural Hall",
      type: "city"
   },
   {
      value: "Rutherford College",
      label: "Rutherford College",
      type: "city"
   },
   {
      value: "Saint James",
      label: "Saint James",
      type: "city"
   },
   {
      value: "Saint Pauls",
      label: "Saint Pauls",
      type: "city"
   },
   {
      value: "Salisbury",
      label: "Salisbury",
      type: "city"
   },
   {
      value: "Salter Path",
      label: "Salter Path",
      type: "city"
   },
   {
      value: "Saluda",
      label: "Saluda",
      type: "city"
   },
   {
      value: "Salvo",
      label: "Salvo",
      type: "city"
   },
   {
      value: "Sanford",
      label: "Sanford",
      type: "city"
   },
   {
      value: "Scotland Neck",
      label: "Scotland Neck",
      type: "city"
   },
   {
      value: "Seagrove",
      label: "Seagrove",
      type: "city"
   },
   {
      value: "Selma",
      label: "Selma",
      type: "city"
   },
   {
      value: "Seven Devils",
      label: "Seven Devils",
      type: "city"
   },
   {
      value: "Shallotte",
      label: "Shallotte",
      type: "city"
   },
   {
      value: "Sharpsburg",
      label: "Sharpsburg",
      type: "city"
   },
   {
      value: "Shelby",
      label: "Shelby",
      type: "city"
   },
   {
      value: "Siler City",
      label: "Siler City",
      type: "city"
   },
   {
      value: "Smithfield",
      label: "Smithfield",
      type: "city"
   },
   {
      value: "Sneads Ferry",
      label: "Sneads Ferry",
      type: "city"
   },
   {
      value: "Snow Hill",
      label: "Snow Hill",
      type: "city"
   },
   {
      value: "Southern Pines",
      label: "Southern Pines",
      type: "city"
   },
   {
      value: "Southern Shores",
      label: "Southern Shores",
      type: "city"
   },
   {
      value: "Southport",
      label: "Southport",
      type: "city"
   },
   {
      value: "Sparta",
      label: "Sparta",
      type: "city"
   },
   {
      value: "Spencer",
      label: "Spencer",
      type: "city"
   },
   {
      value: "Spindale",
      label: "Spindale",
      type: "city"
   },
   {
      value: "Spring Lake",
      label: "Spring Lake",
      type: "city"
   },
   {
      value: "Spruce Pine",
      label: "Spruce Pine",
      type: "city"
   },
   {
      value: "St. Stephens",
      label: "St. Stephens",
      type: "city"
   },
   {
      value: "Stallings",
      label: "Stallings",
      type: "city"
   },
   {
      value: "Stanley",
      label: "Stanley",
      type: "city"
   },
   {
      value: "Statesville",
      label: "Statesville",
      type: "city"
   },
   {
      value: "Stoneville",
      label: "Stoneville",
      type: "city"
   },
   {
      value: "Sugar Mountain",
      label: "Sugar Mountain",
      type: "city"
   },
   {
      value: "Summerfield",
      label: "Summerfield",
      type: "city"
   },
   {
      value: "Sunset Beach",
      label: "Sunset Beach",
      type: "city"
   },
   {
      value: "Surf City",
      label: "Surf City",
      type: "city"
   },
   {
      value: "Swan Quarter",
      label: "Swan Quarter",
      type: "city"
   },
   {
      value: "Swansboro",
      label: "Swansboro",
      type: "city"
   },
   {
      value: "Sylva",
      label: "Sylva",
      type: "city"
   },
   {
      value: "Tabor City",
      label: "Tabor City",
      type: "city"
   },
   {
      value: "Tarboro",
      label: "Tarboro",
      type: "city"
   },
   {
      value: "Taylorsville",
      label: "Taylorsville",
      type: "city"
   },
   {
      value: "Taylortown",
      label: "Taylortown",
      type: "city"
   },
   {
      value: "Thomasville",
      label: "Thomasville",
      type: "city"
   },
   {
      value: "Tobaccoville",
      label: "Tobaccoville",
      type: "city"
   },
   {
      value: "Topsail Beach",
      label: "Topsail Beach",
      type: "city"
   },
   {
      value: "Trenton",
      label: "Trenton",
      type: "city"
   },
   {
      value: "TrentWoods",
      label: "TrentWoods",
      type: "city"
   },
   {
      value: "Trinity",
      label: "Trinity",
      type: "city"
   },
   {
      value: "Troutman",
      label: "Troutman",
      type: "city"
   },
   {
      value: "Troy",
      label: "Troy",
      type: "city"
   },
   {
      value: "Tryon",
      label: "Tryon",
      type: "city"
   },
   {
      value: "Unionville",
      label: "Unionville",
      type: "city"
   },
   {
      value: "Valdese",
      label: "Valdese",
      type: "city"
   },
   {
      value: "Valle Crucis",
      label: "Valle Crucis",
      type: "city"
   },
   {
      value: "Vass",
      label: "Vass",
      type: "city"
   },
   {
      value: "Waco",
      label: "Waco",
      type: "city"
   },
   {
      value: "Wadesboro",
      label: "Wadesboro",
      type: "city"
   },
   {
      value: "Wake Forest",
      label: "Wake Forest",
      type: "city"
   },
   {
      value: "Walkertown",
      label: "Walkertown",
      type: "city"
   },
   {
      value: "Walnut Creek",
      label: "Walnut Creek",
      type: "city"
   },
   {
      value: "Warrenton",
      label: "Warrenton",
      type: "city"
   },
   {
      value: "Warsaw",
      label: "Warsaw",
      type: "city"
   },
   {
      value: "Washington",
      label: "Washington",
      type: "city"
   },
   {
      value: "Waves",
      label: "Waves",
      type: "city"
   },
   {
      value: "Waxhaw",
      label: "Waxhaw",
      type: "city"
   },
   {
      value: "Waynesville",
      label: "Waynesville",
      type: "city"
   },
   {
      value: "Weaverville",
      label: "Weaverville",
      type: "city"
   },
   {
      value: "Weddington",
      label: "Weddington",
      type: "city"
   },
   {
      value: "Weldon",
      label: "Weldon",
      type: "city"
   },
   {
      value: "Wendell",
      label: "Wendell",
      type: "city"
   },
   {
      value: "Wesley Chapel",
      label: "Wesley Chapel",
      type: "city"
   },
   {
      value: "West End",
      label: "West End",
      type: "city"
   },
   {
      value: "West Jefferson",
      label: "West Jefferson",
      type: "city"
   },
   {
      value: "Whispering Pines",
      label: "Whispering Pines",
      type: "city"
   },
   {
      value: "White Lake",
      label: "White Lake",
      type: "city"
   },
   {
      value: "Whiteville",
      label: "Whiteville",
      type: "city"
   },
   {
      value: "Wilkesboro",
      label: "Wilkesboro",
      type: "city"
   },
   {
      value: "Williamston",
      label: "Williamston",
      type: "city"
   },
   {
      value: "Wilmington",
      label: "Wilmington",
      type: "city"
   },
   {
      value: "Wilson",
      label: "Wilson",
      type: "city"
   },
   {
      value: "Windsor",
      label: "Windsor",
      type: "city"
   },
   {
      value: "Wingate",
      label: "Wingate",
      type: "city"
   },
   {
      value: "Winston-Salem",
      label: "Winston-Salem",
      type: "city"
   },
   {
      value: "Winterville",
      label: "Winterville",
      type: "city"
   },
   {
      value: "Winton",
      label: "Winton",
      type: "city"
   },
   {
      value: "Woodfin",
      label: "Woodfin",
      type: "city"
   },
   {
      value: "Wrightsville Beach",
      label: "Wrightsville Beach",
      type: "city"
   },
   {
      value: "Yadkinville",
      label: "Yadkinville",
      type: "city"
   },
   {
      value: "Yanceyville",
      label: "Yanceyville",
      type: "city"
   },
   {
      value: "Zebulon",
      label: "Zebulon",
      type: "city"
   }
];

export const COUNTY_LIST = [
   {
      label: "Alamance",
      value: "Alamance",
      type: "county"
   },
   {
      label: "Alexander",
      value: "Alexander",
      type: "county"
   },
   {
      label: "Alleghany",
      value: "Alleghany",
      type: "county"
   },
   {
      label: "Anson",
      value: "Anson",
      type: "county"
   },
   {
      label: "Ashe",
      value: "Ashe",
      type: "county"
   },
   {
      label: "Avery",
      value: "Avery",
      type: "county"
   },
   {
      label: "Beaufort",
      value: "Beaufort",
      type: "county"
   },
   {
      label: "Bertie",
      value: "Bertie",
      type: "county"
   },
   {
      label: "Bladen",
      value: "Bladen",
      type: "county"
   },
   {
      label: "Brunswick",
      value: "Brunswick",
      type: "county"
   },
   {
      label: "Buncombe",
      value: "Buncombe",
      type: "county"
   },
   {
      label: "Burke",
      value: "Burke",
      type: "county"
   },
   {
      label: "Cabarrus",
      value: "Cabarrus",
      type: "county"
   },
   {
      label: "Caldwell",
      value: "Caldwell",
      type: "county"
   },
   {
      label: "Camden",
      value: "Camden",
      type: "county"
   },
   {
      label: "Carteret",
      value: "Carteret",
      type: "county"
   },
   {
      label: "Caswell",
      value: "Caswell",
      type: "county"
   },
   {
      label: "Catawba",
      value: "Catawba",
      type: "county"
   },
   {
      label: "Chatham",
      value: "Chatham",
      type: "county"
   },
   {
      label: "Cherokee",
      value: "Cherokee",
      type: "county"
   },
   {
      label: "Chowan",
      value: "Chowan",
      type: "county"
   },
   {
      label: "Clay",
      value: "Clay",
      type: "county"
   },
   {
      label: "Cleveland",
      value: "Cleveland",
      type: "county"
   },
   {
      label: "Columbus",
      value: "Columbus",
      type: "county"
   },
   {
      label: "Craven",
      value: "Craven",
      type: "county"
   },
   {
      label: "Cumberland",
      value: "Cumberland",
      type: "county"
   },
   {
      label: "Currituck",
      value: "Currituck",
      type: "county"
   },
   {
      label: "Dare",
      value: "Dare",
      type: "county"
   },
   {
      label: "Davidson",
      value: "Davidson",
      type: "county"
   },
   {
      label: "Davie",
      value: "Davie",
      type: "county"
   },
   {
      label: "Duplin",
      value: "Duplin",
      type: "county"
   },
   {
      label: "Durham",
      value: "Durham",
      type: "county"
   },
   {
      label: "Edgecombe",
      value: "Edgecombe",
      type: "county"
   },
   {
      label: "Forsyth",
      value: "Forsyth",
      type: "county"
   },
   {
      label: "Franklin",
      value: "Franklin",
      type: "county"
   },
   {
      label: "Gaston",
      value: "Gaston",
      type: "county"
   },
   {
      label: "Gates",
      value: "Gates",
      type: "county"
   },
   {
      label: "Graham",
      value: "Graham",
      type: "county"
   },
   {
      label: "Granville",
      value: "Granville",
      type: "county"
   },
   {
      label: "Greene",
      value: "Greene",
      type: "county"
   },
   {
      label: "Guilford",
      value: "Guilford",
      type: "county"
   },
   {
      label: "Halifax",
      value: "Halifax",
      type: "county"
   },
   {
      label: "Harnett",
      value: "Harnett",
      type: "county"
   },
   {
      label: "Haywood",
      value: "Haywood",
      type: "county"
   },
   {
      label: "Henderson",
      value: "Henderson",
      type: "county"
   },
   {
      label: "Hertford",
      value: "Hertford",
      type: "county"
   },
   {
      label: "Hoke",
      value: "Hoke",
      type: "county"
   },
   {
      label: "Hyde",
      value: "Hyde",
      type: "county"
   },
   {
      label: "Iredell",
      value: "Iredell",
      type: "county"
   },
   {
      label: "Jackson",
      value: "Jackson",
      type: "county"
   },
   {
      label: "Johnston",
      value: "Johnston",
      type: "county"
   },
   {
      label: "Jones",
      value: "Jones",
      type: "county"
   },
   {
      label: "Lee",
      value: "Lee",
      type: "county"
   },
   {
      label: "Lenoir",
      value: "Lenoir",
      type: "county"
   },
   {
      label: "Lincoln",
      value: "Lincoln",
      type: "county"
   },
   {
      label: "Macon",
      value: "Macon",
      type: "county"
   },
   {
      label: "Madison",
      value: "Madison",
      type: "county"
   },
   {
      label: "Martin",
      value: "Martin",
      type: "county"
   },
   {
      label: "McDowell",
      value: "McDowell",
      type: "county"
   },
   {
      label: "Mecklenburg",
      value: "Mecklenburg",
      type: "county"
   },
   {
      label: "Mitchell",
      value: "Mitchell",
      type: "county"
   },
   {
      label: "Montgomery",
      value: "Montgomery",
      type: "county"
   },
   {
      label: "Moore",
      value: "Moore",
      type: "county"
   },
   {
      label: "Nash",
      value: "Nash",
      type: "county"
   },
   {
      label: "New Hanover",
      value: "New Hanover",
      type: "county"
   },
   {
      label: "Northampton",
      value: "Northampton",
      type: "county"
   },
   {
      label: "Onslow",
      value: "Onslow",
      type: "county"
   },
   {
      label: "Orange",
      value: "Orange",
      type: "county"
   },
   {
      label: "Pamilico",
      value: "Pamilico",
      type: "county"
   },
   {
      label: "Pasquotank",
      value: "Pasquotank",
      type: "county"
   },
   {
      label: "Pender",
      value: "Pender",
      type: "county"
   },
   {
      label: "Perquimans",
      value: "Perquimans",
      type: "county"
   },
   {
      label: "Person",
      value: "Person",
      type: "county"
   },
   {
      label: "Pitt",
      value: "Pitt",
      type: "county"
   },
   {
      label: "Polk",
      value: "Polk",
      type: "county"
   },
   {
      label: "Randolph",
      value: "Randolph",
      type: "county"
   },
   {
      label: "Richmond",
      value: "Richmond",
      type: "county"
   },
   {
      label: "Robeson",
      value: "Robeson",
      type: "county"
   },
   {
      label: "Rockingham",
      value: "Rockingham",
      type: "county"
   },
   {
      label: "Rowan",
      value: "Rowan",
      type: "county"
   },
   {
      label: "Rutherford",
      value: "Rutherford",
      type: "county"
   },
   {
      label: "Sampson",
      value: "Sampson",
      type: "county"
   },
   {
      label: "Scotland",
      value: "Scotland",
      type: "county"
   },
   {
      label: "Stanly",
      value: "Stanly",
      type: "county"
   },
   {
      label: "Stokes",
      value: "Stokes",
      type: "county"
   },
   {
      label: "Surry",
      value: "Surry",
      type: "county"
   },
   {
      label: "Swain",
      value: "Swain",
      type: "county"
   },
   {
      label: "Transylvania",
      value: "Transylvania",
      type: "county"
   },
   {
      label: "Tyrell",
      value: "Tyrell",
      type: "county"
   },
   {
      label: "Union",
      value: "Union",
      type: "county"
   },
   {
      label: "Vance",
      value: "Vance",
      type: "county"
   },
   {
      label: "Wake",
      value: "Wake",
      type: "county"
   },
   {
      label: "Warren",
      value: "Warren",
      type: "county"
   },
   {
      label: "Washington",
      value: "Washington",
      type: "county"
   },
   {
      label: "Watauga",
      value: "Watauga",
      type: "county"
   },
   {
      label: "Wayne",
      value: "Wayne",
      type: "county"
   },
   {
      label: "Wilkes",
      value: "Wilkes",
      type: "county"
   },
   {
      label: "Wilson",
      value: "Wilson",
      type: "county"
   },
   {
      label: "Yadkin",
      value: "Yadkin",
      type: "county"
   },
   {
      label: "Yancey",
      value: "Yancey",
      type: "county"
   }
];

export const OPP_CLASS_LIST = [

   {
      label: "No preference",
      value: "No preference",
      type: "opp_type"
   },
   {
      label: "Certified HUBZone Small Business",
      value: "Certified HUBZone Small Business",
      type: "opp_type"
   },
   {
      label: "Certified in SBAs 8 (a) Program",
      value: "Certified in SBAs 8 (a) Program",
      type: "opp_type"
   },
   {
      label: "Certified Small Disadvantaged Business",
      value: "Certified Small Disadvantaged Business",
      type: "opp_type"
   },
   {
      label: "Federal Government",
      value: "Federal Government",
      type: "opp_type"
   },
   {
      label: "HBCU/MI - Historically Black Colleges and Universities/Minority Institutions",
      value: "HBCU/MI - Historically Black Colleges and Universities/Minority Institutions",
      type: "opp_type"
   },
   {
      label: "JWOD Non-profit Agency",
      value: "JWOD Non-profit Agency",
      type: "opp_type"
   },
   {
      label: "Local Government",
      value: "Local Government",
      type: "opp_type"
   },
   {
      label: "Non-profit Agency",
      value: "Non-profit Agency",
      type: "opp_type"
   },
   {
      label: "Other than Small Business",
      value: "Other than Small Business",
      type: "opp_type"
   },
   {
      label: "Service-Disabled Veteran-owned Small Business",
      value: "Service-Disabled Veteran-owned Small Business",
      type: "opp_type"
   },
   {
      label: "Small Business",
      value: "Small Business",
      type: "opp_type"
   },
   {
      label: "State Government",
      value: "State Government",
      type: "opp_type"
   },
   {
      label: "Veteran-owned Small Business",
      value: "Veteran-owned Small Business",
      type: "opp_type"
   },
   {
      label: "Woman-owned Small Business",
      value: "Woman-owned Small Business",
      type: "opp_type"
   }
];

export const BUSINESS_TYPE_LIST = [
   {
      label: "Advertising/Marketing Services",
      value: "Advertising/Marketing Services",
      type: "type"
   },
   {
      label: "Aerospace",
      value: "Aerospace",
      type: "type"
   },
   {
      label: "Agri-Tourism",
      value: "Agri-Tourism",
      type: "type"
   },
   {
      label: "Agribusiness",
      value: "Agribusiness",
      type: "type"
   },
   {
      label: "Agriculture",
      value: "Agriculture",
      type: "type"
   },
   {
      label: "Aircraft Components",
      value: "Aircraft Components",
      type: "type"
   },
   {
      label: "Architect and Engineering Services",
      value: "Architect and Engineering Services",
      type: "type"
   },
   {
      label: "Banking/Finance Services",
      value: "Banking/Finance Services",
      type: "type"
   },
   {
      label: "Chemicals",
      value: "Chemicals",
      type: "type"
   },
   {
      label: "Construction (Residential/Commercial)",
      value: "Construction (Residential/Commercial)",
      type: "type"
   },
   {
      label: "Construction Supplies",
      value: "Construction Supplies",
      type: "type"
   },
   {
      label: "Consulting Services",
      value: "Consulting Services",
      type: "type"
   },
   {
      label: "Education",
      value: "Education",
      type: "type"
   },
   {
      label: "Electrical",
      value: "Electrical",
      type: "type"
   },
   {
      label: "Engineering",
      value: "Engineering",
      type: "type"
   },
   {
      label: "Engines, Turbines and Components",
      value: "Engines, Turbines and Components",
      type: "type"
   },
   {
      label: "Environmental Services",
      value: "Environmental Services",
      type: "type"
   },
   {
      label: "Healthcare Services",
      value: "Healthcare Services",
      type: "type"
   },
   {
      label: "Information Technology Services",
      value: "Information Technology Services",
      type: "type"
   },
   {
      label: "Instruments and Laboratory Equipment",
      value: "Instruments and Laboratory Equipment",
      type: "type"
   },
   {
      label: "Insurance Services",
      value: "Insurance Services",
      type: "type"
   },
   {
      label: "Job Placement/Staffing Services",
      value: "Job Placement/Staffing Services",
      type: "type"
   },
   {
      label: "Management Services",
      value: "Management Services",
      type: "type"
   },
   {
      label: "Manufacturing",
      value: "Manufacturing",
      type: "type"
   },
   {
      label: "Medical Services",
      value: "Medical Services",
      type: "type"
   },
   {
      label: "Metalworking",
      value: "Metalworking",
      type: "type"
   },
   {
      label: "Military Surplus",
      value: "Military Surplus",
      type: "type"
   },
   {
      label: "Office Supplies and Devices",
      value: "Office Supplies and Devices",
      type: "type"
   },
   {
      label: "Photographic, Mapping, &amp; Printing",
      value: "Photographic, Mapping, &amp; Printing",
      type: "type"
   },
   {
      label: "Plastics/Rubber",
      value: "Plastics/Rubber",
      type: "type"
   },
   {
      label: "Professional, Admin. Management Support",
      value: "Professional, Admin. Management Support",
      type: "type"
   },
   {
      label: "Quality Control, Testing and Inspection",
      value: "Quality Control, Testing and Inspection",
      type: "type"
   },
   {
      label: "Real Estate Services",
      value: "Real Estate Services",
      type: "type"
   },
   {
      label: "Research and Development",
      value: "Research and Development",
      type: "type"
   },
   {
      label: "Resource Extraction Services",
      value: "Resource Extraction Services",
      type: "type"
   },
   {
      label: "Retail",
      value: "Retail",
      type: "type"
   },
   {
      label: "Ship and Marine Equipment",
      value: "Ship and Marine Equipment",
      type: "type"
   },
   {
      label: "Special Studies and Analyses",
      value: "Special Studies and Analyses",
      type: "type"
   },
   {
      label: "Storage Services",
      value: "Storage Services",
      type: "type"
   },
   {
      label: "Telecommunications",
      value: "Telecommunications",
      type: "type"
   },
   {
      label: "Textiles",
      value: "Textiles",
      type: "type"
   },
   {
      label: "Training and Education",
      value: "Training and Education",
      type: "type"
   },
   {
      label: "Travel/Tourism Services",
      value: "Travel/Tourism Services",
      type: "type"
   },
   {
      label: "Weapons",
      value: "Weapons",
      type: "type"
   },
   {
      label: "Web Design/Development",
      value: "Web Design/Development",
      type: "type"
   },
   {
      label: "Woodworking Machinery",
      value: "Woodworking Machinery",
      type: "type"
   },
   {
      label: "Workforce Development",
      value: "Workforce Development",
      type: "type"
   },
   {
      label: "Other",
      value: "Other",
      type: "type"
   }
];

export const BUSINESSES_OPTIONS_LIST = [
   {
      label: "Business Name",
      type: "text",
      name: "name",
      id: 1,
      info_type: "business"
   },
   {
      label: "Web Site Address",
      type: "text",
      name: "url",
      id: 2,
      info_type: "business"
   },
   {
      label: "City",
      type: "select",
      name: "city",
      id: 3,
      info_type: "business"
   },
   {
      label: "County",
      type: "select",
      name: "county",
      id: 4,
      info_type: "business"
   },
   {
      label: "Opporunity Classification",
      type: "select",
      name: "set_aside",
      id: 5,
      info_type: "business"
   },
   {
      label: "ZIP Code",
      type: "select",
      name: "zip",
      id: 6,
      info_type: "business"
   },
   {
      label: "Business Type",
      type: "select",
      name: "type",
      id: 7,
      info_type: "business"
   },
   {
      label: "NAICS Code",
      type: "select",
      name: "naics",
      id: 8,
      info_type: "business"
   },
   {
      label: "Business Keywords",
      type: "text",
      name: "keywords",
      id: 9,
      info_type: "business"
   },
   {
      label: "GSA Contract Number",
      type: "text",
      name: "contract_number",
      id: 10,
      info_type: "business"
   },
   {
      label: "GSA Contract",
      type: "radio",
      name: "contract",
      id: 11,
      choises: {
         f_id: "gsa_choise_yes",
         s_id: "gsa_choise_no"
      },
      info_type: "business"
   },
   {
      label: "Registered SAM",
      type: "radio",
      name: "registered_in_sam",
      id: 12,
      choises: {
         f_id: "sam_yes",
         s_id: "sam_no"
      },
      info_type: "business"
   },
];

export const CONTRACT_OPTIONS_LIST = [
   {
      label: "Class Code",
      type: "text",
      name: "class_code",
      id: 2,
      info_type: "contracts"
   },
   {
      label: "Opporunity Classification",
      type: "select",
      name: "setaside_code",
      id: 3,
      info_type: "contracts"
   },
   {
      label: "NAICS Code",
      type: "select",
      name: "naics",
      id: 4,
      info_type: "contracts"
   },
   {
      label: "Contract Date",
      type: "date",
      name: "deadline",
      id: 5,
      info_type: "contracts"
   },
   {
      label: "Contract Description",
      type: "text",
      name: "search",
      id: 6,
      info_type: "contracts"
   },
   {
      label: "City",
      type: "text",
      name: "city",
      id: 7,
      info_type: "contracts"
   },
   {
      label: "ZIP Code",
      type: "text",
      name: "zip_code",
      id: 8,
      info_type: "contracts"
   },
   {
      label: "State",
      type: "text",
      name: "state",
      id: 9,
      info_type: "contracts"
   },
   {
      label: "Contract Reference Number",
      type: "text",
      name: "sol_no",
      id: 10,
      info_type: "contracts"
   }
];

export const PURCHASE_OPTIONS_LIST = [
   {
      label: "Opporunity Classification",
      type: "select",
      name: "setaside_code",
      id: 1,
      info_type: "purchases"
   },
   {
      label: "Keywords",
      type: "text",
      name: "keywords",
      id: 2,
      info_type: "purchases"
   },
   {
      label: "Comments",
      type: "text",
      name: "comment",
      id: 3,
      info_type: "purchases"
   },
   {
      label: "Item",
      type: "text",
      name: "name",
      id: 4,
      info_type: "purchases"
   },
   {
      label: "Request Date",
      type: "date",
      name: "required_date_range",
      id: 5,
      info_type: "purchases"
   }
];

export const EDIT_BUSINESS_INFO_LIST = [
   {
      label: "Business name",
      name: "name",
      type: "text",
      id: 1,
      info_type: "business"
   },
   {
      label: "Business type",
      name: "business_type",
      type: "select",
      id: 13,
      info_type: "business"
   },
   {
      label: "Web address",
      name: "url",
      type: "text",
      id: 2,
      info_type: "business"
   },
   {
      label: "Contact name",
      name: "contact",
      type: "text",
      id: 3,
      info_type: "business"
   },
   {
      label: "Address",
      name: "address1",
      type: "address",
      id: 4,
      info_type: "business"
   },
   // {
   //    label: "Address 2",
   //    name: "address2",
   //    type: "text",
   //    id: 5,
   //    info_type: "business"
   // },
   // {
   //    label: "City",
   //    name: "city",
   //    type: "text",
   //    id: 6,
   //    info_type: "business"
   // },
   // {
   //    label: "State",
   //    name: "state",
   //    type: "text",
   //    id: 7,
   //    info_type: "business"
   // },
   // {
   //    label: "County",
   //    name: "county",
   //    type: "text",
   //    id: 8,
   //    info_type: "business"
   // },
   // {
   //    label: "ZIP Code",
   //    name: "zip",
   //    type: "text",
   //    id: 9,
   //    info_type: "business"
   // },
   {
      label: "Phone",
      name: "phone",
      type: "tel",
      id: 10,
      info_type: "business"
   },
   {
      label: "I am a:",
      name: "gov_relations",
      type: "single_select",
      id: 14,
      info_type: "business"
   },
   // {
   //    label: "Locations where you do business",
   //    name: "location",
   //    type: "location",
   //    id: 15,
   //    info_type: "business"
   // },
      {
      label: "Locations where you do business",
      name: "geo_fencing_locations",
      type: "map",
      id: 18,
      info_type: "business"
   },
   // {
   //    label: "ZIP Codes for Geo Fencing",
   //    name: "geo_fencing_zips",
   //    type: "select",
   //    id: 16,
   //    info_type: "business"
   // },
   {
      label: "Fax",
      name: "fax",
      type: "tel",
      id: 11,
      info_type: "business"
   },
   {
      label: "Alternative Email address",
      name: "email",
      type: "email",
      id: 12,
      info_type: "business"
   },
   {
      label: "Notifications Type",
      name: "notifications_type",
      type: "single_select",
      id: 20,
      info_type: "business"
   }
]

export const EDIT_OFFICER_INFO_LIST = [
   {
      label: "Name",
      name: "name",
      type: "text",
      id: 1,
      info_type: "officer"
   },
   {
      label: "Phone Number",
      name: "phone1",
      type: "tel",
      id: 2,
      info_type: "officer"
   },
   {
      label: "Fax",
      name: "fax",
      type: "tel",
      id: 3,
      info_type: "officer"
   },
   {
      label: "Secondary Phone Number",
      name: "phone2",
      type: "tel",
      id: 4,
      info_type: "officer"
   },
   {
      label: "Secondary Email Address",
      name: "email",
      type: "email",
      id: 5,
      info_type: "officer"
   },
   {
      label: "City",
      name: "city",
      type: "text",
      id: 6,
      info_type: "officer"
   },
   {
      label: "Installation",
      name: "installation",
      type: "single_select",
      id: 7,
      info_type: "officer"
   },
   {
      label: "County",
      name: "county",
      type: "text",
      id: 8,
      info_type: "officer"
   },
   {
      label: "State",
      name: "state",
      type: "text",
      id: 9,
      info_type: "officer"
   },
   {
      label: "Zip Code",
      name: "zip",
      type: "text",
      id: 10,
      info_type: "officer"
   },
]

export const EDIT_USER_INFO_LIST = [
   {
      label: "User Name",
      type: "text",
      name: "username",
      id: 1,
      info_type: "user"
   },
   {
      label: "Email Address",
      type: "email",
      name: "email",
      id: 2,
      info_type: "user"
   },
   {
      label: "Password",
      type: "password",
      name: "password",
      id: 3,
      info_type: "user"
   },
   {
      label: "Recieve Email",
      type: "radio",
      name: "user_contact",
      id: 4,
      info_type: "user",
      choises: {
         f_id: "email_receive_choise_yes",
         s_id: "email_receive_choise_no"
      }
   },
   {
      label: "Recieve Messages",
      type: "radio",
      name: "user_message",
      id: 5,
      info_type: "user",
      choises: {
         f_id: "receive_messages_choice_yes",
         s_id: "receive_messages_choice_no"
      }
   },
];

export const EDIT_BUSINESS_ADDITIONAL_LIST = [
   {
      label: "Business Type",
      type: "select",
      name: "type",
      id: 1,
      info_type: "business"
   },
   {
      label: "Small Business Set Aside",
      type: "select",
      name: "set_aside",
      id: 111,
      info_type: "business"
   },
   {
      label: "Business Description",
      type: "textarea",
      name: "description",
      id: 2,
      info_type: "business"
   },
   {
      label: "Business Keywords",
      type: "textarea",
      name: "keywords",
      id: 3,
      info_type: "business"
   },
   {
      label: "GSA Contract",
      type: "radio",
      name: "contract",
      id: 4,
      choises: {
         f_id: "gsa_choise_yes",
         s_id: "gsa_choise_no"
      },
      info_type: "business"
   },
   {
      label: "GSA Contract Number",
      type: "text",
      name: "contract_number",
      id: 5,
      info_type: "business"
   },
   {
      label: "DUNS Number",
      type: "text",
      name: "duns_number",
      id: 6,
      info_type: "business"
   },
   {
      label: "UEI",
      type: "text",
      name: "uei",
      id: 16,
      info_type: "business"
   },
   {
      label: "Cage Code",
      type: "text",
      name: "cage_code",
      id: 7,
      info_type: "business"
   },
   {
      label: "TAX ID",
      type: "text",
      name: "tax_number",
      id: 8,
      info_type: "business"
   },
   {
      label: "Registered SAM",
      type: "radio",
      name: "registered_in_sam",
      id: 9,
      choises: {
         f_id: "sam_yes",
         s_id: "sam_no"
      },
      info_type: "business"
   },
   {
      label: "NAICS Code",
      type: "select",
      name: "naics",
      id: 10,
      info_type: "business"
   },
   {
      label: "PSC Code",
      type: "select",
      name: "psc_code",
      id: 11,
      info_type: "business"
   },
   {
      label: "Sell 3rd-Party Certified Environmentally-Friendly Products",
      type: "radio",
      name: "env_products",
      id: 12,
      choises: {
         f_id: "env_products_yes",
         s_id: "env_products_no"
      },
      info_type: "business"
   },
   {
      label: "Accept Credit Card",
      type: "radio",
      name: "credit",
      id: 13,
      choises: {
         f_id: "credit_yes",
         s_id: "credit_no"
      },
      info_type: "business"
   },
   {
      label: "Payment Type",
      type: "select",
      name: "payment_method",
      id: 14,
      info_type: "business"
   },
   {
      label: "Quantity Discounts",
      type: "radio",
      name: "discounts",
      id: 15,
      choises: {
         f_id: "discounts_yes",
         s_id: "discounts_no"
      },
      info_type: "business"
   },
   {
      label: "Shipping Terms",
      type: "select",
      name: "shipping_terms",
      id: 16,
      info_type: "business"
   }
];

export const PAYMENT_TYPES = [
   { value: "Visa", label: "Visa", type: "payment_method" },
   { value: "Master Card", label: "Master Card", type: "payment_method" },
   { value: "American Express", label: "American Express", type: "payment_method" },
   { value: "PayPal", label: "PayPal", type: "payment_method" },
   { value: "eCheck", label: "eCheck", type: "payment_method" }
];

// export const ZIP_LIST = [
//    { type: "zip", label: "27006", value: "27006" },
//    { type: "zip", label: "27007", value: "27007" },
//    { type: "zip", label: "27009", value: "27009" },
//    { type: "zip", label: "27010", value: "27010" },
//    { type: "zip", label: "27011", value: "27011" },
//    { type: "zip", label: "27012", value: "27012" },
//    { type: "zip", label: "27013", value: "27013" },
//    { type: "zip", label: "27014", value: "27014" },
//    { type: "zip", label: "27016", value: "27016" },
//    { type: "zip", label: "27017", value: "27017" },
//    { type: "zip", label: "27018", value: "27018" },
//    { type: "zip", label: "27019", value: "27019" },
//    { type: "zip", label: "27020", value: "27020" },
//    { type: "zip", label: "27021", value: "27021" },
//    { type: "zip", label: "27022", value: "27022" },
//    { type: "zip", label: "27023", value: "27023" },
//    { type: "zip", label: "27024", value: "27024" },
//    { type: "zip", label: "27025", value: "27025" },
//    { type: "zip", label: "27027", value: "27027" },
//    { type: "zip", label: "27028", value: "27028" },
//    { type: "zip", label: "27030", value: "27030" },
//    { type: "zip", label: "27031", value: "27031" },
//    { type: "zip", label: "27040", value: "27040" },
//    { type: "zip", label: "27041", value: "27041" },
//    { type: "zip", label: "27042", value: "27042" },
//    { type: "zip", label: "27043", value: "27043" },
//    { type: "zip", label: "27045", value: "27045" },
//    { type: "zip", label: "27046", value: "27046" },
//    { type: "zip", label: "27047", value: "27047" },
//    { type: "zip", label: "27048", value: "27048" },
//    { type: "zip", label: "27049", value: "27049" },
//    { type: "zip", label: "27050", value: "27050" },
//    { type: "zip", label: "27051", value: "27051" },
//    { type: "zip", label: "27052", value: "27052" },
//    { type: "zip", label: "27053", value: "27053" },
//    { type: "zip", label: "27054", value: "27054" },
//    { type: "zip", label: "27055", value: "27055" },
//    { type: "zip", label: "27094", value: "27094" },
//    { type: "zip", label: "27098", value: "27098" },
//    { type: "zip", label: "27099", value: "27099" },
//    { type: "zip", label: "27101", value: "27101" },
//    { type: "zip", label: "27102", value: "27102" },
//    { type: "zip", label: "27103", value: "27103" },
//    { type: "zip", label: "27104", value: "27104" },
//    { type: "zip", label: "27105", value: "27105" },
//    { type: "zip", label: "27106", value: "27106" },
//    { type: "zip", label: "27107", value: "27107" },
//    { type: "zip", label: "27108", value: "27108" },
//    { type: "zip", label: "27109", value: "27109" },
//    { type: "zip", label: "27110", value: "27110" },
//    { type: "zip", label: "27111", value: "27111" },
//    { type: "zip", label: "27113", value: "27113" },
//    { type: "zip", label: "27114", value: "27114" },
//    { type: "zip", label: "27115", value: "27115" },
//    { type: "zip", label: "27116", value: "27116" },
//    { type: "zip", label: "27117", value: "27117" },
//    { type: "zip", label: "27120", value: "27120" },
//    { type: "zip", label: "27127", value: "27127" },
//    { type: "zip", label: "27130", value: "27130" },
//    { type: "zip", label: "27150", value: "27150" },
//    { type: "zip", label: "27151", value: "27151" },
//    { type: "zip", label: "27152", value: "27152" },
//    { type: "zip", label: "27155", value: "27155" },
//    { type: "zip", label: "27156", value: "27156" },
//    { type: "zip", label: "27157", value: "27157" },
//    { type: "zip", label: "27198", value: "27198" },
//    { type: "zip", label: "27199", value: "27199" },
//    { type: "zip", label: "27201", value: "27201" },
//    { type: "zip", label: "27202", value: "27202" },
//    { type: "zip", label: "27203", value: "27203" },
//    { type: "zip", label: "27204", value: "27204" },
//    { type: "zip", label: "27207", value: "27207" },
//    { type: "zip", label: "27208", value: "27208" },
//    { type: "zip", label: "27209", value: "27209" },
//    { type: "zip", label: "27212", value: "27212" },
//    { type: "zip", label: "27213", value: "27213" },
//    { type: "zip", label: "27214", value: "27214" },
//    { type: "zip", label: "27215", value: "27215" },
//    { type: "zip", label: "27216", value: "27216" },
//    { type: "zip", label: "27217", value: "27217" },
//    { type: "zip", label: "27220", value: "27220" },
//    { type: "zip", label: "27228", value: "27228" },
//    { type: "zip", label: "27229", value: "27229" },
//    { type: "zip", label: "27230", value: "27230" },
//    { type: "zip", label: "27231", value: "27231" },
//    { type: "zip", label: "27233", value: "27233" },
//    { type: "zip", label: "27235", value: "27235" },
//    { type: "zip", label: "27237", value: "27237" },
//    { type: "zip", label: "27239", value: "27239" },
//    { type: "zip", label: "27242", value: "27242" },
//    { type: "zip", label: "27243", value: "27243" },
//    { type: "zip", label: "27244", value: "27244" },
//    { type: "zip", label: "27247", value: "27247" },
//    { type: "zip", label: "27248", value: "27248" },
//    { type: "zip", label: "27249", value: "27249" },
//    { type: "zip", label: "27252", value: "27252" },
//    { type: "zip", label: "27253", value: "27253" },
//    { type: "zip", label: "27256", value: "27256" },
//    { type: "zip", label: "27258", value: "27258" },
//    { type: "zip", label: "27259", value: "27259" },
//    { type: "zip", label: "27260", value: "27260" },
//    { type: "zip", label: "27261", value: "27261" },
//    { type: "zip", label: "27262", value: "27262" },
//    { type: "zip", label: "27263", value: "27263" },
//    { type: "zip", label: "27264", value: "27264" },
//    { type: "zip", label: "27265", value: "27265" },
//    { type: "zip", label: "27278", value: "27278" },
//    { type: "zip", label: "27281", value: "27281" },
//    { type: "zip", label: "27282", value: "27282" },
//    { type: "zip", label: "27283", value: "27283" },
//    { type: "zip", label: "27284", value: "27284" },
//    { type: "zip", label: "27285", value: "27285" },
//    { type: "zip", label: "27288", value: "27288" },
//    { type: "zip", label: "27289", value: "27289" },
//    { type: "zip", label: "27291", value: "27291" },
//    { type: "zip", label: "27292", value: "27292" },
//    { type: "zip", label: "27293", value: "27293" },
//    { type: "zip", label: "27294", value: "27294" },
//    { type: "zip", label: "27295", value: "27295" },
//    { type: "zip", label: "27298", value: "27298" },
//    { type: "zip", label: "27299", value: "27299" },
//    { type: "zip", label: "27301", value: "27301" },
//    { type: "zip", label: "27302", value: "27302" },
//    { type: "zip", label: "27305", value: "27305" },
//    { type: "zip", label: "27306", value: "27306" },
//    { type: "zip", label: "27310", value: "27310" },
//    { type: "zip", label: "27311", value: "27311" },
//    { type: "zip", label: "27312", value: "27312" },
//    { type: "zip", label: "27313", value: "27313" },
//    { type: "zip", label: "27314", value: "27314" },
//    { type: "zip", label: "27315", value: "27315" },
//    { type: "zip", label: "27316", value: "27316" },
//    { type: "zip", label: "27317", value: "27317" },
//    { type: "zip", label: "27320", value: "27320" },
//    { type: "zip", label: "27321", value: "27321" },
//    { type: "zip", label: "27322", value: "27322" },
//    { type: "zip", label: "27323", value: "27323" },
//    { type: "zip", label: "27325", value: "27325" },
//    { type: "zip", label: "27326", value: "27326" },
//    { type: "zip", label: "27330", value: "27330" },
//    { type: "zip", label: "27331", value: "27331" },
//    { type: "zip", label: "27340", value: "27340" },
//    { type: "zip", label: "27341", value: "27341" },
//    { type: "zip", label: "27342", value: "27342" },
//    { type: "zip", label: "27343", value: "27343" },
//    { type: "zip", label: "27344", value: "27344" },
//    { type: "zip", label: "27349", value: "27349" },
//    { type: "zip", label: "27350", value: "27350" },
//    { type: "zip", label: "27351", value: "27351" },
//    { type: "zip", label: "27355", value: "27355" },
//    { type: "zip", label: "27356", value: "27356" },
//    { type: "zip", label: "27357", value: "27357" },
//    { type: "zip", label: "27358", value: "27358" },
//    { type: "zip", label: "27359", value: "27359" },
//    { type: "zip", label: "27360", value: "27360" },
//    { type: "zip", label: "27361", value: "27361" },
//    { type: "zip", label: "27370", value: "27370" },
//    { type: "zip", label: "27371", value: "27371" },
//    { type: "zip", label: "27373", value: "27373" },
//    { type: "zip", label: "27374", value: "27374" },
//    { type: "zip", label: "27375", value: "27375" },
//    { type: "zip", label: "27376", value: "27376" },
//    { type: "zip", label: "27377", value: "27377" },
//    { type: "zip", label: "27379", value: "27379" },
//    { type: "zip", label: "27401", value: "27401" },
//    { type: "zip", label: "27402", value: "27402" },
//    { type: "zip", label: "27403", value: "27403" },
//    { type: "zip", label: "27404", value: "27404" },
//    { type: "zip", label: "27405", value: "27405" },
//    { type: "zip", label: "27406", value: "27406" },
//    { type: "zip", label: "27407", value: "27407" },
//    { type: "zip", label: "27408", value: "27408" },
//    { type: "zip", label: "27409", value: "27409" },
//    { type: "zip", label: "27410", value: "27410" },
//    { type: "zip", label: "27411", value: "27411" },
//    { type: "zip", label: "27412", value: "27412" },
//    { type: "zip", label: "27413", value: "27413" },
//    { type: "zip", label: "27415", value: "27415" },
//    { type: "zip", label: "27416", value: "27416" },
//    { type: "zip", label: "27417", value: "27417" },
//    { type: "zip", label: "27419", value: "27419" },
//    { type: "zip", label: "27420", value: "27420" },
//    { type: "zip", label: "27425", value: "27425" },
//    { type: "zip", label: "27427", value: "27427" },
//    { type: "zip", label: "27429", value: "27429" },
//    { type: "zip", label: "27435", value: "27435" },
//    { type: "zip", label: "27438", value: "27438" },
//    { type: "zip", label: "27455", value: "27455" },
//    { type: "zip", label: "27480", value: "27480" },
//    { type: "zip", label: "27495", value: "27495" },
//    { type: "zip", label: "27498", value: "27498" },
//    { type: "zip", label: "27499", value: "27499" },
//    { type: "zip", label: "27501", value: "27501" },
//    { type: "zip", label: "27502", value: "27502" },
//    { type: "zip", label: "27503", value: "27503" },
//    { type: "zip", label: "27504", value: "27504" },
//    { type: "zip", label: "27505", value: "27505" },
//    { type: "zip", label: "27506", value: "27506" },
//    { type: "zip", label: "27507", value: "27507" },
//    { type: "zip", label: "27508", value: "27508" },
//    { type: "zip", label: "27509", value: "27509" },
//    { type: "zip", label: "27510", value: "27510" },
//    { type: "zip", label: "27511", value: "27511" },
//    { type: "zip", label: "27512", value: "27512" },
//    { type: "zip", label: "27513", value: "27513" },
//    { type: "zip", label: "27514", value: "27514" },
//    { type: "zip", label: "27515", value: "27515" },
//    { type: "zip", label: "27516", value: "27516" },
//    { type: "zip", label: "27518", value: "27518" },
//    { type: "zip", label: "27519", value: "27519" },
//    { type: "zip", label: "27520", value: "27520" },
//    { type: "zip", label: "27521", value: "27521" },
//    { type: "zip", label: "27522", value: "27522" },
//    { type: "zip", label: "27524", value: "27524" },
//    { type: "zip", label: "27525", value: "27525" },
//    { type: "zip", label: "27526", value: "27526" },
//    { type: "zip", label: "27529", value: "27529" },
//    { type: "zip", label: "27530", value: "27530" },
//    { type: "zip", label: "27531", value: "27531" },
//    { type: "zip", label: "27532", value: "27532" },
//    { type: "zip", label: "27533", value: "27533" },
//    { type: "zip", label: "27534", value: "27534" },
//    { type: "zip", label: "27536", value: "27536" },
//    { type: "zip", label: "27540", value: "27540" },
//    { type: "zip", label: "27541", value: "27541" },
//    { type: "zip", label: "27542", value: "27542" },
//    { type: "zip", label: "27543", value: "27543" },
//    { type: "zip", label: "27544", value: "27544" },
//    { type: "zip", label: "27545", value: "27545" },
//    { type: "zip", label: "27546", value: "27546" },
//    { type: "zip", label: "27549", value: "27549" },
//    { type: "zip", label: "27551", value: "27551" },
//    { type: "zip", label: "27552", value: "27552" },
//    { type: "zip", label: "27553", value: "27553" },
//    { type: "zip", label: "27555", value: "27555" },
//    { type: "zip", label: "27556", value: "27556" },
//    { type: "zip", label: "27557", value: "27557" },
//    { type: "zip", label: "27559", value: "27559" },
//    { type: "zip", label: "27560", value: "27560" },
//    { type: "zip", label: "27562", value: "27562" },
//    { type: "zip", label: "27563", value: "27563" },
//    { type: "zip", label: "27564", value: "27564" },
//    { type: "zip", label: "27565", value: "27565" },
//    { type: "zip", label: "27568", value: "27568" },
//    { type: "zip", label: "27569", value: "27569" },
//    { type: "zip", label: "27570", value: "27570" },
//    { type: "zip", label: "27571", value: "27571" },
//    { type: "zip", label: "27572", value: "27572" },
//    { type: "zip", label: "27573", value: "27573" },
//    { type: "zip", label: "27576", value: "27576" },
//    { type: "zip", label: "27577", value: "27577" },
//    { type: "zip", label: "27581", value: "27581" },
//    { type: "zip", label: "27582", value: "27582" },
//    { type: "zip", label: "27583", value: "27583" },
//    { type: "zip", label: "27584", value: "27584" },
//    { type: "zip", label: "27586", value: "27586" },
//    { type: "zip", label: "27587", value: "27587" },
//    { type: "zip", label: "27588", value: "27588" },
//    { type: "zip", label: "27589", value: "27589" },
//    { type: "zip", label: "27591", value: "27591" },
//    { type: "zip", label: "27592", value: "27592" },
//    { type: "zip", label: "27593", value: "27593" },
//    { type: "zip", label: "27594", value: "27594" },
//    { type: "zip", label: "27596", value: "27596" },
//    { type: "zip", label: "27597", value: "27597" },
//    { type: "zip", label: "27599", value: "27599" },
//    { type: "zip", label: "27601", value: "27601" },
//    { type: "zip", label: "27602", value: "27602" },
//    { type: "zip", label: "27603", value: "27603" },
//    { type: "zip", label: "27604", value: "27604" },
//    { type: "zip", label: "27605", value: "27605" },
//    { type: "zip", label: "27606", value: "27606" },
//    { type: "zip", label: "27607", value: "27607" },
//    { type: "zip", label: "27608", value: "27608" },
//    { type: "zip", label: "27609", value: "27609" },
//    { type: "zip", label: "27610", value: "27610" },
//    { type: "zip", label: "27611", value: "27611" },
//    { type: "zip", label: "27612", value: "27612" },
//    { type: "zip", label: "27613", value: "27613" },
//    { type: "zip", label: "27614", value: "27614" },
//    { type: "zip", label: "27615", value: "27615" },
//    { type: "zip", label: "27616", value: "27616" },
//    { type: "zip", label: "27617", value: "27617" },
//    { type: "zip", label: "27619", value: "27619" },
//    { type: "zip", label: "27620", value: "27620" },
//    { type: "zip", label: "27621", value: "27621" },
//    { type: "zip", label: "27622", value: "27622" },
//    { type: "zip", label: "27623", value: "27623" },
//    { type: "zip", label: "27624", value: "27624" },
//    { type: "zip", label: "27625", value: "27625" },
//    { type: "zip", label: "27626", value: "27626" },
//    { type: "zip", label: "27627", value: "27627" },
//    { type: "zip", label: "27628", value: "27628" },
//    { type: "zip", label: "27629", value: "27629" },
//    { type: "zip", label: "27634", value: "27634" },
//    { type: "zip", label: "27635", value: "27635" },
//    { type: "zip", label: "27636", value: "27636" },
//    { type: "zip", label: "27640", value: "27640" },
//    { type: "zip", label: "27650", value: "27650" },
//    { type: "zip", label: "27656", value: "27656" },
//    { type: "zip", label: "27658", value: "27658" },
//    { type: "zip", label: "27661", value: "27661" },
//    { type: "zip", label: "27668", value: "27668" },
//    { type: "zip", label: "27675", value: "27675" },
//    { type: "zip", label: "27676", value: "27676" },
//    { type: "zip", label: "27690", value: "27690" },
//    { type: "zip", label: "27695", value: "27695" },
//    { type: "zip", label: "27697", value: "27697" },
//    { type: "zip", label: "27698", value: "27698" },
//    { type: "zip", label: "27699", value: "27699" },
//    { type: "zip", label: "27701", value: "27701" },
//    { type: "zip", label: "27702", value: "27702" },
//    { type: "zip", label: "27703", value: "27703" },
//    { type: "zip", label: "27704", value: "27704" },
//    { type: "zip", label: "27705", value: "27705" },
//    { type: "zip", label: "27706", value: "27706" },
//    { type: "zip", label: "27707", value: "27707" },
//    { type: "zip", label: "27708", value: "27708" },
//    { type: "zip", label: "27709", value: "27709" },
//    { type: "zip", label: "27710", value: "27710" },
//    { type: "zip", label: "27711", value: "27711" },
//    { type: "zip", label: "27712", value: "27712" },
//    { type: "zip", label: "27713", value: "27713" },
//    { type: "zip", label: "27715", value: "27715" },
//    { type: "zip", label: "27717", value: "27717" },
//    { type: "zip", label: "27722", value: "27722" },
//    { type: "zip", label: "27801", value: "27801" },
//    { type: "zip", label: "27802", value: "27802" },
//    { type: "zip", label: "27803", value: "27803" },
//    { type: "zip", label: "27804", value: "27804" },
//    { type: "zip", label: "27805", value: "27805" },
//    { type: "zip", label: "27806", value: "27806" },
//    { type: "zip", label: "27807", value: "27807" },
//    { type: "zip", label: "27808", value: "27808" },
//    { type: "zip", label: "27809", value: "27809" },
//    { type: "zip", label: "27810", value: "27810" },
//    { type: "zip", label: "27811", value: "27811" },
//    { type: "zip", label: "27812", value: "27812" },
//    { type: "zip", label: "27813", value: "27813" },
//    { type: "zip", label: "27814", value: "27814" },
//    { type: "zip", label: "27816", value: "27816" },
//    { type: "zip", label: "27817", value: "27817" },
//    { type: "zip", label: "27818", value: "27818" },
//    { type: "zip", label: "27819", value: "27819" },
//    { type: "zip", label: "27820", value: "27820" },
//    { type: "zip", label: "27821", value: "27821" },
//    { type: "zip", label: "27822", value: "27822" },
//    { type: "zip", label: "27823", value: "27823" },
//    { type: "zip", label: "27824", value: "27824" },
//    { type: "zip", label: "27825", value: "27825" },
//    { type: "zip", label: "27826", value: "27826" },
//    { type: "zip", label: "27827", value: "27827" },
//    { type: "zip", label: "27828", value: "27828" },
//    { type: "zip", label: "27829", value: "27829" },
//    { type: "zip", label: "27830", value: "27830" },
//    { type: "zip", label: "27831", value: "27831" },
//    { type: "zip", label: "27832", value: "27832" },
//    { type: "zip", label: "27833", value: "27833" },
//    { type: "zip", label: "27834", value: "27834" },
//    { type: "zip", label: "27835", value: "27835" },
//    { type: "zip", label: "27836", value: "27836" },
//    { type: "zip", label: "27837", value: "27837" },
//    { type: "zip", label: "27838", value: "27838" },
//    { type: "zip", label: "27839", value: "27839" },
//    { type: "zip", label: "27840", value: "27840" },
//    { type: "zip", label: "27841", value: "27841" },
//    { type: "zip", label: "27842", value: "27842" },
//    { type: "zip", label: "27843", value: "27843" },
//    { type: "zip", label: "27844", value: "27844" },
//    { type: "zip", label: "27845", value: "27845" },
//    { type: "zip", label: "27846", value: "27846" },
//    { type: "zip", label: "27847", value: "27847" },
//    { type: "zip", label: "27849", value: "27849" },
//    { type: "zip", label: "27850", value: "27850" },
//    { type: "zip", label: "27851", value: "27851" },
//    { type: "zip", label: "27852", value: "27852" },
//    { type: "zip", label: "27853", value: "27853" },
//    { type: "zip", label: "27854", value: "27854" },
//    { type: "zip", label: "27855", value: "27855" },
//    { type: "zip", label: "27856", value: "27856" },
//    { type: "zip", label: "27857", value: "27857" },
//    { type: "zip", label: "27858", value: "27858" },
//    { type: "zip", label: "27859", value: "27859" },
//    { type: "zip", label: "27860", value: "27860" },
//    { type: "zip", label: "27861", value: "27861" },
//    { type: "zip", label: "27862", value: "27862" },
//    { type: "zip", label: "27863", value: "27863" },
//    { type: "zip", label: "27864", value: "27864" },
//    { type: "zip", label: "27865", value: "27865" },
//    { type: "zip", label: "27866", value: "27866" },
//    { type: "zip", label: "27867", value: "27867" },
//    { type: "zip", label: "27868", value: "27868" },
//    { type: "zip", label: "27869", value: "27869" },
//    { type: "zip", label: "27870", value: "27870" },
//    { type: "zip", label: "27871", value: "27871" },
//    { type: "zip", label: "27872", value: "27872" },
//    { type: "zip", label: "27873", value: "27873" },
//    { type: "zip", label: "27874", value: "27874" },
//    { type: "zip", label: "27875", value: "27875" },
//    { type: "zip", label: "27876", value: "27876" },
//    { type: "zip", label: "27877", value: "27877" },
//    { type: "zip", label: "27878", value: "27878" },
//    { type: "zip", label: "27879", value: "27879" },
//    { type: "zip", label: "27880", value: "27880" },
//    { type: "zip", label: "27881", value: "27881" },
//    { type: "zip", label: "27882", value: "27882" },
//    { type: "zip", label: "27883", value: "27883" },
//    { type: "zip", label: "27884", value: "27884" },
//    { type: "zip", label: "27885", value: "27885" },
//    { type: "zip", label: "27886", value: "27886" },
//    { type: "zip", label: "27887", value: "27887" },
//    { type: "zip", label: "27888", value: "27888" },
//    { type: "zip", label: "27889", value: "27889" },
//    { type: "zip", label: "27890", value: "27890" },
//    { type: "zip", label: "27891", value: "27891" },
//    { type: "zip", label: "27892", value: "27892" },
//    { type: "zip", label: "27893", value: "27893" },
//    { type: "zip", label: "27894", value: "27894" },
//    { type: "zip", label: "27895", value: "27895" },
//    { type: "zip", label: "27896", value: "27896" },
//    { type: "zip", label: "27897", value: "27897" },
//    { type: "zip", label: "27906", value: "27906" },
//    { type: "zip", label: "27907", value: "27907" },
//    { type: "zip", label: "27909", value: "27909" },
//    { type: "zip", label: "27910", value: "27910" },
//    { type: "zip", label: "27915", value: "27915" },
//    { type: "zip", label: "27916", value: "27916" },
//    { type: "zip", label: "27917", value: "27917" },
//    { type: "zip", label: "27919", value: "27919" },
//    { type: "zip", label: "27920", value: "27920" },
//    { type: "zip", label: "27921", value: "27921" },
//    { type: "zip", label: "27922", value: "27922" },
//    { type: "zip", label: "27923", value: "27923" },
//    { type: "zip", label: "27924", value: "27924" },
//    { type: "zip", label: "27925", value: "27925" },
//    { type: "zip", label: "27926", value: "27926" },
//    { type: "zip", label: "27927", value: "27927" },
//    { type: "zip", label: "27928", value: "27928" },
//    { type: "zip", label: "27929", value: "27929" },
//    { type: "zip", label: "27930", value: "27930" },
//    { type: "zip", label: "27932", value: "27932" },
//    { type: "zip", label: "27935", value: "27935" },
//    { type: "zip", label: "27936", value: "27936" },
//    { type: "zip", label: "27937", value: "27937" },
//    { type: "zip", label: "27938", value: "27938" },
//    { type: "zip", label: "27939", value: "27939" },
//    { type: "zip", label: "27941", value: "27941" },
//    { type: "zip", label: "27942", value: "27942" },
//    { type: "zip", label: "27943", value: "27943" },
//    { type: "zip", label: "27944", value: "27944" },
//    { type: "zip", label: "27946", value: "27946" },
//    { type: "zip", label: "27947", value: "27947" },
//    { type: "zip", label: "27948", value: "27948" },
//    { type: "zip", label: "27949", value: "27949" },
//    { type: "zip", label: "27950", value: "27950" },
//    { type: "zip", label: "27953", value: "27953" },
//    { type: "zip", label: "27954", value: "27954" },
//    { type: "zip", label: "27956", value: "27956" },
//    { type: "zip", label: "27957", value: "27957" },
//    { type: "zip", label: "27958", value: "27958" },
//    { type: "zip", label: "27959", value: "27959" },
//    { type: "zip", label: "27960", value: "27960" },
//    { type: "zip", label: "27962", value: "27962" },
//    { type: "zip", label: "27964", value: "27964" },
//    { type: "zip", label: "27965", value: "27965" },
//    { type: "zip", label: "27966", value: "27966" },
//    { type: "zip", label: "27967", value: "27967" },
//    { type: "zip", label: "27968", value: "27968" },
//    { type: "zip", label: "27969", value: "27969" },
//    { type: "zip", label: "27970", value: "27970" },
//    { type: "zip", label: "27972", value: "27972" },
//    { type: "zip", label: "27973", value: "27973" },
//    { type: "zip", label: "27974", value: "27974" },
//    { type: "zip", label: "27976", value: "27976" },
//    { type: "zip", label: "27978", value: "27978" },
//    { type: "zip", label: "27979", value: "27979" },
//    { type: "zip", label: "27980", value: "27980" },
//    { type: "zip", label: "27981", value: "27981" },
//    { type: "zip", label: "27982", value: "27982" },
//    { type: "zip", label: "27983", value: "27983" },
//    { type: "zip", label: "27985", value: "27985" },
//    { type: "zip", label: "27986", value: "27986" },
//    { type: "zip", label: "28001", value: "28001" },
//    { type: "zip", label: "28002", value: "28002" },
//    { type: "zip", label: "28006", value: "28006" },
//    { type: "zip", label: "28007", value: "28007" },
//    { type: "zip", label: "28009", value: "28009" },
//    { type: "zip", label: "28010", value: "28010" },
//    { type: "zip", label: "28012", value: "28012" },
//    { type: "zip", label: "28016", value: "28016" },
//    { type: "zip", label: "28017", value: "28017" },
//    { type: "zip", label: "28018", value: "28018" },
//    { type: "zip", label: "28019", value: "28019" },
//    { type: "zip", label: "28020", value: "28020" },
//    { type: "zip", label: "28021", value: "28021" },
//    { type: "zip", label: "28023", value: "28023" },
//    { type: "zip", label: "28024", value: "28024" },
//    { type: "zip", label: "28025", value: "28025" },
//    { type: "zip", label: "28026", value: "28026" },
//    { type: "zip", label: "28027", value: "28027" },
//    { type: "zip", label: "28031", value: "28031" },
//    { type: "zip", label: "28032", value: "28032" },
//    { type: "zip", label: "28033", value: "28033" },
//    { type: "zip", label: "28034", value: "28034" },
//    { type: "zip", label: "28036", value: "28036" },
//    { type: "zip", label: "28037", value: "28037" },
//    { type: "zip", label: "28038", value: "28038" },
//    { type: "zip", label: "28039", value: "28039" },
//    { type: "zip", label: "28040", value: "28040" },
//    { type: "zip", label: "28041", value: "28041" },
//    { type: "zip", label: "28042", value: "28042" },
//    { type: "zip", label: "28043", value: "28043" },
//    { type: "zip", label: "28052", value: "28052" },
//    { type: "zip", label: "28053", value: "28053" },
//    { type: "zip", label: "28054", value: "28054" },
//    { type: "zip", label: "28055", value: "28055" },
//    { type: "zip", label: "28056", value: "28056" },
//    { type: "zip", label: "28070", value: "28070" },
//    { type: "zip", label: "28071", value: "28071" },
//    { type: "zip", label: "28072", value: "28072" },
//    { type: "zip", label: "28073", value: "28073" },
//    { type: "zip", label: "28074", value: "28074" },
//    { type: "zip", label: "28075", value: "28075" },
//    { type: "zip", label: "28076", value: "28076" },
//    { type: "zip", label: "28077", value: "28077" },
//    { type: "zip", label: "28078", value: "28078" },
//    { type: "zip", label: "28079", value: "28079" },
//    { type: "zip", label: "28080", value: "28080" },
//    { type: "zip", label: "28081", value: "28081" },
//    { type: "zip", label: "28082", value: "28082" },
//    { type: "zip", label: "28083", value: "28083" },
//    { type: "zip", label: "28086", value: "28086" },
//    { type: "zip", label: "28088", value: "28088" },
//    { type: "zip", label: "28089", value: "28089" },
//    { type: "zip", label: "28090", value: "28090" },
//    { type: "zip", label: "28091", value: "28091" },
//    { type: "zip", label: "28092", value: "28092" },
//    { type: "zip", label: "28093", value: "28093" },
//    { type: "zip", label: "28097", value: "28097" },
//    { type: "zip", label: "28098", value: "28098" },
//    { type: "zip", label: "28101", value: "28101" },
//    { type: "zip", label: "28102", value: "28102" },
//    { type: "zip", label: "28103", value: "28103" },
//    { type: "zip", label: "28104", value: "28104" },
//    { type: "zip", label: "28105", value: "28105" },
//    { type: "zip", label: "28106", value: "28106" },
//    { type: "zip", label: "28107", value: "28107" },
//    { type: "zip", label: "28108", value: "28108" },
//    { type: "zip", label: "28109", value: "28109" },
//    { type: "zip", label: "28110", value: "28110" },
//    { type: "zip", label: "28111", value: "28111" },
//    { type: "zip", label: "28112", value: "28112" },
//    { type: "zip", label: "28114", value: "28114" },
//    { type: "zip", label: "28115", value: "28115" },
//    { type: "zip", label: "28117", value: "28117" },
//    { type: "zip", label: "28119", value: "28119" },
//    { type: "zip", label: "28120", value: "28120" },
//    { type: "zip", label: "28123", value: "28123" },
//    { type: "zip", label: "28124", value: "28124" },
//    { type: "zip", label: "28125", value: "28125" },
//    { type: "zip", label: "28126", value: "28126" },
//    { type: "zip", label: "28127", value: "28127" },
//    { type: "zip", label: "28128", value: "28128" },
//    { type: "zip", label: "28129", value: "28129" },
//    { type: "zip", label: "28130", value: "28130" },
//    { type: "zip", label: "28133", value: "28133" },
//    { type: "zip", label: "28134", value: "28134" },
//    { type: "zip", label: "28135", value: "28135" },
//    { type: "zip", label: "28136", value: "28136" },
//    { type: "zip", label: "28137", value: "28137" },
//    { type: "zip", label: "28138", value: "28138" },
//    { type: "zip", label: "28139", value: "28139" },
//    { type: "zip", label: "28144", value: "28144" },
//    { type: "zip", label: "28145", value: "28145" },
//    { type: "zip", label: "28146", value: "28146" },
//    { type: "zip", label: "28147", value: "28147" },
//    { type: "zip", label: "28150", value: "28150" },
//    { type: "zip", label: "28151", value: "28151" },
//    { type: "zip", label: "28152", value: "28152" },
//    { type: "zip", label: "28159", value: "28159" },
//    { type: "zip", label: "28160", value: "28160" },
//    { type: "zip", label: "28163", value: "28163" },
//    { type: "zip", label: "28164", value: "28164" },
//    { type: "zip", label: "28166", value: "28166" },
//    { type: "zip", label: "28167", value: "28167" },
//    { type: "zip", label: "28168", value: "28168" },
//    { type: "zip", label: "28169", value: "28169" },
//    { type: "zip", label: "28170", value: "28170" },
//    { type: "zip", label: "28173", value: "28173" },
//    { type: "zip", label: "28174", value: "28174" },
//    { type: "zip", label: "28201", value: "28201" },
//    { type: "zip", label: "28202", value: "28202" },
//    { type: "zip", label: "28203", value: "28203" },
//    { type: "zip", label: "28204", value: "28204" },
//    { type: "zip", label: "28205", value: "28205" },
//    { type: "zip", label: "28206", value: "28206" },
//    { type: "zip", label: "28207", value: "28207" },
//    { type: "zip", label: "28208", value: "28208" },
//    { type: "zip", label: "28209", value: "28209" },
//    { type: "zip", label: "28210", value: "28210" },
//    { type: "zip", label: "28211", value: "28211" },
//    { type: "zip", label: "28212", value: "28212" },
//    { type: "zip", label: "28213", value: "28213" },
//    { type: "zip", label: "28214", value: "28214" },
//    { type: "zip", label: "28215", value: "28215" },
//    { type: "zip", label: "28216", value: "28216" },
//    { type: "zip", label: "28217", value: "28217" },
//    { type: "zip", label: "28218", value: "28218" },
//    { type: "zip", label: "28219", value: "28219" },
//    { type: "zip", label: "28220", value: "28220" },
//    { type: "zip", label: "28221", value: "28221" },
//    { type: "zip", label: "28222", value: "28222" },
//    { type: "zip", label: "28223", value: "28223" },
//    { type: "zip", label: "28224", value: "28224" },
//    { type: "zip", label: "28225", value: "28225" },
//    { type: "zip", label: "28226", value: "28226" },
//    { type: "zip", label: "28227", value: "28227" },
//    { type: "zip", label: "28228", value: "28228" },
//    { type: "zip", label: "28229", value: "28229" },
//    { type: "zip", label: "28230", value: "28230" },
//    { type: "zip", label: "28231", value: "28231" },
//    { type: "zip", label: "28232", value: "28232" },
//    { type: "zip", label: "28233", value: "28233" },
//    { type: "zip", label: "28234", value: "28234" },
//    { type: "zip", label: "28235", value: "28235" },
//    { type: "zip", label: "28236", value: "28236" },
//    { type: "zip", label: "28237", value: "28237" },
//    { type: "zip", label: "28240", value: "28240" },
//    { type: "zip", label: "28241", value: "28241" },
//    { type: "zip", label: "28242", value: "28242" },
//    { type: "zip", label: "28243", value: "28243" },
//    { type: "zip", label: "28244", value: "28244" },
//    { type: "zip", label: "28246", value: "28246" },
//    { type: "zip", label: "28247", value: "28247" },
//    { type: "zip", label: "28250", value: "28250" },
//    { type: "zip", label: "28253", value: "28253" },
//    { type: "zip", label: "28254", value: "28254" },
//    { type: "zip", label: "28255", value: "28255" },
//    { type: "zip", label: "28256", value: "28256" },
//    { type: "zip", label: "28258", value: "28258" },
//    { type: "zip", label: "28260", value: "28260" },
//    { type: "zip", label: "28261", value: "28261" },
//    { type: "zip", label: "28262", value: "28262" },
//    { type: "zip", label: "28265", value: "28265" },
//    { type: "zip", label: "28266", value: "28266" },
//    { type: "zip", label: "28269", value: "28269" },
//    { type: "zip", label: "28270", value: "28270" },
//    { type: "zip", label: "28272", value: "28272" },
//    { type: "zip", label: "28273", value: "28273" },
//    { type: "zip", label: "28274", value: "28274" },
//    { type: "zip", label: "28275", value: "28275" },
//    { type: "zip", label: "28277", value: "28277" },
//    { type: "zip", label: "28278", value: "28278" },
//    { type: "zip", label: "28280", value: "28280" },
//    { type: "zip", label: "28281", value: "28281" },
//    { type: "zip", label: "28282", value: "28282" },
//    { type: "zip", label: "28283", value: "28283" },
//    { type: "zip", label: "28284", value: "28284" },
//    { type: "zip", label: "28285", value: "28285" },
//    { type: "zip", label: "28286", value: "28286" },
//    { type: "zip", label: "28287", value: "28287" },
//    { type: "zip", label: "28288", value: "28288" },
//    { type: "zip", label: "28289", value: "28289" },
//    { type: "zip", label: "28290", value: "28290" },
//    { type: "zip", label: "28296", value: "28296" },
//    { type: "zip", label: "28297", value: "28297" },
//    { type: "zip", label: "28299", value: "28299" },
//    { type: "zip", label: "28301", value: "28301" },
//    { type: "zip", label: "28302", value: "28302" },
//    { type: "zip", label: "28303", value: "28303" },
//    { type: "zip", label: "28304", value: "28304" },
//    { type: "zip", label: "28305", value: "28305" },
//    { type: "zip", label: "28306", value: "28306" },
//    { type: "zip", label: "28307", value: "28307" },
//    { type: "zip", label: "28308", value: "28308" },
//    { type: "zip", label: "28309", value: "28309" },
//    { type: "zip", label: "28310", value: "28310" },
//    { type: "zip", label: "28311", value: "28311" },
//    { type: "zip", label: "28314", value: "28314" },
//    { type: "zip", label: "28315", value: "28315" },
//    { type: "zip", label: "28318", value: "28318" },
//    { type: "zip", label: "28319", value: "28319" },
//    { type: "zip", label: "28320", value: "28320" },
//    { type: "zip", label: "28323", value: "28323" },
//    { type: "zip", label: "28325", value: "28325" },
//    { type: "zip", label: "28326", value: "28326" },
//    { type: "zip", label: "28327", value: "28327" },
//    { type: "zip", label: "28328", value: "28328" },
//    { type: "zip", label: "28329", value: "28329" },
//    { type: "zip", label: "28330", value: "28330" },
//    { type: "zip", label: "28331", value: "28331" },
//    { type: "zip", label: "28332", value: "28332" },
//    { type: "zip", label: "28333", value: "28333" },
//    { type: "zip", label: "28334", value: "28334" },
//    { type: "zip", label: "28335", value: "28335" },
//    { type: "zip", label: "28337", value: "28337" },
//    { type: "zip", label: "28338", value: "28338" },
//    { type: "zip", label: "28339", value: "28339" },
//    { type: "zip", label: "28340", value: "28340" },
//    { type: "zip", label: "28341", value: "28341" },
//    { type: "zip", label: "28342", value: "28342" },
//    { type: "zip", label: "28343", value: "28343" },
//    { type: "zip", label: "28344", value: "28344" },
//    { type: "zip", label: "28345", value: "28345" },
//    { type: "zip", label: "28347", value: "28347" },
//    { type: "zip", label: "28348", value: "28348" },
//    { type: "zip", label: "28349", value: "28349" },
//    { type: "zip", label: "28350", value: "28350" },
//    { type: "zip", label: "28351", value: "28351" },
//    { type: "zip", label: "28352", value: "28352" },
//    { type: "zip", label: "28353", value: "28353" },
//    { type: "zip", label: "28355", value: "28355" },
//    { type: "zip", label: "28356", value: "28356" },
//    { type: "zip", label: "28357", value: "28357" },
//    { type: "zip", label: "28358", value: "28358" },
//    { type: "zip", label: "28359", value: "28359" },
//    { type: "zip", label: "28360", value: "28360" },
//    { type: "zip", label: "28361", value: "28361" },
//    { type: "zip", label: "28362", value: "28362" },
//    { type: "zip", label: "28363", value: "28363" },
//    { type: "zip", label: "28364", value: "28364" },
//    { type: "zip", label: "28365", value: "28365" },
//    { type: "zip", label: "28366", value: "28366" },
//    { type: "zip", label: "28367", value: "28367" },
//    { type: "zip", label: "28368", value: "28368" },
//    { type: "zip", label: "28369", value: "28369" },
//    { type: "zip", label: "28370", value: "28370" },
//    { type: "zip", label: "28371", value: "28371" },
//    { type: "zip", label: "28372", value: "28372" },
//    { type: "zip", label: "28373", value: "28373" },
//    { type: "zip", label: "28374", value: "28374" },
//    { type: "zip", label: "28375", value: "28375" },
//    { type: "zip", label: "28376", value: "28376" },
//    { type: "zip", label: "28377", value: "28377" },
//    { type: "zip", label: "28378", value: "28378" },
//    { type: "zip", label: "28379", value: "28379" },
//    { type: "zip", label: "28380", value: "28380" },
//    { type: "zip", label: "28382", value: "28382" },
//    { type: "zip", label: "28383", value: "28383" },
//    { type: "zip", label: "28384", value: "28384" },
//    { type: "zip", label: "28385", value: "28385" },
//    { type: "zip", label: "28386", value: "28386" },
//    { type: "zip", label: "28387", value: "28387" },
//    { type: "zip", label: "28388", value: "28388" },
//    { type: "zip", label: "28390", value: "28390" },
//    { type: "zip", label: "28391", value: "28391" },
//    { type: "zip", label: "28392", value: "28392" },
//    { type: "zip", label: "28393", value: "28393" },
//    { type: "zip", label: "28394", value: "28394" },
//    { type: "zip", label: "28395", value: "28395" },
//    { type: "zip", label: "28396", value: "28396" },
//    { type: "zip", label: "28398", value: "28398" },
//    { type: "zip", label: "28399", value: "28399" },
//    { type: "zip", label: "28401", value: "28401" },
//    { type: "zip", label: "28402", value: "28402" },
//    { type: "zip", label: "28403", value: "28403" },
//    { type: "zip", label: "28404", value: "28404" },
//    { type: "zip", label: "28405", value: "28405" },
//    { type: "zip", label: "28406", value: "28406" },
//    { type: "zip", label: "28407", value: "28407" },
//    { type: "zip", label: "28408", value: "28408" },
//    { type: "zip", label: "28409", value: "28409" },
//    { type: "zip", label: "28410", value: "28410" },
//    { type: "zip", label: "28411", value: "28411" },
//    { type: "zip", label: "28412", value: "28412" },
//    { type: "zip", label: "28420", value: "28420" },
//    { type: "zip", label: "28421", value: "28421" },
//    { type: "zip", label: "28422", value: "28422" },
//    { type: "zip", label: "28423", value: "28423" },
//    { type: "zip", label: "28424", value: "28424" },
//    { type: "zip", label: "28425", value: "28425" },
//    { type: "zip", label: "28428", value: "28428" },
//    { type: "zip", label: "28429", value: "28429" },
//    { type: "zip", label: "28430", value: "28430" },
//    { type: "zip", label: "28431", value: "28431" },
//    { type: "zip", label: "28432", value: "28432" },
//    { type: "zip", label: "28433", value: "28433" },
//    { type: "zip", label: "28434", value: "28434" },
//    { type: "zip", label: "28435", value: "28435" },
//    { type: "zip", label: "28436", value: "28436" },
//    { type: "zip", label: "28438", value: "28438" },
//    { type: "zip", label: "28439", value: "28439" },
//    { type: "zip", label: "28441", value: "28441" },
//    { type: "zip", label: "28442", value: "28442" },
//    { type: "zip", label: "28443", value: "28443" },
//    { type: "zip", label: "28444", value: "28444" },
//    { type: "zip", label: "28445", value: "28445" },
//    { type: "zip", label: "28446", value: "28446" },
//    { type: "zip", label: "28447", value: "28447" },
//    { type: "zip", label: "28448", value: "28448" },
//    { type: "zip", label: "28449", value: "28449" },
//    { type: "zip", label: "28450", value: "28450" },
//    { type: "zip", label: "28451", value: "28451" },
//    { type: "zip", label: "28452", value: "28452" },
//    { type: "zip", label: "28453", value: "28453" },
//    { type: "zip", label: "28454", value: "28454" },
//    { type: "zip", label: "28455", value: "28455" },
//    { type: "zip", label: "28456", value: "28456" },
//    { type: "zip", label: "28457", value: "28457" },
//    { type: "zip", label: "28458", value: "28458" },
//    { type: "zip", label: "28459", value: "28459" },
//    { type: "zip", label: "28460", value: "28460" },
//    { type: "zip", label: "28461", value: "28461" },
//    { type: "zip", label: "28462", value: "28462" },
//    { type: "zip", label: "28463", value: "28463" },
//    { type: "zip", label: "28464", value: "28464" },
//    { type: "zip", label: "28465", value: "28465" },
//    { type: "zip", label: "28466", value: "28466" },
//    { type: "zip", label: "28467", value: "28467" },
//    { type: "zip", label: "28468", value: "28468" },
//    { type: "zip", label: "28469", value: "28469" },
//    { type: "zip", label: "28470", value: "28470" },
//    { type: "zip", label: "28471", value: "28471" },
//    { type: "zip", label: "28472", value: "28472" },
//    { type: "zip", label: "28478", value: "28478" },
//    { type: "zip", label: "28479", value: "28479" },
//    { type: "zip", label: "28480", value: "28480" },
//    { type: "zip", label: "28501", value: "28501" },
//    { type: "zip", label: "28502", value: "28502" },
//    { type: "zip", label: "28503", value: "28503" },
//    { type: "zip", label: "28504", value: "28504" },
//    { type: "zip", label: "28508", value: "28508" },
//    { type: "zip", label: "28509", value: "28509" },
//    { type: "zip", label: "28510", value: "28510" },
//    { type: "zip", label: "28511", value: "28511" },
//    { type: "zip", label: "28512", value: "28512" },
//    { type: "zip", label: "28513", value: "28513" },
//    { type: "zip", label: "28515", value: "28515" },
//    { type: "zip", label: "28516", value: "28516" },
//    { type: "zip", label: "28518", value: "28518" },
//    { type: "zip", label: "28519", value: "28519" },
//    { type: "zip", label: "28520", value: "28520" },
//    { type: "zip", label: "28521", value: "28521" },
//    { type: "zip", label: "28522", value: "28522" },
//    { type: "zip", label: "28523", value: "28523" },
//    { type: "zip", label: "28524", value: "28524" },
//    { type: "zip", label: "28525", value: "28525" },
//    { type: "zip", label: "28526", value: "28526" },
//    { type: "zip", label: "28527", value: "28527" },
//    { type: "zip", label: "28528", value: "28528" },
//    { type: "zip", label: "28529", value: "28529" },
//    { type: "zip", label: "28530", value: "28530" },
//    { type: "zip", label: "28531", value: "28531" },
//    { type: "zip", label: "28532", value: "28532" },
//    { type: "zip", label: "28533", value: "28533" },
//    { type: "zip", label: "28537", value: "28537" },
//    { type: "zip", label: "28538", value: "28538" },
//    { type: "zip", label: "28539", value: "28539" },
//    { type: "zip", label: "28540", value: "28540" },
//    { type: "zip", label: "28541", value: "28541" },
//    { type: "zip", label: "28542", value: "28542" },
//    { type: "zip", label: "28543", value: "28543" },
//    { type: "zip", label: "28544", value: "28544" },
//    { type: "zip", label: "28545", value: "28545" },
//    { type: "zip", label: "28546", value: "28546" },
//    { type: "zip", label: "28547", value: "28547" },
//    { type: "zip", label: "28551", value: "28551" },
//    { type: "zip", label: "28552", value: "28552" },
//    { type: "zip", label: "28553", value: "28553" },
//    { type: "zip", label: "28554", value: "28554" },
//    { type: "zip", label: "28555", value: "28555" },
//    { type: "zip", label: "28556", value: "28556" },
//    { type: "zip", label: "28557", value: "28557" },
//    { type: "zip", label: "28560", value: "28560" },
//    { type: "zip", label: "28561", value: "28561" },
//    { type: "zip", label: "28562", value: "28562" },
//    { type: "zip", label: "28563", value: "28563" },
//    { type: "zip", label: "28564", value: "28564" },
//    { type: "zip", label: "28570", value: "28570" },
//    { type: "zip", label: "28571", value: "28571" },
//    { type: "zip", label: "28572", value: "28572" },
//    { type: "zip", label: "28573", value: "28573" },
//    { type: "zip", label: "28574", value: "28574" },
//    { type: "zip", label: "28575", value: "28575" },
//    { type: "zip", label: "28577", value: "28577" },
//    { type: "zip", label: "28578", value: "28578" },
//    { type: "zip", label: "28579", value: "28579" },
//    { type: "zip", label: "28580", value: "28580" },
//    { type: "zip", label: "28581", value: "28581" },
//    { type: "zip", label: "28582", value: "28582" },
//    { type: "zip", label: "28583", value: "28583" },
//    { type: "zip", label: "28584", value: "28584" },
//    { type: "zip", label: "28585", value: "28585" },
//    { type: "zip", label: "28586", value: "28586" },
//    { type: "zip", label: "28587", value: "28587" },
//    { type: "zip", label: "28589", value: "28589" },
//    { type: "zip", label: "28590", value: "28590" },
//    { type: "zip", label: "28594", value: "28594" },
//    { type: "zip", label: "28601", value: "28601" },
//    { type: "zip", label: "28602", value: "28602" },
//    { type: "zip", label: "28603", value: "28603" },
//    { type: "zip", label: "28604", value: "28604" },
//    { type: "zip", label: "28605", value: "28605" },
//    { type: "zip", label: "28606", value: "28606" },
//    { type: "zip", label: "28607", value: "28607" },
//    { type: "zip", label: "28608", value: "28608" },
//    { type: "zip", label: "28609", value: "28609" },
//    { type: "zip", label: "28610", value: "28610" },
//    { type: "zip", label: "28611", value: "28611" },
//    { type: "zip", label: "28612", value: "28612" },
//    { type: "zip", label: "28613", value: "28613" },
//    { type: "zip", label: "28615", value: "28615" },
//    { type: "zip", label: "28616", value: "28616" },
//    { type: "zip", label: "28617", value: "28617" },
//    { type: "zip", label: "28618", value: "28618" },
//    { type: "zip", label: "28619", value: "28619" },
//    { type: "zip", label: "28621", value: "28621" },
//    { type: "zip", label: "28622", value: "28622" },
//    { type: "zip", label: "28623", value: "28623" },
//    { type: "zip", label: "28624", value: "28624" },
//    { type: "zip", label: "28625", value: "28625" },
//    { type: "zip", label: "28626", value: "28626" },
//    { type: "zip", label: "28627", value: "28627" },
//    { type: "zip", label: "28628", value: "28628" },
//    { type: "zip", label: "28629", value: "28629" },
//    { type: "zip", label: "28630", value: "28630" },
//    { type: "zip", label: "28631", value: "28631" },
//    { type: "zip", label: "28633", value: "28633" },
//    { type: "zip", label: "28634", value: "28634" },
//    { type: "zip", label: "28635", value: "28635" },
//    { type: "zip", label: "28636", value: "28636" },
//    { type: "zip", label: "28637", value: "28637" },
//    { type: "zip", label: "28638", value: "28638" },
//    { type: "zip", label: "28640", value: "28640" },
//    { type: "zip", label: "28641", value: "28641" },
//    { type: "zip", label: "28642", value: "28642" },
//    { type: "zip", label: "28643", value: "28643" },
//    { type: "zip", label: "28644", value: "28644" },
//    { type: "zip", label: "28645", value: "28645" },
//    { type: "zip", label: "28646", value: "28646" },
//    { type: "zip", label: "28647", value: "28647" },
//    { type: "zip", label: "28649", value: "28649" },
//    { type: "zip", label: "28650", value: "28650" },
//    { type: "zip", label: "28651", value: "28651" },
//    { type: "zip", label: "28652", value: "28652" },
//    { type: "zip", label: "28653", value: "28653" },
//    { type: "zip", label: "28654", value: "28654" },
//    { type: "zip", label: "28655", value: "28655" },
//    { type: "zip", label: "28656", value: "28656" },
//    { type: "zip", label: "28657", value: "28657" },
//    { type: "zip", label: "28658", value: "28658" },
//    { type: "zip", label: "28659", value: "28659" },
//    { type: "zip", label: "28660", value: "28660" },
//    { type: "zip", label: "28661", value: "28661" },
//    { type: "zip", label: "28662", value: "28662" },
//    { type: "zip", label: "28663", value: "28663" },
//    { type: "zip", label: "28664", value: "28664" },
//    { type: "zip", label: "28665", value: "28665" },
//    { type: "zip", label: "28666", value: "28666" },
//    { type: "zip", label: "28667", value: "28667" },
//    { type: "zip", label: "28668", value: "28668" },
//    { type: "zip", label: "28669", value: "28669" },
//    { type: "zip", label: "28670", value: "28670" },
//    { type: "zip", label: "28671", value: "28671" },
//    { type: "zip", label: "28672", value: "28672" },
//    { type: "zip", label: "28673", value: "28673" },
//    { type: "zip", label: "28674", value: "28674" },
//    { type: "zip", label: "28675", value: "28675" },
//    { type: "zip", label: "28676", value: "28676" },
//    { type: "zip", label: "28677", value: "28677" },
//    { type: "zip", label: "28678", value: "28678" },
//    { type: "zip", label: "28679", value: "28679" },
//    { type: "zip", label: "28680", value: "28680" },
//    { type: "zip", label: "28681", value: "28681" },
//    { type: "zip", label: "28682", value: "28682" },
//    { type: "zip", label: "28683", value: "28683" },
//    { type: "zip", label: "28684", value: "28684" },
//    { type: "zip", label: "28685", value: "28685" },
//    { type: "zip", label: "28687", value: "28687" },
//    { type: "zip", label: "28688", value: "28688" },
//    { type: "zip", label: "28689", value: "28689" },
//    { type: "zip", label: "28690", value: "28690" },
//    { type: "zip", label: "28691", value: "28691" },
//    { type: "zip", label: "28692", value: "28692" },
//    { type: "zip", label: "28693", value: "28693" },
//    { type: "zip", label: "28694", value: "28694" },
//    { type: "zip", label: "28697", value: "28697" },
//    { type: "zip", label: "28698", value: "28698" },
//    { type: "zip", label: "28699", value: "28699" },
//    { type: "zip", label: "28701", value: "28701" },
//    { type: "zip", label: "28702", value: "28702" },
//    { type: "zip", label: "28704", value: "28704" },
//    { type: "zip", label: "28705", value: "28705" },
//    { type: "zip", label: "28707", value: "28707" },
//    { type: "zip", label: "28708", value: "28708" },
//    { type: "zip", label: "28709", value: "28709" },
//    { type: "zip", label: "28710", value: "28710" },
//    { type: "zip", label: "28711", value: "28711" },
//    { type: "zip", label: "28712", value: "28712" },
//    { type: "zip", label: "28713", value: "28713" },
//    { type: "zip", label: "28714", value: "28714" },
//    { type: "zip", label: "28715", value: "28715" },
//    { type: "zip", label: "28716", value: "28716" },
//    { type: "zip", label: "28717", value: "28717" },
//    { type: "zip", label: "28718", value: "28718" },
//    { type: "zip", label: "28719", value: "28719" },
//    { type: "zip", label: "28720", value: "28720" },
//    { type: "zip", label: "28721", value: "28721" },
//    { type: "zip", label: "28722", value: "28722" },
//    { type: "zip", label: "28723", value: "28723" },
//    { type: "zip", label: "28724", value: "28724" },
//    { type: "zip", label: "28725", value: "28725" },
//    { type: "zip", label: "28726", value: "28726" },
//    { type: "zip", label: "28727", value: "28727" },
//    { type: "zip", label: "28728", value: "28728" },
//    { type: "zip", label: "28729", value: "28729" },
//    { type: "zip", label: "28730", value: "28730" },
//    { type: "zip", label: "28731", value: "28731" },
//    { type: "zip", label: "28732", value: "28732" },
//    { type: "zip", label: "28733", value: "28733" },
//    { type: "zip", label: "28734", value: "28734" },
//    { type: "zip", label: "28735", value: "28735" },
//    { type: "zip", label: "28736", value: "28736" },
//    { type: "zip", label: "28737", value: "28737" },
//    { type: "zip", label: "28738", value: "28738" },
//    { type: "zip", label: "28739", value: "28739" },
//    { type: "zip", label: "28740", value: "28740" },
//    { type: "zip", label: "28741", value: "28741" },
//    { type: "zip", label: "28742", value: "28742" },
//    { type: "zip", label: "28743", value: "28743" },
//    { type: "zip", label: "28744", value: "28744" },
//    { type: "zip", label: "28745", value: "28745" },
//    { type: "zip", label: "28746", value: "28746" },
//    { type: "zip", label: "28747", value: "28747" },
//    { type: "zip", label: "28748", value: "28748" },
//    { type: "zip", label: "28749", value: "28749" },
//    { type: "zip", label: "28750", value: "28750" },
//    { type: "zip", label: "28751", value: "28751" },
//    { type: "zip", label: "28752", value: "28752" },
//    { type: "zip", label: "28753", value: "28753" },
//    { type: "zip", label: "28754", value: "28754" },
//    { type: "zip", label: "28755", value: "28755" },
//    { type: "zip", label: "28756", value: "28756" },
//    { type: "zip", label: "28757", value: "28757" },
//    { type: "zip", label: "28758", value: "28758" },
//    { type: "zip", label: "28760", value: "28760" },
//    { type: "zip", label: "28761", value: "28761" },
//    { type: "zip", label: "28762", value: "28762" },
//    { type: "zip", label: "28763", value: "28763" },
//    { type: "zip", label: "28765", value: "28765" },
//    { type: "zip", label: "28766", value: "28766" },
//    { type: "zip", label: "28768", value: "28768" },
//    { type: "zip", label: "28770", value: "28770" },
//    { type: "zip", label: "28771", value: "28771" },
//    { type: "zip", label: "28772", value: "28772" },
//    { type: "zip", label: "28773", value: "28773" },
//    { type: "zip", label: "28774", value: "28774" },
//    { type: "zip", label: "28775", value: "28775" },
//    { type: "zip", label: "28776", value: "28776" },
//    { type: "zip", label: "28777", value: "28777" },
//    { type: "zip", label: "28778", value: "28778" },
//    { type: "zip", label: "28779", value: "28779" },
//    { type: "zip", label: "28781", value: "28781" },
//    { type: "zip", label: "28782", value: "28782" },
//    { type: "zip", label: "28783", value: "28783" },
//    { type: "zip", label: "28784", value: "28784" },
//    { type: "zip", label: "28785", value: "28785" },
//    { type: "zip", label: "28786", value: "28786" },
//    { type: "zip", label: "28787", value: "28787" },
//    { type: "zip", label: "28788", value: "28788" },
//    { type: "zip", label: "28789", value: "28789" },
//    { type: "zip", label: "28790", value: "28790" },
//    { type: "zip", label: "28791", value: "28791" },
//    { type: "zip", label: "28792", value: "28792" },
//    { type: "zip", label: "28793", value: "28793" },
//    { type: "zip", label: "28801", value: "28801" },
//    { type: "zip", label: "28802", value: "28802" },
//    { type: "zip", label: "28803", value: "28803" },
//    { type: "zip", label: "28804", value: "28804" },
//    { type: "zip", label: "28805", value: "28805" },
//    { type: "zip", label: "28806", value: "28806" },
//    { type: "zip", label: "28810", value: "28810" },
//    { type: "zip", label: "28813", value: "28813" },
//    { type: "zip", label: "28814", value: "28814" },
//    { type: "zip", label: "28815", value: "28815" },
//    { type: "zip", label: "28816", value: "28816" },
//    { type: "zip", label: "28901", value: "28901" },
//    { type: "zip", label: "28902", value: "28902" },
//    { type: "zip", label: "28903", value: "28903" },
//    { type: "zip", label: "28904", value: "28904" },
//    { type: "zip", label: "28905", value: "28905" },
//    { type: "zip", label: "28906", value: "28906" },
//    { type: "zip", label: "28909", value: "28909" },
// ];

export const ZIP_LIST = [
   { type: "zip", label: "37201", value: "37201" },
   { type: "zip", label: "37203", value: "37203" },
   { type: "zip", label: "37204", value: "37204" },
   { type: "zip", label: "37205", value: "37205" },
   { type: "zip", label: "37206", value: "37206" },
   { type: "zip", label: "37207", value: "37207" },
   { type: "zip", label: "37208", value: "37208" },
   { type: "zip", label: "37209", value: "37209" },
   { type: "zip", label: "37210", value: "37210" },
   { type: "zip", label: "37211", value: "37211" },
   { type: "zip", label: "37212", value: "37212" },
   { type: "zip", label: "37214", value: "37214" },
   { type: "zip", label: "37215", value: "37215" },
   { type: "zip", label: "37216", value: "37216" },
   { type: "zip", label: "37217", value: "37217" },
   { type: "zip", label: "37218", value: "37218" },
   { type: "zip", label: "37219", value: "37219" },
   { type: "zip", label: "37220", value: "37220" },
   { type: "zip", label: "37221", value: "37221" },
];

export const SHIPPING_TERMS_LIST = [
   {
      label: "Free to ALL NC Military Installations",
      value: "Free to ALL NC Military Installations",
      type: "shipping_terms"
   },
   {
      label: "Free to Ft. Bragg",
      value: "Free to Ft. Bragg",
      type: "shipping_terms"
   },
   {
      label: "Free to Pope AFB",
      value: "Free to Pope AFB",
      type: "shipping_terms"
   },
   {
      label: "Free to Seymour Johnson AFB",
      value: "Free to Seymour Johnson AFB",
      type: "shipping_terms"
   },
   {
      label: "Free to Camp Lejeune",
      value: "Free to Camp Lejeune",
      type: "shipping_terms"
   },
   {
      label: "Free to Cherry Point",
      value: "Free to Cherry Point",
      type: "shipping_terms"
   }
]

export const BUSINESS_POST_LIST = [
   {
      label: "Item title",
      type: "text",
      name: "name",
      id: 1,
      info_type: "post",
      isRequired: true
   },
   {
      label: "Product or Service You Need",
      type: "text",
      name: "description",
      id: 2,
      info_type: "post",
      isRequired: true
   },
   {
      label: "Quantity",
      type: "text",
      name: "quantity",
      id: 3,
      info_type: "post",
      isRequired: false
   },
   {
      label: "Date Item Required",
      type: "date",
      name: "required_date",
      id: 4,
      info_type: "post",
      isRequired: true
   },
   {
      label: "Address",
      type: "text",
      name: "city",
      id: 5,
      info_type: "post",
      isRequired: false
   },
   {
      label: "State",
      type: "text",
      name: "state",
      id: 6,
      info_type: "post",
      isRequired: false
   },
   {
      label: "Zip",
      type: "text",
      name: "zip",
      id: 7,
      info_type: "post",
      isRequired: false
   },
   {
      label: "Business Identifier",
      type: "select",
      name: "setaside_code",
      id: 8,
      info_type: "post",
      isRequired: false
   },
   {
      label: "Describe Product or Service You Need",
      type: "textarea",
      name: "comment",
      id: 9,
      info_type: "post",
      isRequired: true
   },
   // {
   //    label: "Primary NAICS",
   //    type: "select",
   //    name: "naics",
   //    id: 10,
   //    info_type: "post",
   //    isRequired: true
   // },
   {
      label: "Applicable Link",
      type: "text",
      name: "link",
      id: 11,
      info_type: "post",
      isRequired: false
   },
]

export const ADMIN_POST_LIST = [
   {
      label: "Your Name",
      type: "text",
      name: "name",
      id: 1,
      info_type: "post",
      isRequired: true
   },
   {
      label: "Organization Name",
      type: "text",
      name: "agency_name",
      id: 2,
      info_type: "post",
      isRequired: true
   },
   // {
   //    label: "Requestor Name",
   //    type: "text",
   //    name: "requestor_name",
   //    id: 3,
   //    info_type: "post",
   //    isRequired: false
   // },
   {
      label: "Product or Service You Need",
      type: "text",
      name: "description",
      id: 4,
      info_type: "post",
      isRequired: true
   },
   {
      label: "Quantity",
      type: "text",
      name: "quantity",
      id: 5,
      info_type: "post",
      isRequired: false
   },
   {
      label: "Date Item Required",
      type: "date",
      name: "required_date",
      id: 6,
      info_type: "post",
      isRequired: true
   },
   // {
   //    label: "Closing Date",
   //    type: "date",
   //    name: "closing_date",
   //    id: 7,
   //    info_type: "post",
   //    isRequired: true
   // },
   {
      label: "Address",
      type: "text",
      name: "city",
      id: 8,
      info_type: "post",
      isRequired: false
   },
   {
      label: "State",
      type: "text",
      name: "state",
      id: 9,
      info_type: "post",
      isRequired: false
   },
   {
      label: "Zip",
      type: "text",
      name: "zip",
      id: 10,
      info_type: "post",
      isRequired: false
   },
   // {
   //    label: "Part Number",
   //    type: "text",
   //    name: "part_number",
   //    id: 11,
   //    info_type: "post",
   //    isRequired: false
   // },
   {
      label: "Describe Product or Service You Need",
      type: "textarea",
      name: "comment",
      id: 12,
      info_type: "post",
      isRequired: true
   },
   // {
   //    label: "Primary NAICS",
   //    type: "text",
   //    name: "naics",
   //    id: 13,
   //    info_type: "post",
   //    isRequired: true
   // },
   // {
   //    label: "Set-Aside",
   //    type: "select",
   //    name: "setaside_code",
   //    id: 14,
   //    info_type: "post",
   //    isRequired: true
   // },
   {
      label: "Applicable Link",
      type: "text",
      name: "link",
      id: 15,
      info_type: "post",
      isRequired: false
   },
]

export const OFFICER_POST_LIST = [
   {
      label: "Purchase Name",
      type: "text",
      name: "name",
      id: 1,
      info_type: "post",
      isRequired: true
   },
   {
      label: "Line Item Description",
      type: "text",
      name: "description",
      id: 2,
      info_type: "post",
      isRequired: true
   },
   {
      label: "Agency Name",
      type: "text",
      name: "agency_name",
      id: 3,
      info_type: "post",
      isRequired: true
   },
   {
      label: "Name of Requestor",
      type: "text",
      name: "requestor_name",
      id: 4,
      info_type: "post",
      isRequired: false
   },
   {
      label: "Date Requested",
      type: "date",
      name: "required_date",
      id: 5,
      info_type: "post",
      isRequired: true
   },
   {
      label: "Closing Date",
      type: "date",
      name: "closing_date",
      id: 6,
      info_type: "post",
      isRequired: true
   },
   {
      label: "Primary NAICS",
      type: "text",
      name: "naics",
      id: 7,
      info_type: "post",
      isRequired: true
   },
   {
      label: "Part Number/Stock Number",
      type: "text",
      name: "part_number",
      id: 8,
      info_type: "post",
      isRequired: false
   },
   {
      label: "Quantity",
      type: "text",
      name: "quantity",
      id: 9,
      info_type: "post",
      isRequired: false
   },
   {
      label: "City/Installation",
      type: "text",
      name: "city",
      id: 10,
      info_type: "post",
      isRequired: false
   },
   {
      label: "State",
      type: "text",
      name: "state",
      id: 11,
      info_type: "post",
      isRequired: false
   },
   {
      label: "ZIP Code",
      type: "text",
      name: "zip",
      id: 12,
      info_type: "post",
      isRequired: false
   },
   {
      label: "Business Type/Set-Aside",
      type: "select",
      name: "setaside_code",
      id: 13,
      info_type: "post",
      isRequired: true
   },
   {
      label: "Comments",
      type: "text",
      name: "comment",
      id: 14,
      info_type: "post",
      isRequired: false
   },
   {
      label: "Applicable Link",
      type: "text",
      name: "link",
      id: 15,
      info_type: "post",
      isRequired: false
   },
]

export const STATE_LIST = [
   {
      type: "state",
      label: "North Carolina",
      value: "North Carolina"
   },
   {
      type: "state",
      label: "Alabama",
      value: "Alabama"
   },
   {
      type: "state",
      label: "Alaska",
      value: "Alaska"
   },
   {
      type: "state",
      label: "Arizona",
      value: "Arizona"
   },
   {
      type: "state",
      label: "Arkansas",
      value: "Arkansas"
   },
   {
      type: "state",
      label: "California",
      value: "California"
   },
   {
      type: "state",
      label: "Colorado",
      value: "Colorado"
   },
   {
      type: "state",
      label: "Connecticut",
      value: "Connecticut"
   },
   {
      type: "state",
      label: "Delaware",
      value: "Delaware"
   },
   {
      type: "state",
      label: "District of Columbia",
      value: "District of Columbia"
   },
   {
      type: "state",
      label: "Florida",
      value: "Florida"
   },
   {
      type: "state",
      label: "Georgia",
      value: "Georgia"
   },
   {
      type: "state",
      label: "Hawaii",
      value: "Hawaii"
   },
   {
      type: "state",
      label: "Idaho",
      value: "Idaho"
   },
   {
      type: "state",
      label: "Illinois",
      value: "Illinois"
   },
   {
      type: "state",
      label: "Indiana",
      value: "Indiana"
   },
   {
      type: "state",
      label: "Iowa",
      value: "Iowa"
   },
   {
      type: "state",
      label: "Kansas",
      value: "Kansas"
   },
   {
      type: "state",
      label: "Kentucky",
      value: "Kentucky"
   },
   {
      type: "state",
      label: "Louisiana",
      value: "Louisiana"
   },
   {
      type: "state",
      label: "Maine",
      value: "Maine"
   },
   {
      type: "state",
      label: "Maryland",
      value: "Maryland"
   },
   {
      type: "state",
      label: "Massachusetts",
      value: "Massachusetts"
   },
   {
      type: "state",
      label: "Michigan",
      value: "Michigan"
   },
   {
      type: "state",
      label: "Minnesota",
      value: "Minnesota"
   },
   {
      type: "state",
      label: "Mississippi",
      value: "Mississippi"
   },
   {
      type: "state",
      label: "Missouri",
      value: "Missouri"
   },
   {
      type: "state",
      label: "Montana",
      value: "Montana"
   },
   {
      type: "state",
      label: "Nebraska",
      value: "Nebraska"
   },
   {
      type: "state",
      label: "Nevada",
      value: "Nevada"
   },
   {
      type: "state",
      label: "New Hampshire",
      value: "New Hampshire"
   },
   {
      type: "state",
      label: "New Jersey",
      value: "New Jersey"
   },
   {
      type: "state",
      label: "New Mexico",
      value: "New Mexico"
   },
   {
      type: "state",
      label: "New York",
      value: "New York"
   },
   {
      type: "state",
      label: "North Dakota",
      value: "North Dakota"
   },
   {
      type: "state",
      label: "Ohio",
      value: "Ohio"
   },
   {
      type: "state",
      label: "Oklahoma",
      value: "Oklahoma"
   },
   {
      type: "state",
      label: "Oregon",
      value: "Oregon"
   },
   {
      type: "state",
      label: "Pennsylvania",
      value: "Pennsylvania"
   },
   {
      type: "state",
      label: "Rhode Isand",
      value: "Rhode Isand"
   },
   {
      type: "state",
      label: "South Carolina",
      value: "South Carolina"
   },
   {
      type: "state",
      label: "South Dakota",
      value: "South Dakota"
   },
   {
      type: "state",
      label: "Tennessee",
      value: "Tennessee"
   },
   {
      type: "state",
      label: "Texas",
      value: "Texas"
   },
   {
      type: "state",
      label: "Utah",
      value: "Utah"
   },
   {
      type: "state",
      label: "Vermont",
      value: "Vermont"
   },
   {
      type: "state",
      label: "Virginia",
      value: "Virginia"
   },
   {
      type: "state",
      label: "Washington",
      value: "Washington"
   },
   {
      type: "state",
      label: "West Virginia",
      value: "West Virginia"
   },
   {
      type: "state",
      label: "Wisconsin",
      value: "Wisconsin"
   },
   {
      type: "state",
      label: "Wyoming",
      value: "Wyoming"
   },
]

export const GOVERNMENT_INPUT_LIST = [
   {
      label: "Name",
      name: "name",
      required: true,
      type: "text",
      tooltip: "Example: John Doe"
   },
   {
      label: "Phone",
      name: "phone1",
      required: false,
      type: "tel",
      tooltip: "Please provide your phone number in this format: (xxx)yyy-zzzz"
   },
   {
      label: "Fax",
      name: "fax",
      required: false,
      type: "tel",
      tooltip: "In the event that you have a fax number, please provide it. This field is not required."
   },
   {
      label: "Alternate Phone",
      name: "phone2",
      required: false,
      type: "tel",
      tooltip: "Please provide your phone number in this format: (xxx)yyy-zzzz"
   },
   {
      label: "Alternate email",
      name: "email",
      required: false,
      type: "email",
      tooltip: "Please provide a valid email address, such as joe@abc.com. This will not be used at all times, however can be used in the event you forget your password."
   },
   {
      label: "City",
      name: "city",
      required: true,
      type: "text",
      tooltip: "The City in which you live or work."
   },
   {
      label: "State",
      name: "state",
      required: true,
      type: "text",
      tooltip: "Govtide is intended for businesses located in North Carolina.  If you are not located in NC, please register as an Associate Business."
   },
   {
      label: "ZIP Code",
      name: "zip",
      required: true,
      type: "text",
      tooltip: "Zip code in which you live or work"
   },
   {
      label: "City/Installation",
      name: "installation",
      required: true,
      type: "select",
      tooltip: "Select which government agency or organization you're with."
   }
];

export const CITY_INSTALLATION_LIST = [
   {
      label: "Fort Bragg",
      value: "Fort Bragg",
      type: "installation"
   },
   {
      label: "Camp Lejeune",
      value: "Camp Lejeune",
      type: "installation"
   },
   {
      label: "Pope AF Base",
      value: "Pope AF Base",
      type: "installation"
   },
   {
      label: "Seymour Johnson",
      value: "Seymour Johnson",
      type: "installation"
   },
   {
      label: "Cherry Point",
      value: "Cherry Point",
      type: "installation"
   },
   {
      label: "USCG Elizabeth City",
      value: "USCG Elizabeth City",
      type: "installation"
   },
   {
      label: "Other",
      value: "Other",
      type: "installation"
   }
];

export const RELATIONSHIP_LIST = [
   { value: "Business Organization", label: "Business Organization", type: "gov_relations" },
   { value: "Government Entity", label: "Government Entity", type: "gov_relations" },
   { value: "Contracting Consultant", label: "Contracting Consultant", type: "gov_relations" },
   { value: "Lobbyist Group", label: "Lobbyist Group", type: "gov_relations" },
   { value: "Lender Institution", label: "Lender Institution", type: "gov_relations" },
   { value: "Financial Investor", label: "Financial Investor", type: "gov_relations" },
   { value: "Individual Employee", label: "Individual Employee", type: "gov_relations" },
   { value: "Media Entity", label: "Media Entity", type: "gov_relations" },
   { value: "Elected Official", label: "Elected Official", type: "gov_relations" },
]
    

export const ROLE_LIST = {
   business: "Business",
   officer: "Officer",
   admin: "Admin"
}

export const CONTRACT_OPP_SELECT_REPORTS = [
   {
      id: "contacts",
      label: "Contacts",
      name: "select"
   },
   {
      id: "class_code",
      label: "Class Code",
      name: "select"
   },
   {
      id: "description",
      label: "Description",
      name: "select"
   },
   {
      id: "naics",
      label: "NAICS Code",
      name: "select"
   },
   {
      id: "deadline",
      label: "Close Date",
      name: "select"
   },
   {
      id: "posted_date",
      label: "Request Date",
      name: "select"
   },
   {
      id: "sol_no",
      label: "Solicitation Number",
      name: "select"
   },
   {
      id: "office_address",
      label: "Office Address",
      name: "select"
   },
   {
      id: "sam_link",
      label: "Url",
      name: "select"
   },
   {
      id: "title",
      label: "Title",
      name: "select"
   }
]

export const CONTRACT_OPP_INPUT_REPORTS = [
   {
      label: "NAICS Code",
      type: "select",
      name: "naics",
      id: 1,
      info_type: "contracts"
   },
   {
      label: "City",
      type: "text",
      name: "city",
      id: 2,
      info_type: "contracts"
   },
   {
      label: "State",
      type: "text",
      name: "state",
      id: 3,
      info_type: "contracts"
   },
   {
      label: "Solicitation Number",
      type: "text",
      name: "sol_no",
      id: 4,
      info_type: "contracts"
   },
   {
      label: "Close Date",
      type: "date",
      name: "deadline",
      id: 5,
      info_type: "contracts"
   }
];

export const B2B_OPPORTUNITIES_SELECT_REPORTS = [
   {
      id: "city",
      label: "City",
      name: "select"
   },
   {
      id: "state",
      label: "State",
      name: "select"
   },
   {
      id: "zip",
      label: "ZIP Code",
      name: "select"
   },
   {
      id: "naics",
      label: "NAICS Code",
      name: "select"
   },
   {
      id: "required_date",
      label: "Date Required",
      name: "select"
   },
   {
      id: "link",
      label: "Url",
      name: "select"
   },
   {
      id: "name",
      label: "Title",
      name: "select"
   },
   {
      id: "description",
      label: "Description",
      name: 'select'
   },
   {
      id: "comment",
      label: "Comment",
      name: 'select'
   },
   {
      id: "quantity",
      label: "Quantity",
      name: "select"
   },
   {
      id: "setaside_code",
      label: "Business Type",
      name: "select"
   }
]

export const B2B_OPP_INPUT_REPORTS = [
   {
      label: "Comment",
      type: "textarea",
      name: "comment",
      id: 2,
      info_type: "contracts"
   },
   {
      label: "Keywords",
      type: "textarea",
      name: "keywords",
      id: 3,
      info_type: "contracts"
   },
   {
      label: "Title",
      type: "text",
      name: "name",
      id: 4,
      info_type: "contracts"
   },
   {
      label: "Date Required",
      type: "date",
      name: "required_date_range",
      id: 5,
      info_type: "contracts"
   },
   {
      label: "Business Type",
      type: "select",
      name: "setaside_code",
      id: 6,
      info_type: "contracts"
   }
];

export const PURCHASES_SELECT_REPORTS = [
   {
      id: "description",
      label: "Description",
      name: "select"
   },
   {
      id: "required_date",
      label: "Date Required",
      name: "select"
   },
   {
      id: "city",
      label: "City",
      name: "select"
   },
   {
      id: "state",
      label: "State",
      name: "select"
   },
   {
      id: "zip",
      label: "ZIP Code",
      name: "select"
   },
   {
      id: "comment",
      label: "Comments",
      name: "select"
   },
   {
      id: "quantity",
      label: "Quantity",
      name: "select"
   },
   {
      id: "closing_date",
      label: "Request Date",
      name: 'select'
   },
   {
      id: "link",
      label: "Url",
      name: 'select'
   },
   {
      id: "name",
      label: "Purchase Name",
      name: "select"
   }
]

export const PURCHASES_INPUT_REPORTS = [
   {
      label: "Comment",
      type: "textarea",
      name: "comment",
      id: 2,
      info_type: "contracts"
   },
   {
      label: "Keywords",
      type: "textarea",
      name: "keywords",
      id: 3,
      info_type: "contracts"
   },
   {
      label: "Title",
      type: "text",
      name: "name",
      id: 4,
      info_type: "contracts"
   },
   {
      label: "Date Required",
      type: "date",
      name: "required_date_range",
      id: 5,
      info_type: "contracts"
   },
   {
      label: "Business Type",
      type: "select",
      name: "setaside_code",
      id: 6,
      info_type: "contracts"
   }
];

export const BUSINESS_SELECT_REPORTS = [
   {
      id: "address1",
      label: "Address",
      name: "select"
   },
   {
      id: "city",
      label: "City",
      name: "select"
   },
   {
      id: "contact",
      label: "Contact",
      name: "select"
   },
   {
      id: "description",
      label: "Description",
      name: "select"
   },
   {
      id: "duns_number",
      label: "DUNS Number",
      name: "select"
   },
   {
      id: "email",
      label: "Email",
      name: "select"
   },
   {
      id: "fax",
      label: "Fax",
      name: 'select'
   },
   {
      id: "keywords",
      label: "Keywords",
      name: 'select'
   },
   {
      id: "naics",
      label: "NAICS Code",
      name: "select"
   },
   {
      id: "name",
      label: "Name",
      name: "select"
   },
   {
      id: "phone",
      label: "Phone",
      name: "select"
   },
   {
      id: "state",
      label: "State",
      name: "select"
   },
   {
      id: "url",
      label: "Url",
      name: "select"
   },
   {
      id: "zip",
      label: "ZIP Code",
      name: "select"
   }
]

export const BUSINESS_INPUT_REPORTS = [
   {
      label: "City",
      type: "select",
      name: "city",
      id: 2,
      info_type: "contracts"
   },
   {
      label: "NAICS Code",
      type: "select",
      name: "naics",
      id: 3,
      info_type: "contracts"
   },
   {
      label: "Business Type",
      type: "select",
      name: "type",
      id: 4,
      info_type: "contracts"
   },
   {
      label: "County",
      type: "select",
      name: "county",
      id: 5,
      info_type: "contracts"
   },
   {
      label: "Registered in SAM?",
      type: "radio",
      name: "registered_in_sam",
      choises: {
         f_id: "env_products_yes",
         s_id: "env_products_no"
      },
      id: 6,
      info_type: "contracts"
   },
   {
      label: "Keywords",
      type: "textarea",
      name: "keywords",
      id: 7,
      info_type: "contracts"
   },
   {
      label: "Set-Aside",
      type: "select",
      name: "set_aside",
      id: 8,
      info_type: "contracts"
   },
   {
      label: "ZIP Code",
      type: "text",
      name: "zip",
      id: 9,
      info_type: "contracts"
   }
];

export const OFFICER_SELECT_REPORTS = [
   {
      id: "address1",
      label: "Address",
      name: "select"
   },
   {
      id: "city",
      label: "City",
      name: "select"
   },
   {
      id: "county",
      label: "County",
      name: "select"
   },
   {
      id: "email",
      label: "Email",
      name: "select"
   },
   {
      id: "fax",
      label: "Fax",
      name: "select"
   },
   {
      id: "installation",
      label: "Installation",
      name: "select"
   },
   {
      id: "name",
      label: "Name",
      name: 'select'
   },
   {
      id: "phone1",
      label: "Phone",
      name: 'select'
   },
   {
      id: "state",
      label: "State",
      name: "select"
   },
   {
      id: "zip",
      label: "ZIP Code",
      name: "select"
   }
]

export const OFFICER_INPUT_REPORTS = [
   {
      label: "City",
      type: "select",
      name: "city",
      id: 1,
      info_type: "contracts"
   },
   {
      label: "Installation",
      type: "select",
      name: "installation",
      id: 2,
      info_type: "contracts"
   },
   {
      label: "County",
      type: "select",
      name: "county",
      id: 3,
      info_type: "contracts"
   },
   {
      label: "ZIP Code",
      type: "select",
      name: "zip",
      id: 4,
      info_type: "contracts"
   },
   {
      label: "Name",
      type: "text",
      name: "name",
      id: 6,
      info_type: "contracts"
   },
   {
      label: "Office",
      type: "text",
      name: "office",
      id: 7,
      info_type: "contracts"
   },
   {
      label: "Registered",
      type: "date",
      name: "registered",
      id: 8,
      info_type: "contracts"
   }
];

export const USER_SELECT_REPORTS = [
   {
      id: "email",
      label: "Email",
      name: "select"
   },
   {
      id: "is_active",
      label: "Status",
      name: "select"
   },
   {
      id: "registration_date",
      label: "Registration Date",
      name: "select"
   },
   {
      id: "role_description",
      label: "Role",
      name: "select"
   },
   {
      id: "source_info",
      label: "Source Info",
      name: "select"
   },
   {
      id: "username",
      label: "User Name",
      name: "select"
   }
]

export const USER_INPUT_REPORTS = [
   {
      label: "Registration Date",
      type: "date",
      name: "registration_date",
      id: 1,
      info_type: "contracts"
   },
   {
      label: "User Name",
      type: "text",
      name: "username",
      id: 2,
      info_type: "contracts"
   },
   {
      label: "Email",
      type: "email",
      name: "email",
      id: 3,
      info_type: "contracts"
   },
   {
      label: "User Role",
      type: "select",
      name: "role_description",
      id: 4,
      info_type: "contracts"
   }
];

export const ROLES_LIST = [
   { value: "admin", label: "Admin", type: "role_description" },
   { value: "business", label: "Business", type: "role_description" },
   { value: "officer", label: "Officer", type: "role_description" }
]

export const PSC_LIST = [
   {
      "label": "10 - WEAPONS",
      "value": "10",
      "type": "psc_code"
   },
   {
      "label": "",
      "value": "1000",
      "type": "psc_code"
   },
   {
      "label": "1005 - GUNS, THROUGH 30MM",
      "value": "1005",
      "type": "psc_code"
   },
   {
      "label": "1005 - GUNS, THROUGH 30 MM",
      "value": "1005",
      "type": "psc_code"
   },
   {
      "label": "1010 - GUNS, OVER 30MM UP TO 75MM",
      "value": "1010",
      "type": "psc_code"
   },
   {
      "label": "1010 - GUNS, OVER 30 MM UP TO 75 MM",
      "value": "1010",
      "type": "psc_code"
   },
   {
      "label": "1015 - GUNS, 75MM THROUGH 125MM",
      "value": "1015",
      "type": "psc_code"
   },
   {
      "label": "1015 - GUNS, 75 MM THROUGH 125 MM",
      "value": "1015",
      "type": "psc_code"
   },
   {
      "label": "1020 - GUNS, OVER 125MM THROUGH 150MM",
      "value": "1020",
      "type": "psc_code"
   },
   {
      "label": "1020 - GUNS OVER 125 MM THROUGH 150 MM",
      "value": "1020",
      "type": "psc_code"
   },
   {
      "label": "1025 - GUNS, OVER 150MM THROUGH 200MM",
      "value": "1025",
      "type": "psc_code"
   },
   {
      "label": "1025 - GUNS OVER 150 MM THROUGH 200 MM",
      "value": "1025",
      "type": "psc_code"
   },
   {
      "label": "1030 - GUNS, OVER 200MM THROUGH 300MM",
      "value": "1030",
      "type": "psc_code"
   },
   {
      "label": "1030 - GUNS OVER 200 MM THROUGH 300 MM",
      "value": "1030",
      "type": "psc_code"
   },
   {
      "label": "1035 - GUNS, OVER 300MM",
      "value": "1035",
      "type": "psc_code"
   },
   {
      "label": "1035 - GUNS, OVER 300 MM",
      "value": "1035",
      "type": "psc_code"
   },
   {
      "label": "1040 - CHEMICAL WEAPONS AND EQUIPMENT",
      "value": "1040",
      "type": "psc_code"
   },
   {
      "label": "1045 - LAUNCHERS, TORPEDO AND DEPTH CHARGE",
      "value": "1045",
      "type": "psc_code"
   },
   {
      "label": "1055 - LAUNCHERS, ROCKET AND PYROTECHNIC",
      "value": "1055",
      "type": "psc_code"
   },
   {
      "label": "1070 - NETS AND BOOMS, ORDNANCE",
      "value": "1070",
      "type": "psc_code"
   },
   {
      "label": "1075 - DEGAUSSING AND MINE SWEEPING EQUIPMENT",
      "value": "1075",
      "type": "psc_code"
   },
   {
      "label": "1075 - DEGAUSSING & MINE SWEEPING EQUIPMEN",
      "value": "1075",
      "type": "psc_code"
   },
   {
      "label": "1080 - CAMOUFLAGE AND DECEPTION EQUIPMENT",
      "value": "1080",
      "type": "psc_code"
   },
   {
      "label": "1090 - ASSEMBLIES INTERCHANGEABLE BETWEEN WEAPONS IN TWO OR MORE CLASSES",
      "value": "1090",
      "type": "psc_code"
   },
   {
      "label": "1090 - WPNS ASSY INTERCHANGE BETWN 2/MORE",
      "value": "1090",
      "type": "psc_code"
   },
   {
      "label": "1095 - MISCELLANEOUS WEAPONS",
      "value": "1095",
      "type": "psc_code"
   },
   {
      "label": "11 - NUCLEAR ORDNANCE",
      "value": "11",
      "type": "psc_code"
   },
   {
      "label": "1105 - NUCLEAR BOMBS",
      "value": "1105",
      "type": "psc_code"
   },
   {
      "label": "1110 - NUCLEAR PROJECTILES",
      "value": "1110",
      "type": "psc_code"
   },
   {
      "label": "1115 - NUCLEAR WARHEADS & WARHEAD SECTIONS",
      "value": "1115",
      "type": "psc_code"
   },
   {
      "label": "1115 - NUCLEAR WARHEADS AND WARHEAD SECTIONS",
      "value": "1115",
      "type": "psc_code"
   },
   {
      "label": "1120 - NUCLEAR DEPTH CHARGES",
      "value": "1120",
      "type": "psc_code"
   },
   {
      "label": "1125 - NUCLEAR DEMOLITION CHARGES",
      "value": "1125",
      "type": "psc_code"
   },
   {
      "label": "1127 - NUCLEAR ROCKETS",
      "value": "1127",
      "type": "psc_code"
   },
   {
      "label": "1130 - CONVERSION KITS, NUCLEAR ORDNANCE",
      "value": "1130",
      "type": "psc_code"
   },
   {
      "label": "1135 - FUZING AND FIRING DEVICES, NUCLEAR ORDNANCE",
      "value": "1135",
      "type": "psc_code"
   },
   {
      "label": "1135 - FUZING & FIRING DEVICES, NUCLEAR OR",
      "value": "1135",
      "type": "psc_code"
   },
   {
      "label": "1140 - NUCLEAR COMPONENTS",
      "value": "1140",
      "type": "psc_code"
   },
   {
      "label": "1145 - EXPLOSIVE & PYROTECHNIC COMPS, NUCL",
      "value": "1145",
      "type": "psc_code"
   },
   {
      "label": "1145 - EXPLOSIVE AND PYROTECHNIC COMPONENTS, NUCLEAR ORDNANCE",
      "value": "1145",
      "type": "psc_code"
   },
   {
      "label": "1190 - SPEC TEST & HANDLING EQ, NUCLEAR EQ",
      "value": "1190",
      "type": "psc_code"
   },
   {
      "label": "1190 - SPECIALIZED TEST AND HANDLING EQUIPMENT, NUCLEAR ORDNANCE",
      "value": "1190",
      "type": "psc_code"
   },
   {
      "label": "1195 - MISCELLANEOUS NUCLEAR ORDNANCE",
      "value": "1195",
      "type": "psc_code"
   },
   {
      "label": "12 - FIRE CONTROL EQPT.",
      "value": "12",
      "type": "psc_code"
   },
   {
      "label": "1210 - FIRE CONTROL DIRECTORS",
      "value": "1210",
      "type": "psc_code"
   },
   {
      "label": "1220 - FIRE CONTROL COMPUTING SIGHTS AND DEVICES",
      "value": "1220",
      "type": "psc_code"
   },
   {
      "label": "1220 - FIRE CONT COMPUTING SIGHTS & DEVICE",
      "value": "1220",
      "type": "psc_code"
   },
   {
      "label": "1230 - FIRE CONTROL SYSTEMS, COMPLETE",
      "value": "1230",
      "type": "psc_code"
   },
   {
      "label": "1240 - OPTICAL SIGHTING AND RANGING EQUIPMENT",
      "value": "1240",
      "type": "psc_code"
   },
   {
      "label": "1240 - OPTICAL SIGHTING & RANGING EQUIPMEN",
      "value": "1240",
      "type": "psc_code"
   },
   {
      "label": "1250 - FIRE CONTROL STABILIZING MECHANISMS",
      "value": "1250",
      "type": "psc_code"
   },
   {
      "label": "1260 - FIRE CONT DESIGNATING-INDICATING EQ",
      "value": "1260",
      "type": "psc_code"
   },
   {
      "label": "1260 - FIRE CONTROL DESIGNATING AND INDICATING EQUIPMENT",
      "value": "1260",
      "type": "psc_code"
   },
   {
      "label": "1265 - FIRE CONT EQ EXCEPT AIRBORNE",
      "value": "1265",
      "type": "psc_code"
   },
   {
      "label": "1265 - FIRE CONTROL TRANSMITTING AND RECEIVING EQUIPMENT, EXCEPT AIRBORNE",
      "value": "1265",
      "type": "psc_code"
   },
   {
      "label": "1270 - AIRCRAFT GUNNERY FIRE CONT COMPS",
      "value": "1270",
      "type": "psc_code"
   },
   {
      "label": "1270 - AIRCRAFT GUNNERY FIRE CONTROL COMPONENTS",
      "value": "1270",
      "type": "psc_code"
   },
   {
      "label": "1280 - AIRCRAFT BOMBING FIRE CONT COMPS",
      "value": "1280",
      "type": "psc_code"
   },
   {
      "label": "1280 - AIRCRAFT BOMBING FIRE CONTROL COMPONENTS",
      "value": "1280",
      "type": "psc_code"
   },
   {
      "label": "1285 - FIRE CONTROL RADAR EQUIPMENT, EXCEPT AIRBORNE",
      "value": "1285",
      "type": "psc_code"
   },
   {
      "label": "1285 - FIRE CONT RADAR EQ EXCEPT AIRBORNE",
      "value": "1285",
      "type": "psc_code"
   },
   {
      "label": "1287 - FIRE CONTROL SONAR EQUIPMENT",
      "value": "1287",
      "type": "psc_code"
   },
   {
      "label": "1290 - MISCELLANEOUS FIRE CONTROL EQUIPMENT",
      "value": "1290",
      "type": "psc_code"
   },
   {
      "label": "1290 - MISC FIRE CONT EQUIPMENT",
      "value": "1290",
      "type": "psc_code"
   },
   {
      "label": "13 - AMMUNITION AND EXPLOSIVES",
      "value": "13",
      "type": "psc_code"
   },
   {
      "label": "1305 - AMMUNITION, THROUGH 30 MM",
      "value": "1305",
      "type": "psc_code"
   },
   {
      "label": "1305 - AMMUNITION, THROUGH 30MM",
      "value": "1305",
      "type": "psc_code"
   },
   {
      "label": "1310 - AMMUNITION, OVER 30MM UP TO 75MM",
      "value": "1310",
      "type": "psc_code"
   },
   {
      "label": "1310 - AMMUNITION, OVER 30 MM UP TO 75 MM",
      "value": "1310",
      "type": "psc_code"
   },
   {
      "label": "1315 - AMMUNITION, 75 MM THROUGH 125 MM.",
      "value": "1315",
      "type": "psc_code"
   },
   {
      "label": "1315 - AMMUNITION, 75MM THROUGH 125MM",
      "value": "1315",
      "type": "psc_code"
   },
   {
      "label": "1320 - AMMUNITION, OVER 125 MM",
      "value": "1320",
      "type": "psc_code"
   },
   {
      "label": "1320 - AMMUNITION, OVER 125MM",
      "value": "1320",
      "type": "psc_code"
   },
   {
      "label": "1325 - BOMBS",
      "value": "1325",
      "type": "psc_code"
   },
   {
      "label": "1330 - GRENADES",
      "value": "1330",
      "type": "psc_code"
   },
   {
      "label": "1336 - GUIDED MISSILE WARHEADS AND EXPLOSIVE COMPONENTS",
      "value": "1336",
      "type": "psc_code"
   },
   {
      "label": "1336 - GUIDED MSL WARHDS-EXPLOSIVE COMPS",
      "value": "1336",
      "type": "psc_code"
   },
   {
      "label": "1337 - MSL & SPACE VEH EXPLOSIVE PROPULSN",
      "value": "1337",
      "type": "psc_code"
   },
   {
      "label": "1337 - GUIDED MISSILE AND SPACE VEHICLE EXPLOSIVE PROPULSION UNITS, SOLID FUEL; AND COMPONENTS",
      "value": "1337",
      "type": "psc_code"
   },
   {
      "label": "1338 - GUIDED MISSILE AND SPACE VEHICLE INERT PROPULSION UNITS, SOLID FUEL; AND COMPONENTS",
      "value": "1338",
      "type": "psc_code"
   },
   {
      "label": "1338 - MSL & SPACE VEH INERT PROPULSION",
      "value": "1338",
      "type": "psc_code"
   },
   {
      "label": "1340 - ROCKETS, ROCKET AMMUNITION AND ROCKET COMPONENTS",
      "value": "1340",
      "type": "psc_code"
   },
   {
      "label": "1340 - ROCKETS & ROCKET AMMUNITION",
      "value": "1340",
      "type": "psc_code"
   },
   {
      "label": "1345 - LAND MINES",
      "value": "1345",
      "type": "psc_code"
   },
   {
      "label": "1346 - REMOTE MUNITIONS",
      "value": "1346",
      "type": "psc_code"
   },
   {
      "label": "1350 - UNDERWATER MINE INERT COMPONENTS",
      "value": "1350",
      "type": "psc_code"
   },
   {
      "label": "1350 - UNDERWATER MINE AND COMPONENTS, INERT",
      "value": "1350",
      "type": "psc_code"
   },
   {
      "label": "1351 - UNDERWATER MINE EXPLOSIVE COMPS",
      "value": "1351",
      "type": "psc_code"
   },
   {
      "label": "1351 - UNDERWATER MINES AND COMPONENTS, EXPLOSIVE",
      "value": "1351",
      "type": "psc_code"
   },
   {
      "label": "1352 - UNDERWATER MINE DISPOSAL INERT DEVICES",
      "value": "1352",
      "type": "psc_code"
   },
   {
      "label": "1352 - UNDERWATER MINE DISPOAL INERT DEVICES",
      "value": "1352",
      "type": "psc_code"
   },
   {
      "label": "1353 - UNDERWATER MINE DISPOSAL EXPLOSIVE DEVICES",
      "value": "1353",
      "type": "psc_code"
   },
   {
      "label": "1353 - UNDERWATER MINE DISPOAL EXPLOSIVE DEVICES",
      "value": "1353",
      "type": "psc_code"
   },
   {
      "label": "1355 - TORPEDOS AND COMPONENTS, INERT",
      "value": "1355",
      "type": "psc_code"
   },
   {
      "label": "1355 - TORPEDO INERT COMPONENTS",
      "value": "1355",
      "type": "psc_code"
   },
   {
      "label": "1356 - TORPEDOS AND COMPONENTS, EXPLOSIVE",
      "value": "1356",
      "type": "psc_code"
   },
   {
      "label": "1356 - TORPEDO EXPLOSIVE COMPONENTS",
      "value": "1356",
      "type": "psc_code"
   },
   {
      "label": "1360 - DEPTH CHARGES AND COMPONENTS, INERT",
      "value": "1360",
      "type": "psc_code"
   },
   {
      "label": "1360 - DEPTH CHARGE INERT COMPONENTS",
      "value": "1360",
      "type": "psc_code"
   },
   {
      "label": "1361 - DEPTH CHARGES AND COMPONENTS, EXPLOSIVE",
      "value": "1361",
      "type": "psc_code"
   },
   {
      "label": "1361 - DEPTH CHARGE EXPLOSIVE COMPONENTS",
      "value": "1361",
      "type": "psc_code"
   },
   {
      "label": "1365 - MILITARY CHEMICAL AGENTS",
      "value": "1365",
      "type": "psc_code"
   },
   {
      "label": "1367 - TACTICAL SETS, KITS, AND OUTFITS",
      "value": "1367",
      "type": "psc_code"
   },
   {
      "label": "1370 - PYROTECHNICS",
      "value": "1370",
      "type": "psc_code"
   },
   {
      "label": "1375 - DEMOLITION MATERIALS",
      "value": "1375",
      "type": "psc_code"
   },
   {
      "label": "1376 - BULK EXPLOSIVES",
      "value": "1376",
      "type": "psc_code"
   },
   {
      "label": "1377 - CARTRIDGE & PROPELLANT DEVICES",
      "value": "1377",
      "type": "psc_code"
   },
   {
      "label": "1377 - CARTRIDGE AND PROPELLANT ACTUATED DEVICES AND COMPONENTS",
      "value": "1377",
      "type": "psc_code"
   },
   {
      "label": "1380 - MILITARY BIOLOGICAL AGENTS",
      "value": "1380",
      "type": "psc_code"
   },
   {
      "label": "1385 - SURFACE USE EXPLOSIVE ORDNANCE DISPOSAL TOOLS AND EQUIPMENT",
      "value": "1385",
      "type": "psc_code"
   },
   {
      "label": "1385 - SURFACE EXPLOSIVE ORD DISPOSAL TOOL",
      "value": "1385",
      "type": "psc_code"
   },
   {
      "label": "1386 - UNDERWATER USE EXPLOSIVE ORDNANCE DISPOSAL AND SWIMMER WEAPONS SYSTEMS TOOLS AND EQUIPMENT",
      "value": "1386",
      "type": "psc_code"
   },
   {
      "label": "1386 - UNDERWATER EXPLOSIVE ORD DISPOSAL",
      "value": "1386",
      "type": "psc_code"
   },
   {
      "label": "1390 - FUZES AND PRIMERS",
      "value": "1390",
      "type": "psc_code"
   },
   {
      "label": "1395 - MISCELLANEOUS AMMUNITION",
      "value": "1395",
      "type": "psc_code"
   },
   {
      "label": "1398 - AMMUNITION HANDLING & SERVICING EQ",
      "value": "1398",
      "type": "psc_code"
   },
   {
      "label": "1398 - SPECIALIZED AMMUNITION HANDLING AND SERVICING EQUIPMENT",
      "value": "1398",
      "type": "psc_code"
   },
   {
      "label": "14 - GUIDED MISSLES",
      "value": "14",
      "type": "psc_code"
   },
   {
      "label": "1410 - GUIDED MISSILES",
      "value": "1410",
      "type": "psc_code"
   },
   {
      "label": "1420 - GUIDED MISSILE COMPONENTS",
      "value": "1420",
      "type": "psc_code"
   },
   {
      "label": "1425 - GUIDED MISSILE SYSTEMS, COMPLETE",
      "value": "1425",
      "type": "psc_code"
   },
   {
      "label": "1427 - GUIDED MISSILE SUBSYSTEMS",
      "value": "1427",
      "type": "psc_code"
   },
   {
      "label": "1430 - GUIDED MISSILE REMOTE CONTROL SYSTEMS",
      "value": "1430",
      "type": "psc_code"
   },
   {
      "label": "1430 - GUIDED MISSILE REMOTE CONT SYSTEMS",
      "value": "1430",
      "type": "psc_code"
   },
   {
      "label": "1440 - LAUNCHERS, GUIDED MISSILE",
      "value": "1440",
      "type": "psc_code"
   },
   {
      "label": "1450 - GUIDED MISSILE HANDLING SERVICE EQ",
      "value": "1450",
      "type": "psc_code"
   },
   {
      "label": "1450 - GUIDED MISSILE HANDLING AND SERVICING EQUIPMENT",
      "value": "1450",
      "type": "psc_code"
   },
   {
      "label": "15 - AIRCRAFT/AIRFRAME STRUCTURE COMPTS",
      "value": "15",
      "type": "psc_code"
   },
   {
      "label": "15 - AEROSPACE CRAFT AND STRUCTURAL COMPONENTS",
      "value": "15",
      "type": "psc_code"
   },
   {
      "label": "1510 - AIRCRAFT, FIXED WING",
      "value": "1510",
      "type": "psc_code"
   },
   {
      "label": "1520 - AIRCRAFT, ROTARY WING",
      "value": "1520",
      "type": "psc_code"
   },
   {
      "label": "1540 - GLIDERS",
      "value": "1540",
      "type": "psc_code"
   },
   {
      "label": "1550 - DRONES",
      "value": "1550",
      "type": "psc_code"
   },
   {
      "label": "1550 - UNMANNED AIRCRAFT",
      "value": "1550",
      "type": "psc_code"
   },
   {
      "label": "1555 - SPACE VEHICLES",
      "value": "1555",
      "type": "psc_code"
   },
   {
      "label": "1560 - AIRFRAME STRUCTURAL COMPONENTS",
      "value": "1560",
      "type": "psc_code"
   },
   {
      "label": "16 - AIRCRAFT COMPONENTS/ACCESSORIES",
      "value": "16",
      "type": "psc_code"
   },
   {
      "label": "16 - AEROSPACE CRAFT COMPONENTS AND ACCESSORIES",
      "value": "16",
      "type": "psc_code"
   },
   {
      "label": "1610 - AIRCRAFT PROPELLERS AND COMPONENTS",
      "value": "1610",
      "type": "psc_code"
   },
   {
      "label": "1615 - HELICOPTER ROTOR BLADES, DRIVE MECHANISMS AND COMPONENTS",
      "value": "1615",
      "type": "psc_code"
   },
   {
      "label": "1615 - HELICOPTER ROTOR BLADES-DRIVE MECH",
      "value": "1615",
      "type": "psc_code"
   },
   {
      "label": "1620 - AIRCRAFT LANDING GEAR COMPONENTS",
      "value": "1620",
      "type": "psc_code"
   },
   {
      "label": "1630 - AIRCRAFT WHEEL AND BRAKE SYSTEMS",
      "value": "1630",
      "type": "psc_code"
   },
   {
      "label": "1640 - AIRCRAFT CONTROL CABLE PRODUCTS",
      "value": "1640",
      "type": "psc_code"
   },
   {
      "label": "1650 - AIRCRAFT HYDRAULIC, VACUUM, AND DE-ICING SYSTEM COMPONENTS",
      "value": "1650",
      "type": "psc_code"
   },
   {
      "label": "1650 - AIRCRAFT HYDRAULIC VACUUM DE-ICING",
      "value": "1650",
      "type": "psc_code"
   },
   {
      "label": "1660 - AIRCRAFT AIR CONDITIONING, HEATING, AND PRESSURIZING EQUIPMENT",
      "value": "1660",
      "type": "psc_code"
   },
   {
      "label": "1660 - AIRCRAFT AIR CONDITION HEATING EQ",
      "value": "1660",
      "type": "psc_code"
   },
   {
      "label": "1670 - PARACHUTES; AERIAL PICK UP, DELIVERY, RECOVERY SYSTEMS; AND CARGO TIE DOWN EQUIPMENT",
      "value": "1670",
      "type": "psc_code"
   },
   {
      "label": "1670 - PARACHUTE RECOVER SYS & TIE DOWN EQ",
      "value": "1670",
      "type": "psc_code"
   },
   {
      "label": "1675 - SPACE VEHICLE COMPONENTS",
      "value": "1675",
      "type": "psc_code"
   },
   {
      "label": "1677 - SPACE VEHICLE REMOTE CONTROL SYSTEMS",
      "value": "1677",
      "type": "psc_code"
   },
   {
      "label": "1680 - MISCELLANEOUS AIRCRAFT ACCESSORIES AND COMPONENTS",
      "value": "1680",
      "type": "psc_code"
   },
   {
      "label": "1680 - MISCL AIRCRAFT ACCESSORIES COMPS",
      "value": "1680",
      "type": "psc_code"
   },
   {
      "label": "17 - AIRCRAFT LAUNCH/LAND/GROUND HANDLE",
      "value": "17",
      "type": "psc_code"
   },
   {
      "label": "17 - AEROSPACE CRAFT LAUNCHING, LANDING, GROUND HANDLING AND SERVICING EQUIPMENT",
      "value": "17",
      "type": "psc_code"
   },
   {
      "label": "1710 - AIRCRAFT LANDING EQUIPMENT",
      "value": "1710",
      "type": "psc_code"
   },
   {
      "label": "1720 - AIRCRAFT LAUNCHING EQUIPMENT",
      "value": "1720",
      "type": "psc_code"
   },
   {
      "label": "1725 - SPACE VEHICLE LAUNCHERS",
      "value": "1725",
      "type": "psc_code"
   },
   {
      "label": "1730 - AIRCRAFT GROUND SERVICING EQUIPMENT",
      "value": "1730",
      "type": "psc_code"
   },
   {
      "label": "1735 - SPACE VEHICLE HANDLING AND SERVICING EQUIPMENT",
      "value": "1735",
      "type": "psc_code"
   },
   {
      "label": "1740 - AIRFIELD SPECIAL TRUCKS & TRAILERS",
      "value": "1740",
      "type": "psc_code"
   },
   {
      "label": "1740 - AIRFIELD SPECIALIZED TRUCKS AND TRAILERS",
      "value": "1740",
      "type": "psc_code"
   },
   {
      "label": "18 - SPACE VEHICLES",
      "value": "18",
      "type": "psc_code"
   },
   {
      "label": "1810 - SPACE VEHICLES",
      "value": "1810",
      "type": "psc_code"
   },
   {
      "label": "1820 - SPACE VEHICLE COMPONENTS",
      "value": "1820",
      "type": "psc_code"
   },
   {
      "label": "1830 - SPACE VEHICLE REMOTE CONTROL SYSTEMS",
      "value": "1830",
      "type": "psc_code"
   },
   {
      "label": "1830 - SPACE VEHICLE REMOTE CONT SYSTEMS",
      "value": "1830",
      "type": "psc_code"
   },
   {
      "label": "1840 - SPACE VEHICLE LAUNCHERS",
      "value": "1840",
      "type": "psc_code"
   },
   {
      "label": "1850 - SPACE VEHICLE HANDLING & SERVICE EQ",
      "value": "1850",
      "type": "psc_code"
   },
   {
      "label": "1850 - SPACE VEHICLE HANDLING AND SERVICING EQUIPMENT",
      "value": "1850",
      "type": "psc_code"
   },
   {
      "label": "1860 - SPACE SURVIVAL EQUIPMENT",
      "value": "1860",
      "type": "psc_code"
   },
   {
      "label": "19 - SHIPS, SMALL CRAFT, PONTOON, DOCKS",
      "value": "19",
      "type": "psc_code"
   },
   {
      "label": "1900 - FRIGATES ANS CORVETTES",
      "value": "1900",
      "type": "psc_code"
   },
   {
      "label": "1901 - AIRCRAFT CARRIERS",
      "value": "1901",
      "type": "psc_code"
   },
   {
      "label": "1902 - CRUISERS",
      "value": "1902",
      "type": "psc_code"
   },
   {
      "label": "1903 - DESTROYERS",
      "value": "1903",
      "type": "psc_code"
   },
   {
      "label": "1904 - SUBMARINES",
      "value": "1904",
      "type": "psc_code"
   },
   {
      "label": "1905 - COMBAT SHIPS AND LANDING VESSELS",
      "value": "1905",
      "type": "psc_code"
   },
   {
      "label": "1906 - MINELAYERS ADN MINESWEEPERS",
      "value": "1906",
      "type": "psc_code"
   },
   {
      "label": "1907 - LANDING CRAFT",
      "value": "1907",
      "type": "psc_code"
   },
   {
      "label": "1908 - TORPEDO BOATS AND GUN BOATS",
      "value": "1908",
      "type": "psc_code"
   },
   {
      "label": "1909 - HYDROFOILS",
      "value": "1909",
      "type": "psc_code"
   },
   {
      "label": "1910 - TRANSPORT VESSELS-PASSENGER & TROOP",
      "value": "1910",
      "type": "psc_code"
   },
   {
      "label": "1910 - TRANSPORT VESSELS, PASSENGER AND TROOP",
      "value": "1910",
      "type": "psc_code"
   },
   {
      "label": "1911 - AMPHIBIOUS ASSAULT SHIPS",
      "value": "1911",
      "type": "psc_code"
   },
   {
      "label": "1915 - CARGO AND TANKER VESSELS",
      "value": "1915",
      "type": "psc_code"
   },
   {
      "label": "1920 - FISHING VESSELS",
      "value": "1920",
      "type": "psc_code"
   },
   {
      "label": "1921 - TUGS AND TOWBOATS",
      "value": "1921",
      "type": "psc_code"
   },
   {
      "label": "1922 - FIRE BOATS",
      "value": "1922",
      "type": "psc_code"
   },
   {
      "label": "1923 - ICE BREAKERS",
      "value": "1923",
      "type": "psc_code"
   },
   {
      "label": "1924 - REPAIR SHIPS",
      "value": "1924",
      "type": "psc_code"
   },
   {
      "label": "1925 - SPECIAL SERVICE VESSELS",
      "value": "1925",
      "type": "psc_code"
   },
   {
      "label": "1926 - LIGHTSHIPS",
      "value": "1926",
      "type": "psc_code"
   },
   {
      "label": "1927 - CABLE SHIPS",
      "value": "1927",
      "type": "psc_code"
   },
   {
      "label": "1928 - SALVAGE VESSELS",
      "value": "1928",
      "type": "psc_code"
   },
   {
      "label": "1929 - RESCUE VESSELS",
      "value": "1929",
      "type": "psc_code"
   },
   {
      "label": "1930 - BARGES AND LIGHTERS, CARGO",
      "value": "1930",
      "type": "psc_code"
   },
   {
      "label": "1935 - BARGES & LIGHTERS SPECIAL PURPOSE",
      "value": "1935",
      "type": "psc_code"
   },
   {
      "label": "1935 - BARGES AND LIGHTERS, SPECIAL PURPOSE",
      "value": "1935",
      "type": "psc_code"
   },
   {
      "label": "1940 - SMALL CRAFT",
      "value": "1940",
      "type": "psc_code"
   },
   {
      "label": "1945 - PONTOONS AND FLOATING DOCKS",
      "value": "1945",
      "type": "psc_code"
   },
   {
      "label": "1950 - FLOATING DRYDOCKS",
      "value": "1950",
      "type": "psc_code"
   },
   {
      "label": "1955 - DREDGES",
      "value": "1955",
      "type": "psc_code"
   },
   {
      "label": "1990 - MISCELLANEOUS VESSELS",
      "value": "1990",
      "type": "psc_code"
   },
   {
      "label": "20 - SHIP AND MARINE EQUIPMENT",
      "value": "20",
      "type": "psc_code"
   },
   {
      "label": "2010 - SHIP AND BOAT PROPULSION COMPONENTS",
      "value": "2010",
      "type": "psc_code"
   },
   {
      "label": "2020 - RIGGING AND RIGGING GEAR",
      "value": "2020",
      "type": "psc_code"
   },
   {
      "label": "2030 - DECK MACHINERY",
      "value": "2030",
      "type": "psc_code"
   },
   {
      "label": "2040 - MARINE HARDWARE AND HULL ITEMS",
      "value": "2040",
      "type": "psc_code"
   },
   {
      "label": "2050 - BUOYS",
      "value": "2050",
      "type": "psc_code"
   },
   {
      "label": "2060 - COMMERCIAL FISHING EQUIPMENT",
      "value": "2060",
      "type": "psc_code"
   },
   {
      "label": "2090 - MISC SHIP & MARINE EQ",
      "value": "2090",
      "type": "psc_code"
   },
   {
      "label": "2090 - MISCELLANEOUS SHIP AND MARINE EQUIPMENT",
      "value": "2090",
      "type": "psc_code"
   },
   {
      "label": "22 - RAILWAY EQUIPMENT",
      "value": "22",
      "type": "psc_code"
   },
   {
      "label": "2210 - LOCOMOTIVES",
      "value": "2210",
      "type": "psc_code"
   },
   {
      "label": "2220 - RAIL CARS",
      "value": "2220",
      "type": "psc_code"
   },
   {
      "label": "2230 - RAILROAD RIGHT OF WAY CONSTR EQ",
      "value": "2230",
      "type": "psc_code"
   },
   {
      "label": "2230 - RIGHT-OF-WAY CONSTRUCTION AND MAINTENANCE EQUIPMENT, RAILROAD",
      "value": "2230",
      "type": "psc_code"
   },
   {
      "label": "2240 - LOCOMOTIVE AND RAIL CAR ACCESSORIES AND COMPONENTS",
      "value": "2240",
      "type": "psc_code"
   },
   {
      "label": "2240 - LOCOMOTIVE & RAIL CAR ACCESSORIES",
      "value": "2240",
      "type": "psc_code"
   },
   {
      "label": "2250 - TRACK MATERIAL, RAILROAD",
      "value": "2250",
      "type": "psc_code"
   },
   {
      "label": "23 - MOTOR VEHICLES, CYCLES, TRAILERS",
      "value": "23",
      "type": "psc_code"
   },
   {
      "label": "2305 - GROUND EFFECT VEHICLES",
      "value": "2305",
      "type": "psc_code"
   },
   {
      "label": "2310 - PASSENGER MOTOR VEHICLES",
      "value": "2310",
      "type": "psc_code"
   },
   {
      "label": "2320 - TRUCKS AND TRUCK TRACTORS, WHEELED",
      "value": "2320",
      "type": "psc_code"
   },
   {
      "label": "2330 - TRAILERS",
      "value": "2330",
      "type": "psc_code"
   },
   {
      "label": "2340 - MOTORCYCLES, MOTOR SCOOTERS, AND BICYCLES",
      "value": "2340",
      "type": "psc_code"
   },
   {
      "label": "2340 - MOTORCYCLE,MOTOR SCOOTERS & BIKES",
      "value": "2340",
      "type": "psc_code"
   },
   {
      "label": "2350 - COMBAT, ASSAULT, AND TACTICAL VEHICLES, TRACKED",
      "value": "2350",
      "type": "psc_code"
   },
   {
      "label": "2350 - COMBAT ASSAULT & TACTICAL VEH",
      "value": "2350",
      "type": "psc_code"
   },
   {
      "label": "2355 - COMBAT ASSAULT AND TACTICAL VEHICLES WHEELED",
      "value": "2355",
      "type": "psc_code"
   },
   {
      "label": "2355 - COMBAT, ASSAULT, AND TACTICAL VEHICLES, WHEELED",
      "value": "2355",
      "type": "psc_code"
   },
   {
      "label": "24 - TRACTORS",
      "value": "24",
      "type": "psc_code"
   },
   {
      "label": "2410 - TRACTOR, FULL TRACKED, LOW SPEED",
      "value": "2410",
      "type": "psc_code"
   },
   {
      "label": "2410 - TRACTORS, FULL TRACKED, LOW SPEED",
      "value": "2410",
      "type": "psc_code"
   },
   {
      "label": "2420 - TRACTORS, WHEELED",
      "value": "2420",
      "type": "psc_code"
   },
   {
      "label": "2430 - TRACTORS, FULL TRACKED, HIGH SPEED",
      "value": "2430",
      "type": "psc_code"
   },
   {
      "label": "25 - VEHICULAR EQUIPMENT COMPONENTS",
      "value": "25",
      "type": "psc_code"
   },
   {
      "label": "2510 - VEHICULAR CAB, BODY, AND FRAME STRUCTURAL COMPONENTS",
      "value": "2510",
      "type": "psc_code"
   },
   {
      "label": "2510 - VEH CAB BODY FRAME STRUCTURAL COMP",
      "value": "2510",
      "type": "psc_code"
   },
   {
      "label": "2520 - VEHICULAR POWER TRANSMISSION COMPONENTS",
      "value": "2520",
      "type": "psc_code"
   },
   {
      "label": "2520 - VEH POWER TRANSMISSION COMPONENTS",
      "value": "2520",
      "type": "psc_code"
   },
   {
      "label": "2530 - VEH BRAKE STEERING AXLE WHEEL COMP",
      "value": "2530",
      "type": "psc_code"
   },
   {
      "label": "2530 - VEHICULAR BRAKE, STEERING, AXLE, WHEEL, AND TRACK COMPONENTS",
      "value": "2530",
      "type": "psc_code"
   },
   {
      "label": "2540 - VEHICULAR FURNITURE AND ACCESSORIES",
      "value": "2540",
      "type": "psc_code"
   },
   {
      "label": "2541 - WEAPONS SYSTEMS SPECIFIC VEHICULAR ACCESSORIES",
      "value": "2541",
      "type": "psc_code"
   },
   {
      "label": "2541 - VEH ACCESSORY/WEAPONS SYS SPECIFIC",
      "value": "2541",
      "type": "psc_code"
   },
   {
      "label": "2590 - MISCELLANEOUS VEHICULAR COMPONENTS",
      "value": "2590",
      "type": "psc_code"
   },
   {
      "label": "26 - TIRES AND TUBES",
      "value": "26",
      "type": "psc_code"
   },
   {
      "label": "2610 - TIRES & TUBES PNEUMATIC EXCEPT AIR",
      "value": "2610",
      "type": "psc_code"
   },
   {
      "label": "2610 - TIRES AND TUBES, PNEUMATIC, EXCEPT AIRCRAFT",
      "value": "2610",
      "type": "psc_code"
   },
   {
      "label": "2620 - TIRES AND TUBES, PNEUMATIC, AIRCRAFT",
      "value": "2620",
      "type": "psc_code"
   },
   {
      "label": "2620 - TIRES & TUBES PNEUMATIC AIRCRAFT",
      "value": "2620",
      "type": "psc_code"
   },
   {
      "label": "2630 - TIRES, SOLID AND CUSHION",
      "value": "2630",
      "type": "psc_code"
   },
   {
      "label": "2640 - TIRE REBUILDING AND TIRE AND TUBE REPAIR MATERIALS",
      "value": "2640",
      "type": "psc_code"
   },
   {
      "label": "2640 - TIRES & TUBES REBUILDING-REP MTL",
      "value": "2640",
      "type": "psc_code"
   },
   {
      "label": "28 - ENGINES AND TURBINES AND COMPONENT",
      "value": "28",
      "type": "psc_code"
   },
   {
      "label": "2805 - GASOLINE RECIPROCATING ENGINES, EXCEPT AIRCRAFT; AND COMPONENTS",
      "value": "2805",
      "type": "psc_code"
   },
   {
      "label": "2805 - GAS RECIPROCATING ENG - EXCEPT AIR",
      "value": "2805",
      "type": "psc_code"
   },
   {
      "label": "2810 - GASOLINE RECIPROCATING ENGINES, AIRCRAFT PRIME MOVER; AND COMPONENTS",
      "value": "2810",
      "type": "psc_code"
   },
   {
      "label": "2810 - GAS RECIPROCATING ENG - AIRCRAFT",
      "value": "2810",
      "type": "psc_code"
   },
   {
      "label": "2815 - DIESEL ENGINES AND COMPONENTS",
      "value": "2815",
      "type": "psc_code"
   },
   {
      "label": "2820 - STEAM ENGINES, RECIPROCATING; AND COMPONENTS",
      "value": "2820",
      "type": "psc_code"
   },
   {
      "label": "2820 - STEAM ENGINES RECIPROCATING & COMPS",
      "value": "2820",
      "type": "psc_code"
   },
   {
      "label": "2825 - STEAM TURBINES AND COMPONENTS",
      "value": "2825",
      "type": "psc_code"
   },
   {
      "label": "2830 - WATER TURBINES AND WATER WHEELS; AND COMPONENTS",
      "value": "2830",
      "type": "psc_code"
   },
   {
      "label": "2830 - WATER TURBINES,WATER WHEELS & COMPS",
      "value": "2830",
      "type": "psc_code"
   },
   {
      "label": "2835 - GAS TURBINES AND JET ENGINES; NON-AIRCRAFT PRIME MOVER, AIRCRAFT NON-PRIME MOVER, AND COMPONENTS",
      "value": "2835",
      "type": "psc_code"
   },
   {
      "label": "2835 - GAS TURBINES & JET ENG EXCEPT AIR",
      "value": "2835",
      "type": "psc_code"
   },
   {
      "label": "2840 - GAS TURBINES AND JET ENGINES, AIRCRAFT, PRIME MOVING; AND COMPONENTS",
      "value": "2840",
      "type": "psc_code"
   },
   {
      "label": "2840 - GAS TURBINES & JET ENGINES AIRCRAFT",
      "value": "2840",
      "type": "psc_code"
   },
   {
      "label": "2845 - ROCKET ENGINES AND COMPONENTS",
      "value": "2845",
      "type": "psc_code"
   },
   {
      "label": "2850 - GASOLINE ROTARY ENGINES AND COMPONENTS",
      "value": "2850",
      "type": "psc_code"
   },
   {
      "label": "2850 - GASOLINE ROTORY ENGINES & COMPS",
      "value": "2850",
      "type": "psc_code"
   },
   {
      "label": "2895 - MISCELLANEOUS ENGINES AND COMPONENTS",
      "value": "2895",
      "type": "psc_code"
   },
   {
      "label": "2895 - MISC ENGINES & COMPONENTS",
      "value": "2895",
      "type": "psc_code"
   },
   {
      "label": "29 - ENGINE ACCESSORIES",
      "value": "29",
      "type": "psc_code"
   },
   {
      "label": "2910 - ENGINE FUEL SYSTEM COMPONENTS, NONAIRCRAFT",
      "value": "2910",
      "type": "psc_code"
   },
   {
      "label": "2910 - ENGINE FUEL SYS COMPONENTS NONAIR",
      "value": "2910",
      "type": "psc_code"
   },
   {
      "label": "2915 - ENGINE FUEL SYSTEM COMPONENTS, AIRCRAFT AND MISSILE PRIME MOVERS",
      "value": "2915",
      "type": "psc_code"
   },
   {
      "label": "2915 - ENGINE FUEL SYSTEM COMPONENTS AIR",
      "value": "2915",
      "type": "psc_code"
   },
   {
      "label": "2920 - ENGINE ELECTRICAL SYSTEM COMPONENTS, NONAIRCRAFT",
      "value": "2920",
      "type": "psc_code"
   },
   {
      "label": "2920 - ENGINE ELECT SYS COMPS NONAIRCRAFT",
      "value": "2920",
      "type": "psc_code"
   },
   {
      "label": "2925 - ENGINE ELECTRICAL SYSTEM COMPONENTS, AIRCRAFT PRIME MOVING",
      "value": "2925",
      "type": "psc_code"
   },
   {
      "label": "2925 - ENGINE ELECT SYS COMPS - AIRCRAFT",
      "value": "2925",
      "type": "psc_code"
   },
   {
      "label": "2930 - ENGINE COOLING SYSTEM COMPONENTS, NONAIRCRAFT",
      "value": "2930",
      "type": "psc_code"
   },
   {
      "label": "2930 - ENGINE COOLING SYS COMPS - NONAIR",
      "value": "2930",
      "type": "psc_code"
   },
   {
      "label": "2935 - ENGINE SYSTEM COOLING COMPONENTS, AIRCRAFT PRIME MOVING",
      "value": "2935",
      "type": "psc_code"
   },
   {
      "label": "2935 - ENGINE COOLING SYS COMPONENTS - AIR",
      "value": "2935",
      "type": "psc_code"
   },
   {
      "label": "2940 - ENGINE AIR AND OIL FILTERS, STRAINERS, AND CLEANERS, NONAIRCRAFT",
      "value": "2940",
      "type": "psc_code"
   },
   {
      "label": "2940 - ENGINE AIR & OIL FILTERS - NONAIR",
      "value": "2940",
      "type": "psc_code"
   },
   {
      "label": "2945 - ENGINE AIR AND OIL FILTERS, CLEANERS, AIRCRAFT PRIME MOVING",
      "value": "2945",
      "type": "psc_code"
   },
   {
      "label": "2945 - ENGINE AIR & OIL FILTERS - AIR",
      "value": "2945",
      "type": "psc_code"
   },
   {
      "label": "2950 - TURBOSUPERCHARGER AND COMPONENTS",
      "value": "2950",
      "type": "psc_code"
   },
   {
      "label": "2950 - TURBOSUPERCHARGERS AND COMPONENTS",
      "value": "2950",
      "type": "psc_code"
   },
   {
      "label": "2990 - MISCELLANEOUS ENGINE ACCESSORIES, NONAIRCRAFT",
      "value": "2990",
      "type": "psc_code"
   },
   {
      "label": "2990 - MISC ENGINE ACCESSORIES - NONAIR",
      "value": "2990",
      "type": "psc_code"
   },
   {
      "label": "2995 - MISCELLANEOUS ENGINE ACCESSORIES, AIRCRAFT",
      "value": "2995",
      "type": "psc_code"
   },
   {
      "label": "2995 - MISC ENGINE ACCESSORIES - AIRCRAFT",
      "value": "2995",
      "type": "psc_code"
   },
   {
      "label": "30 - MECHANICAL POWER TRANSMISSION EQPT",
      "value": "30",
      "type": "psc_code"
   },
   {
      "label": "3010 - TORQUE CONVERTERS & SPEED CHANGERS",
      "value": "3010",
      "type": "psc_code"
   },
   {
      "label": "3010 - TORQUE CONVERTERS AND SPEED CHANGERS",
      "value": "3010",
      "type": "psc_code"
   },
   {
      "label": "3020 - GEARS, PULLEYS, SPROCKETS, AND TRANSMISSION CHAIN",
      "value": "3020",
      "type": "psc_code"
   },
   {
      "label": "3020 - GEAR PULLEY SPROCKET & TRAN CHAIN",
      "value": "3020",
      "type": "psc_code"
   },
   {
      "label": "3030 - BELTING, DRIVE BELTS, FAN BELTS, AND ACCESSORIES",
      "value": "3030",
      "type": "psc_code"
   },
   {
      "label": "3030 - BELTING DRIVE BELTS FAN BELTS & ACC",
      "value": "3030",
      "type": "psc_code"
   },
   {
      "label": "3040 - MISCELLANEOUS POWER TRANSMISSION EQUIPMENT",
      "value": "3040",
      "type": "psc_code"
   },
   {
      "label": "3040 - MISC POWER TRANSMISSION EQ",
      "value": "3040",
      "type": "psc_code"
   },
   {
      "label": "31 - BEARINGS",
      "value": "31",
      "type": "psc_code"
   },
   {
      "label": "3110 - BEARINGS, ANTIFRICTION, UNMOUNTED",
      "value": "3110",
      "type": "psc_code"
   },
   {
      "label": "3120 - BEARINGS, PLAIN, UNMOUNTED",
      "value": "3120",
      "type": "psc_code"
   },
   {
      "label": "3130 - BEARINGS, MOUNTED",
      "value": "3130",
      "type": "psc_code"
   },
   {
      "label": "32 - WOODWORKING MACHINERY AND EQPT",
      "value": "32",
      "type": "psc_code"
   },
   {
      "label": "3210 - SAWMILL AND PLANING MILL MACHINERY",
      "value": "3210",
      "type": "psc_code"
   },
   {
      "label": "3220 - WOODWORKING MACHINES",
      "value": "3220",
      "type": "psc_code"
   },
   {
      "label": "3230 - TOOLS - ATTACHS FOR WOODWORK MACH",
      "value": "3230",
      "type": "psc_code"
   },
   {
      "label": "3230 - TOOLS AND ATTACHMENTS FOR WOODWORKING MACHINERY",
      "value": "3230",
      "type": "psc_code"
   },
   {
      "label": "34 - METALWORKING MACHINERY",
      "value": "34",
      "type": "psc_code"
   },
   {
      "label": "3405 - SAWS AND FILING MACHINES",
      "value": "3405",
      "type": "psc_code"
   },
   {
      "label": "3408 - MACHINING CENTERS AND WAY-TYPE MACHINES",
      "value": "3408",
      "type": "psc_code"
   },
   {
      "label": "3408 - MACHING CENTERS & WAY-TYPE MACHINES",
      "value": "3408",
      "type": "psc_code"
   },
   {
      "label": "3410 - ELECT & ULTRASONIC EROSION MACHINES",
      "value": "3410",
      "type": "psc_code"
   },
   {
      "label": "3410 - ELECTRICAL AND ULTRASONIC EROSION MACHINES",
      "value": "3410",
      "type": "psc_code"
   },
   {
      "label": "3411 - BORING MACHINES.",
      "value": "3411",
      "type": "psc_code"
   },
   {
      "label": "3411 - BORING MACHINES",
      "value": "3411",
      "type": "psc_code"
   },
   {
      "label": "3412 - BROACHING MACHINES",
      "value": "3412",
      "type": "psc_code"
   },
   {
      "label": "3413 - DRILLING AND TAPPING MACHINES",
      "value": "3413",
      "type": "psc_code"
   },
   {
      "label": "3414 - GEAR CUTTING AND FINISHING MACHINES",
      "value": "3414",
      "type": "psc_code"
   },
   {
      "label": "3415 - GRINDING MACHINES",
      "value": "3415",
      "type": "psc_code"
   },
   {
      "label": "3416 - LATHES",
      "value": "3416",
      "type": "psc_code"
   },
   {
      "label": "3417 - MILLING MACHINES",
      "value": "3417",
      "type": "psc_code"
   },
   {
      "label": "3418 - PLANERS AND SHAPERS",
      "value": "3418",
      "type": "psc_code"
   },
   {
      "label": "3419 - MISCELLANEOUS MACHINE TOOLS",
      "value": "3419",
      "type": "psc_code"
   },
   {
      "label": "3422 - ROLLING MILLS AND DRAWING MACHINES",
      "value": "3422",
      "type": "psc_code"
   },
   {
      "label": "3424 - METAL HEAT TREATING AND NON-THERMAL TREATING EQUIPMENT",
      "value": "3424",
      "type": "psc_code"
   },
   {
      "label": "3424 - METAL HEAT TREATING EQ",
      "value": "3424",
      "type": "psc_code"
   },
   {
      "label": "3426 - METAL FINISHING EQUIPMENT",
      "value": "3426",
      "type": "psc_code"
   },
   {
      "label": "3431 - ELECTRIC ARC WELDING EQUIPMENT",
      "value": "3431",
      "type": "psc_code"
   },
   {
      "label": "3432 - ELECTRIC RESISTANCE WELDING EQUIPMENT",
      "value": "3432",
      "type": "psc_code"
   },
   {
      "label": "3432 - ELECTRIC RESISTANCE WELDING EQ",
      "value": "3432",
      "type": "psc_code"
   },
   {
      "label": "3433 - GAS WELDING, HEAT CUTTING, AND METALIZING EQUIPMENT",
      "value": "3433",
      "type": "psc_code"
   },
   {
      "label": "3433 - GAS WELD HEAT CUT - METALIZING EQ",
      "value": "3433",
      "type": "psc_code"
   },
   {
      "label": "3436 - WELDING POSITIONERS & MANIPULATORS",
      "value": "3436",
      "type": "psc_code"
   },
   {
      "label": "3436 - WELDING POSITIONERS AND MANIPULATORS",
      "value": "3436",
      "type": "psc_code"
   },
   {
      "label": "3438 - MISCELLANEOUS WELDING EQUIPMENT",
      "value": "3438",
      "type": "psc_code"
   },
   {
      "label": "3439 - MISC WELD SOLDER & BRAZING SUPPLY",
      "value": "3439",
      "type": "psc_code"
   },
   {
      "label": "3439 - MISCELLANEOUS WELDING, SOLDERING, AND BRAZING SUPPLIES AND ACCESSORIES",
      "value": "3439",
      "type": "psc_code"
   },
   {
      "label": "3441 - BENDING AND FORMING MACHINES",
      "value": "3441",
      "type": "psc_code"
   },
   {
      "label": "3442 - HYDRAULIC AND PNEUMATIC PRESSES, POWER DRIVEN",
      "value": "3442",
      "type": "psc_code"
   },
   {
      "label": "3442 - HYDRAULIC/PNEUMATIC POWER PRESS DR",
      "value": "3442",
      "type": "psc_code"
   },
   {
      "label": "3443 - MECHANICAL PRESSES, POWER DRIVEN",
      "value": "3443",
      "type": "psc_code"
   },
   {
      "label": "3444 - MANUAL PRESSES",
      "value": "3444",
      "type": "psc_code"
   },
   {
      "label": "3445 - PUNCHING AND SHEARING MACHINES",
      "value": "3445",
      "type": "psc_code"
   },
   {
      "label": "3446 - FORGING MACHINERY AND HAMMERS",
      "value": "3446",
      "type": "psc_code"
   },
   {
      "label": "3447 - WIRE AND METAL RIBBON FORMING MACHINES",
      "value": "3447",
      "type": "psc_code"
   },
   {
      "label": "3447 - WIRE & METAL RIBBON FORMING MACHS",
      "value": "3447",
      "type": "psc_code"
   },
   {
      "label": "3448 - RIVETING MACHINES",
      "value": "3448",
      "type": "psc_code"
   },
   {
      "label": "3449 - MISCELLANEOUS SECONDARY METAL FORMING AND CUTTING MACHINES",
      "value": "3449",
      "type": "psc_code"
   },
   {
      "label": "3449 - MSC SECONDARY METAL FORM-CUT MACH",
      "value": "3449",
      "type": "psc_code"
   },
   {
      "label": "3450 - MACHINE TOOLS, PORTABLE",
      "value": "3450",
      "type": "psc_code"
   },
   {
      "label": "3455 - CUTTING TOOLS FOR MACHINE TOOLS",
      "value": "3455",
      "type": "psc_code"
   },
   {
      "label": "3456 - CUTTING AND FORMING TOOLS FOR SECONDARY METALWORKING MACHINERY",
      "value": "3456",
      "type": "psc_code"
   },
   {
      "label": "3456 - SECONDARY METAL MACH-CUTTING TOOLS",
      "value": "3456",
      "type": "psc_code"
   },
   {
      "label": "3460 - MACHINE TOOL ACCESSORIES",
      "value": "3460",
      "type": "psc_code"
   },
   {
      "label": "3461 - ACCESSORIES FOR SECONDARY METALWORKING MACHINERY",
      "value": "3461",
      "type": "psc_code"
   },
   {
      "label": "3461 - SECONDARY METALWORKING MACH-ACCESS",
      "value": "3461",
      "type": "psc_code"
   },
   {
      "label": "3465 - PRODUCTION JIGS, FIXTURES, AND TEMPLATES",
      "value": "3465",
      "type": "psc_code"
   },
   {
      "label": "3465 - PRODUCTION JIGS FIXTURES & TEMPLATE",
      "value": "3465",
      "type": "psc_code"
   },
   {
      "label": "3470 - MACHINE SHOP SETS, KITS, AND OUTFITS",
      "value": "3470",
      "type": "psc_code"
   },
   {
      "label": "3470 - MACHINE SHOP SETS KITS & OUTFITS",
      "value": "3470",
      "type": "psc_code"
   },
   {
      "label": "35 - SERVICE AND TRADE EQPT",
      "value": "35",
      "type": "psc_code"
   },
   {
      "label": "3510 - LAUNDRY AND DRY CLEANING EQUIPMENT",
      "value": "3510",
      "type": "psc_code"
   },
   {
      "label": "3520 - SHOE REPAIRING EQUIPMENT",
      "value": "3520",
      "type": "psc_code"
   },
   {
      "label": "3530 - INDUSTRIAL SEWING MACHINES AND MOBILE TEXTILE REPAIR SHOPS",
      "value": "3530",
      "type": "psc_code"
   },
   {
      "label": "3530 - IND SEW MACH & MOBILE TEXTILE SHOPS",
      "value": "3530",
      "type": "psc_code"
   },
   {
      "label": "3540 - WRAPPING AND PACKAGING MACHINERY",
      "value": "3540",
      "type": "psc_code"
   },
   {
      "label": "3550 - VENDING AND COIN OPERATED MACHINES",
      "value": "3550",
      "type": "psc_code"
   },
   {
      "label": "3590 - MISC SERVICE & TRADE EQ",
      "value": "3590",
      "type": "psc_code"
   },
   {
      "label": "3590 - MISCELLANEOUS SERVICE AND TRADE EQUIPMENT",
      "value": "3590",
      "type": "psc_code"
   },
   {
      "label": "36 - SPECIAL INDUSTRY MACHINERY",
      "value": "36",
      "type": "psc_code"
   },
   {
      "label": "3605 - FOOD PRODUCTS MACHINE & EQ",
      "value": "3605",
      "type": "psc_code"
   },
   {
      "label": "3605 - FOOD PRODUCTS MACHINERY AND EQUIPMENT",
      "value": "3605",
      "type": "psc_code"
   },
   {
      "label": "3610 - PRINTING, DUPLICATING, AND BOOKBINDING EQUIPMENT",
      "value": "3610",
      "type": "psc_code"
   },
   {
      "label": "3610 - PRINTING,DUPLICATING & BOOKBIND EQ",
      "value": "3610",
      "type": "psc_code"
   },
   {
      "label": "3611 - INDUSTRIAL MARKING MACHINES",
      "value": "3611",
      "type": "psc_code"
   },
   {
      "label": "3615 - PULP AND PAPER INDUSTRIES MACHINERY",
      "value": "3615",
      "type": "psc_code"
   },
   {
      "label": "3620 - RUBBER AND PLASTICS WORKING MACHINERY",
      "value": "3620",
      "type": "psc_code"
   },
   {
      "label": "3620 - RUBBER & PLASTIC WORKING MACHINE",
      "value": "3620",
      "type": "psc_code"
   },
   {
      "label": "3625 - TEXTILE INDUSTRIES MACHINERY",
      "value": "3625",
      "type": "psc_code"
   },
   {
      "label": "3630 - CLAY AND CONCRETE PRODUCTS INDUSTRIES MACHINERY",
      "value": "3630",
      "type": "psc_code"
   },
   {
      "label": "3630 - CLAY-CONCRETE PRODUCTS MACH",
      "value": "3630",
      "type": "psc_code"
   },
   {
      "label": "3635 - CRYSTAL & GLASS INDUSTRIES MACHINE",
      "value": "3635",
      "type": "psc_code"
   },
   {
      "label": "3635 - CRYSTAL AND GLASS INDUSTRIES MACHINERY",
      "value": "3635",
      "type": "psc_code"
   },
   {
      "label": "3640 - TOBACCO MANUFACTURING MACHINERY",
      "value": "3640",
      "type": "psc_code"
   },
   {
      "label": "3645 - LEATHER TANNING AND LEATHER WORKING INDUSTRIES MACHINERY",
      "value": "3645",
      "type": "psc_code"
   },
   {
      "label": "3645 - LEATHER TANNING & WORKING MACHINE",
      "value": "3645",
      "type": "psc_code"
   },
   {
      "label": "3650 - CHEMICAL AND PHARMACEUTICAL PRODUCTS MANUFACTURING MACHINERY",
      "value": "3650",
      "type": "psc_code"
   },
   {
      "label": "3650 - CHEMICAL & PHARMACEUTICAL MACHINE",
      "value": "3650",
      "type": "psc_code"
   },
   {
      "label": "3655 - GAS GENERATING AND DISPENSING SYSTEMS, FIXED OR MOBILE",
      "value": "3655",
      "type": "psc_code"
   },
   {
      "label": "3655 - GAS GENERATING & DISPENSING SYSTEM",
      "value": "3655",
      "type": "psc_code"
   },
   {
      "label": "3660 - INDUSTRIAL SIZE REDUCTION MACHINERY",
      "value": "3660",
      "type": "psc_code"
   },
   {
      "label": "3670 - SPECIALIZED SEMICONDUCTOR, MICROCIRCUIT, AND PRINTED CIRCUIT BOARD MANUFACTURING MACHINERY",
      "value": "3670",
      "type": "psc_code"
   },
   {
      "label": "3670 - PRINTED CIRCUIT BOARD MFG MACH",
      "value": "3670",
      "type": "psc_code"
   },
   {
      "label": "3680 - FOUNDRY MACHINERY, RELATED EQUIPMENT AND SUPPLIES",
      "value": "3680",
      "type": "psc_code"
   },
   {
      "label": "3680 - FOUNDRY MACHINE - RELATED EQ & SUP",
      "value": "3680",
      "type": "psc_code"
   },
   {
      "label": "3685 - SPECIAL METAL CONTAINER MFG MACH",
      "value": "3685",
      "type": "psc_code"
   },
   {
      "label": "3685 - SPECIALIZED METAL CONTAINER MANUFACTURING MACHINERY AND RELATED EQUIPMENT",
      "value": "3685",
      "type": "psc_code"
   },
   {
      "label": "3690 - SPECIALIZED AMMUNITION AND ORDNANCE MACHINERY AND RELATED EQUIPMENT",
      "value": "3690",
      "type": "psc_code"
   },
   {
      "label": "3690 - SPECIAL AMMUNITION & ORDNANCE MACH",
      "value": "3690",
      "type": "psc_code"
   },
   {
      "label": "3693 - INDUSTRIAL ASSEMBLY MACHINES",
      "value": "3693",
      "type": "psc_code"
   },
   {
      "label": "3694 - CLEAN WORK STATIONS, CONTROLLED ENVIRONMENT, AND RELATED EQUIPMENT",
      "value": "3694",
      "type": "psc_code"
   },
   {
      "label": "3694 - CLEAN WORK STATIONS ENVIRONMENT EQ",
      "value": "3694",
      "type": "psc_code"
   },
   {
      "label": "3695 - MISC SPECIAL INDUSTRY MACHINE",
      "value": "3695",
      "type": "psc_code"
   },
   {
      "label": "3695 - MISCELLANEOUS SPECIAL INDUSTRY MACHINERY",
      "value": "3695",
      "type": "psc_code"
   },
   {
      "label": "37 - AGRICULTURAL MACHINERY AND EQPT",
      "value": "37",
      "type": "psc_code"
   },
   {
      "label": "3710 - SOIL PREPARATION EQUIPMENT",
      "value": "3710",
      "type": "psc_code"
   },
   {
      "label": "3720 - HARVESTING EQUIPMENT",
      "value": "3720",
      "type": "psc_code"
   },
   {
      "label": "3730 - DAIRY, POULTRY, AND LIVESTOCK EQUIPMENT",
      "value": "3730",
      "type": "psc_code"
   },
   {
      "label": "3730 - DAIRY POULTRY & LIVESTOCK EQ",
      "value": "3730",
      "type": "psc_code"
   },
   {
      "label": "3740 - PEST, DISEASE, AND FROST CONTROL EQUIPMENT",
      "value": "3740",
      "type": "psc_code"
   },
   {
      "label": "3740 - PEST DISEASE & FROST CONT EQ",
      "value": "3740",
      "type": "psc_code"
   },
   {
      "label": "3750 - GARDENING IMPLEMENTS AND TOOLS",
      "value": "3750",
      "type": "psc_code"
   },
   {
      "label": "3760 - ANIMAL DRAWN VEHICLE & FARM TRAILER",
      "value": "3760",
      "type": "psc_code"
   },
   {
      "label": "3770 - SADDLERY, HARNESS, WHIPS & FURNISHING",
      "value": "3770",
      "type": "psc_code"
   },
   {
      "label": "3770 - SADDLERY, HARNESS, WHIPS, AND RELATED ANIMAL FURNISHINGS",
      "value": "3770",
      "type": "psc_code"
   },
   {
      "label": "38 - CONSTRUCT/MINE/EXCAVATE/HIGHWY EQPT",
      "value": "38",
      "type": "psc_code"
   },
   {
      "label": "3805 - EARTH MOVING AND EXCAVATING EQUIPMENT",
      "value": "3805",
      "type": "psc_code"
   },
   {
      "label": "3805 - EARTH MOVING & EXCAVATING EQ",
      "value": "3805",
      "type": "psc_code"
   },
   {
      "label": "3810 - CRANES AND CRANE-SHOVELS",
      "value": "3810",
      "type": "psc_code"
   },
   {
      "label": "3815 - CRANE AND CRANE-SHOVEL ATTACHMENTS",
      "value": "3815",
      "type": "psc_code"
   },
   {
      "label": "3820 - MINING, ROCK DRILLING, EARTH BORING, AND RELATED EQUIPMENT",
      "value": "3820",
      "type": "psc_code"
   },
   {
      "label": "3820 - MINING,ROCK DRILLING,EARTH BORE EQ",
      "value": "3820",
      "type": "psc_code"
   },
   {
      "label": "3825 - ROAD CLEARING, CLEANING, AND MARKING EQUIPMENT",
      "value": "3825",
      "type": "psc_code"
   },
   {
      "label": "3825 - ROAD CLEARING & CLEANING EQ",
      "value": "3825",
      "type": "psc_code"
   },
   {
      "label": "3830 - TRUCK AND TRACTOR ATTACHMENTS",
      "value": "3830",
      "type": "psc_code"
   },
   {
      "label": "3835 - PETROLEUM PRODUCTION AND DISTRIBUTION EQUIPMENT",
      "value": "3835",
      "type": "psc_code"
   },
   {
      "label": "3835 - PETROLEUM PRODUCTION-DISTRIB EQ",
      "value": "3835",
      "type": "psc_code"
   },
   {
      "label": "3895 - MISCELLANEOUS CONSTRUCTION EQUIPMENT",
      "value": "3895",
      "type": "psc_code"
   },
   {
      "label": "3895 - MISC CONTRUCT EQ",
      "value": "3895",
      "type": "psc_code"
   },
   {
      "label": "39 - MATERIALS HANDLING EQPT",
      "value": "39",
      "type": "psc_code"
   },
   {
      "label": "3910 - CONVEYORS",
      "value": "3910",
      "type": "psc_code"
   },
   {
      "label": "3915 - MATERIALS FEEDERS",
      "value": "3915",
      "type": "psc_code"
   },
   {
      "label": "3920 - MAT HANDLING EQ - NONSELF-PROPELLED",
      "value": "3920",
      "type": "psc_code"
   },
   {
      "label": "3920 - MATERIAL HANDLING EQUIPMENT, NONSELF-PROPELLED",
      "value": "3920",
      "type": "psc_code"
   },
   {
      "label": "3930 - WAREHOUSE TRKS & TRACTORS SELF-PROP",
      "value": "3930",
      "type": "psc_code"
   },
   {
      "label": "3930 - WAREHOUSE TRUCKS AND TRACTORS, SELF-PROPELLED",
      "value": "3930",
      "type": "psc_code"
   },
   {
      "label": "3940 - BLOCKS, TACKLE, RIGGING, AND SLINGS",
      "value": "3940",
      "type": "psc_code"
   },
   {
      "label": "3950 - WINCHES, HOISTS, CRANES, AND DERRICKS",
      "value": "3950",
      "type": "psc_code"
   },
   {
      "label": "3950 - WINCHES HOISTS CRANES & DERRICKS",
      "value": "3950",
      "type": "psc_code"
   },
   {
      "label": "3960 - FREIGHT ELEVATORS",
      "value": "3960",
      "type": "psc_code"
   },
   {
      "label": "3990 - MISC MATERIALS HANDLING EQ",
      "value": "3990",
      "type": "psc_code"
   },
   {
      "label": "3990 - MISCELLANEOUS MATERIALS HANDLING EQUIPMENT",
      "value": "3990",
      "type": "psc_code"
   },
   {
      "label": "40 - ROPE, CABLE, CHAIN, FITTINGS",
      "value": "40",
      "type": "psc_code"
   },
   {
      "label": "4010 - CHAIN AND WIRE ROPE",
      "value": "4010",
      "type": "psc_code"
   },
   {
      "label": "4020 - FIBER ROPE, CORDAGE, AND TWINE",
      "value": "4020",
      "type": "psc_code"
   },
   {
      "label": "4030 - FITTINGS FOR ROPE, CABLE, AND CHAIN",
      "value": "4030",
      "type": "psc_code"
   },
   {
      "label": "41 - REFRIG, AIR CONDIT/CIRCULAT EQPT",
      "value": "41",
      "type": "psc_code"
   },
   {
      "label": "4110 - REFRIGERATION EQUIPMENT",
      "value": "4110",
      "type": "psc_code"
   },
   {
      "label": "4120 - AIR CONDITIONING EQUIPMENT",
      "value": "4120",
      "type": "psc_code"
   },
   {
      "label": "4130 - REFRIGERATION AND AIR CONDITIONING COMPONENTS",
      "value": "4130",
      "type": "psc_code"
   },
   {
      "label": "4130 - REFRIGERATION & AIR CONDITION COMP",
      "value": "4130",
      "type": "psc_code"
   },
   {
      "label": "4140 - FANS, AIR CIRCULATORS, AND BLOWER EQUIPMENT",
      "value": "4140",
      "type": "psc_code"
   },
   {
      "label": "4140 - FANS AIR CIRCULATORS & BLOWER EQ",
      "value": "4140",
      "type": "psc_code"
   },
   {
      "label": "4150 - VORTEX TUBES AND REL. COOLING TUBES",
      "value": "4150",
      "type": "psc_code"
   },
   {
      "label": "4150 - VORTEX TUBES AND OTHER RELATED COOLING TUBES",
      "value": "4150",
      "type": "psc_code"
   },
   {
      "label": "42 - FIRE/RESCUE/SAFETY; ENVIRO PROTECT",
      "value": "42",
      "type": "psc_code"
   },
   {
      "label": "4210 - FIRE FIGHTING EQUIPMENT",
      "value": "4210",
      "type": "psc_code"
   },
   {
      "label": "4220 - MARINE LIFESAVING & DIVING EQ",
      "value": "4220",
      "type": "psc_code"
   },
   {
      "label": "4220 - MARINE LIFESAVING AND DIVING EQUIPMENT",
      "value": "4220",
      "type": "psc_code"
   },
   {
      "label": "4230 - DECONTAMINATING & IMPREGNATING EQ",
      "value": "4230",
      "type": "psc_code"
   },
   {
      "label": "4230 - DECONTAMINATING AND IMPREGNATING EQUIPMENT",
      "value": "4230",
      "type": "psc_code"
   },
   {
      "label": "4235 - Haz. Mat. Spill Cntnmnt. & Clean-up",
      "value": "4235",
      "type": "psc_code"
   },
   {
      "label": "4235 - HAZARDOUS MATERIAL SPILL CONTAINMENT AND CLEAN-UP EQUIPMENT AND MATERIAL",
      "value": "4235",
      "type": "psc_code"
   },
   {
      "label": "4240 - SAFETY AND RESCUE EQUIPMENT",
      "value": "4240",
      "type": "psc_code"
   },
   {
      "label": "4250 - RECYCLING AND RECLAMATION EQUIPMENT",
      "value": "4250",
      "type": "psc_code"
   },
   {
      "label": "43 - PUMPS AND COMPRESSORS",
      "value": "43",
      "type": "psc_code"
   },
   {
      "label": "4310 - COMPRESSORS AND VACUUM PUMPS",
      "value": "4310",
      "type": "psc_code"
   },
   {
      "label": "4320 - POWER AND HAND PUMPS",
      "value": "4320",
      "type": "psc_code"
   },
   {
      "label": "4330 - CENTRIFUGALS, SEPARATORS, AND PRESSURE AND VACUUM FILTERS",
      "value": "4330",
      "type": "psc_code"
   },
   {
      "label": "4330 - CENTRIFUGALS SEPARATORS & FILTERS",
      "value": "4330",
      "type": "psc_code"
   },
   {
      "label": "44 - FURNACE/STEAM/DRYING; NUCL REACTOR",
      "value": "44",
      "type": "psc_code"
   },
   {
      "label": "4410 - INDUSTRIAL BOILERS",
      "value": "4410",
      "type": "psc_code"
   },
   {
      "label": "4420 - HEAT EXCHANGERS AND STEAM CONDENSERS",
      "value": "4420",
      "type": "psc_code"
   },
   {
      "label": "4420 - HEAT EXCHANGERS & STEAM CONDENSERS",
      "value": "4420",
      "type": "psc_code"
   },
   {
      "label": "4430 - INDUSTRIAL FURNACES,KILNS & OVENS",
      "value": "4430",
      "type": "psc_code"
   },
   {
      "label": "4430 - INDUSTRIAL FURNACES, KILNS, LEHRS, AND OVENS",
      "value": "4430",
      "type": "psc_code"
   },
   {
      "label": "4440 - DRIERS DEHYDRATORS & ANHYDRATORS",
      "value": "4440",
      "type": "psc_code"
   },
   {
      "label": "4440 - DRIERS, DEHYDRATORS, AND ANHYDRATORS",
      "value": "4440",
      "type": "psc_code"
   },
   {
      "label": "4460 - AIR PURIFICATION EQUIPMENT",
      "value": "4460",
      "type": "psc_code"
   },
   {
      "label": "4470 - NUCLEAR REACTORS",
      "value": "4470",
      "type": "psc_code"
   },
   {
      "label": "45 - PLUMBING, HEATING, WASTE DISPOSAL",
      "value": "45",
      "type": "psc_code"
   },
   {
      "label": "4510 - PLUMBING FIXTURES AND ACCESSORIES",
      "value": "4510",
      "type": "psc_code"
   },
   {
      "label": "4520 - SPACE AND WATER HEATING EQUIPMENT",
      "value": "4520",
      "type": "psc_code"
   },
   {
      "label": "4530 - FUEL BURNING EQUIPMENT UNITS",
      "value": "4530",
      "type": "psc_code"
   },
   {
      "label": "4540 - WASTE DISPOSAL EQUIPMENT",
      "value": "4540",
      "type": "psc_code"
   },
   {
      "label": "46 - WATER PURIFICATION/SEWAGE TREATMENT",
      "value": "46",
      "type": "psc_code"
   },
   {
      "label": "4610 - WATER PURIFICATION EQUIPMENT",
      "value": "4610",
      "type": "psc_code"
   },
   {
      "label": "4620 - WATER DISTILLATION EQUIPMENT, MARINE AND INDUSTRIAL",
      "value": "4620",
      "type": "psc_code"
   },
   {
      "label": "4620 - WATER DISTILL EQ-MARINE & INDUST",
      "value": "4620",
      "type": "psc_code"
   },
   {
      "label": "4630 - SEWAGE TREATMENT EQUIPMENT",
      "value": "4630",
      "type": "psc_code"
   },
   {
      "label": "47 - PIPE, TUBING, HOSE, AND FITTINGS",
      "value": "47",
      "type": "psc_code"
   },
   {
      "label": "4710 - PIPE, TUBE AND RIGID TUBING",
      "value": "4710",
      "type": "psc_code"
   },
   {
      "label": "4720 - HOSE AND FLEXIBLE TUBING",
      "value": "4720",
      "type": "psc_code"
   },
   {
      "label": "4730 - FITTINGS - HOSE PIPE & TUBE",
      "value": "4730",
      "type": "psc_code"
   },
   {
      "label": "4730 - HOSE, PIPE, TUBE, LUBRICATION, AND RAILING FITTINGS",
      "value": "4730",
      "type": "psc_code"
   },
   {
      "label": "48 - VALVES",
      "value": "48",
      "type": "psc_code"
   },
   {
      "label": "4810 - VALVES, POWERED",
      "value": "4810",
      "type": "psc_code"
   },
   {
      "label": "4820 - VALVES, NONPOWERED",
      "value": "4820",
      "type": "psc_code"
   },
   {
      "label": "49 - MAINT/REPAIR SHOP EQPT",
      "value": "49",
      "type": "psc_code"
   },
   {
      "label": "4910 - MOTOR VEHICLE MAINT EQ",
      "value": "4910",
      "type": "psc_code"
   },
   {
      "label": "4910 - MOTOR VEHICLE MAINTENANCE AND REPAIR SHOP SPECIALIZED EQUIPMENT",
      "value": "4910",
      "type": "psc_code"
   },
   {
      "label": "4920 - AIRCRAFT MAINTENANCE AND REPAIR SHOP SPECIALIZED EQUIPMENT",
      "value": "4920",
      "type": "psc_code"
   },
   {
      "label": "4920 - AIRCRAFT MAINT & REP SHOP EQ",
      "value": "4920",
      "type": "psc_code"
   },
   {
      "label": "4921 - TORPEDO MAINT REP & CHECKOUT EQ",
      "value": "4921",
      "type": "psc_code"
   },
   {
      "label": "4921 - TORPEDO MAINTENANCE, REPAIR, AND CHECKOUT SPECIALIZED EQUIPMENT",
      "value": "4921",
      "type": "psc_code"
   },
   {
      "label": "4923 - DEPTH CHARGES AND UNDERWATER MINES MAINTENANCE, REPAIR, AND CHECKOUT SPECIALIZED EQUIPMENT",
      "value": "4923",
      "type": "psc_code"
   },
   {
      "label": "4923 - DEPTH CHARGES-MINES MAINT & REP EQ",
      "value": "4923",
      "type": "psc_code"
   },
   {
      "label": "4925 - AMMUNITION MAINTENANCE, REPAIR, AND CHECKOUT SPECIALIZED EQUIPMENT",
      "value": "4925",
      "type": "psc_code"
   },
   {
      "label": "4925 - AMMUNITION MAINT - CHECKOUT EQ",
      "value": "4925",
      "type": "psc_code"
   },
   {
      "label": "4927 - ROCKET MAINTENANCE, REPAIR AND CHECKOUT SPECIALIZED EQUIPMENT",
      "value": "4927",
      "type": "psc_code"
   },
   {
      "label": "4927 - ROCKET MAINT & REP EQ",
      "value": "4927",
      "type": "psc_code"
   },
   {
      "label": "4930 - LUBRICATION AND FUEL DISPENSING EQUIPMENT",
      "value": "4930",
      "type": "psc_code"
   },
   {
      "label": "4930 - LUBRICATION & FUEL DISPENSING EQ",
      "value": "4930",
      "type": "psc_code"
   },
   {
      "label": "4931 - FIRE CONTROL MAINTENANCE AND REPAIR SHOP SPECIALIZED EQUIPMENT",
      "value": "4931",
      "type": "psc_code"
   },
   {
      "label": "4931 - FIRE CONT MAINT EQ",
      "value": "4931",
      "type": "psc_code"
   },
   {
      "label": "4933 - WEAPONS MAINT EQ",
      "value": "4933",
      "type": "psc_code"
   },
   {
      "label": "4933 - WEAPONS MAINTENANCE AND REPAIR SHOP SPECIALIZED EQUIPMENT",
      "value": "4933",
      "type": "psc_code"
   },
   {
      "label": "4935 - GUIDED MISSILE MAINTENANCE, REPAIR, AND CHECKOUT SPECIALIZED EQUIPMENT",
      "value": "4935",
      "type": "psc_code"
   },
   {
      "label": "4935 - GUIDED MISSILE MAINT EQ",
      "value": "4935",
      "type": "psc_code"
   },
   {
      "label": "4940 - MISCELLANEOUS MAINTENANCE AND REPAIR SHOP SPECIALIZED EQUIPMENT",
      "value": "4940",
      "type": "psc_code"
   },
   {
      "label": "4940 - MISC MAINT EQ",
      "value": "4940",
      "type": "psc_code"
   },
   {
      "label": "4960 - SPACE VEHICLE MAINTENANCE, REPAIR, AND CHECKOUT SPECIALIZED EQUIPMENT",
      "value": "4960",
      "type": "psc_code"
   },
   {
      "label": "4960 - SPACE VEHICLE MAINT EQ",
      "value": "4960",
      "type": "psc_code"
   },
   {
      "label": "4970 - MULTIPLE GUIDED WEAPONS, SPECIALIZED MAINTENANCE AND REPAIR SHOP EQUIPMENT",
      "value": "4970",
      "type": "psc_code"
   },
   {
      "label": "4970 - MULTI GUIDED WEAPON/SPEC MAINT EQ",
      "value": "4970",
      "type": "psc_code"
   },
   {
      "label": "51 - HAND TOOLS",
      "value": "51",
      "type": "psc_code"
   },
   {
      "label": "5110 - HAND TOOLS, EDGED, NONPOWERED",
      "value": "5110",
      "type": "psc_code"
   },
   {
      "label": "5120 - HAND TOOLS, NONEDGED, NONPOWERED",
      "value": "5120",
      "type": "psc_code"
   },
   {
      "label": "5130 - HAND TOOLS, POWER DRIVEN",
      "value": "5130",
      "type": "psc_code"
   },
   {
      "label": "5133 - DRILL BITS, COUNTERBORES, AND COUNTERSINKS: HAND AND MACHINE",
      "value": "5133",
      "type": "psc_code"
   },
   {
      "label": "5133 - DRILL BITS,COUNTERBORES & SINKS",
      "value": "5133",
      "type": "psc_code"
   },
   {
      "label": "5136 - TAPS, DIES, AND COLLETS; HAND AND MACHINE",
      "value": "5136",
      "type": "psc_code"
   },
   {
      "label": "5136 - TAPS,DIES & COLLETS-HAND & MACHINE",
      "value": "5136",
      "type": "psc_code"
   },
   {
      "label": "5140 - TOOL AND HARDWARE BOXES",
      "value": "5140",
      "type": "psc_code"
   },
   {
      "label": "5180 - SETS KITS & OUTFITS OF HAND TOOLS",
      "value": "5180",
      "type": "psc_code"
   },
   {
      "label": "5180 - SETS, KITS, AND OUTFITS OF HAND TOOLS",
      "value": "5180",
      "type": "psc_code"
   },
   {
      "label": "52 - MEASURING TOOLS",
      "value": "52",
      "type": "psc_code"
   },
   {
      "label": "5210 - MEASURING TOOLS, CRAFTSMEN'S",
      "value": "5210",
      "type": "psc_code"
   },
   {
      "label": "5220 - GAGES & PRECISION LAYOUT TOOL",
      "value": "5220",
      "type": "psc_code"
   },
   {
      "label": "5220 - INSPECTION GAGES AND PRECISION LAYOUT TOOLS",
      "value": "5220",
      "type": "psc_code"
   },
   {
      "label": "5280 - SETS, KITS, AND OUTFITS OF MEASURING TOOLS",
      "value": "5280",
      "type": "psc_code"
   },
   {
      "label": "5280 - SETS, KITS & OUTFITS OF MEASURING T",
      "value": "5280",
      "type": "psc_code"
   },
   {
      "label": "53 - HARDWARE AND ABRASIVES",
      "value": "53",
      "type": "psc_code"
   },
   {
      "label": "5305 - SCREWS",
      "value": "5305",
      "type": "psc_code"
   },
   {
      "label": "5306 - BOLTS",
      "value": "5306",
      "type": "psc_code"
   },
   {
      "label": "5307 - STUDS",
      "value": "5307",
      "type": "psc_code"
   },
   {
      "label": "5310 - NUTS AND WASHERS",
      "value": "5310",
      "type": "psc_code"
   },
   {
      "label": "5315 - NAILS, MACHINE KEYS, AND PINS",
      "value": "5315",
      "type": "psc_code"
   },
   {
      "label": "5320 - RIVETS",
      "value": "5320",
      "type": "psc_code"
   },
   {
      "label": "5325 - FASTENING DEVICES",
      "value": "5325",
      "type": "psc_code"
   },
   {
      "label": "5330 - PACKING AND GASKET MATERIALS",
      "value": "5330",
      "type": "psc_code"
   },
   {
      "label": "5331 - O-RING",
      "value": "5331",
      "type": "psc_code"
   },
   {
      "label": "5335 - METAL SCREENING",
      "value": "5335",
      "type": "psc_code"
   },
   {
      "label": "5340 - HARDWARE, COMMERCIAL",
      "value": "5340",
      "type": "psc_code"
   },
   {
      "label": "5340 - HARDWARE",
      "value": "5340",
      "type": "psc_code"
   },
   {
      "label": "5341 - BRACKETS",
      "value": "5341",
      "type": "psc_code"
   },
   {
      "label": "5342 - HARDWARE, WEAPON SYSTEM",
      "value": "5342",
      "type": "psc_code"
   },
   {
      "label": "5345 - DISKS AND STONES, ABRASIVE",
      "value": "5345",
      "type": "psc_code"
   },
   {
      "label": "5350 - ABRASIVE MATERIALS",
      "value": "5350",
      "type": "psc_code"
   },
   {
      "label": "5355 - KNOBS AND POINTERS",
      "value": "5355",
      "type": "psc_code"
   },
   {
      "label": "5360 - COIL, FLAT, LEAF, AND WIRE SPRINGS",
      "value": "5360",
      "type": "psc_code"
   },
   {
      "label": "5365 - BUSHINGS, RINGS, SHIMS AND SPACERS",
      "value": "5365",
      "type": "psc_code"
   },
   {
      "label": "5365 - BUSHINGS, RINGS, SHIMS, AND SPACERS",
      "value": "5365",
      "type": "psc_code"
   },
   {
      "label": "54 - PREFAB STRUCTURES/SCAFFOLDING",
      "value": "54",
      "type": "psc_code"
   },
   {
      "label": "5410 - PREFABRICATED AND PORTABLE BUILDINGS",
      "value": "5410",
      "type": "psc_code"
   },
   {
      "label": "5410 - PREFABRICATED & PORTABLE BUILDINGS",
      "value": "5410",
      "type": "psc_code"
   },
   {
      "label": "5411 - RIGID WALL SHELTERS",
      "value": "5411",
      "type": "psc_code"
   },
   {
      "label": "5419 - COLLECTIVE MODULAR SUPPORT SYSTEM",
      "value": "5419",
      "type": "psc_code"
   },
   {
      "label": "5420 - BRIDGES, FIXED AND FLOATING",
      "value": "5420",
      "type": "psc_code"
   },
   {
      "label": "5430 - STORAGE TANKS",
      "value": "5430",
      "type": "psc_code"
   },
   {
      "label": "5440 - SCAFFOLDING EQUIPMENT AND CONCRETE FORMS",
      "value": "5440",
      "type": "psc_code"
   },
   {
      "label": "5440 - SCAFFOLDING EQ & CONCRETE FORMS",
      "value": "5440",
      "type": "psc_code"
   },
   {
      "label": "5445 - PREFABRICATED TOWER STRUCTURES",
      "value": "5445",
      "type": "psc_code"
   },
   {
      "label": "5450 - MISC PREFABRICATED STRUCTURES",
      "value": "5450",
      "type": "psc_code"
   },
   {
      "label": "5450 - MISCELLANEOUS PREFABRICATED STRUCTURES",
      "value": "5450",
      "type": "psc_code"
   },
   {
      "label": "55 - LUMBER, MILLWORK, PLYWOOD, VENEER",
      "value": "55",
      "type": "psc_code"
   },
   {
      "label": "5510 - LUMBER AND RELATED BASIC WOOD MATERIALS",
      "value": "5510",
      "type": "psc_code"
   },
   {
      "label": "5510 - LUMBER & RELATED WOOD MATERIALS",
      "value": "5510",
      "type": "psc_code"
   },
   {
      "label": "5520 - MILLWORK",
      "value": "5520",
      "type": "psc_code"
   },
   {
      "label": "5530 - PLYWOOD AND VENEER",
      "value": "5530",
      "type": "psc_code"
   },
   {
      "label": "56 - CONSTRUCTION AND BUILDING MATERIAL",
      "value": "56",
      "type": "psc_code"
   },
   {
      "label": "5610 - MINERAL CONSTRUCTION MATERIALS, BULK",
      "value": "5610",
      "type": "psc_code"
   },
   {
      "label": "5610 - MINERAL CONTRUCT MATERIALS BULK",
      "value": "5610",
      "type": "psc_code"
   },
   {
      "label": "5620 - TILE, BRICK AND BLOCK",
      "value": "5620",
      "type": "psc_code"
   },
   {
      "label": "5620 - TILE, BRICK, AND BLOCK",
      "value": "5620",
      "type": "psc_code"
   },
   {
      "label": "5630 - PIPE AND CONDUIT, NONMETALLIC",
      "value": "5630",
      "type": "psc_code"
   },
   {
      "label": "5640 - WALLBOARD, BUILDING PAPER, AND THERMAL INSULATION MATERIALS",
      "value": "5640",
      "type": "psc_code"
   },
   {
      "label": "5640 - WALLBOARD BLDG & THERMAL INSULATION",
      "value": "5640",
      "type": "psc_code"
   },
   {
      "label": "5650 - ROOFING AND SIDING MATERIALS",
      "value": "5650",
      "type": "psc_code"
   },
   {
      "label": "5660 - FENCING, FENCES, GATES AND COMPONENTS",
      "value": "5660",
      "type": "psc_code"
   },
   {
      "label": "5660 - FENCING FENCES & GATES",
      "value": "5660",
      "type": "psc_code"
   },
   {
      "label": "5670 - BUILDING COMPONENTS, PREFABRICATED",
      "value": "5670",
      "type": "psc_code"
   },
   {
      "label": "5675 - NONWOOD CONSTRUCT MATERIAL",
      "value": "5675",
      "type": "psc_code"
   },
   {
      "label": "5675 - NONWOOD CONSTRUCTION LUMBER AND RELATED MATERIALS",
      "value": "5675",
      "type": "psc_code"
   },
   {
      "label": "5680 - MISCELLANEOUS CONSTRUCTION MATERIALS",
      "value": "5680",
      "type": "psc_code"
   },
   {
      "label": "5680 - MISC CONTRUCT MATERIALS",
      "value": "5680",
      "type": "psc_code"
   },
   {
      "label": "58 - COMM/DETECT/COHERENT RADIATION",
      "value": "58",
      "type": "psc_code"
   },
   {
      "label": "5805 - TELEPHONE AND TELEGRAPH EQUIPMENT",
      "value": "5805",
      "type": "psc_code"
   },
   {
      "label": "5810 - COMMUNICATIONS SECURITY EQUIPMENT AND COMPONENTS",
      "value": "5810",
      "type": "psc_code"
   },
   {
      "label": "5810 - COMM SECURITY EQ & COMPS",
      "value": "5810",
      "type": "psc_code"
   },
   {
      "label": "5811 - OTHER CRYPTOLOGIC EQUIPMENT AND COMPONENTS",
      "value": "5811",
      "type": "psc_code"
   },
   {
      "label": "5811 - OTHER CRYPTOLOGIC EQ & COMPONENTS",
      "value": "5811",
      "type": "psc_code"
   },
   {
      "label": "5815 - TELETYPE AND FACSIMILE EQUIPMENT",
      "value": "5815",
      "type": "psc_code"
   },
   {
      "label": "5820 - RADIO AND TELEVISION COMMUNICATION EQUIPMENT, EXCEPT AIRBORNE",
      "value": "5820",
      "type": "psc_code"
   },
   {
      "label": "5820 - RADIO TV EQ EXCEPT AIRBORNE",
      "value": "5820",
      "type": "psc_code"
   },
   {
      "label": "5821 - RADIO & TV EQ AIRBORNE",
      "value": "5821",
      "type": "psc_code"
   },
   {
      "label": "5821 - RADIO AND TELEVISION COMMUNICATION EQUIPMENT, AIRBORNE",
      "value": "5821",
      "type": "psc_code"
   },
   {
      "label": "5825 - RADIO NAVIGATION EQ - EXCEPT AIR",
      "value": "5825",
      "type": "psc_code"
   },
   {
      "label": "5825 - RADIO NAVIGATION EQUIPMENT, EXCEPT AIRBORNE",
      "value": "5825",
      "type": "psc_code"
   },
   {
      "label": "5826 - RADIO NAVIGATION EQ AIR",
      "value": "5826",
      "type": "psc_code"
   },
   {
      "label": "5826 - RADIO NAVIGATION EQUIPMENT, AIRBORNE",
      "value": "5826",
      "type": "psc_code"
   },
   {
      "label": "5830 - INTERCOMMUNICATION AND PUBLIC ADDRESS SYSTEMS, EXCEPT AIRBORNE",
      "value": "5830",
      "type": "psc_code"
   },
   {
      "label": "5830 - INTERCOMM PUBLIC ADDRESS SYS EX AIR",
      "value": "5830",
      "type": "psc_code"
   },
   {
      "label": "5831 - INTERCOMMUNICATION AND PUBLIC ADDRESS SYSTEMS, AIRBORNE",
      "value": "5831",
      "type": "psc_code"
   },
   {
      "label": "5831 - INTERCOMM PUBLIC ADDRESS SYS - AIR",
      "value": "5831",
      "type": "psc_code"
   },
   {
      "label": "5835 - SOUND RECORDING AND REPRODUCING EQUIPMENT",
      "value": "5835",
      "type": "psc_code"
   },
   {
      "label": "5835 - SOUND RECORDING & REPRODUCING EQ",
      "value": "5835",
      "type": "psc_code"
   },
   {
      "label": "5836 - VIDEO RECORDING AND REPRODUCING EQUIPMENT",
      "value": "5836",
      "type": "psc_code"
   },
   {
      "label": "5836 - VIDEO RECORDING AND REPRODUCING EQU",
      "value": "5836",
      "type": "psc_code"
   },
   {
      "label": "5840 - RADAR EQUIPMENT, EXCEPT AIRBORNE",
      "value": "5840",
      "type": "psc_code"
   },
   {
      "label": "5841 - RADAR EQUIPMENT, AIRBORNE",
      "value": "5841",
      "type": "psc_code"
   },
   {
      "label": "5845 - UNDERWATER SOUND EQUIPMENT",
      "value": "5845",
      "type": "psc_code"
   },
   {
      "label": "5850 - VISIBLE AND INVISIBLE LIGHT COMMUNICATION EQUIPMENT",
      "value": "5850",
      "type": "psc_code"
   },
   {
      "label": "5850 - VISIBLE INVISIBLE LIGHT COMMUN EQ",
      "value": "5850",
      "type": "psc_code"
   },
   {
      "label": "5855 - NIGHT VISION EQUIPMENT, EMITTED AND REFLECTED RADIATION",
      "value": "5855",
      "type": "psc_code"
   },
   {
      "label": "5855 - NIGHT VISION EQ",
      "value": "5855",
      "type": "psc_code"
   },
   {
      "label": "5860 - STIMULATED COHERENT RADIATION DEV",
      "value": "5860",
      "type": "psc_code"
   },
   {
      "label": "5860 - STIMULATED COHERENT RADIATION DEVICES, COMPONENTS, AND ACCESSORIES",
      "value": "5860",
      "type": "psc_code"
   },
   {
      "label": "5865 - ELECTRONIC COUNTERMEASURES, COUNTER-COUNTERMEASURES AND QUICK REACTION CAPABILITY EQUIPMENT",
      "value": "5865",
      "type": "psc_code"
   },
   {
      "label": "5865 - ELEC COUNTERMEASURE & QUICK REAC EQ",
      "value": "5865",
      "type": "psc_code"
   },
   {
      "label": "5895 - MISC COMMUNICATION EQ",
      "value": "5895",
      "type": "psc_code"
   },
   {
      "label": "5895 - MISCELLANEOUS COMMUNICATION EQUIPMENT",
      "value": "5895",
      "type": "psc_code"
   },
   {
      "label": "59 - ELECTRICAL/ELECTRONIC EQPT COMPNTS",
      "value": "59",
      "type": "psc_code"
   },
   {
      "label": "5905 - RESISTORS",
      "value": "5905",
      "type": "psc_code"
   },
   {
      "label": "5910 - CAPACITORS",
      "value": "5910",
      "type": "psc_code"
   },
   {
      "label": "5915 - FILTERS AND NETWORKS",
      "value": "5915",
      "type": "psc_code"
   },
   {
      "label": "5920 - FUSES, ARRESTORS, ABSORBERS, AND PROTECTORS",
      "value": "5920",
      "type": "psc_code"
   },
   {
      "label": "5920 - FUSES ARRESTERS ABORBERS & PROTECTO",
      "value": "5920",
      "type": "psc_code"
   },
   {
      "label": "5925 - CIRCUIT BREAKERS",
      "value": "5925",
      "type": "psc_code"
   },
   {
      "label": "5930 - SWITCHES",
      "value": "5930",
      "type": "psc_code"
   },
   {
      "label": "5935 - CONNECTORS, ELECTRICAL",
      "value": "5935",
      "type": "psc_code"
   },
   {
      "label": "5940 - LUGS TERMINALS & TERMINAL STRIPS",
      "value": "5940",
      "type": "psc_code"
   },
   {
      "label": "5940 - LUGS, TERMINALS, AND TERMINAL STRIPS",
      "value": "5940",
      "type": "psc_code"
   },
   {
      "label": "5945 - RELAYS AND SOLENOIDS",
      "value": "5945",
      "type": "psc_code"
   },
   {
      "label": "5950 - COILS AND TRANSFORMERS",
      "value": "5950",
      "type": "psc_code"
   },
   {
      "label": "5955 - OSCILLATORS AND PIEZOELECTRIC CRYSTALS",
      "value": "5955",
      "type": "psc_code"
   },
   {
      "label": "5955 - OSCILLATORS PIEZOELECTRIC CRYSTALS",
      "value": "5955",
      "type": "psc_code"
   },
   {
      "label": "5960 - ELECTRON TUBES & ASSOC HARDWARE",
      "value": "5960",
      "type": "psc_code"
   },
   {
      "label": "5960 - ELECTRON TUBES AND ASSOCIATED HARDWARE",
      "value": "5960",
      "type": "psc_code"
   },
   {
      "label": "5961 - SEMICONDUCTOR DEVICES AND ASSOCIATED HARDWARE",
      "value": "5961",
      "type": "psc_code"
   },
   {
      "label": "5961 - SEMI CONDUCTOR DEVICES",
      "value": "5961",
      "type": "psc_code"
   },
   {
      "label": "5962 - MICROCIRCUITS, ELECTRONIC",
      "value": "5962",
      "type": "psc_code"
   },
   {
      "label": "5963 - ELECTRONIC MODULES",
      "value": "5963",
      "type": "psc_code"
   },
   {
      "label": "5965 - HEADSETS, HANDSETS, MICROPHONES AND SPEAKERS",
      "value": "5965",
      "type": "psc_code"
   },
   {
      "label": "5965 - HEADSET,HANDSET,MICROPHONE & SPEAK",
      "value": "5965",
      "type": "psc_code"
   },
   {
      "label": "5970 - ELECTRICAL INSULATORS AND INSULATING MATERIALS",
      "value": "5970",
      "type": "psc_code"
   },
   {
      "label": "5970 - ELECT INSULATORS & INSULATING MAT",
      "value": "5970",
      "type": "psc_code"
   },
   {
      "label": "5975 - ELECTRICAL HARDWARE AND SUPPLIES",
      "value": "5975",
      "type": "psc_code"
   },
   {
      "label": "5977 - ELECT CONTACT BRUSHES & ELECTRODES",
      "value": "5977",
      "type": "psc_code"
   },
   {
      "label": "5977 - ELECTRICAL CONTACT BRUSHES AND ELECTRODES",
      "value": "5977",
      "type": "psc_code"
   },
   {
      "label": "5980 - OPTOELECTRICAL DEVICES/ASSOC HARDWA",
      "value": "5980",
      "type": "psc_code"
   },
   {
      "label": "5980 - OPTOELECTRONIC DEVICES AND ASSOCIATED HARDWARE",
      "value": "5980",
      "type": "psc_code"
   },
   {
      "label": "5985 - ANTENNAS, WAVEGUIDES, AND RELATED EQUIPMENT",
      "value": "5985",
      "type": "psc_code"
   },
   {
      "label": "5985 - ANTENNAS WAVEGUIDES & RELATED EQ",
      "value": "5985",
      "type": "psc_code"
   },
   {
      "label": "5990 - SYNCHROS AND RESOLVERS",
      "value": "5990",
      "type": "psc_code"
   },
   {
      "label": "5995 - CABLE CORD WIRE ASSEMBLY - COMM EQ",
      "value": "5995",
      "type": "psc_code"
   },
   {
      "label": "5995 - CABLE, CORD, AND WIRE ASSEMBLIES: COMMUNICATION EQUIPMENT",
      "value": "5995",
      "type": "psc_code"
   },
   {
      "label": "5996 - AMPLIFIERS",
      "value": "5996",
      "type": "psc_code"
   },
   {
      "label": "5998 - ELECTRICAL AND ELECTRONIC ASSEMBLIES, BOARDS, CARDS, AND ASSOCIATED HARDWARE",
      "value": "5998",
      "type": "psc_code"
   },
   {
      "label": "5998 - ELE ASSEMB-BDS CARDS-ASSOC HARDWARE",
      "value": "5998",
      "type": "psc_code"
   },
   {
      "label": "5999 - MSC ELECT & ELECTRONIC COMPONENTS",
      "value": "5999",
      "type": "psc_code"
   },
   {
      "label": "5999 - MISCELLANEOUS ELECTRICAL AND ELECTRONIC COMPONENTS",
      "value": "5999",
      "type": "psc_code"
   },
   {
      "label": "60 - FIBER OPTIC",
      "value": "60",
      "type": "psc_code"
   },
   {
      "label": "6004 - ROTARY JOINTS",
      "value": "6004",
      "type": "psc_code"
   },
   {
      "label": "6005 - COUPLERS, SPLITTERS, & MIXERS",
      "value": "6005",
      "type": "psc_code"
   },
   {
      "label": "6006 - ATTENUATORS",
      "value": "6006",
      "type": "psc_code"
   },
   {
      "label": "6007 - FILTERS",
      "value": "6007",
      "type": "psc_code"
   },
   {
      "label": "6008 - OPTICAL MULTIPLEXERS/DEMULTIPLEXERS",
      "value": "6008",
      "type": "psc_code"
   },
   {
      "label": "6010 - FIBER OPTIC CONDUCTORS",
      "value": "6010",
      "type": "psc_code"
   },
   {
      "label": "6015 - FIBER OPTIC CABLES",
      "value": "6015",
      "type": "psc_code"
   },
   {
      "label": "6020 - FIBER OPTIC CABLE ASSEMBLY & HARNES",
      "value": "6020",
      "type": "psc_code"
   },
   {
      "label": "6020 - FIBER OPTIC CABLE ASSEMBLIES AND HARNESSES",
      "value": "6020",
      "type": "psc_code"
   },
   {
      "label": "6021 - FIBER OPTIC SWITCHES",
      "value": "6021",
      "type": "psc_code"
   },
   {
      "label": "6025 - FIBER OPTIC TRANSMITTERS",
      "value": "6025",
      "type": "psc_code"
   },
   {
      "label": "6026 - FIBER OPTIC RECEIVERS",
      "value": "6026",
      "type": "psc_code"
   },
   {
      "label": "6029 - FIBER OPTIC REPEATERS",
      "value": "6029",
      "type": "psc_code"
   },
   {
      "label": "6030 - FIBER OPTIC DEVICES",
      "value": "6030",
      "type": "psc_code"
   },
   {
      "label": "6031 - INTEGRATED OPTICAL CIRCUITS",
      "value": "6031",
      "type": "psc_code"
   },
   {
      "label": "6032 - FIBER OPTIC LIGHT SOURCES AND PHOTO DETECTORS",
      "value": "6032",
      "type": "psc_code"
   },
   {
      "label": "6032 - FIBER OPTIC LIGHT SOURCES",
      "value": "6032",
      "type": "psc_code"
   },
   {
      "label": "6033 - FIBER OPTIC PHOTO DETECTORS",
      "value": "6033",
      "type": "psc_code"
   },
   {
      "label": "6034 - FIBER OPTIC MOD/DEMODULATORS",
      "value": "6034",
      "type": "psc_code"
   },
   {
      "label": "6035 - FIBER OPTIC LIGHT TRANSFER AND IMAGE TRANSFER DEVICES",
      "value": "6035",
      "type": "psc_code"
   },
   {
      "label": "6035 - FIBER OPTIC LIGHT/IMAGE TRANSFER",
      "value": "6035",
      "type": "psc_code"
   },
   {
      "label": "6040 - FIBER OPTIC SENSORS",
      "value": "6040",
      "type": "psc_code"
   },
   {
      "label": "6050 - FIBER OPTIC PASSIVE DEVICES",
      "value": "6050",
      "type": "psc_code"
   },
   {
      "label": "6060 - FIBER OPTIC INTERCONNECTORS",
      "value": "6060",
      "type": "psc_code"
   },
   {
      "label": "6070 - FIBER OPTIC ACCESSORIES & SUPPLIES",
      "value": "6070",
      "type": "psc_code"
   },
   {
      "label": "6070 - FIBER OPTIC ACCESSORIES AND SUPPLIES",
      "value": "6070",
      "type": "psc_code"
   },
   {
      "label": "6080 - FIBER OPTIC KITS AND SETS",
      "value": "6080",
      "type": "psc_code"
   },
   {
      "label": "6099 - MISC FIBER OPTIC COMPONENTS",
      "value": "6099",
      "type": "psc_code"
   },
   {
      "label": "6099 - MISCELLANEOUS FIBER OPTIC COMPONENTS",
      "value": "6099",
      "type": "psc_code"
   },
   {
      "label": "61 - ELECTRIC WIRE, POWER DISTRIB EQPT",
      "value": "61",
      "type": "psc_code"
   },
   {
      "label": "6105 - MOTORS, ELECTRICAL",
      "value": "6105",
      "type": "psc_code"
   },
   {
      "label": "6110 - ELECTRICAL CONTROL EQUIPMENT",
      "value": "6110",
      "type": "psc_code"
   },
   {
      "label": "6115 - GENERATORS AND GENERATOR SETS, ELECTRICAL",
      "value": "6115",
      "type": "psc_code"
   },
   {
      "label": "6115 - GENERATORS & GENERATOR SETS ELECT",
      "value": "6115",
      "type": "psc_code"
   },
   {
      "label": "6116 - FUEL CELL POWER UNITS, COMPONENTS, AND ACCESSORIES",
      "value": "6116",
      "type": "psc_code"
   },
   {
      "label": "6116 - FUEL CELL POWER UNITS, COMP, ACC",
      "value": "6116",
      "type": "psc_code"
   },
   {
      "label": "6117 - SOLAR ELECTRIC POWER SYSTEMS",
      "value": "6117",
      "type": "psc_code"
   },
   {
      "label": "6120 - TRANSFORMERS: DISTRIBUTION AND POWER STATION",
      "value": "6120",
      "type": "psc_code"
   },
   {
      "label": "6120 - TRANSFORMERS - DISTRIB & POWER STA",
      "value": "6120",
      "type": "psc_code"
   },
   {
      "label": "6125 - CONVERTERS, ELECTRICAL, ROTATING",
      "value": "6125",
      "type": "psc_code"
   },
   {
      "label": "6130 - CONVERTERS, ELECTRICAL, NONROTATING",
      "value": "6130",
      "type": "psc_code"
   },
   {
      "label": "6135 - BATTERIES, NONRECHARGEABLE",
      "value": "6135",
      "type": "psc_code"
   },
   {
      "label": "6140 - BATTERIES, RECHARGEABLE",
      "value": "6140",
      "type": "psc_code"
   },
   {
      "label": "6145 - WIRE AND CABLE, ELECTRICAL",
      "value": "6145",
      "type": "psc_code"
   },
   {
      "label": "6150 - MISCELLANEOUS ELECTRIC POWER AND DISTRIBUTION EQUIPMENT",
      "value": "6150",
      "type": "psc_code"
   },
   {
      "label": "6150 - MISC ELECTRIC POWER & DISTRIB EQ",
      "value": "6150",
      "type": "psc_code"
   },
   {
      "label": "6160 - MISC BATTERY RET FIXTURES & LINERS",
      "value": "6160",
      "type": "psc_code"
   },
   {
      "label": "6160 - MISCELLANEOUS BATTERY RETAINING FIXTURES, LINERS AND ANCILLARY ITEMS",
      "value": "6160",
      "type": "psc_code"
   },
   {
      "label": "62 - LIGHTING FIXTURES, LAMPS",
      "value": "62",
      "type": "psc_code"
   },
   {
      "label": "6210 - INDOOR AND OUTDOOR ELECTRIC LIGHTING FIXTURES",
      "value": "6210",
      "type": "psc_code"
   },
   {
      "label": "6210 - INDOOR & OUTDOOR ELEC LIGHTING FIXT",
      "value": "6210",
      "type": "psc_code"
   },
   {
      "label": "6220 - ELECTRIC VEHICULAR LIGHTS AND FIXTURES",
      "value": "6220",
      "type": "psc_code"
   },
   {
      "label": "6220 - ELEC VEHICULAR LIGHTS & FIXTURES",
      "value": "6220",
      "type": "psc_code"
   },
   {
      "label": "6230 - ELEC PORTABLE & HAND LIGHTING EQ",
      "value": "6230",
      "type": "psc_code"
   },
   {
      "label": "6230 - ELECTRIC PORTABLE AND HAND LIGHTING EQUIPMENT",
      "value": "6230",
      "type": "psc_code"
   },
   {
      "label": "6240 - ELECTRIC LAMPS",
      "value": "6240",
      "type": "psc_code"
   },
   {
      "label": "6250 - BALLASTS, LAMPHOLDERS, AND STARTERS",
      "value": "6250",
      "type": "psc_code"
   },
   {
      "label": "6260 - NONELECTRICAL LIGHTING FIXTURES",
      "value": "6260",
      "type": "psc_code"
   },
   {
      "label": "63 - ALARM, SIGNAL, SECURITY DETECTION",
      "value": "63",
      "type": "psc_code"
   },
   {
      "label": "6310 - TRAFFIC AND TRANSIT SIGNAL SYSTEMS",
      "value": "6310",
      "type": "psc_code"
   },
   {
      "label": "6320 - SHIPBOARD ALARM AND SIGNAL SYSTEMS",
      "value": "6320",
      "type": "psc_code"
   },
   {
      "label": "6320 - SHIPBOARD ALARM AND SIGNALS SYSTEMS",
      "value": "6320",
      "type": "psc_code"
   },
   {
      "label": "6330 - RAILROAD SIGNAL AND WARNING DEVICES",
      "value": "6330",
      "type": "psc_code"
   },
   {
      "label": "6340 - AIRCRAFT ALARM AND SIGNAL SYSTEMS",
      "value": "6340",
      "type": "psc_code"
   },
   {
      "label": "6350 - MISC ALARM, SIGNAL, SEC SYSTEMS",
      "value": "6350",
      "type": "psc_code"
   },
   {
      "label": "6350 - MISCELLANEOUS ALARM, SIGNAL, AND SECURITY DETECTION SYSTEMS",
      "value": "6350",
      "type": "psc_code"
   },
   {
      "label": "65 - MEDICAL/DENTAL/VETERINARY EQPT/SUPP",
      "value": "65",
      "type": "psc_code"
   },
   {
      "label": "6505 - DRUGS AND BIOLOGICALS",
      "value": "6505",
      "type": "psc_code"
   },
   {
      "label": "6506 - BLOOD",
      "value": "6506",
      "type": "psc_code"
   },
   {
      "label": "6507 - BLOOD DERIVATIVES",
      "value": "6507",
      "type": "psc_code"
   },
   {
      "label": "6508 - MEDICATED COSMETICS AND TOILETRIES",
      "value": "6508",
      "type": "psc_code"
   },
   {
      "label": "6509 - DRUGS AND BIOLOGICALS, VETERINARY USE",
      "value": "6509",
      "type": "psc_code"
   },
   {
      "label": "6510 - SURGICAL DRESSING MATERIALS",
      "value": "6510",
      "type": "psc_code"
   },
   {
      "label": "6515 - MEDICAL AND SURGICAL INSTRUMENTS, EQUIPMENT, AND SUPPLIES",
      "value": "6515",
      "type": "psc_code"
   },
   {
      "label": "6515 - MED & SURGICAL INSTRUMENTS,EQ & SUP",
      "value": "6515",
      "type": "psc_code"
   },
   {
      "label": "6520 - DENTAL INSTRUMENTS, EQUIPMENT, AND SUPPLIES",
      "value": "6520",
      "type": "psc_code"
   },
   {
      "label": "6520 - DENTAL INSTRUMENTS EQ & SUPPLIES",
      "value": "6520",
      "type": "psc_code"
   },
   {
      "label": "6525 - IMAGING EQUIPMENT AND SUPPLIES: MEDICAL, DENTAL, VETERINARY",
      "value": "6525",
      "type": "psc_code"
   },
   {
      "label": "6525 - X-RAY EQ SUPPLIES - MED, DENTAL & V",
      "value": "6525",
      "type": "psc_code"
   },
   {
      "label": "6530 - HOSP FURNITURE,EQ,UTENSILS & SUP",
      "value": "6530",
      "type": "psc_code"
   },
   {
      "label": "6530 - HOSPITAL FURNITURE, EQUIPMENT, UTENSILS, AND SUPPLIES",
      "value": "6530",
      "type": "psc_code"
   },
   {
      "label": "6532 - HOSPITAL & SURGICAL CLOTHING",
      "value": "6532",
      "type": "psc_code"
   },
   {
      "label": "6532 - HOSPITAL AND SURGICAL CLOTHING AND RELATED SPECIAL PURPOSE ITEMS",
      "value": "6532",
      "type": "psc_code"
   },
   {
      "label": "6540 - OPHTHALMIC INSTRUMENTS, EQUIPMENT, AND SUPPLIES",
      "value": "6540",
      "type": "psc_code"
   },
   {
      "label": "6540 - OPTHALMIC INSTRUMENTS EQ & SUPPLIES",
      "value": "6540",
      "type": "psc_code"
   },
   {
      "label": "6545 - REPLENISHABLE FIELD MEDICAL SETS, KITS, AND OUTFITS",
      "value": "6545",
      "type": "psc_code"
   },
   {
      "label": "6545 - MEDICAL SETS KITS & OUTFITS",
      "value": "6545",
      "type": "psc_code"
   },
   {
      "label": "6550 - IN VITRO DIAGNOSTIC SUBSTANCES, REAGENTS, TEST KITS AND SETS",
      "value": "6550",
      "type": "psc_code"
   },
   {
      "label": "6550 - IN VITRO DIAGNO SUBSTANCES,REAGENTS",
      "value": "6550",
      "type": "psc_code"
   },
   {
      "label": "66 - INSTRUMENTS AND LABORATORY EQPT",
      "value": "66",
      "type": "psc_code"
   },
   {
      "label": "6605 - NAVIGATIONAL INSTRUMENTS",
      "value": "6605",
      "type": "psc_code"
   },
   {
      "label": "6610 - FLIGHT INSTRUMENTS",
      "value": "6610",
      "type": "psc_code"
   },
   {
      "label": "6615 - AUTO PILOT MECHANISMS AIRBORNE GYRO",
      "value": "6615",
      "type": "psc_code"
   },
   {
      "label": "6615 - AUTOMATIC PILOT MECHANISMS AND AIRBORNE GYRO COMPONENTS",
      "value": "6615",
      "type": "psc_code"
   },
   {
      "label": "6620 - ENGINE INSTRUMENTS",
      "value": "6620",
      "type": "psc_code"
   },
   {
      "label": "6625 - ELECT ELECTRONIC MEASURING INSTRUMT",
      "value": "6625",
      "type": "psc_code"
   },
   {
      "label": "6625 - ELECTRICAL AND ELECTRONIC PROPERTIES MEASURING AND TESTING INSTRUMENTS",
      "value": "6625",
      "type": "psc_code"
   },
   {
      "label": "6630 - CHEMICAL ANALYSIS INSTRUMENTS",
      "value": "6630",
      "type": "psc_code"
   },
   {
      "label": "6632 - ANALYSIS INSTRUMENTS, MEDICAL LABORATORY USE",
      "value": "6632",
      "type": "psc_code"
   },
   {
      "label": "6635 - PHYSICAL PROPERTIES TESTING AND INSPECTION",
      "value": "6635",
      "type": "psc_code"
   },
   {
      "label": "6635 - PHYSICAL PROPERTIES TEST EQ",
      "value": "6635",
      "type": "psc_code"
   },
   {
      "label": "6636 - ENVIRONMENTAL CHAMBERS & RELATED EQ",
      "value": "6636",
      "type": "psc_code"
   },
   {
      "label": "6636 - ENVIRONMENTAL CHAMBERS AND RELATED EQUIPMENT",
      "value": "6636",
      "type": "psc_code"
   },
   {
      "label": "6640 - LABORATORY EQUIPMENT AND SUPPLIES",
      "value": "6640",
      "type": "psc_code"
   },
   {
      "label": "6645 - TIME MEASURING INSTRUMENTS",
      "value": "6645",
      "type": "psc_code"
   },
   {
      "label": "6650 - OPTICAL INSTRUMENTS",
      "value": "6650",
      "type": "psc_code"
   },
   {
      "label": "6650 - OPTICAL INSTRUMENTS, TEST EQUIPMENT, COMPONENTS AND ACCESSORIES",
      "value": "6650",
      "type": "psc_code"
   },
   {
      "label": "6655 - GEOPHYSICAL INSTRUMENTS",
      "value": "6655",
      "type": "psc_code"
   },
   {
      "label": "6660 - METEOROLOG INSTRUMENTS & APPARATUS",
      "value": "6660",
      "type": "psc_code"
   },
   {
      "label": "6660 - METEOROLOGICAL INSTRUMENTS AND APPARATUS",
      "value": "6660",
      "type": "psc_code"
   },
   {
      "label": "6665 - HAZARD-DETECTING INSTRU & APPARATUS",
      "value": "6665",
      "type": "psc_code"
   },
   {
      "label": "6665 - HAZARD-DETECTING INSTRUMENTS AND APPARATUS",
      "value": "6665",
      "type": "psc_code"
   },
   {
      "label": "6670 - SCALES AND BALANCES",
      "value": "6670",
      "type": "psc_code"
   },
   {
      "label": "6675 - DRAFTING, SURVEYING, AND MAPPING INSTRUMENTS",
      "value": "6675",
      "type": "psc_code"
   },
   {
      "label": "6675 - DRAFTING SURVEYING & MAPPING INSTRU",
      "value": "6675",
      "type": "psc_code"
   },
   {
      "label": "6680 - LIQUID AND GAS FLOW, LIQUID LEVEL, AND MECHANICAL MOTION MEASURING INSTRUMENTS",
      "value": "6680",
      "type": "psc_code"
   },
   {
      "label": "6680 - LIQUID-GAS-MOTION MEASURING INSTRU",
      "value": "6680",
      "type": "psc_code"
   },
   {
      "label": "6685 - PRESSURE, TEMPERATURE, AND HUMIDITY MEASURING AND CONTROLLING INSTRUMENTS",
      "value": "6685",
      "type": "psc_code"
   },
   {
      "label": "6685 - PRESSURE TEMP HUMIDITY INSTRUMENTS",
      "value": "6685",
      "type": "psc_code"
   },
   {
      "label": "6695 - COMBINATION AND MISCELLANEOUS INSTRUMENTS",
      "value": "6695",
      "type": "psc_code"
   },
   {
      "label": "6695 - COMBINATION & MISC INSTRUMENTS",
      "value": "6695",
      "type": "psc_code"
   },
   {
      "label": "67 - PHOTOGRAPHIC EQPT",
      "value": "67",
      "type": "psc_code"
   },
   {
      "label": "6710 - CAMERAS, MOTION PICTURE",
      "value": "6710",
      "type": "psc_code"
   },
   {
      "label": "6720 - CAMERAS, STILL PICTURE",
      "value": "6720",
      "type": "psc_code"
   },
   {
      "label": "6730 - PHOTOGRAPHIC PROJECTION EQUIPMENT",
      "value": "6730",
      "type": "psc_code"
   },
   {
      "label": "6740 - PHOTOGRAPHIC DEVELOPING AND FINISHING EQUIPMENT",
      "value": "6740",
      "type": "psc_code"
   },
   {
      "label": "6740 - PHOTO DEVELOPLNG & FINISHING EQ",
      "value": "6740",
      "type": "psc_code"
   },
   {
      "label": "6750 - PHOTOGRAPHIC SUPPLIES",
      "value": "6750",
      "type": "psc_code"
   },
   {
      "label": "6760 - PHOTOGRAPHIC EQUIPMENT AND ACCESSORIES",
      "value": "6760",
      "type": "psc_code"
   },
   {
      "label": "6760 - PHOTOGRAPHIC EQ & ACCESSORIES",
      "value": "6760",
      "type": "psc_code"
   },
   {
      "label": "6770 - FILM, PROCESSED",
      "value": "6770",
      "type": "psc_code"
   },
   {
      "label": "6780 - PHOTOGRAPHIC SETS, KITS, AND OUTFITS",
      "value": "6780",
      "type": "psc_code"
   },
   {
      "label": "6780 - PHOTOGRAPHIC SETS KITS & OUTFITS",
      "value": "6780",
      "type": "psc_code"
   },
   {
      "label": "68 - CHEMICALS AND CHEMICAL PRODUCTS",
      "value": "68",
      "type": "psc_code"
   },
   {
      "label": "6810 - CHEMICALS",
      "value": "6810",
      "type": "psc_code"
   },
   {
      "label": "6820 - DYES",
      "value": "6820",
      "type": "psc_code"
   },
   {
      "label": "6830 - GASES: COMPRESSED AND LIQUEFIED",
      "value": "6830",
      "type": "psc_code"
   },
   {
      "label": "6830 - GASES: COMPRESSED AND LIQUEFIED",
      "value": "6830",
      "type": "psc_code"
   },
   {
      "label": "6835 - MEDICAL GASES",
      "value": "6835",
      "type": "psc_code"
   },
   {
      "label": "6840 - PEST CONTROL AGENTS AND DISINFECTANTS",
      "value": "6840",
      "type": "psc_code"
   },
   {
      "label": "6840 - PEST CONT AGENTS & DISINFECTANTS",
      "value": "6840",
      "type": "psc_code"
   },
   {
      "label": "6850 - MISCELLANEOUS CHEMICAL SPECIALTIES",
      "value": "6850",
      "type": "psc_code"
   },
   {
      "label": "69 - TRAINING AIDS AND DEVICES",
      "value": "69",
      "type": "psc_code"
   },
   {
      "label": "6910 - TRAINING AIDS",
      "value": "6910",
      "type": "psc_code"
   },
   {
      "label": "6920 - ARMAMENT TRAINING DEVICES",
      "value": "6920",
      "type": "psc_code"
   },
   {
      "label": "6930 - OPERATION TRAINING DEVICES",
      "value": "6930",
      "type": "psc_code"
   },
   {
      "label": "6940 - COMMUNICATION TRAINING DEVICES",
      "value": "6940",
      "type": "psc_code"
   },
   {
      "label": "7 - IT AND TELECOM - INFORMATION TECHNOLOGY AND TELECOMMUNICATIONS",
      "value": "7",
      "type": "psc_code"
   },
   {
      "label": "70 - ADP EQPT/SOFTWARE/SUPPLIES AND EQPT",
      "value": "70",
      "type": "psc_code"
   },
   {
      "label": "70 - INFORMATION TECHNOLOGY EQUIPMENT (INCLD FIRMWARE) SOFTWARE,SUPPLIES& SUPPORT EQUIPMENT",
      "value": "70",
      "type": "psc_code"
   },
   {
      "label": "7010 - ADPE SYSTEM CONFIGURATION",
      "value": "7010",
      "type": "psc_code"
   },
   {
      "label": "7010 - INFORMATION TECHNOLOGY EQUIPMENT SYSTEM CONFIGURATION",
      "value": "7010",
      "type": "psc_code"
   },
   {
      "label": "7020 - ADP CENTRAL PROCESSING UNIT (CPU, COMPUTER), ANALOG",
      "value": "7020",
      "type": "psc_code"
   },
   {
      "label": "7020 - INFORMATION TECHNOLOGY CENTRAL PROCESSING UNIT (CPU, COMPUTER), ANALOG",
      "value": "7020",
      "type": "psc_code"
   },
   {
      "label": "7020 - ADP CENTRAL PROCESSING UNIT-ANALOG",
      "value": "7020",
      "type": "psc_code"
   },
   {
      "label": "7021 - ADP CENTRAL PROCESSING UNIT (CPU, COMPUTER), DIGITAL",
      "value": "7021",
      "type": "psc_code"
   },
   {
      "label": "7021 - INFORMATION TECHNOLOGY CENTRAL PROCESSING UNIT (CPU, COMPUTER), DIGITAL",
      "value": "7021",
      "type": "psc_code"
   },
   {
      "label": "7021 - ADP CENTRAL PROCESSING UNIT-DIGITAL",
      "value": "7021",
      "type": "psc_code"
   },
   {
      "label": "7022 - ADP CENTRAL PROCESSING UNIT (CPU, COMPUTER), HYBRID",
      "value": "7022",
      "type": "psc_code"
   },
   {
      "label": "7022 - INFORMATION TECHNOLOGY CENTRAL PROCESSING UNIT (CPU, COMPUTER), HYBRID",
      "value": "7022",
      "type": "psc_code"
   },
   {
      "label": "7022 - ADP CENTRAL PROCESSING UNIT-HYBRID",
      "value": "7022",
      "type": "psc_code"
   },
   {
      "label": "7025 - ADP INPUT/OUTPUT AND STORAGE DEVICES",
      "value": "7025",
      "type": "psc_code"
   },
   {
      "label": "7025 - INFORMATION TECHNOLOGY INPUT/OUTPUT AND STORAGE DEVICES",
      "value": "7025",
      "type": "psc_code"
   },
   {
      "label": "7025 - ADP INPUT/OUTPUT & STORAGE DEVICES",
      "value": "7025",
      "type": "psc_code"
   },
   {
      "label": "7030 - ADP SOFTWARE",
      "value": "7030",
      "type": "psc_code"
   },
   {
      "label": "7030 - INFORMATION TECHNOLOGY SOFTWARE",
      "value": "7030",
      "type": "psc_code"
   },
   {
      "label": "7035 - ADP SUPPORT EQUIPMENT",
      "value": "7035",
      "type": "psc_code"
   },
   {
      "label": "7035 - INFORMATION TECHNOLOGY SUPPORT EQUIPMENT",
      "value": "7035",
      "type": "psc_code"
   },
   {
      "label": "7040 - PUNCHED CARD EQUIPMENT",
      "value": "7040",
      "type": "psc_code"
   },
   {
      "label": "7042 - MINI AND MICRO COMPUTER CONTROL DEVICES",
      "value": "7042",
      "type": "psc_code"
   },
   {
      "label": "7042 - MINI & MICRO COMPUTER CONT DEVICES",
      "value": "7042",
      "type": "psc_code"
   },
   {
      "label": "7045 - ADP SUPPLIES",
      "value": "7045",
      "type": "psc_code"
   },
   {
      "label": "7045 - INFORMATION TECHNOLOGY SUPPLIES",
      "value": "7045",
      "type": "psc_code"
   },
   {
      "label": "7050 - ADP COMPONENTS",
      "value": "7050",
      "type": "psc_code"
   },
   {
      "label": "7050 - INFORMATION TECHNOLOGY COMPONENTS",
      "value": "7050",
      "type": "psc_code"
   },
   {
      "label": "7A - IT AND TELECOM - APLLICATIONS",
      "value": "7A",
      "type": "psc_code"
   },
   {
      "label": "7A20 - IT AND TELECOM - APPLICATION DEVELOPMENT SOFTWARE (PERPETUAL LICENSE SOFTWARE)",
      "value": "7A20",
      "type": "psc_code"
   },
   {
      "label": "7A21 - IT AND TELECOM - BUSINESS APPLICATION SOFTWARE (PERPETUAL LICENSE SOFTWARE)",
      "value": "7A21",
      "type": "psc_code"
   },
   {
      "label": "7B - IT AND TELECOM - COMPUTE",
      "value": "7B",
      "type": "psc_code"
   },
   {
      "label": "7B20 - IT AND TELECOM - HIGH PERFORMANCE COMPUTE (HARDWARE AND PERPETUAL LICENSE SOFTWARE)",
      "value": "7B20",
      "type": "psc_code"
   },
   {
      "label": "7B21 - IT AND TELECOM - COMPUTE: MAINFRAME (HARDWARE AND PERPETUAL LICENSE SOFTWARE)",
      "value": "7B21",
      "type": "psc_code"
   },
   {
      "label": "7B22 - IT AND TELECOM - COMPUTE: SERVERS (HARDWARE AND PERPETUAL LICENSE SOFTWARE)",
      "value": "7B22",
      "type": "psc_code"
   },
   {
      "label": "7C - IT AND TELECOM - DATA CENTER",
      "value": "7C",
      "type": "psc_code"
   },
   {
      "label": "7C20 - IT AND TELECOM - DATA CENTER PRODUCTS (HARDWARE AND PERPETUAL LICENSE SOFTWARE)",
      "value": "7C20",
      "type": "psc_code"
   },
   {
      "label": "7C21 - IT AND TELECOM - OTHER DATA CENTER FACILITIES PRODUCTS (HARDWARE AND PERPETUAL LICENSE SOFTWARE)",
      "value": "7C21",
      "type": "psc_code"
   },
   {
      "label": "7D - IT AND TELECOM - DELIVERY",
      "value": "7D",
      "type": "psc_code"
   },
   {
      "label": "7D20 - IT AND TELECOM - SERVICE DELIVERY MANAGEMENT (HARDWARE AND PERPETUAL LICENSE SOFTWARE)",
      "value": "7D20",
      "type": "psc_code"
   },
   {
      "label": "7E - IT AND TELECOM - END USER",
      "value": "7E",
      "type": "psc_code"
   },
   {
      "label": "7E20 - IT AND TELECOM \u2013 END USER: HELP DESK;TIER 1-2, WORKSPACE, PRINT, PRODUCTIVITY TOOL (HW/PERPETUAL SW)",
      "value": "7E20",
      "type": "psc_code"
   },
   {
      "label": "7E21 - IT AND TELECOM - MOBILE DEVICE PRODUCTS (HARDWARE AND PERPETUAL LICENSE SOFTWARE)",
      "value": "7E21",
      "type": "psc_code"
   },
   {
      "label": "7F - IT AND TELECOM - IT MANAGEMENT",
      "value": "7F",
      "type": "psc_code"
   },
   {
      "label": "7F20 - IT AND TELECOM - IT MANAGEMENT TOOLS/PRODUCTS (HARDWARE AND PERPETUAL LICENSE SOFTWARE)",
      "value": "7F20",
      "type": "psc_code"
   },
   {
      "label": "7G - IT AND TELECOM - NETWORK",
      "value": "7G",
      "type": "psc_code"
   },
   {
      "label": "7G20 - IT AND TELECOM - NETWORK: ANALOG VOICE PRODUCTS (HARDWARE AND PERPETUAL LICENSE SOFTWARE)",
      "value": "7G20",
      "type": "psc_code"
   },
   {
      "label": "7G21 - IT AND TELECOM - NETWORK: DIGITAL NETWORK PRODUCTS (HARDWARE AND PERPETUAL LICENSE SOFTWARE)",
      "value": "7G21",
      "type": "psc_code"
   },
   {
      "label": "7G22 - IT AND TELECOM - NETWORK: SATELLITE AND RF COMMUNICATIONS PRODUCTS (HW, PERPETUAL LICENSE SOFTWARE)",
      "value": "7G22",
      "type": "psc_code"
   },
   {
      "label": "7H - IT AND TELECOM - PLATFORM",
      "value": "7H",
      "type": "psc_code"
   },
   {
      "label": "7H20 - IT AND TELECOM - PLATFORM PRODUCTS: DATABASE, MAINFRAME, MIDDLEWARE (HW, PERPETUAL LICENSE SOFTWARE)",
      "value": "7H20",
      "type": "psc_code"
   },
   {
      "label": "7J - IT AND TELECOM - SECURITY AND COMPLIANCE",
      "value": "7J",
      "type": "psc_code"
   },
   {
      "label": "7J20 - IT AND TELECOM - SECURITY AND COMPLIANCE PRODUCTS (HARDWARE AND PERPETUAL LICENSE SOFTWARE)",
      "value": "7J20",
      "type": "psc_code"
   },
   {
      "label": "7K - IT AND TELECOM - STORAGE",
      "value": "7K",
      "type": "psc_code"
   },
   {
      "label": "7K20 - IT AND TELECOM - STORAGE PRODUCTS (HARDWARE AND PERPETUAL LICENSE SOFTWARE)",
      "value": "7K20",
      "type": "psc_code"
   },
   {
      "label": "71 - FURNITURE",
      "value": "71",
      "type": "psc_code"
   },
   {
      "label": "7105 - HOUSEHOLD FURNITURE",
      "value": "7105",
      "type": "psc_code"
   },
   {
      "label": "7110 - OFFICE FURNITURE",
      "value": "7110",
      "type": "psc_code"
   },
   {
      "label": "7125 - CABINETS, LOCKERS, BINS, AND SHELVING",
      "value": "7125",
      "type": "psc_code"
   },
   {
      "label": "7125 - CABINETS LOCKERS BINS & SHELVING",
      "value": "7125",
      "type": "psc_code"
   },
   {
      "label": "7195 - MISC FURNITURE & FIXTURES",
      "value": "7195",
      "type": "psc_code"
   },
   {
      "label": "7195 - MISCELLANEOUS FURNITURE AND FIXTURES",
      "value": "7195",
      "type": "psc_code"
   },
   {
      "label": "72 - HOUSEHOLD/COMMERC FURNISH/APPLIANCE",
      "value": "72",
      "type": "psc_code"
   },
   {
      "label": "7210 - HOUSEHOLD FURNISHINGS",
      "value": "7210",
      "type": "psc_code"
   },
   {
      "label": "7220 - FLOOR COVERINGS",
      "value": "7220",
      "type": "psc_code"
   },
   {
      "label": "7230 - DRAPERIES, AWNINGS, AND SHADES",
      "value": "7230",
      "type": "psc_code"
   },
   {
      "label": "7240 - HOUSEHOLD AND COMMERCIAL UTILITY CONTAINERS",
      "value": "7240",
      "type": "psc_code"
   },
   {
      "label": "7240 - HOUSEHOLD & COMM UTIL CONTAINERS",
      "value": "7240",
      "type": "psc_code"
   },
   {
      "label": "7290 - MSC HOUSEHOLD FURNISHINGS APPLIANCE",
      "value": "7290",
      "type": "psc_code"
   },
   {
      "label": "7290 - MISCELLANEOUS HOUSEHOLD AND COMMERCIAL FURNISHINGS AND APPLIANCES",
      "value": "7290",
      "type": "psc_code"
   },
   {
      "label": "73 - FOOD PREPARATION/SERVING EQPT",
      "value": "73",
      "type": "psc_code"
   },
   {
      "label": "7310 - FOOD COOKING, BAKING, AND SERVING EQUIPMENT",
      "value": "7310",
      "type": "psc_code"
   },
   {
      "label": "7310 - FOOD COOKING BAKING SERVING EQ",
      "value": "7310",
      "type": "psc_code"
   },
   {
      "label": "7320 - KITCHEN EQUIPMENT AND APPLIANCES",
      "value": "7320",
      "type": "psc_code"
   },
   {
      "label": "7330 - KITCHEN HAND TOOLS AND UTENSILS",
      "value": "7330",
      "type": "psc_code"
   },
   {
      "label": "7340 - CUTLERY AND FLATWARE",
      "value": "7340",
      "type": "psc_code"
   },
   {
      "label": "7350 - TABLEWARE",
      "value": "7350",
      "type": "psc_code"
   },
   {
      "label": "7360 - SET KIT & OUTFIT FOOD PREP & SERV",
      "value": "7360",
      "type": "psc_code"
   },
   {
      "label": "7360 - SETS, KITS, OUTFITS AND MODULES, FOOD PREPERATION AND SERVING",
      "value": "7360",
      "type": "psc_code"
   },
   {
      "label": "74 - OFFICE MACH/TEXT PROCESS/VISIB REC",
      "value": "74",
      "type": "psc_code"
   },
   {
      "label": "7420 - ACCOUNTING AND CALCULATING MACHINES",
      "value": "7420",
      "type": "psc_code"
   },
   {
      "label": "7430 - TYPEWRITERS AND OFFICE TYPE COMPOSING MACHINES",
      "value": "7430",
      "type": "psc_code"
   },
   {
      "label": "7430 - TYPEWRITER & OFFICE COMPOSING MACH",
      "value": "7430",
      "type": "psc_code"
   },
   {
      "label": "7435 - OFFICE INFORMATION SYSTEM EQUIPMENT",
      "value": "7435",
      "type": "psc_code"
   },
   {
      "label": "7450 - OFFICE SOUND RECORDING REPRO MACH",
      "value": "7450",
      "type": "psc_code"
   },
   {
      "label": "7450 - OFFICE TYPE SOUND RECORDING AND REPRODUCING MACHINES",
      "value": "7450",
      "type": "psc_code"
   },
   {
      "label": "7460 - VISIBLE RECORD EQUIPMENT",
      "value": "7460",
      "type": "psc_code"
   },
   {
      "label": "7490 - MISCELLANEOUS OFFICE MACHINES",
      "value": "7490",
      "type": "psc_code"
   },
   {
      "label": "75 - OFFICE SUPPLIES AND DEVICES",
      "value": "75",
      "type": "psc_code"
   },
   {
      "label": "7510 - OFFICE SUPPLIES",
      "value": "7510",
      "type": "psc_code"
   },
   {
      "label": "7520 - OFFICE DEVICES AND ACCESSORIES",
      "value": "7520",
      "type": "psc_code"
   },
   {
      "label": "7530 - STATIONERY AND RECORD FORMS",
      "value": "7530",
      "type": "psc_code"
   },
   {
      "label": "7540 - STANDARD FORMS",
      "value": "7540",
      "type": "psc_code"
   },
   {
      "label": "76 - BOOKS, MAPS, OTHER PUBLICATIONS",
      "value": "76",
      "type": "psc_code"
   },
   {
      "label": "7610 - BOOKS AND PAMPHLETS",
      "value": "7610",
      "type": "psc_code"
   },
   {
      "label": "7630 - NEWSPAPERS AND PERIODICALS",
      "value": "7630",
      "type": "psc_code"
   },
   {
      "label": "7640 - MAPS, ATLASES, CHARTS, AND GLOBES",
      "value": "7640",
      "type": "psc_code"
   },
   {
      "label": "7641 - AERONAUTICAL MAPS, CHARTS AND GEODETIC PRODUCTS",
      "value": "7641",
      "type": "psc_code"
   },
   {
      "label": "7641 - AERO MAPS, CHARTS, GEODETIC PRODS",
      "value": "7641",
      "type": "psc_code"
   },
   {
      "label": "7642 - HYDROGRAPHIC MAPS, CHARTS AND GEODETIC PRODUCTS",
      "value": "7642",
      "type": "psc_code"
   },
   {
      "label": "7642 - HYDRO MAPS, CHARTS, GEODESIC PROJ",
      "value": "7642",
      "type": "psc_code"
   },
   {
      "label": "7643 - Topographic Maps, Charts & Geodesic Pr",
      "value": "7643",
      "type": "psc_code"
   },
   {
      "label": "7643 - TOPOGRAPHIC MAPS, CHARTS AND GEODETIC PRODUCTS",
      "value": "7643",
      "type": "psc_code"
   },
   {
      "label": "7644 - DIGITAL MAPS, CHARTS, GEODETIC PROD",
      "value": "7644",
      "type": "psc_code"
   },
   {
      "label": "7644 - DIGITAL MAPS, CHARTS AND GEODETIC PRODUCTS",
      "value": "7644",
      "type": "psc_code"
   },
   {
      "label": "7650 - DRAWINGS AND SPECIFICATIONS",
      "value": "7650",
      "type": "psc_code"
   },
   {
      "label": "7660 - SHEET AND BOOK MUSIC",
      "value": "7660",
      "type": "psc_code"
   },
   {
      "label": "7670 - MICROFILM, PROCESSED",
      "value": "7670",
      "type": "psc_code"
   },
   {
      "label": "7690 - MISCELLANEOUS PRINTED MATTER",
      "value": "7690",
      "type": "psc_code"
   },
   {
      "label": "77 - MUSICAL INST/PHONOGRAPH/HOME RADIO",
      "value": "77",
      "type": "psc_code"
   },
   {
      "label": "7710 - MUSICAL INSTRUMENTS",
      "value": "7710",
      "type": "psc_code"
   },
   {
      "label": "7720 - MUSICAL INSTRUMENT PARTS AND ACCESSORIES",
      "value": "7720",
      "type": "psc_code"
   },
   {
      "label": "7720 - MUSICAL INSTRUMENT PARTS & ACC",
      "value": "7720",
      "type": "psc_code"
   },
   {
      "label": "7730 - PHONOGRAPHS, RADIOS, AND TELEVISION SETS: HOME TYPE",
      "value": "7730",
      "type": "psc_code"
   },
   {
      "label": "7730 - PHONOGRAPH,RADIO & TV-HOME TYPE",
      "value": "7730",
      "type": "psc_code"
   },
   {
      "label": "7735 - PARTS AND ACCESSORIES OF PHONOGRAPHS, RADIOS, AND TELEVISION SET: HOME TYPE",
      "value": "7735",
      "type": "psc_code"
   },
   {
      "label": "7740 - PHONOGRAPH RECORDS",
      "value": "7740",
      "type": "psc_code"
   },
   {
      "label": "78 - RECREATIONAL/ATHLETIC EQPT",
      "value": "78",
      "type": "psc_code"
   },
   {
      "label": "7810 - ATHLETIC AND SPORTING EQUIPMENT",
      "value": "7810",
      "type": "psc_code"
   },
   {
      "label": "7820 - GAMES, TOYS, AND WHEELED GOODS",
      "value": "7820",
      "type": "psc_code"
   },
   {
      "label": "7830 - RECREATIONAL & GYMNASTIC EQ",
      "value": "7830",
      "type": "psc_code"
   },
   {
      "label": "7830 - RECREATIONAL AND GYMNASTIC EQUIPMENT",
      "value": "7830",
      "type": "psc_code"
   },
   {
      "label": "79 - CLEANING EQPT AND SUPPLIES",
      "value": "79",
      "type": "psc_code"
   },
   {
      "label": "7910 - FLOOR POLISHERS AND VACUUM CLEANING EQUIPMENT",
      "value": "7910",
      "type": "psc_code"
   },
   {
      "label": "7910 - FLOOR POLISHERS & VACUUM CLEANERS",
      "value": "7910",
      "type": "psc_code"
   },
   {
      "label": "7920 - BROOMS, BRUSHES, MOPS, AND SPONGES",
      "value": "7920",
      "type": "psc_code"
   },
   {
      "label": "7930 - CLEANING AND POLISHING COMPOUNDS AND PREPARATIONS",
      "value": "7930",
      "type": "psc_code"
   },
   {
      "label": "7930 - CLEANING/POLISHING COMPOUNDS & PREP",
      "value": "7930",
      "type": "psc_code"
   },
   {
      "label": "80 - BRUSHES, PAINTS, SEALERS, ADHESIVES",
      "value": "80",
      "type": "psc_code"
   },
   {
      "label": "8010 - PAINTS, DOPES, VARNISHES, AND RELATED PRODUCTS",
      "value": "8010",
      "type": "psc_code"
   },
   {
      "label": "8010 - PAINT,DOPE,VARNISH & RELATED PROD",
      "value": "8010",
      "type": "psc_code"
   },
   {
      "label": "8020 - PAINT AND ARTISTS' BRUSHES",
      "value": "8020",
      "type": "psc_code"
   },
   {
      "label": "8030 - PRESERVATIVE AND SEALING COMPOUNDS",
      "value": "8030",
      "type": "psc_code"
   },
   {
      "label": "8040 - ADHESIVES",
      "value": "8040",
      "type": "psc_code"
   },
   {
      "label": "81 - CONTAINERS/PACKAGING/PACKING SUPPL",
      "value": "81",
      "type": "psc_code"
   },
   {
      "label": "8105 - BAGS AND SACKS",
      "value": "8105",
      "type": "psc_code"
   },
   {
      "label": "8110 - DRUMS AND CANS",
      "value": "8110",
      "type": "psc_code"
   },
   {
      "label": "8115 - BOXES, CARTONS, AND CRATES",
      "value": "8115",
      "type": "psc_code"
   },
   {
      "label": "8120 - COMMERCIAL AND INDUSTRIAL GAS CYLINDERS",
      "value": "8120",
      "type": "psc_code"
   },
   {
      "label": "8120 - GAS CYLINDERS",
      "value": "8120",
      "type": "psc_code"
   },
   {
      "label": "8125 - BOTTLES AND JARS",
      "value": "8125",
      "type": "psc_code"
   },
   {
      "label": "8130 - REELS AND SPOOLS",
      "value": "8130",
      "type": "psc_code"
   },
   {
      "label": "8135 - PACKAGING & PACKING BULK MATERIALS",
      "value": "8135",
      "type": "psc_code"
   },
   {
      "label": "8135 - PACKAGING AND PACKING BULK MATERIALS",
      "value": "8135",
      "type": "psc_code"
   },
   {
      "label": "8140 - AMMUNITION & NUCLEAR ORDNANCE BOXES",
      "value": "8140",
      "type": "psc_code"
   },
   {
      "label": "8140 - AMMUNITION AND NUCLEAR ORDNANCE BOXES, PACKAGES AND SPECIAL CONTAINERS",
      "value": "8140",
      "type": "psc_code"
   },
   {
      "label": "8145 - SPECIAL SHIPPING & STORAGE CONTAIN",
      "value": "8145",
      "type": "psc_code"
   },
   {
      "label": "8145 - SPECIALIZED SHIPPING AND STORAGE CONTAINERS",
      "value": "8145",
      "type": "psc_code"
   },
   {
      "label": "8150 - FREIGHT CONTAINERS",
      "value": "8150",
      "type": "psc_code"
   },
   {
      "label": "83 - TEXTILE/LEATHER/FUR; TENT; FLAG",
      "value": "83",
      "type": "psc_code"
   },
   {
      "label": "8305 - TEXTILE FABRICS",
      "value": "8305",
      "type": "psc_code"
   },
   {
      "label": "8310 - YARN AND THREAD",
      "value": "8310",
      "type": "psc_code"
   },
   {
      "label": "8310 - YARD AND THREAD",
      "value": "8310",
      "type": "psc_code"
   },
   {
      "label": "8315 - NOTIONS AND APPAREL FINDINGS",
      "value": "8315",
      "type": "psc_code"
   },
   {
      "label": "8320 - PADDING AND STUFFING MATERIALS",
      "value": "8320",
      "type": "psc_code"
   },
   {
      "label": "8325 - FUR MATERIALS",
      "value": "8325",
      "type": "psc_code"
   },
   {
      "label": "8330 - LEATHER",
      "value": "8330",
      "type": "psc_code"
   },
   {
      "label": "8335 - SHOE FINDINGS AND SOLING MATERIALS",
      "value": "8335",
      "type": "psc_code"
   },
   {
      "label": "8340 - TENTS AND TARPAULINS",
      "value": "8340",
      "type": "psc_code"
   },
   {
      "label": "8345 - FLAGS AND PENNANTS",
      "value": "8345",
      "type": "psc_code"
   },
   {
      "label": "84 - CLOTHING/INDIVIDUAL EQPT, INSIGNIA",
      "value": "84",
      "type": "psc_code"
   },
   {
      "label": "84 - CLOTHING, INDIVIDUAL EQUIPMENT, INSIGNA, AND JEWELRY",
      "value": "84",
      "type": "psc_code"
   },
   {
      "label": "8405 - OUTERWEAR, MEN'S",
      "value": "8405",
      "type": "psc_code"
   },
   {
      "label": "8410 - OUTERWEAR, WOMEN'S",
      "value": "8410",
      "type": "psc_code"
   },
   {
      "label": "8415 - CLOTHING, SPECIAL PURPOSE",
      "value": "8415",
      "type": "psc_code"
   },
   {
      "label": "8420 - UNDERWEAR AND NIGHTWEAR, MEN'S",
      "value": "8420",
      "type": "psc_code"
   },
   {
      "label": "8425 - UNDERWEAR AND NIGHTWEAR, WOMEN'S",
      "value": "8425",
      "type": "psc_code"
   },
   {
      "label": "8430 - FOOTWEAR, MEN'S",
      "value": "8430",
      "type": "psc_code"
   },
   {
      "label": "8435 - FOOTWEAR, WOMEN'S",
      "value": "8435",
      "type": "psc_code"
   },
   {
      "label": "8440 - HOSIERY, HANDWEAR, AND CLOTHING ACCESSORIES, MEN'S",
      "value": "8440",
      "type": "psc_code"
   },
   {
      "label": "8440 - HOSIERY HANDWEAR & CLOTHING ACC-MEN",
      "value": "8440",
      "type": "psc_code"
   },
   {
      "label": "8445 - HOSIERY, HANDWEAR, AND CLOTHING ACCESSORIES, WOMEN'S",
      "value": "8445",
      "type": "psc_code"
   },
   {
      "label": "8445 - HOSIERY HANDWEAR-CLOTHING ACC-WOMEN",
      "value": "8445",
      "type": "psc_code"
   },
   {
      "label": "8450 - CHILDREN & INFANTS APPAREL & ACC",
      "value": "8450",
      "type": "psc_code"
   },
   {
      "label": "8450 - CHILDREN'S AND INFANTS' APPAREL AND ACCESSORIES",
      "value": "8450",
      "type": "psc_code"
   },
   {
      "label": "8455 - BADGES AND INSIGNIA",
      "value": "8455",
      "type": "psc_code"
   },
   {
      "label": "8457 - JEWELRY",
      "value": "8457",
      "type": "psc_code"
   },
   {
      "label": "8460 - LUGGAGE",
      "value": "8460",
      "type": "psc_code"
   },
   {
      "label": "8465 - INDIVIDUAL EQUIPMENT",
      "value": "8465",
      "type": "psc_code"
   },
   {
      "label": "8470 - ARMOR, PERSONAL",
      "value": "8470",
      "type": "psc_code"
   },
   {
      "label": "8475 - SPEC FLIGHT CLOTHING & ACCESSORIES",
      "value": "8475",
      "type": "psc_code"
   },
   {
      "label": "8475 - SPECIALIZED FLIGHT CLOTHING AND ACCESSORIES",
      "value": "8475",
      "type": "psc_code"
   },
   {
      "label": "85 - TOILETRIES",
      "value": "85",
      "type": "psc_code"
   },
   {
      "label": "8510 - PERFUMES, TOILET PREPARATIONS, AND POWDERS",
      "value": "8510",
      "type": "psc_code"
   },
   {
      "label": "8510 - PERFUME,TOILET PREPARATION & POWDER",
      "value": "8510",
      "type": "psc_code"
   },
   {
      "label": "8520 - TOILET SOAP,SHAVE PREP & DENTIFRICE",
      "value": "8520",
      "type": "psc_code"
   },
   {
      "label": "8520 - TOILET SOAP, SHAVING PREPARATIONS, AND DENTIFRICES",
      "value": "8520",
      "type": "psc_code"
   },
   {
      "label": "8530 - PERSONAL TOILETRY ARTICLES",
      "value": "8530",
      "type": "psc_code"
   },
   {
      "label": "8540 - TOILETRY PAPER PRODUCTS",
      "value": "8540",
      "type": "psc_code"
   },
   {
      "label": "87 - AGRICULTURAL SUPPLIES",
      "value": "87",
      "type": "psc_code"
   },
   {
      "label": "8710 - FORAGE AND FEED",
      "value": "8710",
      "type": "psc_code"
   },
   {
      "label": "8720 - FERTILIZERS",
      "value": "8720",
      "type": "psc_code"
   },
   {
      "label": "8730 - SEEDS AND NURSERY STOCK",
      "value": "8730",
      "type": "psc_code"
   },
   {
      "label": "88 - LIVE ANIMALS",
      "value": "88",
      "type": "psc_code"
   },
   {
      "label": "8810 - LIVE ANIMALS, RAISED FOR FOOD",
      "value": "8810",
      "type": "psc_code"
   },
   {
      "label": "8820 - LIVE ANIMALS, NOT RAISED FOR FOOD",
      "value": "8820",
      "type": "psc_code"
   },
   {
      "label": "89 - SUBSISTENCE",
      "value": "89",
      "type": "psc_code"
   },
   {
      "label": "8900 - PERISHABLE SUBSISTENCE $10K TO $25K",
      "value": "8900",
      "type": "psc_code"
   },
   {
      "label": "8905 - MEAT, POULTRY, AND FISH",
      "value": "8905",
      "type": "psc_code"
   },
   {
      "label": "8910 - DAIRY FOODS AND EGGS",
      "value": "8910",
      "type": "psc_code"
   },
   {
      "label": "8915 - FRUITS AND VEGETABLES",
      "value": "8915",
      "type": "psc_code"
   },
   {
      "label": "8920 - BAKERY AND CEREAL PRODUCTS",
      "value": "8920",
      "type": "psc_code"
   },
   {
      "label": "8925 - SUGAR, CONFECTIONERY, AND NUTS",
      "value": "8925",
      "type": "psc_code"
   },
   {
      "label": "8930 - JAMS, JELLIES, AND PRESERVES",
      "value": "8930",
      "type": "psc_code"
   },
   {
      "label": "8935 - SOUPS AND BOUILLONS",
      "value": "8935",
      "type": "psc_code"
   },
   {
      "label": "8940 - SPECIAL DIETARY FOOD & SPECIAL PREP",
      "value": "8940",
      "type": "psc_code"
   },
   {
      "label": "8940 - SPECIAL DIETARY FOODS AND FOOD SPECIALTY PREPARATIONS",
      "value": "8940",
      "type": "psc_code"
   },
   {
      "label": "8945 - FOOD, OILS AND FATS",
      "value": "8945",
      "type": "psc_code"
   },
   {
      "label": "8950 - CONDIMENTS AND RELATED PRODUCTS",
      "value": "8950",
      "type": "psc_code"
   },
   {
      "label": "8955 - COFFEE, TEA, AND COCOA",
      "value": "8955",
      "type": "psc_code"
   },
   {
      "label": "8960 - BEVERAGES, NONALCOHOLIC",
      "value": "8960",
      "type": "psc_code"
   },
   {
      "label": "8965 - BEVERAGES, ALCOHOLIC",
      "value": "8965",
      "type": "psc_code"
   },
   {
      "label": "8970 - COMPOSITE FOOD PACKAGES",
      "value": "8970",
      "type": "psc_code"
   },
   {
      "label": "8975 - TOBACCO PRODUCTS",
      "value": "8975",
      "type": "psc_code"
   },
   {
      "label": "8999 - FOOD ITEMS FOR RESALE",
      "value": "8999",
      "type": "psc_code"
   },
   {
      "label": "91 - FUELS, LUBRICANTS, OILS, WAXES",
      "value": "91",
      "type": "psc_code"
   },
   {
      "label": "9110 - FUELS, SOLID",
      "value": "9110",
      "type": "psc_code"
   },
   {
      "label": "9130 - LIQUID PROPELLANTS AND FUELS, PETROLEUM BASE",
      "value": "9130",
      "type": "psc_code"
   },
   {
      "label": "9130 - LIQUID PROPELLANTS -PETROLEUM BASE",
      "value": "9130",
      "type": "psc_code"
   },
   {
      "label": "9135 - LIQUID PROPELLANTS - CHEMICAL BASE",
      "value": "9135",
      "type": "psc_code"
   },
   {
      "label": "9135 - LIQUID PROPELLANT FUELS AND OXIDIZERS, CHEMICAL BASE",
      "value": "9135",
      "type": "psc_code"
   },
   {
      "label": "9140 - FUEL OILS",
      "value": "9140",
      "type": "psc_code"
   },
   {
      "label": "9150 - OIL & GREASE-CUT,LUBR & HYDRAULIC",
      "value": "9150",
      "type": "psc_code"
   },
   {
      "label": "9150 - OILS AND GREASES: CUTTING, LUBRICATING, AND HYDRAULIC",
      "value": "9150",
      "type": "psc_code"
   },
   {
      "label": "9160 - MISCELLANEOUS WAXES, OILS, AND FATS",
      "value": "9160",
      "type": "psc_code"
   },
   {
      "label": "93 - NONMETALLIC FABRICATED MATERIALS",
      "value": "93",
      "type": "psc_code"
   },
   {
      "label": "9310 - PAPER AND PAPERBOARD",
      "value": "9310",
      "type": "psc_code"
   },
   {
      "label": "9320 - RUBBER FABRICATED MATERIALS",
      "value": "9320",
      "type": "psc_code"
   },
   {
      "label": "9330 - PLASTICS FABRICATED MATERIALS",
      "value": "9330",
      "type": "psc_code"
   },
   {
      "label": "9340 - GLASS FABRICATED MATERIALS",
      "value": "9340",
      "type": "psc_code"
   },
   {
      "label": "9350 - REFRACTORIES & FIRE SURFACING MAT",
      "value": "9350",
      "type": "psc_code"
   },
   {
      "label": "9350 - REFRACTORIES AND FIRE SURFACING MATERIALS",
      "value": "9350",
      "type": "psc_code"
   },
   {
      "label": "9390 - MISC FABRICATED NONMETAL MATERIALS",
      "value": "9390",
      "type": "psc_code"
   },
   {
      "label": "9390 - MISCELLANEOUS FABRICATED NONMETALLIC MATERIALS",
      "value": "9390",
      "type": "psc_code"
   },
   {
      "label": "94 - NONMETALLIC CRUDE MATERIALS",
      "value": "94",
      "type": "psc_code"
   },
   {
      "label": "9410 - CRUDE GRADES OF PLANT MATERIALS",
      "value": "9410",
      "type": "psc_code"
   },
   {
      "label": "9420 - FIBERS VEGETABLE ANIMAL & SYNTHETIC",
      "value": "9420",
      "type": "psc_code"
   },
   {
      "label": "9420 - FIBERS: VEGETABLE, ANIMAL, AND SYNTHETIC",
      "value": "9420",
      "type": "psc_code"
   },
   {
      "label": "9430 - MISC CRUDE ANIMAL PRODS - INEDIBLE",
      "value": "9430",
      "type": "psc_code"
   },
   {
      "label": "9430 - MISCELLANEOUS CRUDE ANIMAL PRODUCTS, INEDIBLE",
      "value": "9430",
      "type": "psc_code"
   },
   {
      "label": "9440 - MISC CRUDE AGRI & FORESTRY PROD",
      "value": "9440",
      "type": "psc_code"
   },
   {
      "label": "9440 - MISCELLANEOUS CRUDE AGRICULTURAL AND FORESTRY PRODUCTS",
      "value": "9440",
      "type": "psc_code"
   },
   {
      "label": "9450 - NONMETALLIC SCRAP, EXCEPT TEXTILE",
      "value": "9450",
      "type": "psc_code"
   },
   {
      "label": "95 - METAL BARS, SHEETS, SHAPES",
      "value": "95",
      "type": "psc_code"
   },
   {
      "label": "9505 - WIRE, NONELECTRICAL",
      "value": "9505",
      "type": "psc_code"
   },
   {
      "label": "9510 - BARS AND RODS",
      "value": "9510",
      "type": "psc_code"
   },
   {
      "label": "9515 - PLATE, SHEET, STRIP, FOIL, AND LEAF",
      "value": "9515",
      "type": "psc_code"
   },
   {
      "label": "9520 - STRUCTURAL SHAPES",
      "value": "9520",
      "type": "psc_code"
   },
   {
      "label": "9525 - WIRE, NONELECTRICAL, NONFERROUS BASE METAL",
      "value": "9525",
      "type": "psc_code"
   },
   {
      "label": "9525 - WIRE NONELECT NONFERROUS BASE METAL",
      "value": "9525",
      "type": "psc_code"
   },
   {
      "label": "9530 - BARS AND RODS, NONFERROUS BASE METAL",
      "value": "9530",
      "type": "psc_code"
   },
   {
      "label": "9530 - BARS & RODS NONFERROUS BASE METAL",
      "value": "9530",
      "type": "psc_code"
   },
   {
      "label": "9535 - PLATE SHEET STRIP-NONFERROUS METAL",
      "value": "9535",
      "type": "psc_code"
   },
   {
      "label": "9535 - PLATE, SHEET, STRIP, AND FOIL; NONFERROUS BASE METAL",
      "value": "9535",
      "type": "psc_code"
   },
   {
      "label": "9540 - STRUCTURAL SHAPES, NONFERROUS BASE METAL",
      "value": "9540",
      "type": "psc_code"
   },
   {
      "label": "9540 - STRUCTURAL SHAPES NONFERROUS METAL",
      "value": "9540",
      "type": "psc_code"
   },
   {
      "label": "9545 - PLATE, SHEET, STRIP, FOIL, AND WIRE: PRECIOUS METAL",
      "value": "9545",
      "type": "psc_code"
   },
   {
      "label": "9545 - PLATE,SHEET,STRIP & WIRE-PREC METAL",
      "value": "9545",
      "type": "psc_code"
   },
   {
      "label": "96 - ORES, MINERALS AND PRIMARY PRODUCTS",
      "value": "96",
      "type": "psc_code"
   },
   {
      "label": "9610 - ORES",
      "value": "9610",
      "type": "psc_code"
   },
   {
      "label": "9620 - MINERALS, NATURAL AND SYNTHETIC",
      "value": "9620",
      "type": "psc_code"
   },
   {
      "label": "9630 - ADDITIVE METAL MATERIALS",
      "value": "9630",
      "type": "psc_code"
   },
   {
      "label": "9640 - IRON & STEEL PRIMARY & SEMIFINISHED",
      "value": "9640",
      "type": "psc_code"
   },
   {
      "label": "9640 - IRON AND STEEL PRIMARY AND SEMIFINISHED PRODUCTS",
      "value": "9640",
      "type": "psc_code"
   },
   {
      "label": "9650 - NONFERROUS BASE METAL REFINERY",
      "value": "9650",
      "type": "psc_code"
   },
   {
      "label": "9650 - NONFERROUS BASE METAL REFINERY AND INTERMEDIATE FORMS",
      "value": "9650",
      "type": "psc_code"
   },
   {
      "label": "9660 - PRECIOUS METALS PRIMARY FORMS",
      "value": "9660",
      "type": "psc_code"
   },
   {
      "label": "9670 - IRON AND STEEL SCRAP",
      "value": "9670",
      "type": "psc_code"
   },
   {
      "label": "9680 - NONFERROUS SCRAP",
      "value": "9680",
      "type": "psc_code"
   },
   {
      "label": "99 - MISCELLANEOUS",
      "value": "99",
      "type": "psc_code"
   },
   {
      "label": "9905 - SIGNS,AD DISPLAYS & IDENT PLATES",
      "value": "9905",
      "type": "psc_code"
   },
   {
      "label": "9905 - SIGNS, ADVERTISING DISPLAYS, AND IDENTIFICATION PLATES",
      "value": "9905",
      "type": "psc_code"
   },
   {
      "label": "9910 - JEWELRY",
      "value": "9910",
      "type": "psc_code"
   },
   {
      "label": "9915 - COLLECTORS' AND/OR HISTORICAL ITEMS",
      "value": "9915",
      "type": "psc_code"
   },
   {
      "label": "9915 - COLLECTORS AND/OR HISTORICAL ITEMS",
      "value": "9915",
      "type": "psc_code"
   },
   {
      "label": "9920 - SMOKERS' ARTICLES AND MATCHES",
      "value": "9920",
      "type": "psc_code"
   },
   {
      "label": "9925 - ECCLESIASTICAL EQUIPMENT, FURNISHINGS, AND SUPPLIES",
      "value": "9925",
      "type": "psc_code"
   },
   {
      "label": "9925 - ECCLESIASTICAL EQ FURNISHING & SUP",
      "value": "9925",
      "type": "psc_code"
   },
   {
      "label": "9930 - MEMORIALS-CEMETERY & MORTUARY EQ",
      "value": "9930",
      "type": "psc_code"
   },
   {
      "label": "9930 - MEMORIALS; CEMETERIAL AND MORTUARY EQUIPMENT AND SUPPLIES",
      "value": "9930",
      "type": "psc_code"
   },
   {
      "label": "9998 - NON-FOOD ITEMS FOR RESALE",
      "value": "9998",
      "type": "psc_code"
   },
   {
      "label": "9999 - MISCELLANEOUS ITEMS",
      "value": "9999",
      "type": "psc_code"
   },
   {
      "label": "A - RESEARCH AND DEVELOPMENT",
      "value": "A",
      "type": "psc_code"
   },
   {
      "label": "AA - AGRICULTURE R&D",
      "value": "AA",
      "type": "psc_code"
   },
   {
      "label": "AA - AGRICULTURE R&D SERVICES",
      "value": "AA",
      "type": "psc_code"
   },
   {
      "label": "AA10 - R&D-INSECT & DIS CONT",
      "value": "AA10",
      "type": "psc_code"
   },
   {
      "label": "AA11 - R&D- AGRICULTURE: INSECT AND DISEASE CONTROL (BASIC RESEARCH)",
      "value": "AA11",
      "type": "psc_code"
   },
   {
      "label": "AA11 - R&D-INSECT & DIS CONT-B RES",
      "value": "AA11",
      "type": "psc_code"
   },
   {
      "label": "AA11 - AGRICULTURE R&D SERVICES; AGRICULTURAL RESEARCH AND SERVICES; BASIC RESEARCH",
      "value": "AA11",
      "type": "psc_code"
   },
   {
      "label": "AA12 - R&D- AGRICULTURE: INSECT AND DISEASE CONTROL (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AA12",
      "type": "psc_code"
   },
   {
      "label": "AA12 - R&D-INSECT & DIS CONT-A RES/EXPL DE",
      "value": "AA12",
      "type": "psc_code"
   },
   {
      "label": "AA12 - AGRICULTURE R&D SERVICES; AGRICULTURAL RESEARCH AND SERVICES; APPLIED RESEARCH",
      "value": "AA12",
      "type": "psc_code"
   },
   {
      "label": "AA13 - R&D- AGRICULTURE: INSECT AND DISEASE CONTROL (ADVANCED DEVELOPMENT)",
      "value": "AA13",
      "type": "psc_code"
   },
   {
      "label": "AA13 - R&D-INSECT & DIS CONT-ADV DEV",
      "value": "AA13",
      "type": "psc_code"
   },
   {
      "label": "AA13 - AGRICULTURE R&D SERVICES; AGRICULTURAL RESEARCH AND SERVICES; EXPERIMENTAL DEVELOPMENT",
      "value": "AA13",
      "type": "psc_code"
   },
   {
      "label": "AA14 - R&D- AGRICULTURE: INSECT AND DISEASE CONTROL (ENGINEERING DEVELOPMENT)",
      "value": "AA14",
      "type": "psc_code"
   },
   {
      "label": "AA14 - R&D-INSECT & DIS CONT-ENG DEV",
      "value": "AA14",
      "type": "psc_code"
   },
   {
      "label": "AA14 - AGRICULTURE R&D SERVICES; AGRICULTURAL RESEARCH AND SERVICES; R&D ADMINISTRATIVE EXPENSES",
      "value": "AA14",
      "type": "psc_code"
   },
   {
      "label": "AA15 - R&D- AGRICULTURE: INSECT AND DISEASE CONTROL (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AA15",
      "type": "psc_code"
   },
   {
      "label": "AA15 - R&D-INSECT & DIS CONT-OPSY DEV",
      "value": "AA15",
      "type": "psc_code"
   },
   {
      "label": "AA15 - AGRICULTURE R&D SVCS; AGRICULTURAL RESEARCH & SVCS; R&D FACILITIES & MAJ EQUIP",
      "value": "AA15",
      "type": "psc_code"
   },
   {
      "label": "AA16 - R&D-INSECT & DIS CONT-MGMT SUP",
      "value": "AA16",
      "type": "psc_code"
   },
   {
      "label": "AA16 - R&D- AGRICULTURE: INSECT AND DISEASE CONTROL (MANAGEMENT/SUPPORT)",
      "value": "AA16",
      "type": "psc_code"
   },
   {
      "label": "AA17 - R&D- AGRICULTURE: INSECT AND DISEASE CONTROL (COMMERCIALIZED)",
      "value": "AA17",
      "type": "psc_code"
   },
   {
      "label": "AA17 - R&D-INSECT & DISEASE CNTL-COMERCLIZ",
      "value": "AA17",
      "type": "psc_code"
   },
   {
      "label": "AA20 - R&D-AGRI MARKETING",
      "value": "AA20",
      "type": "psc_code"
   },
   {
      "label": "AA21 - AGRICULTURE MARKETING (BASIC)",
      "value": "AA21",
      "type": "psc_code"
   },
   {
      "label": "AA21 - R&D- AGRICULTURE: MARKETING (BASIC RESEARCH)",
      "value": "AA21",
      "type": "psc_code"
   },
   {
      "label": "AA22 - R&D- AGRICULTURE: MARKETING (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AA22",
      "type": "psc_code"
   },
   {
      "label": "AA22 - R&D-AGRI MARKETING-A RES/EXPL DEV",
      "value": "AA22",
      "type": "psc_code"
   },
   {
      "label": "AA23 - AGRICULTURE MARKETING (ADVANCED)",
      "value": "AA23",
      "type": "psc_code"
   },
   {
      "label": "AA23 - R&D- AGRICULTURE: MARKETING (ADVANCED DEVELOPMENT)",
      "value": "AA23",
      "type": "psc_code"
   },
   {
      "label": "AA24 - AGRICULTURE MARKETING (ENGINEERING)",
      "value": "AA24",
      "type": "psc_code"
   },
   {
      "label": "AA24 - R&D- AGRICULTURE: MARKETING (ENGINEERING DEVELOPMENT)",
      "value": "AA24",
      "type": "psc_code"
   },
   {
      "label": "AA25 - AGRICULTURE MARKETING (OPERATIONAL)",
      "value": "AA25",
      "type": "psc_code"
   },
   {
      "label": "AA25 - R&D- AGRICULTURE: MARKETING (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AA25",
      "type": "psc_code"
   },
   {
      "label": "AA26 - R&D- AGRICULTURE: MARKETING (MANAGEMENT/SUPPORT)",
      "value": "AA26",
      "type": "psc_code"
   },
   {
      "label": "AA26 - R&D-AGRI MARKETING-MGMT SUP",
      "value": "AA26",
      "type": "psc_code"
   },
   {
      "label": "AA27 - R&D- AGRICULTURE: MARKETING (COMMERCIALIZED)",
      "value": "AA27",
      "type": "psc_code"
   },
   {
      "label": "AA27 - R&D-AGRI MARKETING-COMERCLIZ",
      "value": "AA27",
      "type": "psc_code"
   },
   {
      "label": "AA30 - R&D-AGRI PRODUCTION",
      "value": "AA30",
      "type": "psc_code"
   },
   {
      "label": "AA31 - AGRICULTURE PRODUCTION (BASIC)",
      "value": "AA31",
      "type": "psc_code"
   },
   {
      "label": "AA31 - R&D- AGRICULTURE: PRODUCTION (BASIC RESEARCH)",
      "value": "AA31",
      "type": "psc_code"
   },
   {
      "label": "AA32 - R&D- AGRICULTURE: PRODUCTION (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AA32",
      "type": "psc_code"
   },
   {
      "label": "AA32 - R&D-AGRI PRODUCTION-A RES/EXPL DEV",
      "value": "AA32",
      "type": "psc_code"
   },
   {
      "label": "AA33 - R&D- AGRICULTURE: PRODUCTION (ADVANCED DEVELOPMENT)",
      "value": "AA33",
      "type": "psc_code"
   },
   {
      "label": "AA33 - AGRICULTURE PRODUCTION (ADVANCED)",
      "value": "AA33",
      "type": "psc_code"
   },
   {
      "label": "AA34 - R&D-AGRI PRODUCTION-ENG DEV",
      "value": "AA34",
      "type": "psc_code"
   },
   {
      "label": "AA34 - R&D- AGRICULTURE: PRODUCTION (ENGINEERING DEVELOPMENT)",
      "value": "AA34",
      "type": "psc_code"
   },
   {
      "label": "AA35 - R&D-AGRI PRODUCTION-OPSY DEV",
      "value": "AA35",
      "type": "psc_code"
   },
   {
      "label": "AA35 - R&D- AGRICULTURE: PRODUCTION (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AA35",
      "type": "psc_code"
   },
   {
      "label": "AA36 - R&D- AGRICULTURE: PRODUCTION (MANAGEMENT/SUPPORT)",
      "value": "AA36",
      "type": "psc_code"
   },
   {
      "label": "AA36 - R&D-AGRI PRODUCTION-MGMT SUP",
      "value": "AA36",
      "type": "psc_code"
   },
   {
      "label": "AA37 - R&D- AGRICULTURE: PRODUCTION (COMMERCIALIZED)",
      "value": "AA37",
      "type": "psc_code"
   },
   {
      "label": "AA37 - R&D-AGRI PRODUCTION-COMERCLIZ",
      "value": "AA37",
      "type": "psc_code"
   },
   {
      "label": "",
      "value": "AA40",
      "type": "psc_code"
   },
   {
      "label": "AA41 - R&D-AQUACUL/HYDROPONICS-B RES",
      "value": "AA41",
      "type": "psc_code"
   },
   {
      "label": "AA42 - R&D-AQUACUL/HYDROPONICS-A RES/EXPL",
      "value": "AA42",
      "type": "psc_code"
   },
   {
      "label": "AA43 - R&D-AQUACUL/HYDROPONICS-ADV DEV",
      "value": "AA43",
      "type": "psc_code"
   },
   {
      "label": "AA44 - R&D-AQUACUL/HYDROPONICS-ENG DEV",
      "value": "AA44",
      "type": "psc_code"
   },
   {
      "label": "AA45 - R&D-AQUACUL/HYDROPONICS-OPSY DEV",
      "value": "AA45",
      "type": "psc_code"
   },
   {
      "label": "AA46 - R&D-AQUACUL/HYDROPONICS-MGMT SUP",
      "value": "AA46",
      "type": "psc_code"
   },
   {
      "label": "AA47 - RDTE/AQUACULTURE & HYDROPONICS-OP SYSTEM DEVELOP",
      "value": "AA47",
      "type": "psc_code"
   },
   {
      "label": "AA90 - R&D-OTHER AGRI",
      "value": "AA90",
      "type": "psc_code"
   },
   {
      "label": "AA91 - OTHER AGRICULTURE (BASIC)",
      "value": "AA91",
      "type": "psc_code"
   },
   {
      "label": "AA91 - R&D- AGRICULTURE: OTHER (BASIC RESEARCH)",
      "value": "AA91",
      "type": "psc_code"
   },
   {
      "label": "AA92 - R&D- AGRICULTURE: OTHER (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AA92",
      "type": "psc_code"
   },
   {
      "label": "AA92 - R&D-OTHER AGRI-A RES/EXPL DEV",
      "value": "AA92",
      "type": "psc_code"
   },
   {
      "label": "AA93 - OTHER AGRICULTURE (ADVANCED)",
      "value": "AA93",
      "type": "psc_code"
   },
   {
      "label": "AA93 - R&D- AGRICULTURE: OTHER (ADVANCED DEVELOPMENT)",
      "value": "AA93",
      "type": "psc_code"
   },
   {
      "label": "AA94 - OTHER AGRICULTURE (ENGINEERING)",
      "value": "AA94",
      "type": "psc_code"
   },
   {
      "label": "AA94 - R&D- AGRICULTURE: OTHER (ENGINEERING DEVELOPMENT)",
      "value": "AA94",
      "type": "psc_code"
   },
   {
      "label": "AA95 - OTHER AGRICULTURE (OPERATIONAL)",
      "value": "AA95",
      "type": "psc_code"
   },
   {
      "label": "AA95 - R&D- AGRICULTURE: OTHER (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AA95",
      "type": "psc_code"
   },
   {
      "label": "AA96 - R&D- AGRICULTURE: OTHER (MANAGEMENT/SUPPORT)",
      "value": "AA96",
      "type": "psc_code"
   },
   {
      "label": "AA96 - R&D-OTHER AGRI-MGMT SUP",
      "value": "AA96",
      "type": "psc_code"
   },
   {
      "label": "AA97 - R&D- AGRICULTURE: OTHER (COMMERCIALIZED)",
      "value": "AA97",
      "type": "psc_code"
   },
   {
      "label": "AA97 - R&D-OTHER AGRI-COMERCLIZ",
      "value": "AA97",
      "type": "psc_code"
   },
   {
      "label": "AB - COMMUNITY SERVICE/DEVELOPMENT R&D",
      "value": "AB",
      "type": "psc_code"
   },
   {
      "label": "AB - Community and Regional Development R&D Services",
      "value": "AB",
      "type": "psc_code"
   },
   {
      "label": "AB10 - R&D-CRIME PREVENT & CONT",
      "value": "AB10",
      "type": "psc_code"
   },
   {
      "label": "AB11 - R&D- COMMUNITY SVC/DEVELOP: CRIME PREVENTION/CONTROL (BASIC RESEARCH)",
      "value": "AB11",
      "type": "psc_code"
   },
   {
      "label": "AB11 - R&D-CRIME PREVENT & CONT-B RES",
      "value": "AB11",
      "type": "psc_code"
   },
   {
      "label": "AB11 - COMMUNITY AND REGIONAL DEVELOPMENT R&D SERVICES; COMMUNITY DEVELOPMENT; BASIC RESEARCH",
      "value": "AB11",
      "type": "psc_code"
   },
   {
      "label": "AB12 - R&D- COMMUNITY SVC/DEVELOP: CRIME PREVENTION/CONTROL (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AB12",
      "type": "psc_code"
   },
   {
      "label": "AB12 - R&D-CRIME PREVENT & CONT-A RES/EXPL",
      "value": "AB12",
      "type": "psc_code"
   },
   {
      "label": "AB12 - COMMUNITY AND REGIONAL DEVELOPMENT R&D SERVICES; COMMUNITY DEVELOPMENT; APPLIED RESEARCH",
      "value": "AB12",
      "type": "psc_code"
   },
   {
      "label": "AB13 - R&D-CRIME PREVENT & CONT-ADV DEV",
      "value": "AB13",
      "type": "psc_code"
   },
   {
      "label": "AB13 - R&D- COMMUNITY SVC/DEVELOP: CRIME PREVENTION/CONTROL (ADVANCED DEVELOPMENT)",
      "value": "AB13",
      "type": "psc_code"
   },
   {
      "label": "AB13 - COMMUNITY AND REGIONAL DEVELOPMENT R&D SERVICES; COMMUNITY DEVELOPMENT; EXPERIMENTAL DEVELOPMENT",
      "value": "AB13",
      "type": "psc_code"
   },
   {
      "label": "AB14 - R&D- COMMUNITY SVC/DEVELOP: CRIME PREVENTION/CONTROL (ENGINEERING DEVELOPMENT)",
      "value": "AB14",
      "type": "psc_code"
   },
   {
      "label": "AB14 - R&D-CRIME PREVENT & CONT-ENG DEV",
      "value": "AB14",
      "type": "psc_code"
   },
   {
      "label": "AB14 - COMMUNITY AND REGIONAL DEVELOPMENT R&D SERVICES; COMMUNITY DEVELOPMENT; R&D ADMINISTRATIVE EXPENSES",
      "value": "AB14",
      "type": "psc_code"
   },
   {
      "label": "AB15 - R&D-CRIME PREVENT & CONT-OPSY DEV",
      "value": "AB15",
      "type": "psc_code"
   },
   {
      "label": "AB15 - R&D- COMMUNITY SVC/DEVELOP: CRIME PREVENTION/CONTROL (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AB15",
      "type": "psc_code"
   },
   {
      "label": "AB15 - COMMUNITY & REGIONAL DEVELOPMENT R&D SVCS; COMMUNITY DEVELOPMENT; R&D FACILITIES & MAJ EQUIP",
      "value": "AB15",
      "type": "psc_code"
   },
   {
      "label": "AB16 - R&D-CRIME PREVENT & CONT-MGMT SUP",
      "value": "AB16",
      "type": "psc_code"
   },
   {
      "label": "AB16 - R&D- COMMUNITY SVC/DEVELOP: CRIME PREVENTION/CONTROL (MANAGEMENT/SUPPORT)",
      "value": "AB16",
      "type": "psc_code"
   },
   {
      "label": "AB17 - R&D-CRIME PREVENT & CONT-COMERCLIZ",
      "value": "AB17",
      "type": "psc_code"
   },
   {
      "label": "AB17 - R&D- COMMUNITY SVC/DEVELOP: CRIME PREVENTION/CONTROL (COMMERCIALIZED)",
      "value": "AB17",
      "type": "psc_code"
   },
   {
      "label": "AB20 - R&D-FIRE PREVENT & CONT",
      "value": "AB20",
      "type": "psc_code"
   },
   {
      "label": "AB21 - FIRE PREVENTION AND CONTROL (BASIC)",
      "value": "AB21",
      "type": "psc_code"
   },
   {
      "label": "AB21 - R&D- COMMUNITY SVC/DEVELOP: FIRE PREVENTION/CONTROL (BASIC RESEARCH)",
      "value": "AB21",
      "type": "psc_code"
   },
   {
      "label": "AB21 - COMMUNITY AND REGIONAL DEVELOPMENT R&D SERVICES; AREA AND REGIONAL DEVELOPMENT; BASIC RESEARCH",
      "value": "AB21",
      "type": "psc_code"
   },
   {
      "label": "AB22 - R&D- COMMUNITY SVC/DEVELOP: FIRE PREVENTION/CONTROL (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AB22",
      "type": "psc_code"
   },
   {
      "label": "AB22 - R&D-FIRE PREVENT & CONT-A RES/EXPL",
      "value": "AB22",
      "type": "psc_code"
   },
   {
      "label": "AB22 - COMMUNITY AND REGIONAL DEVELOPMENT R&D SERVICES; AREA AND REGIONAL DEVELOPMENT; APPLIED RESEARCH",
      "value": "AB22",
      "type": "psc_code"
   },
   {
      "label": "AB23 - R&D- COMMUNITY SVC/DEVELOP: FIRE PREVENTION/CONTROL (ADVANCED DEVELOPMENT)",
      "value": "AB23",
      "type": "psc_code"
   },
   {
      "label": "AB23 - R&D-FIRE PREVENT & CONT-ADV DEV",
      "value": "AB23",
      "type": "psc_code"
   },
   {
      "label": "AB23 - COMMUNITY & REGIONAL DEVELOPMENT R&D SVCS; AREA & REGIONAL DEVELOPMENT; EXPERIMENTAL DEVELOPMENT",
      "value": "AB23",
      "type": "psc_code"
   },
   {
      "label": "AB24 - R&D- COMMUNITY SVC/DEVELOP: FIRE PREVENTION/CONTROL (ENGINEERING DEVELOPMENT)",
      "value": "AB24",
      "type": "psc_code"
   },
   {
      "label": "AB24 - R&D-FIRE PREVENT & CONT-ENG DEV",
      "value": "AB24",
      "type": "psc_code"
   },
   {
      "label": "AB24 - COMMUNITY & REGIONAL DEVELOPMENT R&D SVCS; AREA & REGIONAL DEVELOPMENT; R&D ADMINISTRATIVE EXPENSES",
      "value": "AB24",
      "type": "psc_code"
   },
   {
      "label": "AB25 - R&D- COMMUNITY SVC/DEVELOP: FIRE PREVENTION/CONTROL (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AB25",
      "type": "psc_code"
   },
   {
      "label": "AB25 - R&D-FIRE PREVENT & CONT-OPSY DEV",
      "value": "AB25",
      "type": "psc_code"
   },
   {
      "label": "AB25 - COMMUNITY & REGIONAL DEVELOPMENT R&D SVCS; AREA & REGIONAL DEVELOPMENT; R&D FACILITIES & MAJ EQUIP",
      "value": "AB25",
      "type": "psc_code"
   },
   {
      "label": "AB26 - R&D- COMMUNITY SVC/DEVELOP: FIRE PREVENTION/CONTROL (MANAGEMENT/SUPPORT)",
      "value": "AB26",
      "type": "psc_code"
   },
   {
      "label": "AB26 - R&D-FIRE PREVENT & CONT-MGMT SUP",
      "value": "AB26",
      "type": "psc_code"
   },
   {
      "label": "AB27 - R&D-FIRE PREVENT & CONT-COMERCLIZ",
      "value": "AB27",
      "type": "psc_code"
   },
   {
      "label": "AB27 - R&D- COMMUNITY SVC/DEVELOP: FIRE PREVENTION/CONTROL (COMMERCIALIZED)",
      "value": "AB27",
      "type": "psc_code"
   },
   {
      "label": "AB30 - R&D-RURAL SVCS & DEVELOP",
      "value": "AB30",
      "type": "psc_code"
   },
   {
      "label": "AB31 - R&D- COMMUNITY SERVICE/DEVELOPMENT: RURAL (BASIC RESEARCH)",
      "value": "AB31",
      "type": "psc_code"
   },
   {
      "label": "AB31 - R&D-RURAL SVCS & DEVELOP-B RES",
      "value": "AB31",
      "type": "psc_code"
   },
   {
      "label": "AB32 - R&D-RURAL SVCS & DEVELOP-A RES/EXPL",
      "value": "AB32",
      "type": "psc_code"
   },
   {
      "label": "AB32 - R&D- COMMUNITY SERVICE/DEVELOPMENT: RURAL (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AB32",
      "type": "psc_code"
   },
   {
      "label": "AB33 - R&D- COMMUNITY SERVICE/DEVELOPMENT: RURAL (ADVANCED DEVELOPMENT)",
      "value": "AB33",
      "type": "psc_code"
   },
   {
      "label": "AB33 - R&D-RURAL SVCS & DEVELOP-ADV DEV",
      "value": "AB33",
      "type": "psc_code"
   },
   {
      "label": "AB34 - R&D- COMMUNITY SERVICE/DEVELOPMENT: RURAL (ENGINEERING DEVELOPMENT)",
      "value": "AB34",
      "type": "psc_code"
   },
   {
      "label": "AB34 - R&D-RURAL SVCS & DEVELOP-ENG DEV",
      "value": "AB34",
      "type": "psc_code"
   },
   {
      "label": "AB35 - R&D-RURAL SVCS & DEVELOP-OPSY DEV",
      "value": "AB35",
      "type": "psc_code"
   },
   {
      "label": "AB35 - R&D- COMMUNITY SERVICE/DEVELOPMENT: RURAL (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AB35",
      "type": "psc_code"
   },
   {
      "label": "AB36 - R&D-RURAL SVCS & DEVELOP-MGMT SUP",
      "value": "AB36",
      "type": "psc_code"
   },
   {
      "label": "AB36 - R&D- COMMUNITY SERVICE/DEVELOPMENT: RURAL (MANAGEMENT/SUPPORT)",
      "value": "AB36",
      "type": "psc_code"
   },
   {
      "label": "AB37 - R&D-RURAL SVCS & DEVELOP-COMERCLIZ",
      "value": "AB37",
      "type": "psc_code"
   },
   {
      "label": "AB37 - R&D- COMMUNITY SERVICE/DEVELOPMENT: RURAL (COMMERCIALIZED)",
      "value": "AB37",
      "type": "psc_code"
   },
   {
      "label": "AB40 - R&D-URBAN SVCS & DEVELOP",
      "value": "AB40",
      "type": "psc_code"
   },
   {
      "label": "AB41 - R&D-URBAN SVCS & DEVELOP-B RES",
      "value": "AB41",
      "type": "psc_code"
   },
   {
      "label": "AB41 - R&D- COMMUNITY SERVICE/DEVELOPMENT: URBAN (BASIC RESEARCH)",
      "value": "AB41",
      "type": "psc_code"
   },
   {
      "label": "AB42 - R&D-URBAN SVCS & DEVELOP-A RES/EXPL",
      "value": "AB42",
      "type": "psc_code"
   },
   {
      "label": "AB42 - R&D- COMMUNITY SERVICE/DEVELOPMENT: URBAN (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AB42",
      "type": "psc_code"
   },
   {
      "label": "AB43 - R&D- COMMUNITY SERVICE/DEVELOPMENT: URBAN (ADVANCED DEVELOPMENT)",
      "value": "AB43",
      "type": "psc_code"
   },
   {
      "label": "AB43 - R&D-URBAN SVCS & DEVELOP-ADV DEV",
      "value": "AB43",
      "type": "psc_code"
   },
   {
      "label": "AB44 - R&D- COMMUNITY SERVICE/DEVELOPMENT: URBAN (ENGINEERING DEVELOPMENT)",
      "value": "AB44",
      "type": "psc_code"
   },
   {
      "label": "AB44 - R&D-URBAN SVCS & DEVELOP-ENG DEV",
      "value": "AB44",
      "type": "psc_code"
   },
   {
      "label": "AB45 - R&D-URBAN SVCS & DEVELOP-OPSY DEV",
      "value": "AB45",
      "type": "psc_code"
   },
   {
      "label": "AB45 - R&D- COMMUNITY SERVICE/DEVELOPMENT: URBAN (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AB45",
      "type": "psc_code"
   },
   {
      "label": "AB46 - R&D- COMMUNITY SERVICE/DEVELOPMENT: URBAN (MANAGEMENT/SUPPORT)",
      "value": "AB46",
      "type": "psc_code"
   },
   {
      "label": "AB46 - R&D-URBAN SVCS & DEVELOP-MGMT SUP",
      "value": "AB46",
      "type": "psc_code"
   },
   {
      "label": "AB47 - R&D- COMMUNITY SERVICE/DEVELOPMENT: URBAN (COMMERCIALIZED)",
      "value": "AB47",
      "type": "psc_code"
   },
   {
      "label": "AB47 - R&D-URBAN SVCS & DEVELOP-COMERCLIZ",
      "value": "AB47",
      "type": "psc_code"
   },
   {
      "label": "AB90 - R&D-OTHER SVC & DEVELOP",
      "value": "AB90",
      "type": "psc_code"
   },
   {
      "label": "AB91 - R&D- COMMUNITY SERVICE/DEVELOPMENT: OTHER (BASIC RESEARCH)",
      "value": "AB91",
      "type": "psc_code"
   },
   {
      "label": "AB91 - R&D-OTHER SVC & DEVELOP-B RES",
      "value": "AB91",
      "type": "psc_code"
   },
   {
      "label": "AB92 - R&D- COMMUNITY SERVICE/DEVELOPMENT: OTHER (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AB92",
      "type": "psc_code"
   },
   {
      "label": "AB92 - R&D-OTHER SVC & DEVELOP-A RES/EXPL",
      "value": "AB92",
      "type": "psc_code"
   },
   {
      "label": "AB93 - R&D-OTHER SVC & DEVELOP-ADV DEV",
      "value": "AB93",
      "type": "psc_code"
   },
   {
      "label": "AB93 - R&D- COMMUNITY SERVICE/DEVELOPMENT: OTHER (ADVANCED DEVELOPMENT)",
      "value": "AB93",
      "type": "psc_code"
   },
   {
      "label": "AB94 - R&D-OTHER SVC & DEVELOP-ENG DEV",
      "value": "AB94",
      "type": "psc_code"
   },
   {
      "label": "AB94 - R&D- COMMUNITY SERVICE/DEVELOPMENT: OTHER (ENGINEERING DEVELOPMENT)",
      "value": "AB94",
      "type": "psc_code"
   },
   {
      "label": "AB95 - R&D- COMMUNITY SERVICE/DEVELOPMENT: OTHER (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AB95",
      "type": "psc_code"
   },
   {
      "label": "AB95 - R&D-OTHER SVC & DEVELOP-OPSY DEV",
      "value": "AB95",
      "type": "psc_code"
   },
   {
      "label": "AB96 - R&D-OTHER SVC & DEVELOP-MGMT SUP",
      "value": "AB96",
      "type": "psc_code"
   },
   {
      "label": "AB96 - R&D- COMMUNITY SERVICE/DEVELOPMENT: OTHER (MANAGEMENT/SUPPORT)",
      "value": "AB96",
      "type": "psc_code"
   },
   {
      "label": "AB97 - R&D-OTHER SVC & DEVELOP-COMERCLIZ",
      "value": "AB97",
      "type": "psc_code"
   },
   {
      "label": "AB97 - R&D- COMMUNITY SERVICE/DEVELOPMENT: OTHER (COMMERCIALIZED)",
      "value": "AB97",
      "type": "psc_code"
   },
   {
      "label": "AC - DEFENSE SYSTEMS R&D",
      "value": "AC",
      "type": "psc_code"
   },
   {
      "label": "AC - C \u2013 National Defense R&D Services",
      "value": "AC",
      "type": "psc_code"
   },
   {
      "label": "AC10 - R&D-AIRCRAFT",
      "value": "AC10",
      "type": "psc_code"
   },
   {
      "label": "AC11 - R&D- DEFENSE SYSTEM: AIRCRAFT (BASIC RESEARCH)",
      "value": "AC11",
      "type": "psc_code"
   },
   {
      "label": "AC11 - DEFENSE AIRCRAFT (BASIC)",
      "value": "AC11",
      "type": "psc_code"
   },
   {
      "label": "AC11 - NATIONAL DEFENSE R&D SERVICES; DEPARTMENT OF DEFENSE - MILITARY; BASIC RESEARCH",
      "value": "AC11",
      "type": "psc_code"
   },
   {
      "label": "AC12 - R&D- DEFENSE SYSTEM: AIRCRAFT (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AC12",
      "type": "psc_code"
   },
   {
      "label": "AC12 - R&D-AIRCRAFT-A RES/EXPL DEV",
      "value": "AC12",
      "type": "psc_code"
   },
   {
      "label": "AC12 - NATIONAL DEFENSE R&D SERVICES; DEPARTMENT OF DEFENSE - MILITARY; APPLIED RESEARCH",
      "value": "AC12",
      "type": "psc_code"
   },
   {
      "label": "AC13 - R&D- DEFENSE SYSTEM: AIRCRAFT (ADVANCED DEVELOPMENT)",
      "value": "AC13",
      "type": "psc_code"
   },
   {
      "label": "AC13 - DEFENSE AIRCRAFT (ADVANCED)",
      "value": "AC13",
      "type": "psc_code"
   },
   {
      "label": "AC13 - NATIONAL DEFENSE R&D SERVICES; DEPARTMENT OF DEFENSE - MILITARY; EXPERIMENTAL DEVELOPMENT",
      "value": "AC13",
      "type": "psc_code"
   },
   {
      "label": "AC14 - R&D- DEFENSE SYSTEM: AIRCRAFT (ENGINEERING DEVELOPMENT)",
      "value": "AC14",
      "type": "psc_code"
   },
   {
      "label": "AC14 - DEFENSE AIRCRAFT (ENGINEERING)",
      "value": "AC14",
      "type": "psc_code"
   },
   {
      "label": "AC14 - NATIONAL DEFENSE R&D SERVICES; DEPARTMENT OF DEFENSE - MILITARY; R&D ADMINISTRATIVE EXPENSES",
      "value": "AC14",
      "type": "psc_code"
   },
   {
      "label": "AC15 - DEFENSE AIRCRAFT (OPERATIONAL)",
      "value": "AC15",
      "type": "psc_code"
   },
   {
      "label": "AC15 - R&D- DEFENSE SYSTEM: AIRCRAFT (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AC15",
      "type": "psc_code"
   },
   {
      "label": "AC15 - NATIONAL DEFENSE R&D SVCS; DEPARTMENT OF DEFENSE - MILITARY; R&D FACILITIES & MAJ EQUIP",
      "value": "AC15",
      "type": "psc_code"
   },
   {
      "label": "AC16 - R&D-AIRCRAFT-MGMT SUP",
      "value": "AC16",
      "type": "psc_code"
   },
   {
      "label": "AC16 - R&D- DEFENSE SYSTEM: AIRCRAFT (MANAGEMENT/SUPPORT)",
      "value": "AC16",
      "type": "psc_code"
   },
   {
      "label": "AC17 - R&D- DEFENSE SYSTEM: AIRCRAFT (COMMERCIALIZED)",
      "value": "AC17",
      "type": "psc_code"
   },
   {
      "label": "AC17 - R&D-AIRCRAFT-COMERCLIZ",
      "value": "AC17",
      "type": "psc_code"
   },
   {
      "label": "AC20 - R&D-MISSILE & SPACE SYS",
      "value": "AC20",
      "type": "psc_code"
   },
   {
      "label": "AC21 - R&D-MISSILE & SPACE SYS-B RES",
      "value": "AC21",
      "type": "psc_code"
   },
   {
      "label": "AC21 - R&D- DEFENSE SYSTEM: MISSILE/SPACE SYSTEMS (BASIC RESEARCH)",
      "value": "AC21",
      "type": "psc_code"
   },
   {
      "label": "AC21 - NATIONAL DEFENSE R&D SERVICES; ATOMIC ENERGY DEFENSE ACTIVITIES; BASIC RESEARCH",
      "value": "AC21",
      "type": "psc_code"
   },
   {
      "label": "AC22 - R&D- DEFENSE SYSTEM: MISSILE/SPACE SYSTEMS (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AC22",
      "type": "psc_code"
   },
   {
      "label": "AC22 - R&D-MISSILE & SPACE SYS-A RES/EXPL",
      "value": "AC22",
      "type": "psc_code"
   },
   {
      "label": "AC22 - NATIONAL DEFENSE R&D SERVICES; ATOMIC ENERGY DEFENSE ACTIVITIES; APPLIED RESEARCH",
      "value": "AC22",
      "type": "psc_code"
   },
   {
      "label": "AC23 - R&D-MISSILE & SPACE SYS-ADV DEV",
      "value": "AC23",
      "type": "psc_code"
   },
   {
      "label": "AC23 - NATIONAL DEFENSE R&D SERVICES; ATOMIC ENERGY DEFENSE ACTIVITIES; EXPERIMENTAL DEVELOPMENT",
      "value": "AC23",
      "type": "psc_code"
   },
   {
      "label": "AC24 - R&D-MISSILE & SPACE SYS-ENG DEV",
      "value": "AC24",
      "type": "psc_code"
   },
   {
      "label": "AC24 - R&D- DEFENSE SYSTEM: MISSILE/SPACE SYSTEMS (ENGINEERING DEVELOPMENT)",
      "value": "AC24",
      "type": "psc_code"
   },
   {
      "label": "AC24 - NATIONAL DEFENSE R&D SERVICES; ATOMIC ENERGY DEFENSE ACTIVITIES; R&D ADMINISTRATIVE EXPENSES",
      "value": "AC24",
      "type": "psc_code"
   },
   {
      "label": "AC25 - R&D- DEFENSE SYSTEM: MISSILE/SPACE SYSTEMS (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AC25",
      "type": "psc_code"
   },
   {
      "label": "AC25 - R&D-MISSILE & SPACE SYS-OPSY DEV",
      "value": "AC25",
      "type": "psc_code"
   },
   {
      "label": "AC25 - NATIONAL DEFENSE R&D SVCS; ATOMIC ENERGY DEFENSE ACTIVITIES; R&D FACILITIES & MAJ EQUIP",
      "value": "AC25",
      "type": "psc_code"
   },
   {
      "label": "AC26 - R&D- DEFENSE SYSTEM: MISSILE/SPACE SYSTEMS (MANAGEMENT/SUPPORT)",
      "value": "AC26",
      "type": "psc_code"
   },
   {
      "label": "AC26 - R&D-MISSILE & SPACE SYS-MGMT SUP",
      "value": "AC26",
      "type": "psc_code"
   },
   {
      "label": "AC27 - R&D-MISSILE & SPACE SYS-COMERCLIZ",
      "value": "AC27",
      "type": "psc_code"
   },
   {
      "label": "AC27 - R&D- DEFENSE SYSTEM: MISSILE/SPACE SYSTEMS (COMMERCIALIZED)",
      "value": "AC27",
      "type": "psc_code"
   },
   {
      "label": "AC30 - R&D-SHIPS",
      "value": "AC30",
      "type": "psc_code"
   },
   {
      "label": "AC31 - DEFENSE SHIPS (BASIC)",
      "value": "AC31",
      "type": "psc_code"
   },
   {
      "label": "AC31 - R&D- DEFENSE SYSTEM: SHIPS (BASIC RESEARCH)",
      "value": "AC31",
      "type": "psc_code"
   },
   {
      "label": "AC31 - NATIONAL DEFENSE R&D SERVICES; DEFENSE-RELATED ACTIVITIES; BASIC RESEARCH",
      "value": "AC31",
      "type": "psc_code"
   },
   {
      "label": "AC32 - R&D- DEFENSE SYSTEM: SHIPS (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AC32",
      "type": "psc_code"
   },
   {
      "label": "AC32 - DEFENSE SHIPS (APPLIED/EXPLORATORY)",
      "value": "AC32",
      "type": "psc_code"
   },
   {
      "label": "AC32 - NATIONAL DEFENSE R&D SERVICES; DEFENSE-RELATED ACTIVITIES; APPLIED RESEARCH",
      "value": "AC32",
      "type": "psc_code"
   },
   {
      "label": "AC33 - DEFENSE SHIPS (ADVANCED)",
      "value": "AC33",
      "type": "psc_code"
   },
   {
      "label": "AC33 - R&D- DEFENSE SYSTEM: SHIPS (ADVANCED DEVELOPMENT)",
      "value": "AC33",
      "type": "psc_code"
   },
   {
      "label": "AC33 - NATIONAL DEFENSE R&D SERVICES; DEFENSE-RELATED ACTIVITIES; EXPERIMENTAL DEVELOPMENT",
      "value": "AC33",
      "type": "psc_code"
   },
   {
      "label": "AC34 - R&D- DEFENSE SYSTEM: SHIPS (ENGINEERING DEVELOPMENT)",
      "value": "AC34",
      "type": "psc_code"
   },
   {
      "label": "AC34 - DEFENSE SHIPS (ENGINEERING)",
      "value": "AC34",
      "type": "psc_code"
   },
   {
      "label": "AC34 - NATIONAL DEFENSE R&D SERVICES; DEFENSE-RELATED ACTIVITIES; R&D ADMINISTRATIVE EXPENSES",
      "value": "AC34",
      "type": "psc_code"
   },
   {
      "label": "AC35 - R&D- DEFENSE SYSTEM: SHIPS (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AC35",
      "type": "psc_code"
   },
   {
      "label": "AC35 - DEFENSE SHIPS (OPERATIONAL)",
      "value": "AC35",
      "type": "psc_code"
   },
   {
      "label": "AC35 - NATIONAL DEFENSE R&D SVCS; DEFENSE-RELATED ACTIVITIES; R&D FACILITIES & MAJ EQUIP",
      "value": "AC35",
      "type": "psc_code"
   },
   {
      "label": "AC36 - DEFENSE SHIPS (MANAGEMENT/SUPPORT)",
      "value": "AC36",
      "type": "psc_code"
   },
   {
      "label": "AC36 - R&D- DEFENSE SYSTEM: SHIPS (MANAGEMENT/SUPPORT)",
      "value": "AC36",
      "type": "psc_code"
   },
   {
      "label": "AC37 - R&D- DEFENSE SYSTEM: SHIPS (COMMERCIALIZED)",
      "value": "AC37",
      "type": "psc_code"
   },
   {
      "label": "AC37 - R&D-SHIPS-COMERCLIZ",
      "value": "AC37",
      "type": "psc_code"
   },
   {
      "label": "AC40 - R&D-TANK - AUTOMOTIVE",
      "value": "AC40",
      "type": "psc_code"
   },
   {
      "label": "AC41 - DEFENSE TANK - AUTOMOTIVE (BASIC)",
      "value": "AC41",
      "type": "psc_code"
   },
   {
      "label": "AC41 - R&D- DEFENSE SYSTEM: TANK/AUTOMOTIVE (BASIC RESEARCH)",
      "value": "AC41",
      "type": "psc_code"
   },
   {
      "label": "AC42 - R&D- DEFENSE SYSTEM: TANK/AUTOMOTIVE (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AC42",
      "type": "psc_code"
   },
   {
      "label": "AC42 - R&D-TANK - AUTOMOTIVE-A RES/EXPL DE",
      "value": "AC42",
      "type": "psc_code"
   },
   {
      "label": "AC43 - R&D-TANK - AUTOMOTIVE-ADV DEV",
      "value": "AC43",
      "type": "psc_code"
   },
   {
      "label": "AC43 - R&D- DEFENSE SYSTEM: TANK/AUTOMOTIVE (ADVANCED DEVELOPMENT)",
      "value": "AC43",
      "type": "psc_code"
   },
   {
      "label": "AC44 - R&D- DEFENSE SYSTEM: TANK/AUTOMOTIVE (ENGINEERING DEVELOPMENT)",
      "value": "AC44",
      "type": "psc_code"
   },
   {
      "label": "AC44 - R&D-TANK - AUTOMOTIVE-ENG DEV",
      "value": "AC44",
      "type": "psc_code"
   },
   {
      "label": "AC45 - R&D- DEFENSE SYSTEM: TANK/AUTOMOTIVE (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AC45",
      "type": "psc_code"
   },
   {
      "label": "AC45 - R&D-TANK - AUTOMOTIVE-OPSY DEV",
      "value": "AC45",
      "type": "psc_code"
   },
   {
      "label": "AC46 - R&D-TANK - AUTOMOTIVE-MGMT SUP",
      "value": "AC46",
      "type": "psc_code"
   },
   {
      "label": "AC46 - R&D- DEFENSE SYSTEM: TANK/AUTOMOTIVE (MANAGEMENT/SUPPORT)",
      "value": "AC46",
      "type": "psc_code"
   },
   {
      "label": "AC47 - R&D- DEFENSE SYSTEM: TANK/AUTOMOTIVE (COMMERCIALIZED)",
      "value": "AC47",
      "type": "psc_code"
   },
   {
      "label": "AC47 - R&D-TANK - AUTOMOTIVE-COMERCLIZ",
      "value": "AC47",
      "type": "psc_code"
   },
   {
      "label": "AC50 - R&D-WEAPONS",
      "value": "AC50",
      "type": "psc_code"
   },
   {
      "label": "AC51 - WEAPONS (BASIC)",
      "value": "AC51",
      "type": "psc_code"
   },
   {
      "label": "AC51 - R&D- DEFENSE SYSTEM: WEAPONS (BASIC RESEARCH)",
      "value": "AC51",
      "type": "psc_code"
   },
   {
      "label": "AC52 - R&D- DEFENSE SYSTEM: WEAPONS (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AC52",
      "type": "psc_code"
   },
   {
      "label": "AC52 - WEAPONS (APPLIED/EXPLORATORY)",
      "value": "AC52",
      "type": "psc_code"
   },
   {
      "label": "AC53 - R&D- DEFENSE SYSTEM: WEAPONS (ADVANCED DEVELOPMENT)",
      "value": "AC53",
      "type": "psc_code"
   },
   {
      "label": "AC53 - WEAPONS (ADVANCED)",
      "value": "AC53",
      "type": "psc_code"
   },
   {
      "label": "AC54 - WEAPONS (ENGINEERING)",
      "value": "AC54",
      "type": "psc_code"
   },
   {
      "label": "AC54 - R&D- DEFENSE SYSTEM: WEAPONS (ENGINEERING DEVELOPMENT)",
      "value": "AC54",
      "type": "psc_code"
   },
   {
      "label": "AC55 - WEAPONS (OPERATIONAL)",
      "value": "AC55",
      "type": "psc_code"
   },
   {
      "label": "AC55 - R&D- DEFENSE SYSTEM: WEAPONS (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AC55",
      "type": "psc_code"
   },
   {
      "label": "AC56 - R&D- DEFENSE SYSTEM: WEAPONS (MANAGEMENT/SUPPORT)",
      "value": "AC56",
      "type": "psc_code"
   },
   {
      "label": "AC56 - WEAPONS (MANAGEMENT/SUPPORT)",
      "value": "AC56",
      "type": "psc_code"
   },
   {
      "label": "AC57 - R&D- DEFENSE SYSTEM: WEAPONS (COMMERCIALIZED)",
      "value": "AC57",
      "type": "psc_code"
   },
   {
      "label": "AC57 - R&D-WEAPONS-COMERCLIZ",
      "value": "AC57",
      "type": "psc_code"
   },
   {
      "label": "AC60 - R&D-ELECTRONICS & COMM EQ",
      "value": "AC60",
      "type": "psc_code"
   },
   {
      "label": "AC61 - R&D-ELECTRONICS & COMM EQ-B RES",
      "value": "AC61",
      "type": "psc_code"
   },
   {
      "label": "AC61 - R&D- DEFENSE SYSTEM: ELECTRONICS/COMMUNICATION EQUIPMENT (BASIC RESEARCH)",
      "value": "AC61",
      "type": "psc_code"
   },
   {
      "label": "AC62 - R&D- DEFENSE SYSTEM: ELECTRONICS/COMMUNICATION EQUIPMENT (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AC62",
      "type": "psc_code"
   },
   {
      "label": "AC62 - R&D-ELECTRONICS & COMM EQ-A RES/EXP",
      "value": "AC62",
      "type": "psc_code"
   },
   {
      "label": "AC63 - R&D-ELECTRONICS & COMM EQ-ADV DEV",
      "value": "AC63",
      "type": "psc_code"
   },
   {
      "label": "AC63 - R&D- DEFENSE SYSTEM: ELECTRONICS/COMMUNICATION EQUIPMENT (ADVANCED DEVELOPMENT)",
      "value": "AC63",
      "type": "psc_code"
   },
   {
      "label": "AC64 - R&D- DEFENSE SYSTEM: ELECTRONICS/COMMUNICATION EQUIPMENT (ENGINEERING DEVELOPMENT)",
      "value": "AC64",
      "type": "psc_code"
   },
   {
      "label": "AC64 - R&D-ELECTRONICS & COMM EQ-ENG DEV",
      "value": "AC64",
      "type": "psc_code"
   },
   {
      "label": "AC65 - R&D- DEFENSE SYSTEM: ELECTRONICS/COMMUNICATION EQUIPMENT (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AC65",
      "type": "psc_code"
   },
   {
      "label": "AC65 - R&D-ELECTRONICS & COMM EQ-OPSY DEV",
      "value": "AC65",
      "type": "psc_code"
   },
   {
      "label": "AC66 - R&D-ELECTRONICS & COMM EQ-MGMT SUP",
      "value": "AC66",
      "type": "psc_code"
   },
   {
      "label": "AC66 - R&D- DEFENSE SYSTEM: ELECTRONICS/COMMUNICATION EQUIPMENT (MANAGEMENT/SUPPORT)",
      "value": "AC66",
      "type": "psc_code"
   },
   {
      "label": "AC67 - R&D- DEFENSE SYSTEM: ELECTRONICS/COMMUNICATION EQUIPMENT (COMMERCIALIZED)",
      "value": "AC67",
      "type": "psc_code"
   },
   {
      "label": "AC67 - R&D-ELECTRONICS & COMM EQ-COMERCLIZ",
      "value": "AC67",
      "type": "psc_code"
   },
   {
      "label": "AC90 - R&D-MISC HARD GOODS",
      "value": "AC90",
      "type": "psc_code"
   },
   {
      "label": "AC91 - R&D- DEFENSE SYSTEM: MISCELLANEOUS HARD GOODS (BASIC RESEARCH)",
      "value": "AC91",
      "type": "psc_code"
   },
   {
      "label": "AC91 - R&D-MISC HARD GOODS-B RES",
      "value": "AC91",
      "type": "psc_code"
   },
   {
      "label": "AC92 - R&D-MISC HARD GOODS-A RES/EXPL DEV",
      "value": "AC92",
      "type": "psc_code"
   },
   {
      "label": "AC92 - R&D- DEFENSE SYSTEM: MISCELLANEOUS HARD GOODS (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AC92",
      "type": "psc_code"
   },
   {
      "label": "AC93 - R&D- DEFENSE SYSTEM: MISCELLANEOUS HARD GOODS (ADVANCED DEVELOPMENT)",
      "value": "AC93",
      "type": "psc_code"
   },
   {
      "label": "AC93 - R&D-MISC HARD GOODS-ADV DEV",
      "value": "AC93",
      "type": "psc_code"
   },
   {
      "label": "AC94 - R&D-MISC HARD GOODS-ENG DEV",
      "value": "AC94",
      "type": "psc_code"
   },
   {
      "label": "AC94 - R&D- DEFENSE SYSTEM: MISCELLANEOUS HARD GOODS (ENGINEERING DEVELOPMENT)",
      "value": "AC94",
      "type": "psc_code"
   },
   {
      "label": "AC95 - R&D- DEFENSE SYSTEM: MISCELLANEOUS HARD GOODS (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AC95",
      "type": "psc_code"
   },
   {
      "label": "AC95 - R&D-MISC HARD GOODS-OPSY DEV",
      "value": "AC95",
      "type": "psc_code"
   },
   {
      "label": "AC96 - R&D- DEFENSE SYSTEM: MISCELLANEOUS HARD GOODS (MANAGEMENT/SUPPORT)",
      "value": "AC96",
      "type": "psc_code"
   },
   {
      "label": "AC96 - R&D-MISC HARD GOODS-MGMT SUP",
      "value": "AC96",
      "type": "psc_code"
   },
   {
      "label": "AC97 - R&D- DEFENSE SYSTEM: MISCELLANEOUS HARD GOODS (COMMERCIALIZED)",
      "value": "AC97",
      "type": "psc_code"
   },
   {
      "label": "AC97 - R&D-MISC HARD GOODS-COMERCLIZ",
      "value": "AC97",
      "type": "psc_code"
   },
   {
      "label": "AD - DEFENSE (OTHER) R&D",
      "value": "AD",
      "type": "psc_code"
   },
   {
      "label": "AD10 - R&D-AMMUNITION",
      "value": "AD10",
      "type": "psc_code"
   },
   {
      "label": "AD11 - R&D- DEFENSE OTHER: AMMUNITION (BASIC RESEARCH)",
      "value": "AD11",
      "type": "psc_code"
   },
   {
      "label": "AD11 - AMMUNITION (BASIC)",
      "value": "AD11",
      "type": "psc_code"
   },
   {
      "label": "AD12 - R&D- DEFENSE OTHER: AMMUNITION (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AD12",
      "type": "psc_code"
   },
   {
      "label": "AD12 - AMMUNITION (APPLIED/EXPLORATORY)",
      "value": "AD12",
      "type": "psc_code"
   },
   {
      "label": "AD13 - R&D- DEFENSE OTHER: AMMUNITION (ADVANCED DEVELOPMENT)",
      "value": "AD13",
      "type": "psc_code"
   },
   {
      "label": "AD13 - AMMUNITION (ADVANCED)",
      "value": "AD13",
      "type": "psc_code"
   },
   {
      "label": "AD14 - R&D- DEFENSE OTHER: AMMUNITION (ENGINEERING DEVELOPMENT)",
      "value": "AD14",
      "type": "psc_code"
   },
   {
      "label": "AD14 - AMMUNITION (ENGINEERING)",
      "value": "AD14",
      "type": "psc_code"
   },
   {
      "label": "AD15 - R&D- DEFENSE OTHER: AMMUNITION (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AD15",
      "type": "psc_code"
   },
   {
      "label": "AD15 - AMMUNITION (OPERATIONAL)",
      "value": "AD15",
      "type": "psc_code"
   },
   {
      "label": "AD16 - AMMUNITION (MANAGEMENT/SUPPORT)",
      "value": "AD16",
      "type": "psc_code"
   },
   {
      "label": "AD16 - R&D- DEFENSE OTHER: AMMUNITION (MANAGEMENT/SUPPORT)",
      "value": "AD16",
      "type": "psc_code"
   },
   {
      "label": "AD17 - R&D- DEFENSE OTHER: AMMUNITION (COMMERCIALIZED)",
      "value": "AD17",
      "type": "psc_code"
   },
   {
      "label": "AD17 - R&D-AMMUNITION-COMERCLIZ",
      "value": "AD17",
      "type": "psc_code"
   },
   {
      "label": "AD20 - R&D-SVCS",
      "value": "AD20",
      "type": "psc_code"
   },
   {
      "label": "AD21 - SERVICES (BASIC)",
      "value": "AD21",
      "type": "psc_code"
   },
   {
      "label": "AD21 - R&D- DEFENSE OTHER: SERVICES (BASIC RESEARCH)",
      "value": "AD21",
      "type": "psc_code"
   },
   {
      "label": "AD22 - SERVICES (APPLIED/EXPLORATORY)",
      "value": "AD22",
      "type": "psc_code"
   },
   {
      "label": "AD22 - R&D- DEFENSE OTHER: SERVICES (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AD22",
      "type": "psc_code"
   },
   {
      "label": "AD23 - R&D- DEFENSE OTHER: SERVICES (ADVANCED DEVELOPMENT)",
      "value": "AD23",
      "type": "psc_code"
   },
   {
      "label": "AD23 - SERVICES (ADVANCED)",
      "value": "AD23",
      "type": "psc_code"
   },
   {
      "label": "AD24 - SERVICES (ENGINEERING)",
      "value": "AD24",
      "type": "psc_code"
   },
   {
      "label": "AD24 - R&D- DEFENSE OTHER: SERVICES (ENGINEERING DEVELOPMENT)",
      "value": "AD24",
      "type": "psc_code"
   },
   {
      "label": "AD25 - R&D- DEFENSE OTHER: SERVICES (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AD25",
      "type": "psc_code"
   },
   {
      "label": "AD25 - SERVICES (OPERATIONAL)",
      "value": "AD25",
      "type": "psc_code"
   },
   {
      "label": "AD26 - R&D- DEFENSE OTHER: SERVICES (MANAGEMENT/SUPPORT)",
      "value": "AD26",
      "type": "psc_code"
   },
   {
      "label": "AD26 - SERVICES (MANAGEMENT/SUPPORT)",
      "value": "AD26",
      "type": "psc_code"
   },
   {
      "label": "AD27 - R&D-SVCS-COMERCLIZ",
      "value": "AD27",
      "type": "psc_code"
   },
   {
      "label": "AD27 - R&D- DEFENSE OTHER: SERVICES (COMMERCIALIZED)",
      "value": "AD27",
      "type": "psc_code"
   },
   {
      "label": "AD30 - R&D-SUBSISTENCE",
      "value": "AD30",
      "type": "psc_code"
   },
   {
      "label": "AD31 - SUBSISTENCE (BASIC)",
      "value": "AD31",
      "type": "psc_code"
   },
   {
      "label": "AD31 - R&D- DEFENSE OTHER: SUBSISTENCE (BASIC RESEARCH)",
      "value": "AD31",
      "type": "psc_code"
   },
   {
      "label": "AD32 - R&D- DEFENSE OTHER: SUBSISTENCE (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AD32",
      "type": "psc_code"
   },
   {
      "label": "AD32 - SUBSISTENCE (APPLIED/EXPLORATORY)",
      "value": "AD32",
      "type": "psc_code"
   },
   {
      "label": "AD33 - R&D- DEFENSE OTHER: SUBSISTENCE (ADVANCED DEVELOPMENT)",
      "value": "AD33",
      "type": "psc_code"
   },
   {
      "label": "AD33 - SUBSISTENCE (ADVANCED)",
      "value": "AD33",
      "type": "psc_code"
   },
   {
      "label": "AD34 - SUBSISTENCE (ENGINEERING)",
      "value": "AD34",
      "type": "psc_code"
   },
   {
      "label": "AD34 - R&D- DEFENSE OTHER: SUBSISTENCE (ENGINEERING DEVELOPMENT)",
      "value": "AD34",
      "type": "psc_code"
   },
   {
      "label": "AD35 - R&D- DEFENSE OTHER: SUBSISTENCE (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AD35",
      "type": "psc_code"
   },
   {
      "label": "AD35 - SUBSISTENCE (OPERATIONAL)",
      "value": "AD35",
      "type": "psc_code"
   },
   {
      "label": "AD36 - R&D- DEFENSE OTHER: SUBSISTENCE (MANAGEMENT/SUPPORT)",
      "value": "AD36",
      "type": "psc_code"
   },
   {
      "label": "AD36 - SUBSISTENCE (MANAGEMENT/SUPPORT)",
      "value": "AD36",
      "type": "psc_code"
   },
   {
      "label": "AD37 - R&D- DEFENSE OTHER: SUBSISTENCE (COMMERCIALIZED)",
      "value": "AD37",
      "type": "psc_code"
   },
   {
      "label": "AD37 - R&D-SUBSISTENCE-COMERCLIZ",
      "value": "AD37",
      "type": "psc_code"
   },
   {
      "label": "AD40 - R&D-TEXTILES,CLOTHING,ETC",
      "value": "AD40",
      "type": "psc_code"
   },
   {
      "label": "AD41 - R&D-TEXTILES,CLOTHING,ETC-B RES",
      "value": "AD41",
      "type": "psc_code"
   },
   {
      "label": "AD41 - R&D- DEFENSE OTHER: TEXTILES/CLOTHING/EQUIPAGE (BASIC RESEARCH)",
      "value": "AD41",
      "type": "psc_code"
   },
   {
      "label": "AD42 - R&D- DEFENSE OTHER: TEXTILES/CLOTHING/EQUIPAGE (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AD42",
      "type": "psc_code"
   },
   {
      "label": "AD42 - R&D-TEXTILES,CLOTHING,ETC-A RES/EXP",
      "value": "AD42",
      "type": "psc_code"
   },
   {
      "label": "AD43 - R&D-TEXTILES,CLOTHING,ETC-ADV DEV",
      "value": "AD43",
      "type": "psc_code"
   },
   {
      "label": "AD43 - R&D- DEFENSE OTHER: TEXTILES/CLOTHING/EQUIPAGE (ADVANCED DEVELOPMENT)",
      "value": "AD43",
      "type": "psc_code"
   },
   {
      "label": "AD44 - R&D-TEXTILES,CLOTHING,ETC-ENG DEV",
      "value": "AD44",
      "type": "psc_code"
   },
   {
      "label": "AD44 - R&D- DEFENSE OTHER: TEXTILES/CLOTHING/EQUIPAGE (ENGINEERING DEVELOPMENT)",
      "value": "AD44",
      "type": "psc_code"
   },
   {
      "label": "AD45 - R&D-TEXTILES,CLOTHING,ETC-OPSY DEV",
      "value": "AD45",
      "type": "psc_code"
   },
   {
      "label": "AD45 - R&D- DEFENSE OTHER: TEXTILES/CLOTHING/EQUIPAGE (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AD45",
      "type": "psc_code"
   },
   {
      "label": "AD46 - R&D- DEFENSE OTHER: TEXTILES/CLOTHING/EQUIPAGE (MANAGEMENT/SUPPORT)",
      "value": "AD46",
      "type": "psc_code"
   },
   {
      "label": "AD46 - R&D-TEXTILES,CLOTHING,ETC-MGMT SUP",
      "value": "AD46",
      "type": "psc_code"
   },
   {
      "label": "AD47 - R&D- DEFENSE OTHER: TEXTILES/CLOTHING/EQUIPAGE (COMMERCIALIZED)",
      "value": "AD47",
      "type": "psc_code"
   },
   {
      "label": "AD47 - R&D-TEXTILES,CLOTHING,ETC-COMERCLIZ",
      "value": "AD47",
      "type": "psc_code"
   },
   {
      "label": "AD50 - R&D-FUELS & LUBRICANTS",
      "value": "AD50",
      "type": "psc_code"
   },
   {
      "label": "AD51 - FUELS AND LUBRICANTS (BASIC)",
      "value": "AD51",
      "type": "psc_code"
   },
   {
      "label": "AD51 - R&D- DEFENSE OTHER: FUELS/LUBRICANTS (BASIC RESEARCH)",
      "value": "AD51",
      "type": "psc_code"
   },
   {
      "label": "AD52 - R&D- DEFENSE OTHER: FUELS/LUBRICANTS (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AD52",
      "type": "psc_code"
   },
   {
      "label": "AD52 - R&D-FUELS & LUBRICANTS-A RES/EXPL D",
      "value": "AD52",
      "type": "psc_code"
   },
   {
      "label": "AD53 - FUELS AND LUBRICANTS (ADVANCED)",
      "value": "AD53",
      "type": "psc_code"
   },
   {
      "label": "AD53 - R&D- DEFENSE OTHER: FUELS/LUBRICANTS (ADVANCED DEVELOPMENT)",
      "value": "AD53",
      "type": "psc_code"
   },
   {
      "label": "AD54 - R&D- DEFENSE OTHER: FUELS/LUBRICANTS (ENGINEERING DEVELOPMENT)",
      "value": "AD54",
      "type": "psc_code"
   },
   {
      "label": "AD54 - FUELS AND LUBRICANTS (ENGINEERING)",
      "value": "AD54",
      "type": "psc_code"
   },
   {
      "label": "AD55 - R&D- DEFENSE OTHER: FUELS/LUBRICANTS (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AD55",
      "type": "psc_code"
   },
   {
      "label": "AD55 - FUELS AND LUBRICANTS (OPERATIONAL)",
      "value": "AD55",
      "type": "psc_code"
   },
   {
      "label": "AD56 - R&D-FUELS & LUBRICANTS-MGMT SUP",
      "value": "AD56",
      "type": "psc_code"
   },
   {
      "label": "AD56 - R&D- DEFENSE OTHER: FUELS/LUBRICANTS (MANAGEMENT/SUPPORT)",
      "value": "AD56",
      "type": "psc_code"
   },
   {
      "label": "AD57 - R&D- DEFENSE OTHER: FUELS/LUBRICANTS (COMMERCIALIZED)",
      "value": "AD57",
      "type": "psc_code"
   },
   {
      "label": "AD57 - R&D-FUELS & LUBRICANTS-COMERCLIZ",
      "value": "AD57",
      "type": "psc_code"
   },
   {
      "label": "AD60 - R&D-CONSTRUCT",
      "value": "AD60",
      "type": "psc_code"
   },
   {
      "label": "AD61 - CONSTRUCTION (BASIC)",
      "value": "AD61",
      "type": "psc_code"
   },
   {
      "label": "AD61 - R&D- DEFENSE OTHER: CONSTRUCTION (BASIC RESEARCH)",
      "value": "AD61",
      "type": "psc_code"
   },
   {
      "label": "AD62 - R&D- DEFENSE OTHER: CONSTRUCTION (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AD62",
      "type": "psc_code"
   },
   {
      "label": "AD62 - CONSTRUCTION (APPLIED/EXPLORATORY)",
      "value": "AD62",
      "type": "psc_code"
   },
   {
      "label": "AD63 - CONSTRUCTION (ADVANCED)",
      "value": "AD63",
      "type": "psc_code"
   },
   {
      "label": "AD63 - R&D- DEFENSE OTHER: CONSTRUCTION (ADVANCED DEVELOPMENT)",
      "value": "AD63",
      "type": "psc_code"
   },
   {
      "label": "AD64 - R&D- DEFENSE OTHER: CONSTRUCTION (ENGINEERING DEVELOPMENT)",
      "value": "AD64",
      "type": "psc_code"
   },
   {
      "label": "AD64 - CONSTRUCTION (ENGINEERING)",
      "value": "AD64",
      "type": "psc_code"
   },
   {
      "label": "AD65 - R&D- DEFENSE OTHER: CONSTRUCTION (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AD65",
      "type": "psc_code"
   },
   {
      "label": "AD65 - CONSTRUCTION (OPERATIONAL)",
      "value": "AD65",
      "type": "psc_code"
   },
   {
      "label": "AD66 - CONSTRUCTION (MANAGEMENT/SUPPORT)",
      "value": "AD66",
      "type": "psc_code"
   },
   {
      "label": "AD66 - R&D- DEFENSE OTHER: CONSTRUCTION (MANAGEMENT/SUPPORT)",
      "value": "AD66",
      "type": "psc_code"
   },
   {
      "label": "AD67 - R&D- DEFENSE OTHER: CONSTRUCTION (COMMERCIALIZED)",
      "value": "AD67",
      "type": "psc_code"
   },
   {
      "label": "AD67 - R&D-CONSTRUCT-COMERCLIZ",
      "value": "AD67",
      "type": "psc_code"
   },
   {
      "label": "AD90 - R&D-OTHER DEFENSE",
      "value": "AD90",
      "type": "psc_code"
   },
   {
      "label": "AD91 - OTHER DEFENSE (BASIC)",
      "value": "AD91",
      "type": "psc_code"
   },
   {
      "label": "AD91 - R&D- DEFENSE OTHER: OTHER (BASIC RESEARCH)",
      "value": "AD91",
      "type": "psc_code"
   },
   {
      "label": "AD92 - R&D- DEFENSE OTHER: OTHER (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AD92",
      "type": "psc_code"
   },
   {
      "label": "AD92 - OTHER DEFENSE (APPLIED/EXPLORATORY)",
      "value": "AD92",
      "type": "psc_code"
   },
   {
      "label": "AD93 - R&D- DEFENSE OTHER: OTHER (ADVANCED DEVELOPMENT)",
      "value": "AD93",
      "type": "psc_code"
   },
   {
      "label": "AD93 - OTHER DEFENSE (ADVANCED)",
      "value": "AD93",
      "type": "psc_code"
   },
   {
      "label": "AD94 - OTHER DEFENSE (ENGINEERING)",
      "value": "AD94",
      "type": "psc_code"
   },
   {
      "label": "AD94 - R&D- DEFENSE OTHER: OTHER (ENGINEERING DEVELOPMENT)",
      "value": "AD94",
      "type": "psc_code"
   },
   {
      "label": "AD95 - OTHER DEFENSE (OPERATIONAL)",
      "value": "AD95",
      "type": "psc_code"
   },
   {
      "label": "AD95 - R&D- DEFENSE OTHER: OTHER (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AD95",
      "type": "psc_code"
   },
   {
      "label": "AD96 - R&D- DEFENSE OTHER: OTHER (MANAGEMENT/SUPPORT)",
      "value": "AD96",
      "type": "psc_code"
   },
   {
      "label": "AD96 - OTHER DEFENSE (MANAGEMENT/SUPPORT)",
      "value": "AD96",
      "type": "psc_code"
   },
   {
      "label": "AD97 - R&D- DEFENSE OTHER: OTHER (COMMERCIALIZED)",
      "value": "AD97",
      "type": "psc_code"
   },
   {
      "label": "AD97 - R&D-OTHER DEFENSE-COMERCLIZ",
      "value": "AD97",
      "type": "psc_code"
   },
   {
      "label": "AE - ECONOMIC GROWTH/PRODUCTIVITY R&D",
      "value": "AE",
      "type": "psc_code"
   },
   {
      "label": "AE10 - R&D-EMPL GROW-PRODUCTIVTY",
      "value": "AE10",
      "type": "psc_code"
   },
   {
      "label": "AE11 - R&D- ECONOMIC GROWTH: EMPLOYMENT GROWTH/PRODUCTIVITY (BASIC RESEARCH)",
      "value": "AE11",
      "type": "psc_code"
   },
   {
      "label": "AE11 - R&D-EMPL GROW-PRODUCTIVTY-B RES",
      "value": "AE11",
      "type": "psc_code"
   },
   {
      "label": "AE12 - R&D- ECONOMIC GROWTH: EMPLOYMENT GROWTH/PRODUCTIVITY (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AE12",
      "type": "psc_code"
   },
   {
      "label": "AE12 - R&D-EMPL GROW-PRODUCTIVTY-A RES/EXP",
      "value": "AE12",
      "type": "psc_code"
   },
   {
      "label": "AE13 - R&D- ECONOMIC GROWTH: EMPLOYMENT GROWTH/PRODUCTIVITY (ADVANCED DEVELOPMENT)",
      "value": "AE13",
      "type": "psc_code"
   },
   {
      "label": "AE13 - R&D-EMPL GROW-PRODUCTIVTY-ADV DEV",
      "value": "AE13",
      "type": "psc_code"
   },
   {
      "label": "AE14 - R&D-EMPL GROW-PRODUCTIVTY-ENG DEV",
      "value": "AE14",
      "type": "psc_code"
   },
   {
      "label": "AE14 - R&D- ECONOMIC GROWTH: EMPLOYMENT GROWTH/PRODUCTIVITY (ENGINEERING DEVELOPMENT)",
      "value": "AE14",
      "type": "psc_code"
   },
   {
      "label": "AE15 - R&D- ECONOMIC GROWTH: EMPLOYMENT GROWTH/PRODUCTIVITY (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AE15",
      "type": "psc_code"
   },
   {
      "label": "AE15 - R&D-EMPL GROW-PRODUCTIVTY-OPSY DEV",
      "value": "AE15",
      "type": "psc_code"
   },
   {
      "label": "AE16 - R&D-EMPL GROW-PRODUCTIVTY-MGMT SUP",
      "value": "AE16",
      "type": "psc_code"
   },
   {
      "label": "AE16 - R&D- ECONOMIC GROWTH: EMPLOYMENT GROWTH/PRODUCTIVITY (MANAGEMENT/SUPPORT)",
      "value": "AE16",
      "type": "psc_code"
   },
   {
      "label": "AE17 - R&D-EMPL GROW-PRODUCTIVTY-COMERCLIZ",
      "value": "AE17",
      "type": "psc_code"
   },
   {
      "label": "AE17 - R&D- ECONOMIC GROWTH: EMPLOYMENT GROWTH/PRODUCTIVITY (COMMERCIALIZED)",
      "value": "AE17",
      "type": "psc_code"
   },
   {
      "label": "AE20 - R&D-PROD/SERV IMPROVEMENT",
      "value": "AE20",
      "type": "psc_code"
   },
   {
      "label": "AE21 - R&D-PROD/SERV IMPROVEMENT-B RES",
      "value": "AE21",
      "type": "psc_code"
   },
   {
      "label": "AE21 - R&D- ECONOMIC GROWTH: PRODUCT/SERVICE IMPROVEMENT (BASIC RESEARCH)",
      "value": "AE21",
      "type": "psc_code"
   },
   {
      "label": "AE22 - R&D-PROD/SERV IMPROVEMENT-A RES/EXP",
      "value": "AE22",
      "type": "psc_code"
   },
   {
      "label": "AE22 - R&D- ECONOMIC GROWTH: PRODUCT/SERVICE IMPROVEMENT (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AE22",
      "type": "psc_code"
   },
   {
      "label": "AE23 - R&D- ECONOMIC GROWTH: PRODUCT/SERVICE IMPROVEMENT (ADVANCED DEVELOPMENT)",
      "value": "AE23",
      "type": "psc_code"
   },
   {
      "label": "AE23 - R&D-PROD/SERV IMPROVEMENT-ADV DEV",
      "value": "AE23",
      "type": "psc_code"
   },
   {
      "label": "AE24 - R&D- ECONOMIC GROWTH: PRODUCT/SERVICE IMPROVEMENT (ENGINEERING DEVELOPMENT)",
      "value": "AE24",
      "type": "psc_code"
   },
   {
      "label": "AE24 - R&D-PROD/SERV IMPROVEMENT-ENG DEV",
      "value": "AE24",
      "type": "psc_code"
   },
   {
      "label": "AE25 - R&D- ECONOMIC GROWTH: PRODUCT/SERVICE IMPROVEMENT (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AE25",
      "type": "psc_code"
   },
   {
      "label": "AE25 - R&D-PROD/SERV IMPROVEMENT-OPSY DEV",
      "value": "AE25",
      "type": "psc_code"
   },
   {
      "label": "AE26 - R&D- ECONOMIC GROWTH: PRODUCT/SERVICE IMPROVEMENT (MANAGEMENT/SUPPORT)",
      "value": "AE26",
      "type": "psc_code"
   },
   {
      "label": "AE26 - R&D-PROD/SERV IMPROVEMENT-MGMT SUP",
      "value": "AE26",
      "type": "psc_code"
   },
   {
      "label": "AE27 - R&D-PROD/SERV IMPROVEMENT-COMERCLIZ",
      "value": "AE27",
      "type": "psc_code"
   },
   {
      "label": "AE27 - R&D- ECONOMIC GROWTH: PRODUCT/SERVICE IMPROVEMENT (COMMERCIALIZED)",
      "value": "AE27",
      "type": "psc_code"
   },
   {
      "label": "AE30 - R&D-MANUFACTURING TECH",
      "value": "AE30",
      "type": "psc_code"
   },
   {
      "label": "AE31 - R&D- ECONOMIC GROWTH: MANUFACTURING TECHNOLOGY (BASIC RESEARCH)",
      "value": "AE31",
      "type": "psc_code"
   },
   {
      "label": "AE31 - MANUFACTURING TECHNOLOGY (BASIC)",
      "value": "AE31",
      "type": "psc_code"
   },
   {
      "label": "AE32 - R&D- ECONOMIC GROWTH: MANUFACTURING TECHNOLOGY (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AE32",
      "type": "psc_code"
   },
   {
      "label": "AE32 - R&D-MANUFACTURING TECH-A RES/EXPL D",
      "value": "AE32",
      "type": "psc_code"
   },
   {
      "label": "AE33 - R&D- ECONOMIC GROWTH: MANUFACTURING TECHNOLOGY (ADVANCED DEVELOPMENT)",
      "value": "AE33",
      "type": "psc_code"
   },
   {
      "label": "AE33 - MANUFACTURING TECHNOLOGY (ADVANCED)",
      "value": "AE33",
      "type": "psc_code"
   },
   {
      "label": "AE34 - R&D- ECONOMIC GROWTH: MANUFACTURING TECHNOLOGY (ENGINEERING DEVELOPMENT)",
      "value": "AE34",
      "type": "psc_code"
   },
   {
      "label": "AE34 - R&D-MANUFACTURING TECH-ENG DEV",
      "value": "AE34",
      "type": "psc_code"
   },
   {
      "label": "AE35 - R&D-MANUFACTURING TECH-OPSY DEV",
      "value": "AE35",
      "type": "psc_code"
   },
   {
      "label": "AE35 - R&D- ECONOMIC GROWTH: MANUFACTURING TECHNOLOGY (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AE35",
      "type": "psc_code"
   },
   {
      "label": "AE36 - R&D- ECONOMIC GROWTH: MANUFACTURING TECHNOLOGY (MANAGEMENT/SUPPORT)",
      "value": "AE36",
      "type": "psc_code"
   },
   {
      "label": "AE36 - R&D-MANUFACTURING TECH-MGMT SUP",
      "value": "AE36",
      "type": "psc_code"
   },
   {
      "label": "AE37 - R&D-MANUFACTURING TECH-COMERCLIZ",
      "value": "AE37",
      "type": "psc_code"
   },
   {
      "label": "AE37 - R&D- ECONOMIC GROWTH: MANUFACTURING TECHNOLOGY (COMMERCIALIZED)",
      "value": "AE37",
      "type": "psc_code"
   },
   {
      "label": "AE90 - R&D-ECON GROW-PRODUCTIVTY",
      "value": "AE90",
      "type": "psc_code"
   },
   {
      "label": "AE91 - R&D- ECONOMIC GROWTH: OTHER (BASIC RESEARCH)",
      "value": "AE91",
      "type": "psc_code"
   },
   {
      "label": "AE91 - R&D-ECON GROW-PRODUCTIVTY-B RES",
      "value": "AE91",
      "type": "psc_code"
   },
   {
      "label": "AE92 - R&D- ECONOMIC GROWTH: OTHER (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AE92",
      "type": "psc_code"
   },
   {
      "label": "AE92 - R&D-ECON GROW-PRODUCTIVTY-A RES/EXP",
      "value": "AE92",
      "type": "psc_code"
   },
   {
      "label": "AE93 - R&D-ECON GROW-PRODUCTIVTY-ADV DEV",
      "value": "AE93",
      "type": "psc_code"
   },
   {
      "label": "AE93 - R&D- ECONOMIC GROWTH: OTHER (ADVANCED DEVELOPMENT)",
      "value": "AE93",
      "type": "psc_code"
   },
   {
      "label": "AE94 - R&D-ECON GROW-PRODUCTIVTY-ENG DEV",
      "value": "AE94",
      "type": "psc_code"
   },
   {
      "label": "AE94 - R&D- ECONOMIC GROWTH: OTHER (ENGINEERING DEVELOPMENT)",
      "value": "AE94",
      "type": "psc_code"
   },
   {
      "label": "AE95 - R&D-ECON GROW-PRODUCTIVTY-OPSY DEV",
      "value": "AE95",
      "type": "psc_code"
   },
   {
      "label": "AE95 - R&D- ECONOMIC GROWTH: OTHER (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AE95",
      "type": "psc_code"
   },
   {
      "label": "AE96 - R&D-ECON GROW-PRODUCTIVTY-MGMT SUP",
      "value": "AE96",
      "type": "psc_code"
   },
   {
      "label": "AE96 - R&D- ECONOMIC GROWTH: OTHER (MANAGEMENT/SUPPORT)",
      "value": "AE96",
      "type": "psc_code"
   },
   {
      "label": "AE97 - R&D- ECONOMIC GROWTH: OTHER (COMMERCIALIZED)",
      "value": "AE97",
      "type": "psc_code"
   },
   {
      "label": "AE97 - R&D-ECON GROW-PRODUCTIVTY-COMERCLIZ",
      "value": "AE97",
      "type": "psc_code"
   },
   {
      "label": "AF - EDUCATION R and D",
      "value": "AF",
      "type": "psc_code"
   },
   {
      "label": "AF - Education, Training, Employment, and Social Services R&D Services",
      "value": "AF",
      "type": "psc_code"
   },
   {
      "label": "AF10 - R&D-EDUCATIONAL",
      "value": "AF10",
      "type": "psc_code"
   },
   {
      "label": "AF11 - R&D- EDUCATION: EDUCATIONAL (BASIC RESEARCH)",
      "value": "AF11",
      "type": "psc_code"
   },
   {
      "label": "AF11 - EDUCATION (BASIC)",
      "value": "AF11",
      "type": "psc_code"
   },
   {
      "label": "AF11 - EDUCATION, TRAINING, EMPLOYMENT, & SOCIAL SVCS R&D SVCS; EDUCATION SVCS R&D; BASIC RESEARCH",
      "value": "AF11",
      "type": "psc_code"
   },
   {
      "label": "AF12 - R&D- EDUCATION: EDUCATIONAL (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AF12",
      "type": "psc_code"
   },
   {
      "label": "AF12 - EDUCATION (APPLIED/EXPLORATORY)",
      "value": "AF12",
      "type": "psc_code"
   },
   {
      "label": "AF12 - EDUCATION, TRAINING, EMPLOYMENT, & SOCIAL SVCS R&D SVCS; EDUCATION SVCS R&D; APPLIED RESEARCH",
      "value": "AF12",
      "type": "psc_code"
   },
   {
      "label": "AF13 - R&D- EDUCATION: EDUCATIONAL (ADVANCED DEVELOPMENT)",
      "value": "AF13",
      "type": "psc_code"
   },
   {
      "label": "AF13 - EDUCATION (ADVANCED)",
      "value": "AF13",
      "type": "psc_code"
   },
   {
      "label": "AF13 - EDUCATION, TRAINING, EMPLOYMENT, & SOCIAL SVCS R&D SVCS; EDUC SVCS R&D; EXPERIMENTAL DEVELOPMENT",
      "value": "AF13",
      "type": "psc_code"
   },
   {
      "label": "AF14 - R&D- EDUCATION: EDUCATIONAL (ENGINEERING DEVELOPMENT)",
      "value": "AF14",
      "type": "psc_code"
   },
   {
      "label": "AF14 - EDUCATION (ENGINEERING)",
      "value": "AF14",
      "type": "psc_code"
   },
   {
      "label": "AF14 - EDUCATION, TRAINING, EMPLOYMENT, & SOCIAL SVCS R&D SVCS; EDUC SVCS R&D; R&D ADMINISTRATIVE EXPENSES",
      "value": "AF14",
      "type": "psc_code"
   },
   {
      "label": "AF15 - EDUCATION (OPERATIONAL)",
      "value": "AF15",
      "type": "psc_code"
   },
   {
      "label": "AF15 - R&D- EDUCATION: EDUCATIONAL (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AF15",
      "type": "psc_code"
   },
   {
      "label": "AF15 - EDUCATION, TRAINING, EMPLOYMENT, & SOCIAL SVCS R&D SVCS; EDUC SVCS R&D; R&D FACILITIES & MAJ EQUIP",
      "value": "AF15",
      "type": "psc_code"
   },
   {
      "label": "AF16 - EDUCATION (MANAGEMENT/SUPPORT)",
      "value": "AF16",
      "type": "psc_code"
   },
   {
      "label": "AF16 - R&D- EDUCATION: EDUCATIONAL (MANAGEMENT/SUPPORT)",
      "value": "AF16",
      "type": "psc_code"
   },
   {
      "label": "AF17 - R&D- EDUCATION: EDUCATIONAL (COMMERCIALIZED)",
      "value": "AF17",
      "type": "psc_code"
   },
   {
      "label": "AF17 - R&D-EDUCATIONAL-COMERCLIZ",
      "value": "AF17",
      "type": "psc_code"
   },
   {
      "label": "AF21 - EDUCATION, TRAINING, EMPLOYMENT, & SOCIAL SVCS R&D SVCS; TRAINING & LABOR R&D; BASIC RESEARCH",
      "value": "AF21",
      "type": "psc_code"
   },
   {
      "label": "AF22 - EDUCATION, TRAINING, EMPLOYMENT, & SOCIAL SVCS R&D SVCS; TRAINING & LABOR R&D; APPLIED RESEARCH",
      "value": "AF22",
      "type": "psc_code"
   },
   {
      "label": "AF23 - EDUCATION, TRAINING, EMPLOYMENT, & SOCIAL SVCS R&D; TRAINING & LABOR R&D; EXPERIMENTAL DEVELOPMENT",
      "value": "AF23",
      "type": "psc_code"
   },
   {
      "label": "AF24 - EDUCATION, TRAINING, EMPLOYMENT, & SOCIAL SVCS R&D SVCS; TRAINING & LABOR R&D; R&D ADMIN EXPENSES",
      "value": "AF24",
      "type": "psc_code"
   },
   {
      "label": "AF25 - EDUCATION, TRAINING, EMPLOYMENT & SOCIAL SVCS R&D; TRAINING & LABOR R&D; R&D FACILITIES & MAJ EQUIP",
      "value": "AF25",
      "type": "psc_code"
   },
   {
      "label": "AF31 - EDUCATION, TRAINING, EMPLOYMENT, & SOCIAL SVCS R&D SVCS; SOCIAL SVCS R&D; BASIC RESEARCH",
      "value": "AF31",
      "type": "psc_code"
   },
   {
      "label": "AF32 - EDUCATION, TRAINING, EMPLOYMENT, & SOCIAL SVCS R&D SVCS; SOCIAL SVCS R&D; APPLIED RESEARCH",
      "value": "AF32",
      "type": "psc_code"
   },
   {
      "label": "AF33 - EDUCATION, TRAINING, EMPLOYMENT, & SOCIAL SVCS R&D SVCS; SOCIAL SVCS R&D; EXPERIMENTAL DEVELOPMENT",
      "value": "AF33",
      "type": "psc_code"
   },
   {
      "label": "AF34 - EDUCATION, TRAINING, EMPLOYMENT & SOCIAL SVCS R&D SVCS; SOCIAL SVCS R&D; R&D ADMINISTRATIVE EXPENSES",
      "value": "AF34",
      "type": "psc_code"
   },
   {
      "label": "AF35 - EDUCATION, TRAINING, EMPLOYMENT & SOCIAL SVCS R&D SVCS; SOCIAL SVCS R&D; R&D FACILITIES & MAJ EQUIP",
      "value": "AF35",
      "type": "psc_code"
   },
   {
      "label": "AG - ENERGY R&D",
      "value": "AG",
      "type": "psc_code"
   },
   {
      "label": "AG - Energy R&D Services",
      "value": "AG",
      "type": "psc_code"
   },
   {
      "label": "AG10 - R&D-ENERGY - COAL",
      "value": "AG10",
      "type": "psc_code"
   },
   {
      "label": "AG11 - COAL (BASIC)",
      "value": "AG11",
      "type": "psc_code"
   },
   {
      "label": "AG11 - R&D- ENERGY: COAL (BASIC RESEARCH)",
      "value": "AG11",
      "type": "psc_code"
   },
   {
      "label": "AG11 - ENERGY R&D SERVICES; ENERGY SUPPLY; BASIC RESEARCH",
      "value": "AG11",
      "type": "psc_code"
   },
   {
      "label": "AG12 - R&D- ENERGY: COAL (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AG12",
      "type": "psc_code"
   },
   {
      "label": "AG12 - COAL (APPLIED/EXPLORATORY)",
      "value": "AG12",
      "type": "psc_code"
   },
   {
      "label": "AG12 - ENERGY R&D SERVICES; ENERGY SUPPLY; APPLIED RESEARCH",
      "value": "AG12",
      "type": "psc_code"
   },
   {
      "label": "AG13 - COAL (ADVANCED)",
      "value": "AG13",
      "type": "psc_code"
   },
   {
      "label": "AG13 - R&D- ENERGY: COAL (ADVANCED DEVELOPMENT)",
      "value": "AG13",
      "type": "psc_code"
   },
   {
      "label": "AG13 - ENERGY R&D SERVICES; ENERGY SUPPLY; EXPERIMENTAL DEVELOPMENT",
      "value": "AG13",
      "type": "psc_code"
   },
   {
      "label": "AG14 - COAL (ENGINEERING)",
      "value": "AG14",
      "type": "psc_code"
   },
   {
      "label": "AG14 - R&D- ENERGY: COAL (ENGINEERING DEVELOPMENT)",
      "value": "AG14",
      "type": "psc_code"
   },
   {
      "label": "AG14 - ENERGY R&D SERVICES; ENERGY SUPPLY; R&D ADMINISTRATIVE EXPENSES",
      "value": "AG14",
      "type": "psc_code"
   },
   {
      "label": "AG15 - COAL (OPERATIONAL)",
      "value": "AG15",
      "type": "psc_code"
   },
   {
      "label": "AG15 - R&D- ENERGY: COAL (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AG15",
      "type": "psc_code"
   },
   {
      "label": "AG15 - ENERGY R&D SERVICES; ENERGY SUPPLY; EXPENSES FOR R&D FACILITIES AND MAJOR EQUIPMENT",
      "value": "AG15",
      "type": "psc_code"
   },
   {
      "label": "AG16 - COAL (MANAGEMENT/SUPPORT)",
      "value": "AG16",
      "type": "psc_code"
   },
   {
      "label": "AG16 - R&D- ENERGY: COAL (MANAGEMENT/SUPPORT)",
      "value": "AG16",
      "type": "psc_code"
   },
   {
      "label": "AG17 - R&D- ENERGY: COAL (COMMERCIALIZED)",
      "value": "AG17",
      "type": "psc_code"
   },
   {
      "label": "AG17 - R&D-ENERGY - COAL-COMERCLIZ",
      "value": "AG17",
      "type": "psc_code"
   },
   {
      "label": "AG20 - R&D-ENERGY - GAS",
      "value": "AG20",
      "type": "psc_code"
   },
   {
      "label": "AG21 - GAS (BASIC)",
      "value": "AG21",
      "type": "psc_code"
   },
   {
      "label": "AG21 - R&D- ENERGY: GAS (BASIC RESEARCH)",
      "value": "AG21",
      "type": "psc_code"
   },
   {
      "label": "AG21 - ENERGY R&D SERVICES; ENERGY CONSERVATION; BASIC RESEARCH",
      "value": "AG21",
      "type": "psc_code"
   },
   {
      "label": "AG22 - GAS (APPLIED/EXPLORATORY)",
      "value": "AG22",
      "type": "psc_code"
   },
   {
      "label": "AG22 - R&D- ENERGY: GAS (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AG22",
      "type": "psc_code"
   },
   {
      "label": "AG22 - ENERGY R&D SERVICES; ENERGY CONSERVATION; APPLIED RESEARCH",
      "value": "AG22",
      "type": "psc_code"
   },
   {
      "label": "AG23 - GAS (ADVANCED)",
      "value": "AG23",
      "type": "psc_code"
   },
   {
      "label": "AG23 - R&D- ENERGY: GAS (ADVANCED DEVELOPMENT)",
      "value": "AG23",
      "type": "psc_code"
   },
   {
      "label": "AG23 - ENERGY R&D SERVICES; ENERGY CONSERVATION; EXPERIMENTAL DEVELOPMENT",
      "value": "AG23",
      "type": "psc_code"
   },
   {
      "label": "AG24 - R&D- ENERGY: GAS (ENGINEERING DEVELOPMENT)",
      "value": "AG24",
      "type": "psc_code"
   },
   {
      "label": "AG24 - GAS (ENGINEERING)",
      "value": "AG24",
      "type": "psc_code"
   },
   {
      "label": "AG24 - ENERGY R&D SERVICES; ENERGY CONSERVATION; R&D ADMINISTRATIVE EXPENSES",
      "value": "AG24",
      "type": "psc_code"
   },
   {
      "label": "AG25 - GAS (OPERATIONAL)",
      "value": "AG25",
      "type": "psc_code"
   },
   {
      "label": "AG25 - R&D- ENERGY: GAS (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AG25",
      "type": "psc_code"
   },
   {
      "label": "AG25 - ENERGY R&D SERVICES; ENERGY CONSERVATION; EXPENSES FOR R&D FACILITIES AND MAJOR EQUIPMENT",
      "value": "AG25",
      "type": "psc_code"
   },
   {
      "label": "AG26 - R&D- ENERGY: GAS (MANAGEMENT/SUPPORT)",
      "value": "AG26",
      "type": "psc_code"
   },
   {
      "label": "AG26 - GAS (MANAGEMENT/SUPPORT)",
      "value": "AG26",
      "type": "psc_code"
   },
   {
      "label": "AG27 - R&D- ENERGY: GAS (COMMERCIALIZED)",
      "value": "AG27",
      "type": "psc_code"
   },
   {
      "label": "AG27 - R&D-ENERGY - GAS-COMERCLIZ",
      "value": "AG27",
      "type": "psc_code"
   },
   {
      "label": "AG30 - R&D-ENERGY - GEOTHERMAL",
      "value": "AG30",
      "type": "psc_code"
   },
   {
      "label": "AG31 - R&D- ENERGY: GEOTHERMAL (BASIC RESEARCH)",
      "value": "AG31",
      "type": "psc_code"
   },
   {
      "label": "AG31 - GEOTHERMAL (BASIC)",
      "value": "AG31",
      "type": "psc_code"
   },
   {
      "label": "AG31 - ENERGY R&D SERVICES; EMERGENCY ENERGY PREPAREDNESS; BASIC RESEARCH",
      "value": "AG31",
      "type": "psc_code"
   },
   {
      "label": "AG32 - R&D- ENERGY: GEOTHERMAL (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AG32",
      "type": "psc_code"
   },
   {
      "label": "AG32 - GEOTHERMAL (APPLIED/EXPLORATORY)",
      "value": "AG32",
      "type": "psc_code"
   },
   {
      "label": "AG32 - ENERGY R&D SERVICES; EMERGENCY ENERGY PREPAREDNESS; APPLIED RESEARCH",
      "value": "AG32",
      "type": "psc_code"
   },
   {
      "label": "AG33 - GEOTHERMAL (ADVANCED)",
      "value": "AG33",
      "type": "psc_code"
   },
   {
      "label": "AG33 - R&D- ENERGY: GEOTHERMAL (ADVANCED DEVELOPMENT)",
      "value": "AG33",
      "type": "psc_code"
   },
   {
      "label": "AG33 - ENERGY R&D SERVICES; EMERGENCY ENERGY PREPAREDNESS; EXPERIMENTAL DEVELOPMENT",
      "value": "AG33",
      "type": "psc_code"
   },
   {
      "label": "AG34 - GEOTHERMAL (ENGINEERING)",
      "value": "AG34",
      "type": "psc_code"
   },
   {
      "label": "AG34 - R&D- ENERGY: GEOTHERMAL (ENGINEERING DEVELOPMENT)",
      "value": "AG34",
      "type": "psc_code"
   },
   {
      "label": "AG34 - ENERGY R&D SERVICES; EMERGENCY ENERGY PREPAREDNESS; R&D ADMINISTRATIVE EXPENSES",
      "value": "AG34",
      "type": "psc_code"
   },
   {
      "label": "AG35 - GEOTHERMAL (OPERATIONAL)",
      "value": "AG35",
      "type": "psc_code"
   },
   {
      "label": "AG35 - R&D- ENERGY: GEOTHERMAL (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AG35",
      "type": "psc_code"
   },
   {
      "label": "AG35 - ENERGY R&D SERVICES; EMERGENCY ENERGY PREPAREDNESS; EXPENSES FOR R&D FACILITIES AND MAJOR EQUIPMENT",
      "value": "AG35",
      "type": "psc_code"
   },
   {
      "label": "AG36 - GEOTHERMAL (MANAGEMENT/SUPPORT)",
      "value": "AG36",
      "type": "psc_code"
   },
   {
      "label": "AG36 - R&D- ENERGY: GEOTHERMAL (MANAGEMENT/SUPPORT)",
      "value": "AG36",
      "type": "psc_code"
   },
   {
      "label": "AG37 - R&D- ENERGY: GEOTHERMAL (COMMERCIALIZED)",
      "value": "AG37",
      "type": "psc_code"
   },
   {
      "label": "AG37 - R&D-ENERGY - GEOTHERMAL-COMERCLIZ",
      "value": "AG37",
      "type": "psc_code"
   },
   {
      "label": "AG40 - R&D-ENERGY - WIND",
      "value": "AG40",
      "type": "psc_code"
   },
   {
      "label": "AG41 - WIND ENERGY (BASIC)",
      "value": "AG41",
      "type": "psc_code"
   },
   {
      "label": "AG41 - R&D- ENERGY: WIND (BASIC RESEARCH)",
      "value": "AG41",
      "type": "psc_code"
   },
   {
      "label": "AG41 - ENERGY R&D SERVICES; ENERGY INFORMATION, POLICY AND REGULATION; BASIC RESEARCH",
      "value": "AG41",
      "type": "psc_code"
   },
   {
      "label": "AG42 - R&D- ENERGY: WIND (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AG42",
      "type": "psc_code"
   },
   {
      "label": "AG42 - WIND ENERGY (APPLIED/EXPLORATORY)",
      "value": "AG42",
      "type": "psc_code"
   },
   {
      "label": "AG42 - ENERGY R&D SERVICES; ENERGY INFORMATION, POLICY AND REGULATION; APPLIED RESEARCH",
      "value": "AG42",
      "type": "psc_code"
   },
   {
      "label": "AG43 - WIND ENERGY (ADVANCED)",
      "value": "AG43",
      "type": "psc_code"
   },
   {
      "label": "AG43 - R&D- ENERGY: WIND (ADVANCED DEVELOPMENT)",
      "value": "AG43",
      "type": "psc_code"
   },
   {
      "label": "AG43 - ENERGY R&D SERVICES; ENERGY INFORMATION, POLICY AND REGULATION; EXPERIMENTAL DEVELOPMENT",
      "value": "AG43",
      "type": "psc_code"
   },
   {
      "label": "AG44 - WIND ENERGY (ENGINEERING)",
      "value": "AG44",
      "type": "psc_code"
   },
   {
      "label": "AG44 - R&D- ENERGY: WIND (ENGINEERING DEVELOPMENT)",
      "value": "AG44",
      "type": "psc_code"
   },
   {
      "label": "AG44 - ENERGY R&D SERVICES; ENERGY INFORMATION, POLICY AND REGULATION; R&D ADMINISTRATIVE EXPENSES",
      "value": "AG44",
      "type": "psc_code"
   },
   {
      "label": "AG45 - WIND ENERGY (OPERATIONAL)",
      "value": "AG45",
      "type": "psc_code"
   },
   {
      "label": "AG45 - R&D- ENERGY: WIND (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AG45",
      "type": "psc_code"
   },
   {
      "label": "AG45 - ENERGY R&D SVCS; ENERGY INFORMATION, POLICY & REGULATION; R&D FACILITIES & MAJ EQUIP",
      "value": "AG45",
      "type": "psc_code"
   },
   {
      "label": "AG46 - R&D- ENERGY: WIND (MANAGEMENT/SUPPORT)",
      "value": "AG46",
      "type": "psc_code"
   },
   {
      "label": "AG46 - WIND ENERGY (MANAGEMENT/SUPPORT)",
      "value": "AG46",
      "type": "psc_code"
   },
   {
      "label": "AG47 - R&D- ENERGY: WIND (COMMERCIALIZED)",
      "value": "AG47",
      "type": "psc_code"
   },
   {
      "label": "AG47 - R&D-ENERGY - WIND-COMERCLIZ",
      "value": "AG47",
      "type": "psc_code"
   },
   {
      "label": "AG50 - R&D-ENERGY - NUCLEAR",
      "value": "AG50",
      "type": "psc_code"
   },
   {
      "label": "AG51 - R&D- ENERGY: NUCLEAR (BASIC RESEARCH)",
      "value": "AG51",
      "type": "psc_code"
   },
   {
      "label": "AG51 - NUCLEAR (BASIC)",
      "value": "AG51",
      "type": "psc_code"
   },
   {
      "label": "AG52 - R&D- ENERGY: NUCLEAR (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AG52",
      "type": "psc_code"
   },
   {
      "label": "AG52 - NUCLEAR (APPLIED/EXPLORATORY)",
      "value": "AG52",
      "type": "psc_code"
   },
   {
      "label": "AG53 - NUCLEAR (ADVANCED)",
      "value": "AG53",
      "type": "psc_code"
   },
   {
      "label": "AG53 - R&D- ENERGY: NUCLEAR (ADVANCED DEVELOPMENT)",
      "value": "AG53",
      "type": "psc_code"
   },
   {
      "label": "AG54 - NUCLEAR (ENGINEERING)",
      "value": "AG54",
      "type": "psc_code"
   },
   {
      "label": "AG54 - R&D- ENERGY: NUCLEAR (ENGINEERING DEVELOPMENT)",
      "value": "AG54",
      "type": "psc_code"
   },
   {
      "label": "AG55 - NUCLEAR (OPERATIONAL)",
      "value": "AG55",
      "type": "psc_code"
   },
   {
      "label": "AG55 - R&D- ENERGY: NUCLEAR (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AG55",
      "type": "psc_code"
   },
   {
      "label": "AG56 - NUCLEAR (MANAGEMENT/SUPPORT)",
      "value": "AG56",
      "type": "psc_code"
   },
   {
      "label": "AG56 - R&D- ENERGY: NUCLEAR (MANAGEMENT/SUPPORT)",
      "value": "AG56",
      "type": "psc_code"
   },
   {
      "label": "AG57 - R&D-ENERGY - NUCLEAR-COMERCLIZ",
      "value": "AG57",
      "type": "psc_code"
   },
   {
      "label": "AG57 - R&D- ENERGY: NUCLEAR (COMMERCIALIZED)",
      "value": "AG57",
      "type": "psc_code"
   },
   {
      "label": "AG60 - R&D-ENERGY - PETROLEUM",
      "value": "AG60",
      "type": "psc_code"
   },
   {
      "label": "AG61 - PETROLEUM (BASIC)",
      "value": "AG61",
      "type": "psc_code"
   },
   {
      "label": "AG61 - R&D- ENERGY: PETROLEUM (BASIC RESEARCH)",
      "value": "AG61",
      "type": "psc_code"
   },
   {
      "label": "AG62 - R&D- ENERGY: PETROLEUM (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AG62",
      "type": "psc_code"
   },
   {
      "label": "AG62 - PETROLEUM (APPLIED/EXPLORATORY)",
      "value": "AG62",
      "type": "psc_code"
   },
   {
      "label": "AG63 - R&D- ENERGY: PETROLEUM (ADVANCED DEVELOPMENT)",
      "value": "AG63",
      "type": "psc_code"
   },
   {
      "label": "AG63 - PETROLEUM (ADVANCED)",
      "value": "AG63",
      "type": "psc_code"
   },
   {
      "label": "AG64 - R&D- ENERGY: PETROLEUM (ENGINEERING DEVELOPMENT)",
      "value": "AG64",
      "type": "psc_code"
   },
   {
      "label": "AG64 - PETROLEUM (ENGINEERING)",
      "value": "AG64",
      "type": "psc_code"
   },
   {
      "label": "AG65 - R&D- ENERGY: PETROLEUM (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AG65",
      "type": "psc_code"
   },
   {
      "label": "AG65 - PETROLEUM (OPERATIONAL)",
      "value": "AG65",
      "type": "psc_code"
   },
   {
      "label": "AG66 - PETROLEUM (MANAGEMENT/SUPPORT)",
      "value": "AG66",
      "type": "psc_code"
   },
   {
      "label": "AG66 - R&D- ENERGY: PETROLEUM (MANAGEMENT/SUPPORT)",
      "value": "AG66",
      "type": "psc_code"
   },
   {
      "label": "AG67 - R&D- ENERGY: PETROLEUM (COMMERCIALIZED)",
      "value": "AG67",
      "type": "psc_code"
   },
   {
      "label": "AG67 - R&D-ENERGY - PETROLEUM-COMERCLIZ",
      "value": "AG67",
      "type": "psc_code"
   },
   {
      "label": "AG70 - R&D-ENERGY - SOLAR/PHOTOVOLTAIC",
      "value": "AG70",
      "type": "psc_code"
   },
   {
      "label": "AG71 - SOLAR/PHOTOVOLTAIC (BASIC)",
      "value": "AG71",
      "type": "psc_code"
   },
   {
      "label": "AG71 - R&D- ENERGY: SOLAR/PHOTOVOLTAIC (BASIC RESEARCH)",
      "value": "AG71",
      "type": "psc_code"
   },
   {
      "label": "AG72 - R&D- ENERGY: SOLAR/PHOTOVOLTAIC (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AG72",
      "type": "psc_code"
   },
   {
      "label": "AG72 - R&D-ENERGY - SOLAR/PHOTOVOLTAIC-A R",
      "value": "AG72",
      "type": "psc_code"
   },
   {
      "label": "AG73 - SOLAR/PHOTOVOLTAIC (ADVANCED)",
      "value": "AG73",
      "type": "psc_code"
   },
   {
      "label": "AG73 - R&D- ENERGY: SOLAR/PHOTOVOLTAIC (ADVANCED DEVELOPMENT)",
      "value": "AG73",
      "type": "psc_code"
   },
   {
      "label": "AG74 - R&D- ENERGY: SOLAR/PHOTOVOLTAIC (ENGINEERING DEVELOPMENT)",
      "value": "AG74",
      "type": "psc_code"
   },
   {
      "label": "AG74 - SOLAR/PHOTOVOLTAIC (ENGINEERING)",
      "value": "AG74",
      "type": "psc_code"
   },
   {
      "label": "AG75 - R&D- ENERGY: SOLAR/PHOTOVOLTAIC (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AG75",
      "type": "psc_code"
   },
   {
      "label": "AG75 - SOLAR/PHOTOVOLTAIC (OPERATIONAL)",
      "value": "AG75",
      "type": "psc_code"
   },
   {
      "label": "AG76 - R&D-ENERGY - SOLAR/PHOTOVOLTAIC-MGM",
      "value": "AG76",
      "type": "psc_code"
   },
   {
      "label": "AG76 - R&D- ENERGY: SOLAR/PHOTOVOLTAIC (MANAGEMENT/SUPPORT)",
      "value": "AG76",
      "type": "psc_code"
   },
   {
      "label": "AG77 - R&D- ENERGY: SOLAR/PHOTOVOLTAIC (COMMERCIALIZED)",
      "value": "AG77",
      "type": "psc_code"
   },
   {
      "label": "AG77 - R&D-ENERGY -SOLAR/PHOTOVOLTAIC-COME",
      "value": "AG77",
      "type": "psc_code"
   },
   {
      "label": "AG80 - R&D-CONSERV OF ENERGY",
      "value": "AG80",
      "type": "psc_code"
   },
   {
      "label": "AG81 - R&D- ENERGY: CONSERVATION (BASIC RESEARCH)",
      "value": "AG81",
      "type": "psc_code"
   },
   {
      "label": "AG81 - CONSERVATION OF ENERGY (BASIC)",
      "value": "AG81",
      "type": "psc_code"
   },
   {
      "label": "AG82 - R&D- ENERGY: CONSERVATION (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AG82",
      "type": "psc_code"
   },
   {
      "label": "AG82 - R&D-CONSERV OF ENERGY-A RES/EXPL DE",
      "value": "AG82",
      "type": "psc_code"
   },
   {
      "label": "AG83 - CONSERVATION OF ENERGY (ADVANCED)",
      "value": "AG83",
      "type": "psc_code"
   },
   {
      "label": "AG83 - R&D- ENERGY: CONSERVATION (ADVANCED DEVELOPMENT)",
      "value": "AG83",
      "type": "psc_code"
   },
   {
      "label": "AG84 - R&D-CONSERV OF ENERGY-ENG DEV",
      "value": "AG84",
      "type": "psc_code"
   },
   {
      "label": "AG84 - R&D- ENERGY: CONSERVATION (ENGINEERING DEVELOPMENT)",
      "value": "AG84",
      "type": "psc_code"
   },
   {
      "label": "AG85 - R&D-CONSERV OF ENERGY-OPSY DEV",
      "value": "AG85",
      "type": "psc_code"
   },
   {
      "label": "AG85 - R&D- ENERGY: CONSERVATION (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AG85",
      "type": "psc_code"
   },
   {
      "label": "AG86 - R&D- ENERGY: CONSERVATION (MANAGEMENT/SUPPORT)",
      "value": "AG86",
      "type": "psc_code"
   },
   {
      "label": "AG86 - R&D-CONSERV OF ENERGY-MGMT SUP",
      "value": "AG86",
      "type": "psc_code"
   },
   {
      "label": "AG87 - R&D- ENERGY: CONSERVATION (COMMERCIALIZED)",
      "value": "AG87",
      "type": "psc_code"
   },
   {
      "label": "AG87 - R&D-CONSERV OF ENERGY-COMERCLIZ",
      "value": "AG87",
      "type": "psc_code"
   },
   {
      "label": "AG90 - R&D-OTHER ENERGY",
      "value": "AG90",
      "type": "psc_code"
   },
   {
      "label": "AG91 - OTHER ENERGY (BASIC)",
      "value": "AG91",
      "type": "psc_code"
   },
   {
      "label": "AG91 - R&D- ENERGY: OTHER (BASIC RESEARCH)",
      "value": "AG91",
      "type": "psc_code"
   },
   {
      "label": "AG92 - R&D- ENERGY: OTHER (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AG92",
      "type": "psc_code"
   },
   {
      "label": "AG92 - OTHER ENERGY (APPLIED/EXPLORATORY)",
      "value": "AG92",
      "type": "psc_code"
   },
   {
      "label": "AG93 - R&D- ENERGY: OTHER (ADVANCED DEVELOPMENT)",
      "value": "AG93",
      "type": "psc_code"
   },
   {
      "label": "AG93 - OTHER ENERGY (ADVANCED)",
      "value": "AG93",
      "type": "psc_code"
   },
   {
      "label": "AG94 - OTHER ENERGY (ENGINEERING)",
      "value": "AG94",
      "type": "psc_code"
   },
   {
      "label": "AG94 - R&D- ENERGY: OTHER (ENGINEERING DEVELOPMENT)",
      "value": "AG94",
      "type": "psc_code"
   },
   {
      "label": "AG95 - R&D- ENERGY: OTHER (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AG95",
      "type": "psc_code"
   },
   {
      "label": "AG95 - OTHER ENERGY (OPERATIONAL)",
      "value": "AG95",
      "type": "psc_code"
   },
   {
      "label": "AG96 - R&D- ENERGY: OTHER (MANAGEMENT/SUPPORT)",
      "value": "AG96",
      "type": "psc_code"
   },
   {
      "label": "AG96 - OTHER ENERGY (MANAGEMENT/SUPPORT)",
      "value": "AG96",
      "type": "psc_code"
   },
   {
      "label": "AG97 - R&D-OTHER ENERGY-COMERCLIZ",
      "value": "AG97",
      "type": "psc_code"
   },
   {
      "label": "AG97 - R&D- ENERGY: OTHER (COMMERCIALIZED)",
      "value": "AG97",
      "type": "psc_code"
   },
   {
      "label": "AH - ENVIRONMENTAL PROTECTION R&D",
      "value": "AH",
      "type": "psc_code"
   },
   {
      "label": "AH - Natural Resources and Environment R&D Services",
      "value": "AH",
      "type": "psc_code"
   },
   {
      "label": "AH10 - R&D-POLLUTION CONT/ABATE",
      "value": "AH10",
      "type": "psc_code"
   },
   {
      "label": "AH11 - R&D- ENVIRONMENTAL PROTECTION: POLLUTION CONTROL/ABATEMENT (BASIC RESEARCH)",
      "value": "AH11",
      "type": "psc_code"
   },
   {
      "label": "AH11 - R&D-POLLUTION CONT/ABATE-B RES",
      "value": "AH11",
      "type": "psc_code"
   },
   {
      "label": "AH11 - NATURAL RESOURCES AND ENVIRONMENT R&D SERVICES; WATER RESOURCES; BASIC RESEARCH",
      "value": "AH11",
      "type": "psc_code"
   },
   {
      "label": "AH12 - R&D- ENVIRON PROTECTION: POLLUTION CONTROL/ABATEMENT (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AH12",
      "type": "psc_code"
   },
   {
      "label": "AH12 - R&D-POLLUTION CONT/ABATE-A RES/EXPL",
      "value": "AH12",
      "type": "psc_code"
   },
   {
      "label": "AH12 - NATURAL RESOURCES AND ENVIRONMENT R&D SERVICES; WATER RESOURCES; APPLIED RESEARCH",
      "value": "AH12",
      "type": "psc_code"
   },
   {
      "label": "AH13 - R&D-POLLUTION CONT/ABATE-ADV DEV",
      "value": "AH13",
      "type": "psc_code"
   },
   {
      "label": "AH13 - R&D- ENVIRONMENTAL PROTECTION: POLLUTION CONTROL/ABATEMENT (ADVANCED DEVELOPMENT)",
      "value": "AH13",
      "type": "psc_code"
   },
   {
      "label": "AH13 - NATURAL RESOURCES AND ENVIRONMENT R&D SERVICES; WATER RESOURCES; EXPERIMENTAL DEVELOPMENT",
      "value": "AH13",
      "type": "psc_code"
   },
   {
      "label": "AH14 - R&D-POLLUTION CONT/ABATE-ENG DEV",
      "value": "AH14",
      "type": "psc_code"
   },
   {
      "label": "AH14 - R&D- ENVIRONMENTAL PROTECTION: POLLUTION CONTROL/ABATEMENT (ENGINEERING DEVELOPMENT)",
      "value": "AH14",
      "type": "psc_code"
   },
   {
      "label": "AH14 - NATURAL RESOURCES AND ENVIRONMENT R&D SERVICES; WATER RESOURCES; R&D ADMINISTRATIVE EXPENSES",
      "value": "AH14",
      "type": "psc_code"
   },
   {
      "label": "AH15 - R&D- ENVIRONMENTAL PROTECTION: POLLUTION CONTROL/ABATEMENT (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AH15",
      "type": "psc_code"
   },
   {
      "label": "AH15 - R&D-POLLUTION CONT/ABATE-OPSY DEV",
      "value": "AH15",
      "type": "psc_code"
   },
   {
      "label": "AH15 - NATURAL RESOURCES & ENVIRONMENT R&D SVCS; WATER RESOURCES; R&D FACILITIES & MAJ EQUIP",
      "value": "AH15",
      "type": "psc_code"
   },
   {
      "label": "AH16 - R&D- ENVIRONMENTAL PROTECTION: POLLUTION CONTROL/ABATEMENT (MANAGEMENT/SUPPORT)",
      "value": "AH16",
      "type": "psc_code"
   },
   {
      "label": "AH16 - R&D-POLLUTION CONT/ABATE-MGMT SUP",
      "value": "AH16",
      "type": "psc_code"
   },
   {
      "label": "AH17 - R&D-POLLUTION CONT/ABATE-COMERCLIZ",
      "value": "AH17",
      "type": "psc_code"
   },
   {
      "label": "AH17 - R&D- ENVIRONMENTAL PROTECTION: POLLUTION CONTROL/ABATEMENT (COMMERCIALIZED)",
      "value": "AH17",
      "type": "psc_code"
   },
   {
      "label": "AH20 - R&D-AIR POLLUTION",
      "value": "AH20",
      "type": "psc_code"
   },
   {
      "label": "AH21 - R&D- ENVIRONMENTAL PROTECTION: AIR POLLUTION (BASIC RESEARCH)",
      "value": "AH21",
      "type": "psc_code"
   },
   {
      "label": "AH21 - AIR POLLUTION (BASIC)",
      "value": "AH21",
      "type": "psc_code"
   },
   {
      "label": "AH21 - NATURAL RESOURCES AND ENVIRONMENT R&D SERVICES; CONSERVATION AND LAND MANAGEMENT; BASIC RESEARCH",
      "value": "AH21",
      "type": "psc_code"
   },
   {
      "label": "AH22 - AIR POLLUTION (APPLIED/EXPLORATORY)",
      "value": "AH22",
      "type": "psc_code"
   },
   {
      "label": "AH22 - R&D- ENVIRONMENTAL PROTECTION: AIR POLLUTION (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AH22",
      "type": "psc_code"
   },
   {
      "label": "AH22 - NATURAL RESOURCES AND ENVIRONMENT R&D SERVICES; CONSERVATION AND LAND MANAGEMENT; APPLIED RESEARCH",
      "value": "AH22",
      "type": "psc_code"
   },
   {
      "label": "AH23 - AIR POLLUTION (ADVANCED)",
      "value": "AH23",
      "type": "psc_code"
   },
   {
      "label": "AH23 - R&D- ENVIRONMENTAL PROTECTION: AIR POLLUTION (ADVANCED DEVELOPMENT)",
      "value": "AH23",
      "type": "psc_code"
   },
   {
      "label": "AH23 - NATURAL RESOURCES & ENVIRONMENT R&D SVCS; CONSERVATION & LAND MANAGEMENT; EXPERIMENTAL DEVELOPMENT",
      "value": "AH23",
      "type": "psc_code"
   },
   {
      "label": "AH24 - AIR POLLUTION (ENGINEERING)",
      "value": "AH24",
      "type": "psc_code"
   },
   {
      "label": "AH24 - R&D- ENVIRONMENTAL PROTECTION: AIR POLLUTION (ENGINEERING DEVELOPMENT)",
      "value": "AH24",
      "type": "psc_code"
   },
   {
      "label": "AH24 - NATURAL RESOURCES & ENVIRONMENT R&D SVCS; CONSERVATION & LAND MGMT; R&D ADMINISTRATIVE EXPENSES",
      "value": "AH24",
      "type": "psc_code"
   },
   {
      "label": "AH25 - AIR POLLUTION (OPERATIONAL)",
      "value": "AH25",
      "type": "psc_code"
   },
   {
      "label": "AH25 - R&D- ENVIRONMENTAL PROTECTION: AIR POLLUTION (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AH25",
      "type": "psc_code"
   },
   {
      "label": "AH25 - NATURAL RESOURCES & ENVIRONMENT R&D SVCS; CONSERVATION & LAND MGMT; R&D FACILITIES & MAJ EQUIP",
      "value": "AH25",
      "type": "psc_code"
   },
   {
      "label": "AH26 - AIR POLLUTION (MANAGEMENT/SUPPORT)",
      "value": "AH26",
      "type": "psc_code"
   },
   {
      "label": "AH26 - R&D- ENVIRONMENTAL PROTECTION: AIR POLLUTION (MANAGEMENT/SUPPORT)",
      "value": "AH26",
      "type": "psc_code"
   },
   {
      "label": "AH27 - R&D-AIR POLLUTION-COMERCLIZ",
      "value": "AH27",
      "type": "psc_code"
   },
   {
      "label": "AH27 - R&D- ENVIRONMENTAL PROTECTION: AIR POLLUTION (COMMERCIALIZED)",
      "value": "AH27",
      "type": "psc_code"
   },
   {
      "label": "AH30 - R&D-WATER POLLUTION",
      "value": "AH30",
      "type": "psc_code"
   },
   {
      "label": "AH31 - R&D- ENVIRONMENTAL PROTECTION: WATER POLLUTION (BASIC RESEARCH)",
      "value": "AH31",
      "type": "psc_code"
   },
   {
      "label": "AH31 - WATER POLLUTION (BASIC)",
      "value": "AH31",
      "type": "psc_code"
   },
   {
      "label": "AH31 - NATURAL RESOURCES AND ENVIRONMENT R&D SERVICES; RECREATIONAL RESOURCES; BASIC RESEARCH",
      "value": "AH31",
      "type": "psc_code"
   },
   {
      "label": "AH32 - R&D-WATER POLLUTION-A RES/EXPL DEV",
      "value": "AH32",
      "type": "psc_code"
   },
   {
      "label": "AH32 - R&D- ENVIRONMENTAL PROTECTION: WATER POLLUTION (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AH32",
      "type": "psc_code"
   },
   {
      "label": "AH32 - NATURAL RESOURCES AND ENVIRONMENT R&D SERVICES; RECREATIONAL RESOURCES; APPLIED RESEARCH",
      "value": "AH32",
      "type": "psc_code"
   },
   {
      "label": "AH33 - WATER POLLUTION (ADVANCED)",
      "value": "AH33",
      "type": "psc_code"
   },
   {
      "label": "AH33 - R&D- ENVIRONMENTAL PROTECTION: WATER POLLUTION (ADVANCED DEVELOPMENT)",
      "value": "AH33",
      "type": "psc_code"
   },
   {
      "label": "AH33 - NATURAL RESOURCES AND ENVIRONMENT R&D SERVICES; RECREATIONAL RESOURCES; EXPERIMENTAL DEVELOPMENT",
      "value": "AH33",
      "type": "psc_code"
   },
   {
      "label": "AH34 - WATER POLLUTION (ENGINEERING)",
      "value": "AH34",
      "type": "psc_code"
   },
   {
      "label": "AH34 - R&D- ENVIRONMENTAL PROTECTION: WATER POLLUTION (ENGINEERING DEVELOPMENT)",
      "value": "AH34",
      "type": "psc_code"
   },
   {
      "label": "AH34 - NATURAL RESOURCES AND ENVIRONMENT R&D SERVICES; RECREATIONAL RESOURCES; R&D ADMINISTRATIVE EXPENSES",
      "value": "AH34",
      "type": "psc_code"
   },
   {
      "label": "AH35 - WATER POLLUTION (OPERATIONAL)",
      "value": "AH35",
      "type": "psc_code"
   },
   {
      "label": "AH35 - R&D- ENVIRONMENTAL PROTECTION: WATER POLLUTION (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AH35",
      "type": "psc_code"
   },
   {
      "label": "AH35 - NATURAL RESOURCES & ENVIRONMENT R&D SVCS; RECREATIONAL RESOURCES; R&D FACILITIES & MAJ EQUIP",
      "value": "AH35",
      "type": "psc_code"
   },
   {
      "label": "AH36 - R&D-WATER POLLUTION-MGMT SUP",
      "value": "AH36",
      "type": "psc_code"
   },
   {
      "label": "AH36 - R&D- ENVIRONMENTAL PROTECTION: WATER POLLUTION (MANAGEMENT/SUPPORT)",
      "value": "AH36",
      "type": "psc_code"
   },
   {
      "label": "AH37 - R&D-WATER POLLUTION-COMERCLIZ",
      "value": "AH37",
      "type": "psc_code"
   },
   {
      "label": "AH37 - R&D- ENVIRONMENTAL PROTECTION: WATER POLLUTION (COMMERCIALIZED)",
      "value": "AH37",
      "type": "psc_code"
   },
   {
      "label": "AH40 - R&D-NOISE POLLUTION",
      "value": "AH40",
      "type": "psc_code"
   },
   {
      "label": "AH41 - NOISE POLLUTION (BASIC)",
      "value": "AH41",
      "type": "psc_code"
   },
   {
      "label": "AH41 - R&D- ENVIRONMENTAL PROTECTION: NOISE POLLUTION (BASIC RESEARCH)",
      "value": "AH41",
      "type": "psc_code"
   },
   {
      "label": "AH41 - NATURAL RESOURCES AND ENVIRONMENT R&D SERVICES; POLLUTION CONTROL AND ABATEMENT; BASIC RESEARCH",
      "value": "AH41",
      "type": "psc_code"
   },
   {
      "label": "AH42 - R&D-NOISE POLLUTION-A RES/EXPL DEV",
      "value": "AH42",
      "type": "psc_code"
   },
   {
      "label": "AH42 - R&D- ENVIRONMENTAL PROTECTION: NOISE POLLUTION (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AH42",
      "type": "psc_code"
   },
   {
      "label": "AH42 - NATURAL RESOURCES AND ENVIRONMENT R&D SERVICES; POLLUTION CONTROL AND ABATEMENT; APPLIED RESEARCH",
      "value": "AH42",
      "type": "psc_code"
   },
   {
      "label": "AH43 - R&D- ENVIRONMENTAL PROTECTION: NOISE POLLUTION (ADVANCED DEVELOPMENT)",
      "value": "AH43",
      "type": "psc_code"
   },
   {
      "label": "AH43 - NOISE POLLUTION (ADVANCED)",
      "value": "AH43",
      "type": "psc_code"
   },
   {
      "label": "AH43 - NATURAL RESOURCES & ENVIRONMENT R&D SVCS; POLLUTION CONTROL & ABATEMENT; EXPERIMENTAL DEVELOPMENT",
      "value": "AH43",
      "type": "psc_code"
   },
   {
      "label": "AH44 - R&D- ENVIRONMENTAL PROTECTION: NOISE POLLUTION (ENGINEERING DEVELOPMENT)",
      "value": "AH44",
      "type": "psc_code"
   },
   {
      "label": "AH44 - NOISE POLLUTION (ENGINEERING)",
      "value": "AH44",
      "type": "psc_code"
   },
   {
      "label": "AH44 - NATURAL RESOURCES & ENVIRONMENT R&D SVCS; POLLUTION CONTROL & ABATEMENT; R&D ADMINISTRATIVE EXPENSES",
      "value": "AH44",
      "type": "psc_code"
   },
   {
      "label": "AH45 - R&D- ENVIRONMENTAL PROTECTION: NOISE POLLUTION (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AH45",
      "type": "psc_code"
   },
   {
      "label": "AH45 - NOISE POLLUTION (OPERATIONAL)",
      "value": "AH45",
      "type": "psc_code"
   },
   {
      "label": "AH45 - NATURAL RESOURCES & ENVIRONMENT R&D SVCS; POLLUTION CONTROL & ABATEMENT; R&D FACILITIES & MAJ EQUIP",
      "value": "AH45",
      "type": "psc_code"
   },
   {
      "label": "AH46 - R&D-NOISE POLLUTION-MGMT SUP",
      "value": "AH46",
      "type": "psc_code"
   },
   {
      "label": "AH46 - R&D- ENVIRONMENTAL PROTECTION: NOISE POLLUTION (MANAGEMENT/SUPPORT)",
      "value": "AH46",
      "type": "psc_code"
   },
   {
      "label": "AH47 - R&D-NOISE POLLUTION-COMERCLIZ",
      "value": "AH47",
      "type": "psc_code"
   },
   {
      "label": "AH47 - R&D- ENVIRONMENTAL PROTECTION: NOISE POLLUTION (COMMERCIALIZED)",
      "value": "AH47",
      "type": "psc_code"
   },
   {
      "label": "AH50 - R&D-OTHER POLLUTION",
      "value": "AH50",
      "type": "psc_code"
   },
   {
      "label": "AH51 - R&D-OTHER POLLUTION-RESEARCH",
      "value": "AH51",
      "type": "psc_code"
   },
   {
      "label": "AH51 - NATURAL RESOURCES AND ENVIRONMENT R&D SERVICES; OTHER NATURAL RESOURCES; BASIC RESEARCH",
      "value": "AH51",
      "type": "psc_code"
   },
   {
      "label": "AH52 - R&D-OTHER POLLUTION-EXPL DEV",
      "value": "AH52",
      "type": "psc_code"
   },
   {
      "label": "AH52 - NATURAL RESOURCES AND ENVIRONMENT R&D SERVICES; OTHER NATURAL RESOURCES; APPLIED RESEARCH",
      "value": "AH52",
      "type": "psc_code"
   },
   {
      "label": "AH53 - R&D-OTHER POLLUTION-ADV DEV",
      "value": "AH53",
      "type": "psc_code"
   },
   {
      "label": "AH53 - NATURAL RESOURCES AND ENVIRONMENT R&D SERVICES; OTHER NATURAL RESOURCES; EXPERIMENTAL DEVELOPMENT",
      "value": "AH53",
      "type": "psc_code"
   },
   {
      "label": "AH54 - R&D-OTHER POLLUTION-ENG DEV",
      "value": "AH54",
      "type": "psc_code"
   },
   {
      "label": "AH54 - NATURAL RESOURCES & ENVIRONMENT R&D SVCS; OTHER NATURAL RESOURCES; R&D ADMINISTRATIVE EXPENSES",
      "value": "AH54",
      "type": "psc_code"
   },
   {
      "label": "AH55 - R&D-OTHER POLLUTION-OPSY DEV",
      "value": "AH55",
      "type": "psc_code"
   },
   {
      "label": "AH55 - NATURAL RESOURCES & ENVIRONMENT R&D SVCS; OTHER NATURAL RESOURCES; R&D FACILITIES & MAJ EQUIP",
      "value": "AH55",
      "type": "psc_code"
   },
   {
      "label": "AH56 - R&D-OTHER POLLUTION-MGMT SUP",
      "value": "AH56",
      "type": "psc_code"
   },
   {
      "label": "AH57 - R&D-OTHER POLLUTION-COMERCLIZ",
      "value": "AH57",
      "type": "psc_code"
   },
   {
      "label": "AH90 - R&D-OTHER ENVIRONMENT",
      "value": "AH90",
      "type": "psc_code"
   },
   {
      "label": "AH91 - R&D- ENVIRONMENTAL PROTECTION: OTHER (BASIC RESEARCH)",
      "value": "AH91",
      "type": "psc_code"
   },
   {
      "label": "AH91 - R&D-OTHER ENVIRONMENT-B RES",
      "value": "AH91",
      "type": "psc_code"
   },
   {
      "label": "AH92 - R&D- ENVIRONMENTAL PROTECTION: OTHER (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AH92",
      "type": "psc_code"
   },
   {
      "label": "AH92 - R&D-OTHER ENVIRONMENT-A RES/EXPL DE",
      "value": "AH92",
      "type": "psc_code"
   },
   {
      "label": "AH93 - R&D-OTHER ENVIRONMENT-ADV DEV",
      "value": "AH93",
      "type": "psc_code"
   },
   {
      "label": "AH93 - R&D- ENVIRONMENTAL PROTECTION: OTHER (ADVANCED DEVELOPMENT)",
      "value": "AH93",
      "type": "psc_code"
   },
   {
      "label": "AH94 - R&D-OTHER ENVIRONMENT-ENG DEV",
      "value": "AH94",
      "type": "psc_code"
   },
   {
      "label": "AH94 - R&D- ENVIRONMENTAL PROTECTION: OTHER (ENGINEERING DEVELOPMENT)",
      "value": "AH94",
      "type": "psc_code"
   },
   {
      "label": "AH95 - R&D-OTHER ENVIRONMENT-OPSY DEV",
      "value": "AH95",
      "type": "psc_code"
   },
   {
      "label": "AH95 - R&D- ENVIRONMENTAL PROTECTION: OTHER (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AH95",
      "type": "psc_code"
   },
   {
      "label": "AH96 - R&D- ENVIRONMENTAL PROTECTION: OTHER (MANAGEMENT/SUPPORT)",
      "value": "AH96",
      "type": "psc_code"
   },
   {
      "label": "AH96 - R&D-OTHER ENVIRONMENT-MGMT SUP",
      "value": "AH96",
      "type": "psc_code"
   },
   {
      "label": "AH97 - R&D- ENVIRONMENTAL PROTECTION: OTHER (COMMERCIALIZED)",
      "value": "AH97",
      "type": "psc_code"
   },
   {
      "label": "AH97 - R&D-OTHER ENVIRONMENT-COMERCLIZ",
      "value": "AH97",
      "type": "psc_code"
   },
   {
      "label": "AJ - GEN. SCIENCE/TECHNOLOGY R&D",
      "value": "AJ",
      "type": "psc_code"
   },
   {
      "label": "AJ - General Science and Technology R&D Services",
      "value": "AJ",
      "type": "psc_code"
   },
   {
      "label": "AJ10 - R&D-PHYSICAL SCIENCE",
      "value": "AJ10",
      "type": "psc_code"
   },
   {
      "label": "AJ11 - PHYSICAL SCIENCES (BASIC)",
      "value": "AJ11",
      "type": "psc_code"
   },
   {
      "label": "AJ11 - R&D- GENERAL SCIENCE/TECHNOLOGY: PHYSICAL SCIENCES (BASIC RESEARCH)",
      "value": "AJ11",
      "type": "psc_code"
   },
   {
      "label": "AJ11 - GENERAL SCIENCE AND TECHNOLOGY R&D SERVICES; GENERAL SCIENCE AND TECHNOLOGY; BASIC RESEARCH",
      "value": "AJ11",
      "type": "psc_code"
   },
   {
      "label": "AJ12 - R&D-PHYSICAL SCIENCE-A RES/EXPL DEV",
      "value": "AJ12",
      "type": "psc_code"
   },
   {
      "label": "AJ12 - R&D- GENERAL SCIENCE/TECHNOLOGY: PHYSICAL SCIENCES (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AJ12",
      "type": "psc_code"
   },
   {
      "label": "AJ12 - GENERAL SCIENCE AND TECHNOLOGY R&D SERVICES; GENERAL SCIENCE AND TECHNOLOGY; APPLIED RESEARCH",
      "value": "AJ12",
      "type": "psc_code"
   },
   {
      "label": "AJ13 - PHYSICAL SCIENCES (ADVANCED)",
      "value": "AJ13",
      "type": "psc_code"
   },
   {
      "label": "AJ13 - R&D- GENERAL SCIENCE/TECHNOLOGY: PHYSICAL SCIENCES (ADVANCED DEVELOPMENT)",
      "value": "AJ13",
      "type": "psc_code"
   },
   {
      "label": "AJ13 - GENERAL SCIENCE & TECHNOLOGY R&D SVCS; GENERAL SCIENCE & TECHNOLOGY; EXPERIMENTAL DEVELOPMENT",
      "value": "AJ13",
      "type": "psc_code"
   },
   {
      "label": "AJ14 - R&D- GENERAL SCIENCE/TECHNOLOGY: PHYSICAL SCIENCES (ENGINEERING DEVELOPMENT)",
      "value": "AJ14",
      "type": "psc_code"
   },
   {
      "label": "AJ14 - PHYSICAL SCIENCES (ENGINEERING)",
      "value": "AJ14",
      "type": "psc_code"
   },
   {
      "label": "AJ14 - GENERAL SCIENCE AND TECHNOLOGY R&D SERVICES; GENERAL SCIENCE AND TECHNOLOGY; R&D ADMINISTRATIVE EXPENSES",
      "value": "AJ14",
      "type": "psc_code"
   },
   {
      "label": "AJ15 - PHYSICAL SCIENCES (OPERATIONAL)",
      "value": "AJ15",
      "type": "psc_code"
   },
   {
      "label": "AJ15 - R&D- GENERAL SCIENCE/TECHNOLOGY: PHYSICAL SCIENCES (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AJ15",
      "type": "psc_code"
   },
   {
      "label": "AJ15 - GENERAL SCIENCE & TECHNOLOGY R&D SVCS; GENERAL SCIENCE & TECHNOLOGY; R&D FACILITIES & MAJ EQUIP",
      "value": "AJ15",
      "type": "psc_code"
   },
   {
      "label": "AJ16 - R&D- GENERAL SCIENCE/TECHNOLOGY: PHYSICAL SCIENCES (MANAGEMENT/SUPPORT)",
      "value": "AJ16",
      "type": "psc_code"
   },
   {
      "label": "AJ16 - R&D-PHYSICAL SCIENCE-MGMT SUP",
      "value": "AJ16",
      "type": "psc_code"
   },
   {
      "label": "AJ17 - R&D- GENERAL SCIENCE/TECHNOLOGY: PHYSICAL SCIENCES (COMMERCIALIZED)",
      "value": "AJ17",
      "type": "psc_code"
   },
   {
      "label": "AJ17 - R&D-PHYSICAL SCIENCE-COMERCLIZ",
      "value": "AJ17",
      "type": "psc_code"
   },
   {
      "label": "AJ21 - R&D-MATH & COMPUTER SCI-B RES",
      "value": "AJ21",
      "type": "psc_code"
   },
   {
      "label": "AJ21 - R&D- GENERAL SCIENCE/TECHNOLOGY: MATHEMATICAL/COMPUTER SCIENCES (BASIC RESEARCH)",
      "value": "AJ21",
      "type": "psc_code"
   },
   {
      "label": "AJ22 - R&D- GENERAL SCI/TECH: MATHEMATICAL/COMPUTER SCIENCES (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AJ22",
      "type": "psc_code"
   },
   {
      "label": "AJ22 - R&D-MATH & COMPUTER SCI-A RES/EXPL",
      "value": "AJ22",
      "type": "psc_code"
   },
   {
      "label": "AJ23 - R&D- GENERAL SCIENCE/TECHNOLOGY: MATHEMATICAL/COMPUTER SCIENCES (ADVANCED DEVELOPMENT)",
      "value": "AJ23",
      "type": "psc_code"
   },
   {
      "label": "AJ23 - R&D-MATH & COMPUTER SCI-ADV DEV",
      "value": "AJ23",
      "type": "psc_code"
   },
   {
      "label": "AJ24 - R&D-MATH & COMPUTER SCI-ENG DEV",
      "value": "AJ24",
      "type": "psc_code"
   },
   {
      "label": "AJ24 - R&D- GENERAL SCIENCE/TECHNOLOGY: MATHEMATICAL/COMPUTER SCIENCES (ENGINEERING DEVELOPMENT)",
      "value": "AJ24",
      "type": "psc_code"
   },
   {
      "label": "AJ25 - R&D-MATH & COMPUTER SCI-OPSY DEV",
      "value": "AJ25",
      "type": "psc_code"
   },
   {
      "label": "AJ25 - R&D- GENERAL SCIENCE/TECHNOLOGY: MATHEMATICAL/COMPUTER SCIENCES (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AJ25",
      "type": "psc_code"
   },
   {
      "label": "AJ26 - R&D- GENERAL SCIENCE/TECHNOLOGY: MATHEMATICAL/COMPUTER SCIENCES (MANAGEMENT/SUPPORT)",
      "value": "AJ26",
      "type": "psc_code"
   },
   {
      "label": "AJ26 - R&D-MATH & COMPUTER SCI-MGMT SUP",
      "value": "AJ26",
      "type": "psc_code"
   },
   {
      "label": "AJ27 - R&D- GENERAL SCIENCE/TECHNOLOGY: MATHEMATICAL/COMPUTER SCIENCES (COMMERCIALIZED)",
      "value": "AJ27",
      "type": "psc_code"
   },
   {
      "label": "AJ27 - R&D-MATH, COMPUTER SCI - COMERCLIZ",
      "value": "AJ27",
      "type": "psc_code"
   },
   {
      "label": "AJ31 - ENVIRONMENTAL SCIENCES (BASIC)",
      "value": "AJ31",
      "type": "psc_code"
   },
   {
      "label": "AJ31 - R&D- GENERAL SCIENCE/TECHNOLOGY: ENVIRONMENTAL SCIENCES (BASIC RESEARCH)",
      "value": "AJ31",
      "type": "psc_code"
   },
   {
      "label": "AJ32 - R&D- GENERAL SCIENCE/TECHNOLOGY: ENVIRONMENTAL SCIENCES (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AJ32",
      "type": "psc_code"
   },
   {
      "label": "AJ32 - R&D-ENVIRONMENTAL SCI-A RES/EXPL DE",
      "value": "AJ32",
      "type": "psc_code"
   },
   {
      "label": "AJ33 - ENVIRONMENTAL SCIENCES (ADVANCED)",
      "value": "AJ33",
      "type": "psc_code"
   },
   {
      "label": "AJ33 - R&D- GENERAL SCIENCE/TECHNOLOGY: ENVIRONMENTAL SCIENCES (ADVANCED DEVELOPMENT)",
      "value": "AJ33",
      "type": "psc_code"
   },
   {
      "label": "AJ34 - R&D-ENVIRONMENTAL SCI-ENG DEV",
      "value": "AJ34",
      "type": "psc_code"
   },
   {
      "label": "AJ34 - R&D- GENERAL SCIENCE/TECHNOLOGY: ENVIRONMENTAL SCIENCES (ENGINEERING DEVELOPMENT)",
      "value": "AJ34",
      "type": "psc_code"
   },
   {
      "label": "AJ35 - R&D- GENERAL SCIENCE/TECHNOLOGY: ENVIRONMENTAL SCIENCES (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AJ35",
      "type": "psc_code"
   },
   {
      "label": "AJ35 - R&D-ENVIRONMENTAL SCI-OPSY DEV",
      "value": "AJ35",
      "type": "psc_code"
   },
   {
      "label": "AJ36 - R&D- GENERAL SCIENCE/TECHNOLOGY: ENVIRONMENTAL SCIENCES (MANAGEMENT/SUPPORT)",
      "value": "AJ36",
      "type": "psc_code"
   },
   {
      "label": "AJ36 - R&D-ENVIRONMENTAL SCI-MGMT SUP",
      "value": "AJ36",
      "type": "psc_code"
   },
   {
      "label": "AJ37 - R&D- GENERAL SCIENCE/TECHNOLOGY: ENVIRONMENTAL SCIENCES (COMMERCIALIZED)",
      "value": "AJ37",
      "type": "psc_code"
   },
   {
      "label": "AJ37 - RDTE/Environmental Sciences - Op System Develop",
      "value": "AJ37",
      "type": "psc_code"
   },
   {
      "label": "AJ41 - ENGINEERING (BASIC)",
      "value": "AJ41",
      "type": "psc_code"
   },
   {
      "label": "AJ41 - R&D- GENERAL SCIENCE/TECHNOLOGY: ENGINEERING (BASIC RESEARCH)",
      "value": "AJ41",
      "type": "psc_code"
   },
   {
      "label": "AJ42 - R&D- GENERAL SCIENCE/TECHNOLOGY: ENGINEERING (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AJ42",
      "type": "psc_code"
   },
   {
      "label": "AJ42 - ENGINEERING (APPLIED/EXPLORATORY)",
      "value": "AJ42",
      "type": "psc_code"
   },
   {
      "label": "AJ43 - ENGINEERING (ADVANCED)",
      "value": "AJ43",
      "type": "psc_code"
   },
   {
      "label": "AJ43 - R&D- GENERAL SCIENCE/TECHNOLOGY: ENGINEERING (ADVANCED DEVELOPMENT)",
      "value": "AJ43",
      "type": "psc_code"
   },
   {
      "label": "AJ44 - ENGINEERING (ENGINEERING)",
      "value": "AJ44",
      "type": "psc_code"
   },
   {
      "label": "AJ44 - R&D- GENERAL SCIENCE/TECHNOLOGY: ENGINEERING (ENGINEERING DEVELOPMENT)",
      "value": "AJ44",
      "type": "psc_code"
   },
   {
      "label": "AJ45 - R&D- GENERAL SCIENCE/TECHNOLOGY: ENGINEERING (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AJ45",
      "type": "psc_code"
   },
   {
      "label": "AJ45 - ENGINEERING (OPERATIONAL)",
      "value": "AJ45",
      "type": "psc_code"
   },
   {
      "label": "AJ46 - R&D- GENERAL SCIENCE/TECHNOLOGY: ENGINEERING (MANAGEMENT/SUPPORT)",
      "value": "AJ46",
      "type": "psc_code"
   },
   {
      "label": "AJ46 - ENGINEERING (MANAGEMENT/SUPPORT)",
      "value": "AJ46",
      "type": "psc_code"
   },
   {
      "label": "AJ47 - R&D- GENERAL SCIENCE/TECHNOLOGY: ENGINEERING (COMMERCIALIZED)",
      "value": "AJ47",
      "type": "psc_code"
   },
   {
      "label": "AJ47 - RDTE/Engineering Sci-Oper Sy Dev",
      "value": "AJ47",
      "type": "psc_code"
   },
   {
      "label": "AJ51 - LIFE SCIENCES (BASIC)",
      "value": "AJ51",
      "type": "psc_code"
   },
   {
      "label": "AJ51 - R&D- GENERAL SCIENCE/TECHNOLOGY: LIFE SCIENCES (BASIC RESEARCH)",
      "value": "AJ51",
      "type": "psc_code"
   },
   {
      "label": "AJ52 - R&D- GENERAL SCIENCE/TECHNOLOGY: LIFE SCIENCES (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AJ52",
      "type": "psc_code"
   },
   {
      "label": "AJ52 - LIFE SCIENCES (APPLIED/EXPLORATORY)",
      "value": "AJ52",
      "type": "psc_code"
   },
   {
      "label": "AJ53 - R&D- GENERAL SCIENCE/TECHNOLOGY: LIFE SCIENCES (ADVANCED DEVELOPMENT)",
      "value": "AJ53",
      "type": "psc_code"
   },
   {
      "label": "AJ53 - LIFE SCIENCES (ADVANCED)",
      "value": "AJ53",
      "type": "psc_code"
   },
   {
      "label": "AJ54 - LIFE SCIENCES (ENGINEERING)",
      "value": "AJ54",
      "type": "psc_code"
   },
   {
      "label": "AJ54 - R&D- GENERAL SCIENCE/TECHNOLOGY: LIFE SCIENCES (ENGINEERING DEVELOPMENT)",
      "value": "AJ54",
      "type": "psc_code"
   },
   {
      "label": "AJ55 - R&D- GENERAL SCIENCE/TECHNOLOGY: LIFE SCIENCES (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AJ55",
      "type": "psc_code"
   },
   {
      "label": "AJ55 - LIFE SCIENCES (OPERATIONAL)",
      "value": "AJ55",
      "type": "psc_code"
   },
   {
      "label": "AJ56 - R&D- GENERAL SCIENCE/TECHNOLOGY: LIFE SCIENCES (MANAGEMENT/SUPPORT)",
      "value": "AJ56",
      "type": "psc_code"
   },
   {
      "label": "AJ56 - LIFE SCIENCES (MANAGEMENT/SUPPORT)",
      "value": "AJ56",
      "type": "psc_code"
   },
   {
      "label": "AJ57 - R&D- GENERAL SCIENCE/TECHNOLOGY: LIFE SCIENCES (COMMERCIALIZED)",
      "value": "AJ57",
      "type": "psc_code"
   },
   {
      "label": "AJ57 - RDTE/Life Sciences - Operational System Develop",
      "value": "AJ57",
      "type": "psc_code"
   },
   {
      "label": "AJ61 - PSYCHOLOGICAL SCIENCES (BASIC)",
      "value": "AJ61",
      "type": "psc_code"
   },
   {
      "label": "AJ61 - R&D- GENERAL SCIENCE/TECHNOLOGY: PSYCHOLOGICAL SCIENCES (BASIC RESEARCH)",
      "value": "AJ61",
      "type": "psc_code"
   },
   {
      "label": "AJ62 - R&D- GENERAL SCIENCE/TECHNOLOGY: PSYCHOLOGICAL SCIENCES (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AJ62",
      "type": "psc_code"
   },
   {
      "label": "AJ62 - R&D-PSYCHOLOGICAL SCI-A RES/EXPL DE",
      "value": "AJ62",
      "type": "psc_code"
   },
   {
      "label": "AJ63 - R&D- GENERAL SCIENCE/TECHNOLOGY: PSYCHOLOGICAL SCIENCES (ADVANCED DEVELOPMENT)",
      "value": "AJ63",
      "type": "psc_code"
   },
   {
      "label": "AJ63 - PSYCHOLOGICAL SCIENCES (ADVANCED)",
      "value": "AJ63",
      "type": "psc_code"
   },
   {
      "label": "AJ64 - R&D-PSYCHOLOGICAL SCI-ENG DEV",
      "value": "AJ64",
      "type": "psc_code"
   },
   {
      "label": "AJ64 - R&D- GENERAL SCIENCE/TECHNOLOGY: PSYCHOLOGICAL SCIENCES (ENGINEERING DEVELOPMENT)",
      "value": "AJ64",
      "type": "psc_code"
   },
   {
      "label": "AJ65 - R&D- GENERAL SCIENCE/TECHNOLOGY: PSYCHOLOGICAL SCIENCES (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AJ65",
      "type": "psc_code"
   },
   {
      "label": "AJ65 - R&D-PSYCHOLOGICAL SCI-OPSY DEV",
      "value": "AJ65",
      "type": "psc_code"
   },
   {
      "label": "AJ66 - R&D-PSYCHOLOGICAL SCI-MGMT SUP",
      "value": "AJ66",
      "type": "psc_code"
   },
   {
      "label": "AJ66 - R&D- GENERAL SCIENCE/TECHNOLOGY: PSYCHOLOGICAL SCIENCES (MANAGEMENT/SUPPORT)",
      "value": "AJ66",
      "type": "psc_code"
   },
   {
      "label": "AJ67 - R&D- GENERAL SCIENCE/TECHNOLOGY: PSYCHOLOGICAL SCIENCES (COMMERCIALIZED)",
      "value": "AJ67",
      "type": "psc_code"
   },
   {
      "label": "AJ67 - RDTE/Psychological Sciences - Operational Sy Dev",
      "value": "AJ67",
      "type": "psc_code"
   },
   {
      "label": "AJ71 - SOCIAL SCIENCES (BASIC)",
      "value": "AJ71",
      "type": "psc_code"
   },
   {
      "label": "AJ71 - R&D- GENERAL SCIENCE/TECHNOLOGY: SOCIAL SCIENCES (BASIC RESEARCH)",
      "value": "AJ71",
      "type": "psc_code"
   },
   {
      "label": "AJ72 - R&D- GENERAL SCIENCE/TECHNOLOGY: SOCIAL SCIENCES (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AJ72",
      "type": "psc_code"
   },
   {
      "label": "AJ72 - R&D-SOCIAL SCIENCES-A RES/EXPL DEV",
      "value": "AJ72",
      "type": "psc_code"
   },
   {
      "label": "AJ73 - R&D- GENERAL SCIENCE/TECHNOLOGY: SOCIAL SCIENCES (ADVANCED DEVELOPMENT)",
      "value": "AJ73",
      "type": "psc_code"
   },
   {
      "label": "AJ73 - SOCIAL SCIENCES (ADVANCED)",
      "value": "AJ73",
      "type": "psc_code"
   },
   {
      "label": "AJ74 - SOCIAL SCIENCES (ENGINEERING)",
      "value": "AJ74",
      "type": "psc_code"
   },
   {
      "label": "AJ74 - R&D- GENERAL SCIENCE/TECHNOLOGY: SOCIAL SCIENCES (ENGINEERING DEVELOPMENT)",
      "value": "AJ74",
      "type": "psc_code"
   },
   {
      "label": "AJ75 - R&D- GENERAL SCIENCE/TECHNOLOGY: SOCIAL SCIENCES (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AJ75",
      "type": "psc_code"
   },
   {
      "label": "AJ75 - SOCIAL SCIENCES (OPERATIONAL)",
      "value": "AJ75",
      "type": "psc_code"
   },
   {
      "label": "AJ76 - R&D- GENERAL SCIENCE/TECHNOLOGY: SOCIAL SCIENCES (MANAGEMENT/SUPPORT)",
      "value": "AJ76",
      "type": "psc_code"
   },
   {
      "label": "AJ76 - R&D-SOCIAL SCIENCES-MGMT SUP",
      "value": "AJ76",
      "type": "psc_code"
   },
   {
      "label": "AJ77 - R&D- GENERAL SCIENCE/TECHNOLOGY: SOCIAL SCIENCES (COMMERCIALIZED)",
      "value": "AJ77",
      "type": "psc_code"
   },
   {
      "label": "AJ77 - RDTE/Social Sciences - Operational System Develop",
      "value": "AJ77",
      "type": "psc_code"
   },
   {
      "label": "",
      "value": "AJ90",
      "type": "psc_code"
   },
   {
      "label": "AJ91 - R&D-OTHER SCIENCES-B RES",
      "value": "AJ91",
      "type": "psc_code"
   },
   {
      "label": "AJ91 - R&D- GENERAL SCIENCE/TECHNOLOGY: OTHER (BASIC RESEARCH)",
      "value": "AJ91",
      "type": "psc_code"
   },
   {
      "label": "AJ92 - R&D-OTHER SCIENCES-A RES/EXPL DEV",
      "value": "AJ92",
      "type": "psc_code"
   },
   {
      "label": "AJ92 - R&D- GENERAL SCIENCE/TECHNOLOGY: OTHER (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AJ92",
      "type": "psc_code"
   },
   {
      "label": "AJ93 - R&D- GENERAL SCIENCE/TECHNOLOGY: OTHER (ADVANCED DEVELOPMENT)",
      "value": "AJ93",
      "type": "psc_code"
   },
   {
      "label": "AJ93 - R&D-OTHER SCIENCES-ADV DEV",
      "value": "AJ93",
      "type": "psc_code"
   },
   {
      "label": "AJ94 - R&D- GENERAL SCIENCE/TECHNOLOGY: OTHER (ENGINEERING DEVELOPMENT)",
      "value": "AJ94",
      "type": "psc_code"
   },
   {
      "label": "AJ94 - R&D-OTHER SCIENCES-ENG DEV",
      "value": "AJ94",
      "type": "psc_code"
   },
   {
      "label": "AJ95 - R&D-OTHER SCIENCES-OPSY DEV",
      "value": "AJ95",
      "type": "psc_code"
   },
   {
      "label": "AJ95 - R&D- GENERAL SCIENCE/TECHNOLOGY: OTHER (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AJ95",
      "type": "psc_code"
   },
   {
      "label": "AJ96 - R&D- GENERAL SCIENCE/TECHNOLOGY: OTHER (MANAGEMENT/SUPPORT)",
      "value": "AJ96",
      "type": "psc_code"
   },
   {
      "label": "AJ96 - R&D-OTHER SCIENCES-MGMT SUP",
      "value": "AJ96",
      "type": "psc_code"
   },
   {
      "label": "AJ97 - R&D- GENERAL SCIENCE/TECHNOLOGY: OTHER (COMMERCIALIZED)",
      "value": "AJ97",
      "type": "psc_code"
   },
   {
      "label": "AJ97 - R&D-OTHER INCOME MAIN-COMERCLIZ",
      "value": "AJ97",
      "type": "psc_code"
   },
   {
      "label": "AK - HOUSING R&D",
      "value": "AK",
      "type": "psc_code"
   },
   {
      "label": "AK - Commerce and Housing Credit R&D Services",
      "value": "AK",
      "type": "psc_code"
   },
   {
      "label": "AK10 - R&D-HOUSING",
      "value": "AK10",
      "type": "psc_code"
   },
   {
      "label": "AK11 - HOUSING (BASIC)",
      "value": "AK11",
      "type": "psc_code"
   },
   {
      "label": "AK11 - R&D- HOUSING: HOUSING (BASIC RESEARCH)",
      "value": "AK11",
      "type": "psc_code"
   },
   {
      "label": "AK11 - COMMERCE AND HOUSING CREDIT R&D SERVICES; COMMERCE AND HOUSING CREDIT; BASIC RESEARCH",
      "value": "AK11",
      "type": "psc_code"
   },
   {
      "label": "AK12 - HOUSING (APPLIED/EXPLORATORY)",
      "value": "AK12",
      "type": "psc_code"
   },
   {
      "label": "AK12 - R&D- HOUSING: HOUSING (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AK12",
      "type": "psc_code"
   },
   {
      "label": "AK12 - COMMERCE AND HOUSING CREDIT R&D SERVICES; COMMERCE AND HOUSING CREDIT; APPLIED RESEARCH",
      "value": "AK12",
      "type": "psc_code"
   },
   {
      "label": "AK13 - R&D- HOUSING: HOUSING (ADVANCED DEVELOPMENT)",
      "value": "AK13",
      "type": "psc_code"
   },
   {
      "label": "AK13 - HOUSING (ADVANCED)",
      "value": "AK13",
      "type": "psc_code"
   },
   {
      "label": "AK13 - COMMERCE AND HOUSING CREDIT R&D SERVICES; COMMERCE AND HOUSING CREDIT; EXPERIMENTAL DEVELOPMENT",
      "value": "AK13",
      "type": "psc_code"
   },
   {
      "label": "AK14 - R&D- HOUSING: HOUSING (ENGINEERING DEVELOPMENT)",
      "value": "AK14",
      "type": "psc_code"
   },
   {
      "label": "AK14 - HOUSING (ENGINEERING)",
      "value": "AK14",
      "type": "psc_code"
   },
   {
      "label": "AK14 - GENERAL SCIENCE & TECHNOLOGY R&D SVCS; GENERAL SCIENCE & TECHNOLOGY; R&D ADMINISTRATIVE EXPENSES",
      "value": "AK14",
      "type": "psc_code"
   },
   {
      "label": "AK15 - R&D- HOUSING: HOUSING (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AK15",
      "type": "psc_code"
   },
   {
      "label": "AK15 - HOUSING (OPERATIONAL)",
      "value": "AK15",
      "type": "psc_code"
   },
   {
      "label": "AK15 - COMMERCE & HOUSING CREDIT R&D SVCS; COMMERCE & HOUSING CREDIT; R&D FACILITIES & MAJ EQUIP",
      "value": "AK15",
      "type": "psc_code"
   },
   {
      "label": "AK16 - R&D- HOUSING: HOUSING (MANAGEMENT/SUPPORT)",
      "value": "AK16",
      "type": "psc_code"
   },
   {
      "label": "AK16 - HOUSING (MANAGEMENT/SUPPORT)",
      "value": "AK16",
      "type": "psc_code"
   },
   {
      "label": "AK17 - R&D-HOUSING-COMERCLIZ",
      "value": "AK17",
      "type": "psc_code"
   },
   {
      "label": "AK17 - R&D- HOUSING: HOUSING (COMMERCIALIZED)",
      "value": "AK17",
      "type": "psc_code"
   },
   {
      "label": "AL - INCOME SECURITY R&D",
      "value": "AL",
      "type": "psc_code"
   },
   {
      "label": "AL - Income Security R&D Services",
      "value": "AL",
      "type": "psc_code"
   },
   {
      "label": "AL10 - R&D-EMPLOYMENT",
      "value": "AL10",
      "type": "psc_code"
   },
   {
      "label": "AL11 - R&D- INCOME SECURITY: EMPLOYMENT (BASIC RESEARCH)",
      "value": "AL11",
      "type": "psc_code"
   },
   {
      "label": "AL11 - EMPLOYMENT (BASIC)",
      "value": "AL11",
      "type": "psc_code"
   },
   {
      "label": "AL11 - INCOME SECURITY R&D SERVICES; INCOME SECURITY; BASIC RESEARCH",
      "value": "AL11",
      "type": "psc_code"
   },
   {
      "label": "AL12 - R&D- INCOME SECURITY: EMPLOYMENT (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AL12",
      "type": "psc_code"
   },
   {
      "label": "AL12 - EMPLOYMENT (APPLIED/EXPLORATORY)",
      "value": "AL12",
      "type": "psc_code"
   },
   {
      "label": "AL12 - INCOME SECURITY R&D SERVICES; INCOME SECURITY; APPLIED RESEARCH",
      "value": "AL12",
      "type": "psc_code"
   },
   {
      "label": "AL13 - R&D- INCOME SECURITY: EMPLOYMENT (ADVANCED DEVELOPMENT)",
      "value": "AL13",
      "type": "psc_code"
   },
   {
      "label": "AL13 - EMPLOYMENT (ADVANCED)",
      "value": "AL13",
      "type": "psc_code"
   },
   {
      "label": "AL13 - INCOME SECURITY R&D SERVICES; INCOME SECURITY; EXPERIMENTAL DEVELOPMENT",
      "value": "AL13",
      "type": "psc_code"
   },
   {
      "label": "AL14 - R&D- INCOME SECURITY: EMPLOYMENT (ENGINEERING DEVELOPMENT)",
      "value": "AL14",
      "type": "psc_code"
   },
   {
      "label": "AL14 - EMPLOYMENT (ENGINEERING)",
      "value": "AL14",
      "type": "psc_code"
   },
   {
      "label": "AL14 - INCOME SECURITY R&D SERVICES; INCOME SECURITY; R&D ADMINISTRATIVE EXPENSES",
      "value": "AL14",
      "type": "psc_code"
   },
   {
      "label": "AL15 - R&D- INCOME SECURITY: EMPLOYMENT (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AL15",
      "type": "psc_code"
   },
   {
      "label": "AL15 - EMPLOYMENT (OPERATIONAL)",
      "value": "AL15",
      "type": "psc_code"
   },
   {
      "label": "AL15 - INCOME SECURITY R&D SERVICES; INCOME SECURITY; EXPENSES FOR R&D FACILITIES AND MAJOR EQUIPMENT",
      "value": "AL15",
      "type": "psc_code"
   },
   {
      "label": "AL16 - R&D- INCOME SECURITY: EMPLOYMENT (MANAGEMENT/SUPPORT)",
      "value": "AL16",
      "type": "psc_code"
   },
   {
      "label": "AL16 - EMPLOYMENT (MANAGEMENT/SUPPORT)",
      "value": "AL16",
      "type": "psc_code"
   },
   {
      "label": "AL17 - R&D- INCOME SECURITY: EMPLOYMENT (COMMERCIALIZED)",
      "value": "AL17",
      "type": "psc_code"
   },
   {
      "label": "AL17 - R&D-EMPLOYMENT-COMERCLIZ",
      "value": "AL17",
      "type": "psc_code"
   },
   {
      "label": "AL20 - R&D-INCOME MAINT",
      "value": "AL20",
      "type": "psc_code"
   },
   {
      "label": "AL21 - R&D- INCOME SECURITY: INCOME MAINTENANCE (BASIC RESEARCH)",
      "value": "AL21",
      "type": "psc_code"
   },
   {
      "label": "AL21 - INCOME MAINTENANCE (BASIC)",
      "value": "AL21",
      "type": "psc_code"
   },
   {
      "label": "AL22 - R&D- INCOME SECURITY: INCOME MAINTENANCE (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AL22",
      "type": "psc_code"
   },
   {
      "label": "AL22 - R&D-INCOME MAINT-A RES/EXPL DEV",
      "value": "AL22",
      "type": "psc_code"
   },
   {
      "label": "AL23 - R&D- INCOME SECURITY: INCOME MAINTENANCE (ADVANCED DEVELOPMENT)",
      "value": "AL23",
      "type": "psc_code"
   },
   {
      "label": "AL23 - INCOME MAINTENANCE (ADVANCED)",
      "value": "AL23",
      "type": "psc_code"
   },
   {
      "label": "AL24 - R&D- INCOME SECURITY: INCOME MAINTENANCE (ENGINEERING DEVELOPMENT)",
      "value": "AL24",
      "type": "psc_code"
   },
   {
      "label": "AL24 - INCOME MAINTENANCE (ENGINEERING)",
      "value": "AL24",
      "type": "psc_code"
   },
   {
      "label": "AL25 - R&D- INCOME SECURITY: INCOME MAINTENANCE (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AL25",
      "type": "psc_code"
   },
   {
      "label": "AL25 - INCOME MAINTENANCE (OPERATIONAL)",
      "value": "AL25",
      "type": "psc_code"
   },
   {
      "label": "AL26 - R&D- INCOME SECURITY: INCOME MAINTENANCE (MANAGEMENT/SUPPORT)",
      "value": "AL26",
      "type": "psc_code"
   },
   {
      "label": "AL26 - R&D-INCOME MAINT-MGMT SUP",
      "value": "AL26",
      "type": "psc_code"
   },
   {
      "label": "AL27 - R&D- INCOME SECURITY: INCOME MAINTENANCE (COMMERCIALIZED)",
      "value": "AL27",
      "type": "psc_code"
   },
   {
      "label": "AL27 - R&D-INCOME MAINT-COMERCLIZ",
      "value": "AL27",
      "type": "psc_code"
   },
   {
      "label": "AL90 - R&D-OTHER INCOME SECURITY",
      "value": "AL90",
      "type": "psc_code"
   },
   {
      "label": "AL91 - R&D- INCOME SECURITY: OTHER (BASIC RESEARCH)",
      "value": "AL91",
      "type": "psc_code"
   },
   {
      "label": "AL91 - OTHER INCOME SECURITY (BASIC)",
      "value": "AL91",
      "type": "psc_code"
   },
   {
      "label": "AL92 - R&D- INCOME SECURITY: OTHER (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AL92",
      "type": "psc_code"
   },
   {
      "label": "AL92 - R&D-OTHER INCOME SECURITY-A RES/EXP",
      "value": "AL92",
      "type": "psc_code"
   },
   {
      "label": "AL93 - R&D- INCOME SECURITY: OTHER (ADVANCED DEVELOPMENT)",
      "value": "AL93",
      "type": "psc_code"
   },
   {
      "label": "AL93 - OTHER INCOME SECURITY (ADVANCED)",
      "value": "AL93",
      "type": "psc_code"
   },
   {
      "label": "AL94 - R&D- INCOME SECURITY: OTHER (ENGINEERING DEVELOPMENT)",
      "value": "AL94",
      "type": "psc_code"
   },
   {
      "label": "AL94 - OTHER INCOME SECURITY (ENGINEERING)",
      "value": "AL94",
      "type": "psc_code"
   },
   {
      "label": "AL95 - R&D- INCOME SECURITY: OTHER (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AL95",
      "type": "psc_code"
   },
   {
      "label": "AL95 - OTHER INCOME SECURITY (OPERATIONAL)",
      "value": "AL95",
      "type": "psc_code"
   },
   {
      "label": "AL96 - R&D- INCOME SECURITY: OTHER (MANAGEMENT/SUPPORT)",
      "value": "AL96",
      "type": "psc_code"
   },
   {
      "label": "AL96 - R&D-OTHER INCOME SECURITY-MGMT SUP",
      "value": "AL96",
      "type": "psc_code"
   },
   {
      "label": "AL97 - R&D- INCOME SECURITY: OTHER (COMMERCIALIZED)",
      "value": "AL97",
      "type": "psc_code"
   },
   {
      "label": "AL97 - R&D-OTHER INCOME SECURTIY-COMERCLIZ",
      "value": "AL97",
      "type": "psc_code"
   },
   {
      "label": "AM - INTERNATIONAL AFFAIR/COOPERAT R&D",
      "value": "AM",
      "type": "psc_code"
   },
   {
      "label": "AM - International Affairs R&D Services",
      "value": "AM",
      "type": "psc_code"
   },
   {
      "label": "AM10 - R&D-INTERNATIONAL AFFAIRS",
      "value": "AM10",
      "type": "psc_code"
   },
   {
      "label": "AM11 - R&D-INTERNATIONAL AFFAIRS-B RES",
      "value": "AM11",
      "type": "psc_code"
   },
   {
      "label": "AM11 - R&D- INTERNATIONAL AFFAIRS AND COOPERATION (BASIC RESEARCH)",
      "value": "AM11",
      "type": "psc_code"
   },
   {
      "label": "AM11 - INTERNATIONAL AFFAIRS R&D SERVICES; INTERNATIONAL AFFAIRS; BASIC RESEARCH",
      "value": "AM11",
      "type": "psc_code"
   },
   {
      "label": "AM12 - R&D-INTERNATIONAL AFFAIRS-A RES/EXP",
      "value": "AM12",
      "type": "psc_code"
   },
   {
      "label": "AM12 - R&D- INTERNATIONAL AFFAIRS AND COOPERATION (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AM12",
      "type": "psc_code"
   },
   {
      "label": "AM12 - INTERNATIONAL AFFAIRS R&D SERVICES; INTERNATIONAL AFFAIRS; APPLIED RESEARCH",
      "value": "AM12",
      "type": "psc_code"
   },
   {
      "label": "AM13 - R&D- INTERNATIONAL AFFAIRS AND COOPERATION (ADVANCED DEVELOPMENT)",
      "value": "AM13",
      "type": "psc_code"
   },
   {
      "label": "AM13 - R&D-INTERNATIONAL AFFAIRS-ADV DEV",
      "value": "AM13",
      "type": "psc_code"
   },
   {
      "label": "AM13 - INTERNATIONAL AFFAIRS R&D SERVICES; INTERNATIONAL AFFAIRS; EXPERIMENTAL DEVELOPMENT",
      "value": "AM13",
      "type": "psc_code"
   },
   {
      "label": "AM14 - R&D-INTERNATIONAL AFFAIRS-ENG DEV",
      "value": "AM14",
      "type": "psc_code"
   },
   {
      "label": "AM14 - R&D- INTERNATIONAL AFFAIRS AND COOPERATION (ENGINEERING DEVELOPMENT)",
      "value": "AM14",
      "type": "psc_code"
   },
   {
      "label": "AM14 - INTERNATIONAL AFFAIRS R&D SERVICES; INTERNATIONAL AFFAIRS; R&D ADMINISTRATIVE EXPENSES",
      "value": "AM14",
      "type": "psc_code"
   },
   {
      "label": "AM15 - R&D- INTERNATIONAL AFFAIRS AND COOPERATION (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AM15",
      "type": "psc_code"
   },
   {
      "label": "AM15 - R&D-INTERNATIONAL AFFAIRS-OPSY DEV",
      "value": "AM15",
      "type": "psc_code"
   },
   {
      "label": "AM15 - INTERNATIONAL AFFAIRS R&D SVCS; INTERNATIONAL AFFAIRS; R&D FACILITIES & MAJ EQUIP",
      "value": "AM15",
      "type": "psc_code"
   },
   {
      "label": "AM16 - R&D- INTERNATIONAL AFFAIRS AND COOPERATION (MANAGEMENT/SUPPORT)",
      "value": "AM16",
      "type": "psc_code"
   },
   {
      "label": "AM16 - R&D-INTERNATIONAL AFFAIRS-MGMT SUP",
      "value": "AM16",
      "type": "psc_code"
   },
   {
      "label": "AM17 - R&D- INTERNATIONAL AFFAIRS AND COOPERATION (COMMERCIALIZED)",
      "value": "AM17",
      "type": "psc_code"
   },
   {
      "label": "AM17 - R&D-INTERNATIONAL AFFAIRS-COMERCLIZ",
      "value": "AM17",
      "type": "psc_code"
   },
   {
      "label": "AN - MEDICAL R&D",
      "value": "AN",
      "type": "psc_code"
   },
   {
      "label": "AN - N \u2013 Health R&D Services",
      "value": "AN",
      "type": "psc_code"
   },
   {
      "label": "AN10 - R&D-BIOMEDICAL",
      "value": "AN10",
      "type": "psc_code"
   },
   {
      "label": "AN11 - R&D- MEDICAL: BIOMEDICAL (BASIC RESEARCH)",
      "value": "AN11",
      "type": "psc_code"
   },
   {
      "label": "AN11 - BIOMEDICAL (BASIC)",
      "value": "AN11",
      "type": "psc_code"
   },
   {
      "label": "AN11 - HEALTH R&D SERVICES; HEALTH CARE SERVICES; BASIC RESEARCH",
      "value": "AN11",
      "type": "psc_code"
   },
   {
      "label": "AN12 - R&D- MEDICAL: BIOMEDICAL (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AN12",
      "type": "psc_code"
   },
   {
      "label": "AN12 - BIOMEDICAL (APPLIED/EXPLORATORY)",
      "value": "AN12",
      "type": "psc_code"
   },
   {
      "label": "AN12 - HEALTH R&D SERVICES; HEALTH CARE SERVICES; APPLIED RESEARCH",
      "value": "AN12",
      "type": "psc_code"
   },
   {
      "label": "AN13 - BIOMEDICAL (ADVANCED)",
      "value": "AN13",
      "type": "psc_code"
   },
   {
      "label": "AN13 - R&D- MEDICAL: BIOMEDICAL (ADVANCED DEVELOPMENT)",
      "value": "AN13",
      "type": "psc_code"
   },
   {
      "label": "AN13 - HEALTH R&D SERVICES; HEALTH CARE SERVICES; EXPERIMENTAL DEVELOPMENT",
      "value": "AN13",
      "type": "psc_code"
   },
   {
      "label": "AN14 - R&D- MEDICAL: BIOMEDICAL (ENGINEERING DEVELOPMENT)",
      "value": "AN14",
      "type": "psc_code"
   },
   {
      "label": "AN14 - BIOMEDICAL (ENGINEERING)",
      "value": "AN14",
      "type": "psc_code"
   },
   {
      "label": "AN14 - HEALTH R&D SERVICES; HEALTH CARE SERVICES; R&D ADMINISTRATIVE EXPENSES",
      "value": "AN14",
      "type": "psc_code"
   },
   {
      "label": "AN15 - R&D- MEDICAL: BIOMEDICAL (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AN15",
      "type": "psc_code"
   },
   {
      "label": "AN15 - BIOMEDICAL (OPERATIONAL)",
      "value": "AN15",
      "type": "psc_code"
   },
   {
      "label": "AN15 - HEALTH R&D SERVICES; HEALTH CARE SERVICES; EXPENSES FOR R&D FACILITIES AND MAJOR EQUIPMENT",
      "value": "AN15",
      "type": "psc_code"
   },
   {
      "label": "AN16 - R&D- MEDICAL: BIOMEDICAL (MANAGEMENT/SUPPORT)",
      "value": "AN16",
      "type": "psc_code"
   },
   {
      "label": "AN16 - BIOMEDICAL (MANAGEMENT/SUPPORT)",
      "value": "AN16",
      "type": "psc_code"
   },
   {
      "label": "AN17 - R&D- MEDICAL: BIOMEDICAL (COMMERCIALIZED)",
      "value": "AN17",
      "type": "psc_code"
   },
   {
      "label": "AN17 - R&D-BIOMEDICAL-COMERCLIZ",
      "value": "AN17",
      "type": "psc_code"
   },
   {
      "label": "AN20 - R&D-DRUG DEPENDENCY",
      "value": "AN20",
      "type": "psc_code"
   },
   {
      "label": "AN21 - R&D- MEDICAL: DRUG DEPENDENCY (BASIC RESEARCH)",
      "value": "AN21",
      "type": "psc_code"
   },
   {
      "label": "AN21 - DRUGS DEPENDENCY (BASIC)",
      "value": "AN21",
      "type": "psc_code"
   },
   {
      "label": "AN21 - HEALTH R&D SERVICES; HEALTH RESEARCH AND TRAINING; BASIC RESEARCH",
      "value": "AN21",
      "type": "psc_code"
   },
   {
      "label": "AN22 - R&D- MEDICAL: DRUG DEPENDENCY (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AN22",
      "type": "psc_code"
   },
   {
      "label": "AN22 - R&D-DRUG DEPENDENCY-A RES/EXPL DEV",
      "value": "AN22",
      "type": "psc_code"
   },
   {
      "label": "AN22 - HEALTH R&D SERVICES; HEALTH RESEARCH AND TRAINING; APPLIED RESEARCH",
      "value": "AN22",
      "type": "psc_code"
   },
   {
      "label": "AN23 - R&D- MEDICAL: DRUG DEPENDENCY (ADVANCED DEVELOPMENT)",
      "value": "AN23",
      "type": "psc_code"
   },
   {
      "label": "AN23 - DRUGS DEPENDENCY (ADVANCED)",
      "value": "AN23",
      "type": "psc_code"
   },
   {
      "label": "AN23 - HEALTH R&D SERVICES; HEALTH RESEARCH AND TRAINING; EXPERIMENTAL DEVELOPMENT",
      "value": "AN23",
      "type": "psc_code"
   },
   {
      "label": "AN24 - R&D- MEDICAL: DRUG DEPENDENCY (ENGINEERING DEVELOPMENT)",
      "value": "AN24",
      "type": "psc_code"
   },
   {
      "label": "AN24 - DRUGS DEPENDENCY (ENGINEERING)",
      "value": "AN24",
      "type": "psc_code"
   },
   {
      "label": "AN24 - HEALTH R&D SERVICES; HEALTH RESEARCH AND TRAINING; R&D ADMINISTRATIVE EXPENSES",
      "value": "AN24",
      "type": "psc_code"
   },
   {
      "label": "AN25 - DRUGS DEPENDENCY (OPERATIONAL)",
      "value": "AN25",
      "type": "psc_code"
   },
   {
      "label": "AN25 - R&D- MEDICAL: DRUG DEPENDENCY (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AN25",
      "type": "psc_code"
   },
   {
      "label": "AN25 - HEALTH R&D SERVICES; HEALTH RESEARCH AND TRAINING; EXPENSES FOR R&D FACILITIES AND MAJOR EQUIPMENT",
      "value": "AN25",
      "type": "psc_code"
   },
   {
      "label": "AN26 - R&D-DRUG DEPENDENCY-MGMT SUP",
      "value": "AN26",
      "type": "psc_code"
   },
   {
      "label": "AN26 - R&D- MEDICAL: DRUG DEPENDENCY (MANAGEMENT/SUPPORT)",
      "value": "AN26",
      "type": "psc_code"
   },
   {
      "label": "AN27 - R&D- MEDICAL: DRUG DEPENDENCY (COMMERCIALIZED)",
      "value": "AN27",
      "type": "psc_code"
   },
   {
      "label": "AN27 - R&D-DRUG DEPENDENCY-COMERCLIZ",
      "value": "AN27",
      "type": "psc_code"
   },
   {
      "label": "AN30 - R&D-ALCOHOL DEPENDENCY",
      "value": "AN30",
      "type": "psc_code"
   },
   {
      "label": "AN31 - ALCOHOL DEPENDENCY (BASIC)",
      "value": "AN31",
      "type": "psc_code"
   },
   {
      "label": "AN31 - R&D- MEDICAL: ALCOHOL DEPENDENCY (BASIC RESEARCH)",
      "value": "AN31",
      "type": "psc_code"
   },
   {
      "label": "AN31 - HEALTH R&D SERVICES; CONSUMER AND OCCUPATIONAL HEALTH AND SAFETY; BASIC RESEARCH",
      "value": "AN31",
      "type": "psc_code"
   },
   {
      "label": "AN32 - R&D- MEDICAL: ALCOHOL DEPENDENCY (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AN32",
      "type": "psc_code"
   },
   {
      "label": "AN32 - R&D-ALCOHOL DEPENDENCY-A RES/EXPL D",
      "value": "AN32",
      "type": "psc_code"
   },
   {
      "label": "AN32 - HEALTH R&D SERVICES; CONSUMER AND OCCUPATIONAL HEALTH AND SAFETY; APPLIED RESEARCH",
      "value": "AN32",
      "type": "psc_code"
   },
   {
      "label": "AN33 - ALCOHOL DEPENDENCY (ADVANCED)",
      "value": "AN33",
      "type": "psc_code"
   },
   {
      "label": "AN33 - R&D- MEDICAL: ALCOHOL DEPENDENCY (ADVANCED DEVELOPMENT)",
      "value": "AN33",
      "type": "psc_code"
   },
   {
      "label": "AN33 - HEALTH R&D SERVICES; CONSUMER AND OCCUPATIONAL HEALTH AND SAFETY; EXPERIMENTAL DEVELOPMENT",
      "value": "AN33",
      "type": "psc_code"
   },
   {
      "label": "AN34 - R&D- MEDICAL: ALCOHOL DEPENDENCY (ENGINEERING DEVELOPMENT)",
      "value": "AN34",
      "type": "psc_code"
   },
   {
      "label": "AN34 - ALCOHOL DEPENDENCY (ENGINEERING)",
      "value": "AN34",
      "type": "psc_code"
   },
   {
      "label": "AN34 - HEALTH R&D SERVICES; CONSUMER AND OCCUPATIONAL HEALTH AND SAFETY; R&D ADMINISTRATIVE EXPENSES",
      "value": "AN34",
      "type": "psc_code"
   },
   {
      "label": "AN35 - ALCOHOL DEPENDENCY (OPERATIONAL)",
      "value": "AN35",
      "type": "psc_code"
   },
   {
      "label": "AN35 - R&D- MEDICAL: ALCOHOL DEPENDENCY (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AN35",
      "type": "psc_code"
   },
   {
      "label": "AN35 - HEALTH R&D SVCS; CONSUMER & OCCUPATIONAL HEALTH & SAFETY; R&D FACILITIES & MAJ EQUIP",
      "value": "AN35",
      "type": "psc_code"
   },
   {
      "label": "AN36 - R&D- MEDICAL: ALCOHOL DEPENDENCY (MANAGEMENT/SUPPORT)",
      "value": "AN36",
      "type": "psc_code"
   },
   {
      "label": "AN36 - R&D-ALCOHOL DEPENDENCY-MGMT SUP",
      "value": "AN36",
      "type": "psc_code"
   },
   {
      "label": "AN37 - R&D-ALCOHOL DEPENDENCY-COMERCLIZ",
      "value": "AN37",
      "type": "psc_code"
   },
   {
      "label": "AN37 - R&D- MEDICAL: ALCOHOL DEPENDENCY (COMMERCIALIZED)",
      "value": "AN37",
      "type": "psc_code"
   },
   {
      "label": "AN40 - R&D-HEALTH SVCS",
      "value": "AN40",
      "type": "psc_code"
   },
   {
      "label": "AN41 - HEALTH SERVICES (BASIC)",
      "value": "AN41",
      "type": "psc_code"
   },
   {
      "label": "AN41 - R&D- MEDICAL: HEALTH SERVICES (BASIC RESEARCH)",
      "value": "AN41",
      "type": "psc_code"
   },
   {
      "label": "AN41 - HEALTH R&D SERVICES; HEALTH CARE - OTHER; BASIC RESEARCH",
      "value": "AN41",
      "type": "psc_code"
   },
   {
      "label": "AN42 - R&D-HEALTH SVCS-A RES/EXPL DEV",
      "value": "AN42",
      "type": "psc_code"
   },
   {
      "label": "AN42 - R&D- MEDICAL: HEALTH SERVICES (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AN42",
      "type": "psc_code"
   },
   {
      "label": "AN42 - HEALTH R&D SERVICES; HEALTH CARE - OTHER; APPLIED RESEARCH",
      "value": "AN42",
      "type": "psc_code"
   },
   {
      "label": "AN43 - HEALTH SERVICES (ADVANCED)",
      "value": "AN43",
      "type": "psc_code"
   },
   {
      "label": "AN43 - R&D- MEDICAL: HEALTH SERVICES (ADVANCED DEVELOPMENT)",
      "value": "AN43",
      "type": "psc_code"
   },
   {
      "label": "AN43 - HEALTH R&D SERVICES; HEALTH CARE - OTHER; EXPERIMENTAL DEVELOPMENT",
      "value": "AN43",
      "type": "psc_code"
   },
   {
      "label": "AN44 - R&D- MEDICAL: HEALTH SERVICES (ENGINEERING DEVELOPMENT)",
      "value": "AN44",
      "type": "psc_code"
   },
   {
      "label": "AN44 - HEALTH SERVICES (ENGINEERING)",
      "value": "AN44",
      "type": "psc_code"
   },
   {
      "label": "AN44 - HEALTH R&D SERVICES; HEALTH CARE - OTHER; R&D ADMINISTRATIVE EXPENSES",
      "value": "AN44",
      "type": "psc_code"
   },
   {
      "label": "AN45 - HEALTH SERVICES (OPERATIONAL)",
      "value": "AN45",
      "type": "psc_code"
   },
   {
      "label": "AN45 - R&D- MEDICAL: HEALTH SERVICES (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AN45",
      "type": "psc_code"
   },
   {
      "label": "AN45 - HEALTH R&D SERVICES; HEALTH CARE - OTHER; EXPENSES FOR R&D FACILITIES AND MAJOR EQUIPMENT",
      "value": "AN45",
      "type": "psc_code"
   },
   {
      "label": "AN46 - R&D- MEDICAL: HEALTH SERVICES (MANAGEMENT/SUPPORT)",
      "value": "AN46",
      "type": "psc_code"
   },
   {
      "label": "AN46 - R&D-HEALTH SVCS-MGMT SUP",
      "value": "AN46",
      "type": "psc_code"
   },
   {
      "label": "AN47 - R&D- MEDICAL: HEALTH SERVICES (COMMERCIALIZED)",
      "value": "AN47",
      "type": "psc_code"
   },
   {
      "label": "AN47 - R&D-HEALTH SVCS-COMERCLIZ",
      "value": "AN47",
      "type": "psc_code"
   },
   {
      "label": "AN50 - R&D-MENTAL HEALTH",
      "value": "AN50",
      "type": "psc_code"
   },
   {
      "label": "AN51 - MENTAL HEALTH (BASIC)",
      "value": "AN51",
      "type": "psc_code"
   },
   {
      "label": "AN51 - R&D- MEDICAL: MENTAL HEALTH (BASIC RESEARCH)",
      "value": "AN51",
      "type": "psc_code"
   },
   {
      "label": "AN52 - R&D- MEDICAL: MENTAL HEALTH (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AN52",
      "type": "psc_code"
   },
   {
      "label": "AN52 - MENTAL HEALTH (APPLIED/EXPLORATORY)",
      "value": "AN52",
      "type": "psc_code"
   },
   {
      "label": "AN53 - MENTAL HEALTH (ADVANCED)",
      "value": "AN53",
      "type": "psc_code"
   },
   {
      "label": "AN53 - R&D- MEDICAL: MENTAL HEALTH (ADVANCED DEVELOPMENT)",
      "value": "AN53",
      "type": "psc_code"
   },
   {
      "label": "AN54 - R&D- MEDICAL: MENTAL HEALTH (ENGINEERING DEVELOPMENT)",
      "value": "AN54",
      "type": "psc_code"
   },
   {
      "label": "AN54 - MENTAL HEALTH (ENGINEERING)",
      "value": "AN54",
      "type": "psc_code"
   },
   {
      "label": "AN55 - R&D- MEDICAL: MENTAL HEALTH (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AN55",
      "type": "psc_code"
   },
   {
      "label": "AN55 - MENTAL HEALTH (OPERATIONAL)",
      "value": "AN55",
      "type": "psc_code"
   },
   {
      "label": "AN56 - MENTAL HEALTH (MANAGEMENT/SUPPORT)",
      "value": "AN56",
      "type": "psc_code"
   },
   {
      "label": "AN56 - R&D- MEDICAL: MENTAL HEALTH (MANAGEMENT/SUPPORT)",
      "value": "AN56",
      "type": "psc_code"
   },
   {
      "label": "AN57 - R&D- MEDICAL: MENTAL HEALTH (COMMERCIALIZED)",
      "value": "AN57",
      "type": "psc_code"
   },
   {
      "label": "AN57 - R&D-MENTAL HEALTH-COMERCLIZ",
      "value": "AN57",
      "type": "psc_code"
   },
   {
      "label": "AN60 - R&D-MED REHAB ENGINEERING",
      "value": "AN60",
      "type": "psc_code"
   },
   {
      "label": "AN61 - REHABILITATIVE ENGINEERING (BASIC)",
      "value": "AN61",
      "type": "psc_code"
   },
   {
      "label": "AN61 - R&D- MEDICAL: REHABILITATIVE ENGINEERING (BASIC RESEARCH)",
      "value": "AN61",
      "type": "psc_code"
   },
   {
      "label": "AN62 - R&D- MEDICAL: REHABILITATIVE ENGINEERING (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AN62",
      "type": "psc_code"
   },
   {
      "label": "AN62 - R&D-MED REHAB ENGINEERING-A RES/EXP",
      "value": "AN62",
      "type": "psc_code"
   },
   {
      "label": "AN63 - R&D-MED REHAB ENGINEERING-ADV DEV",
      "value": "AN63",
      "type": "psc_code"
   },
   {
      "label": "AN63 - R&D- MEDICAL: REHABILITATIVE ENGINEERING (ADVANCED DEVELOPMENT)",
      "value": "AN63",
      "type": "psc_code"
   },
   {
      "label": "AN64 - R&D- MEDICAL: REHABILITATIVE ENGINEERING (ENGINEERING DEVELOPMENT)",
      "value": "AN64",
      "type": "psc_code"
   },
   {
      "label": "AN64 - R&D-MED REHAB ENGINEERING-ENG DEV",
      "value": "AN64",
      "type": "psc_code"
   },
   {
      "label": "AN65 - R&D- MEDICAL: REHABILITATIVE ENGINEERING (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AN65",
      "type": "psc_code"
   },
   {
      "label": "AN65 - R&D-MED REHAB ENGINEERING-OPSY DEV",
      "value": "AN65",
      "type": "psc_code"
   },
   {
      "label": "AN66 - R&D-MED REHAB ENGINEERING-MGMT SUP",
      "value": "AN66",
      "type": "psc_code"
   },
   {
      "label": "AN66 - R&D- MEDICAL: REHABILITATIVE ENGINEERING (MANAGEMENT/SUPPORT)",
      "value": "AN66",
      "type": "psc_code"
   },
   {
      "label": "AN67 - R&D- MEDICAL: REHABILITATIVE ENGINEERING (COMMERCIALIZED)",
      "value": "AN67",
      "type": "psc_code"
   },
   {
      "label": "AN67 - R&D-MED REHAB ENGINEERING-COMERCLIZ",
      "value": "AN67",
      "type": "psc_code"
   },
   {
      "label": "AN70 - R&D-SPEC MED SVCS",
      "value": "AN70",
      "type": "psc_code"
   },
   {
      "label": "AN71 - R&D- MEDICAL: SPECIALIZED MEDICAL SERVICES (BASIC RESEARCH)",
      "value": "AN71",
      "type": "psc_code"
   },
   {
      "label": "AN71 - R&D-SPEC MED SVCS-B RES",
      "value": "AN71",
      "type": "psc_code"
   },
   {
      "label": "AN72 - R&D-SPEC MED SVCS-A RES/EXPL DEV",
      "value": "AN72",
      "type": "psc_code"
   },
   {
      "label": "AN72 - R&D- MEDICAL: SPECIALIZED MEDICAL SERVICES (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AN72",
      "type": "psc_code"
   },
   {
      "label": "AN73 - R&D-SPEC MED SVCS-ADV DEV",
      "value": "AN73",
      "type": "psc_code"
   },
   {
      "label": "AN73 - R&D- MEDICAL: SPECIALIZED MEDICAL SERVICES (ADVANCED DEVELOPMENT)",
      "value": "AN73",
      "type": "psc_code"
   },
   {
      "label": "AN74 - R&D- MEDICAL: SPECIALIZED MEDICAL SERVICES (ENGINEERING DEVELOPMENT)",
      "value": "AN74",
      "type": "psc_code"
   },
   {
      "label": "AN74 - R&D-SPEC MED SVCS-ENG DEV",
      "value": "AN74",
      "type": "psc_code"
   },
   {
      "label": "AN75 - R&D-SPEC MED SVCS-OPSY DEV",
      "value": "AN75",
      "type": "psc_code"
   },
   {
      "label": "AN75 - R&D- MEDICAL: SPECIALIZED MEDICAL SERVICES (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AN75",
      "type": "psc_code"
   },
   {
      "label": "AN76 - R&D-SPEC MED SVCS-MGMT SUP",
      "value": "AN76",
      "type": "psc_code"
   },
   {
      "label": "AN76 - R&D- MEDICAL: SPECIALIZED MEDICAL SERVICES (MANAGEMENT/SUPPORT)",
      "value": "AN76",
      "type": "psc_code"
   },
   {
      "label": "AN77 - R&D-SPEC MED SVCS-COMERCLIZ",
      "value": "AN77",
      "type": "psc_code"
   },
   {
      "label": "AN77 - R&D- MEDICAL: SPECIALIZED MEDICAL SERVICES (COMMERCIALIZED)",
      "value": "AN77",
      "type": "psc_code"
   },
   {
      "label": "AN81 - AIDS RESEARCH (BASIC)",
      "value": "AN81",
      "type": "psc_code"
   },
   {
      "label": "AN81 - R&D- MEDICAL: AIDS RESEARCH (BASIC RESEARCH)",
      "value": "AN81",
      "type": "psc_code"
   },
   {
      "label": "AN82 - R&D- MEDICAL: AIDS RESEARCH (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AN82",
      "type": "psc_code"
   },
   {
      "label": "AN82 - AIDS RESEARCH (APPLIED/EXPLORATORY)",
      "value": "AN82",
      "type": "psc_code"
   },
   {
      "label": "AN83 - AIDS RESEARCH (ADVANCED)",
      "value": "AN83",
      "type": "psc_code"
   },
   {
      "label": "AN83 - R&D- MEDICAL: AIDS RESEARCH (ADVANCED DEVELOPMENT)",
      "value": "AN83",
      "type": "psc_code"
   },
   {
      "label": "AN84 - AIDS RESEARCH (ENGINEERING)",
      "value": "AN84",
      "type": "psc_code"
   },
   {
      "label": "AN84 - R&D- MEDICAL: AIDS RESEARCH (ENGINEERING DEVELOPMENT)",
      "value": "AN84",
      "type": "psc_code"
   },
   {
      "label": "AN85 - R&D- MEDICAL: AIDS RESEARCH (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AN85",
      "type": "psc_code"
   },
   {
      "label": "AN85 - AIDS RESEARCH (OPERATIONAL)",
      "value": "AN85",
      "type": "psc_code"
   },
   {
      "label": "AN86 - R&D- MEDICAL: AIDS RESEARCH (MANAGEMENT/SUPPORT)",
      "value": "AN86",
      "type": "psc_code"
   },
   {
      "label": "AN86 - AIDS RESEARCH (MANAGEMENT/SUPPORT)",
      "value": "AN86",
      "type": "psc_code"
   },
   {
      "label": "AN87 - R&D- MEDICAL: AIDS RESEARCH (COMMERCIALIZED)",
      "value": "AN87",
      "type": "psc_code"
   },
   {
      "label": "AN87 - RDTE/Aids Research - Operational System Develop",
      "value": "AN87",
      "type": "psc_code"
   },
   {
      "label": "AN90 - R&D-OTHER MEDICAL",
      "value": "AN90",
      "type": "psc_code"
   },
   {
      "label": "AN91 - OTHER MEDICAL (BASIC)",
      "value": "AN91",
      "type": "psc_code"
   },
   {
      "label": "AN91 - R&D- MEDICAL: OTHER (BASIC RESEARCH)",
      "value": "AN91",
      "type": "psc_code"
   },
   {
      "label": "AN92 - R&D- MEDICAL: OTHER (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AN92",
      "type": "psc_code"
   },
   {
      "label": "AN92 - OTHER MEDICAL (APPLIED/EXPLORATORY)",
      "value": "AN92",
      "type": "psc_code"
   },
   {
      "label": "AN93 - R&D- MEDICAL: OTHER (ADVANCED DEVELOPMENT)",
      "value": "AN93",
      "type": "psc_code"
   },
   {
      "label": "AN93 - OTHER MEDICAL (ADVANCED)",
      "value": "AN93",
      "type": "psc_code"
   },
   {
      "label": "AN94 - OTHER MEDICAL (ENGINEERING)",
      "value": "AN94",
      "type": "psc_code"
   },
   {
      "label": "AN94 - R&D- MEDICAL: OTHER (ENGINEERING DEVELOPMENT)",
      "value": "AN94",
      "type": "psc_code"
   },
   {
      "label": "AN95 - R&D- MEDICAL: OTHER (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AN95",
      "type": "psc_code"
   },
   {
      "label": "AN95 - OTHER MEDICAL (OPERATIONAL)",
      "value": "AN95",
      "type": "psc_code"
   },
   {
      "label": "AN96 - R&D- MEDICAL: OTHER (MANAGEMENT/SUPPORT)",
      "value": "AN96",
      "type": "psc_code"
   },
   {
      "label": "AN96 - OTHER MEDICAL (MANAGEMENT/SUPPORT)",
      "value": "AN96",
      "type": "psc_code"
   },
   {
      "label": "AN97 - R&D-OTHER MEDICAL-COMERCLIZ",
      "value": "AN97",
      "type": "psc_code"
   },
   {
      "label": "AN97 - R&D- MEDICAL: OTHER (COMMERCIALIZED)",
      "value": "AN97",
      "type": "psc_code"
   },
   {
      "label": "AP - NATURAL RESOURCES R&D",
      "value": "AP",
      "type": "psc_code"
   },
   {
      "label": "AP10 - R&D-NAT RESOURCE-AQUACUL",
      "value": "AP10",
      "type": "psc_code"
   },
   {
      "label": "AP11 - R&D-NAT RESOURCE-AQUACUL-RESEARCH",
      "value": "AP11",
      "type": "psc_code"
   },
   {
      "label": "AP12 - R&D-NAT RESOURCE-AQUACUL-EXPL DEV",
      "value": "AP12",
      "type": "psc_code"
   },
   {
      "label": "AP13 - R&D-NAT RESOURCE-AQUACUL-ADV DEV",
      "value": "AP13",
      "type": "psc_code"
   },
   {
      "label": "AP14 - R&D-NAT RESOURCE-AQUACUL-ENG DEV",
      "value": "AP14",
      "type": "psc_code"
   },
   {
      "label": "AP15 - R&D-NAT RESOURCE-AQUACUL-OPSY DEV",
      "value": "AP15",
      "type": "psc_code"
   },
   {
      "label": "AP16 - R&D-NAT RESOURCE-AQUACUL-MGMT SUP",
      "value": "AP16",
      "type": "psc_code"
   },
   {
      "label": "AP17 - R&D-NAT RESOURCE-AQUACUL-COMERCLIZ",
      "value": "AP17",
      "type": "psc_code"
   },
   {
      "label": "AP20 - R&D-NAT RESOURCE-LAND",
      "value": "AP20",
      "type": "psc_code"
   },
   {
      "label": "AP21 - LAND (BASIC)",
      "value": "AP21",
      "type": "psc_code"
   },
   {
      "label": "AP21 - R&D- NATURAL RESOURCE: LAND (BASIC RESEARCH)",
      "value": "AP21",
      "type": "psc_code"
   },
   {
      "label": "AP22 - LAND (APPLIED/EXPLORATORY)",
      "value": "AP22",
      "type": "psc_code"
   },
   {
      "label": "AP22 - R&D- NATURAL RESOURCE: LAND (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AP22",
      "type": "psc_code"
   },
   {
      "label": "AP23 - LAND (ADVANCED)",
      "value": "AP23",
      "type": "psc_code"
   },
   {
      "label": "AP23 - R&D- NATURAL RESOURCE: LAND (ADVANCED DEVELOPMENT)",
      "value": "AP23",
      "type": "psc_code"
   },
   {
      "label": "AP24 - LAND (ENGINEERING)",
      "value": "AP24",
      "type": "psc_code"
   },
   {
      "label": "AP24 - R&D- NATURAL RESOURCE: LAND (ENGINEERING DEVELOPMENT)",
      "value": "AP24",
      "type": "psc_code"
   },
   {
      "label": "AP25 - R&D- NATURAL RESOURCE: LAND (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AP25",
      "type": "psc_code"
   },
   {
      "label": "AP25 - LAND (OPERATIONAL)",
      "value": "AP25",
      "type": "psc_code"
   },
   {
      "label": "AP26 - LAND (MANAGEMENT/SUPPORT)",
      "value": "AP26",
      "type": "psc_code"
   },
   {
      "label": "AP26 - R&D- NATURAL RESOURCE: LAND (MANAGEMENT/SUPPORT)",
      "value": "AP26",
      "type": "psc_code"
   },
   {
      "label": "AP27 - R&D- NATURAL RESOURCE: LAND (COMMERCIALIZED)",
      "value": "AP27",
      "type": "psc_code"
   },
   {
      "label": "AP27 - R&D-NAT RESOURCE-LAND-COMERCLIZ",
      "value": "AP27",
      "type": "psc_code"
   },
   {
      "label": "AP30 - R&D-NAT RESOURCE-MINERAL",
      "value": "AP30",
      "type": "psc_code"
   },
   {
      "label": "AP31 - MINERAL (BASIC)",
      "value": "AP31",
      "type": "psc_code"
   },
   {
      "label": "AP31 - R&D- NATURAL RESOURCE: MINERAL (BASIC RESEARCH)",
      "value": "AP31",
      "type": "psc_code"
   },
   {
      "label": "AP32 - MINERAL (APPLIED/EXPLORATORY)",
      "value": "AP32",
      "type": "psc_code"
   },
   {
      "label": "AP32 - R&D- NATURAL RESOURCE: MINERAL (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AP32",
      "type": "psc_code"
   },
   {
      "label": "AP33 - MINERAL (ADVANCED)",
      "value": "AP33",
      "type": "psc_code"
   },
   {
      "label": "AP33 - R&D- NATURAL RESOURCE: MINERAL (ADVANCED DEVELOPMENT)",
      "value": "AP33",
      "type": "psc_code"
   },
   {
      "label": "AP34 - MINERAL (ENGINEERING)",
      "value": "AP34",
      "type": "psc_code"
   },
   {
      "label": "AP34 - R&D- NATURAL RESOURCE: MINERAL (ENGINEERING DEVELOPMENT)",
      "value": "AP34",
      "type": "psc_code"
   },
   {
      "label": "AP35 - R&D- NATURAL RESOURCE: MINERAL (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AP35",
      "type": "psc_code"
   },
   {
      "label": "AP35 - MINERAL (OPERATIONAL)",
      "value": "AP35",
      "type": "psc_code"
   },
   {
      "label": "AP36 - MINERAL (MANAGEMENT/SUPPORT)",
      "value": "AP36",
      "type": "psc_code"
   },
   {
      "label": "AP36 - R&D- NATURAL RESOURCE: MINERAL (MANAGEMENT/SUPPORT)",
      "value": "AP36",
      "type": "psc_code"
   },
   {
      "label": "AP37 - R&D-NAT RESOURCE-MINERAL-COMERCLIZ",
      "value": "AP37",
      "type": "psc_code"
   },
   {
      "label": "AP37 - R&D- NATURAL RESOURCE: MINERAL (COMMERCIALIZED)",
      "value": "AP37",
      "type": "psc_code"
   },
   {
      "label": "AP40 - R&D-NAT RESOURCE-RECREATN",
      "value": "AP40",
      "type": "psc_code"
   },
   {
      "label": "AP41 - R&D- NATURAL RESOURCE: RECREATION (BASIC RESEARCH)",
      "value": "AP41",
      "type": "psc_code"
   },
   {
      "label": "AP41 - RECREATION (BASIC)",
      "value": "AP41",
      "type": "psc_code"
   },
   {
      "label": "AP42 - RECREATION (APPLIED/EXPLORATORY)",
      "value": "AP42",
      "type": "psc_code"
   },
   {
      "label": "AP42 - R&D- NATURAL RESOURCE: RECREATION (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AP42",
      "type": "psc_code"
   },
   {
      "label": "AP43 - RECREATION (ADVANCED)",
      "value": "AP43",
      "type": "psc_code"
   },
   {
      "label": "AP43 - R&D- NATURAL RESOURCE: RECREATION (ADVANCED DEVELOPMENT)",
      "value": "AP43",
      "type": "psc_code"
   },
   {
      "label": "AP44 - R&D- NATURAL RESOURCE: RECREATION (ENGINEERING DEVELOPMENT)",
      "value": "AP44",
      "type": "psc_code"
   },
   {
      "label": "AP44 - RECREATION (ENGINEERING)",
      "value": "AP44",
      "type": "psc_code"
   },
   {
      "label": "AP45 - R&D- NATURAL RESOURCE: RECREATION (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AP45",
      "type": "psc_code"
   },
   {
      "label": "AP45 - RECREATION (OPERATIONAL)",
      "value": "AP45",
      "type": "psc_code"
   },
   {
      "label": "AP46 - R&D- NATURAL RESOURCE: RECREATION (MANAGEMENT/SUPPORT)",
      "value": "AP46",
      "type": "psc_code"
   },
   {
      "label": "AP46 - RECREATION (MANAGEMENT/SUPPORT)",
      "value": "AP46",
      "type": "psc_code"
   },
   {
      "label": "AP47 - R&D-NAT RESOURCE-RECREATN-COMERCLIZ",
      "value": "AP47",
      "type": "psc_code"
   },
   {
      "label": "AP47 - R&D- NATURAL RESOURCE: RECREATION (COMMERCIALIZED)",
      "value": "AP47",
      "type": "psc_code"
   },
   {
      "label": "AP50 - R&D-NAT RES-MARINE & OCEAN",
      "value": "AP50",
      "type": "psc_code"
   },
   {
      "label": "AP51 - MARINE AND OCEANOGRAPHIC (BASIC)",
      "value": "AP51",
      "type": "psc_code"
   },
   {
      "label": "AP51 - R&D- NATURAL RESOURCE: MARINE AND OCEANOGRAPHIC (BASIC RESEARCH)",
      "value": "AP51",
      "type": "psc_code"
   },
   {
      "label": "AP52 - R&D- NATURAL RESOURCE: MARINE AND OCEANOGRAPHIC (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AP52",
      "type": "psc_code"
   },
   {
      "label": "AP52 - R&D-NAT RES-MARINE & OCEAN-A RES/EX",
      "value": "AP52",
      "type": "psc_code"
   },
   {
      "label": "AP53 - MARINE AND OCEANOGRAPHIC (ADVANCED)",
      "value": "AP53",
      "type": "psc_code"
   },
   {
      "label": "AP53 - R&D- NATURAL RESOURCE: MARINE AND OCEANOGRAPHIC (ADVANCED DEVELOPMENT)",
      "value": "AP53",
      "type": "psc_code"
   },
   {
      "label": "AP54 - R&D-NAT RES-MARINE & OCEAN-ENG DEV",
      "value": "AP54",
      "type": "psc_code"
   },
   {
      "label": "AP54 - R&D- NATURAL RESOURCE: MARINE AND OCEANOGRAPHIC (ENGINEERING DEVELOPMENT)",
      "value": "AP54",
      "type": "psc_code"
   },
   {
      "label": "AP55 - R&D-NAT RES-MARINE & OCEAN-OPSY DEV",
      "value": "AP55",
      "type": "psc_code"
   },
   {
      "label": "AP55 - R&D- NATURAL RESOURCE: MARINE AND OCEANOGRAPHIC (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AP55",
      "type": "psc_code"
   },
   {
      "label": "AP56 - R&D-NAT RES-MARINE & OCEAN-MGMT SUP",
      "value": "AP56",
      "type": "psc_code"
   },
   {
      "label": "AP56 - R&D- NATURAL RESOURCE: MARINE AND OCEANOGRAPHIC (MANAGEMENT/SUPPORT)",
      "value": "AP56",
      "type": "psc_code"
   },
   {
      "label": "AP57 - R&D-NAT RESOURCE-MARINE-COMERCLIZ",
      "value": "AP57",
      "type": "psc_code"
   },
   {
      "label": "AP57 - R&D- NATURAL RESOURCE: MARINE AND OCEANOGRAPHIC (COMMERCIALIZED)",
      "value": "AP57",
      "type": "psc_code"
   },
   {
      "label": "AP61 - MARINE FISHERIES (BASIC)",
      "value": "AP61",
      "type": "psc_code"
   },
   {
      "label": "AP61 - R&D- NATURAL RESOURCE: MARINE FISHERIES (BASIC RESEARCH)",
      "value": "AP61",
      "type": "psc_code"
   },
   {
      "label": "AP62 - R&D-NAT RES-MARINE FISHERIES-A RES/",
      "value": "AP62",
      "type": "psc_code"
   },
   {
      "label": "AP62 - R&D- NATURAL RESOURCE: MARINE FISHERIES (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AP62",
      "type": "psc_code"
   },
   {
      "label": "AP63 - R&D- NATURAL RESOURCE: MARINE FISHERIES (ADVANCED DEVELOPMENT)",
      "value": "AP63",
      "type": "psc_code"
   },
   {
      "label": "AP63 - MARINE FISHERIES (ADVANCED)",
      "value": "AP63",
      "type": "psc_code"
   },
   {
      "label": "AP64 - R&D- NATURAL RESOURCE: MARINE FISHERIES (ENGINEERING DEVELOPMENT)",
      "value": "AP64",
      "type": "psc_code"
   },
   {
      "label": "AP64 - MARINE FISHERIES (ENGINEERING)",
      "value": "AP64",
      "type": "psc_code"
   },
   {
      "label": "AP65 - R&D- NATURAL RESOURCE: MARINE FISHERIES (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AP65",
      "type": "psc_code"
   },
   {
      "label": "AP65 - MARINE FISHERIES (OPERATIONAL)",
      "value": "AP65",
      "type": "psc_code"
   },
   {
      "label": "AP66 - R&D-NAT RES-MARINE FISHERIES-MGMT S",
      "value": "AP66",
      "type": "psc_code"
   },
   {
      "label": "AP66 - R&D- NATURAL RESOURCE: MARINE FISHERIES (MANAGEMENT/SUPPORT)",
      "value": "AP66",
      "type": "psc_code"
   },
   {
      "label": "AP67 - RDTE/Marine Fisheries - Operational System Dev",
      "value": "AP67",
      "type": "psc_code"
   },
   {
      "label": "AP67 - R&D- NATURAL RESOURCE: MARINE FISHERIES (COMMERCIALIZED)",
      "value": "AP67",
      "type": "psc_code"
   },
   {
      "label": "AP71 - ATMOSPHERIC (BASIC)",
      "value": "AP71",
      "type": "psc_code"
   },
   {
      "label": "AP71 - R&D- NATURAL RESOURCE: ATMOSPHERIC (BASIC RESEARCH)",
      "value": "AP71",
      "type": "psc_code"
   },
   {
      "label": "AP72 - R&D- NATURAL RESOURCE: ATMOSPHERIC (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AP72",
      "type": "psc_code"
   },
   {
      "label": "AP72 - ATMOSPHERIC (APPLIED/EXPLORATORY)",
      "value": "AP72",
      "type": "psc_code"
   },
   {
      "label": "AP73 - R&D- NATURAL RESOURCE: ATMOSPHERIC (ADVANCED DEVELOPMENT)",
      "value": "AP73",
      "type": "psc_code"
   },
   {
      "label": "AP73 - ATMOSPHERIC (ADVANCED)",
      "value": "AP73",
      "type": "psc_code"
   },
   {
      "label": "AP74 - ATMOSPHERIC (ENGINEERING)",
      "value": "AP74",
      "type": "psc_code"
   },
   {
      "label": "AP74 - R&D- NATURAL RESOURCE: ATMOSPHERIC (ENGINEERING DEVELOPMENT)",
      "value": "AP74",
      "type": "psc_code"
   },
   {
      "label": "AP75 - ATMOSPHERIC (OPERATIONAL)",
      "value": "AP75",
      "type": "psc_code"
   },
   {
      "label": "AP75 - R&D- NATURAL RESOURCE: ATMOSPHERIC (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AP75",
      "type": "psc_code"
   },
   {
      "label": "AP76 - R&D- NATURAL RESOURCE: ATMOSPHERIC (MANAGEMENT/SUPPORT)",
      "value": "AP76",
      "type": "psc_code"
   },
   {
      "label": "AP76 - ATMOSPHERIC (MANAGEMENT/SUPPORT)",
      "value": "AP76",
      "type": "psc_code"
   },
   {
      "label": "AP77 - R&D- NATURAL RESOURCE: ATMOSPHERIC (COMMERCIALIZED)",
      "value": "AP77",
      "type": "psc_code"
   },
   {
      "label": "AP77 - RDTE/Natural Resources-Atmospheric-Op System Dev",
      "value": "AP77",
      "type": "psc_code"
   },
   {
      "label": "AP90 - R&D-OTHER NAT RESOURCE",
      "value": "AP90",
      "type": "psc_code"
   },
   {
      "label": "AP91 - R&D- NATURAL RESOURCE: OTHER (BASIC RESEARCH)",
      "value": "AP91",
      "type": "psc_code"
   },
   {
      "label": "AP91 - OTHER NATURAL RESOURCES (BASIC)",
      "value": "AP91",
      "type": "psc_code"
   },
   {
      "label": "AP92 - R&D-OTHER NAT RESOURCE-A RES/EXPL D",
      "value": "AP92",
      "type": "psc_code"
   },
   {
      "label": "AP92 - R&D- NATURAL RESOURCE: OTHER (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AP92",
      "type": "psc_code"
   },
   {
      "label": "AP93 - OTHER NATURAL RESOURCES (ADVANCED)",
      "value": "AP93",
      "type": "psc_code"
   },
   {
      "label": "AP93 - R&D- NATURAL RESOURCE: OTHER (ADVANCED DEVELOPMENT)",
      "value": "AP93",
      "type": "psc_code"
   },
   {
      "label": "AP94 - R&D-OTHER NAT RESOURCE-ENG DEV",
      "value": "AP94",
      "type": "psc_code"
   },
   {
      "label": "AP94 - R&D- NATURAL RESOURCE: OTHER (ENGINEERING DEVELOPMENT)",
      "value": "AP94",
      "type": "psc_code"
   },
   {
      "label": "AP95 - R&D-OTHER NAT RESOURCE-OPSY DEV",
      "value": "AP95",
      "type": "psc_code"
   },
   {
      "label": "AP95 - R&D- NATURAL RESOURCE: OTHER (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AP95",
      "type": "psc_code"
   },
   {
      "label": "AP96 - R&D- NATURAL RESOURCE: OTHER (MANAGEMENT/SUPPORT)",
      "value": "AP96",
      "type": "psc_code"
   },
   {
      "label": "AP96 - R&D-OTHER NAT RESOURCE-MGMT SUP",
      "value": "AP96",
      "type": "psc_code"
   },
   {
      "label": "AP97 - R&D- NATURAL RESOURCE: OTHER (COMMERCIALIZED)",
      "value": "AP97",
      "type": "psc_code"
   },
   {
      "label": "AP97 - R&D-OTHER NAT RESOURCE-COMERCLIZ",
      "value": "AP97",
      "type": "psc_code"
   },
   {
      "label": "AQ - SOCIAL SERVICES R&D",
      "value": "AQ",
      "type": "psc_code"
   },
   {
      "label": "AQ10 - R&D-SOCIAL SVCS-GERIATRIC",
      "value": "AQ10",
      "type": "psc_code"
   },
   {
      "label": "AQ11 - R&D-SOCIAL SVCS-GERIATRIC-B RES",
      "value": "AQ11",
      "type": "psc_code"
   },
   {
      "label": "AQ11 - R&D- SOCIAL SERVICES: GERIATRIC OTHER THAN MEDICAL (BASIC RESEARCH)",
      "value": "AQ11",
      "type": "psc_code"
   },
   {
      "label": "AQ12 - R&D- SOCIAL SERVICES: GERIATRIC OTHER THAN MEDICAL (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AQ12",
      "type": "psc_code"
   },
   {
      "label": "AQ12 - R&D-SOCIAL SVCS-GERIATRIC-A RES/EXP",
      "value": "AQ12",
      "type": "psc_code"
   },
   {
      "label": "AQ13 - R&D-SOCIAL SVCS-GERIATRIC-ADV DEV",
      "value": "AQ13",
      "type": "psc_code"
   },
   {
      "label": "AQ13 - R&D- SOCIAL SERVICES: GERIATRIC OTHER THAN MEDICAL (ADVANCED DEVELOPMENT)",
      "value": "AQ13",
      "type": "psc_code"
   },
   {
      "label": "AQ14 - R&D-SOCIAL SVCS-GERIATRIC-ENG DEV",
      "value": "AQ14",
      "type": "psc_code"
   },
   {
      "label": "AQ14 - R&D- SOCIAL SERVICES: GERIATRIC OTHER THAN MEDICAL (ENGINEERING DEVELOPMENT)",
      "value": "AQ14",
      "type": "psc_code"
   },
   {
      "label": "AQ15 - R&D-SOCIAL SVCS-GERIATRIC-OPSY DEV",
      "value": "AQ15",
      "type": "psc_code"
   },
   {
      "label": "AQ15 - R&D- SOCIAL SERVICES: GERIATRIC OTHER THAN MEDICAL (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AQ15",
      "type": "psc_code"
   },
   {
      "label": "AQ16 - R&D- SOCIAL SERVICES: GERIATRIC OTHER THAN MEDICAL (MANAGEMENT/SUPPORT)",
      "value": "AQ16",
      "type": "psc_code"
   },
   {
      "label": "AQ16 - R&D-SOCIAL SVCS-GERIATRIC-MGMT SUP",
      "value": "AQ16",
      "type": "psc_code"
   },
   {
      "label": "AQ17 - R&D-SOCIAL SVCS-GERIATRIC-COMERCLIZ",
      "value": "AQ17",
      "type": "psc_code"
   },
   {
      "label": "AQ17 - R&D- SOCIAL SERVICES: GERIATRIC OTHER THAN MEDICAL (COMMERCIALIZED)",
      "value": "AQ17",
      "type": "psc_code"
   },
   {
      "label": "AQ90 - R&D-OTHER SOCIAL SVCS",
      "value": "AQ90",
      "type": "psc_code"
   },
   {
      "label": "AQ91 - R&D- SOCIAL SERVICES: OTHER (BASIC RESEARCH)",
      "value": "AQ91",
      "type": "psc_code"
   },
   {
      "label": "AQ91 - OTHER SOCIAL SERVICES (BASIC)",
      "value": "AQ91",
      "type": "psc_code"
   },
   {
      "label": "AQ92 - R&D- SOCIAL SERVICES: OTHER (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AQ92",
      "type": "psc_code"
   },
   {
      "label": "AQ92 - R&D-OTHER SOCIAL SVCS-A RES/EXPL DE",
      "value": "AQ92",
      "type": "psc_code"
   },
   {
      "label": "AQ93 - OTHER SOCIAL SERVICES (ADVANCED)",
      "value": "AQ93",
      "type": "psc_code"
   },
   {
      "label": "AQ93 - R&D- SOCIAL SERVICES: OTHER (ADVANCED DEVELOPMENT)",
      "value": "AQ93",
      "type": "psc_code"
   },
   {
      "label": "AQ94 - OTHER SOCIAL SERVICES (ENGINEERING)",
      "value": "AQ94",
      "type": "psc_code"
   },
   {
      "label": "AQ94 - R&D- SOCIAL SERVICES: OTHER (ENGINEERING DEVELOPMENT)",
      "value": "AQ94",
      "type": "psc_code"
   },
   {
      "label": "AQ95 - R&D- SOCIAL SERVICES: OTHER (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AQ95",
      "type": "psc_code"
   },
   {
      "label": "AQ95 - OTHER SOCIAL SERVICES (OPERATIONAL)",
      "value": "AQ95",
      "type": "psc_code"
   },
   {
      "label": "AQ96 - R&D-OTHER SOCIAL SVCS-MGMT SUP",
      "value": "AQ96",
      "type": "psc_code"
   },
   {
      "label": "AQ96 - R&D- SOCIAL SERVICES: OTHER (MANAGEMENT/SUPPORT)",
      "value": "AQ96",
      "type": "psc_code"
   },
   {
      "label": "AQ97 - R&D- SOCIAL SERVICES: OTHER (COMMERCIALIZED)",
      "value": "AQ97",
      "type": "psc_code"
   },
   {
      "label": "AQ97 - R&D-OTHER SOCIAL SVCS-COMERCLIZ",
      "value": "AQ97",
      "type": "psc_code"
   },
   {
      "label": "AR - SPACE R&D",
      "value": "AR",
      "type": "psc_code"
   },
   {
      "label": "AR - Space R&D Services",
      "value": "AR",
      "type": "psc_code"
   },
   {
      "label": "AR10 - R&D-AERO & SPACE TECH",
      "value": "AR10",
      "type": "psc_code"
   },
   {
      "label": "AR11 - R&D- SPACE: AERONAUTICS/SPACE TECHNOLOGY (BASIC RESEARCH)",
      "value": "AR11",
      "type": "psc_code"
   },
   {
      "label": "AR11 - R&D-AERO & SPACE TECH-B RES",
      "value": "AR11",
      "type": "psc_code"
   },
   {
      "label": "AR11 - SPACE R&D SERVICES; SPACE FLIGHT, RESEARCH AND SUPPORTING ACTIVITIES; BASIC RESEARCH",
      "value": "AR11",
      "type": "psc_code"
   },
   {
      "label": "AR12 - R&D-AERO & SPACE TECH-A RES/EXPL DE",
      "value": "AR12",
      "type": "psc_code"
   },
   {
      "label": "AR12 - R&D- SPACE: AERONAUTICS/SPACE TECHNOLOGY (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AR12",
      "type": "psc_code"
   },
   {
      "label": "AR12 - SPACE R&D SERVICES; SPACE FLIGHT, RESEARCH AND SUPPORTING ACTIVITIES; APPLIED RESEARCH",
      "value": "AR12",
      "type": "psc_code"
   },
   {
      "label": "AR13 - R&D-AERO & SPACE TECH-ADV DEV",
      "value": "AR13",
      "type": "psc_code"
   },
   {
      "label": "AR13 - R&D- SPACE: AERONAUTICS/SPACE TECHNOLOGY (ADVANCED DEVELOPMENT)",
      "value": "AR13",
      "type": "psc_code"
   },
   {
      "label": "AR13 - SPACE R&D SERVICES; SPACE FLIGHT, RESEARCH AND SUPPORTING ACTIVITIES; EXPERIMENTAL DEVELOPMENT",
      "value": "AR13",
      "type": "psc_code"
   },
   {
      "label": "AR14 - R&D- SPACE: AERONAUTICS/SPACE TECHNOLOGY (ENGINEERING DEVELOPMENT)",
      "value": "AR14",
      "type": "psc_code"
   },
   {
      "label": "AR14 - R&D-AERO & SPACE TECH-ENG DEV",
      "value": "AR14",
      "type": "psc_code"
   },
   {
      "label": "AR14 - SPACE R&D SERVICES; SPACE FLIGHT, RESEARCH AND SUPPORTING ACTIVITIES; R&D ADMINISTRATIVE EXPENSES",
      "value": "AR14",
      "type": "psc_code"
   },
   {
      "label": "AR15 - R&D- SPACE: AERONAUTICS/SPACE TECHNOLOGY (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AR15",
      "type": "psc_code"
   },
   {
      "label": "AR15 - R&D-AERO & SPACE TECH-OPSY DEV",
      "value": "AR15",
      "type": "psc_code"
   },
   {
      "label": "AR15 - SPACE R&D SVCS; SPACE FLIGHT, RESEARCH & SUPPORTING ACTIVITIES; R&D FACILITIES & MAJ EQUIP",
      "value": "AR15",
      "type": "psc_code"
   },
   {
      "label": "AR16 - R&D-AERO & SPACE TECH-MGMT SUP",
      "value": "AR16",
      "type": "psc_code"
   },
   {
      "label": "AR16 - R&D- SPACE: AERONAUTICS/SPACE TECHNOLOGY (MANAGEMENT/SUPPORT)",
      "value": "AR16",
      "type": "psc_code"
   },
   {
      "label": "AR17 - R&D-AERO & SPACE TECH-COMERCLIZ",
      "value": "AR17",
      "type": "psc_code"
   },
   {
      "label": "AR17 - R&D- SPACE: AERONAUTICS/SPACE TECHNOLOGY (COMMERCIALIZED)",
      "value": "AR17",
      "type": "psc_code"
   },
   {
      "label": "AR20 - R&D-SPACE SCIENCE & APPL",
      "value": "AR20",
      "type": "psc_code"
   },
   {
      "label": "AR21 - R&D-SPACE SCIENCE & APPL-B RES",
      "value": "AR21",
      "type": "psc_code"
   },
   {
      "label": "AR21 - R&D- SPACE: SCIENCE/APPLICATIONS (BASIC RESEARCH)",
      "value": "AR21",
      "type": "psc_code"
   },
   {
      "label": "AR22 - R&D-SPACE SCIENCE & APPL-A RES/EXPL",
      "value": "AR22",
      "type": "psc_code"
   },
   {
      "label": "AR22 - R&D- SPACE: SCIENCE/APPLICATIONS (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AR22",
      "type": "psc_code"
   },
   {
      "label": "AR23 - R&D- SPACE: SCIENCE/APPLICATIONS (ADVANCED DEVELOPMENT)",
      "value": "AR23",
      "type": "psc_code"
   },
   {
      "label": "AR23 - R&D-SPACE SCIENCE & APPL-ADV DEV",
      "value": "AR23",
      "type": "psc_code"
   },
   {
      "label": "AR24 - R&D-SPACE SCIENCE & APPL-ENG DEV",
      "value": "AR24",
      "type": "psc_code"
   },
   {
      "label": "AR24 - R&D- SPACE: SCIENCE/APPLICATIONS (ENGINEERING DEVELOPMENT)",
      "value": "AR24",
      "type": "psc_code"
   },
   {
      "label": "AR25 - R&D- SPACE: SCIENCE/APPLICATIONS (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AR25",
      "type": "psc_code"
   },
   {
      "label": "AR25 - R&D-SPACE SCIENCE & APPL-OPSY DEV",
      "value": "AR25",
      "type": "psc_code"
   },
   {
      "label": "AR26 - R&D- SPACE: SCIENCE/APPLICATIONS (MANAGEMENT/SUPPORT)",
      "value": "AR26",
      "type": "psc_code"
   },
   {
      "label": "AR26 - R&D-SPACE SCIENCE & APPL-MGMT SUP",
      "value": "AR26",
      "type": "psc_code"
   },
   {
      "label": "AR27 - R&D-SPACE SCIENCE-COMERCLIZ",
      "value": "AR27",
      "type": "psc_code"
   },
   {
      "label": "AR27 - R&D- SPACE: SCIENCE/APPLICATIONS (COMMERCIALIZED)",
      "value": "AR27",
      "type": "psc_code"
   },
   {
      "label": "AR30 - R&D-SPACE TRANS SYSTEMS",
      "value": "AR30",
      "type": "psc_code"
   },
   {
      "label": "AR31 - R&D- SPACE: FLIGHT (BASIC RESEARCH)",
      "value": "AR31",
      "type": "psc_code"
   },
   {
      "label": "AR31 - SPACE FLIGHT (BASIC)",
      "value": "AR31",
      "type": "psc_code"
   },
   {
      "label": "AR32 - R&D- SPACE: FLIGHT (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AR32",
      "type": "psc_code"
   },
   {
      "label": "AR32 - SPACE FLIGHT (APPLIED/EXPLORATORY)",
      "value": "AR32",
      "type": "psc_code"
   },
   {
      "label": "AR33 - R&D- SPACE: FLIGHT (ADVANCED DEVELOPMENT)",
      "value": "AR33",
      "type": "psc_code"
   },
   {
      "label": "AR33 - SPACE FLIGHT (ADVANCED)",
      "value": "AR33",
      "type": "psc_code"
   },
   {
      "label": "AR34 - SPACE FLIGHT (ENGINEERING)",
      "value": "AR34",
      "type": "psc_code"
   },
   {
      "label": "AR34 - R&D- SPACE: FLIGHT (ENGINEERING DEVELOPMENT)",
      "value": "AR34",
      "type": "psc_code"
   },
   {
      "label": "AR35 - SPACE FLIGHT (OPERATIONAL)",
      "value": "AR35",
      "type": "psc_code"
   },
   {
      "label": "AR35 - R&D- SPACE: FLIGHT (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AR35",
      "type": "psc_code"
   },
   {
      "label": "AR36 - R&D- SPACE: FLIGHT (MANAGEMENT/SUPPORT)",
      "value": "AR36",
      "type": "psc_code"
   },
   {
      "label": "AR36 - SPACE FLIGHT (MANAGEMENT/SUPPORT)",
      "value": "AR36",
      "type": "psc_code"
   },
   {
      "label": "AR37 - R&D-SPACE TRANS SYSTEMS -COMERCLIZ",
      "value": "AR37",
      "type": "psc_code"
   },
   {
      "label": "AR37 - R&D- SPACE: FLIGHT (COMMERCIALIZED)",
      "value": "AR37",
      "type": "psc_code"
   },
   {
      "label": "AR40 - R&D-SPACE TRACK DATA ACQ",
      "value": "AR40",
      "type": "psc_code"
   },
   {
      "label": "AR41 - R&D-SPACE TRACK DATA ACQ-B RES",
      "value": "AR41",
      "type": "psc_code"
   },
   {
      "label": "AR41 - R&D- SPACE: OPERATIONS, TRACKING AND DATA ACQUISITION (BASIC RESEARCH)",
      "value": "AR41",
      "type": "psc_code"
   },
   {
      "label": "AR42 - R&D-SPACE TRACK DATA ACQ-A RES/EXPL",
      "value": "AR42",
      "type": "psc_code"
   },
   {
      "label": "AR42 - R&D- SPACE: OPERATIONS, TRACKING AND DATA ACQUISITION (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AR42",
      "type": "psc_code"
   },
   {
      "label": "AR43 - R&D-SPACE TRACK DATA ACQ-ADV DEV",
      "value": "AR43",
      "type": "psc_code"
   },
   {
      "label": "AR43 - R&D- SPACE: OPERATIONS, TRACKING AND DATA ACQUISITION (ADVANCED DEVELOPMENT)",
      "value": "AR43",
      "type": "psc_code"
   },
   {
      "label": "AR44 - R&D-SPACE TRACK DATA ACQ-ENG DEV",
      "value": "AR44",
      "type": "psc_code"
   },
   {
      "label": "AR44 - R&D- SPACE: OPERATIONS, TRACKING AND DATA ACQUISITION (ENGINEERING DEVELOPMENT)",
      "value": "AR44",
      "type": "psc_code"
   },
   {
      "label": "AR45 - R&D- SPACE: OPERATIONS, TRACKING AND DATA ACQUISITION (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AR45",
      "type": "psc_code"
   },
   {
      "label": "AR45 - R&D-SPACE TRACK DATA ACQ-OPSY DEV",
      "value": "AR45",
      "type": "psc_code"
   },
   {
      "label": "AR46 - R&D-SPACE TRACK DATA ACQ-MGMT SUP",
      "value": "AR46",
      "type": "psc_code"
   },
   {
      "label": "AR46 - R&D- SPACE: OPERATIONS, TRACKING AND DATA ACQUISITION (MANAGEMENT/SUPPORT)",
      "value": "AR46",
      "type": "psc_code"
   },
   {
      "label": "AR47 - R&D- SPACE: OPERATIONS, TRACKING AND DATA ACQUISITION (COMMERCIALIZED)",
      "value": "AR47",
      "type": "psc_code"
   },
   {
      "label": "AR47 - R&D-SPACE TRACK DATA ACQ-COMERCLIZ",
      "value": "AR47",
      "type": "psc_code"
   },
   {
      "label": "AR50 - R&D-SPACE & TERRESTRIAL",
      "value": "AR50",
      "type": "psc_code"
   },
   {
      "label": "AR51 - R&D-SPACE & TERRESTRIAL-RESEARCH",
      "value": "AR51",
      "type": "psc_code"
   },
   {
      "label": "AR52 - RDTE/SP+TERRESTRIAL APP-APPLIED RES",
      "value": "AR52",
      "type": "psc_code"
   },
   {
      "label": "AR53 - R&D-SPACE & TERRESTRIAL-ADV DEV",
      "value": "AR53",
      "type": "psc_code"
   },
   {
      "label": "AR54 - RDTE/SP+TERRESTRIAL APP-DEMO/VALID",
      "value": "AR54",
      "type": "psc_code"
   },
   {
      "label": "AR55 - RDTE/SP+TERRESTRIAL APP-ENG/MANUF",
      "value": "AR55",
      "type": "psc_code"
   },
   {
      "label": "AR56 - R&D-SPACE & TERRESTRIAL-MGMT SUP",
      "value": "AR56",
      "type": "psc_code"
   },
   {
      "label": "AR57 - R&D-SPACE & TERRESTRIAL-COMERCLIZ",
      "value": "AR57",
      "type": "psc_code"
   },
   {
      "label": "",
      "value": "AR60",
      "type": "psc_code"
   },
   {
      "label": "AR61 - R&D-SPACE STATION-B RES",
      "value": "AR61",
      "type": "psc_code"
   },
   {
      "label": "AR61 - R&D- SPACE: STATION (BASIC RESEARCH)",
      "value": "AR61",
      "type": "psc_code"
   },
   {
      "label": "AR62 - R&D- SPACE: STATION (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AR62",
      "type": "psc_code"
   },
   {
      "label": "AR62 - R&D-SPACE STATION-A RES/EXP DEV",
      "value": "AR62",
      "type": "psc_code"
   },
   {
      "label": "AR63 - R&D-SPACE STATION-ADV DEV",
      "value": "AR63",
      "type": "psc_code"
   },
   {
      "label": "AR63 - R&D- SPACE: STATION (ADVANCED DEVELOPMENT)",
      "value": "AR63",
      "type": "psc_code"
   },
   {
      "label": "AR64 - R&D-SPACE STATION-ENG DEV",
      "value": "AR64",
      "type": "psc_code"
   },
   {
      "label": "AR64 - R&D- SPACE: STATION (ENGINEERING DEVELOPMENT)",
      "value": "AR64",
      "type": "psc_code"
   },
   {
      "label": "AR65 - R&D-SPACE STATION-OPSY DEV",
      "value": "AR65",
      "type": "psc_code"
   },
   {
      "label": "AR65 - R&D- SPACE: STATION (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AR65",
      "type": "psc_code"
   },
   {
      "label": "AR66 - R&D- SPACE: STATION (MANAGEMENT/SUPPORT)",
      "value": "AR66",
      "type": "psc_code"
   },
   {
      "label": "AR66 - R&D-SPACE STATION-MGMT SUP",
      "value": "AR66",
      "type": "psc_code"
   },
   {
      "label": "AR67 - R&D- SPACE: STATION (COMMERCIALIZED)",
      "value": "AR67",
      "type": "psc_code"
   },
   {
      "label": "AR67 - R&D-SPACE STATION-COMERCLIZ",
      "value": "AR67",
      "type": "psc_code"
   },
   {
      "label": "",
      "value": "AR70",
      "type": "psc_code"
   },
   {
      "label": "AR71 - R&D-COMMERCIAL PROGRAMS-B RES",
      "value": "AR71",
      "type": "psc_code"
   },
   {
      "label": "AR71 - R&D- SPACE: COMMERCIAL PROGRAMS (BASIC RESEARCH)",
      "value": "AR71",
      "type": "psc_code"
   },
   {
      "label": "AR72 - R&D-COMMERCIAL PROGRAMS A RES/EXP D",
      "value": "AR72",
      "type": "psc_code"
   },
   {
      "label": "AR72 - R&D- SPACE: COMMERCIAL PROGRAMS (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AR72",
      "type": "psc_code"
   },
   {
      "label": "AR73 - R&D- SPACE: COMMERCIAL PROGRAMS (ADVANCED DEVELOPMENT)",
      "value": "AR73",
      "type": "psc_code"
   },
   {
      "label": "AR73 - R&D-COMMERCIAL PROGRAMS-ADV DEV",
      "value": "AR73",
      "type": "psc_code"
   },
   {
      "label": "AR74 - R&D-COMMERCIAL PROGRAMS-ENG DEV",
      "value": "AR74",
      "type": "psc_code"
   },
   {
      "label": "AR74 - R&D- SPACE: COMMERCIAL PROGRAMS (ENGINEERING DEVELOPMENT)",
      "value": "AR74",
      "type": "psc_code"
   },
   {
      "label": "AR75 - R&D-COMMERCIAL PROGRAMS-OPSY DEV",
      "value": "AR75",
      "type": "psc_code"
   },
   {
      "label": "AR75 - R&D- SPACE: COMMERCIAL PROGRAMS (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AR75",
      "type": "psc_code"
   },
   {
      "label": "AR76 - R&D- SPACE: COMMERCIAL PROGRAMS (MANAGEMENT/SUPPORT)",
      "value": "AR76",
      "type": "psc_code"
   },
   {
      "label": "AR76 - R&D-COMMERCIAL PROGRAMS-MGMT SUP",
      "value": "AR76",
      "type": "psc_code"
   },
   {
      "label": "AR77 - R&D- SPACE: COMMERCIAL PROGRAMS (COMMERCIALIZED)",
      "value": "AR77",
      "type": "psc_code"
   },
   {
      "label": "AR77 - R&D-COMMERCIAL PROGRAMS-COMERCLIZ",
      "value": "AR77",
      "type": "psc_code"
   },
   {
      "label": "AR90 - R&D-OTHER SPACE",
      "value": "AR90",
      "type": "psc_code"
   },
   {
      "label": "AR91 - R&D-OTHER SPACE-B RES",
      "value": "AR91",
      "type": "psc_code"
   },
   {
      "label": "AR91 - R&D- SPACE: OTHER (BASIC RESEARCH)",
      "value": "AR91",
      "type": "psc_code"
   },
   {
      "label": "AR92 - R&D- SPACE: OTHER (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AR92",
      "type": "psc_code"
   },
   {
      "label": "AR92 - R&D-OTHER SPACE-A RES/EXPL DEV",
      "value": "AR92",
      "type": "psc_code"
   },
   {
      "label": "AR93 - R&D- SPACE: OTHER (ADVANCED DEVELOPMENT)",
      "value": "AR93",
      "type": "psc_code"
   },
   {
      "label": "AR93 - R&D-OTHER SPACE-ADV DEV",
      "value": "AR93",
      "type": "psc_code"
   },
   {
      "label": "AR94 - R&D-OTHER SPACE-ENG DEV",
      "value": "AR94",
      "type": "psc_code"
   },
   {
      "label": "AR94 - R&D- SPACE: OTHER (ENGINEERING DEVELOPMENT)",
      "value": "AR94",
      "type": "psc_code"
   },
   {
      "label": "AR95 - R&D-OTHER SPACE-OPSY DEV",
      "value": "AR95",
      "type": "psc_code"
   },
   {
      "label": "AR95 - R&D- SPACE: OTHER (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AR95",
      "type": "psc_code"
   },
   {
      "label": "AR96 - R&D- SPACE: OTHER (MANAGEMENT/SUPPORT)",
      "value": "AR96",
      "type": "psc_code"
   },
   {
      "label": "AR96 - R&D-OTHER SPACE-MGMT SUP",
      "value": "AR96",
      "type": "psc_code"
   },
   {
      "label": "AR97 - R&D- SPACE: OTHER (COMMERCIALIZED)",
      "value": "AR97",
      "type": "psc_code"
   },
   {
      "label": "AR97 - R&D-OTHER SPACE-COMERCLIZ",
      "value": "AR97",
      "type": "psc_code"
   },
   {
      "label": "AS - TRANSPORTATION (MODAL) R&D",
      "value": "AS",
      "type": "psc_code"
   },
   {
      "label": "AS - Transportation R&D Services",
      "value": "AS",
      "type": "psc_code"
   },
   {
      "label": "AS10 - R&D-TRANS-AIR",
      "value": "AS10",
      "type": "psc_code"
   },
   {
      "label": "AS11 - AIR TRANSPORTATION (BASIC)",
      "value": "AS11",
      "type": "psc_code"
   },
   {
      "label": "AS11 - R&D- MODAL TRANSPORTATION: AIR (BASIC RESEARCH)",
      "value": "AS11",
      "type": "psc_code"
   },
   {
      "label": "AS11 - TRANSPORTATION R&D SERVICES; SURFACE TRANSPORTATION, PUBLIC TRANSIT, AND RAIL; BASIC RESEARCH",
      "value": "AS11",
      "type": "psc_code"
   },
   {
      "label": "AS12 - R&D- MODAL TRANSPORTATION: AIR (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AS12",
      "type": "psc_code"
   },
   {
      "label": "AS12 - R&D-TRANS-AIR-A RES/EXPL DEV",
      "value": "AS12",
      "type": "psc_code"
   },
   {
      "label": "AS12 - TRANSPORTATION R&D SERVICES; SURFACE TRANSPORTATION, PUBLIC TRANSIT, AND RAIL; APPLIED RESEARCH",
      "value": "AS12",
      "type": "psc_code"
   },
   {
      "label": "AS13 - AIR TRANSPORTATION (ADVANCED)",
      "value": "AS13",
      "type": "psc_code"
   },
   {
      "label": "AS13 - R&D- MODAL TRANSPORTATION: AIR (ADVANCED DEVELOPMENT)",
      "value": "AS13",
      "type": "psc_code"
   },
   {
      "label": "AS13 - TRANSPORTATION R&D SVCS; SURFACE TRANSPORTATION, PUBLIC TRANSIT, & RAIL; EXPERIMENTAL DEVELOPMENT",
      "value": "AS13",
      "type": "psc_code"
   },
   {
      "label": "AS14 - AIR TRANSPORTATION (ENGINEERING)",
      "value": "AS14",
      "type": "psc_code"
   },
   {
      "label": "AS14 - R&D- MODAL TRANSPORTATION: AIR (ENGINEERING DEVELOPMENT)",
      "value": "AS14",
      "type": "psc_code"
   },
   {
      "label": "AS14 - TRANSPORTATION R&D SVCS; SURFACE TRANSPORTATION, PUBLIC TRANSIT, & RAIL; R&D ADMINISTRATIVE EXPENSES",
      "value": "AS14",
      "type": "psc_code"
   },
   {
      "label": "AS15 - AIR TRANSPORTATION (OPERATIONAL)",
      "value": "AS15",
      "type": "psc_code"
   },
   {
      "label": "AS15 - R&D- MODAL TRANSPORTATION: AIR (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AS15",
      "type": "psc_code"
   },
   {
      "label": "AS15 - TRANSPORTATION R&D SVCS; SURFACE TRANSPORTATION, PUBLIC TRANSIT, & RAIL; R&D FACILITIES & MAJ EQUIP",
      "value": "AS15",
      "type": "psc_code"
   },
   {
      "label": "AS16 - R&D-TRANS-AIR-MGMT SUP",
      "value": "AS16",
      "type": "psc_code"
   },
   {
      "label": "AS16 - R&D- MODAL TRANSPORTATION: AIR (MANAGEMENT/SUPPORT)",
      "value": "AS16",
      "type": "psc_code"
   },
   {
      "label": "AS17 - R&D- MODAL TRANSPORTATION: AIR (COMMERCIALIZED)",
      "value": "AS17",
      "type": "psc_code"
   },
   {
      "label": "AS17 - R&D-TRANS-AIR-COMERCLIZ",
      "value": "AS17",
      "type": "psc_code"
   },
   {
      "label": "AS20 - R&D-TRANS- MOTOR VEH",
      "value": "AS20",
      "type": "psc_code"
   },
   {
      "label": "AS21 - R&D- MODAL TRANSPORTATION: SURFACE MOTOR VEHICLES (BASIC RESEARCH)",
      "value": "AS21",
      "type": "psc_code"
   },
   {
      "label": "AS21 - SURFACE MOTOR VEHICLES (BASIC)",
      "value": "AS21",
      "type": "psc_code"
   },
   {
      "label": "AS21 - TRANSPORTATION R&D SERVICES; AEROSPACE RESEARCH; BASIC RESEARCH",
      "value": "AS21",
      "type": "psc_code"
   },
   {
      "label": "AS22 - R&D-TRANS- MOTOR VEH-A RES/EXPL DEV",
      "value": "AS22",
      "type": "psc_code"
   },
   {
      "label": "AS22 - R&D- MODAL TRANSPORTATION: SURFACE MOTOR VEHICLES (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AS22",
      "type": "psc_code"
   },
   {
      "label": "AS22 - TRANSPORTATION R&D SERVICES; AEROSPACE RESEARCH; APPLIED RESEARCH",
      "value": "AS22",
      "type": "psc_code"
   },
   {
      "label": "AS23 - R&D- MODAL TRANSPORTATION: SURFACE MOTOR VEHICLES (ADVANCED DEVELOPMENT)",
      "value": "AS23",
      "type": "psc_code"
   },
   {
      "label": "AS23 - SURFACE MOTOR VEHICLES (ADVANCED)",
      "value": "AS23",
      "type": "psc_code"
   },
   {
      "label": "AS23 - TRANSPORTATION R&D SERVICES; AEROSPACE RESEARCH; EXPERIMENTAL DEVELOPMENT",
      "value": "AS23",
      "type": "psc_code"
   },
   {
      "label": "AS24 - R&D-TRANS- MOTOR VEH-ENG DEV",
      "value": "AS24",
      "type": "psc_code"
   },
   {
      "label": "AS24 - R&D- MODAL TRANSPORTATION: SURFACE MOTOR VEHICLES (ENGINEERING DEVELOPMENT)",
      "value": "AS24",
      "type": "psc_code"
   },
   {
      "label": "AS24 - TRANSPORTATION R&D SERVICES; AEROSPACE RESEARCH; R&D ADMINISTRATIVE EXPENSES",
      "value": "AS24",
      "type": "psc_code"
   },
   {
      "label": "AS25 - R&D-TRANS- MOTOR VEH-OPSY DEV",
      "value": "AS25",
      "type": "psc_code"
   },
   {
      "label": "AS25 - R&D- MODAL TRANSPORTATION: SURFACE MOTOR VEHICLES (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AS25",
      "type": "psc_code"
   },
   {
      "label": "AS25 - TRANSPORTATION R&D SERVICES; AEROSPACE RESEARCH; EXPENSES FOR R&D FACILITIES AND MAJOR EQUIPMENT",
      "value": "AS25",
      "type": "psc_code"
   },
   {
      "label": "AS26 - R&D-TRANS- MOTOR VEH-MGMT SUP",
      "value": "AS26",
      "type": "psc_code"
   },
   {
      "label": "AS26 - R&D- MODAL TRANSPORTATION: SURFACE MOTOR VEHICLES (MANAGEMENT/SUPPORT)",
      "value": "AS26",
      "type": "psc_code"
   },
   {
      "label": "AS27 - R&D-TRANS- MOTOR VEH-COMERCLIZ",
      "value": "AS27",
      "type": "psc_code"
   },
   {
      "label": "AS27 - R&D- MODAL TRANSPORTATION: SURFACE MOTOR VEHICLES (COMMERCIALIZED)",
      "value": "AS27",
      "type": "psc_code"
   },
   {
      "label": "AS30 - R&D-TRANS-RAIL",
      "value": "AS30",
      "type": "psc_code"
   },
   {
      "label": "AS31 - RAIL TRANSPORTATION (BASIC)",
      "value": "AS31",
      "type": "psc_code"
   },
   {
      "label": "AS31 - R&D- MODAL TRANSPORTATION: RAIL (BASIC RESEARCH)",
      "value": "AS31",
      "type": "psc_code"
   },
   {
      "label": "AS31 - TRANSPORTATION R&D SERVICES; WATER TRANSPORTATION; BASIC RESEARCH",
      "value": "AS31",
      "type": "psc_code"
   },
   {
      "label": "AS32 - R&D-TRANS-RAIL-A RES/EXPL DEV",
      "value": "AS32",
      "type": "psc_code"
   },
   {
      "label": "AS32 - R&D- MODAL TRANSPORTATION: RAIL (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AS32",
      "type": "psc_code"
   },
   {
      "label": "AS32 - TRANSPORTATION R&D SERVICES; WATER TRANSPORTATION; APPLIED RESEARCH",
      "value": "AS32",
      "type": "psc_code"
   },
   {
      "label": "AS33 - RAIL TRANSPORTATION (ADVANCED)",
      "value": "AS33",
      "type": "psc_code"
   },
   {
      "label": "AS33 - R&D- MODAL TRANSPORTATION: RAIL (ADVANCED DEVELOPMENT)",
      "value": "AS33",
      "type": "psc_code"
   },
   {
      "label": "AS33 - TRANSPORTATION R&D SERVICES; WATER TRANSPORTATION; EXPERIMENTAL DEVELOPMENT",
      "value": "AS33",
      "type": "psc_code"
   },
   {
      "label": "AS34 - RAIL TRANSPORTATION (ENGINEERING)",
      "value": "AS34",
      "type": "psc_code"
   },
   {
      "label": "AS34 - R&D- MODAL TRANSPORTATION: RAIL (ENGINEERING DEVELOPMENT)",
      "value": "AS34",
      "type": "psc_code"
   },
   {
      "label": "AS34 - TRANSPORTATION R&D SERVICES; WATER TRANSPORTATION; R&D ADMINISTRATIVE EXPENSES",
      "value": "AS34",
      "type": "psc_code"
   },
   {
      "label": "AS35 - RAIL TRANSPORTATION (OPERATIONAL)",
      "value": "AS35",
      "type": "psc_code"
   },
   {
      "label": "AS35 - R&D- MODAL TRANSPORTATION: RAIL (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AS35",
      "type": "psc_code"
   },
   {
      "label": "AS35 - TRANSPORTATION R&D SERVICES; WATER TRANSPORTATION; EXPENSES FOR R&D FACILITIES AND MAJOR EQUIPMENT",
      "value": "AS35",
      "type": "psc_code"
   },
   {
      "label": "AS36 - R&D-TRANS-RAIL-MGMT SUP",
      "value": "AS36",
      "type": "psc_code"
   },
   {
      "label": "AS36 - R&D- MODAL TRANSPORTATION: RAIL (MANAGEMENT/SUPPORT)",
      "value": "AS36",
      "type": "psc_code"
   },
   {
      "label": "AS37 - R&D-TRANS-RAIL-COMERCLIZ",
      "value": "AS37",
      "type": "psc_code"
   },
   {
      "label": "AS37 - R&D- MODAL TRANSPORTATION: RAIL (COMMERCIALIZED)",
      "value": "AS37",
      "type": "psc_code"
   },
   {
      "label": "AS40 - R&D-TRANS-MARINE",
      "value": "AS40",
      "type": "psc_code"
   },
   {
      "label": "AS41 - R&D- MODAL TRANSPORTATION: MARINE (BASIC RESEARCH)",
      "value": "AS41",
      "type": "psc_code"
   },
   {
      "label": "AS41 - MARINE TRANSPORTATION (BASIC)",
      "value": "AS41",
      "type": "psc_code"
   },
   {
      "label": "AS41 - TRANSPORTATION R&D SVCS; PIPELINES; HAZ MAT; CROSS-FUNCTIONAL TRANSPORTATION; BASIC RESEARCH",
      "value": "AS41",
      "type": "psc_code"
   },
   {
      "label": "AS42 - R&D-TRANS-MARINE-A RES/EXPL DEV",
      "value": "AS42",
      "type": "psc_code"
   },
   {
      "label": "AS42 - R&D- MODAL TRANSPORTATION: MARINE (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AS42",
      "type": "psc_code"
   },
   {
      "label": "AS42 - TRANSPORTATION R&D SVCS; PIPELINES; HAZ MAT; CROSS-FUNCTIONAL TRANSPORTATION; APPLIED RESEARCH",
      "value": "AS42",
      "type": "psc_code"
   },
   {
      "label": "AS43 - MARINE TRANSPORTATION (ADVANCED)",
      "value": "AS43",
      "type": "psc_code"
   },
   {
      "label": "AS43 - R&D- MODAL TRANSPORTATION: MARINE (ADVANCED DEVELOPMENT)",
      "value": "AS43",
      "type": "psc_code"
   },
   {
      "label": "AS43 - TRANSPORTATION R&D SVCS; PIPELINES; HAZ MAT; CROSS-FUNCTIONAL TRANSP; EXPERIMENTAL DEVELOPMENT",
      "value": "AS43",
      "type": "psc_code"
   },
   {
      "label": "AS44 - R&D- MODAL TRANSPORTATION: MARINE (ENGINEERING DEVELOPMENT)",
      "value": "AS44",
      "type": "psc_code"
   },
   {
      "label": "AS44 - MARINE TRANSPORTATION (ENGINEERING)",
      "value": "AS44",
      "type": "psc_code"
   },
   {
      "label": "AS44 - TRANSPORTATION R&D SVCS; PIPELINES; HAZ MAT; CROSS-FUNCTIONAL TRANSP; R&D ADMINISTRATIVE EXPENSES",
      "value": "AS44",
      "type": "psc_code"
   },
   {
      "label": "AS45 - MARINE TRANSPORTATION (OPERATIONAL)",
      "value": "AS45",
      "type": "psc_code"
   },
   {
      "label": "AS45 - R&D- MODAL TRANSPORTATION: MARINE (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AS45",
      "type": "psc_code"
   },
   {
      "label": "AS45 - TRANSPORTATION R&D SVCS; PIPELINES; HAZ MAT; CROSS-FUNCTIONAL TRANSP; R&D FACILITIES & MAJ EQUIP",
      "value": "AS45",
      "type": "psc_code"
   },
   {
      "label": "AS46 - R&D-TRANS-MARINE-MGMT SUP",
      "value": "AS46",
      "type": "psc_code"
   },
   {
      "label": "AS46 - R&D- MODAL TRANSPORTATION: MARINE (MANAGEMENT/SUPPORT)",
      "value": "AS46",
      "type": "psc_code"
   },
   {
      "label": "AS47 - R&D-TRANS-MARINE-COMERCLIZ",
      "value": "AS47",
      "type": "psc_code"
   },
   {
      "label": "AS47 - R&D- MODAL TRANSPORTATION: MARINE (COMMERCIALIZED)",
      "value": "AS47",
      "type": "psc_code"
   },
   {
      "label": "AS90 - R&D-OTHER MODAL TRANS",
      "value": "AS90",
      "type": "psc_code"
   },
   {
      "label": "AS91 - R&D- MODAL TRANSPORTATION: OTHER MODAL (BASIC RESEARCH)",
      "value": "AS91",
      "type": "psc_code"
   },
   {
      "label": "AS91 - OTHER MODAL TRANSPORTATION (BASIC)",
      "value": "AS91",
      "type": "psc_code"
   },
   {
      "label": "AS92 - R&D-OTHER MODAL TRANS-A RES/EXPL DE",
      "value": "AS92",
      "type": "psc_code"
   },
   {
      "label": "AS92 - R&D- MODAL TRANSPORTATION: OTHER MODAL (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AS92",
      "type": "psc_code"
   },
   {
      "label": "AS93 - R&D- MODAL TRANSPORTATION: OTHER MODAL (ADVANCED DEVELOPMENT)",
      "value": "AS93",
      "type": "psc_code"
   },
   {
      "label": "AS93 - R&D-OTHER MODAL TRANS-ADV DEV",
      "value": "AS93",
      "type": "psc_code"
   },
   {
      "label": "AS94 - R&D- MODAL TRANSPORTATION: OTHER MODAL (ENGINEERING DEVELOPMENT)",
      "value": "AS94",
      "type": "psc_code"
   },
   {
      "label": "AS94 - R&D-OTHER MODAL TRANS-ENG DEV",
      "value": "AS94",
      "type": "psc_code"
   },
   {
      "label": "AS95 - R&D- MODAL TRANSPORTATION: OTHER MODAL (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AS95",
      "type": "psc_code"
   },
   {
      "label": "AS95 - R&D-OTHER MODAL TRANS-OPSY DEV",
      "value": "AS95",
      "type": "psc_code"
   },
   {
      "label": "AS96 - R&D- MODAL TRANSPORTATION: OTHER MODAL (MANAGEMENT/SUPPORT)",
      "value": "AS96",
      "type": "psc_code"
   },
   {
      "label": "AS96 - R&D-OTHER MODAL TRANS-MGMT SUP",
      "value": "AS96",
      "type": "psc_code"
   },
   {
      "label": "AS97 - R&D-OTHER MODAL TRANS-COMERCLIZ",
      "value": "AS97",
      "type": "psc_code"
   },
   {
      "label": "AS97 - R&D- MODAL TRANSPORTATION: OTHER MODAL (COMMERCIALIZED)",
      "value": "AS97",
      "type": "psc_code"
   },
   {
      "label": "AT - TRANSPORTATION (OTHER) R&D",
      "value": "AT",
      "type": "psc_code"
   },
   {
      "label": "AT10 - R&D-TRANS-ROADS-BRIDGES",
      "value": "AT10",
      "type": "psc_code"
   },
   {
      "label": "AT11 - R&D-TRANS-ROADS-BRIDGES-B RES",
      "value": "AT11",
      "type": "psc_code"
   },
   {
      "label": "AT11 - R&D- OTHER TRANSPORTATION: HIGHWAYS, ROADS, AND BRIDGES (BASIC RESEARCH)",
      "value": "AT11",
      "type": "psc_code"
   },
   {
      "label": "AT12 - R&D- OTHER TRANSPORTATION: HIGHWAYS, ROADS, AND BRIDGES (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AT12",
      "type": "psc_code"
   },
   {
      "label": "AT12 - R&D-TRANS-ROADS-BRIDGES-A RES/EXPL",
      "value": "AT12",
      "type": "psc_code"
   },
   {
      "label": "AT13 - R&D-TRANS-ROADS-BRIDGES-ADV DEV",
      "value": "AT13",
      "type": "psc_code"
   },
   {
      "label": "AT13 - R&D- OTHER TRANSPORTATION: HIGHWAYS, ROADS, AND BRIDGES (ADVANCED DEVELOPMENT)",
      "value": "AT13",
      "type": "psc_code"
   },
   {
      "label": "AT14 - R&D- OTHER TRANSPORTATION: HIGHWAYS, ROADS, AND BRIDGES (ENGINEERING DEVELOPMENT)",
      "value": "AT14",
      "type": "psc_code"
   },
   {
      "label": "AT14 - R&D-TRANS-ROADS-BRIDGES-ENG DEV",
      "value": "AT14",
      "type": "psc_code"
   },
   {
      "label": "AT15 - R&D- OTHER TRANSPORTATION: HIGHWAYS, ROADS, AND BRIDGES (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AT15",
      "type": "psc_code"
   },
   {
      "label": "AT15 - R&D-TRANS-ROADS-BRIDGES-OPSY DEV",
      "value": "AT15",
      "type": "psc_code"
   },
   {
      "label": "AT16 - R&D-TRANS-ROADS-BRIDGES-MGMT SUP",
      "value": "AT16",
      "type": "psc_code"
   },
   {
      "label": "AT16 - R&D- OTHER TRANSPORTATION: HIGHWAYS, ROADS, AND BRIDGES (MANAGEMENT/SUPPORT)",
      "value": "AT16",
      "type": "psc_code"
   },
   {
      "label": "AT17 - R&D- OTHER TRANSPORTATION: HIGHWAYS, ROADS, AND BRIDGES (COMMERCIALIZED)",
      "value": "AT17",
      "type": "psc_code"
   },
   {
      "label": "AT17 - R&D-TRANS-ROADS-BRIDGES-COMERCLIZ",
      "value": "AT17",
      "type": "psc_code"
   },
   {
      "label": "AT20 - R&D-TRANS-HUMAN FACTORS",
      "value": "AT20",
      "type": "psc_code"
   },
   {
      "label": "AT21 - R&D- OTHER TRANSPORTATION: HUMAN FACTORS CONCERNING TRANSPORTATION (BASIC RESEARCH)",
      "value": "AT21",
      "type": "psc_code"
   },
   {
      "label": "AT21 - R&D-TRANS-HUMAN FACTORS-B RES",
      "value": "AT21",
      "type": "psc_code"
   },
   {
      "label": "AT22 - R&D-TRANS-HUMAN FACTORS-A RES/EXPL",
      "value": "AT22",
      "type": "psc_code"
   },
   {
      "label": "AT22 - R&D- OTHER TRANSPORTATION: HUMAN FACTORS (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AT22",
      "type": "psc_code"
   },
   {
      "label": "AT23 - R&D-TRANS-HUMAN FACTORS-ADV DEV",
      "value": "AT23",
      "type": "psc_code"
   },
   {
      "label": "AT23 - R&D- OTHER TRANSPORTATION: HUMAN FACTORS CONCERNING TRANSPORTATION (ADVANCED DEVELOPMENT)",
      "value": "AT23",
      "type": "psc_code"
   },
   {
      "label": "AT24 - R&D- OTHER TRANSPORTATION: HUMAN FACTORS CONCERNING TRANSPORTATION (ENGINEERING DEVELOPMENT)",
      "value": "AT24",
      "type": "psc_code"
   },
   {
      "label": "AT24 - R&D-TRANS-HUMAN FACTORS-ENG DEV",
      "value": "AT24",
      "type": "psc_code"
   },
   {
      "label": "AT25 - R&D-TRANS-HUMAN FACTORS-OPSY DEV",
      "value": "AT25",
      "type": "psc_code"
   },
   {
      "label": "AT25 - R&D- OTHER TRANSPORTATION: HUMAN FACTORS CONCERNING TRANSPORTATION (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AT25",
      "type": "psc_code"
   },
   {
      "label": "AT26 - R&D-TRANS-HUMAN FACTORS-MGMT SUP",
      "value": "AT26",
      "type": "psc_code"
   },
   {
      "label": "AT26 - R&D- OTHER TRANSPORTATION: HUMAN FACTORS CONCERNING TRANSPORTATION (MANAGEMENT/SUPPORT)",
      "value": "AT26",
      "type": "psc_code"
   },
   {
      "label": "AT27 - R&D- OTHER TRANSPORTATION: HUMAN FACTORS CONCERNING TRANSPORTATION (COMMERCIALIZED)",
      "value": "AT27",
      "type": "psc_code"
   },
   {
      "label": "AT27 - R&D-TRANS-HUMAN FACTORS-COMERCLIZ",
      "value": "AT27",
      "type": "psc_code"
   },
   {
      "label": "AT30 - R&D-TRANS-NAVIGATION AIDS",
      "value": "AT30",
      "type": "psc_code"
   },
   {
      "label": "AT31 - R&D-TRANS-NAVIGATION AIDS-B RES",
      "value": "AT31",
      "type": "psc_code"
   },
   {
      "label": "AT31 - R&D- OTHER TRANSPORTATION: NAVIGATION AND NAVIGATIONAL AIDS (BASIC RESEARCH)",
      "value": "AT31",
      "type": "psc_code"
   },
   {
      "label": "AT32 - R&D- OTHER TRANSPORTATION: NAVIGATION/NAVIGATIONAL AIDS (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AT32",
      "type": "psc_code"
   },
   {
      "label": "AT32 - R&D-TRANS-NAVIGATION AIDS-A RES/EXP",
      "value": "AT32",
      "type": "psc_code"
   },
   {
      "label": "AT33 - R&D- OTHER TRANSPORTATION: NAVIGATION AND NAVIGATIONAL AIDS (ADVANCED DEVELOPMENT)",
      "value": "AT33",
      "type": "psc_code"
   },
   {
      "label": "AT33 - R&D-TRANS-NAVIGATION AIDS-ADV DEV",
      "value": "AT33",
      "type": "psc_code"
   },
   {
      "label": "AT34 - R&D-TRANS-NAVIGATION AIDS-ENG DEV",
      "value": "AT34",
      "type": "psc_code"
   },
   {
      "label": "AT34 - R&D- OTHER TRANSPORTATION: NAVIGATION AND NAVIGATIONAL AIDS (ENGINEERING DEVELOPMENT)",
      "value": "AT34",
      "type": "psc_code"
   },
   {
      "label": "AT35 - R&D- OTHER TRANSPORTATION: NAVIGATION AND NAVIGATIONAL AIDS (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AT35",
      "type": "psc_code"
   },
   {
      "label": "AT35 - R&D-TRANS-NAVIGATION AIDS-OPSY DEV",
      "value": "AT35",
      "type": "psc_code"
   },
   {
      "label": "AT36 - R&D- OTHER TRANSPORTATION: NAVIGATION AND NAVIGATIONAL AIDS (MANAGEMENT/SUPPORT)",
      "value": "AT36",
      "type": "psc_code"
   },
   {
      "label": "AT36 - R&D-TRANS-NAVIGATION AIDS-MGMT SUP",
      "value": "AT36",
      "type": "psc_code"
   },
   {
      "label": "AT37 - R&D-TRANS-NAVIGATION AIDS-COMERCLIZ",
      "value": "AT37",
      "type": "psc_code"
   },
   {
      "label": "AT37 - R&D- OTHER TRANSPORTATION: NAVIGATION AND NAVIGATIONAL AIDS (COMMERCIALIZED)",
      "value": "AT37",
      "type": "psc_code"
   },
   {
      "label": "AT40 - R&D-TRANS-PASS SAFETY",
      "value": "AT40",
      "type": "psc_code"
   },
   {
      "label": "AT41 - R&D-TRANS-PASS SAFETY-B RES",
      "value": "AT41",
      "type": "psc_code"
   },
   {
      "label": "AT41 - R&D- OTHER TRANSPORTATION: PASSENGER SAFETY AND SECURITY (BASIC RESEARCH)",
      "value": "AT41",
      "type": "psc_code"
   },
   {
      "label": "AT42 - R&D- OTHER TRANSPORTATION: PASSENGER SAFETY AND SECURITY (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AT42",
      "type": "psc_code"
   },
   {
      "label": "AT42 - R&D-TRANS-PASS SAFETY-A RES/EXPL DE",
      "value": "AT42",
      "type": "psc_code"
   },
   {
      "label": "AT43 - R&D-TRANS-PASS SAFETY-ADV DEV",
      "value": "AT43",
      "type": "psc_code"
   },
   {
      "label": "AT43 - R&D- OTHER TRANSPORTATION: PASSENGER SAFETY AND SECURITY (ADVANCED DEVELOPMENT)",
      "value": "AT43",
      "type": "psc_code"
   },
   {
      "label": "AT44 - R&D- OTHER TRANSPORTATION: PASSENGER SAFETY AND SECURITY (ENGINEERING DEVELOPMENT)",
      "value": "AT44",
      "type": "psc_code"
   },
   {
      "label": "AT44 - R&D-TRANS-PASS SAFETY-ENG DEV",
      "value": "AT44",
      "type": "psc_code"
   },
   {
      "label": "AT45 - R&D- OTHER TRANSPORTATION: PASSENGER SAFETY AND SECURITY (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AT45",
      "type": "psc_code"
   },
   {
      "label": "AT45 - R&D-TRANS-PASS SAFETY-OPSY DEV",
      "value": "AT45",
      "type": "psc_code"
   },
   {
      "label": "AT46 - R&D-TRANS-PASS SAFETY-MGMT SUP",
      "value": "AT46",
      "type": "psc_code"
   },
   {
      "label": "AT46 - R&D- OTHER TRANSPORTATION: PASSENGER SAFETY AND SECURITY (MANAGEMENT/SUPPORT)",
      "value": "AT46",
      "type": "psc_code"
   },
   {
      "label": "AT47 - R&D-TRANS-PASS SAFETY-COMERCLIZ",
      "value": "AT47",
      "type": "psc_code"
   },
   {
      "label": "AT47 - R&D- OTHER TRANSPORTATION: PASSENGER SAFETY AND SECURITY (COMMERCIALIZED)",
      "value": "AT47",
      "type": "psc_code"
   },
   {
      "label": "AT50 - R&D-TRANS-PIPELINE SAFETY",
      "value": "AT50",
      "type": "psc_code"
   },
   {
      "label": "AT51 - PIPELINE SAFETY (BASIC)",
      "value": "AT51",
      "type": "psc_code"
   },
   {
      "label": "AT51 - R&D- OTHER TRANSPORTATION: PIPELINE SAFETY (BASIC RESEARCH)",
      "value": "AT51",
      "type": "psc_code"
   },
   {
      "label": "AT52 - R&D- OTHER TRANSPORTATION: PIPELINE SAFETY (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AT52",
      "type": "psc_code"
   },
   {
      "label": "AT52 - R&D-TRANS-PIPELINE SAFETY-A RES/EXP",
      "value": "AT52",
      "type": "psc_code"
   },
   {
      "label": "AT53 - PIPELINE SAFETY (ADVANCED)",
      "value": "AT53",
      "type": "psc_code"
   },
   {
      "label": "AT53 - R&D- OTHER TRANSPORTATION: PIPELINE SAFETY (ADVANCED DEVELOPMENT)",
      "value": "AT53",
      "type": "psc_code"
   },
   {
      "label": "AT54 - R&D- OTHER TRANSPORTATION: PIPELINE SAFETY (ENGINEERING DEVELOPMENT)",
      "value": "AT54",
      "type": "psc_code"
   },
   {
      "label": "AT54 - PIPELINE SAFETY (ENGINEERING)",
      "value": "AT54",
      "type": "psc_code"
   },
   {
      "label": "AT55 - R&D- OTHER TRANSPORTATION: PIPELINE SAFETY (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AT55",
      "type": "psc_code"
   },
   {
      "label": "AT55 - PIPELINE SAFETY (OPERATIONAL)",
      "value": "AT55",
      "type": "psc_code"
   },
   {
      "label": "AT56 - R&D-TRANS-PIPELINE SAFETY-MGMT SUP",
      "value": "AT56",
      "type": "psc_code"
   },
   {
      "label": "AT56 - R&D- OTHER TRANSPORTATION: PIPELINE SAFETY (MANAGEMENT/SUPPORT)",
      "value": "AT56",
      "type": "psc_code"
   },
   {
      "label": "AT57 - R&D- OTHER TRANSPORTATION: PIPELINE SAFETY (COMMERCIALIZED)",
      "value": "AT57",
      "type": "psc_code"
   },
   {
      "label": "AT57 - R&D-TRANS-PIPELINE SAFETY-COMERCLIZ",
      "value": "AT57",
      "type": "psc_code"
   },
   {
      "label": "AT60 - R&D-TRANS-TRAFFIC MGT",
      "value": "AT60",
      "type": "psc_code"
   },
   {
      "label": "AT61 - R&D- OTHER TRANSPORTATION: TRAFFIC MANAGEMENT (BASIC RESEARCH)",
      "value": "AT61",
      "type": "psc_code"
   },
   {
      "label": "AT61 - TRAFFIC MANAGEMENT (BASIC)",
      "value": "AT61",
      "type": "psc_code"
   },
   {
      "label": "AT62 - R&D-TRANS-TRAFFIC MGT-A RES/EXPL DE",
      "value": "AT62",
      "type": "psc_code"
   },
   {
      "label": "AT62 - R&D- OTHER TRANSPORTATION: TRAFFIC MANAGEMENT (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AT62",
      "type": "psc_code"
   },
   {
      "label": "AT63 - R&D- OTHER TRANSPORTATION: TRAFFIC MANAGEMENT (ADVANCED DEVELOPMENT)",
      "value": "AT63",
      "type": "psc_code"
   },
   {
      "label": "AT63 - TRAFFIC MANAGEMENT (ADVANCED)",
      "value": "AT63",
      "type": "psc_code"
   },
   {
      "label": "AT64 - R&D- OTHER TRANSPORTATION: TRAFFIC MANAGEMENT (ENGINEERING DEVELOPMENT)",
      "value": "AT64",
      "type": "psc_code"
   },
   {
      "label": "AT64 - TRAFFIC MANAGEMENT (ENGINEERING)",
      "value": "AT64",
      "type": "psc_code"
   },
   {
      "label": "AT65 - TRAFFIC MANAGEMENT (OPERATIONAL)",
      "value": "AT65",
      "type": "psc_code"
   },
   {
      "label": "AT65 - R&D- OTHER TRANSPORTATION: TRAFFIC MANAGEMENT (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AT65",
      "type": "psc_code"
   },
   {
      "label": "AT66 - R&D- OTHER TRANSPORTATION: TRAFFIC MANAGEMENT (MANAGEMENT/SUPPORT)",
      "value": "AT66",
      "type": "psc_code"
   },
   {
      "label": "AT66 - R&D-TRANS-TRAFFIC MGT-MGMT SUP",
      "value": "AT66",
      "type": "psc_code"
   },
   {
      "label": "AT67 - R&D-TRANS-TRAFFIC MGT-COMERCLIZ",
      "value": "AT67",
      "type": "psc_code"
   },
   {
      "label": "AT67 - R&D- OTHER TRANSPORTATION: TRAFFIC MANAGEMENT (COMMERCIALIZED)",
      "value": "AT67",
      "type": "psc_code"
   },
   {
      "label": "AT70 - R&D-TRANS-TUNNELS",
      "value": "AT70",
      "type": "psc_code"
   },
   {
      "label": "AT71 - R&D-TRANS-TUNNELS-B RES",
      "value": "AT71",
      "type": "psc_code"
   },
   {
      "label": "AT71 - R&D- OTHER TRANSPORTATION: TUNNELS AND OTHER SUBSURFACE STRUCTURES (BASIC RESEARCH)",
      "value": "AT71",
      "type": "psc_code"
   },
   {
      "label": "AT72 - R&D-TRANS-TUNNELS-A RES/EXPL DEV",
      "value": "AT72",
      "type": "psc_code"
   },
   {
      "label": "AT72 - R&D- OTHER TRANSPORTATION: TUNNELS/OTHER SUBSURF STRUC (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AT72",
      "type": "psc_code"
   },
   {
      "label": "AT73 - R&D- OTHER TRANSPORTATION: TUNNELS AND OTHER SUBSURFACE STRUCTURES (ADVANCED DEVELOPMENT)",
      "value": "AT73",
      "type": "psc_code"
   },
   {
      "label": "AT73 - R&D-TRANS-TUNNELS-ADV DEV",
      "value": "AT73",
      "type": "psc_code"
   },
   {
      "label": "AT74 - R&D-TRANS-TUNNELS-ENG DEV",
      "value": "AT74",
      "type": "psc_code"
   },
   {
      "label": "AT74 - R&D- OTHER TRANSPORTATION: TUNNELS AND OTHER SUBSURFACE STRUCTURES (ENGINEERING DEVELOPMENT)",
      "value": "AT74",
      "type": "psc_code"
   },
   {
      "label": "AT75 - R&D- OTHER TRANSPORTATION: TUNNELS AND OTHER SUBSURFACE STRUCTURES (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AT75",
      "type": "psc_code"
   },
   {
      "label": "AT75 - R&D-TRANS-TUNNELS-OPSY DEV",
      "value": "AT75",
      "type": "psc_code"
   },
   {
      "label": "AT76 - R&D-TRANS-TUNNELS-MGMT SUP",
      "value": "AT76",
      "type": "psc_code"
   },
   {
      "label": "AT76 - R&D- OTHER TRANSPORTATION: TUNNELS AND OTHER SUBSURFACE STRUCTURES (MANAGEMENT/SUPPORT)",
      "value": "AT76",
      "type": "psc_code"
   },
   {
      "label": "AT77 - R&D- OTHER TRANSPORTATION: TUNNELS AND OTHER SUBSURFACE STRUCTURES (COMMERCIALIZED)",
      "value": "AT77",
      "type": "psc_code"
   },
   {
      "label": "AT77 - R&D-TRANS-TUNNELS-COMERCLIZ",
      "value": "AT77",
      "type": "psc_code"
   },
   {
      "label": "AT81 - R&D- OTHER TRANSPORTATION: TRANSPORTING HAZARDOUS MATERIALS (BASIC RESEARCH)",
      "value": "AT81",
      "type": "psc_code"
   },
   {
      "label": "AT81 - R&D-TRANS OF HAZARD MAT-B RES",
      "value": "AT81",
      "type": "psc_code"
   },
   {
      "label": "AT82 - R&D- OTHER TRANSPORTATION: TRANSPORTING HAZ MAT (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AT82",
      "type": "psc_code"
   },
   {
      "label": "AT82 - R&D-TRANS OF HAZARD MAT-A RES/EXPL",
      "value": "AT82",
      "type": "psc_code"
   },
   {
      "label": "AT83 - R&D-TRANS OF HAZARD MAT-ADV DEV",
      "value": "AT83",
      "type": "psc_code"
   },
   {
      "label": "AT83 - R&D- OTHER TRANSPORTATION: TRANSPORTING HAZARDOUS MATERIALS (ADVANCED DEVELOPMENT)",
      "value": "AT83",
      "type": "psc_code"
   },
   {
      "label": "AT84 - R&D- OTHER TRANSPORTATION: TRANSPORTING HAZARDOUS MATERIALS (ENGINEERING DEVELOPMENT)",
      "value": "AT84",
      "type": "psc_code"
   },
   {
      "label": "AT84 - R&D-TRANS OF HAZARD MAT-ENG DEV",
      "value": "AT84",
      "type": "psc_code"
   },
   {
      "label": "AT85 - R&D- OTHER TRANSPORTATION: TRANSPORTING HAZARDOUS MATERIALS (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AT85",
      "type": "psc_code"
   },
   {
      "label": "AT85 - R&D-TRANS OF HAZARD MAT-OPSY DEV",
      "value": "AT85",
      "type": "psc_code"
   },
   {
      "label": "AT86 - R&D- OTHER TRANSPORTATION: TRANSPORTING HAZARDOUS MATERIALS (MANAGEMENT/SUPPORT)",
      "value": "AT86",
      "type": "psc_code"
   },
   {
      "label": "AT86 - R&D-TRANS OF HAZARD MAT-MGMT SUP",
      "value": "AT86",
      "type": "psc_code"
   },
   {
      "label": "AT87 - R&D- OTHER TRANSPORTATION: TRANSPORTING HAZARDOUS MATS (COMMERCIALIZED)",
      "value": "AT87",
      "type": "psc_code"
   },
   {
      "label": "AT87 - RDTE/Trans - Hazardous Mats - Operational Sy Dev",
      "value": "AT87",
      "type": "psc_code"
   },
   {
      "label": "AT90 - R&D-OTHER GENERAL TRANS",
      "value": "AT90",
      "type": "psc_code"
   },
   {
      "label": "AT91 - R&D-OTHER GENERAL TRANS-B RES",
      "value": "AT91",
      "type": "psc_code"
   },
   {
      "label": "AT91 - R&D- OTHER TRANSPORTATION: OTHER GENERAL (BASIC RESEARCH)",
      "value": "AT91",
      "type": "psc_code"
   },
   {
      "label": "AT92 - R&D-OTHER GENERAL TRANS-A RES/EXPL",
      "value": "AT92",
      "type": "psc_code"
   },
   {
      "label": "AT92 - R&D- OTHER TRANSPORTATION: OTHER GENERAL (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AT92",
      "type": "psc_code"
   },
   {
      "label": "AT93 - R&D- OTHER TRANSPORTATION: OTHER GENERAL (ADVANCED DEVELOPMENT)",
      "value": "AT93",
      "type": "psc_code"
   },
   {
      "label": "AT93 - R&D-OTHER GENERAL TRANS-ADV DEV",
      "value": "AT93",
      "type": "psc_code"
   },
   {
      "label": "AT94 - R&D-OTHER GENERAL TRANS-ENG DEV",
      "value": "AT94",
      "type": "psc_code"
   },
   {
      "label": "AT94 - R&D- OTHER TRANSPORTATION: OTHER GENERAL (ENGINEERING DEVELOPMENT)",
      "value": "AT94",
      "type": "psc_code"
   },
   {
      "label": "AT95 - R&D- OTHER TRANSPORTATION: OTHER GENERAL (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AT95",
      "type": "psc_code"
   },
   {
      "label": "AT95 - R&D-OTHER GENERAL TRANS-OPSY DEV",
      "value": "AT95",
      "type": "psc_code"
   },
   {
      "label": "AT96 - R&D- OTHER TRANSPORTATION: OTHER GENERAL (MANAGEMENT/SUPPORT)",
      "value": "AT96",
      "type": "psc_code"
   },
   {
      "label": "AT96 - R&D-OTHER GENERAL TRANS-MGMT SUP",
      "value": "AT96",
      "type": "psc_code"
   },
   {
      "label": "AT97 - R&D- OTHER TRANSPORTATION: OTHER GENERAL (COMMERCIALIZED)",
      "value": "AT97",
      "type": "psc_code"
   },
   {
      "label": "AT97 - R&D-OTHER GENERAL TRANS-COMERCLIZ",
      "value": "AT97",
      "type": "psc_code"
   },
   {
      "label": "AU - TRANSPORTATION (OBSOLETE GROUP)",
      "value": "AU",
      "type": "psc_code"
   },
   {
      "label": "AU10 - R&D-TRANS OF HAZARD MAT",
      "value": "AU10",
      "type": "psc_code"
   },
   {
      "label": "AU11 - R&D-TRANS OF HAZARD MAT-RESEARCH",
      "value": "AU11",
      "type": "psc_code"
   },
   {
      "label": "AU12 - R&D-TRANS OF HAZARD MAT-EXPL DEV",
      "value": "AU12",
      "type": "psc_code"
   },
   {
      "label": "AU13 - R&D-TRANS OF HAZARD MAT-ADV DEV",
      "value": "AU13",
      "type": "psc_code"
   },
   {
      "label": "AU14 - R&D-TRANS OF HAZARD MAT-ENG DEV",
      "value": "AU14",
      "type": "psc_code"
   },
   {
      "label": "AU15 - R&D-TRANS OF HAZARD MAT-OPSY DEV",
      "value": "AU15",
      "type": "psc_code"
   },
   {
      "label": "AU16 - R&D-TRANS OF HAZARD MAT-MGMT SUP",
      "value": "AU16",
      "type": "psc_code"
   },
   {
      "label": "AU17 - R&D-TRANS OF HAZARD MAT-COMERCLIZ",
      "value": "AU17",
      "type": "psc_code"
   },
   {
      "label": "AU90 - R&D-OTHER COMMODITY TRANS",
      "value": "AU90",
      "type": "psc_code"
   },
   {
      "label": "AU91 - R&D-OTHER COMMODITY TRANS-RESEARCH",
      "value": "AU91",
      "type": "psc_code"
   },
   {
      "label": "AU92 - R&D-OTHER COMMODITY TRANS-EXPL DEV",
      "value": "AU92",
      "type": "psc_code"
   },
   {
      "label": "AU93 - R&D-OTHER COMMODITY TRANS-ADV DEV",
      "value": "AU93",
      "type": "psc_code"
   },
   {
      "label": "AU94 - R&D-OTHER COMMODITY TRANS-ENG DEV",
      "value": "AU94",
      "type": "psc_code"
   },
   {
      "label": "AU95 - R&D-OTHER COMMODITY TRANS-OPSY DEV",
      "value": "AU95",
      "type": "psc_code"
   },
   {
      "label": "AU96 - R&D-OTHER COMMODITY TRANS-MGMT SUP",
      "value": "AU96",
      "type": "psc_code"
   },
   {
      "label": "AU97 - R&D-OTHER COMMODITY TRANS-COMERCLIZ",
      "value": "AU97",
      "type": "psc_code"
   },
   {
      "label": "AV - MINING R&D",
      "value": "AV",
      "type": "psc_code"
   },
   {
      "label": "AV10 - R&D-SUBSURFACE MINING EQ",
      "value": "AV10",
      "type": "psc_code"
   },
   {
      "label": "AV11 - SUBSURFACE MINING EQUIPMENT (BASIC)",
      "value": "AV11",
      "type": "psc_code"
   },
   {
      "label": "AV11 - R&D- MINING: SUBSURFACE MINING EQUIPMENT (BASIC RESEARCH)",
      "value": "AV11",
      "type": "psc_code"
   },
   {
      "label": "AV12 - R&D-SUBSURFACE MINING EQ-A RES/EXPL",
      "value": "AV12",
      "type": "psc_code"
   },
   {
      "label": "AV12 - R&D- MINING: SUBSURFACE MINING EQUIPMENT (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AV12",
      "type": "psc_code"
   },
   {
      "label": "AV13 - R&D-SUBSURFACE MINING EQ-ADV DEV",
      "value": "AV13",
      "type": "psc_code"
   },
   {
      "label": "AV13 - R&D- MINING: SUBSURFACE MINING EQUIPMENT (ADVANCED DEVELOPMENT)",
      "value": "AV13",
      "type": "psc_code"
   },
   {
      "label": "AV14 - R&D-SUBSURFACE MINING EQ-ENG DEV",
      "value": "AV14",
      "type": "psc_code"
   },
   {
      "label": "AV14 - R&D- MINING: SUBSURFACE MINING EQUIPMENT (ENGINEERING DEVELOPMENT)",
      "value": "AV14",
      "type": "psc_code"
   },
   {
      "label": "AV15 - R&D- MINING: SUBSURFACE MINING EQUIPMENT (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AV15",
      "type": "psc_code"
   },
   {
      "label": "AV15 - R&D-SUBSURFACE MINING EQ-OPSY DEV",
      "value": "AV15",
      "type": "psc_code"
   },
   {
      "label": "AV16 - R&D- MINING: SUBSURFACE MINING EQUIPMENT (MANAGEMENT/SUPPORT)",
      "value": "AV16",
      "type": "psc_code"
   },
   {
      "label": "AV16 - R&D-SUBSURFACE MINING EQ-MGMT SUP",
      "value": "AV16",
      "type": "psc_code"
   },
   {
      "label": "AV17 - R&D-SUBSURFACE MINING EQ-COMERCLIZ",
      "value": "AV17",
      "type": "psc_code"
   },
   {
      "label": "AV17 - R&D- MINING: SUBSURFACE MINING EQUIPMENT (COMMERCIALIZED)",
      "value": "AV17",
      "type": "psc_code"
   },
   {
      "label": "AV20 - R&D-SURFACE MINING EQ",
      "value": "AV20",
      "type": "psc_code"
   },
   {
      "label": "AV21 - SURFACE MINING EQUIPMENT (BASIC)",
      "value": "AV21",
      "type": "psc_code"
   },
   {
      "label": "AV21 - R&D- MINING: SURFACE MINING EQUIPMENT (BASIC RESEARCH)",
      "value": "AV21",
      "type": "psc_code"
   },
   {
      "label": "AV22 - R&D-SURFACE MINING EQ-A RES/EXPL DE",
      "value": "AV22",
      "type": "psc_code"
   },
   {
      "label": "AV22 - R&D- MINING: SURFACE MINING EQUIPMENT (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AV22",
      "type": "psc_code"
   },
   {
      "label": "AV23 - SURFACE MINING EQUIPMENT (ADVANCED)",
      "value": "AV23",
      "type": "psc_code"
   },
   {
      "label": "AV23 - R&D- MINING: SURFACE MINING EQUIPMENT (ADVANCED DEVELOPMENT)",
      "value": "AV23",
      "type": "psc_code"
   },
   {
      "label": "AV24 - R&D-SURFACE MINING EQ-ENG DEV",
      "value": "AV24",
      "type": "psc_code"
   },
   {
      "label": "AV24 - R&D- MINING: SURFACE MINING EQUIPMENT (ENGINEERING DEVELOPMENT)",
      "value": "AV24",
      "type": "psc_code"
   },
   {
      "label": "AV25 - R&D- MINING: SURFACE MINING EQUIPMENT (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AV25",
      "type": "psc_code"
   },
   {
      "label": "AV25 - R&D-SURFACE MINING EQ-OPSY DEV",
      "value": "AV25",
      "type": "psc_code"
   },
   {
      "label": "AV26 - R&D- MINING: SURFACE MINING EQUIPMENT (MANAGEMENT/SUPPORT)",
      "value": "AV26",
      "type": "psc_code"
   },
   {
      "label": "AV26 - R&D-SURFACE MINING EQ-MGMT SUP",
      "value": "AV26",
      "type": "psc_code"
   },
   {
      "label": "AV27 - R&D- MINING: SURFACE MINING EQUIPMENT (COMMERCIALIZED)",
      "value": "AV27",
      "type": "psc_code"
   },
   {
      "label": "AV27 - R&D-SURFACE MINING EQ-COMERCLIZ",
      "value": "AV27",
      "type": "psc_code"
   },
   {
      "label": "AV30 - R&D-SUBSURF MINING METHS",
      "value": "AV30",
      "type": "psc_code"
   },
   {
      "label": "AV31 - R&D- MINING: SUBSURFACE MINING METHODS (BASIC RESEARCH)",
      "value": "AV31",
      "type": "psc_code"
   },
   {
      "label": "AV31 - SUBSURFACE MINING METHODS (BASIC)",
      "value": "AV31",
      "type": "psc_code"
   },
   {
      "label": "AV32 - R&D-SUBSURF MINING METHS-A RES/EXPL",
      "value": "AV32",
      "type": "psc_code"
   },
   {
      "label": "AV32 - R&D- MINING: SUBSURFACE MINING METHODS (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AV32",
      "type": "psc_code"
   },
   {
      "label": "AV33 - R&D-SUBSURF MINING METHS-ADV DEV",
      "value": "AV33",
      "type": "psc_code"
   },
   {
      "label": "AV33 - R&D- MINING: SUBSURFACE MINING METHODS (ADVANCED DEVELOPMENT)",
      "value": "AV33",
      "type": "psc_code"
   },
   {
      "label": "AV34 - R&D- MINING: SUBSURFACE MINING METHODS (ENGINEERING DEVELOPMENT)",
      "value": "AV34",
      "type": "psc_code"
   },
   {
      "label": "AV34 - R&D-SUBSURF MINING METHS-ENG DEV",
      "value": "AV34",
      "type": "psc_code"
   },
   {
      "label": "AV35 - R&D- MINING: SUBSURFACE MINING METHODS (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AV35",
      "type": "psc_code"
   },
   {
      "label": "AV35 - R&D-SUBSURF MINING METHS-OPSY DEV",
      "value": "AV35",
      "type": "psc_code"
   },
   {
      "label": "AV36 - R&D- MINING: SUBSURFACE MINING METHODS (MANAGEMENT/SUPPORT)",
      "value": "AV36",
      "type": "psc_code"
   },
   {
      "label": "AV36 - R&D-SUBSURF MINING METHS-MGMT SUP",
      "value": "AV36",
      "type": "psc_code"
   },
   {
      "label": "AV37 - R&D-SUBSURF MINING METHS-COMERCLIZ",
      "value": "AV37",
      "type": "psc_code"
   },
   {
      "label": "AV37 - R&D- MINING: SUBSURFACE MINING METHODS (COMMERCIALIZED)",
      "value": "AV37",
      "type": "psc_code"
   },
   {
      "label": "AV40 - R&D-SURFACE MINING METHS",
      "value": "AV40",
      "type": "psc_code"
   },
   {
      "label": "AV41 - SURFACE MINING METHODS (BASIC)",
      "value": "AV41",
      "type": "psc_code"
   },
   {
      "label": "AV41 - R&D- MINING: SURFACE MINING METHODS (BASIC RESEARCH)",
      "value": "AV41",
      "type": "psc_code"
   },
   {
      "label": "AV42 - R&D-SURFACE MINING METHS-A RES/EXPL",
      "value": "AV42",
      "type": "psc_code"
   },
   {
      "label": "AV42 - R&D- MINING: SURFACE MINING METHODS (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AV42",
      "type": "psc_code"
   },
   {
      "label": "AV43 - SURFACE MINING METHODS (ADVANCED)",
      "value": "AV43",
      "type": "psc_code"
   },
   {
      "label": "AV43 - R&D- MINING: SURFACE MINING METHODS (ADVANCED DEVELOPMENT)",
      "value": "AV43",
      "type": "psc_code"
   },
   {
      "label": "AV44 - R&D-SURFACE MINING METHS-ENG DEV",
      "value": "AV44",
      "type": "psc_code"
   },
   {
      "label": "AV44 - R&D- MINING: SURFACE MINING METHODS (ENGINEERING DEVELOPMENT)",
      "value": "AV44",
      "type": "psc_code"
   },
   {
      "label": "AV45 - R&D- MINING: SURFACE MINING METHODS (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AV45",
      "type": "psc_code"
   },
   {
      "label": "AV45 - R&D-SURFACE MINING METHS-OPSY DEV",
      "value": "AV45",
      "type": "psc_code"
   },
   {
      "label": "AV46 - R&D-SURFACE MINING METHS-MGMT SUP",
      "value": "AV46",
      "type": "psc_code"
   },
   {
      "label": "AV46 - R&D- MINING: SURFACE MINING METHODS (MANAGEMENT/SUPPORT)",
      "value": "AV46",
      "type": "psc_code"
   },
   {
      "label": "AV47 - R&D- MINING: SURFACE MINING METHODS (COMMERCIALIZED)",
      "value": "AV47",
      "type": "psc_code"
   },
   {
      "label": "AV47 - R&D-SURFACE MINING METHS-COMERCLIZ",
      "value": "AV47",
      "type": "psc_code"
   },
   {
      "label": "AV50 - R&D-MINING RECLAM METHS",
      "value": "AV50",
      "type": "psc_code"
   },
   {
      "label": "AV51 - R&D- MINING: MINING RECLAMATION METHODS (BASIC RESEARCH)",
      "value": "AV51",
      "type": "psc_code"
   },
   {
      "label": "AV51 - MINING RECLAMATION METHODS (BASIC)",
      "value": "AV51",
      "type": "psc_code"
   },
   {
      "label": "AV52 - R&D-MINING RECLAM METHS-A RES/EXPL",
      "value": "AV52",
      "type": "psc_code"
   },
   {
      "label": "AV52 - R&D- MINING: MINING RECLAMATION METHODS (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AV52",
      "type": "psc_code"
   },
   {
      "label": "AV53 - R&D-MINING RECLAM METHS-ADV DEV",
      "value": "AV53",
      "type": "psc_code"
   },
   {
      "label": "AV53 - R&D- MINING: MINING RECLAMATION METHODS (ADVANCED DEVELOPMENT)",
      "value": "AV53",
      "type": "psc_code"
   },
   {
      "label": "AV54 - R&D-MINING RECLAM METHS-ENG DEV",
      "value": "AV54",
      "type": "psc_code"
   },
   {
      "label": "AV54 - R&D- MINING: MINING RECLAMATION METHODS (ENGINEERING DEVELOPMENT)",
      "value": "AV54",
      "type": "psc_code"
   },
   {
      "label": "AV55 - R&D-MINING RECLAM METHS-OPSY DEV",
      "value": "AV55",
      "type": "psc_code"
   },
   {
      "label": "AV55 - R&D- MINING: MINING RECLAMATION METHODS (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AV55",
      "type": "psc_code"
   },
   {
      "label": "AV56 - R&D- MINING: MINING RECLAMATION METHODS (MANAGEMENT/SUPPORT)",
      "value": "AV56",
      "type": "psc_code"
   },
   {
      "label": "AV56 - R&D-MINING RECLAM METHS-MGMT SUP",
      "value": "AV56",
      "type": "psc_code"
   },
   {
      "label": "AV57 - R&D-MINING RECLAM METHS-COMERCLIZ",
      "value": "AV57",
      "type": "psc_code"
   },
   {
      "label": "AV57 - R&D- MINING: MINING RECLAMATION METHODS (COMMERCIALIZED)",
      "value": "AV57",
      "type": "psc_code"
   },
   {
      "label": "AV60 - R&D-MINING SAFETY",
      "value": "AV60",
      "type": "psc_code"
   },
   {
      "label": "AV61 - R&D- MINING: MINING SAFETY (BASIC RESEARCH)",
      "value": "AV61",
      "type": "psc_code"
   },
   {
      "label": "AV61 - MINING SAFETY (BASIC)",
      "value": "AV61",
      "type": "psc_code"
   },
   {
      "label": "AV62 - MINING SAFETY (APPLIED/EXPLORATORY)",
      "value": "AV62",
      "type": "psc_code"
   },
   {
      "label": "AV62 - R&D- MINING: MINING SAFETY (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AV62",
      "type": "psc_code"
   },
   {
      "label": "AV63 - R&D- MINING: MINING SAFETY (ADVANCED DEVELOPMENT)",
      "value": "AV63",
      "type": "psc_code"
   },
   {
      "label": "AV63 - MINING SAFETY (ADVANCED)",
      "value": "AV63",
      "type": "psc_code"
   },
   {
      "label": "AV64 - R&D- MINING: MINING SAFETY (ENGINEERING DEVELOPMENT)",
      "value": "AV64",
      "type": "psc_code"
   },
   {
      "label": "AV64 - MINING SAFETY (ENGINEERING)",
      "value": "AV64",
      "type": "psc_code"
   },
   {
      "label": "AV65 - MINING SAFETY (OPERATIONAL)",
      "value": "AV65",
      "type": "psc_code"
   },
   {
      "label": "AV65 - R&D- MINING: MINING SAFETY (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AV65",
      "type": "psc_code"
   },
   {
      "label": "AV66 - MINING SAFETY (MANAGEMENT/SUPPORT)",
      "value": "AV66",
      "type": "psc_code"
   },
   {
      "label": "AV66 - R&D- MINING: MINING SAFETY (MANAGEMENT/SUPPORT)",
      "value": "AV66",
      "type": "psc_code"
   },
   {
      "label": "AV67 - R&D- MINING: MINING SAFETY (COMMERCIALIZED)",
      "value": "AV67",
      "type": "psc_code"
   },
   {
      "label": "AV67 - R&D-MINING SAFETY-COMERCLIZ",
      "value": "AV67",
      "type": "psc_code"
   },
   {
      "label": "AV70 - R&D-MINING-METALLURGICAL",
      "value": "AV70",
      "type": "psc_code"
   },
   {
      "label": "AV71 - R&D- MINING: METALLURGICAL (BASIC RESEARCH)",
      "value": "AV71",
      "type": "psc_code"
   },
   {
      "label": "AV71 - METALLURGICAL (BASIC)",
      "value": "AV71",
      "type": "psc_code"
   },
   {
      "label": "AV72 - METALLURGICAL (APPLIED/EXPLORATORY)",
      "value": "AV72",
      "type": "psc_code"
   },
   {
      "label": "AV72 - R&D- MINING: METALLURGICAL (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AV72",
      "type": "psc_code"
   },
   {
      "label": "AV73 - R&D- MINING: METALLURGICAL (ADVANCED DEVELOPMENT)",
      "value": "AV73",
      "type": "psc_code"
   },
   {
      "label": "AV73 - METALLURGICAL (ADVANCED)",
      "value": "AV73",
      "type": "psc_code"
   },
   {
      "label": "AV74 - METALLURGICAL (ENGINEERING)",
      "value": "AV74",
      "type": "psc_code"
   },
   {
      "label": "AV74 - R&D- MINING: METALLURGICAL (ENGINEERING DEVELOPMENT)",
      "value": "AV74",
      "type": "psc_code"
   },
   {
      "label": "AV75 - R&D- MINING: METALLURGICAL (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AV75",
      "type": "psc_code"
   },
   {
      "label": "AV75 - METALLURGICAL (OPERATIONAL)",
      "value": "AV75",
      "type": "psc_code"
   },
   {
      "label": "AV76 - METALLURGICAL (MANAGEMENT/SUPPORT)",
      "value": "AV76",
      "type": "psc_code"
   },
   {
      "label": "AV76 - R&D- MINING: METALLURGICAL (MANAGEMENT/SUPPORT)",
      "value": "AV76",
      "type": "psc_code"
   },
   {
      "label": "AV77 - R&D-MINING-METALLURGICAL-COMERCLIZ",
      "value": "AV77",
      "type": "psc_code"
   },
   {
      "label": "AV77 - R&D- MINING: METALLURGICAL (COMMERCIALIZED)",
      "value": "AV77",
      "type": "psc_code"
   },
   {
      "label": "AV90 - R&D-OTHER MINING ACTIV",
      "value": "AV90",
      "type": "psc_code"
   },
   {
      "label": "AV91 - R&D- MINING: OTHER MINING ACTIVITIES (BASIC RESEARCH)",
      "value": "AV91",
      "type": "psc_code"
   },
   {
      "label": "AV91 - OTHER MINING ACTIVITIES (BASIC)",
      "value": "AV91",
      "type": "psc_code"
   },
   {
      "label": "AV92 - R&D-OTHER MINING ACTIV-A RES/EXPL D",
      "value": "AV92",
      "type": "psc_code"
   },
   {
      "label": "AV92 - R&D- MINING: OTHER MINING ACTIVITIES (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AV92",
      "type": "psc_code"
   },
   {
      "label": "AV93 - OTHER MINING ACTIVITIES (ADVANCED)",
      "value": "AV93",
      "type": "psc_code"
   },
   {
      "label": "AV93 - R&D- MINING: OTHER MINING ACTIVITIES (ADVANCED DEVELOPMENT)",
      "value": "AV93",
      "type": "psc_code"
   },
   {
      "label": "AV94 - R&D-OTHER MINING ACTIV-ENG DEV",
      "value": "AV94",
      "type": "psc_code"
   },
   {
      "label": "AV94 - R&D- MINING: OTHER MINING ACTIVITIES (ENGINEERING DEVELOPMENT)",
      "value": "AV94",
      "type": "psc_code"
   },
   {
      "label": "AV95 - R&D- MINING: OTHER MINING ACTIVITIES (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AV95",
      "type": "psc_code"
   },
   {
      "label": "AV95 - R&D-OTHER MINING ACTIV-OPSY DEV",
      "value": "AV95",
      "type": "psc_code"
   },
   {
      "label": "AV96 - R&D-OTHER MINING ACTIV-MGMT SUP",
      "value": "AV96",
      "type": "psc_code"
   },
   {
      "label": "AV96 - R&D- MINING: OTHER MINING ACTIVITIES (MANAGEMENT/SUPPORT)",
      "value": "AV96",
      "type": "psc_code"
   },
   {
      "label": "AV97 - R&D- MINING: OTHER MINING ACTIVITIES (COMMERCIALIZED)",
      "value": "AV97",
      "type": "psc_code"
   },
   {
      "label": "AV97 - R&D-OTHER MINING ACTIV-COMERCLIZ",
      "value": "AV97",
      "type": "psc_code"
   },
   {
      "label": "AZ - OTHER RESEARCH/DEVELOPMENT",
      "value": "AZ",
      "type": "psc_code"
   },
   {
      "label": "AZ10 - R&D-OTHER R & D",
      "value": "AZ10",
      "type": "psc_code"
   },
   {
      "label": "AZ11 - R&D-OTHER R & D-B RES",
      "value": "AZ11",
      "type": "psc_code"
   },
   {
      "label": "AZ11 - R&D- OTHER RESEARCH AND DEVELOPMENT (BASIC RESEARCH)",
      "value": "AZ11",
      "type": "psc_code"
   },
   {
      "label": "AZ12 - R&D- OTHER RESEARCH AND DEVELOPMENT (APPLIED RESEARCH/EXPLORATORY DEVELOPMENT)",
      "value": "AZ12",
      "type": "psc_code"
   },
   {
      "label": "AZ12 - R&D-OTHER R & D-A RES/EXPL DEV",
      "value": "AZ12",
      "type": "psc_code"
   },
   {
      "label": "AZ13 - R&D- OTHER RESEARCH AND DEVELOPMENT (ADVANCED DEVELOPMENT)",
      "value": "AZ13",
      "type": "psc_code"
   },
   {
      "label": "AZ13 - R&D-OTHER R & D-ADV DEV",
      "value": "AZ13",
      "type": "psc_code"
   },
   {
      "label": "AZ14 - R&D-OTHER R & D-ENG DEV",
      "value": "AZ14",
      "type": "psc_code"
   },
   {
      "label": "AZ14 - R&D- OTHER RESEARCH AND DEVELOPMENT (ENGINEERING DEVELOPMENT)",
      "value": "AZ14",
      "type": "psc_code"
   },
   {
      "label": "AZ15 - R&D- OTHER RESEARCH AND DEVELOPMENT (OPERATIONAL SYSTEMS DEVELOPMENT)",
      "value": "AZ15",
      "type": "psc_code"
   },
   {
      "label": "AZ15 - R&D-OTHER R & D-OPSY DEV",
      "value": "AZ15",
      "type": "psc_code"
   },
   {
      "label": "AZ16 - R&D-OTHER R & D-MGMT SUP",
      "value": "AZ16",
      "type": "psc_code"
   },
   {
      "label": "AZ16 - R&D- OTHER RESEARCH AND DEVELOPMENT (MANAGEMENT/SUPPORT)",
      "value": "AZ16",
      "type": "psc_code"
   },
   {
      "label": "AZ17 - R&D-OTHER R & D-COMERCLIZ",
      "value": "AZ17",
      "type": "psc_code"
   },
   {
      "label": "AZ17 - R&D- OTHER RESEARCH AND DEVELOPMENT (COMMERCIALIZED)",
      "value": "AZ17",
      "type": "psc_code"
   },
   {
      "label": "B - SPECIAL STUDIES/ANALYSIS, NOT R&D",
      "value": "B",
      "type": "psc_code"
   },
   {
      "label": "B5 - SPECIAL STUDIES - NOT R and D",
      "value": "B5",
      "type": "psc_code"
   },
   {
      "label": "B502 - SPECIAL STUDIES/ANALYSIS- AIR QUALITY",
      "value": "B502",
      "type": "psc_code"
   },
   {
      "label": "B502 - AIR QUALITY Analysis",
      "value": "B502",
      "type": "psc_code"
   },
   {
      "label": "B503 - STUDY/ARCHEOLOGICAL-PALEONTOLOGICAL",
      "value": "B503",
      "type": "psc_code"
   },
   {
      "label": "B503 - SPECIAL STUDIES/ANALYSIS- ARCHEOLOGICAL/PALEONTOLOGICAL",
      "value": "B503",
      "type": "psc_code"
   },
   {
      "label": "B504 - STUDY/CHEMICAL-BIOLOGICAL",
      "value": "B504",
      "type": "psc_code"
   },
   {
      "label": "B504 - SPECIAL STUDIES/ANALYSIS- CHEMICAL/BIOLOGICAL",
      "value": "B504",
      "type": "psc_code"
   },
   {
      "label": "B505 - COST BENEFIT ANALYSIS",
      "value": "B505",
      "type": "psc_code"
   },
   {
      "label": "B505 - SPECIAL STUDIES/ANALYSIS- COST BENEFIT",
      "value": "B505",
      "type": "psc_code"
   },
   {
      "label": "B506 - SPECIAL STUDIES/ANALYSIS- DATA (OTHER THAN SCIENTIFIC)",
      "value": "B506",
      "type": "psc_code"
   },
   {
      "label": "B506 - STUDY/DATA - OTHER THAN SCIENTIFIC",
      "value": "B506",
      "type": "psc_code"
   },
   {
      "label": "B507 - ECONOMIC STUDIES",
      "value": "B507",
      "type": "psc_code"
   },
   {
      "label": "B507 - SPECIAL STUDIES/ANALYSIS- ECONOMIC",
      "value": "B507",
      "type": "psc_code"
   },
   {
      "label": "B509 - SPECIAL STUDIES/ANALYSIS- ENDANGERED SPECIES: PLANT/ANIMAL",
      "value": "B509",
      "type": "psc_code"
   },
   {
      "label": "B509 - STUDY/ENDANGERED SPECIES-PLANT/ANIM",
      "value": "B509",
      "type": "psc_code"
   },
   {
      "label": "B510 - SPECIAL STUDIES/ANALYSIS- ENVIRONMENTAL ASSESSMENTS",
      "value": "B510",
      "type": "psc_code"
   },
   {
      "label": "B510 - STUDY/ENVIRONMENTAL ASSESSMENTS",
      "value": "B510",
      "type": "psc_code"
   },
   {
      "label": "",
      "value": "B511",
      "type": "psc_code"
   },
   {
      "label": "",
      "value": "B512",
      "type": "psc_code"
   },
   {
      "label": "B513 - SPECIAL STUDIES/ANALYSIS- FEASIBILITY (NON-CONSTRUCTION)",
      "value": "B513",
      "type": "psc_code"
   },
   {
      "label": "B513 - STUDY/FEASIBILITY - NON-CONTRUCT",
      "value": "B513",
      "type": "psc_code"
   },
   {
      "label": "B516 - SPECIAL STUDIES/ANALYSIS- ANIMAL/FISHERIES",
      "value": "B516",
      "type": "psc_code"
   },
   {
      "label": "B516 - ANIMAL AND FISHERIES STUDIES",
      "value": "B516",
      "type": "psc_code"
   },
   {
      "label": "B517 - SPECIAL STUDIES/ANALYSIS- GEOLOGICAL",
      "value": "B517",
      "type": "psc_code"
   },
   {
      "label": "B517 - GEOLOGICAL STUDIES",
      "value": "B517",
      "type": "psc_code"
   },
   {
      "label": "B518 - SPECIAL STUDIES/ANALYSIS- GEOPHYSICAL",
      "value": "B518",
      "type": "psc_code"
   },
   {
      "label": "B518 - GEOPHYSICAL STUDIES",
      "value": "B518",
      "type": "psc_code"
   },
   {
      "label": "B519 - GEOTECHNICAL STUDIES",
      "value": "B519",
      "type": "psc_code"
   },
   {
      "label": "B519 - SPECIAL STUDIES/ANALYSIS- GEOTECHNICAL",
      "value": "B519",
      "type": "psc_code"
   },
   {
      "label": "B520 - GRAZING/RANGE STUDIES",
      "value": "B520",
      "type": "psc_code"
   },
   {
      "label": "B520 - SPECIAL STUDIES/ANALYSIS- GRAZING/RANGE",
      "value": "B520",
      "type": "psc_code"
   },
   {
      "label": "B521 - SPECIAL STUDIES/ANALYSIS- HISTORICAL",
      "value": "B521",
      "type": "psc_code"
   },
   {
      "label": "B521 - HISTORICAL STUDIES",
      "value": "B521",
      "type": "psc_code"
   },
   {
      "label": "B522 - LEGAL STUDIES",
      "value": "B522",
      "type": "psc_code"
   },
   {
      "label": "B522 - SPECIAL STUDIES/ANALYSIS- LEGAL",
      "value": "B522",
      "type": "psc_code"
   },
   {
      "label": "B524 - SPECIAL STUDIES/ANALYSIS- MATHEMATICAL/STATISTICAL",
      "value": "B524",
      "type": "psc_code"
   },
   {
      "label": "B524 - MATHEMATICAL/STATISTICAL Analysis",
      "value": "B524",
      "type": "psc_code"
   },
   {
      "label": "B525 - SPECIAL STUDIES/ANALYSIS- NATURAL RESOURCE",
      "value": "B525",
      "type": "psc_code"
   },
   {
      "label": "B525 - NATURAL RESOURCE STUDIES",
      "value": "B525",
      "type": "psc_code"
   },
   {
      "label": "B526 - SPECIAL STUDIES/ANALYSIS- OCEANOLOGICAL",
      "value": "B526",
      "type": "psc_code"
   },
   {
      "label": "B526 - OCEANOLOGICAL STUDIES",
      "value": "B526",
      "type": "psc_code"
   },
   {
      "label": "B527 - SPECIAL STUDIES/ANALYSIS- RECREATION",
      "value": "B527",
      "type": "psc_code"
   },
   {
      "label": "B527 - RECREATION STUDIES",
      "value": "B527",
      "type": "psc_code"
   },
   {
      "label": "B528 - REGULATORY STUDIES",
      "value": "B528",
      "type": "psc_code"
   },
   {
      "label": "B528 - SPECIAL STUDIES/ANALYSIS- REGULATORY",
      "value": "B528",
      "type": "psc_code"
   },
   {
      "label": "B529 - SPECIAL STUDIES/ANALYSIS- SCIENTIFIC DATA",
      "value": "B529",
      "type": "psc_code"
   },
   {
      "label": "B529 - SCIENTIFIC DATA STUDIES",
      "value": "B529",
      "type": "psc_code"
   },
   {
      "label": "B530 - SEISMOLOGICAL STUDIES",
      "value": "B530",
      "type": "psc_code"
   },
   {
      "label": "B530 - SPECIAL STUDIES/ANALYSIS- SEISMOLOGICAL",
      "value": "B530",
      "type": "psc_code"
   },
   {
      "label": "B532 - SOIL STUDIES",
      "value": "B532",
      "type": "psc_code"
   },
   {
      "label": "B532 - SPECIAL STUDIES/ANALYSIS- SOIL",
      "value": "B532",
      "type": "psc_code"
   },
   {
      "label": "B533 - WATER QUALITY STUDIES",
      "value": "B533",
      "type": "psc_code"
   },
   {
      "label": "B533 - SPECIAL STUDIES/ANALYSIS- WATER QUALITY",
      "value": "B533",
      "type": "psc_code"
   },
   {
      "label": "B534 - WILDLIFE STUDIES",
      "value": "B534",
      "type": "psc_code"
   },
   {
      "label": "B534 - SPECIAL STUDIES/ANALYSIS- WILDLIFE",
      "value": "B534",
      "type": "psc_code"
   },
   {
      "label": "B537 - MEDICAL AND HEALTH STUDIES",
      "value": "B537",
      "type": "psc_code"
   },
   {
      "label": "B537 - SPECIAL STUDIES/ANALYSIS- MEDICAL/HEALTH",
      "value": "B537",
      "type": "psc_code"
   },
   {
      "label": "B538 - SPECIAL STUDIES/ANALYSIS- INTELLIGENCE",
      "value": "B538",
      "type": "psc_code"
   },
   {
      "label": "B538 - INTELLIGENCE STUDIES",
      "value": "B538",
      "type": "psc_code"
   },
   {
      "label": "B539 - AERONAUTIC/SPACE STUDIES",
      "value": "B539",
      "type": "psc_code"
   },
   {
      "label": "B539 - SPECIAL STUDIES/ANALYSIS- AERONAUTICAL/SPACE",
      "value": "B539",
      "type": "psc_code"
   },
   {
      "label": "B540 - SPECIAL STUDIES/ANALYSIS- BUILDING TECHNOLOGY",
      "value": "B540",
      "type": "psc_code"
   },
   {
      "label": "B540 - BUILDING TECHNOLOGY STUDIES",
      "value": "B540",
      "type": "psc_code"
   },
   {
      "label": "B541 - SPECIAL STUDIES/ANALYSIS- DEFENSE",
      "value": "B541",
      "type": "psc_code"
   },
   {
      "label": "B541 - DEFENSE STUDIES",
      "value": "B541",
      "type": "psc_code"
   },
   {
      "label": "B542 - SPECIAL STUDIES/ANALYSIS- EDUCATIONAL",
      "value": "B542",
      "type": "psc_code"
   },
   {
      "label": "B542 - EDUCATIONAL STUDIES AND Analysis",
      "value": "B542",
      "type": "psc_code"
   },
   {
      "label": "B543 - SPECIAL STUDIES/ANALYSIS- ENERGY",
      "value": "B543",
      "type": "psc_code"
   },
   {
      "label": "B543 - ENERGY STUDIES",
      "value": "B543",
      "type": "psc_code"
   },
   {
      "label": "B544 - TECHNOLOGY STUDIES",
      "value": "B544",
      "type": "psc_code"
   },
   {
      "label": "B544 - SPECIAL STUDIES/ANALYSIS- TECHNOLOGY",
      "value": "B544",
      "type": "psc_code"
   },
   {
      "label": "B545 - STUDY/HOUSING AND COMMUNITY DEVELOP",
      "value": "B545",
      "type": "psc_code"
   },
   {
      "label": "B545 - SPECIAL STUDIES/ANALYSIS- HOUSING/COMMUNITY DEVELOPMENT",
      "value": "B545",
      "type": "psc_code"
   },
   {
      "label": "B546 - SPECIAL STUDIES/ANALYSIS- SECURITY (PHYSICAL/PERSONAL)",
      "value": "B546",
      "type": "psc_code"
   },
   {
      "label": "B546 - STUDY/SECURITY (PHYSICAL & PERSONAL",
      "value": "B546",
      "type": "psc_code"
   },
   {
      "label": "B547 - SPECIAL STUDIES/ANALYSIS- ACCOUNTING/FINANCIAL MANAGEMENT",
      "value": "B547",
      "type": "psc_code"
   },
   {
      "label": "B547 - STUDY/ACCOUNTING/FINANCIAL MGT",
      "value": "B547",
      "type": "psc_code"
   },
   {
      "label": "B548 - TRADE ISSUE STUDIES",
      "value": "B548",
      "type": "psc_code"
   },
   {
      "label": "B548 - SPECIAL STUDIES/ANALYSIS- TRADE ISSUE",
      "value": "B548",
      "type": "psc_code"
   },
   {
      "label": "B549 - STUDY/FOREIGN & NAT'L SECURITY POL",
      "value": "B549",
      "type": "psc_code"
   },
   {
      "label": "B549 - SPECIAL STUDIES/ANALYSIS- FOREIGN/NATIONAL SECURITY POLICY",
      "value": "B549",
      "type": "psc_code"
   },
   {
      "label": "B550 - SPECIAL STUDIES/ANALYSIS- ORGANIZATION/ADMINISTRATIVE/PERSONNEL",
      "value": "B550",
      "type": "psc_code"
   },
   {
      "label": "B550 - STUDY/ORGANIZATION/ADMIN/PERSONNEL",
      "value": "B550",
      "type": "psc_code"
   },
   {
      "label": "B551 - SPECIAL STUDIES/ANALYSIS- MOBILIZATION/PREPAREDNESS",
      "value": "B551",
      "type": "psc_code"
   },
   {
      "label": "B551 - MOBILIZATION/PREPAREDNESS STUDIES",
      "value": "B551",
      "type": "psc_code"
   },
   {
      "label": "B552 - MANPOWER STUDIES",
      "value": "B552",
      "type": "psc_code"
   },
   {
      "label": "B552 - SPECIAL STUDIES/ANALYSIS- MANPOWER",
      "value": "B552",
      "type": "psc_code"
   },
   {
      "label": "B553 - SPECIAL STUDIES/ANALYSIS- COMMUNICATIONS",
      "value": "B553",
      "type": "psc_code"
   },
   {
      "label": "B553 - COMMUNICATIONS STUDIES",
      "value": "B553",
      "type": "psc_code"
   },
   {
      "label": "B554 - STUDY/ACQUISITION POLICY/PROCEDURES",
      "value": "B554",
      "type": "psc_code"
   },
   {
      "label": "B554 - SPECIAL STUDIES/ANALYSIS- ACQUISITION POLICY/PROCEDURES",
      "value": "B554",
      "type": "psc_code"
   },
   {
      "label": "B555 - ELDERLY/HANDICAPPED STUDIES",
      "value": "B555",
      "type": "psc_code"
   },
   {
      "label": "B555 - SPECIAL STUDIES/ANALYSIS- ELDERLY/HANDICAPPED",
      "value": "B555",
      "type": "psc_code"
   },
   {
      "label": "B599 - OTHER SPECIAL STUDIES AND Analysis",
      "value": "B599",
      "type": "psc_code"
   },
   {
      "label": "B599 - SPECIAL STUDIES/ANALYSIS- OTHER",
      "value": "B599",
      "type": "psc_code"
   },
   {
      "label": "C - ARCHITECT/ENGINEER SERVICES",
      "value": "C",
      "type": "psc_code"
   },
   {
      "label": "C1 - ARCH-ENG SVCS - CONSTRUCTION",
      "value": "C1",
      "type": "psc_code"
   },
   {
      "label": "C111 - BLDGS & FAC / ADMIN & SVC BLDGS",
      "value": "C111",
      "type": "psc_code"
   },
   {
      "label": "C112 - BLDGS & FAC / AIRFIELD, COM & MSL F",
      "value": "C112",
      "type": "psc_code"
   },
   {
      "label": "C113 - EDUCATIONAL BUILDINGS",
      "value": "C113",
      "type": "psc_code"
   },
   {
      "label": "C114 - HOSPITAL BUILDINGS",
      "value": "C114",
      "type": "psc_code"
   },
   {
      "label": "C115 - INDUSTRIAL BUILDINGS",
      "value": "C115",
      "type": "psc_code"
   },
   {
      "label": "C116 - RESIDENTIAL BUILDINGS",
      "value": "C116",
      "type": "psc_code"
   },
   {
      "label": "C117 - WAREHOUSE BUILDINGS",
      "value": "C117",
      "type": "psc_code"
   },
   {
      "label": "C118 - RESEARCH AND DEVELOPMENT FACILITIES",
      "value": "C118",
      "type": "psc_code"
   },
   {
      "label": "C119 - OTHER BUILDINGS",
      "value": "C119",
      "type": "psc_code"
   },
   {
      "label": "C121 - CONSERVATION AND DEVELOPMENT",
      "value": "C121",
      "type": "psc_code"
   },
   {
      "label": "C122 - HIGHWAY, ROADS, STREETS, BRIDGES, A",
      "value": "C122",
      "type": "psc_code"
   },
   {
      "label": "C123 - ELECTRIC POWER GENERATION (EPG)",
      "value": "C123",
      "type": "psc_code"
   },
   {
      "label": "C124 - UTILITIES",
      "value": "C124",
      "type": "psc_code"
   },
   {
      "label": "C129 - OTHER NON-BUILDING STRUCTURES",
      "value": "C129",
      "type": "psc_code"
   },
   {
      "label": "C130 - RESTORATION",
      "value": "C130",
      "type": "psc_code"
   },
   {
      "label": "C1AA - ARCHITECT AND ENGINEERING- CONSTRUCTION: OFFICE BUILDINGS",
      "value": "C1AA",
      "type": "psc_code"
   },
   {
      "label": "C1AB - ARCHITECT AND ENGINEERING- CONSTRUCTION: CONFERENCE SPACE AND FACILITIES",
      "value": "C1AB",
      "type": "psc_code"
   },
   {
      "label": "C1AZ - ARCHITECT AND ENGINEERING- CONSTRUCTION: OTHER ADMINISTRATIVE FACILITIES/SERVICE BUILDINGS",
      "value": "C1AZ",
      "type": "psc_code"
   },
   {
      "label": "C1BA - ARCHITECT AND ENGINEERING- CONSTRUCTION: AIR TRAFFIC CONTROL TOWERS",
      "value": "C1BA",
      "type": "psc_code"
   },
   {
      "label": "C1BB - ARCHITECT AND ENGINEERING- CONSTRUCTION: AIR TRAFFIC CONTROL TRAINING FACILITIES",
      "value": "C1BB",
      "type": "psc_code"
   },
   {
      "label": "C1BC - ARCHITECT AND ENGINEERING- CONSTRUCTION: RADAR AND NAVIGATIONAL FACILITIES",
      "value": "C1BC",
      "type": "psc_code"
   },
   {
      "label": "C1BD - ARCHITECT AND ENGINEERING- CONSTRUCTION: AIRPORT RUNWAYS AND TAXIWAYS",
      "value": "C1BD",
      "type": "psc_code"
   },
   {
      "label": "C1BE - ARCHITECT AND ENGINEERING- CONSTRUCTION: AIRPORT TERMINALS",
      "value": "C1BE",
      "type": "psc_code"
   },
   {
      "label": "C1BF - ARCHITECT AND ENGINEERING- CONSTRUCTION: MISSILE SYSTEM FACILITIES",
      "value": "C1BF",
      "type": "psc_code"
   },
   {
      "label": "C1BG - ARCHITECT AND ENGINEERING- CONSTRUCTION: ELECTRONIC AND COMMUNICATIONS FACILITIES",
      "value": "C1BG",
      "type": "psc_code"
   },
   {
      "label": "C1BZ - ARCHITECT AND ENGINEERING- CONSTRUCTION: OTHER AIRFIELD STRUCTURES",
      "value": "C1BZ",
      "type": "psc_code"
   },
   {
      "label": "C1CA - ARCHITECT AND ENGINEERING- CONSTRUCTION: SCHOOLS",
      "value": "C1CA",
      "type": "psc_code"
   },
   {
      "label": "C1CZ - ARCHITECT AND ENGINEERING- CONSTRUCTION: OTHER EDUCATIONAL BUILDINGS",
      "value": "C1CZ",
      "type": "psc_code"
   },
   {
      "label": "C1DA - ARCHITECT AND ENGINEERING- CONSTRUCTION: HOSPITALS AND INFIRMARIES",
      "value": "C1DA",
      "type": "psc_code"
   },
   {
      "label": "C1DB - ARCHITECT AND ENGINEERING- CONSTRUCTION: LABORATORIES AND CLINICS",
      "value": "C1DB",
      "type": "psc_code"
   },
   {
      "label": "C1DZ - ARCHITECT AND ENGINEERING- CONSTRUCTION: OTHER HOSPITAL BUILDINGS",
      "value": "C1DZ",
      "type": "psc_code"
   },
   {
      "label": "C1EA - ARCHITECT AND ENGINEERING- CONSTRUCTION: AMMUNITION FACILITIES",
      "value": "C1EA",
      "type": "psc_code"
   },
   {
      "label": "C1EB - ARCHITECT AND ENGINEERING- CONSTRUCTION: MAINTENANCE BUILDINGS",
      "value": "C1EB",
      "type": "psc_code"
   },
   {
      "label": "C1EC - ARCHITECT AND ENGINEERING- CONSTRUCTION: PRODUCTION BUILDINGS",
      "value": "C1EC",
      "type": "psc_code"
   },
   {
      "label": "C1ED - ARCHITECT AND ENGINEERING- CONSTRUCTION: SHIP CONSTRUCTION AND REPAIR FACILITIES",
      "value": "C1ED",
      "type": "psc_code"
   },
   {
      "label": "C1EE - ARCHITECT AND ENGINEERING- CONSTRUCTION: TANK AUTOMOTIVE FACILITIES",
      "value": "C1EE",
      "type": "psc_code"
   },
   {
      "label": "C1EZ - ARCHITECT AND ENGINEERING- CONSTRUCTION: OTHER INDUSTRIAL BUILDINGS",
      "value": "C1EZ",
      "type": "psc_code"
   },
   {
      "label": "C1FA - ARCHITECT AND ENGINEERING- CONSTRUCTION: FAMILY HOUSING FACILITIES",
      "value": "C1FA",
      "type": "psc_code"
   },
   {
      "label": "C1FB - ARCHITECT AND ENGINEERING- CONSTRUCTION: RECREATIONAL BUILDINGS",
      "value": "C1FB",
      "type": "psc_code"
   },
   {
      "label": "C1FC - ARCHITECT AND ENGINEERING- CONSTRUCTION: TROOP HOUSING FACILITIES",
      "value": "C1FC",
      "type": "psc_code"
   },
   {
      "label": "C1FD - ARCHITECT AND ENGINEERING- CONSTRUCTION: DINING FACILITIES",
      "value": "C1FD",
      "type": "psc_code"
   },
   {
      "label": "C1FE - ARCHITECT AND ENGINEERING- CONSTRUCTION: RELIGIOUS FACILITIES",
      "value": "C1FE",
      "type": "psc_code"
   },
   {
      "label": "C1FF - ARCHITECT AND ENGINEERING- CONSTRUCTION: PENAL FACILITIES",
      "value": "C1FF",
      "type": "psc_code"
   },
   {
      "label": "C1FZ - ARCHITECT AND ENGINEERING- CONSTRUCTION: OTHER RESIDENTIAL BUILDINGS",
      "value": "C1FZ",
      "type": "psc_code"
   },
   {
      "label": "C1GA - ARCHITECT AND ENGINEERING- CONSTRUCTION: AMMUNITION STORAGE BUILDINGS",
      "value": "C1GA",
      "type": "psc_code"
   },
   {
      "label": "C1GB - ARCHITECT AND ENGINEERING- CONSTRUCTION: FOOD OR GRAIN STORAGE BUILDINGS",
      "value": "C1GB",
      "type": "psc_code"
   },
   {
      "label": "C1GC - ARCHITECT AND ENGINEERING- CONSTRUCTION: FUEL STORAGE BUILDINGS",
      "value": "C1GC",
      "type": "psc_code"
   },
   {
      "label": "C1GD - ARCHITECT AND ENGINEERING- CONSTRUCTION: OPEN STORAGE FACILITIES",
      "value": "C1GD",
      "type": "psc_code"
   },
   {
      "label": "C1GZ - ARCHITECT AND ENGINEERING- CONSTRUCTION: OTHER WAREHOUSE BUILDINGS",
      "value": "C1GZ",
      "type": "psc_code"
   },
   {
      "label": "C1HA - ARCHITECT AND ENGINEERING- CONSTRUCTION: GOVERNMENT-OWNED CONTRACTOR-OPERATED (GOCO) R&D FACS",
      "value": "C1HA",
      "type": "psc_code"
   },
   {
      "label": "C1HB - ARCHITECT AND ENGINEERING- CONSTRUCTION: GOVERNMENT-OWNED GOVERNMENT-OPERATED (GOGO) R&D FACS",
      "value": "C1HB",
      "type": "psc_code"
   },
   {
      "label": "C1HC - ARCHITECT AND ENGINEERING- CONSTRUCTION: GOVT-OWNED CTR-OPERATED (GOCO) ENVIRONMENTAL LABORATORIES",
      "value": "C1HC",
      "type": "psc_code"
   },
   {
      "label": "C1HZ - ARCHITECT AND ENGINEERING- CONSTRUCTION: GOVT-OWNED GOVT-OPERATED (GOGO) ENVIRONMENTAL LABORATORIES",
      "value": "C1HZ",
      "type": "psc_code"
   },
   {
      "label": "C1JA - ARCHITECT AND ENGINEERING- CONSTRUCTION: MUSEUMS AND EXHIBITION BUILDINGS",
      "value": "C1JA",
      "type": "psc_code"
   },
   {
      "label": "C1JB - ARCHITECT AND ENGINEERING- CONSTRUCTION: TESTING AND MEASUREMENT BUILDINGS",
      "value": "C1JB",
      "type": "psc_code"
   },
   {
      "label": "C1JZ - ARCHITECT AND ENGINEERING- CONSTRUCTION: MISCELLANEOUS BUILDINGS",
      "value": "C1JZ",
      "type": "psc_code"
   },
   {
      "label": "C1KA - ARCHITECT AND ENGINEERING- CONSTRUCTION: DAMS",
      "value": "C1KA",
      "type": "psc_code"
   },
   {
      "label": "C1KB - ARCHITECT AND ENGINEERING- CONSTRUCTION: CANALS",
      "value": "C1KB",
      "type": "psc_code"
   },
   {
      "label": "C1KC - ARCHITECT AND ENGINEERING- CONSTRUCTION: MINE FIRE CONTROL FACILITIES",
      "value": "C1KC",
      "type": "psc_code"
   },
   {
      "label": "C1KD - ARCHITECT AND ENGINEERING- CONSTRUCTION: MINE SUBSIDENCE CONTROL FACILITIES",
      "value": "C1KD",
      "type": "psc_code"
   },
   {
      "label": "C1KE - ARCHITECT AND ENGINEERING- CONSTRUCTION: SURFACE MINE RECLAMATION FACILITIES",
      "value": "C1KE",
      "type": "psc_code"
   },
   {
      "label": "C1KF - ARCHITECT AND ENGINEERING- CONSTRUCTION: DREDGING FACILITIES",
      "value": "C1KF",
      "type": "psc_code"
   },
   {
      "label": "C1KZ - ARCHITECT AND ENGINEERING- CONSTRUCTION: OTHER CONSERVATION AND DEVELOPMENT FACILITIES",
      "value": "C1KZ",
      "type": "psc_code"
   },
   {
      "label": "C1LA - ARCHITECT AND ENGINEERING- CONSTRUCTION: AIRPORT SERVICE ROADS",
      "value": "C1LA",
      "type": "psc_code"
   },
   {
      "label": "C1LB - ARCHITECT AND ENGINEERING- CONSTRUCTION: HIGHWAYS, ROADS, STREETS, BRIDGES, AND RAILWAYS",
      "value": "C1LB",
      "type": "psc_code"
   },
   {
      "label": "C1LC - ARCHITECT AND ENGINEERING- CONSTRUCTION: TUNNELS AND SUBSURFACE STRUCTURES",
      "value": "C1LC",
      "type": "psc_code"
   },
   {
      "label": "C1LZ - ARCHITECT AND ENGINEERING- CONSTRUCTION: PARKING FACILITIES",
      "value": "C1LZ",
      "type": "psc_code"
   },
   {
      "label": "C1MA - ARCHITECT AND ENGINEERING- CONSTRUCTION: EPG FACILITIES - COAL",
      "value": "C1MA",
      "type": "psc_code"
   },
   {
      "label": "C1MB - ARCHITECT AND ENGINEERING- CONSTRUCTION: EPG FACILITIES - GAS",
      "value": "C1MB",
      "type": "psc_code"
   },
   {
      "label": "C1MC - ARCHITECT AND ENGINEERING- CONSTRUCTION: EPG FACILITIES - GEOTHERMAL",
      "value": "C1MC",
      "type": "psc_code"
   },
   {
      "label": "C1MD - ARCHITECT AND ENGINEERING- CONSTRUCTION: EPG FACILITIES - HYDRO",
      "value": "C1MD",
      "type": "psc_code"
   },
   {
      "label": "C1ME - ARCHITECT AND ENGINEERING- CONSTRUCTION: EPG FACILITIES - NUCLEAR",
      "value": "C1ME",
      "type": "psc_code"
   },
   {
      "label": "C1MF - ARCHITECT AND ENGINEERING- CONSTRUCTION: EPG FACILITIES - PETROLEUM",
      "value": "C1MF",
      "type": "psc_code"
   },
   {
      "label": "C1MG - ARCHITECT AND ENGINEERING- CONSTRUCTION: EPG FACILITIES - SOLAR",
      "value": "C1MG",
      "type": "psc_code"
   },
   {
      "label": "C1MH - ARCHITECT AND ENGINEERING- CONSTRUCTION: EPG FACILITIES - WIND",
      "value": "C1MH",
      "type": "psc_code"
   },
   {
      "label": "C1MZ - ARCHITECT AND ENGINEERING- CONSTRUCTION: EPG FACILITIES - OTHER, INCLUDING TRANSMISSION",
      "value": "C1MZ",
      "type": "psc_code"
   },
   {
      "label": "C1NA - ARCHITECT AND ENGINEERING- CONSTRUCTION: FUEL SUPPLY FACILITIES",
      "value": "C1NA",
      "type": "psc_code"
   },
   {
      "label": "C1NB - ARCHITECT AND ENGINEERING- CONSTRUCTION: HEATING AND COOLING PLANTS",
      "value": "C1NB",
      "type": "psc_code"
   },
   {
      "label": "C1NC - ARCHITECT AND ENGINEERING- CONSTRUCTION: POLLUTION ABATEMENT AND CONTROL FACILITIES",
      "value": "C1NC",
      "type": "psc_code"
   },
   {
      "label": "C1ND - ARCHITECT AND ENGINEERING- CONSTRUCTION: SEWAGE AND WASTE FACILITIES",
      "value": "C1ND",
      "type": "psc_code"
   },
   {
      "label": "C1NE - ARCHITECT AND ENGINEERING- CONSTRUCTION: WATER SUPPLY FACILITIES",
      "value": "C1NE",
      "type": "psc_code"
   },
   {
      "label": "C1NZ - ARCHITECT AND ENGINEERING- CONSTRUCTION: OTHER UTILITIES",
      "value": "C1NZ",
      "type": "psc_code"
   },
   {
      "label": "C1PA - ARCHITECT AND ENGINEERING- CONSTRUCTION: RECREATIONAL FACILITIES (NON-BUILDING)",
      "value": "C1PA",
      "type": "psc_code"
   },
   {
      "label": "C1PB - ARCHITECT AND ENGINEERING- CONSTRUCTION: EXHIBIT DESIGN (NON-BUILDING)",
      "value": "C1PB",
      "type": "psc_code"
   },
   {
      "label": "C1PC - ARCHITECT AND ENGINEERING- CONSTRUCTION: UNIMPROVED REAL PROPERTY (LAND)",
      "value": "C1PC",
      "type": "psc_code"
   },
   {
      "label": "C1PD - ARCHITECT AND ENGINEERING- CONSTRUCTION: WASTE TREATMENT AND STORAGE FACILITIES",
      "value": "C1PD",
      "type": "psc_code"
   },
   {
      "label": "C1PZ - ARCHITECT AND ENGINEERING- CONSTRUCTION: OTHER NON-BUILDING FACILITIES",
      "value": "C1PZ",
      "type": "psc_code"
   },
   {
      "label": "C1QA - ARCHITECT AND ENGINEERING- CONSTRUCTION: RESTORATION OF REAL PROPERTY (PUBLIC OR PRIVATE)",
      "value": "C1QA",
      "type": "psc_code"
   },
   {
      "label": "C2 - ARCH-ENG SVCS - GENERAL",
      "value": "C2",
      "type": "psc_code"
   },
   {
      "label": "C211 - ARCHITECT AND ENGINEERING- GENERAL: LANDSCAPING, INTERIOR LAYOUT, AND DESIGNING",
      "value": "C211",
      "type": "psc_code"
   },
   {
      "label": "C211 - A/E SVCS. (INCL LANDSCAPING INTERIO",
      "value": "C211",
      "type": "psc_code"
   },
   {
      "label": "C212 - ARCHITECT AND ENGINEERING- GENERAL: ENGINEERING DRAFTING, NOT CAD/CAM",
      "value": "C212",
      "type": "psc_code"
   },
   {
      "label": "C212 - ENGINEERING DRAFTING SERVICES",
      "value": "C212",
      "type": "psc_code"
   },
   {
      "label": "C213 - ARCHITECT AND ENGINEERING- GENERAL: INSPECTION (NON-CONSTRUCTION)",
      "value": "C213",
      "type": "psc_code"
   },
   {
      "label": "C213 - A/E - INSPECT - NON CONTRUCT",
      "value": "C213",
      "type": "psc_code"
   },
   {
      "label": "C214 - ARCHITECT AND ENGINEERING- GENERAL: MANAGEMENT ENGINEERING",
      "value": "C214",
      "type": "psc_code"
   },
   {
      "label": "C214 - A&E MANAGEMENT ENGINEERING SERVICES",
      "value": "C214",
      "type": "psc_code"
   },
   {
      "label": "C215 - A/E PROD ENG SVCS (INCL DESIGN-CONT",
      "value": "C215",
      "type": "psc_code"
   },
   {
      "label": "C215 - ARCHITECT AND ENGINEERING- GENERAL: PRODUCTION ENGINEERING",
      "value": "C215",
      "type": "psc_code"
   },
   {
      "label": "C216 - A/E - MARINE ENGINEERING",
      "value": "C216",
      "type": "psc_code"
   },
   {
      "label": "C216 - ARCHITECT AND ENGINEERING- GENERAL: MARINE ENGINEERING",
      "value": "C216",
      "type": "psc_code"
   },
   {
      "label": "C217 - A/E - MAPPING",
      "value": "C217",
      "type": "psc_code"
   },
   {
      "label": "C218 - A/E - SURVEYING",
      "value": "C218",
      "type": "psc_code"
   },
   {
      "label": "C219 - ARCHITECT AND ENGINEERING- GENERAL: OTHER",
      "value": "C219",
      "type": "psc_code"
   },
   {
      "label": "C219 - OTHER ARCHITECTS & ENGIN GEN",
      "value": "C219",
      "type": "psc_code"
   },
   {
      "label": "C220 - ARCHITECT AND ENGINEERING- GENERAL: STRUCTURAL ENGINEERING",
      "value": "C220",
      "type": "psc_code"
   },
   {
      "label": "C221 - ARCHITECT AND ENGINEERING- GENERAL: PLUMBING SYSTEMS",
      "value": "C221",
      "type": "psc_code"
   },
   {
      "label": "C222 - ARCHITECT AND ENGINEERING- GENERAL: ELECTRICAL SYSTEMS",
      "value": "C222",
      "type": "psc_code"
   },
   {
      "label": "C223 - ARCHITECT AND ENGINEERING- GENERAL: MECHANICAL SYSTEMS",
      "value": "C223",
      "type": "psc_code"
   },
   {
      "label": "D - ADP AND TELECOMMUNICATIONS",
      "value": "D",
      "type": "psc_code"
   },
   {
      "label": "D - IT AND TELECOM - INFORMATION TECHNOLOGY AND TELECOMMUNICATIONS",
      "value": "D",
      "type": "psc_code"
   },
   {
      "label": "D3 - ADP AND TELECOMMUNICATIONS",
      "value": "D3",
      "type": "psc_code"
   },
   {
      "label": "D301 - ADP FACILITY MANAGEMENT",
      "value": "D301",
      "type": "psc_code"
   },
   {
      "label": "D301 - IT AND TELECOM- FACILITY OPERATION AND MAINTENANCE",
      "value": "D301",
      "type": "psc_code"
   },
   {
      "label": "D302 - IT AND TELECOM- SYSTEMS DEVELOPMENT",
      "value": "D302",
      "type": "psc_code"
   },
   {
      "label": "D302 - ADP SYSTEMS DEVELOPMENT SERVICES",
      "value": "D302",
      "type": "psc_code"
   },
   {
      "label": "D303 - ADP DATA ENTRY SERVICES",
      "value": "D303",
      "type": "psc_code"
   },
   {
      "label": "D303 - IT AND TELECOM- DATA ENTRY",
      "value": "D303",
      "type": "psc_code"
   },
   {
      "label": "D304 - IT AND TELECOM- TELECOMMUNICATIONS AND TRANSMISSION",
      "value": "D304",
      "type": "psc_code"
   },
   {
      "label": "D304 - ADP SVCS/TELECOMM & TRANSMISSION",
      "value": "D304",
      "type": "psc_code"
   },
   {
      "label": "D305 - IT AND TELECOM \u2013 TELEPROCESSING, TIMESHARE, CLOUD COMPUTING, AND HIGH PERFORMANCE COMPUTING",
      "value": "D305",
      "type": "psc_code"
   },
   {
      "label": "D305 - IT AND TELECOM- TELEPROCESSING, TIMESHARE, AND CLOUD COMPUTING",
      "value": "D305",
      "type": "psc_code"
   },
   {
      "label": "D305 - ADP TELEPROCESSING & TIMESHARE",
      "value": "D305",
      "type": "psc_code"
   },
   {
      "label": "D306 - ADP SYSTEMS ANALYSIS SERVICES",
      "value": "D306",
      "type": "psc_code"
   },
   {
      "label": "D306 - IT AND TELECOM- SYSTEMS ANALYSIS",
      "value": "D306",
      "type": "psc_code"
   },
   {
      "label": "D307 - AUTOMATED INFORMATION SYSTEM SVCS",
      "value": "D307",
      "type": "psc_code"
   },
   {
      "label": "D307 - IT AND TELECOM- IT STRATEGY AND ARCHITECTURE",
      "value": "D307",
      "type": "psc_code"
   },
   {
      "label": "D308 - PROGRAMMING SERVICES",
      "value": "D308",
      "type": "psc_code"
   },
   {
      "label": "D308 - IT AND TELECOM- PROGRAMMING",
      "value": "D308",
      "type": "psc_code"
   },
   {
      "label": "D309 - ADP INF, BROADCAST & DIST SVCS",
      "value": "D309",
      "type": "psc_code"
   },
   {
      "label": "D309 - IT AND TELECOM- INFORMATION AND DATA BROADCASTING OR DATA DISTRIBUTION",
      "value": "D309",
      "type": "psc_code"
   },
   {
      "label": "D310 - ADP BACKUP AND SECURITY SERVICES",
      "value": "D310",
      "type": "psc_code"
   },
   {
      "label": "D310 - IT AND TELECOM- CYBER SECURITY AND DATA BACKUP",
      "value": "D310",
      "type": "psc_code"
   },
   {
      "label": "D311 - IT AND TELECOM- DATA CONVERSION",
      "value": "D311",
      "type": "psc_code"
   },
   {
      "label": "D311 - ADP DATA CONVERSION SERVICES",
      "value": "D311",
      "type": "psc_code"
   },
   {
      "label": "D312 - ADP OPTICAL SCANNING SERVICES",
      "value": "D312",
      "type": "psc_code"
   },
   {
      "label": "D312 - IT AND TELECOM- OPTICAL SCANNING",
      "value": "D312",
      "type": "psc_code"
   },
   {
      "label": "D313 - IT AND TELECOM- COMPUTER AIDED DESIGN/COMPUTER AIDED MANUFACTURING (CAD/CAM)",
      "value": "D313",
      "type": "psc_code"
   },
   {
      "label": "D313 - COMPUTER AIDED DESGN/MFG SVCS",
      "value": "D313",
      "type": "psc_code"
   },
   {
      "label": "D314 - ADP ACQUISITION SUP SVCS",
      "value": "D314",
      "type": "psc_code"
   },
   {
      "label": "D314 - IT AND TELECOM- SYSTEM ACQUISITION SUPPORT",
      "value": "D314",
      "type": "psc_code"
   },
   {
      "label": "D315 - DIGITIZING SVCS",
      "value": "D315",
      "type": "psc_code"
   },
   {
      "label": "D315 - IT AND TELECOM- DIGITIZING",
      "value": "D315",
      "type": "psc_code"
   },
   {
      "label": "D316 - TELECOMMUNICATION NETWORK MGMT SVCS",
      "value": "D316",
      "type": "psc_code"
   },
   {
      "label": "D316 - IT AND TELECOM- TELECOMMUNICATIONS NETWORK MANAGEMENT",
      "value": "D316",
      "type": "psc_code"
   },
   {
      "label": "D317 - AUTO NEWS, DATA & OTHER SVCS",
      "value": "D317",
      "type": "psc_code"
   },
   {
      "label": "D317 - IT AND TELECOM- WEB-BASED SUBSCRIPTION",
      "value": "D317",
      "type": "psc_code"
   },
   {
      "label": "D318 - IT AND TELECOM- INTEGRATED HARDWARE/SOFTWARE/SERVICES SOLUTIONS, PREDOMINANTLY SERVICES",
      "value": "D318",
      "type": "psc_code"
   },
   {
      "label": "D319 - IT AND TELECOM- ANNUAL SOFTWARE MAINTENANCE SERVICE PLANS",
      "value": "D319",
      "type": "psc_code"
   },
   {
      "label": "D320 - IT AND TELECOM- ANNUAL HARDWARE MAINTENANCE SERVICE PLANS",
      "value": "D320",
      "type": "psc_code"
   },
   {
      "label": "D321 - IT AND TELECOM- HELP DESK",
      "value": "D321",
      "type": "psc_code"
   },
   {
      "label": "D322 - IT AND TELECOM- INTERNET",
      "value": "D322",
      "type": "psc_code"
   },
   {
      "label": "D324 - IT AND TELECOM- BUSINESS CONTINUITY",
      "value": "D324",
      "type": "psc_code"
   },
   {
      "label": "D325 - IT AND TELECOM- DATA CENTERS AND STORAGE",
      "value": "D325",
      "type": "psc_code"
   },
   {
      "label": "D399 - OTHER ADP & TELECOMMUNICATIONS SVCS",
      "value": "D399",
      "type": "psc_code"
   },
   {
      "label": "D399 - IT AND TELECOM- OTHER IT AND TELECOMMUNICATIONS",
      "value": "D399",
      "type": "psc_code"
   },
   {
      "label": "DA - IT AND TELECOM - APLLICATIONS",
      "value": "DA",
      "type": "psc_code"
   },
   {
      "label": "DA01 - IT AND TELECOM - BUSINESS APPLICATION/APPLICATION DEVELOPMENT SUPPORT SERVICES (LABOR)",
      "value": "DA01",
      "type": "psc_code"
   },
   {
      "label": "DA10 - IT AND TELECOM - BUSINESS APPLICATION/APPLICATION DEVELOPMENT SOFTWARE AS A SERVICE",
      "value": "DA10",
      "type": "psc_code"
   },
   {
      "label": "DB - IT AND TELECOM - COMPUTE",
      "value": "DB",
      "type": "psc_code"
   },
   {
      "label": "DB01 - IT AND TELECOM - HIGH PERFORMANCE COMPUTING (HPC) SUPPORT SERVICES (LABOR)",
      "value": "DB01",
      "type": "psc_code"
   },
   {
      "label": "DB02 - IT AND TELECOM - COMPUTE SUPPORT SERVICES, NON-HPC (LABOR)",
      "value": "DB02",
      "type": "psc_code"
   },
   {
      "label": "DB10 - IT AND TELECOM - COMPUTE AS A SERVICE: MAINFRAME/SERVERS",
      "value": "DB10",
      "type": "psc_code"
   },
   {
      "label": "DC - IT AND TELECOM - DATA CENTER",
      "value": "DC",
      "type": "psc_code"
   },
   {
      "label": "DC01 - IT AND TELECOM - DATA CENTER SUPPORT SERVICES (LABOR)",
      "value": "DC01",
      "type": "psc_code"
   },
   {
      "label": "DC10 - IT AND TELECOM - DATA CENTER AS A SERVICE",
      "value": "DC10",
      "type": "psc_code"
   },
   {
      "label": "DD - IT AND TELECOM - DELIVERY",
      "value": "DD",
      "type": "psc_code"
   },
   {
      "label": "DD01 - IT AND TELECOM - SERVICE DELIVERY SUPPORT SERVICES: ITSM, OPERATIONS CENTER, PROJECT/PM (LABOR)",
      "value": "DD01",
      "type": "psc_code"
   },
   {
      "label": "DE - IT AND TELECOM - END USER",
      "value": "DE",
      "type": "psc_code"
   },
   {
      "label": "DE01 - IT AND TELECOM - END USER: HELP DESK; TIER1-2, WORKSPACE, PRINT, PRODUCTIVITY TOOLS (LABOR)",
      "value": "DE01",
      "type": "psc_code"
   },
   {
      "label": "DE02 - IT AND TELECOM - MOBILE DEVICE SUPPORT SERVICES (LABOR)",
      "value": "DE02",
      "type": "psc_code"
   },
   {
      "label": "DE10 - IT AND TELECOM - END USER AS A SERVICE: HELP DESK; TIER 1-2, WORKSPACE, PRINT, PRODUCTIVITY TOOLS",
      "value": "DE10",
      "type": "psc_code"
   },
   {
      "label": "DE11 - IT AND TELECOM - MOBILE DEVICE AS A SERVICE",
      "value": "DE11",
      "type": "psc_code"
   },
   {
      "label": "DF - IT AND TELECOM - IT MANAGEMENT",
      "value": "DF",
      "type": "psc_code"
   },
   {
      "label": "DF01 - IT AND TELECOM - IT MANAGEMENT SUPPORT SERVICES (LABOR)",
      "value": "DF01",
      "type": "psc_code"
   },
   {
      "label": "DF10 - IT AND TELECOM - IT MANAGEMENT AS A SERVICE",
      "value": "DF10",
      "type": "psc_code"
   },
   {
      "label": "DG - IT AND TELECOM - NETWORK",
      "value": "DG",
      "type": "psc_code"
   },
   {
      "label": "DG01 - IT AND TELECOM - NETWORK SUPPORT SERVICES (LABOR)",
      "value": "DG01",
      "type": "psc_code"
   },
   {
      "label": "DG10 - IT AND TELECOM - NETWORK AS A SERVICE",
      "value": "DG10",
      "type": "psc_code"
   },
   {
      "label": "DG11 - IT AND TELECOM - NETWORK - TELECOM ACCESS SERVICES",
      "value": "DG11",
      "type": "psc_code"
   },
   {
      "label": "DH - IT AND TELECOM - PLATFORM",
      "value": "DH",
      "type": "psc_code"
   },
   {
      "label": "DH01 - IT AND TELECOM - PLATFORM SUPPORT SERVICES: DATABASE, MAINFRAME, MIDDLEWARE (LABOR)",
      "value": "DH01",
      "type": "psc_code"
   },
   {
      "label": "DH10 - IT AND TELECOM - PLATFORM AS A SERVICE: DATABASE, MAINFRAME, MIDDLEWARE",
      "value": "DH10",
      "type": "psc_code"
   },
   {
      "label": "DJ - IT AND TELECOM - SECURITY AND COMPLIANCE",
      "value": "DJ",
      "type": "psc_code"
   },
   {
      "label": "DJ01 - IT AND TELECOM - SECURITY AND COMPLIANCE SUPPORT SERVICES (LABOR)",
      "value": "DJ01",
      "type": "psc_code"
   },
   {
      "label": "DJ10 - IT AND TELECOM - SECURITY AND COMPLIANCE AS A SERVICE",
      "value": "DJ10",
      "type": "psc_code"
   },
   {
      "label": "DK - IT AND TELECOM - STORAGE",
      "value": "DK",
      "type": "psc_code"
   },
   {
      "label": "DK01 - IT AND TELECOM - STORAGE SUPPORT SERVICES (LABOR)",
      "value": "DK01",
      "type": "psc_code"
   },
   {
      "label": "DK10 - IT AND TELECOM - STORAGE AS A SERVICE",
      "value": "DK10",
      "type": "psc_code"
   },
   {
      "label": "E - PURCHASE OF STRUCTURES/FACILITIES",
      "value": "E",
      "type": "psc_code"
   },
   {
      "label": "E1 - PURCHASE BUILDINGS",
      "value": "E1",
      "type": "psc_code"
   },
   {
      "label": "E111 - PURCHASE OF OFFICE BUILDINGS",
      "value": "E111",
      "type": "psc_code"
   },
   {
      "label": "E112 - PURCH OF GOVT CONF SPACE & FAC",
      "value": "E112",
      "type": "psc_code"
   },
   {
      "label": "E119 - PURCH OF GOVT OTHER ADMIN-SVC BLDGS",
      "value": "E119",
      "type": "psc_code"
   },
   {
      "label": "E121 - PURCH OF GOVT AIR TRAFFIC TOWERS",
      "value": "E121",
      "type": "psc_code"
   },
   {
      "label": "E122 - PURCH OF GOVT AIR TRAFFIC TNG FAC",
      "value": "E122",
      "type": "psc_code"
   },
   {
      "label": "E123 - PURCH OF GOVT RADAR & NAV FACILITY",
      "value": "E123",
      "type": "psc_code"
   },
   {
      "label": "E124 - PURCHASE OF AIRPORT RUNWAYS",
      "value": "E124",
      "type": "psc_code"
   },
   {
      "label": "E125 - PURCHASE OF AIRPORT TERMINALS",
      "value": "E125",
      "type": "psc_code"
   },
   {
      "label": "E126 - PURCH OF GOVT MISSILE SYSTEMS FAC",
      "value": "E126",
      "type": "psc_code"
   },
   {
      "label": "E127 - PURCH OF GOVT ELCT & COMM SYS FAC",
      "value": "E127",
      "type": "psc_code"
   },
   {
      "label": "E129 - PURCH OF GOVT OTHER AIRFIELD STRUCT",
      "value": "E129",
      "type": "psc_code"
   },
   {
      "label": "E131 - PURCHASE OF SCHOOLS",
      "value": "E131",
      "type": "psc_code"
   },
   {
      "label": "E139 - PURCH OF GOVT OTHER EDUCATIONAL BLD",
      "value": "E139",
      "type": "psc_code"
   },
   {
      "label": "E141 - PURCH OF GOVT HOSPITALS & INFIRMARY",
      "value": "E141",
      "type": "psc_code"
   },
   {
      "label": "E142 - PURCH OF GOVT LABS & CLINICS",
      "value": "E142",
      "type": "psc_code"
   },
   {
      "label": "E149 - PURCH OF GOVT OTHER HOSPITAL BLDGS",
      "value": "E149",
      "type": "psc_code"
   },
   {
      "label": "E151 - PURCHASE OF AMMUNITION FACILITIES",
      "value": "E151",
      "type": "psc_code"
   },
   {
      "label": "E152 - PURCHASE OF MAINTENANCE BUILDINGS",
      "value": "E152",
      "type": "psc_code"
   },
   {
      "label": "E153 - PURCHASE OF PRODUCTION BUILDINGS",
      "value": "E153",
      "type": "psc_code"
   },
   {
      "label": "E154 - PURCH OF GOVT SHIP CONST-REP FAC",
      "value": "E154",
      "type": "psc_code"
   },
   {
      "label": "E155 - PURCH OF GOVT TANK AUTOMOTIVE FAC",
      "value": "E155",
      "type": "psc_code"
   },
   {
      "label": "E159 - PURCH OF GOVT OTHER INDUSTRIAL BLDG",
      "value": "E159",
      "type": "psc_code"
   },
   {
      "label": "E161 - PURCH OF GOVT FAMILY HOUSING",
      "value": "E161",
      "type": "psc_code"
   },
   {
      "label": "E162 - PURCHASE OF RECREATIONAL BUILDINGS",
      "value": "E162",
      "type": "psc_code"
   },
   {
      "label": "E163 - PURCH OF GOVT TROOP HOUSING",
      "value": "E163",
      "type": "psc_code"
   },
   {
      "label": "E164 - PURCHASE OF DINING FACILITIES",
      "value": "E164",
      "type": "psc_code"
   },
   {
      "label": "E165 - PURCHASE OF RELIGIOUS FACILITIES",
      "value": "E165",
      "type": "psc_code"
   },
   {
      "label": "E166 - PURCHASE OF PENAL FACILITIES",
      "value": "E166",
      "type": "psc_code"
   },
   {
      "label": "E169 - PURCH OF GOVT OTHER RESIDENTIAL BLD",
      "value": "E169",
      "type": "psc_code"
   },
   {
      "label": "E171 - PURCH OF GOVT AMMO STORAGE BLDGS",
      "value": "E171",
      "type": "psc_code"
   },
   {
      "label": "E172 - PURCH OF GOVT FOOD STORAGE BLDGS",
      "value": "E172",
      "type": "psc_code"
   },
   {
      "label": "E173 - PURCHASE OF FUEL STORAGE BUILDINGS",
      "value": "E173",
      "type": "psc_code"
   },
   {
      "label": "E174 - PURCHASE OF OPEN STORAGE FACILITIES",
      "value": "E174",
      "type": "psc_code"
   },
   {
      "label": "E179 - PURCH OF GOVT OTHER WAREHOUSE BLDGS",
      "value": "E179",
      "type": "psc_code"
   },
   {
      "label": "E181 - PURCH OF GOVT R&D GOCO FACILITIES",
      "value": "E181",
      "type": "psc_code"
   },
   {
      "label": "E182 - PURCH OF GOVT R&D GOGO FACILITIES",
      "value": "E182",
      "type": "psc_code"
   },
   {
      "label": "E183 - PURCH OF GOVT R&D GOCO ENVIR LABS",
      "value": "E183",
      "type": "psc_code"
   },
   {
      "label": "E184 - PURCH OF GOVT R&D GOGO ENVIR LABS",
      "value": "E184",
      "type": "psc_code"
   },
   {
      "label": "E191 - PURCH OF GOVT EXHIBITION BUILDINGS",
      "value": "E191",
      "type": "psc_code"
   },
   {
      "label": "E192 - PURCH OF GOVT TEST BUILDINGS",
      "value": "E192",
      "type": "psc_code"
   },
   {
      "label": "E199 - PURCHASE OF MISCELLANEOUS BUILDINGS",
      "value": "E199",
      "type": "psc_code"
   },
   {
      "label": "E1AA - PURCHASE OF OFFICE BUILDINGS",
      "value": "E1AA",
      "type": "psc_code"
   },
   {
      "label": "E1AB - PURCHASE OF CONFERENCE SPACE AND FACILITIES",
      "value": "E1AB",
      "type": "psc_code"
   },
   {
      "label": "E1AZ - PURCHASE OF OTHER ADMINISTRATIVE FACILITIES AND SERVICE BUILDINGS",
      "value": "E1AZ",
      "type": "psc_code"
   },
   {
      "label": "E1BA - PURCHASE OF AIR TRAFFIC CONTROL TOWERS",
      "value": "E1BA",
      "type": "psc_code"
   },
   {
      "label": "E1BB - PURCHASE OF AIR TRAFFIC CONTROL TRAINING FACILITIES",
      "value": "E1BB",
      "type": "psc_code"
   },
   {
      "label": "E1BC - PURCHASE OF RADAR AND NAVIGATIONAL FACILITIES",
      "value": "E1BC",
      "type": "psc_code"
   },
   {
      "label": "E1BD - PURCHASE OF AIRPORT RUNWAYS AND TAXIWAYS",
      "value": "E1BD",
      "type": "psc_code"
   },
   {
      "label": "E1BE - PURCHASE OF AIRPORT TERMINALS",
      "value": "E1BE",
      "type": "psc_code"
   },
   {
      "label": "E1BF - PURCHASE OF MISSILE SYSTEM FACILITIES",
      "value": "E1BF",
      "type": "psc_code"
   },
   {
      "label": "E1BG - PURCHASE OF ELECTRONIC AND COMMUNICATIONS FACILITIES",
      "value": "E1BG",
      "type": "psc_code"
   },
   {
      "label": "E1BZ - PURCHASE OF OTHER AIRFIELD STRUCTURES",
      "value": "E1BZ",
      "type": "psc_code"
   },
   {
      "label": "E1CA - PURCHASE OF SCHOOLS",
      "value": "E1CA",
      "type": "psc_code"
   },
   {
      "label": "E1CZ - PURCHASE OF OTHER EDUCATIONAL BUILDINGS",
      "value": "E1CZ",
      "type": "psc_code"
   },
   {
      "label": "E1DA - PURCHASE OF HOSPITALS AND INFIRMARIES",
      "value": "E1DA",
      "type": "psc_code"
   },
   {
      "label": "E1DB - PURCHASE OF LABORATORIES AND CLINICS",
      "value": "E1DB",
      "type": "psc_code"
   },
   {
      "label": "E1DZ - PURCHASE OF OTHER HOSPITAL BUILDINGS",
      "value": "E1DZ",
      "type": "psc_code"
   },
   {
      "label": "E1EA - PURCHASE OF AMMUNITION FACILITIES",
      "value": "E1EA",
      "type": "psc_code"
   },
   {
      "label": "E1EB - PURCHASE OF MAINTENANCE BUILDINGS",
      "value": "E1EB",
      "type": "psc_code"
   },
   {
      "label": "E1EC - PURCHASE OF PRODUCTION BUILDINGS",
      "value": "E1EC",
      "type": "psc_code"
   },
   {
      "label": "E1ED - PURCHASE OF SHIP CONSTRUCTION AND REPAIR FACILITIES",
      "value": "E1ED",
      "type": "psc_code"
   },
   {
      "label": "E1EE - PURCHASE OF TANK AUTOMOTIVE FACILITIES",
      "value": "E1EE",
      "type": "psc_code"
   },
   {
      "label": "E1EZ - PURCHASE OF OTHER INDUSTRIAL BUILDINGS",
      "value": "E1EZ",
      "type": "psc_code"
   },
   {
      "label": "E1FA - PURCHASE OF FAMILY HOUSING FACILITIES",
      "value": "E1FA",
      "type": "psc_code"
   },
   {
      "label": "E1FB - PURCHASE OF RECREATIONAL BUILDINGS",
      "value": "E1FB",
      "type": "psc_code"
   },
   {
      "label": "E1FC - PURCHASE OF TROOP HOUSING FACILITIES",
      "value": "E1FC",
      "type": "psc_code"
   },
   {
      "label": "E1FD - PURCHASE OF DINING FACILITIES",
      "value": "E1FD",
      "type": "psc_code"
   },
   {
      "label": "E1FE - PURCHASE OF RELIGIOUS FACILITIES",
      "value": "E1FE",
      "type": "psc_code"
   },
   {
      "label": "E1FF - PURCHASE OF PENAL FACILITIES",
      "value": "E1FF",
      "type": "psc_code"
   },
   {
      "label": "E1FZ - PURCHASE OF OTHER RESIDENTIAL BUILDINGS",
      "value": "E1FZ",
      "type": "psc_code"
   },
   {
      "label": "E1GA - PURCHASE OF AMMUNITION STORAGE BUILDINGS",
      "value": "E1GA",
      "type": "psc_code"
   },
   {
      "label": "E1GB - PURCHASE OF FOOD OR GRAIN STORAGE BUILDINGS",
      "value": "E1GB",
      "type": "psc_code"
   },
   {
      "label": "E1GC - PURCHASE OF FUEL STORAGE BUILDINGS",
      "value": "E1GC",
      "type": "psc_code"
   },
   {
      "label": "E1GD - PURCHASE OF OPEN STORAGE FACILITIES",
      "value": "E1GD",
      "type": "psc_code"
   },
   {
      "label": "E1GZ - PURCHASE OF OTHER WAREHOUSE BUILDINGS",
      "value": "E1GZ",
      "type": "psc_code"
   },
   {
      "label": "E1HA - PURCHASE OF GOVERNMENT-OWNED CONTRACTOR-OPERATED (GOCO) R&D FACILITIES",
      "value": "E1HA",
      "type": "psc_code"
   },
   {
      "label": "E1HB - PURCHASE OF GOVERNMENT-OWNED GOVERNMENT-OPERATED (GOGO) R&D FACILITIES",
      "value": "E1HB",
      "type": "psc_code"
   },
   {
      "label": "E1HC - PURCHASE OF GOVERNMENT-OWNED CONTRACTOR-OPERATED (GOCO) ENVIRONMENTAL LABORATORIES",
      "value": "E1HC",
      "type": "psc_code"
   },
   {
      "label": "E1HZ - PURCHASE OF GOVERNMENT-OWNED GOVERNMENT-OPERATED (GOGO) ENVIRONMENTAL LABORATORIES",
      "value": "E1HZ",
      "type": "psc_code"
   },
   {
      "label": "E1JA - PURCHASE OF MUSEUMS AND EXHIBITION BUILDINGS",
      "value": "E1JA",
      "type": "psc_code"
   },
   {
      "label": "E1JB - PURCHASE OF TESTING AND MEASUREMENT BUILDINGS",
      "value": "E1JB",
      "type": "psc_code"
   },
   {
      "label": "E1JZ - PURCHASE OF MISCELLANEOUS BUILDINGS",
      "value": "E1JZ",
      "type": "psc_code"
   },
   {
      "label": "E1KA - PURCHASE OF DAMS",
      "value": "E1KA",
      "type": "psc_code"
   },
   {
      "label": "E1KB - PURCHASE OF CANALS",
      "value": "E1KB",
      "type": "psc_code"
   },
   {
      "label": "E1KC - PURCHASE OF MINE FIRE CONTROL FACILITIES",
      "value": "E1KC",
      "type": "psc_code"
   },
   {
      "label": "E1KD - PURCHASE OF MINE SUBSIDENCE CONTROL FACILITIES",
      "value": "E1KD",
      "type": "psc_code"
   },
   {
      "label": "E1KE - PURCHASE OF SURFACE MINE RECLAMATION FACILITIES",
      "value": "E1KE",
      "type": "psc_code"
   },
   {
      "label": "E1KF - PURCHASE OF DREDGING FACILITIES",
      "value": "E1KF",
      "type": "psc_code"
   },
   {
      "label": "E1KZ - PURCHASE OF OTHER CONSERVATION AND DEVELOPMENT FACILITIES",
      "value": "E1KZ",
      "type": "psc_code"
   },
   {
      "label": "E1LA - PURCHASE OF AIRPORT SERVICE ROADS",
      "value": "E1LA",
      "type": "psc_code"
   },
   {
      "label": "E1LB - PURCHASE OF HIGHWAYS, ROADS, STREETS, BRIDGES, AND RAILWAYS",
      "value": "E1LB",
      "type": "psc_code"
   },
   {
      "label": "E1LC - PURCHASE OF TUNNELS AND SUBSURFACE STRUCTURES",
      "value": "E1LC",
      "type": "psc_code"
   },
   {
      "label": "E1LZ - PURCHASE OF PARKING FACILITIES",
      "value": "E1LZ",
      "type": "psc_code"
   },
   {
      "label": "E1MA - PURCHASE OF EPG FACILITIES - COAL",
      "value": "E1MA",
      "type": "psc_code"
   },
   {
      "label": "E1MB - PURCHASE OF EPG FACILITIES - GAS",
      "value": "E1MB",
      "type": "psc_code"
   },
   {
      "label": "E1MC - PURCHASE OF EPG FACILITIES - GEOTHERMAL",
      "value": "E1MC",
      "type": "psc_code"
   },
   {
      "label": "E1MD - PURCHASE OF EPG FACILITIES - HYDRO",
      "value": "E1MD",
      "type": "psc_code"
   },
   {
      "label": "E1ME - PURCHASE OF EPG FACILITIES - NUCLEAR",
      "value": "E1ME",
      "type": "psc_code"
   },
   {
      "label": "E1MF - PURCHASE OF EPG FACILITIES - PETROLEUM",
      "value": "E1MF",
      "type": "psc_code"
   },
   {
      "label": "E1MG - PURCHASE OF EPG FACILITIES - SOLAR",
      "value": "E1MG",
      "type": "psc_code"
   },
   {
      "label": "E1MH - PURCHASE OF EPG FACILITIES - WIND",
      "value": "E1MH",
      "type": "psc_code"
   },
   {
      "label": "E1MZ - PURCHASE OF EPG FACILITIES - OTHER, INCLUDING TRANSMISSION",
      "value": "E1MZ",
      "type": "psc_code"
   },
   {
      "label": "E1NA - PURCHASE OF FUEL SUPPLY FACILITIES",
      "value": "E1NA",
      "type": "psc_code"
   },
   {
      "label": "E1NB - PURCHASE OF HEATING AND COOLING PLANTS",
      "value": "E1NB",
      "type": "psc_code"
   },
   {
      "label": "E1NC - PURCHASE OF POLLUTION ABATEMENT AND CONTROL FACILITIES",
      "value": "E1NC",
      "type": "psc_code"
   },
   {
      "label": "E1ND - PURCHASE OF SEWAGE AND WASTE FACILITIES",
      "value": "E1ND",
      "type": "psc_code"
   },
   {
      "label": "E1NE - PURCHASE OF WATER SUPPLY FACILITIES",
      "value": "E1NE",
      "type": "psc_code"
   },
   {
      "label": "E1NZ - PURCHASE OF OTHER UTILITIES",
      "value": "E1NZ",
      "type": "psc_code"
   },
   {
      "label": "E1PA - PURCHASE OF RECREATIONAL FACILITIES (NON-BUILDING)",
      "value": "E1PA",
      "type": "psc_code"
   },
   {
      "label": "E1PB - PURCHASE OF EXHIBIT DESIGN (NON-BUILDING)",
      "value": "E1PB",
      "type": "psc_code"
   },
   {
      "label": "E1PC - PURCHASE OF UNIMPROVED REAL PROPERTY (LAND)",
      "value": "E1PC",
      "type": "psc_code"
   },
   {
      "label": "E1PD - PURCHASE OF WASTE TREATMENT AND STORAGE FACILITIES",
      "value": "E1PD",
      "type": "psc_code"
   },
   {
      "label": "E1PZ - PURCHASE OF OTHER NON-BUILDING FACILITIES",
      "value": "E1PZ",
      "type": "psc_code"
   },
   {
      "label": "E1QA - PURCHASE OF RESTORATION OF REAL PROPERTY (PUBLIC OR PRIVATE)",
      "value": "E1QA",
      "type": "psc_code"
   },
   {
      "label": "E2 - PURCHASE FACILITY NOT A BUILDING",
      "value": "E2",
      "type": "psc_code"
   },
   {
      "label": "E211 - PURCHASE OF DAMS",
      "value": "E211",
      "type": "psc_code"
   },
   {
      "label": "E212 - PURCHASE OF CANALS",
      "value": "E212",
      "type": "psc_code"
   },
   {
      "label": "E213 - PURCH OF GOVT MINE FIRE CONT",
      "value": "E213",
      "type": "psc_code"
   },
   {
      "label": "E214 - PURCH OF GOVT MINE SUBSIDENCE CONT",
      "value": "E214",
      "type": "psc_code"
   },
   {
      "label": "E215 - PURCH OF GOVT SURFACE MINE RECLAM",
      "value": "E215",
      "type": "psc_code"
   },
   {
      "label": "E216 - PURCHASE OF DREDGING",
      "value": "E216",
      "type": "psc_code"
   },
   {
      "label": "E219 - PURCH OF GOVT OTHER CONSV STRUCTURE",
      "value": "E219",
      "type": "psc_code"
   },
   {
      "label": "E221 - PURCHASE OF AIRPORT SERVICE ROADS",
      "value": "E221",
      "type": "psc_code"
   },
   {
      "label": "E222 - PURCH OF GOVT HWYS-RDS-STS-BRDGS-RA",
      "value": "E222",
      "type": "psc_code"
   },
   {
      "label": "E223 - PURCH OF GOVT TUNNELS-SUBSURF STRUC",
      "value": "E223",
      "type": "psc_code"
   },
   {
      "label": "E224 - PURCH OF GOVT PARKING FACILITIES",
      "value": "E224",
      "type": "psc_code"
   },
   {
      "label": "E231 - PURCHASE OF EPG FACILITIES - COAL",
      "value": "E231",
      "type": "psc_code"
   },
   {
      "label": "E232 - PURCHASE OF EPG FACILITIES - GAS",
      "value": "E232",
      "type": "psc_code"
   },
   {
      "label": "E233 - PURCH OF GOVT EPG - GEOTHERMAL",
      "value": "E233",
      "type": "psc_code"
   },
   {
      "label": "E234 - PURCHASE OF EPG FACILITIES - HYDRO",
      "value": "E234",
      "type": "psc_code"
   },
   {
      "label": "E235 - PURCH OF GOVT EPG - NUCLEAR",
      "value": "E235",
      "type": "psc_code"
   },
   {
      "label": "E236 - PURCH OF GOVT EPG - PETROLEUM",
      "value": "E236",
      "type": "psc_code"
   },
   {
      "label": "E237 - PURCHASE OF EPG FACILITIES - SOLAR",
      "value": "E237",
      "type": "psc_code"
   },
   {
      "label": "E239 - PURCH OF GOVT EPG - OTHER",
      "value": "E239",
      "type": "psc_code"
   },
   {
      "label": "E241 - PURCHASE OF FUEL SUPPLY FACILITIES",
      "value": "E241",
      "type": "psc_code"
   },
   {
      "label": "E242 - PURCH OF GOVT HEATING & COOL PLANTS",
      "value": "E242",
      "type": "psc_code"
   },
   {
      "label": "E243 - PURCH OF GOVT POLLUTION ABATEMENT",
      "value": "E243",
      "type": "psc_code"
   },
   {
      "label": "E244 - PURCH OF GOVT SEWAGE & WASTE",
      "value": "E244",
      "type": "psc_code"
   },
   {
      "label": "E245 - PURCHASE OF WATER SUPPLY FACILITIES",
      "value": "E245",
      "type": "psc_code"
   },
   {
      "label": "E249 - PURCHASE OF OTHER UTILITIES",
      "value": "E249",
      "type": "psc_code"
   },
   {
      "label": "E291 - PURCH OF GOVT RECREA NON-BLDG STRUC",
      "value": "E291",
      "type": "psc_code"
   },
   {
      "label": "E292 - PURCH OF GOVT EXHIBIT (NON BLDG)",
      "value": "E292",
      "type": "psc_code"
   },
   {
      "label": "E293 - PURCH OF GOVT UNIMPROVED REAL PROP",
      "value": "E293",
      "type": "psc_code"
   },
   {
      "label": "E294 - PURCH OF GOVT WASTE TRMT-STORE FAC",
      "value": "E294",
      "type": "psc_code"
   },
   {
      "label": "E299 - PURCH OF GOVT ALL OTHR NON-BLDG FAC",
      "value": "E299",
      "type": "psc_code"
   },
   {
      "label": "E3 - PURCH RESTORATION OF REAL PROPERTY",
      "value": "E3",
      "type": "psc_code"
   },
   {
      "label": "E300 - PURCH OF GOVT RESTORATION",
      "value": "E300",
      "type": "psc_code"
   },
   {
      "label": "F - NATURAL RESOURCES MANAGEMENT",
      "value": "F",
      "type": "psc_code"
   },
   {
      "label": "F0 - NATURAL RESOURCE CONSERVERVAT SVCS",
      "value": "F0",
      "type": "psc_code"
   },
   {
      "label": "F001 - AERIAL FERTILIZATION - SPRAYING",
      "value": "F001",
      "type": "psc_code"
   },
   {
      "label": "F001 - NATURAL RESOURCES/CONSERVATION- AERIAL FERTILIZATION/SPRAYING",
      "value": "F001",
      "type": "psc_code"
   },
   {
      "label": "F002 - AERIAL SEEDING SERVICES",
      "value": "F002",
      "type": "psc_code"
   },
   {
      "label": "F002 - NATURAL RESOURCES/CONSERVATION- AERIAL SEEDING",
      "value": "F002",
      "type": "psc_code"
   },
   {
      "label": "F003 - FOREST-RANGE FIRE SUPPRESSION",
      "value": "F003",
      "type": "psc_code"
   },
   {
      "label": "F003 - NATURAL RESOURCES/CONSERVATION- FOREST-RANGE FIRE SUPPRESSION/PRESUPPRESSION",
      "value": "F003",
      "type": "psc_code"
   },
   {
      "label": "F004 - FOREST-RANGE FIRE REHABILITATION",
      "value": "F004",
      "type": "psc_code"
   },
   {
      "label": "F004 - NATURAL RESOURCES/CONSERVATION- FOREST/RANGE FIRE REHABILITATION (NON-CONSTRUCTION)",
      "value": "F004",
      "type": "psc_code"
   },
   {
      "label": "F005 - FOREST TREE PLANTING SERVICES",
      "value": "F005",
      "type": "psc_code"
   },
   {
      "label": "F005 - NATURAL RESOURCES/CONSERVATION- FOREST TREE PLANTING",
      "value": "F005",
      "type": "psc_code"
   },
   {
      "label": "F006 - LAND TREATMENT PRACTICES",
      "value": "F006",
      "type": "psc_code"
   },
   {
      "label": "F006 - NATURAL RESOURCES/CONSERVATION- LAND TREATMENT PRACTICES",
      "value": "F006",
      "type": "psc_code"
   },
   {
      "label": "F007 - NATURAL RESOURCES/CONSERVATION- RANGE SEEDING (GROUND EQUIPMENT)",
      "value": "F007",
      "type": "psc_code"
   },
   {
      "label": "F007 - RANGE SEEDING - GROUND EQ",
      "value": "F007",
      "type": "psc_code"
   },
   {
      "label": "F008 - RECREATION SITE MAINT/NON-CONSTR",
      "value": "F008",
      "type": "psc_code"
   },
   {
      "label": "F008 - NATURAL RESOURCES/CONSERVATION- RECREATION SITE MAINTENANCE (NON-CONSTRUCTION)",
      "value": "F008",
      "type": "psc_code"
   },
   {
      "label": "F009 - NATURAL RESOURCES/CONSERVATION- SEED COLLECTION/PRODUCTION",
      "value": "F009",
      "type": "psc_code"
   },
   {
      "label": "F009 - SEED COLLECTION/PRODUCTION SERVICES",
      "value": "F009",
      "type": "psc_code"
   },
   {
      "label": "F010 - SEEDLING PRODUCTION-TRANSPLANTING",
      "value": "F010",
      "type": "psc_code"
   },
   {
      "label": "F010 - NATURAL RESOURCES/CONSERVATION- SEEDLING PRODUCTION/TRANSPLANTING",
      "value": "F010",
      "type": "psc_code"
   },
   {
      "label": "F011 - SURFACE MINING RECLAM/NON-CONSTR",
      "value": "F011",
      "type": "psc_code"
   },
   {
      "label": "F011 - NATURAL RESOURCES/CONSERVATION- SURFACE MINING RECLAMATION (NON-CONSTRUCTION)",
      "value": "F011",
      "type": "psc_code"
   },
   {
      "label": "F012 - NATURAL RESOURCES/CONSERVATION- SURVEY LINE CLEARING",
      "value": "F012",
      "type": "psc_code"
   },
   {
      "label": "F012 - SURVEY LINE CLEARING SERVICES",
      "value": "F012",
      "type": "psc_code"
   },
   {
      "label": "F013 - NATURAL RESOURCES/CONSERVATION- TREE BREEDING",
      "value": "F013",
      "type": "psc_code"
   },
   {
      "label": "F013 - TREE BREEDING SERVICES",
      "value": "F013",
      "type": "psc_code"
   },
   {
      "label": "F014 - NATURAL RESOURCES/CONSERVATION- TREE THINNING",
      "value": "F014",
      "type": "psc_code"
   },
   {
      "label": "F014 - TREE THINNING SERVICES",
      "value": "F014",
      "type": "psc_code"
   },
   {
      "label": "F015 - WELL DRILLING/EXPLORATORY SERVICES",
      "value": "F015",
      "type": "psc_code"
   },
   {
      "label": "F015 - NATURAL RESOURCES/CONSERVATION- WELL DRILLING/EXPLORATORY",
      "value": "F015",
      "type": "psc_code"
   },
   {
      "label": "F016 - NATURAL RESOURCES/CONSERVATION- WILDHORSE/BURRO CONTROL",
      "value": "F016",
      "type": "psc_code"
   },
   {
      "label": "F016 - WILDHORSE/BURRO CONTROL SERVICES",
      "value": "F016",
      "type": "psc_code"
   },
   {
      "label": "F018 - NATURAL RESOURCES/CONSERVATION- OTHER FOREST/RANGE IMPROVEMENTS (NON-CONSTRUCTION)",
      "value": "F018",
      "type": "psc_code"
   },
   {
      "label": "F018 - OTHER RANGE-FOREST IMPROV/NON-CONST",
      "value": "F018",
      "type": "psc_code"
   },
   {
      "label": "F019 - NATURAL RESOURCES/CONSERVATION- OTHER WILDLIFE MANAGEMENT",
      "value": "F019",
      "type": "psc_code"
   },
   {
      "label": "F019 - OTHER WILDLIFE MANAGEMENT SERVICES",
      "value": "F019",
      "type": "psc_code"
   },
   {
      "label": "F020 - NATURAL RESOURCES/CONSERVATION- FISHERIES RESOURCES MANAGEMENT",
      "value": "F020",
      "type": "psc_code"
   },
   {
      "label": "F020 - FISHERIES RES MGMT",
      "value": "F020",
      "type": "psc_code"
   },
   {
      "label": "F021 - NATURAL RESOURCES/CONSERVATION- SITE PREPARATION",
      "value": "F021",
      "type": "psc_code"
   },
   {
      "label": "F021 - SITE PREPARATION",
      "value": "F021",
      "type": "psc_code"
   },
   {
      "label": "F022 - FISH HATCHERY SERVICES",
      "value": "F022",
      "type": "psc_code"
   },
   {
      "label": "F022 - NATURAL RESOURCES/CONSERVATION- FISH HATCHERY",
      "value": "F022",
      "type": "psc_code"
   },
   {
      "label": "F099 - OTHER NAT RES MGMT & CONSERV",
      "value": "F099",
      "type": "psc_code"
   },
   {
      "label": "F099 - NATURAL RESOURCES/CONSERVATION- OTHER",
      "value": "F099",
      "type": "psc_code"
   },
   {
      "label": "F1 - ENVIRONMENTAL SYSTEMS PROTECTION",
      "value": "F1",
      "type": "psc_code"
   },
   {
      "label": "F101 - ENVIRONMENTAL SYSTEMS PROTECTION- AIR QUALITY SUPPORT",
      "value": "F101",
      "type": "psc_code"
   },
   {
      "label": "F101 - AIR QUALITY SUPPORT SERVICES",
      "value": "F101",
      "type": "psc_code"
   },
   {
      "label": "F102 - IND INVEST SURV/TCH SUP",
      "value": "F102",
      "type": "psc_code"
   },
   {
      "label": "F103 - ENVIRONMENTAL SYSTEMS PROTECTION- WATER QUALITY SUPPORT",
      "value": "F103",
      "type": "psc_code"
   },
   {
      "label": "F103 - WATER QUALITY SUPPORT SERVICES",
      "value": "F103",
      "type": "psc_code"
   },
   {
      "label": "F104 - IND INVEST SURV/TCH SUP",
      "value": "F104",
      "type": "psc_code"
   },
   {
      "label": "F105 - PESTICIDES SUPPORT SERVICES",
      "value": "F105",
      "type": "psc_code"
   },
   {
      "label": "F105 - ENVIRONMENTAL SYSTEMS PROTECTION- PESTICIDES SUPPORT",
      "value": "F105",
      "type": "psc_code"
   },
   {
      "label": "F106 - TOXIC SUBSTANCES SUPPORT SERVICES",
      "value": "F106",
      "type": "psc_code"
   },
   {
      "label": "F107 - ENVIRONMENTAL SYSTEMS PROTECTION- TOXIC AND HAZARDOUS SUBSTANCE ANALYSIS",
      "value": "F107",
      "type": "psc_code"
   },
   {
      "label": "F107 - HAZARDOUS SUBSTANCE ANALYSIS",
      "value": "F107",
      "type": "psc_code"
   },
   {
      "label": "F108 - ENVIRONMENTAL SYSTEMS PROTECTION- ENVIRONMENTAL REMEDIATION",
      "value": "F108",
      "type": "psc_code"
   },
   {
      "label": "F108 - HARZ REMV/CLEAN-UP/DISP/OP",
      "value": "F108",
      "type": "psc_code"
   },
   {
      "label": "F109 - ENVIRONMENTAL SYSTEMS PROTECTION- LEAKING UNDERGROUND STORAGE TANK SUPPORT",
      "value": "F109",
      "type": "psc_code"
   },
   {
      "label": "F109 - LEAK UNDERGRND STORE TANK SUP",
      "value": "F109",
      "type": "psc_code"
   },
   {
      "label": "F110 - ENVIRON SYS PROTECT- DEVELOPMENT OF ENVIRON IMPACT STMTS/ASSESSMENTS, TECH ANALYSIS/ENVIRON AUDITS",
      "value": "F110",
      "type": "psc_code"
   },
   {
      "label": "F110 - DEV ENVIR IMPACT STMT/ASSESS",
      "value": "F110",
      "type": "psc_code"
   },
   {
      "label": "F111 - MULT POL IND INVST SURV/TECH SUP",
      "value": "F111",
      "type": "psc_code"
   },
   {
      "label": "F111 - ENVIRONMENTAL SYSTEMS PROTECTION- MULTIPLE POLLUTANT SUPPORT",
      "value": "F111",
      "type": "psc_code"
   },
   {
      "label": "F112 - OIL SPILL RESPONSE",
      "value": "F112",
      "type": "psc_code"
   },
   {
      "label": "F112 - ENVIRONMENTAL SYSTEMS PROTECTION- OIL SPILL RESPONSE",
      "value": "F112",
      "type": "psc_code"
   },
   {
      "label": "F113 - ENVIRONMENTAL SYSTEMS PROTECTION- WETLANDS CONSERVATION AND SUPPORT",
      "value": "F113",
      "type": "psc_code"
   },
   {
      "label": "F114 - ENVIRONMENTAL SYSTEMS PROTECTION- ENVIRONMENTAL LICENSING AND PERMITTING",
      "value": "F114",
      "type": "psc_code"
   },
   {
      "label": "F115 - ENVIRONMENTAL SYSTEMS PROTECTION- ENVIRONMENTAL CONSULTING AND LEGAL SUPPORT",
      "value": "F115",
      "type": "psc_code"
   },
   {
      "label": "",
      "value": "F199",
      "type": "psc_code"
   },
   {
      "label": "",
      "value": "F201",
      "type": "psc_code"
   },
   {
      "label": "",
      "value": "F202",
      "type": "psc_code"
   },
   {
      "label": "",
      "value": "F203",
      "type": "psc_code"
   },
   {
      "label": "",
      "value": "F299",
      "type": "psc_code"
   },
   {
      "label": "",
      "value": "F301",
      "type": "psc_code"
   },
   {
      "label": "",
      "value": "F401",
      "type": "psc_code"
   },
   {
      "label": "",
      "value": "F501",
      "type": "psc_code"
   },
   {
      "label": "",
      "value": "F502",
      "type": "psc_code"
   },
   {
      "label": "",
      "value": "F503",
      "type": "psc_code"
   },
   {
      "label": "",
      "value": "F504",
      "type": "psc_code"
   },
   {
      "label": "",
      "value": "F599",
      "type": "psc_code"
   },
   {
      "label": "F9 - NATURAL RESOURCES - OTHER SVCS",
      "value": "F9",
      "type": "psc_code"
   },
   {
      "label": "",
      "value": "F901",
      "type": "psc_code"
   },
   {
      "label": "",
      "value": "F902",
      "type": "psc_code"
   },
   {
      "label": "",
      "value": "F903",
      "type": "psc_code"
   },
   {
      "label": "F999 - OTHER ENVIRONMENTAL SERVICES",
      "value": "F999",
      "type": "psc_code"
   },
   {
      "label": "F999 - OTHER ENVIR SVC/STUD/SUP",
      "value": "F999",
      "type": "psc_code"
   },
   {
      "label": "G - SOCIAL SERVICES",
      "value": "G",
      "type": "psc_code"
   },
   {
      "label": "G0 - SOCIAL SERVICES",
      "value": "G0",
      "type": "psc_code"
   },
   {
      "label": "G001 - CARE OF REMAINS AND/OR FUNERAL SVCS",
      "value": "G001",
      "type": "psc_code"
   },
   {
      "label": "G001 - SOCIAL- CARE OF REMAINS AND/OR FUNERAL",
      "value": "G001",
      "type": "psc_code"
   },
   {
      "label": "G002 - SOCIAL- CHAPLAIN",
      "value": "G002",
      "type": "psc_code"
   },
   {
      "label": "G002 - CHAPLAIN SERVICES",
      "value": "G002",
      "type": "psc_code"
   },
   {
      "label": "G003 - RECREATIONAL SERVICES",
      "value": "G003",
      "type": "psc_code"
   },
   {
      "label": "G003 - SOCIAL- RECREATIONAL",
      "value": "G003",
      "type": "psc_code"
   },
   {
      "label": "G004 - SOCIAL- SOCIAL REHABILITATION",
      "value": "G004",
      "type": "psc_code"
   },
   {
      "label": "G004 - SOCIAL REHABILITATION SERVICES",
      "value": "G004",
      "type": "psc_code"
   },
   {
      "label": "G005 - SOCIAL- GERIATRIC",
      "value": "G005",
      "type": "psc_code"
   },
   {
      "label": "G005 - GERIATRIC SERVICES",
      "value": "G005",
      "type": "psc_code"
   },
   {
      "label": "G006 - GOVERNMENT LIFE INSURANCE PROGRAMS",
      "value": "G006",
      "type": "psc_code"
   },
   {
      "label": "G006 - SOCIAL- GOVERNMENT LIFE INSURANCE PROGRAMS",
      "value": "G006",
      "type": "psc_code"
   },
   {
      "label": "G007 - GOVT HEALTH INS PROGRAMS",
      "value": "G007",
      "type": "psc_code"
   },
   {
      "label": "G007 - SOCIAL- GOVERNMENT HEALTH INSURANCE PROGRAMS",
      "value": "G007",
      "type": "psc_code"
   },
   {
      "label": "G008 - OTHER GOVERNMENT INSURANCE PROGRAMS",
      "value": "G008",
      "type": "psc_code"
   },
   {
      "label": "G008 - SOCIAL- GOVERNMENT INSURANCE PROGRAMS: OTHER",
      "value": "G008",
      "type": "psc_code"
   },
   {
      "label": "G009 - NON-GOVERNMENT INSURANCE PROGRAMS",
      "value": "G009",
      "type": "psc_code"
   },
   {
      "label": "G009 - SOCIAL- NON-GOVERNMENT INSURANCE PROGRAMS",
      "value": "G009",
      "type": "psc_code"
   },
   {
      "label": "G010 - DIR AID TRIBAL GOVT-DI (PL93-638)",
      "value": "G010",
      "type": "psc_code"
   },
   {
      "label": "G010 - SOCIAL- DIRECT AID TO TRIBAL GOVERNMENTS (PL 93-638)",
      "value": "G010",
      "type": "psc_code"
   },
   {
      "label": "G099 - SOCIAL- OTHER",
      "value": "G099",
      "type": "psc_code"
   },
   {
      "label": "G099 - OTHER SOCIAL SERVICES",
      "value": "G099",
      "type": "psc_code"
   },
   {
      "label": "H - QUALITY CONTROL, TEST, INSPECTION",
      "value": "H",
      "type": "psc_code"
   },
   {
      "label": "H1 - QUALITY CONTROL SERVICES",
      "value": "H1",
      "type": "psc_code"
   },
   {
      "label": "H110 - QUALITY CONTROL SERVICES: WEAPONS",
      "value": "H110",
      "type": "psc_code"
   },
   {
      "label": "H110 - QUALITY CONTROL- WEAPONS",
      "value": "H110",
      "type": "psc_code"
   },
   {
      "label": "H111 - QUALITY CONTROL- NUCLEAR ORDNANCE",
      "value": "H111",
      "type": "psc_code"
   },
   {
      "label": "H111 - QUALITY CONT SV/NUCLEAR ORDNANCE",
      "value": "H111",
      "type": "psc_code"
   },
   {
      "label": "H112 - QUALITY CONTROL- FIRE CONTROL EQUIPMENT",
      "value": "H112",
      "type": "psc_code"
   },
   {
      "label": "H112 - QUALITY CONT SV/FIRE CONTROL EQ",
      "value": "H112",
      "type": "psc_code"
   },
   {
      "label": "H113 - QUALITY CONTROL- AMMUNITION AND EXPLOSIVES",
      "value": "H113",
      "type": "psc_code"
   },
   {
      "label": "H113 - QUALITY CONT SV/AMMO & EXPLOSIVES",
      "value": "H113",
      "type": "psc_code"
   },
   {
      "label": "H114 - QUALITY CONTROL- GUIDED MISSILES",
      "value": "H114",
      "type": "psc_code"
   },
   {
      "label": "H114 - QUALITY CONT SV/GUIDED MISSILES",
      "value": "H114",
      "type": "psc_code"
   },
   {
      "label": "H115 - QUALITY CONT SV/AIRCRAFT",
      "value": "H115",
      "type": "psc_code"
   },
   {
      "label": "H115 - QUALITY CONTROL- AIRCRAFT AND AIRFRAME STRUCTURAL COMPONENTS",
      "value": "H115",
      "type": "psc_code"
   },
   {
      "label": "H116 - QUALITY CONT SV/AIRCRAFT COMPONENTS",
      "value": "H116",
      "type": "psc_code"
   },
   {
      "label": "H116 - QUALITY CONTROL- AIRCRAFT COMPONENTS AND ACCESSORIES",
      "value": "H116",
      "type": "psc_code"
   },
   {
      "label": "H117 - QUALITY CONTROL- AIRCRAFT LAUNCHING, LANDING, AND GROUND HANDLING EQUIPMENT",
      "value": "H117",
      "type": "psc_code"
   },
   {
      "label": "H117 - QUALITY CONT SV/AIRCRAFT GROUND EQ",
      "value": "H117",
      "type": "psc_code"
   },
   {
      "label": "H118 - QUALITY CONTROL- SPACE VEHICLES",
      "value": "H118",
      "type": "psc_code"
   },
   {
      "label": "H118 - QUALITY CONT SV/SPACE VEHICLES",
      "value": "H118",
      "type": "psc_code"
   },
   {
      "label": "H119 - QUALITY CONT SV/SHIPS-CRAFT-DOCKS",
      "value": "H119",
      "type": "psc_code"
   },
   {
      "label": "H119 - QUALITY CONTROL- SHIPS, SMALL CRAFT, PONTOONS, AND FLOATING DOCKS",
      "value": "H119",
      "type": "psc_code"
   },
   {
      "label": "H120 - QUALITY CONTROL- SHIP AND MARINE EQUIPMENT",
      "value": "H120",
      "type": "psc_code"
   },
   {
      "label": "H120 - QUALITY CONT SV/SHIP & MARINE EQ",
      "value": "H120",
      "type": "psc_code"
   },
   {
      "label": "H122 - QUALITY CONTROL- RAILWAY EQUIPMENT",
      "value": "H122",
      "type": "psc_code"
   },
   {
      "label": "H122 - QUALITY CONT SV/RAILWAY EQ",
      "value": "H122",
      "type": "psc_code"
   },
   {
      "label": "H123 - QUALITY CONT SV/VEHICLE-TRAILER-CYC",
      "value": "H123",
      "type": "psc_code"
   },
   {
      "label": "H123 - QUALITY CONTROL- GROUND EFFECT VEHICLES, MOTOR VEHICLES, TRAILERS, AND CYCLES",
      "value": "H123",
      "type": "psc_code"
   },
   {
      "label": "H124 - QUALITY CONTROL SERVICES: TRACTORS",
      "value": "H124",
      "type": "psc_code"
   },
   {
      "label": "H124 - QUALITY CONTROL- TRACTORS",
      "value": "H124",
      "type": "psc_code"
   },
   {
      "label": "H125 - QUALITY CONTROL- VEHICULAR EQUIPMENT COMPONENTS",
      "value": "H125",
      "type": "psc_code"
   },
   {
      "label": "H125 - QUALITY CONT SV/VEHICULAR EQ",
      "value": "H125",
      "type": "psc_code"
   },
   {
      "label": "H126 - QUALITY CONT SV/TIRES & TUBES",
      "value": "H126",
      "type": "psc_code"
   },
   {
      "label": "H126 - QUALITY CONTROL- TIRES AND TUBES",
      "value": "H126",
      "type": "psc_code"
   },
   {
      "label": "H128 - QUALITY CONT SV/ENGINES & TURBINES",
      "value": "H128",
      "type": "psc_code"
   },
   {
      "label": "H128 - QUALITY CONTROL- ENGINES, TURBINES, AND COMPONENTS",
      "value": "H128",
      "type": "psc_code"
   },
   {
      "label": "H129 - QUALITY CONT SV/ENGINE ACCESSORIES",
      "value": "H129",
      "type": "psc_code"
   },
   {
      "label": "H129 - QUALITY CONTROL- ENGINE ACCESSORIES",
      "value": "H129",
      "type": "psc_code"
   },
   {
      "label": "H130 - QUALITY CONTROL- MECHANICAL POWER TRANSMISSION EQUIPMENT",
      "value": "H130",
      "type": "psc_code"
   },
   {
      "label": "H130 - QUALITY CONT SV/MECH POWER TRANS EQ",
      "value": "H130",
      "type": "psc_code"
   },
   {
      "label": "H131 - QUALITY CONTROL SERVICES: BEARINGS",
      "value": "H131",
      "type": "psc_code"
   },
   {
      "label": "H131 - QUALITY CONTROL- BEARINGS",
      "value": "H131",
      "type": "psc_code"
   },
   {
      "label": "H132 - QUALITY CONT SV/WOODWORKING MACHINE",
      "value": "H132",
      "type": "psc_code"
   },
   {
      "label": "H132 - QUALITY CONTROL- WOODWORKING MACHINERY AND EQUIPMENT",
      "value": "H132",
      "type": "psc_code"
   },
   {
      "label": "H134 - QUALITY CONTROL- METALWORKING MACHINERY",
      "value": "H134",
      "type": "psc_code"
   },
   {
      "label": "H134 - QUALITY CONT SV/METALWORK MACHINE",
      "value": "H134",
      "type": "psc_code"
   },
   {
      "label": "H135 - QUALITY CONTROL- SERVICE AND TRADE EQUIPMENT",
      "value": "H135",
      "type": "psc_code"
   },
   {
      "label": "H135 - QUALITY CONT SV/SERVICE & TRADE EQ",
      "value": "H135",
      "type": "psc_code"
   },
   {
      "label": "H136 - QUALITY CONTROL- SPECIAL INDUSTRY MACHINERY",
      "value": "H136",
      "type": "psc_code"
   },
   {
      "label": "H136 - QUALITY CONT SV/SP INDUSTRY MACHINE",
      "value": "H136",
      "type": "psc_code"
   },
   {
      "label": "H137 - QUALITY CONTROL- AGRICULTURAL MACHINERY AND EQUIPMENT",
      "value": "H137",
      "type": "psc_code"
   },
   {
      "label": "H137 - QUALITY CONT SV/AGRICULTURE MACHINE",
      "value": "H137",
      "type": "psc_code"
   },
   {
      "label": "H138 - QUALITY CONT SV/CONTRUCT EQ",
      "value": "H138",
      "type": "psc_code"
   },
   {
      "label": "H138 - QUALITY CONTROL- CONSTRUCTION, MINING, EXCAVATING, AND HIGHWAY MAINTENANCE EQUIPMENT",
      "value": "H138",
      "type": "psc_code"
   },
   {
      "label": "H139 - QUALITY CONTROL- MATERIALS HANDLING EQUIPMENT",
      "value": "H139",
      "type": "psc_code"
   },
   {
      "label": "H139 - QUALITY CONT SV/MATERIAL HANDLIN EQ",
      "value": "H139",
      "type": "psc_code"
   },
   {
      "label": "H140 - QUALITY CONT SV/ROPE-CABLE-CHAIN",
      "value": "H140",
      "type": "psc_code"
   },
   {
      "label": "H140 - QUALITY CONTROL- ROPE, CABLE, CHAIN, AND FITTINGS",
      "value": "H140",
      "type": "psc_code"
   },
   {
      "label": "H141 - QUALITY CONT SV/REFRIGERATION-AC EQ",
      "value": "H141",
      "type": "psc_code"
   },
   {
      "label": "H141 - QUALITY CONTROL- REFRIGERATION, AIR CONDITIONING, AND AIR CIRCULATING EQUIPMENT",
      "value": "H141",
      "type": "psc_code"
   },
   {
      "label": "H142 - QUALITY CONTROL- FIRE FIGHTING/RESCUE/SAFETY EQUIPMENT; ENVIRON PROTECT EQUIPMENT/MATLS",
      "value": "H142",
      "type": "psc_code"
   },
   {
      "label": "H142 - QUALITY CONT SV/FIRE-RESCUE-SAFE EQ",
      "value": "H142",
      "type": "psc_code"
   },
   {
      "label": "H143 - QUALITY CONT SV/PUMPS & COMPRESSORS",
      "value": "H143",
      "type": "psc_code"
   },
   {
      "label": "H143 - QUALITY CONTROL- PUMPS AND COMPRESSORS",
      "value": "H143",
      "type": "psc_code"
   },
   {
      "label": "H144 - QUALITY CONTROL- FURNACE, STEAM PLANT, AND DRYING EQUIPMENT; NUCLEAR REACTORS",
      "value": "H144",
      "type": "psc_code"
   },
   {
      "label": "H144 - QUALITY CONT SV/FURNACE-NUC REACTOR",
      "value": "H144",
      "type": "psc_code"
   },
   {
      "label": "H145 - QUALITY CONTROL- PLUMBING, HEATING, AND WASTE DISPOSAL EQUIPMENT",
      "value": "H145",
      "type": "psc_code"
   },
   {
      "label": "H145 - QUALITY CONT SV/PLUMBING-HEATING EQ",
      "value": "H145",
      "type": "psc_code"
   },
   {
      "label": "H146 - QUALITY CONT SV/WATER PURIFYING EQ",
      "value": "H146",
      "type": "psc_code"
   },
   {
      "label": "H146 - QUALITY CONTROL- WATER PURIFICATION AND SEWAGE TREATMENT EQUIPMENT",
      "value": "H146",
      "type": "psc_code"
   },
   {
      "label": "H147 - QUALITY CONT SV/PIPE-TUBING-HOSE",
      "value": "H147",
      "type": "psc_code"
   },
   {
      "label": "H147 - QUALITY CONTROL- PIPE, TUBING, HOSE, AND FITTINGS",
      "value": "H147",
      "type": "psc_code"
   },
   {
      "label": "H148 - QUALITY CONTROL- VALVES",
      "value": "H148",
      "type": "psc_code"
   },
   {
      "label": "H148 - QUALITY CONTROL SERVICES: VALVES",
      "value": "H148",
      "type": "psc_code"
   },
   {
      "label": "H149 - QUALITY CONTROL- MAINTENANCE AND REPAIR SHOP EQUIPMENT",
      "value": "H149",
      "type": "psc_code"
   },
   {
      "label": "H149 - QUALITY CONT SV/MAINT REPAIR SHOP E",
      "value": "H149",
      "type": "psc_code"
   },
   {
      "label": "H151 - QUALITY CONTROL- HAND TOOLS",
      "value": "H151",
      "type": "psc_code"
   },
   {
      "label": "H151 - QUALITY CONT SV/HAND TOOLS",
      "value": "H151",
      "type": "psc_code"
   },
   {
      "label": "H152 - QUALITY CONT SV/MEASURING TOOLS",
      "value": "H152",
      "type": "psc_code"
   },
   {
      "label": "H152 - QUALITY CONTROL- MEASURING TOOLS",
      "value": "H152",
      "type": "psc_code"
   },
   {
      "label": "H153 - QUALITY CONT SV/HARDWARE & ABRASIVE",
      "value": "H153",
      "type": "psc_code"
   },
   {
      "label": "H153 - QUALITY CONTROL- HARDWARE AND ABRASIVES",
      "value": "H153",
      "type": "psc_code"
   },
   {
      "label": "H154 - QUALITY CONT SV/PREFAB STRUCTURES",
      "value": "H154",
      "type": "psc_code"
   },
   {
      "label": "H154 - QUALITY CONTROL- PREFABRICATED STRUCTURES AND SCAFFOLDING",
      "value": "H154",
      "type": "psc_code"
   },
   {
      "label": "H155 - QUALITY CONT SV/LUMBER & MILLWORK",
      "value": "H155",
      "type": "psc_code"
   },
   {
      "label": "H155 - QUALITY CONTROL- LUMBER, MILLWORK, PLYWOOD, AND VENEER",
      "value": "H155",
      "type": "psc_code"
   },
   {
      "label": "H156 - QUALITY CONTROL- CONSTRUCTION AND BUILDING MATERIALS",
      "value": "H156",
      "type": "psc_code"
   },
   {
      "label": "H156 - QUALITY CONT SV/CONTRUCT MATERIAL",
      "value": "H156",
      "type": "psc_code"
   },
   {
      "label": "H158 - QUALITY CONTROL- COMMUNICATION, DETECTION, AND COHERENT RADIATION EQUIPMENT",
      "value": "H158",
      "type": "psc_code"
   },
   {
      "label": "H158 - QUALITY CONT SV/COMMUNICATION EQ",
      "value": "H158",
      "type": "psc_code"
   },
   {
      "label": "H159 - QUALITY CONT SV/ELECT-ELCT EQ",
      "value": "H159",
      "type": "psc_code"
   },
   {
      "label": "H159 - QUALITY CONTROL- ELECTRICAL AND ELECTRONIC EQUIPMENT COMPONENTS",
      "value": "H159",
      "type": "psc_code"
   },
   {
      "label": "H160 - QUALITY CONTROL- FIBER OPTICS MATERIALS, COMPONENTS, ASSEMBLIES, AND ACCESSORIES",
      "value": "H160",
      "type": "psc_code"
   },
   {
      "label": "H160 - QUALITY CONT SV/FIBER OPTICS MATER",
      "value": "H160",
      "type": "psc_code"
   },
   {
      "label": "H161 - QUALITY CONT SV/POWER DISTRIBUTE EQ",
      "value": "H161",
      "type": "psc_code"
   },
   {
      "label": "H161 - QUALITY CONTROL- ELECTRIC WIRE AND POWER DISTRIBUTION EQUIPMENT",
      "value": "H161",
      "type": "psc_code"
   },
   {
      "label": "H162 - QUALITY CONT SV/LIGHTING FIXTURES",
      "value": "H162",
      "type": "psc_code"
   },
   {
      "label": "H162 - QUALITY CONTROL- LIGHTING FIXTURES AND LAMPS",
      "value": "H162",
      "type": "psc_code"
   },
   {
      "label": "H163 - QUALITY CONTROL- ALARM, SIGNAL, AND SECURITY DETECTION SYSTEMS",
      "value": "H163",
      "type": "psc_code"
   },
   {
      "label": "H163 - QUALITY CONT SV/ALARM & SIGNAL SYS",
      "value": "H163",
      "type": "psc_code"
   },
   {
      "label": "H165 - QUALITY CONTROL- MEDICAL, DENTAL, AND VETERINARY EQUIPMENT AND SUPPLIES",
      "value": "H165",
      "type": "psc_code"
   },
   {
      "label": "H165 - QUALITY CONT SV/MEDICAL-DENTAL-VET",
      "value": "H165",
      "type": "psc_code"
   },
   {
      "label": "H166 - QUALITY CONTROL- INSTRUMENTS AND LABORATORY EQUIPMENT",
      "value": "H166",
      "type": "psc_code"
   },
   {
      "label": "H166 - QUALITY CONT SV/INSTRUMENT & LAB EQ",
      "value": "H166",
      "type": "psc_code"
   },
   {
      "label": "H167 - QUALITY CONTROL- PHOTOGRAPHIC EQUIPMENT",
      "value": "H167",
      "type": "psc_code"
   },
   {
      "label": "H167 - QUALITY CONT SV/PHOTOGRAPHIC EQ",
      "value": "H167",
      "type": "psc_code"
   },
   {
      "label": "H168 - QUALITY CONT SV/CHEMICAL PRODUCTS",
      "value": "H168",
      "type": "psc_code"
   },
   {
      "label": "H168 - QUALITY CONTROL- CHEMICALS AND CHEMICAL PRODUCTS",
      "value": "H168",
      "type": "psc_code"
   },
   {
      "label": "H169 - QUALITY CONTROL- TRAINING AIDS AND DEVICES",
      "value": "H169",
      "type": "psc_code"
   },
   {
      "label": "H169 - QUALITY CONT SV/TRAINING AID-DEVICE",
      "value": "H169",
      "type": "psc_code"
   },
   {
      "label": "H170 - QUALITY CONT SV/ADP EQ & SUPPLIES",
      "value": "H170",
      "type": "psc_code"
   },
   {
      "label": "H170 - QUALITY CONTROL- ADP EQUIPMENT/SOFTWARE/SUPPLIES/SUPPORT EQUIPMENT",
      "value": "H170",
      "type": "psc_code"
   },
   {
      "label": "H171 - QUALITY CONTROL SERVICES: FURNITURE",
      "value": "H171",
      "type": "psc_code"
   },
   {
      "label": "H171 - QUALITY CONTROL- FURNITURE",
      "value": "H171",
      "type": "psc_code"
   },
   {
      "label": "H172 - QUALITY CONT SV/HOUSEHOLD FURNISH",
      "value": "H172",
      "type": "psc_code"
   },
   {
      "label": "H172 - QUALITY CONTROL- HOUSEHOLD AND COMMERCIAL FURNISHINGS AND APPLIANCES",
      "value": "H172",
      "type": "psc_code"
   },
   {
      "label": "H173 - QUALITY CONT SV/FOOD PREP-SERVE EQ",
      "value": "H173",
      "type": "psc_code"
   },
   {
      "label": "H173 - QUALITY CONTROL- FOOD PREPARATION AND SERVING EQUIPMENT",
      "value": "H173",
      "type": "psc_code"
   },
   {
      "label": "H174 - QUALITY CONT SV/OFFICE MACHINES",
      "value": "H174",
      "type": "psc_code"
   },
   {
      "label": "H174 - QUALITY CONTROL- OFFICE MACHINES, TEXT PROCESSING SYSTEMS, AND VISIBLE RECORD EQUIPMENT",
      "value": "H174",
      "type": "psc_code"
   },
   {
      "label": "H175 - QUALITY CONT SV/OFFICE SUPPLIES",
      "value": "H175",
      "type": "psc_code"
   },
   {
      "label": "H175 - QUALITY CONTROL- OFFICE SUPPLIES AND DEVICES",
      "value": "H175",
      "type": "psc_code"
   },
   {
      "label": "H176 - QUALITY CONTROL- BOOKS, MAPS, AND OTHER PUBLICATIONS",
      "value": "H176",
      "type": "psc_code"
   },
   {
      "label": "H176 - QUALITY CONT SV/BOOKS-MAPS-PUBS",
      "value": "H176",
      "type": "psc_code"
   },
   {
      "label": "H177 - QUALITY CONT SV/MUSIC INST-RADIO-TV",
      "value": "H177",
      "type": "psc_code"
   },
   {
      "label": "H177 - QUALITY CONTROL- MUSICAL INST/PHONOGRAPH/HOME RADIO",
      "value": "H177",
      "type": "psc_code"
   },
   {
      "label": "H178 - QUALITY CONTROL- RECREATIONAL AND ATHLETIC EQUIPMENT",
      "value": "H178",
      "type": "psc_code"
   },
   {
      "label": "H178 - QUALITY CONT SV/RECREATIONAL EQ",
      "value": "H178",
      "type": "psc_code"
   },
   {
      "label": "H179 - QUALITY CONTROL- CLEANING EQUIPMENT AND SUPPLIES",
      "value": "H179",
      "type": "psc_code"
   },
   {
      "label": "H179 - QUALITY CONT SV/CLEANING EQ",
      "value": "H179",
      "type": "psc_code"
   },
   {
      "label": "H180 - QUALITY CONTROL- BRUSHES, PAINTS, SEALERS, AND ADHESIVES",
      "value": "H180",
      "type": "psc_code"
   },
   {
      "label": "H180 - QUALITY CONT SV/BRUSH-PAINT-SEALER",
      "value": "H180",
      "type": "psc_code"
   },
   {
      "label": "H181 - QUALITY CONT SV/CONTAINER-PACKAGING",
      "value": "H181",
      "type": "psc_code"
   },
   {
      "label": "H181 - QUALITY CONTROL- CONTAINERS, PACKAGING, AND PACKING SUPPLIES",
      "value": "H181",
      "type": "psc_code"
   },
   {
      "label": "H183 - QUALITY CONT SV/TEXTILE-LEATHER-FUR",
      "value": "H183",
      "type": "psc_code"
   },
   {
      "label": "H183 - QUALITY CONTROL- TEXTILES, LEATHER, FURS, APPAREL AND SHOE FINDINGS, TENTS AND FLAGS",
      "value": "H183",
      "type": "psc_code"
   },
   {
      "label": "H184 - QUALITY CONT SV/CLOTH - INDIVID EQ",
      "value": "H184",
      "type": "psc_code"
   },
   {
      "label": "H184 - QUALITY CONTROL- CLOTHING, INDIVIDUAL EQUIPMENT, AND INSIGNIA",
      "value": "H184",
      "type": "psc_code"
   },
   {
      "label": "H185 - QUALITY CONTROL- TOILETRIES",
      "value": "H185",
      "type": "psc_code"
   },
   {
      "label": "H185 - QUALITY CONT SV/TOILETRIES",
      "value": "H185",
      "type": "psc_code"
   },
   {
      "label": "H187 - QUALITY CONT SV/AGRICULTURAL SUPPLY",
      "value": "H187",
      "type": "psc_code"
   },
   {
      "label": "H187 - QUALITY CONTROL- AGRICULTURAL SUPPLIES",
      "value": "H187",
      "type": "psc_code"
   },
   {
      "label": "H188 - QUALITY CONT SV/LIVE ANIMALS",
      "value": "H188",
      "type": "psc_code"
   },
   {
      "label": "H188 - QUALITY CONTROL- LIVE ANIMALS",
      "value": "H188",
      "type": "psc_code"
   },
   {
      "label": "H189 - QUALITY CONTROL- SUBSISTENCE",
      "value": "H189",
      "type": "psc_code"
   },
   {
      "label": "H189 - QUALITY CONT SV/SUBSISTENCE",
      "value": "H189",
      "type": "psc_code"
   },
   {
      "label": "H191 - QUALITY CONTROL- FUELS, LUBRICANTS, OILS, AND WAXES",
      "value": "H191",
      "type": "psc_code"
   },
   {
      "label": "H191 - QUALITY CONT SV/FUEL-LUBRICANT-OIL",
      "value": "H191",
      "type": "psc_code"
   },
   {
      "label": "H193 - QUALITY CONT SV/NONMETALIC FAB MAT",
      "value": "H193",
      "type": "psc_code"
   },
   {
      "label": "H193 - QUALITY CONTROL- NONMETALLIC FABRICATED MATERIALS",
      "value": "H193",
      "type": "psc_code"
   },
   {
      "label": "H194 - QUALITY CONTROL- NONMETALLIC CRUDE MATERIALS",
      "value": "H194",
      "type": "psc_code"
   },
   {
      "label": "H194 - QUALITY CONT SV/NONMETAL CRUDE MAT",
      "value": "H194",
      "type": "psc_code"
   },
   {
      "label": "H195 - QUALITY CONT SV/METAL BARS & SHEETS",
      "value": "H195",
      "type": "psc_code"
   },
   {
      "label": "H195 - QUALITY CONTROL- METAL BARS, SHEETS, AND SHAPES",
      "value": "H195",
      "type": "psc_code"
   },
   {
      "label": "H196 - QUALITY CONT SV/ORES & MINERALS",
      "value": "H196",
      "type": "psc_code"
   },
   {
      "label": "H196 - QUALITY CONTROL- ORES, MINERALS, AND THEIR PRIMARY PRODUCTS",
      "value": "H196",
      "type": "psc_code"
   },
   {
      "label": "H199 - QUALITY CONT SV/MISC EQ",
      "value": "H199",
      "type": "psc_code"
   },
   {
      "label": "H199 - QUALITY CONTROL- MISCELLANEOUS",
      "value": "H199",
      "type": "psc_code"
   },
   {
      "label": "H2 - EQUIPMENT AND MATERIALS TESTING",
      "value": "H2",
      "type": "psc_code"
   },
   {
      "label": "H210 - EQ TEST SVCS/WEAPONS",
      "value": "H210",
      "type": "psc_code"
   },
   {
      "label": "H210 - EQUIPMENT AND MATERIALS TESTING- WEAPONS",
      "value": "H210",
      "type": "psc_code"
   },
   {
      "label": "H211 - EQUIPMENT AND MATERIALS TESTING- NUCLEAR ORDNANCE",
      "value": "H211",
      "type": "psc_code"
   },
   {
      "label": "H211 - EQ TEST SVCS/NUCLEAR ORDNANCE",
      "value": "H211",
      "type": "psc_code"
   },
   {
      "label": "H212 - EQ TEST SVCS/FIRE CONT EQUIP",
      "value": "H212",
      "type": "psc_code"
   },
   {
      "label": "H212 - EQUIPMENT AND MATERIALS TESTING- FIRE CONTROL EQUIPMENT",
      "value": "H212",
      "type": "psc_code"
   },
   {
      "label": "H213 - EQ TEST SVCS/AMMO & EXPLOSIVES",
      "value": "H213",
      "type": "psc_code"
   },
   {
      "label": "H213 - EQUIPMENT AND MATERIALS TESTING- AMMUNITION AND EXPLOSIVES",
      "value": "H213",
      "type": "psc_code"
   },
   {
      "label": "H214 - EQUIPMENT AND MATERIALS TESTING- GUIDED MISSILES",
      "value": "H214",
      "type": "psc_code"
   },
   {
      "label": "H214 - EQ TEST SVCS/GUIDED MISSILES",
      "value": "H214",
      "type": "psc_code"
   },
   {
      "label": "H215 - EQUIPMENT AND MATERIALS TESTING- AIRCRAFT AND AIRFRAME STRUCTURAL COMPONENTS",
      "value": "H215",
      "type": "psc_code"
   },
   {
      "label": "H215 - EQ TEST SVCS/AIRCRAFT",
      "value": "H215",
      "type": "psc_code"
   },
   {
      "label": "H216 - EQ TEST SVCS/AIRCRAFT COMPONENTS",
      "value": "H216",
      "type": "psc_code"
   },
   {
      "label": "H216 - EQUIPMENT AND MATERIALS TESTING- AIRCRAFT COMPONENTS AND ACCESSORIES",
      "value": "H216",
      "type": "psc_code"
   },
   {
      "label": "H217 - EQ TEST SVCS/AIRCRAFT GROUND EQUIP",
      "value": "H217",
      "type": "psc_code"
   },
   {
      "label": "H217 - EQUIPMENT AND MATERIALS TESTING- AIRCRAFT LAUNCHING, LANDING, AND GROUND HANDLING EQUIPMENT",
      "value": "H217",
      "type": "psc_code"
   },
   {
      "label": "H218 - EQ TEST SVCS/SPACE VEHICLES",
      "value": "H218",
      "type": "psc_code"
   },
   {
      "label": "H218 - EQUIPMENT AND MATERIALS TESTING- SPACE VEHICLES",
      "value": "H218",
      "type": "psc_code"
   },
   {
      "label": "H219 - EQUIPMENT AND MATERIALS TESTING- SHIPS, SMALL CRAFT, PONTOONS, AND FLOATING DOCKS",
      "value": "H219",
      "type": "psc_code"
   },
   {
      "label": "H219 - EQ TEST SVCS/SHIPS-SML CRAFT-DOCKS",
      "value": "H219",
      "type": "psc_code"
   },
   {
      "label": "H220 - EQUIPMENT AND MATERIALS TESTING- SHIP AND MARINE EQUIPMENT",
      "value": "H220",
      "type": "psc_code"
   },
   {
      "label": "H220 - EQ TEST SVCS/SHIP & MARINE EQUIP",
      "value": "H220",
      "type": "psc_code"
   },
   {
      "label": "H222 - EQUIPMENT AND MATERIALS TESTING- RAILWAY EQUIPMENT",
      "value": "H222",
      "type": "psc_code"
   },
   {
      "label": "H222 - EQ TEST SVCS/RAILWAY EQUIP",
      "value": "H222",
      "type": "psc_code"
   },
   {
      "label": "H223 - EQ TEST SVCS/VEHICLES-TRAILERS-CYC",
      "value": "H223",
      "type": "psc_code"
   },
   {
      "label": "H223 - EQUIPMENT AND MATERIALS TESTING- GROUND EFFECT VEHICLES, MOTOR VEHICLES, TRAILERS, AND CYCLES",
      "value": "H223",
      "type": "psc_code"
   },
   {
      "label": "H224 - EQUIPMENT AND MATERIALS TESTING- TRACTORS",
      "value": "H224",
      "type": "psc_code"
   },
   {
      "label": "H224 - EQ TEST SVCS/TRACTORS",
      "value": "H224",
      "type": "psc_code"
   },
   {
      "label": "H225 - EQUIPMENT AND MATERIALS TESTING- VEHICULAR EQUIPMENT COMPONENTS",
      "value": "H225",
      "type": "psc_code"
   },
   {
      "label": "H225 - EQ TEST SVCS/VEHICULAR EQUIP",
      "value": "H225",
      "type": "psc_code"
   },
   {
      "label": "H226 - EQUIPMENT AND MATERIALS TESTING- TIRES AND TUBES",
      "value": "H226",
      "type": "psc_code"
   },
   {
      "label": "H226 - EQ TEST SVCS/TIRES & TUBES",
      "value": "H226",
      "type": "psc_code"
   },
   {
      "label": "H228 - EQ TEST SVCS/ENGINES & TURBINES",
      "value": "H228",
      "type": "psc_code"
   },
   {
      "label": "H228 - EQUIPMENT AND MATERIALS TESTING- ENGINES, TURBINES, AND COMPONENTS",
      "value": "H228",
      "type": "psc_code"
   },
   {
      "label": "H229 - EQUIPMENT AND MATERIALS TESTING- ENGINE ACCESSORIES",
      "value": "H229",
      "type": "psc_code"
   },
   {
      "label": "H229 - EQ TEST SVCS/ENGINE ACCESSORIES",
      "value": "H229",
      "type": "psc_code"
   },
   {
      "label": "H230 - EQUIPMENT AND MATERIALS TESTING- MECHANICAL POWER TRANSMISSION EQUIPMENT",
      "value": "H230",
      "type": "psc_code"
   },
   {
      "label": "H230 - EQ TEST SVCS/MECH POWER TRANS EQ",
      "value": "H230",
      "type": "psc_code"
   },
   {
      "label": "H231 - EQ TEST SVCS/BEARINGS",
      "value": "H231",
      "type": "psc_code"
   },
   {
      "label": "H231 - EQUIPMENT AND MATERIALS TESTING- BEARINGS",
      "value": "H231",
      "type": "psc_code"
   },
   {
      "label": "H232 - EQUIPMENT AND MATERIALS TESTING- WOODWORKING MACHINERY AND EQUIPMENT",
      "value": "H232",
      "type": "psc_code"
   },
   {
      "label": "H232 - EQ TEST SVCS/WOODWORKING MACHINE",
      "value": "H232",
      "type": "psc_code"
   },
   {
      "label": "H234 - EQUIPMENT AND MATERIALS TESTING- METALWORKING MACHINERY",
      "value": "H234",
      "type": "psc_code"
   },
   {
      "label": "H234 - EQ TEST SVCS/METALWORK MACHINE",
      "value": "H234",
      "type": "psc_code"
   },
   {
      "label": "H235 - EQ TEST SVCS/SERVICE & TRADE EQUIP",
      "value": "H235",
      "type": "psc_code"
   },
   {
      "label": "H235 - EQUIPMENT AND MATERIALS TESTING- SERVICE AND TRADE EQUIPMENT",
      "value": "H235",
      "type": "psc_code"
   },
   {
      "label": "H236 - EQ TEST SVCS/SP INDUSTRY MACHINERY",
      "value": "H236",
      "type": "psc_code"
   },
   {
      "label": "H236 - EQUIPMENT AND MATERIALS TESTING- SPECIAL INDUSTRY MACHINERY",
      "value": "H236",
      "type": "psc_code"
   },
   {
      "label": "H237 - EQ TEST SVCS/AGRICULTURE MACHINE",
      "value": "H237",
      "type": "psc_code"
   },
   {
      "label": "H237 - EQUIPMENT AND MATERIALS TESTING- AGRICULTURAL MACHINERY AND EQUIPMENT",
      "value": "H237",
      "type": "psc_code"
   },
   {
      "label": "H238 - EQ TEST SVCS/CONTRUCT EQUIP",
      "value": "H238",
      "type": "psc_code"
   },
   {
      "label": "H238 - EQUIPMENT AND MATERIALS TESTING- CONSTRUCTION, MINING, EXCAVATING, AND HIGHWAY MAINTENANCE EQUIPMENT",
      "value": "H238",
      "type": "psc_code"
   },
   {
      "label": "H239 - EQ TEST SVCS/MATERIALS HANDLING EQ",
      "value": "H239",
      "type": "psc_code"
   },
   {
      "label": "H239 - EQUIPMENT AND MATERIALS TESTING- MATERIALS HANDLING EQUIPMENT",
      "value": "H239",
      "type": "psc_code"
   },
   {
      "label": "H240 - EQ TEST SVCS/ROPE-CABLE-CHAIN",
      "value": "H240",
      "type": "psc_code"
   },
   {
      "label": "H240 - EQUIPMENT AND MATERIALS TESTING- ROPE, CABLE, CHAIN, AND FITTINGS",
      "value": "H240",
      "type": "psc_code"
   },
   {
      "label": "H241 - EQ TEST SVCS/REFRIGERATION - AC EQ",
      "value": "H241",
      "type": "psc_code"
   },
   {
      "label": "H241 - EQUIPMENT AND MATERIALS TESTING- REFRIGERATION, AIR CONDITIONING, AND AIR CIRCULATING EQUIPMENT",
      "value": "H241",
      "type": "psc_code"
   },
   {
      "label": "H242 - EQ TEST SVCS/FIRE-RESCUE-SAFETY EQ",
      "value": "H242",
      "type": "psc_code"
   },
   {
      "label": "H242 - EQUIP/MATERIALS TESTING- FIRE FIGHTING/RESCUE/SAFETY EQUIPMENT; ENVIRON PROTECT EQUIPMENT/MATLS",
      "value": "H242",
      "type": "psc_code"
   },
   {
      "label": "H243 - EQ TEST SVCS/PUMPS & COMPRESSORS",
      "value": "H243",
      "type": "psc_code"
   },
   {
      "label": "H243 - EQUIPMENT AND MATERIALS TESTING- PUMPS AND COMPRESSORS",
      "value": "H243",
      "type": "psc_code"
   },
   {
      "label": "H244 - EQUIPMENT AND MATERIALS TESTING- FURNACE, STEAM PLANT, AND DRYING EQUIPMENT; NUCLEAR REACTORS",
      "value": "H244",
      "type": "psc_code"
   },
   {
      "label": "H244 - EQ TEST SVCS/FURNACE-NUCLEAR REAC",
      "value": "H244",
      "type": "psc_code"
   },
   {
      "label": "H245 - EQ TEST SVCS/PLUMBING-HEATING EQ",
      "value": "H245",
      "type": "psc_code"
   },
   {
      "label": "H245 - EQUIPMENT AND MATERIALS TESTING- PLUMBING, HEATING, AND WASTE DISPOSAL EQUIPMENT",
      "value": "H245",
      "type": "psc_code"
   },
   {
      "label": "H246 - EQUIPMENT AND MATERIALS TESTING- WATER PURIFICATION AND SEWAGE TREATMENT EQUIPMENT",
      "value": "H246",
      "type": "psc_code"
   },
   {
      "label": "H246 - EQ TEST SVCS/WATER PURIFICATION EQ",
      "value": "H246",
      "type": "psc_code"
   },
   {
      "label": "H247 - EQ TEST SVCS/PIPE-TUBING-HOSE",
      "value": "H247",
      "type": "psc_code"
   },
   {
      "label": "H247 - EQUIPMENT AND MATERIALS TESTING- PIPE, TUBING, HOSE, AND FITTINGS",
      "value": "H247",
      "type": "psc_code"
   },
   {
      "label": "H248 - EQ TEST SVCS/VALVES",
      "value": "H248",
      "type": "psc_code"
   },
   {
      "label": "H248 - EQUIPMENT AND MATERIALS TESTING- VALVES",
      "value": "H248",
      "type": "psc_code"
   },
   {
      "label": "H249 - EQ TEST SVCS/MAINT REPAIR SHOP EQ",
      "value": "H249",
      "type": "psc_code"
   },
   {
      "label": "H249 - EQUIPMENT AND MATERIALS TESTING- MAINTENANCE AND REPAIR SHOP EQUIPMENT",
      "value": "H249",
      "type": "psc_code"
   },
   {
      "label": "H251 - EQUIPMENT AND MATERIALS TESTING- HAND TOOLS",
      "value": "H251",
      "type": "psc_code"
   },
   {
      "label": "H251 - EQ TEST SVCS/HAND TOOLS",
      "value": "H251",
      "type": "psc_code"
   },
   {
      "label": "H252 - EQ TEST SVCS/MEASURING TOOLS",
      "value": "H252",
      "type": "psc_code"
   },
   {
      "label": "H252 - EQUIPMENT AND MATERIALS TESTING- MEASURING TOOLS",
      "value": "H252",
      "type": "psc_code"
   },
   {
      "label": "H253 - EQ TEST SVCS/HARDWARE & ABRASIVES",
      "value": "H253",
      "type": "psc_code"
   },
   {
      "label": "H253 - EQUIPMENT AND MATERIALS TESTING- HARDWARE AND ABRASIVES",
      "value": "H253",
      "type": "psc_code"
   },
   {
      "label": "H254 - EQ TEST SVCS/PREFAB STRUCTURES",
      "value": "H254",
      "type": "psc_code"
   },
   {
      "label": "H254 - EQUIPMENT AND MATERIALS TESTING- PREFABRICATED STRUCTURES AND SCAFFOLDING",
      "value": "H254",
      "type": "psc_code"
   },
   {
      "label": "H255 - EQ TEST SVCS/LUMBER & MILLWORK",
      "value": "H255",
      "type": "psc_code"
   },
   {
      "label": "H255 - EQUIPMENT AND MATERIALS TESTING- LUMBER, MILLWORK, PLYWOOD, AND VENEER",
      "value": "H255",
      "type": "psc_code"
   },
   {
      "label": "H256 - EQUIPMENT AND MATERIALS TESTING- CONSTRUCTION AND BUILDING MATERIALS",
      "value": "H256",
      "type": "psc_code"
   },
   {
      "label": "H256 - EQ TEST SVCS/CONTRUCT MATERIAL",
      "value": "H256",
      "type": "psc_code"
   },
   {
      "label": "H258 - EQUIPMENT AND MATERIALS TESTING- COMMUNICATION, DETECTION, AND COHERENT RADIATION EQUIPMENT",
      "value": "H258",
      "type": "psc_code"
   },
   {
      "label": "H258 - EQ TEST SVCS/COMMUNICATION EQUIP",
      "value": "H258",
      "type": "psc_code"
   },
   {
      "label": "H259 - EQUIPMENT AND MATERIALS TESTING- ELECTRICAL AND ELECTRONIC EQUIPMENT COMPONENTS",
      "value": "H259",
      "type": "psc_code"
   },
   {
      "label": "H259 - EQ TEST SVCS/ELECT-ELCT EQUIP",
      "value": "H259",
      "type": "psc_code"
   },
   {
      "label": "H260 - EQUIPMENT AND MATERIALS TESTING- FIBER OPTICS MATERIALS, COMPONENTS, ASSEMBLIES, AND ACCESSORIES",
      "value": "H260",
      "type": "psc_code"
   },
   {
      "label": "H260 - EQ TEST SVCS/FIBER OPTICS MATERIAL",
      "value": "H260",
      "type": "psc_code"
   },
   {
      "label": "H261 - EQ TEST SVCS/POWER DISTRIBUTION EQ",
      "value": "H261",
      "type": "psc_code"
   },
   {
      "label": "H261 - EQUIPMENT AND MATERIALS TESTING- ELECTRIC WIRE AND POWER DISTRIBUTION EQUIPMENT",
      "value": "H261",
      "type": "psc_code"
   },
   {
      "label": "H262 - EQ TEST SVCS/LIGHTING FIXTURES",
      "value": "H262",
      "type": "psc_code"
   },
   {
      "label": "H262 - EQUIPMENT AND MATERIALS TESTING- LIGHTING FIXTURES AND LAMPS",
      "value": "H262",
      "type": "psc_code"
   },
   {
      "label": "H263 - EQUIPMENT AND MATERIALS TESTING- ALARM, SIGNAL, AND SECURITY DETECTION SYSTEMS",
      "value": "H263",
      "type": "psc_code"
   },
   {
      "label": "H263 - EQ TEST SVCS/ALARM & SIGNAL SYSTEM",
      "value": "H263",
      "type": "psc_code"
   },
   {
      "label": "H265 - EQUIPMENT AND MATERIALS TESTING- MEDICAL, DENTAL, AND VETERINARY EQUIPMENT AND SUPPLIES",
      "value": "H265",
      "type": "psc_code"
   },
   {
      "label": "H265 - EQ TEST SVCS/MEDICAL-DENTAL-VET EQ",
      "value": "H265",
      "type": "psc_code"
   },
   {
      "label": "H266 - EQUIPMENT AND MATERIALS TESTING- INSTRUMENTS AND LABORATORY EQUIPMENT",
      "value": "H266",
      "type": "psc_code"
   },
   {
      "label": "H266 - EQ TEST SVCS/INSTRUMENTS & LAB EQ",
      "value": "H266",
      "type": "psc_code"
   },
   {
      "label": "H267 - EQUIPMENT AND MATERIALS TESTING- PHOTOGRAPHIC EQUIPMENT",
      "value": "H267",
      "type": "psc_code"
   },
   {
      "label": "H267 - EQ TEST SVCS/PHOTOGRAPHIC EQUIP",
      "value": "H267",
      "type": "psc_code"
   },
   {
      "label": "H268 - EQ TEST SVCS/CHEMICAL PRODUCTS",
      "value": "H268",
      "type": "psc_code"
   },
   {
      "label": "H268 - EQUIPMENT AND MATERIALS TESTING- CHEMICALS AND CHEMICAL PRODUCTS",
      "value": "H268",
      "type": "psc_code"
   },
   {
      "label": "H269 - EQUIPMENT AND MATERIALS TESTING- TRAINING AIDS AND DEVICES",
      "value": "H269",
      "type": "psc_code"
   },
   {
      "label": "H269 - EQ TEST SVCS/TRAINING AIDS-DEVICES",
      "value": "H269",
      "type": "psc_code"
   },
   {
      "label": "H270 - EQUIPMENT AND MATERIALS TESTING- ADP EQUIPMENT/SOFTWARE/SUPPLIES/SUPPORT EQUIPMENT",
      "value": "H270",
      "type": "psc_code"
   },
   {
      "label": "H270 - EQ TEST SVCS/ADP EQUIP & SUPPLIES",
      "value": "H270",
      "type": "psc_code"
   },
   {
      "label": "H271 - EQ TEST SVCS/FURNITURE",
      "value": "H271",
      "type": "psc_code"
   },
   {
      "label": "H271 - EQUIPMENT AND MATERIALS TESTING- FURNITURE",
      "value": "H271",
      "type": "psc_code"
   },
   {
      "label": "H272 - EQUIPMENT AND MATERIALS TESTING- HOUSEHOLD AND COMMERCIAL FURNISHINGS AND APPLIANCES",
      "value": "H272",
      "type": "psc_code"
   },
   {
      "label": "H272 - EQ TEST SVCS/HOUSEHOLD FURNISHINGS",
      "value": "H272",
      "type": "psc_code"
   },
   {
      "label": "H273 - EQ TEST SVCS/FOOD PREP-SERVING EQ",
      "value": "H273",
      "type": "psc_code"
   },
   {
      "label": "H273 - EQUIPMENT AND MATERIALS TESTING- FOOD PREPARATION AND SERVING EQUIPMENT",
      "value": "H273",
      "type": "psc_code"
   },
   {
      "label": "H274 - EQUIPMENT/MATERIALS TESTING- OFFICE MACHINES, TEXT PROCESSING SYSTEMS, AND VISIBLE RECORD EQUIPMENT",
      "value": "H274",
      "type": "psc_code"
   },
   {
      "label": "H274 - EQ TEST SVCS/OFFICE MACHINES",
      "value": "H274",
      "type": "psc_code"
   },
   {
      "label": "H275 - EQ TEST SVCS/OFFICE SUPPLIES",
      "value": "H275",
      "type": "psc_code"
   },
   {
      "label": "H275 - EQUIPMENT AND MATERIALS TESTING- OFFICE SUPPLIES AND DEVICES",
      "value": "H275",
      "type": "psc_code"
   },
   {
      "label": "H276 - EQ TEST SVCS/BOOKS-MAPS-PUBS",
      "value": "H276",
      "type": "psc_code"
   },
   {
      "label": "H276 - EQUIPMENT AND MATERIALS TESTING- BOOKS, MAPS, AND OTHER PUBLICATIONS",
      "value": "H276",
      "type": "psc_code"
   },
   {
      "label": "H277 - EQ TEST SVCS/MUSICAL INST-RADIO-TV",
      "value": "H277",
      "type": "psc_code"
   },
   {
      "label": "H277 - EQUIPMENT AND MATERIALS TESTING- MUSICAL INST/PHONOGRAPH/HOME RADIO",
      "value": "H277",
      "type": "psc_code"
   },
   {
      "label": "H278 - EQUIPMENT AND MATERIALS TESTING- RECREATIONAL AND ATHLETIC EQUIPMENT",
      "value": "H278",
      "type": "psc_code"
   },
   {
      "label": "H278 - EQ TEST SVCS/RECREATIONAL EQUIP",
      "value": "H278",
      "type": "psc_code"
   },
   {
      "label": "H279 - EQUIPMENT AND MATERIALS TESTING- CLEANING EQUIPMENT AND SUPPLIES",
      "value": "H279",
      "type": "psc_code"
   },
   {
      "label": "H279 - EQ TEST SVCS/CLEANING EQUIP",
      "value": "H279",
      "type": "psc_code"
   },
   {
      "label": "H280 - EQ TEST SVCS/BRUSHES-PAINTS-SEALER",
      "value": "H280",
      "type": "psc_code"
   },
   {
      "label": "H280 - EQUIPMENT AND MATERIALS TESTING- BRUSHES, PAINTS, SEALERS, AND ADHESIVES",
      "value": "H280",
      "type": "psc_code"
   },
   {
      "label": "H281 - EQ TEST SVCS/CONTAINERS-PACKAGING",
      "value": "H281",
      "type": "psc_code"
   },
   {
      "label": "H281 - EQUIPMENT AND MATERIALS TESTING- CONTAINERS, PACKAGING, AND PACKING SUPPLIES",
      "value": "H281",
      "type": "psc_code"
   },
   {
      "label": "H283 - EQUIPMENT AND MATERIALS TESTING- TEXTILES, LEATHER, FURS, APPAREL AND SHOE FINDINGS, TENTS AND FLAGS",
      "value": "H283",
      "type": "psc_code"
   },
   {
      "label": "H283 - EQ TEST SVCS/TEXTILES-LEATHER-FURS",
      "value": "H283",
      "type": "psc_code"
   },
   {
      "label": "H284 - EQUIPMENT AND MATERIALS TESTING- CLOTHING, INDIVIDUAL EQUIPMENT, AND INSIGNIA",
      "value": "H284",
      "type": "psc_code"
   },
   {
      "label": "H284 - EQ TEST SVCS/CLOTHING - INDIVID EQ",
      "value": "H284",
      "type": "psc_code"
   },
   {
      "label": "H285 - EQ TEST SVCS/TOILETRIES",
      "value": "H285",
      "type": "psc_code"
   },
   {
      "label": "H285 - EQUIPMENT AND MATERIALS TESTING- TOILETRIES",
      "value": "H285",
      "type": "psc_code"
   },
   {
      "label": "H287 - EQ TEST SVCS/AGRICULTURAL SUPPLIES",
      "value": "H287",
      "type": "psc_code"
   },
   {
      "label": "H287 - EQUIPMENT AND MATERIALS TESTING- AGRICULTURAL SUPPLIES",
      "value": "H287",
      "type": "psc_code"
   },
   {
      "label": "H288 - EQUIPMENT AND MATERIALS TESTING- LIVE ANIMALS",
      "value": "H288",
      "type": "psc_code"
   },
   {
      "label": "H288 - EQ TEST SVCS/LIVE ANIMALS",
      "value": "H288",
      "type": "psc_code"
   },
   {
      "label": "H289 - EQ TEST SVCS/SUBSISTENCE",
      "value": "H289",
      "type": "psc_code"
   },
   {
      "label": "H289 - EQUIPMENT AND MATERIALS TESTING- SUBSISTENCE",
      "value": "H289",
      "type": "psc_code"
   },
   {
      "label": "H291 - EQ TEST SVCS/FUELS-LUBRICANTS-OILS",
      "value": "H291",
      "type": "psc_code"
   },
   {
      "label": "H291 - EQUIPMENT AND MATERIALS TESTING- FUELS, LUBRICANTS, OILS, AND WAXES",
      "value": "H291",
      "type": "psc_code"
   },
   {
      "label": "H293 - EQ TEST SVCS/NONMETALIC FAB MAT",
      "value": "H293",
      "type": "psc_code"
   },
   {
      "label": "H293 - EQUIPMENT AND MATERIALS TESTING- NONMETALLIC FABRICATED MATERIALS",
      "value": "H293",
      "type": "psc_code"
   },
   {
      "label": "H294 - EQUIPMENT AND MATERIALS TESTING- NONMETALLIC CRUDE MATERIALS",
      "value": "H294",
      "type": "psc_code"
   },
   {
      "label": "H294 - EQ TEST SVCS/NONMETALIC CRUDE MAT",
      "value": "H294",
      "type": "psc_code"
   },
   {
      "label": "H295 - EQ TEST SVCS/METAL BARS & SHEETS",
      "value": "H295",
      "type": "psc_code"
   },
   {
      "label": "H295 - EQUIPMENT AND MATERIALS TESTING- METAL BARS, SHEETS, AND SHAPES",
      "value": "H295",
      "type": "psc_code"
   },
   {
      "label": "H296 - EQUIPMENT AND MATERIALS TESTING- ORES, MINERALS, AND THEIR PRIMARY PRODUCTS",
      "value": "H296",
      "type": "psc_code"
   },
   {
      "label": "H296 - EQ TEST SVCS/ORES & MINERALS",
      "value": "H296",
      "type": "psc_code"
   },
   {
      "label": "H299 - EQUIPMENT AND MATERIALS TESTING- MISCELLANEOUS",
      "value": "H299",
      "type": "psc_code"
   },
   {
      "label": "H299 - EQ TEST SVCS/MISC EQUIP",
      "value": "H299",
      "type": "psc_code"
   },
   {
      "label": "H3 - INSPECTION SERVICES",
      "value": "H3",
      "type": "psc_code"
   },
   {
      "label": "H310 - INSPECTION- WEAPONS",
      "value": "H310",
      "type": "psc_code"
   },
   {
      "label": "H310 - INSPECT SVCS/WEAPONS",
      "value": "H310",
      "type": "psc_code"
   },
   {
      "label": "H311 - INSPECTION- NUCLEAR ORDNANCE",
      "value": "H311",
      "type": "psc_code"
   },
   {
      "label": "H311 - INSPECT SVCS/NUCLEAR ORDNANCE",
      "value": "H311",
      "type": "psc_code"
   },
   {
      "label": "H312 - INSPECTION- FIRE CONTROL EQUIPMENT",
      "value": "H312",
      "type": "psc_code"
   },
   {
      "label": "H312 - INSPECT SVCS/FIRE CONT EQ",
      "value": "H312",
      "type": "psc_code"
   },
   {
      "label": "H313 - INSPECT SVCS/AMMO & EXPLOSIVES",
      "value": "H313",
      "type": "psc_code"
   },
   {
      "label": "H313 - INSPECTION- AMMUNITION AND EXPLOSIVES",
      "value": "H313",
      "type": "psc_code"
   },
   {
      "label": "H314 - INSPECT SVCS/GUIDED MISSILES",
      "value": "H314",
      "type": "psc_code"
   },
   {
      "label": "H314 - INSPECTION- GUIDED MISSILES",
      "value": "H314",
      "type": "psc_code"
   },
   {
      "label": "H315 - INSPECTION- AIRCRAFT AND AIRFRAME STRUCTURAL COMPONENTS",
      "value": "H315",
      "type": "psc_code"
   },
   {
      "label": "H315 - INSPECT SVCS/AIRCRAFT",
      "value": "H315",
      "type": "psc_code"
   },
   {
      "label": "H316 - INSPECTION- AIRCRAFT COMPONENTS AND ACCESSORIES",
      "value": "H316",
      "type": "psc_code"
   },
   {
      "label": "H316 - INSPECT SVCS/AIRCRAFT COMPONENTS",
      "value": "H316",
      "type": "psc_code"
   },
   {
      "label": "H317 - INSPECT SVCS/AIRCRAFT GROUND EQ",
      "value": "H317",
      "type": "psc_code"
   },
   {
      "label": "H317 - INSPECTION- AIRCRAFT LAUNCHING, LANDING, AND GROUND HANDLING EQUIPMENT",
      "value": "H317",
      "type": "psc_code"
   },
   {
      "label": "H318 - INSPECTION- SPACE VEHICLES",
      "value": "H318",
      "type": "psc_code"
   },
   {
      "label": "H318 - INSPECT SVCS/SPACE VEHICLES",
      "value": "H318",
      "type": "psc_code"
   },
   {
      "label": "H319 - INSPECT SVCS/SHIPS-SML CRAFT-DOCKS",
      "value": "H319",
      "type": "psc_code"
   },
   {
      "label": "H319 - INSPECTION- SHIPS, SMALL CRAFT, PONTOONS, AND FLOATING DOCKS",
      "value": "H319",
      "type": "psc_code"
   },
   {
      "label": "H320 - INSPECT SVCS/SHIP & MARINE EQ",
      "value": "H320",
      "type": "psc_code"
   },
   {
      "label": "H320 - INSPECTION- SHIP AND MARINE EQUIPMENT",
      "value": "H320",
      "type": "psc_code"
   },
   {
      "label": "H322 - INSPECT SVCS/RAILWAY EQ",
      "value": "H322",
      "type": "psc_code"
   },
   {
      "label": "H322 - INSPECTION- RAILWAY EQUIPMENT",
      "value": "H322",
      "type": "psc_code"
   },
   {
      "label": "H323 - INSPECTION- GROUND EFFECT VEHICLES, MOTOR VEHICLES, TRAILERS, AND CYCLES",
      "value": "H323",
      "type": "psc_code"
   },
   {
      "label": "H323 - INSPECT SVCS/VEHICLES-TRAILERS-CYC",
      "value": "H323",
      "type": "psc_code"
   },
   {
      "label": "H324 - INSPECT SVCS/TRACTORS",
      "value": "H324",
      "type": "psc_code"
   },
   {
      "label": "H324 - INSPECTION- TRACTORS",
      "value": "H324",
      "type": "psc_code"
   },
   {
      "label": "H325 - INSPECTION- VEHICULAR EQUIPMENT COMPONENTS",
      "value": "H325",
      "type": "psc_code"
   },
   {
      "label": "H325 - INSPECT SVCS/VEHICULAR EQ",
      "value": "H325",
      "type": "psc_code"
   },
   {
      "label": "H326 - INSPECTION- TIRES AND TUBES",
      "value": "H326",
      "type": "psc_code"
   },
   {
      "label": "H326 - INSPECT SVCS/TIRES & TUBES",
      "value": "H326",
      "type": "psc_code"
   },
   {
      "label": "H328 - INSPECTION- ENGINES, TURBINES, AND COMPONENTS",
      "value": "H328",
      "type": "psc_code"
   },
   {
      "label": "H328 - INSPECT SVCS/ENGINES & TURBINES",
      "value": "H328",
      "type": "psc_code"
   },
   {
      "label": "H329 - INSPECTION- ENGINE ACCESSORIES",
      "value": "H329",
      "type": "psc_code"
   },
   {
      "label": "H329 - INSPECT SVCS/ENGINE ACCESSORIES",
      "value": "H329",
      "type": "psc_code"
   },
   {
      "label": "H330 - INSPECTION- MECHANICAL POWER TRANSMISSION EQUIPMENT",
      "value": "H330",
      "type": "psc_code"
   },
   {
      "label": "H330 - INSPECT SVCS/MECH POWER TRANS EQ",
      "value": "H330",
      "type": "psc_code"
   },
   {
      "label": "H331 - INSPECTION- BEARINGS",
      "value": "H331",
      "type": "psc_code"
   },
   {
      "label": "H331 - INSPECT SVCS/BEARINGS",
      "value": "H331",
      "type": "psc_code"
   },
   {
      "label": "H332 - INSPECTION- WOODWORKING MACHINERY AND EQUIPMENT",
      "value": "H332",
      "type": "psc_code"
   },
   {
      "label": "H332 - INSPECT SVCS/WOODWORKING MACHINE",
      "value": "H332",
      "type": "psc_code"
   },
   {
      "label": "H334 - INSPECTION- METALWORKING MACHINERY",
      "value": "H334",
      "type": "psc_code"
   },
   {
      "label": "H334 - INSPECT SVCS/METALWORK MACHINE",
      "value": "H334",
      "type": "psc_code"
   },
   {
      "label": "H335 - INSPECT SVCS/SERVICE & TRADE EQ",
      "value": "H335",
      "type": "psc_code"
   },
   {
      "label": "H335 - INSPECTION- SERVICE AND TRADE EQUIPMENT",
      "value": "H335",
      "type": "psc_code"
   },
   {
      "label": "H336 - INSPECTION- SPECIAL INDUSTRY MACHINERY",
      "value": "H336",
      "type": "psc_code"
   },
   {
      "label": "H336 - INSPECT SVCS/SP INDUSTRY MACHINERY",
      "value": "H336",
      "type": "psc_code"
   },
   {
      "label": "H337 - INSPECTION- AGRICULTURAL MACHINERY AND EQUIPMENT",
      "value": "H337",
      "type": "psc_code"
   },
   {
      "label": "H337 - INSPECT SVCS/AGRICULTURE MACHINE",
      "value": "H337",
      "type": "psc_code"
   },
   {
      "label": "H338 - INSPECT SVCS/CONTRUCT EQ",
      "value": "H338",
      "type": "psc_code"
   },
   {
      "label": "H338 - INSPECTION- CONSTRUCTION, MINING, EXCAVATING, AND HIGHWAY MAINTENANCE EQUIPMENT",
      "value": "H338",
      "type": "psc_code"
   },
   {
      "label": "H339 - INSPECT SVCS/MATERIALS HANDLING EQ",
      "value": "H339",
      "type": "psc_code"
   },
   {
      "label": "H339 - INSPECTION- MATERIALS HANDLING EQUIPMENT",
      "value": "H339",
      "type": "psc_code"
   },
   {
      "label": "H340 - INSPECT SVCS/ROPE-CABLE-CHAIN",
      "value": "H340",
      "type": "psc_code"
   },
   {
      "label": "H340 - INSPECTION- ROPE, CABLE, CHAIN, AND FITTINGS",
      "value": "H340",
      "type": "psc_code"
   },
   {
      "label": "H341 - INSPECTION- REFRIGERATION, AIR CONDITIONING, AND AIR CIRCULATING EQUIPMENT",
      "value": "H341",
      "type": "psc_code"
   },
   {
      "label": "H341 - INSPECT SVCS/REFRIGERATION - AC EQ",
      "value": "H341",
      "type": "psc_code"
   },
   {
      "label": "H342 - INSPECT SVCS/FIRE-RESCUE-SAFETY EQ",
      "value": "H342",
      "type": "psc_code"
   },
   {
      "label": "H342 - INSPECTION- FIRE FIGHTING/RESCUE/SAFETY EQUIPMENT; ENVIRON PROTECT EQUIPMENT/MATLS",
      "value": "H342",
      "type": "psc_code"
   },
   {
      "label": "H343 - INSPECTION- PUMPS AND COMPRESSORS",
      "value": "H343",
      "type": "psc_code"
   },
   {
      "label": "H343 - INSPECT SVCS/PUMPS & COMPRESSORS",
      "value": "H343",
      "type": "psc_code"
   },
   {
      "label": "H344 - INSPECT SVCS/FURNACE-NUCLEAR REAC",
      "value": "H344",
      "type": "psc_code"
   },
   {
      "label": "H344 - INSPECTION- FURNACE, STEAM PLANT, AND DRYING EQUIPMENT; NUCLEAR REACTORS",
      "value": "H344",
      "type": "psc_code"
   },
   {
      "label": "H345 - INSPECT SVCS/PLUMBING-HEATING EQ",
      "value": "H345",
      "type": "psc_code"
   },
   {
      "label": "H345 - INSPECTION- PLUMBING, HEATING, AND WASTE DISPOSAL EQUIPMENT",
      "value": "H345",
      "type": "psc_code"
   },
   {
      "label": "H346 - INSPECT SVCS/WATER PURIFICATION EQ",
      "value": "H346",
      "type": "psc_code"
   },
   {
      "label": "H346 - INSPECTION- WATER PURIFICATION AND SEWAGE TREATMENT EQUIPMENT",
      "value": "H346",
      "type": "psc_code"
   },
   {
      "label": "H347 - INSPECT SVCS/PIPE-TUBING-HOSE",
      "value": "H347",
      "type": "psc_code"
   },
   {
      "label": "H347 - INSPECTION- PIPE, TUBING, HOSE, AND FITTINGS",
      "value": "H347",
      "type": "psc_code"
   },
   {
      "label": "H348 - INSPECT SVCS/VALVES",
      "value": "H348",
      "type": "psc_code"
   },
   {
      "label": "H348 - INSPECTION- VALVES",
      "value": "H348",
      "type": "psc_code"
   },
   {
      "label": "H349 - INSPECT SVCS/MAINT REPAIR SHOP EQ",
      "value": "H349",
      "type": "psc_code"
   },
   {
      "label": "H349 - INSPECTION- MAINTENANCE AND REPAIR SHOP EQUIPMENT",
      "value": "H349",
      "type": "psc_code"
   },
   {
      "label": "H351 - INSPECT SVCS/HAND TOOLS",
      "value": "H351",
      "type": "psc_code"
   },
   {
      "label": "H351 - INSPECTION- HAND TOOLS",
      "value": "H351",
      "type": "psc_code"
   },
   {
      "label": "H352 - INSPECTION- MEASURING TOOLS",
      "value": "H352",
      "type": "psc_code"
   },
   {
      "label": "H352 - INSPECT SVCS/MEASURING TOOLS",
      "value": "H352",
      "type": "psc_code"
   },
   {
      "label": "H353 - INSPECTION- HARDWARE AND ABRASIVES",
      "value": "H353",
      "type": "psc_code"
   },
   {
      "label": "H353 - INSPECT SVCS/HARDWARE & ABRASIVES",
      "value": "H353",
      "type": "psc_code"
   },
   {
      "label": "H354 - INSPECT SVCS/PREFAB STRUCTURES",
      "value": "H354",
      "type": "psc_code"
   },
   {
      "label": "H354 - INSPECTION- PREFABRICATED STRUCTURES AND SCAFFOLDING",
      "value": "H354",
      "type": "psc_code"
   },
   {
      "label": "H355 - INSPECT SVCS/LUMBER & MILLWORK",
      "value": "H355",
      "type": "psc_code"
   },
   {
      "label": "H355 - INSPECTION- LUMBER, MILLWORK, PLYWOOD, AND VENEER",
      "value": "H355",
      "type": "psc_code"
   },
   {
      "label": "H356 - INSPECT SVCS/CONTRUCT MATERIAL",
      "value": "H356",
      "type": "psc_code"
   },
   {
      "label": "H356 - INSPECTION- CONSTRUCTION AND BUILDING MATERIALS",
      "value": "H356",
      "type": "psc_code"
   },
   {
      "label": "H358 - INSPECTION- COMMUNICATION, DETECTION, AND COHERENT RADIATION EQUIPMENT",
      "value": "H358",
      "type": "psc_code"
   },
   {
      "label": "H358 - INSPECT SVCS/COMMUNICATION EQ",
      "value": "H358",
      "type": "psc_code"
   },
   {
      "label": "H359 - INSPECTION- ELECTRICAL AND ELECTRONIC EQUIPMENT COMPONENTS",
      "value": "H359",
      "type": "psc_code"
   },
   {
      "label": "H359 - INSPECT SVCS/ELECT-ELCT EQ",
      "value": "H359",
      "type": "psc_code"
   },
   {
      "label": "H360 - INSPECTION- FIBER OPTICS MATERIALS, COMPONENTS, ASSEMBLIES, AND ACCESSORIES",
      "value": "H360",
      "type": "psc_code"
   },
   {
      "label": "H360 - INSPECT SVCS/FIBER OPTICS",
      "value": "H360",
      "type": "psc_code"
   },
   {
      "label": "H361 - INSPECTION- ELECTRIC WIRE AND POWER DISTRIBUTION EQUIPMENT",
      "value": "H361",
      "type": "psc_code"
   },
   {
      "label": "H361 - INSPECT SVCS/POWER DISTRIBUTION EQ",
      "value": "H361",
      "type": "psc_code"
   },
   {
      "label": "H362 - INSPECTION- LIGHTING FIXTURES AND LAMPS",
      "value": "H362",
      "type": "psc_code"
   },
   {
      "label": "H362 - INSPECT SVCS/LIGHTING FIXTURES",
      "value": "H362",
      "type": "psc_code"
   },
   {
      "label": "H363 - INSPECT SVCS/ALARM & SIGNAL SYSTEM",
      "value": "H363",
      "type": "psc_code"
   },
   {
      "label": "H363 - INSPECTION- ALARM, SIGNAL, AND SECURITY DETECTION SYSTEMS",
      "value": "H363",
      "type": "psc_code"
   },
   {
      "label": "H365 - INSPECT SVCS/MEDICAL-DENTAL-VET EQ",
      "value": "H365",
      "type": "psc_code"
   },
   {
      "label": "H365 - INSPECTION- MEDICAL, DENTAL, AND VETERINARY EQUIPMENT AND SUPPLIES",
      "value": "H365",
      "type": "psc_code"
   },
   {
      "label": "H366 - INSPECT SVCS/INSTRUMENTS & LAB EQ",
      "value": "H366",
      "type": "psc_code"
   },
   {
      "label": "H366 - INSPECTION- INSTRUMENTS AND LABORATORY EQUIPMENT",
      "value": "H366",
      "type": "psc_code"
   },
   {
      "label": "H367 - INSPECT SVCS/PHOTOGRAPHIC EQ",
      "value": "H367",
      "type": "psc_code"
   },
   {
      "label": "H367 - INSPECTION- PHOTOGRAPHIC EQUIPMENT",
      "value": "H367",
      "type": "psc_code"
   },
   {
      "label": "H368 - INSPECT SVCS/CHEMICAL PRODUCTS",
      "value": "H368",
      "type": "psc_code"
   },
   {
      "label": "H368 - INSPECTION- CHEMICALS AND CHEMICAL PRODUCTS",
      "value": "H368",
      "type": "psc_code"
   },
   {
      "label": "H369 - INSPECTION- TRAINING AIDS AND DEVICES",
      "value": "H369",
      "type": "psc_code"
   },
   {
      "label": "H369 - INSPECT SVCS/TRAINING AIDS-DEVICES",
      "value": "H369",
      "type": "psc_code"
   },
   {
      "label": "H370 - INSPECT SVCS/ADP EQ & SUPPLIES",
      "value": "H370",
      "type": "psc_code"
   },
   {
      "label": "H370 - INSPECTION- ADP EQUIPMENT/SOFTWARE/SUPPLIES/SUPPORT EQUIPMENT",
      "value": "H370",
      "type": "psc_code"
   },
   {
      "label": "H371 - INSPECT SVCS/FURNITURE",
      "value": "H371",
      "type": "psc_code"
   },
   {
      "label": "H371 - INSPECTION- FURNITURE",
      "value": "H371",
      "type": "psc_code"
   },
   {
      "label": "H372 - INSPECT SVCS/HOUSEHOLD FURNISHINGS",
      "value": "H372",
      "type": "psc_code"
   },
   {
      "label": "H372 - INSPECTION- HOUSEHOLD AND COMMERCIAL FURNISHINGS AND APPLIANCES",
      "value": "H372",
      "type": "psc_code"
   },
   {
      "label": "H373 - INSPECT SVCS/FOOD PREP-SERVING EQ",
      "value": "H373",
      "type": "psc_code"
   },
   {
      "label": "H373 - INSPECTION- FOOD PREPARATION AND SERVING EQUIPMENT",
      "value": "H373",
      "type": "psc_code"
   },
   {
      "label": "H374 - INSPECTION- OFFICE MACHINES, TEXT PROCESSING SYSTEMS, AND VISIBLE RECORD EQUIPMENT",
      "value": "H374",
      "type": "psc_code"
   },
   {
      "label": "H374 - INSPECT SVCS/OFFICE MACHINES",
      "value": "H374",
      "type": "psc_code"
   },
   {
      "label": "H375 - INSPECTION- OFFICE SUPPLIES AND DEVICES",
      "value": "H375",
      "type": "psc_code"
   },
   {
      "label": "H375 - INSPECT SVCS/OFFICE SUPPLIES",
      "value": "H375",
      "type": "psc_code"
   },
   {
      "label": "H376 - INSPECTION- BOOKS, MAPS, AND OTHER PUBLICATIONS",
      "value": "H376",
      "type": "psc_code"
   },
   {
      "label": "H376 - INSPECT SVCS/BOOKS-MAPS-PUBS",
      "value": "H376",
      "type": "psc_code"
   },
   {
      "label": "H377 - INSPECTION- MUSICAL INST/PHONOGRAPH/HOME RADIO",
      "value": "H377",
      "type": "psc_code"
   },
   {
      "label": "H377 - INSPECT SVCS/MUSICAL INST-RADIO-TV",
      "value": "H377",
      "type": "psc_code"
   },
   {
      "label": "H378 - INSPECTION- RECREATIONAL AND ATHLETIC EQUIPMENT",
      "value": "H378",
      "type": "psc_code"
   },
   {
      "label": "H378 - INSPECT SVCS/RECREATIONAL EQ",
      "value": "H378",
      "type": "psc_code"
   },
   {
      "label": "H379 - INSPECTION- CLEANING EQUIPMENT AND SUPPLIES",
      "value": "H379",
      "type": "psc_code"
   },
   {
      "label": "H379 - INSPECT SVCS/CLEANING EQ",
      "value": "H379",
      "type": "psc_code"
   },
   {
      "label": "H380 - INSPECTION- BRUSHES, PAINTS, SEALERS, AND ADHESIVES",
      "value": "H380",
      "type": "psc_code"
   },
   {
      "label": "H380 - INSPECT SVCS/BRUSHES-PAINTS-SEALER",
      "value": "H380",
      "type": "psc_code"
   },
   {
      "label": "H381 - INSPECT SVCS/CONTAINERS-PACKAGING",
      "value": "H381",
      "type": "psc_code"
   },
   {
      "label": "H381 - INSPECTION- CONTAINERS, PACKAGING, AND PACKING SUPPLIES",
      "value": "H381",
      "type": "psc_code"
   },
   {
      "label": "H383 - INSPECTION- TEXTILES, LEATHER, FURS, APPAREL AND SHOE FINDINGS, TENTS AND FLAGS",
      "value": "H383",
      "type": "psc_code"
   },
   {
      "label": "H383 - INSPECT SVCS/TEXTILES-LEATHER-FURS",
      "value": "H383",
      "type": "psc_code"
   },
   {
      "label": "H384 - INSPECT SVCS/CLOTHING - INDIVID EQ",
      "value": "H384",
      "type": "psc_code"
   },
   {
      "label": "H384 - INSPECTION- CLOTHING, INDIVIDUAL EQUIPMENT, AND INSIGNIA",
      "value": "H384",
      "type": "psc_code"
   },
   {
      "label": "H385 - INSPECTION- TOILETRIES",
      "value": "H385",
      "type": "psc_code"
   },
   {
      "label": "H385 - INSPECT SVCS/TOILETRIES",
      "value": "H385",
      "type": "psc_code"
   },
   {
      "label": "H387 - INSPECTION- AGRICULTURAL SUPPLIES",
      "value": "H387",
      "type": "psc_code"
   },
   {
      "label": "H387 - INSPECT SVCS/AGRICULTURAL SUPPLIES",
      "value": "H387",
      "type": "psc_code"
   },
   {
      "label": "H388 - INSPECT SVCS/LIVE ANIMALS",
      "value": "H388",
      "type": "psc_code"
   },
   {
      "label": "H388 - INSPECTION- LIVE ANIMALS",
      "value": "H388",
      "type": "psc_code"
   },
   {
      "label": "H389 - INSPECT SVCS/SUBSISTENCE",
      "value": "H389",
      "type": "psc_code"
   },
   {
      "label": "H389 - INSPECTION- SUBSISTENCE",
      "value": "H389",
      "type": "psc_code"
   },
   {
      "label": "H391 - INSPECTION- FUELS, LUBRICANTS, OILS, AND WAXES",
      "value": "H391",
      "type": "psc_code"
   },
   {
      "label": "H391 - INSPECT SVCS/FUELS-LUBRICANTS-OILS",
      "value": "H391",
      "type": "psc_code"
   },
   {
      "label": "H393 - INSPECTION- NONMETALLIC FABRICATED MATERIALS",
      "value": "H393",
      "type": "psc_code"
   },
   {
      "label": "H393 - INSPECT SVCS/NONMETALIC FAB MAT",
      "value": "H393",
      "type": "psc_code"
   },
   {
      "label": "H394 - INSPECTION- NONMETALLIC CRUDE MATERIALS",
      "value": "H394",
      "type": "psc_code"
   },
   {
      "label": "H394 - INSPECT SVCS/NONMETALIC CRUDE MAT",
      "value": "H394",
      "type": "psc_code"
   },
   {
      "label": "H395 - INSPECTION- METAL BARS, SHEETS, AND SHAPES",
      "value": "H395",
      "type": "psc_code"
   },
   {
      "label": "H395 - INSPECT SVCS/METAL BARS & SHEETS",
      "value": "H395",
      "type": "psc_code"
   },
   {
      "label": "H396 - INSPECT SVCS/ORES & MINERALS",
      "value": "H396",
      "type": "psc_code"
   },
   {
      "label": "H396 - INSPECTION- ORES, MINERALS, AND THEIR PRIMARY PRODUCTS",
      "value": "H396",
      "type": "psc_code"
   },
   {
      "label": "H399 - INSPECTION- MISCELLANEOUS",
      "value": "H399",
      "type": "psc_code"
   },
   {
      "label": "H399 - INSPECT SVCS/MISC EQ",
      "value": "H399",
      "type": "psc_code"
   },
   {
      "label": "H9 - OTHER QUALITY, TEST, INSPECT SVCS",
      "value": "H9",
      "type": "psc_code"
   },
   {
      "label": "H910 - OTHER QC/TEST/INSPECT- WEAPONS",
      "value": "H910",
      "type": "psc_code"
   },
   {
      "label": "H910 - OTHER QC SVCS/WEAPONS",
      "value": "H910",
      "type": "psc_code"
   },
   {
      "label": "H911 - OTHER QC SVCS/NUCLEAR ORDNANCE",
      "value": "H911",
      "type": "psc_code"
   },
   {
      "label": "H911 - OTHER QC/TEST/INSPECT- NUCLEAR ORDNANCE",
      "value": "H911",
      "type": "psc_code"
   },
   {
      "label": "H912 - OTHER QC/TEST/INSPECT- FIRE CONTROL EQUIPMENT",
      "value": "H912",
      "type": "psc_code"
   },
   {
      "label": "H912 - OTHER QC SVCS/FIRE CONT EQ",
      "value": "H912",
      "type": "psc_code"
   },
   {
      "label": "H913 - OTHER QC/TEST/INSPECT- AMMUNITION AND EXPLOSIVES",
      "value": "H913",
      "type": "psc_code"
   },
   {
      "label": "H913 - OTHER QC SVCS/AMMO & EXPLOSIVES",
      "value": "H913",
      "type": "psc_code"
   },
   {
      "label": "H914 - OTHER QC SVCS/GUIDED MISSILES",
      "value": "H914",
      "type": "psc_code"
   },
   {
      "label": "H914 - OTHER QC/TEST/INSPECT- GUIDED MISSILES",
      "value": "H914",
      "type": "psc_code"
   },
   {
      "label": "H915 - OTHER QC/TEST/INSPECT- AIRCRAFT AND AIRFRAME STRUCTURAL COMPONENTS",
      "value": "H915",
      "type": "psc_code"
   },
   {
      "label": "H915 - OTHER QC SVCS/AIRCRAFT",
      "value": "H915",
      "type": "psc_code"
   },
   {
      "label": "H916 - OTHER QC SVCS/AIRCRAFT COMPONENTS",
      "value": "H916",
      "type": "psc_code"
   },
   {
      "label": "H916 - OTHER QC/TEST/INSPECT- AIRCRAFT COMPONENTS AND ACCESSORIES",
      "value": "H916",
      "type": "psc_code"
   },
   {
      "label": "H917 - OTHER QC SVCS/AIRCRAFT GROUND EQ",
      "value": "H917",
      "type": "psc_code"
   },
   {
      "label": "H917 - OTHER QC/TEST/INSPECT- AIRCRAFT LAUNCHING, LANDING, AND GROUND HANDLING EQUIPMENT",
      "value": "H917",
      "type": "psc_code"
   },
   {
      "label": "H918 - OTHER QC/TEST/INSPECT- SPACE VEHICLES",
      "value": "H918",
      "type": "psc_code"
   },
   {
      "label": "H918 - OTHER QC SVCS/SPACE VEHICLES",
      "value": "H918",
      "type": "psc_code"
   },
   {
      "label": "H919 - OTHER QC SVCS/SHIPS-SML CRAFT-DOCKS",
      "value": "H919",
      "type": "psc_code"
   },
   {
      "label": "H919 - OTHER QC/TEST/INSPECT- SHIPS, SMALL CRAFT, PONTOONS, AND FLOATING DOCKS",
      "value": "H919",
      "type": "psc_code"
   },
   {
      "label": "H920 - OTHER QC/TEST/INSPECT- SHIP AND MARINE EQUIPMENT",
      "value": "H920",
      "type": "psc_code"
   },
   {
      "label": "H920 - OTHER QC SVCS/SHIP & MARINE EQ",
      "value": "H920",
      "type": "psc_code"
   },
   {
      "label": "H922 - OTHER QC/TEST/INSPECT- RAILWAY EQUIPMENT",
      "value": "H922",
      "type": "psc_code"
   },
   {
      "label": "H922 - OTHER QC SVCS/RAILWAY EQ",
      "value": "H922",
      "type": "psc_code"
   },
   {
      "label": "H923 - OTHER QC/TEST/INSPECT- GROUND EFFECT VEHICLES, MOTOR VEHICLES, TRAILERS, AND CYCLES",
      "value": "H923",
      "type": "psc_code"
   },
   {
      "label": "H923 - OTHER QC SVCS/VEHICLES-TRAILERS-CYC",
      "value": "H923",
      "type": "psc_code"
   },
   {
      "label": "H924 - OTHER QC/TEST/INSPECT- TRACTORS",
      "value": "H924",
      "type": "psc_code"
   },
   {
      "label": "H924 - OTHER QC SVCS/TRACTORS",
      "value": "H924",
      "type": "psc_code"
   },
   {
      "label": "H925 - OTHER QC/TEST/INSPECT- VEHICULAR EQUIPMENT COMPONENTS",
      "value": "H925",
      "type": "psc_code"
   },
   {
      "label": "H925 - OTHER QC SVCS/VEHICULAR EQ",
      "value": "H925",
      "type": "psc_code"
   },
   {
      "label": "H926 - OTHER QC SVCS/TIRES & TUBES",
      "value": "H926",
      "type": "psc_code"
   },
   {
      "label": "H926 - OTHER QC/TEST/INSPECT- TIRES AND TUBES",
      "value": "H926",
      "type": "psc_code"
   },
   {
      "label": "H928 - OTHER QC SVCS/ENGINES & TURBINES",
      "value": "H928",
      "type": "psc_code"
   },
   {
      "label": "H928 - OTHER QC/TEST/INSPECT- ENGINES, TURBINES, AND COMPONENTS",
      "value": "H928",
      "type": "psc_code"
   },
   {
      "label": "H929 - OTHER QC SVCS/ENGINE ACCESSORIES",
      "value": "H929",
      "type": "psc_code"
   },
   {
      "label": "H929 - OTHER QC/TEST/INSPECT- ENGINE ACCESSORIES",
      "value": "H929",
      "type": "psc_code"
   },
   {
      "label": "H930 - OTHER QC SVCS/MECH POWER TRANS EQ",
      "value": "H930",
      "type": "psc_code"
   },
   {
      "label": "H930 - OTHER QC/TEST/INSPECT- MECHANICAL POWER TRANSMISSION EQUIPMENT",
      "value": "H930",
      "type": "psc_code"
   },
   {
      "label": "H931 - OTHER QC SVCS/BEARINGS",
      "value": "H931",
      "type": "psc_code"
   },
   {
      "label": "H931 - OTHER QC/TEST/INSPECT- BEARINGS",
      "value": "H931",
      "type": "psc_code"
   },
   {
      "label": "H932 - OTHER QC SVCS/WOODWORKING MACHINE",
      "value": "H932",
      "type": "psc_code"
   },
   {
      "label": "H932 - OTHER QC/TEST/INSPECT- WOODWORKING MACHINERY AND EQUIPMENT",
      "value": "H932",
      "type": "psc_code"
   },
   {
      "label": "H934 - OTHER QC SVCS/METALWORK MACHINE",
      "value": "H934",
      "type": "psc_code"
   },
   {
      "label": "H934 - OTHER QC/TEST/INSPECT- METALWORKING MACHINERY",
      "value": "H934",
      "type": "psc_code"
   },
   {
      "label": "H935 - OTHER QC SVCS/SERVICE & TRADE EQ",
      "value": "H935",
      "type": "psc_code"
   },
   {
      "label": "H935 - OTHER QC/TEST/INSPECT- SERVICE AND TRADE EQUIPMENT",
      "value": "H935",
      "type": "psc_code"
   },
   {
      "label": "H936 - OTHER QC SVCS/SP INDUSTRY MACHINERY",
      "value": "H936",
      "type": "psc_code"
   },
   {
      "label": "H936 - OTHER QC/TEST/INSPECT- SPECIAL INDUSTRY MACHINERY",
      "value": "H936",
      "type": "psc_code"
   },
   {
      "label": "H937 - OTHER QC SVCS/AGRICULTURE MACHINE",
      "value": "H937",
      "type": "psc_code"
   },
   {
      "label": "H937 - OTHER QC/TEST/INSPECT- AGRICULTURAL MACHINERY AND EQUIPMENT",
      "value": "H937",
      "type": "psc_code"
   },
   {
      "label": "H938 - OTHER QC/TEST/INSPECT- CONSTRUCTION, MINING, EXCAVATING, AND HIGHWAY MAINTENANCE EQUIPMENT",
      "value": "H938",
      "type": "psc_code"
   },
   {
      "label": "H938 - OTHER QC SVCS/CONTRUCT EQ",
      "value": "H938",
      "type": "psc_code"
   },
   {
      "label": "H939 - OTHER QC/TEST/INSPECT- MATERIALS HANDLING EQUIPMENT",
      "value": "H939",
      "type": "psc_code"
   },
   {
      "label": "H939 - OTHER QC SVCS/MATERIALS HANDLING EQ",
      "value": "H939",
      "type": "psc_code"
   },
   {
      "label": "H940 - OTHER QC SVCS/ROPE-CABLE-CHAIN",
      "value": "H940",
      "type": "psc_code"
   },
   {
      "label": "H940 - OTHER QC/TEST/INSPECT- ROPE, CABLE, CHAIN, AND FITTINGS",
      "value": "H940",
      "type": "psc_code"
   },
   {
      "label": "H941 - OTHER QC SVCS/REFRIGERATION - AC EQ",
      "value": "H941",
      "type": "psc_code"
   },
   {
      "label": "H941 - OTHER QC/TEST/INSPECT- REFRIGERATION, AIR CONDITIONING, AND AIR CIRCULATING EQUIPMENT",
      "value": "H941",
      "type": "psc_code"
   },
   {
      "label": "H942 - OTHER QC SVCS/FIRE-RESCUE-SAFETY EQ",
      "value": "H942",
      "type": "psc_code"
   },
   {
      "label": "H942 - OTHER QC/TEST/INSPECT- FIRE FIGHTING/RESCUE/SAFETY EQUIPMENT; ENVIRON PROTECT EQUIPMENT/MATLS",
      "value": "H942",
      "type": "psc_code"
   },
   {
      "label": "H943 - OTHER QC SVCS/PUMPS & COMPRESSORS",
      "value": "H943",
      "type": "psc_code"
   },
   {
      "label": "H943 - OTHER QC/TEST/INSPECT- PUMPS AND COMPRESSORS",
      "value": "H943",
      "type": "psc_code"
   },
   {
      "label": "H944 - OTHER QC SVCS/FURNACE-NUCLEAR REAC",
      "value": "H944",
      "type": "psc_code"
   },
   {
      "label": "H944 - OTHER QC/TEST/INSPECT- FURNACE, STEAM PLANT, AND DRYING EQUIPMENT; NUCLEAR REACTORS",
      "value": "H944",
      "type": "psc_code"
   },
   {
      "label": "H945 - OTHER QC SVCS/PLUMBING-HEATING EQ",
      "value": "H945",
      "type": "psc_code"
   },
   {
      "label": "H945 - OTHER QC/TEST/INSPECT- PLUMBING, HEATING, AND WASTE DISPOSAL EQUIPMENT",
      "value": "H945",
      "type": "psc_code"
   },
   {
      "label": "H946 - OTHER QC SVCS/WATER PURIFICATION EQ",
      "value": "H946",
      "type": "psc_code"
   },
   {
      "label": "H946 - OTHER QC/TEST/INSPECT- WATER PURIFICATION AND SEWAGE TREATMENT EQUIPMENT",
      "value": "H946",
      "type": "psc_code"
   },
   {
      "label": "H947 - OTHER QC SVCS/PIPE-TUBING-HOSE",
      "value": "H947",
      "type": "psc_code"
   },
   {
      "label": "H947 - OTHER QC/TEST/INSPECT- PIPE, TUBING, HOSE, AND FITTINGS",
      "value": "H947",
      "type": "psc_code"
   },
   {
      "label": "H948 - OTHER QC SVCS/VALVES",
      "value": "H948",
      "type": "psc_code"
   },
   {
      "label": "H948 - OTHER QC/TEST/INSPECT- VALVES",
      "value": "H948",
      "type": "psc_code"
   },
   {
      "label": "H949 - OTHER QC/TEST/INSPECT- MAINTENANCE AND REPAIR SHOP EQUIPMENT",
      "value": "H949",
      "type": "psc_code"
   },
   {
      "label": "H949 - OTHER QC SVCS/MAINT REPAIR SHOP EQ",
      "value": "H949",
      "type": "psc_code"
   },
   {
      "label": "H951 - OTHER QC/TEST/INSPECT- HAND TOOLS",
      "value": "H951",
      "type": "psc_code"
   },
   {
      "label": "H951 - OTHER QC SVCS/HAND TOOLS",
      "value": "H951",
      "type": "psc_code"
   },
   {
      "label": "H952 - OTHER QC SVCS/MEASURING TOOLS",
      "value": "H952",
      "type": "psc_code"
   },
   {
      "label": "H952 - OTHER QC/TEST/INSPECT- MEASURING TOOLS",
      "value": "H952",
      "type": "psc_code"
   },
   {
      "label": "H953 - OTHER QC SVCS/HARDWARE & ABRASIVES",
      "value": "H953",
      "type": "psc_code"
   },
   {
      "label": "H953 - OTHER QC/TEST/INSPECT- HARDWARE AND ABRASIVES",
      "value": "H953",
      "type": "psc_code"
   },
   {
      "label": "H954 - OTHER QC SVCS/PREFAB STRUCTURES",
      "value": "H954",
      "type": "psc_code"
   },
   {
      "label": "H954 - OTHER QC/TEST/INSPECT- PREFABRICATED STRUCTURES AND SCAFFOLDING",
      "value": "H954",
      "type": "psc_code"
   },
   {
      "label": "H955 - OTHER QC/TEST/INSPECT- LUMBER, MILLWORK, PLYWOOD, AND VENEER",
      "value": "H955",
      "type": "psc_code"
   },
   {
      "label": "H955 - OTHER QC SVCS/LUMBER & MILLWORK",
      "value": "H955",
      "type": "psc_code"
   },
   {
      "label": "H956 - OTHER QC/TEST/INSPECT- CONSTRUCTION AND BUILDING MATERIALS",
      "value": "H956",
      "type": "psc_code"
   },
   {
      "label": "H956 - OTHER QC SVCS/CONTRUCT MATERIAL",
      "value": "H956",
      "type": "psc_code"
   },
   {
      "label": "H958 - OTHER QC SVCS/COMMUNICATION EQ",
      "value": "H958",
      "type": "psc_code"
   },
   {
      "label": "H958 - OTHER QC/TEST/INSPECT- COMMUNICATION, DETECTION, AND COHERENT RADIATION EQUIPMENT",
      "value": "H958",
      "type": "psc_code"
   },
   {
      "label": "H959 - OTHER QC/TEST/INSPECT- ELECTRICAL AND ELECTRONIC EQUIPMENT COMPONENTS",
      "value": "H959",
      "type": "psc_code"
   },
   {
      "label": "H959 - OTHER QC SVCS/ELECT-ELCT EQ",
      "value": "H959",
      "type": "psc_code"
   },
   {
      "label": "H960 - OTHER QC SVCS/FIBER OPTICS",
      "value": "H960",
      "type": "psc_code"
   },
   {
      "label": "H960 - OTHER QC/TEST/INSPECT- FIBER OPTICS MATERIALS, COMPONENTS, ASSEMBLIES, AND ACCESSORIES",
      "value": "H960",
      "type": "psc_code"
   },
   {
      "label": "H961 - OTHER QC/TEST/INSPECT- ELECTRIC WIRE AND POWER DISTRIBUTION EQUIPMENT",
      "value": "H961",
      "type": "psc_code"
   },
   {
      "label": "H961 - OTHER QC SVCS/POWER DISTRIBUTION EQ",
      "value": "H961",
      "type": "psc_code"
   },
   {
      "label": "H962 - OTHER QC SVCS/LIGHTING FIXTURES",
      "value": "H962",
      "type": "psc_code"
   },
   {
      "label": "H962 - OTHER QC/TEST/INSPECT- LIGHTING FIXTURES AND LAMPS",
      "value": "H962",
      "type": "psc_code"
   },
   {
      "label": "H963 - OTHER QC SVCS/ALARM & SIGNAL SYSTEM",
      "value": "H963",
      "type": "psc_code"
   },
   {
      "label": "H963 - OTHER QC/TEST/INSPECT- ALARM, SIGNAL, AND SECURITY DETECTION SYSTEMS",
      "value": "H963",
      "type": "psc_code"
   },
   {
      "label": "H965 - OTHER QC SVCS/MEDICAL-DENTAL-VET EQ",
      "value": "H965",
      "type": "psc_code"
   },
   {
      "label": "H965 - OTHER QC/TEST/INSPECT- MEDICAL, DENTAL, AND VETERINARY EQUIPMENT AND SUPPLIES",
      "value": "H965",
      "type": "psc_code"
   },
   {
      "label": "H966 - OTHER QC/TEST/INSPECT- INSTRUMENTS AND LABORATORY EQUIPMENT",
      "value": "H966",
      "type": "psc_code"
   },
   {
      "label": "H966 - OTHER QC SVCS/INSTRUMENTS & LAB EQ",
      "value": "H966",
      "type": "psc_code"
   },
   {
      "label": "H967 - OTHER QC SVCS/PHOTOGRAPHIC EQ",
      "value": "H967",
      "type": "psc_code"
   },
   {
      "label": "H967 - OTHER QC/TEST/INSPECT- PHOTOGRAPHIC EQUIPMENT",
      "value": "H967",
      "type": "psc_code"
   },
   {
      "label": "H968 - OTHER QC SVCS/CHEMICAL PRODUCTS",
      "value": "H968",
      "type": "psc_code"
   },
   {
      "label": "H968 - OTHER QC/TEST/INSPECT- CHEMICALS AND CHEMICAL PRODUCTS",
      "value": "H968",
      "type": "psc_code"
   },
   {
      "label": "H969 - OTHER QC SVCS/TRAINING AIDS-DEVICES",
      "value": "H969",
      "type": "psc_code"
   },
   {
      "label": "H969 - OTHER QC/TEST/INSPECT- TRAINING AIDS AND DEVICES",
      "value": "H969",
      "type": "psc_code"
   },
   {
      "label": "H970 - OTHER QC SVCS/ADP EQ & SUPPLIES",
      "value": "H970",
      "type": "psc_code"
   },
   {
      "label": "H970 - OTHER QC/TEST/INSPECT- ADP EQUIPMENT/SOFTWARE/SUPPLIES/SUPPORT EQUIPMENT",
      "value": "H970",
      "type": "psc_code"
   },
   {
      "label": "H971 - OTHER QC SVCS/FURNITURE",
      "value": "H971",
      "type": "psc_code"
   },
   {
      "label": "H971 - OTHER QC/TEST/INSPECT- FURNITURE",
      "value": "H971",
      "type": "psc_code"
   },
   {
      "label": "H972 - OTHER QC SVCS/HOUSEHOLD FURNISHINGS",
      "value": "H972",
      "type": "psc_code"
   },
   {
      "label": "H972 - OTHER QC/TEST/INSPECT- HOUSEHOLD AND COMMERCIAL FURNISHINGS AND APPLIANCES",
      "value": "H972",
      "type": "psc_code"
   },
   {
      "label": "H973 - OTHER QC SVCS/FOOD PREP-SERVING EQ",
      "value": "H973",
      "type": "psc_code"
   },
   {
      "label": "H973 - OTHER QC/TEST/INSPECT- FOOD PREPARATION AND SERVING EQUIPMENT",
      "value": "H973",
      "type": "psc_code"
   },
   {
      "label": "H974 - OTHER QC SVCS/OFFICE MACHINES",
      "value": "H974",
      "type": "psc_code"
   },
   {
      "label": "H974 - OTHER QC/TEST/INSPECT- OFFICE MACHINES, TEXT PROCESSING SYSTEMS, AND VISIBLE RECORD EQUIPMENT",
      "value": "H974",
      "type": "psc_code"
   },
   {
      "label": "H975 - OTHER QC SVCS/OFFICE SUPPLIES",
      "value": "H975",
      "type": "psc_code"
   },
   {
      "label": "H975 - OTHER QC/TEST/INSPECT- OFFICE SUPPLIES AND DEVICES",
      "value": "H975",
      "type": "psc_code"
   },
   {
      "label": "H976 - OTHER QC SVCS/BOOKS-MAPS-PUBS",
      "value": "H976",
      "type": "psc_code"
   },
   {
      "label": "H976 - OTHER QC/TEST/INSPECT- BOOKS, MAPS, AND OTHER PUBLICATIONS",
      "value": "H976",
      "type": "psc_code"
   },
   {
      "label": "H977 - OTHER QC SVCS/MUSICAL INST-RADIO-TV",
      "value": "H977",
      "type": "psc_code"
   },
   {
      "label": "H977 - OTHER QC/TEST/INSPECT- MUSICAL INST/PHONOGRAPH/HOME RADIO",
      "value": "H977",
      "type": "psc_code"
   },
   {
      "label": "H978 - OTHER QC SVCS/RECREATIONAL EQ",
      "value": "H978",
      "type": "psc_code"
   },
   {
      "label": "H978 - OTHER QC/TEST/INSPECT- RECREATIONAL AND ATHLETIC EQUIPMENT",
      "value": "H978",
      "type": "psc_code"
   },
   {
      "label": "H979 - OTHER QC/TEST/INSPECT- CLEANING EQUIPMENT AND SUPPLIES",
      "value": "H979",
      "type": "psc_code"
   },
   {
      "label": "H979 - OTHER QC SVCS/CLEANING EQ",
      "value": "H979",
      "type": "psc_code"
   },
   {
      "label": "H980 - OTHER QC/TEST/INSPECT- BRUSHES, PAINTS, SEALERS, AND ADHESIVES",
      "value": "H980",
      "type": "psc_code"
   },
   {
      "label": "H980 - OTHER QC SVCS/BRUSHES-PAINTS-SEALER",
      "value": "H980",
      "type": "psc_code"
   },
   {
      "label": "H981 - OTHER QC SVCS/CONTAINERS-PACKAGING",
      "value": "H981",
      "type": "psc_code"
   },
   {
      "label": "H981 - OTHER QC/TEST/INSPECT- CONTAINERS, PACKAGING, AND PACKING SUPPLIES",
      "value": "H981",
      "type": "psc_code"
   },
   {
      "label": "H983 - OTHER QC SVCS/TEXTILES-LEATHER-FURS",
      "value": "H983",
      "type": "psc_code"
   },
   {
      "label": "H983 - OTHER QC/TEST/INSPECT- TEXTILES, LEATHER, FURS, APPAREL AND SHOE FINDINGS, TENTS AND FLAGS",
      "value": "H983",
      "type": "psc_code"
   },
   {
      "label": "H984 - OTHER QC SVCS/CLOTHING - INDIVID EQ",
      "value": "H984",
      "type": "psc_code"
   },
   {
      "label": "H984 - OTHER QC/TEST/INSPECT- CLOTHING, INDIVIDUAL EQUIPMENT, AND INSIGNIA",
      "value": "H984",
      "type": "psc_code"
   },
   {
      "label": "H985 - OTHER QC/TEST/INSPECT- TOILETRIES",
      "value": "H985",
      "type": "psc_code"
   },
   {
      "label": "H985 - OTHER QC SVCS/TOILETRIES",
      "value": "H985",
      "type": "psc_code"
   },
   {
      "label": "H987 - OTHER QC/TEST/INSPECT- AGRICULTURAL SUPPLIES",
      "value": "H987",
      "type": "psc_code"
   },
   {
      "label": "H987 - OTHER QC SVCS/AGRICULTURAL SUPPLIES",
      "value": "H987",
      "type": "psc_code"
   },
   {
      "label": "H988 - OTHER QC SVCS/LIVE ANIMALS",
      "value": "H988",
      "type": "psc_code"
   },
   {
      "label": "H988 - OTHER QC/TEST/INSPECT- LIVE ANIMALS",
      "value": "H988",
      "type": "psc_code"
   },
   {
      "label": "H989 - OTHER QC/TEST/INSPECT- SUBSISTENCE",
      "value": "H989",
      "type": "psc_code"
   },
   {
      "label": "H989 - OTHER QC SVCS/SUBSISTENCE",
      "value": "H989",
      "type": "psc_code"
   },
   {
      "label": "H991 - OTHER QC SVCS/FUELS-LUBRICANTS-OILS",
      "value": "H991",
      "type": "psc_code"
   },
   {
      "label": "H991 - OTHER QC/TEST/INSPECT- FUELS, LUBRICANTS, OILS, AND WAXES",
      "value": "H991",
      "type": "psc_code"
   },
   {
      "label": "H993 - OTHER QC SVCS/NONMETALIC FAB MAT",
      "value": "H993",
      "type": "psc_code"
   },
   {
      "label": "H993 - OTHER QC/TEST/INSPECT- NONMETALLIC FABRICATED MATERIALS",
      "value": "H993",
      "type": "psc_code"
   },
   {
      "label": "H994 - OTHER QC/TEST/INSPECT- NONMETALLIC CRUDE MATERIALS",
      "value": "H994",
      "type": "psc_code"
   },
   {
      "label": "H994 - OTHER QC SVCS/NONMETALIC CRUDE MAT",
      "value": "H994",
      "type": "psc_code"
   },
   {
      "label": "H995 - OTHER QC SVCS/METAL BARS & SHEETS",
      "value": "H995",
      "type": "psc_code"
   },
   {
      "label": "H995 - OTHER QC/TEST/INSPECT- METAL BARS, SHEETS, AND SHAPES",
      "value": "H995",
      "type": "psc_code"
   },
   {
      "label": "H996 - OTHER QC/TEST/INSPECT- ORES, MINERALS, AND THEIR PRIMARY PRODUCTS",
      "value": "H996",
      "type": "psc_code"
   },
   {
      "label": "H996 - OTHER QC SVCS/ORES & MINERALS",
      "value": "H996",
      "type": "psc_code"
   },
   {
      "label": "H999 - OTHER QC/TEST/INSPECT- MISCELLANEOUS",
      "value": "H999",
      "type": "psc_code"
   },
   {
      "label": "H999 - MISC TEST & INSPECT SVC",
      "value": "H999",
      "type": "psc_code"
   },
   {
      "label": "J - MAINT, REPAIR, REBUILD EQUIPMENT",
      "value": "J",
      "type": "psc_code"
   },
   {
      "label": "J0 - MAINT, REPAIR, REBUILD OF EQUIPMENT",
      "value": "J0",
      "type": "psc_code"
   },
   {
      "label": "J010 - MAINT/REPAIR/REBUILD OF EQUIPMENT- WEAPONS",
      "value": "J010",
      "type": "psc_code"
   },
   {
      "label": "J010 - MAINT-REP OF WEAPONS",
      "value": "J010",
      "type": "psc_code"
   },
   {
      "label": "J011 - MAINT/REPAIR/REBUILD OF EQUIPMENT- NUCLEAR ORDNANCE",
      "value": "J011",
      "type": "psc_code"
   },
   {
      "label": "J011 - MAINT-REP OF NUCLEAR ORDNANCE",
      "value": "J011",
      "type": "psc_code"
   },
   {
      "label": "J012 - MAINT/REPAIR/REBUILD OF EQUIPMENT- FIRE CONTROL EQUIPMENT",
      "value": "J012",
      "type": "psc_code"
   },
   {
      "label": "J012 - MAINT-REP OF FIRE CONT EQ",
      "value": "J012",
      "type": "psc_code"
   },
   {
      "label": "J013 - MAINT/REPAIR/REBUILD OF EQUIPMENT- AMMUNITION AND EXPLOSIVES",
      "value": "J013",
      "type": "psc_code"
   },
   {
      "label": "J013 - MAINT-REP OF AMMO & EXPLOSIVES",
      "value": "J013",
      "type": "psc_code"
   },
   {
      "label": "J014 - MAINT/REPAIR/REBUILD OF EQUIPMENT- GUIDED MISSILES",
      "value": "J014",
      "type": "psc_code"
   },
   {
      "label": "J014 - MAINT-REP OF GUIDED MISSILES",
      "value": "J014",
      "type": "psc_code"
   },
   {
      "label": "J015 - MAINT/REPAIR/REBUILD OF EQUIPMENT- AIRCRAFT AND AIRFRAME STRUCTURAL COMPONENTS",
      "value": "J015",
      "type": "psc_code"
   },
   {
      "label": "J015 - MAINT-REP OF AIRCRAFT",
      "value": "J015",
      "type": "psc_code"
   },
   {
      "label": "J016 - MAINT/REPAIR/REBUILD OF EQUIPMENT- AIRCRAFT COMPONENTS AND ACCESSORIES",
      "value": "J016",
      "type": "psc_code"
   },
   {
      "label": "J016 - MAINT-REP OF AIRCRAFT COMPONENTS",
      "value": "J016",
      "type": "psc_code"
   },
   {
      "label": "J017 - MAINT/REPAIR/REBUILD OF EQUIPMENT- AIRCRAFT LAUNCHING, LANDING, AND GROUND HANDLING EQUIPMENT",
      "value": "J017",
      "type": "psc_code"
   },
   {
      "label": "J017 - MAINT-REP OF AIRCRAFT GROUND EQ",
      "value": "J017",
      "type": "psc_code"
   },
   {
      "label": "J018 - MAINT/REPAIR/REBUILD OF EQUIPMENT- SPACE VEHICLES",
      "value": "J018",
      "type": "psc_code"
   },
   {
      "label": "J018 - MAINT-REP OF SPACE VEHICLES",
      "value": "J018",
      "type": "psc_code"
   },
   {
      "label": "J019 - MAINT-REP OF SHIPS-SML CRAFT-DOCKS",
      "value": "J019",
      "type": "psc_code"
   },
   {
      "label": "J019 - MAINT/REPAIR/REBUILD OF EQUIPMENT- SHIPS, SMALL CRAFT, PONTOONS, AND FLOATING DOCKS",
      "value": "J019",
      "type": "psc_code"
   },
   {
      "label": "J020 - MAINT-REP OF SHIP & MARINE EQ",
      "value": "J020",
      "type": "psc_code"
   },
   {
      "label": "J020 - MAINT/REPAIR/REBUILD OF EQUIPMENT- SHIP AND MARINE EQUIPMENT",
      "value": "J020",
      "type": "psc_code"
   },
   {
      "label": "J022 - MAINT/REPAIR/REBUILD OF EQUIPMENT- RAILWAY EQUIPMENT",
      "value": "J022",
      "type": "psc_code"
   },
   {
      "label": "J022 - MAINT-REP OF RAILWAY EQ",
      "value": "J022",
      "type": "psc_code"
   },
   {
      "label": "J023 - MAINT/REPAIR/REBUILD OF EQUIPMENT- GROUND EFFECT VEHICLES, MOTOR VEHICLES, TRAILERS, AND CYCLES",
      "value": "J023",
      "type": "psc_code"
   },
   {
      "label": "J023 - MAINT-REP OF VEHICLES-TRAILERS-CYC",
      "value": "J023",
      "type": "psc_code"
   },
   {
      "label": "J024 - MAINT/REPAIR/REBUILD OF EQUIPMENT- TRACTORS",
      "value": "J024",
      "type": "psc_code"
   },
   {
      "label": "J024 - MAINT-REP OF TRACTORS",
      "value": "J024",
      "type": "psc_code"
   },
   {
      "label": "J025 - MAINT-REP OF VEHICULAR EQ",
      "value": "J025",
      "type": "psc_code"
   },
   {
      "label": "J025 - MAINT/REPAIR/REBUILD OF EQUIPMENT- VEHICULAR EQUIPMENT COMPONENTS",
      "value": "J025",
      "type": "psc_code"
   },
   {
      "label": "J026 - MAINT/REPAIR/REBUILD OF EQUIPMENT- TIRES AND TUBES",
      "value": "J026",
      "type": "psc_code"
   },
   {
      "label": "J026 - MAINT-REP OF TIRES & TUBES",
      "value": "J026",
      "type": "psc_code"
   },
   {
      "label": "J028 - MAINT/REPAIR/REBUILD OF EQUIPMENT- ENGINES, TURBINES, AND COMPONENTS",
      "value": "J028",
      "type": "psc_code"
   },
   {
      "label": "J028 - MAINT-REP OF ENGINES & TURBINES",
      "value": "J028",
      "type": "psc_code"
   },
   {
      "label": "J029 - MAINT-REP OF ENGINE ACCESSORIES",
      "value": "J029",
      "type": "psc_code"
   },
   {
      "label": "J029 - MAINT/REPAIR/REBUILD OF EQUIPMENT- ENGINE ACCESSORIES",
      "value": "J029",
      "type": "psc_code"
   },
   {
      "label": "J030 - MAINT-REP OF MECH POWER TRANS EQ",
      "value": "J030",
      "type": "psc_code"
   },
   {
      "label": "J030 - MAINT/REPAIR/REBUILD OF EQUIPMENT- MECHANICAL POWER TRANSMISSION EQUIPMENT",
      "value": "J030",
      "type": "psc_code"
   },
   {
      "label": "J031 - MAINT/REPAIR/REBUILD OF EQUIPMENT- BEARINGS",
      "value": "J031",
      "type": "psc_code"
   },
   {
      "label": "J031 - MAINT-REP OF BEARINGS",
      "value": "J031",
      "type": "psc_code"
   },
   {
      "label": "J032 - MAINT/REPAIR/REBUILD OF EQUIPMENT- WOODWORKING MACHINERY AND EQUIPMENT",
      "value": "J032",
      "type": "psc_code"
   },
   {
      "label": "J032 - MAINT-REP OF WOODWORKING MACHINE",
      "value": "J032",
      "type": "psc_code"
   },
   {
      "label": "J034 - MAINT-REP OF METALWORK MACHINE",
      "value": "J034",
      "type": "psc_code"
   },
   {
      "label": "J034 - MAINT/REPAIR/REBUILD OF EQUIPMENT- METALWORKING MACHINERY",
      "value": "J034",
      "type": "psc_code"
   },
   {
      "label": "J035 - MAINT-REP OF SERVICE & TRADE EQ",
      "value": "J035",
      "type": "psc_code"
   },
   {
      "label": "J035 - MAINT/REPAIR/REBUILD OF EQUIPMENT- SERVICE AND TRADE EQUIPMENT",
      "value": "J035",
      "type": "psc_code"
   },
   {
      "label": "J036 - MAINT-REP OF SP INDUSTRY MACHINERY",
      "value": "J036",
      "type": "psc_code"
   },
   {
      "label": "J036 - MAINT/REPAIR/REBUILD OF EQUIPMENT- SPECIAL INDUSTRY MACHINERY",
      "value": "J036",
      "type": "psc_code"
   },
   {
      "label": "J037 - MAINT-REP OF AGRICULTURE MACHINE",
      "value": "J037",
      "type": "psc_code"
   },
   {
      "label": "J037 - MAINT/REPAIR/REBUILD OF EQUIPMENT- AGRICULTURAL MACHINERY AND EQUIPMENT",
      "value": "J037",
      "type": "psc_code"
   },
   {
      "label": "J038 - MAINT/REPAIR/REBUILD OF EQUIPMENT- CONSTRUCTION/MINING/EXCAVATING/HIGHWAY MAINTENANCE EQUIPMENT",
      "value": "J038",
      "type": "psc_code"
   },
   {
      "label": "J038 - MAINT-REP OF CONTRUCT EQ",
      "value": "J038",
      "type": "psc_code"
   },
   {
      "label": "J039 - MAINT-REP OF MATERIALS HANDLING EQ",
      "value": "J039",
      "type": "psc_code"
   },
   {
      "label": "J039 - MAINT/REPAIR/REBUILD OF EQUIPMENT- MATERIALS HANDLING EQUIPMENT",
      "value": "J039",
      "type": "psc_code"
   },
   {
      "label": "J040 - MAINT/REPAIR/REBUILD OF EQUIPMENT- ROPE, CABLE, CHAIN, AND FITTINGS",
      "value": "J040",
      "type": "psc_code"
   },
   {
      "label": "J040 - MAINT-REP OF ROPE-CABLE-CHAIN",
      "value": "J040",
      "type": "psc_code"
   },
   {
      "label": "J041 - MAINT/REPAIR/REBUILD OF EQUIPMENT- REFRIGERATION, AIR CONDITIONING, AND AIR CIRCULATING EQUIPMENT",
      "value": "J041",
      "type": "psc_code"
   },
   {
      "label": "J041 - MAINT-REP OF REFRIGERATION - AC EQ",
      "value": "J041",
      "type": "psc_code"
   },
   {
      "label": "J042 - MAINT/REPAIR/REBUILD OF EQUIP- FIRE FIGHTING/RESCUE/SAFETY EQUIP; ENVIRON PROTECT EQUIP/MATLS",
      "value": "J042",
      "type": "psc_code"
   },
   {
      "label": "J042 - MAINT-REP OF FIRE-RESCUE-SAFETY EQ",
      "value": "J042",
      "type": "psc_code"
   },
   {
      "label": "J043 - MAINT/REPAIR/REBUILD OF EQUIPMENT- PUMPS AND COMPRESSORS",
      "value": "J043",
      "type": "psc_code"
   },
   {
      "label": "J043 - MAINT-REP OF PUMPS & COMPRESSORS",
      "value": "J043",
      "type": "psc_code"
   },
   {
      "label": "J044 - MAINT/REPAIR/REBUILD OF EQUIPMENT- FURNACE, STEAM PLANT, AND DRYING EQUIPMENT; NUCLEAR REACTORS",
      "value": "J044",
      "type": "psc_code"
   },
   {
      "label": "J044 - MAINT-REP OF FURNACE-NUCLEAR REAC",
      "value": "J044",
      "type": "psc_code"
   },
   {
      "label": "J045 - MAINT/REPAIR/REBUILD OF EQUIPMENT- PLUMBING, HEATING, AND WASTE DISPOSAL EQUIPMENT",
      "value": "J045",
      "type": "psc_code"
   },
   {
      "label": "J045 - MAINT-REP OF PLUMBING-HEATING EQ",
      "value": "J045",
      "type": "psc_code"
   },
   {
      "label": "J046 - MAINT/REPAIR/REBUILD OF EQUIPMENT- WATER PURIFICATION AND SEWAGE TREATMENT EQUIPMENT",
      "value": "J046",
      "type": "psc_code"
   },
   {
      "label": "J046 - MAINT-REP OF WATER PURIFICATION EQ",
      "value": "J046",
      "type": "psc_code"
   },
   {
      "label": "J047 - MAINT/REPAIR/REBUILD OF EQUIPMENT- PIPE, TUBING, HOSE, AND FITTINGS",
      "value": "J047",
      "type": "psc_code"
   },
   {
      "label": "J047 - MAINT-REP OF PIPE-TUBING-HOSE",
      "value": "J047",
      "type": "psc_code"
   },
   {
      "label": "J048 - MAINT/REPAIR/REBUILD OF EQUIPMENT- VALVES",
      "value": "J048",
      "type": "psc_code"
   },
   {
      "label": "J048 - MAINT-REP OF VALVES",
      "value": "J048",
      "type": "psc_code"
   },
   {
      "label": "J049 - MAINT-REP OF MAINT REPAIR SHOP EQ",
      "value": "J049",
      "type": "psc_code"
   },
   {
      "label": "J049 - MAINT/REPAIR/REBUILD OF EQUIPMENT- MAINTENANCE AND REPAIR SHOP EQUIPMENT",
      "value": "J049",
      "type": "psc_code"
   },
   {
      "label": "J051 - MAINT-REP OF HAND TOOLS",
      "value": "J051",
      "type": "psc_code"
   },
   {
      "label": "J051 - MAINT/REPAIR/REBUILD OF EQUIPMENT- HAND TOOLS",
      "value": "J051",
      "type": "psc_code"
   },
   {
      "label": "J052 - MAINT/REPAIR/REBUILD OF EQUIPMENT- MEASURING TOOLS",
      "value": "J052",
      "type": "psc_code"
   },
   {
      "label": "J052 - MAINT-REP OF MEASURING TOOLS",
      "value": "J052",
      "type": "psc_code"
   },
   {
      "label": "J053 - MAINT/REPAIR/REBUILD OF EQUIPMENT- HARDWARE AND ABRASIVES",
      "value": "J053",
      "type": "psc_code"
   },
   {
      "label": "J053 - MAINT-REP OF HARDWARE & ABRASIVES",
      "value": "J053",
      "type": "psc_code"
   },
   {
      "label": "J054 - MAINT/REPAIR/REBUILD OF EQUIPMENT- PREFABRICATED STRUCTURES AND SCAFFOLDING",
      "value": "J054",
      "type": "psc_code"
   },
   {
      "label": "J054 - MAINT-REP OF PREFAB STRUCTURES",
      "value": "J054",
      "type": "psc_code"
   },
   {
      "label": "J055 - MAINT-REP OF LUMBER & MILLWORK",
      "value": "J055",
      "type": "psc_code"
   },
   {
      "label": "J055 - MAINT/REPAIR/REBUILD OF EQUIPMENT- LUMBER, MILLWORK, PLYWOOD, AND VENEER",
      "value": "J055",
      "type": "psc_code"
   },
   {
      "label": "J056 - MAINT-REP OF CONTRUCT MATERIAL",
      "value": "J056",
      "type": "psc_code"
   },
   {
      "label": "J056 - MAINT/REPAIR/REBUILD OF EQUIPMENT- CONSTRUCTION AND BUILDING MATERIALS",
      "value": "J056",
      "type": "psc_code"
   },
   {
      "label": "J058 - MAINT/REPAIR/REBUILD OF EQUIPMENT- COMMUNICATION, DETECTION, AND COHERENT RADIATION EQUIPMENT",
      "value": "J058",
      "type": "psc_code"
   },
   {
      "label": "J058 - MAINT-REP OF COMMUNICATION EQ",
      "value": "J058",
      "type": "psc_code"
   },
   {
      "label": "J059 - MAINT-REP OF ELECT-ELCT EQ",
      "value": "J059",
      "type": "psc_code"
   },
   {
      "label": "J059 - MAINT/REPAIR/REBUILD OF EQUIPMENT- ELECTRICAL AND ELECTRONIC EQUIPMENT COMPONENTS",
      "value": "J059",
      "type": "psc_code"
   },
   {
      "label": "J060 - MAINT-REP OF FIBER OPTICS MATER",
      "value": "J060",
      "type": "psc_code"
   },
   {
      "label": "J060 - MAINT/REPAIR/REBUILD OF EQUIPMENT- FIBER OPTICS MATERIALS, COMPONENTS, ASSEMBLIES, AND ACCESSORIES",
      "value": "J060",
      "type": "psc_code"
   },
   {
      "label": "J061 - MAINT-REP OF POWER DISTRIBUTION EQ",
      "value": "J061",
      "type": "psc_code"
   },
   {
      "label": "J061 - MAINT/REPAIR/REBUILD OF EQUIPMENT- ELECTRIC WIRE AND POWER DISTRIBUTION EQUIPMENT",
      "value": "J061",
      "type": "psc_code"
   },
   {
      "label": "J062 - MAINT/REPAIR/REBUILD OF EQUIPMENT- LIGHTING FIXTURES AND LAMPS",
      "value": "J062",
      "type": "psc_code"
   },
   {
      "label": "J062 - MAINT-REP OF LIGHTING FIXTURES",
      "value": "J062",
      "type": "psc_code"
   },
   {
      "label": "J063 - MAINT/REPAIR/REBUILD OF EQUIPMENT- ALARM, SIGNAL, AND SECURITY DETECTION SYSTEMS",
      "value": "J063",
      "type": "psc_code"
   },
   {
      "label": "J063 - MAINT-REP OF ALARM & SIGNAL SYSTEM",
      "value": "J063",
      "type": "psc_code"
   },
   {
      "label": "J065 - MAINT/REPAIR/REBUILD OF EQUIPMENT- MEDICAL, DENTAL, AND VETERINARY EQUIPMENT AND SUPPLIES",
      "value": "J065",
      "type": "psc_code"
   },
   {
      "label": "J065 - MAINT-REP OF MEDICAL-DENTAL-VET EQ",
      "value": "J065",
      "type": "psc_code"
   },
   {
      "label": "J066 - MAINT/REPAIR/REBUILD OF EQUIPMENT- INSTRUMENTS AND LABORATORY EQUIPMENT",
      "value": "J066",
      "type": "psc_code"
   },
   {
      "label": "J066 - MAINT-REP OF INSTRUMENTS & LAB EQ",
      "value": "J066",
      "type": "psc_code"
   },
   {
      "label": "J067 - MAINT/REPAIR/REBUILD OF EQUIPMENT- PHOTOGRAPHIC EQUIPMENT",
      "value": "J067",
      "type": "psc_code"
   },
   {
      "label": "J067 - MAINT-REP OF PHOTOGRAPHIC EQ",
      "value": "J067",
      "type": "psc_code"
   },
   {
      "label": "J068 - MAINT-REP OF CHEMICAL PRODUCTS",
      "value": "J068",
      "type": "psc_code"
   },
   {
      "label": "J068 - MAINT/REPAIR/REBUILD OF EQUIPMENT- CHEMICALS AND CHEMICAL PRODUCTS",
      "value": "J068",
      "type": "psc_code"
   },
   {
      "label": "J069 - MAINT/REPAIR/REBUILD OF EQUIPMENT- TRAINING AIDS AND DEVICES",
      "value": "J069",
      "type": "psc_code"
   },
   {
      "label": "J069 - MAINT-REP OF TRAINING AIDS-DEVICES",
      "value": "J069",
      "type": "psc_code"
   },
   {
      "label": "J070 - MAINT/REPAIR/REBUILD OF EQUIPMENT- ADP EQUIPMENT/SOFTWARE/SUPPLIES/SUPPORT EQUIPMENT",
      "value": "J070",
      "type": "psc_code"
   },
   {
      "label": "J070 - MAINT-REP OF ADP EQ & SUPPLIES",
      "value": "J070",
      "type": "psc_code"
   },
   {
      "label": "J071 - MAINT-REP OF FURNITURE",
      "value": "J071",
      "type": "psc_code"
   },
   {
      "label": "J071 - MAINT/REPAIR/REBUILD OF EQUIPMENT- FURNITURE",
      "value": "J071",
      "type": "psc_code"
   },
   {
      "label": "J072 - MAINT-REP OF HOUSEHOLD FURNISHINGS",
      "value": "J072",
      "type": "psc_code"
   },
   {
      "label": "J072 - MAINT/REPAIR/REBUILD OF EQUIPMENT- HOUSEHOLD AND COMMERCIAL FURNISHINGS AND APPLIANCES",
      "value": "J072",
      "type": "psc_code"
   },
   {
      "label": "J073 - MAINT/REPAIR/REBUILD OF EQUIPMENT- FOOD PREPARATION AND SERVING EQUIPMENT",
      "value": "J073",
      "type": "psc_code"
   },
   {
      "label": "J073 - MAINT-REP OF FOOD PREP-SERVING EQ",
      "value": "J073",
      "type": "psc_code"
   },
   {
      "label": "J074 - MAINT/REPAIR/REBUILD OF EQUIPMENT- OFFICE MACHINES/TEXT PROCESSING SYS/VISIBLE RECORD EQUIPMENT",
      "value": "J074",
      "type": "psc_code"
   },
   {
      "label": "J074 - MAINT-REP OF OFFICE MACHINES",
      "value": "J074",
      "type": "psc_code"
   },
   {
      "label": "J075 - MAINT/REPAIR/REBUILD OF EQUIPMENT- OFFICE SUPPLIES AND DEVICES",
      "value": "J075",
      "type": "psc_code"
   },
   {
      "label": "J075 - MAINT-REP OF OFFICE SUPPLIES",
      "value": "J075",
      "type": "psc_code"
   },
   {
      "label": "J076 - MAINT-REP OF BOOKS-MAPS-PUBS",
      "value": "J076",
      "type": "psc_code"
   },
   {
      "label": "J076 - MAINT/REPAIR/REBUILD OF EQUIPMENT- BOOKS, MAPS, AND OTHER PUBLICATIONS",
      "value": "J076",
      "type": "psc_code"
   },
   {
      "label": "J077 - MAINT/REPAIR/REBUILD OF EQUIPMENT- MUSICAL INST/PHONOGRAPH/HOME RADIO",
      "value": "J077",
      "type": "psc_code"
   },
   {
      "label": "J077 - MAINT-REP OF MUSICAL INST-RADIO-TV",
      "value": "J077",
      "type": "psc_code"
   },
   {
      "label": "J078 - MAINT-REP OF RECREATIONAL EQ",
      "value": "J078",
      "type": "psc_code"
   },
   {
      "label": "J078 - MAINT/REPAIR/REBUILD OF EQUIPMENT- RECREATIONAL AND ATHLETIC EQUIPMENT",
      "value": "J078",
      "type": "psc_code"
   },
   {
      "label": "J079 - MAINT/REPAIR/REBUILD OF EQUIPMENT- CLEANING EQUIPMENT AND SUPPLIES",
      "value": "J079",
      "type": "psc_code"
   },
   {
      "label": "J079 - MAINT-REP OF CLEANING EQ",
      "value": "J079",
      "type": "psc_code"
   },
   {
      "label": "J080 - MAINT-REP OF BRUSHES-PAINTS-SEALER",
      "value": "J080",
      "type": "psc_code"
   },
   {
      "label": "J080 - MAINT/REPAIR/REBUILD OF EQUIPMENT- BRUSHES, PAINTS, SEALERS, AND ADHESIVES",
      "value": "J080",
      "type": "psc_code"
   },
   {
      "label": "J081 - MAINT-REP OF CONTAINERS-PACKAGING",
      "value": "J081",
      "type": "psc_code"
   },
   {
      "label": "J081 - MAINT/REPAIR/REBUILD OF EQUIPMENT- CONTAINERS, PACKAGING, AND PACKING SUPPLIES",
      "value": "J081",
      "type": "psc_code"
   },
   {
      "label": "J083 - MAINT-REP OF TEXTILES-LEATHER-FURS",
      "value": "J083",
      "type": "psc_code"
   },
   {
      "label": "J083 - MAINT/REPAIR/REBUILD OF EQUIPMENT- TEXTILES, LEATHER, FURS, APPAREL/SHOE FINDINGS, TENTS/FLAGS",
      "value": "J083",
      "type": "psc_code"
   },
   {
      "label": "J084 - MAINT-REP OF CLOTHING - INDIVID EQ",
      "value": "J084",
      "type": "psc_code"
   },
   {
      "label": "J084 - MAINT/REPAIR/REBUILD OF EQUIPMENT- CLOTHING, INDIVIDUAL EQUIPMENT, AND INSIGNIA",
      "value": "J084",
      "type": "psc_code"
   },
   {
      "label": "J085 - MAINT/REPAIR/REBUILD OF EQUIPMENT- TOILETRIES",
      "value": "J085",
      "type": "psc_code"
   },
   {
      "label": "J085 - MAINT-REP OF TOILETRIES",
      "value": "J085",
      "type": "psc_code"
   },
   {
      "label": "J087 - MAINT/REPAIR/REBUILD OF EQUIPMENT- AGRICULTURAL SUPPLIES",
      "value": "J087",
      "type": "psc_code"
   },
   {
      "label": "J087 - MAINT-REP OF AGRICULTURAL SUPPLIES",
      "value": "J087",
      "type": "psc_code"
   },
   {
      "label": "J088 - MAINT/REPAIR/REBUILD OF EQUIPMENT- LIVE ANIMALS",
      "value": "J088",
      "type": "psc_code"
   },
   {
      "label": "J088 - MAINT-REP OF LIVE ANIMALS",
      "value": "J088",
      "type": "psc_code"
   },
   {
      "label": "J089 - MAINT-REP OF SUBSISTENCE",
      "value": "J089",
      "type": "psc_code"
   },
   {
      "label": "J089 - MAINT/REPAIR/REBUILD OF EQUIPMENT- SUBSISTENCE",
      "value": "J089",
      "type": "psc_code"
   },
   {
      "label": "J091 - MAINT/REPAIR/REBUILD OF EQUIPMENT- FUELS, LUBRICANTS, OILS, AND WAXES",
      "value": "J091",
      "type": "psc_code"
   },
   {
      "label": "J091 - MAINT-REP OF FUELS-LUBRICANTS-OILS",
      "value": "J091",
      "type": "psc_code"
   },
   {
      "label": "J093 - MAINT/REPAIR/REBUILD OF EQUIPMENT- NONMETALLIC FABRICATED MATERIALS",
      "value": "J093",
      "type": "psc_code"
   },
   {
      "label": "J093 - MAINT-REP OF NONMETALIC FAB MAT",
      "value": "J093",
      "type": "psc_code"
   },
   {
      "label": "J094 - MAINT-REP OF NONMETALIC CRUDE MAT",
      "value": "J094",
      "type": "psc_code"
   },
   {
      "label": "J094 - MAINT/REPAIR/REBUILD OF EQUIPMENT- NONMETALLIC CRUDE MATERIALS",
      "value": "J094",
      "type": "psc_code"
   },
   {
      "label": "J095 - MAINT/REPAIR/REBUILD OF EQUIPMENT- METAL BARS, SHEETS, AND SHAPES",
      "value": "J095",
      "type": "psc_code"
   },
   {
      "label": "J095 - MAINT-REP OF METAL BARS & SHEETS",
      "value": "J095",
      "type": "psc_code"
   },
   {
      "label": "J096 - MAINT-REP OF ORES & MINERALS",
      "value": "J096",
      "type": "psc_code"
   },
   {
      "label": "J096 - MAINT/REPAIR/REBUILD OF EQUIPMENT- ORES, MINERALS, AND THEIR PRIMARY PRODUCTS",
      "value": "J096",
      "type": "psc_code"
   },
   {
      "label": "J099 - MAINT/REPAIR/REBUILD OF EQUIPMENT- MISCELLANEOUS",
      "value": "J099",
      "type": "psc_code"
   },
   {
      "label": "J099 - MAINT-REP OF MISC EQ",
      "value": "J099",
      "type": "psc_code"
   },
   {
      "label": "J9 - NON-NUCLEAR SHIP REPAIR",
      "value": "J9",
      "type": "psc_code"
   },
   {
      "label": "J998 - NON-NUCLEAR SHIP REPAIR (EAST)",
      "value": "J998",
      "type": "psc_code"
   },
   {
      "label": "J999 - NON-NUCLEAR SHIP REPAIR (WEST)",
      "value": "J999",
      "type": "psc_code"
   },
   {
      "label": "K - MODIFICATION OF EQUIPMENT",
      "value": "K",
      "type": "psc_code"
   },
   {
      "label": "K0 - MODIFICATION OF EQUIPMENT",
      "value": "K0",
      "type": "psc_code"
   },
   {
      "label": "K010 - MODIFICATION OF EQUIPMENT- WEAPONS",
      "value": "K010",
      "type": "psc_code"
   },
   {
      "label": "K010 - MODIFICATION OF EQUIPMENT: WEAPONS",
      "value": "K010",
      "type": "psc_code"
   },
   {
      "label": "K011 - MODIFICATION OF EQUIPMENT- NUCLEAR ORDNANCE",
      "value": "K011",
      "type": "psc_code"
   },
   {
      "label": "K011 - MOD OF NUCLEAR ORDNANCE",
      "value": "K011",
      "type": "psc_code"
   },
   {
      "label": "K012 - MODIFICATION OF EQUIPMENT- FIRE CONTROL EQUIPMENT",
      "value": "K012",
      "type": "psc_code"
   },
   {
      "label": "K012 - MOD OF FIRE CONT EQ",
      "value": "K012",
      "type": "psc_code"
   },
   {
      "label": "K013 - MODIFICATION OF EQUIPMENT- AMMUNITION AND EXPLOSIVES",
      "value": "K013",
      "type": "psc_code"
   },
   {
      "label": "K013 - MOD OF AMMO & EXPLOSIVES",
      "value": "K013",
      "type": "psc_code"
   },
   {
      "label": "K014 - MODIFICATION OF EQUIPMENT- GUIDED MISSILES",
      "value": "K014",
      "type": "psc_code"
   },
   {
      "label": "K014 - MOD OF GUIDED MISSILES",
      "value": "K014",
      "type": "psc_code"
   },
   {
      "label": "K015 - MODIFICATION OF EQUIPMENT- AIRCRAFT AND AIRFRAME STRUCTURAL COMPONENTS",
      "value": "K015",
      "type": "psc_code"
   },
   {
      "label": "K015 - MOD OF AIRCRAFT",
      "value": "K015",
      "type": "psc_code"
   },
   {
      "label": "K016 - MODIFICATION OF EQUIPMENT- AIRCRAFT COMPONENTS AND ACCESSORIES",
      "value": "K016",
      "type": "psc_code"
   },
   {
      "label": "K016 - MOD OF AIRCRAFT COMPONENTS",
      "value": "K016",
      "type": "psc_code"
   },
   {
      "label": "K017 - MODIFICATION OF EQUIPMENT- AIRCRAFT LAUNCHING, LANDING, AND GROUND HANDLING EQUIPMENT",
      "value": "K017",
      "type": "psc_code"
   },
   {
      "label": "K017 - MOD OF AIRCRAFT GROUND EQ",
      "value": "K017",
      "type": "psc_code"
   },
   {
      "label": "K018 - MODIFICATION OF EQUIPMENT- SPACE VEHICLES",
      "value": "K018",
      "type": "psc_code"
   },
   {
      "label": "K018 - MOD OF SPACE VEHICLES",
      "value": "K018",
      "type": "psc_code"
   },
   {
      "label": "K019 - MOD OF SHIPS-SML CRAFT-DOCKS",
      "value": "K019",
      "type": "psc_code"
   },
   {
      "label": "K019 - MODIFICATION OF EQUIPMENT- SHIPS, SMALL CRAFT, PONTOONS, AND FLOATING DOCKS",
      "value": "K019",
      "type": "psc_code"
   },
   {
      "label": "K020 - MOD OF SHIP & MARINE EQ",
      "value": "K020",
      "type": "psc_code"
   },
   {
      "label": "K020 - MODIFICATION OF EQUIPMENT- SHIP AND MARINE EQUIPMENT",
      "value": "K020",
      "type": "psc_code"
   },
   {
      "label": "K022 - MODIFICATION OF EQUIPMENT- RAILWAY EQUIPMENT",
      "value": "K022",
      "type": "psc_code"
   },
   {
      "label": "K022 - MOD OF RAILWAY EQ",
      "value": "K022",
      "type": "psc_code"
   },
   {
      "label": "K023 - MODIFICATION OF EQUIPMENT- GROUND EFFECT VEHICLES, MOTOR VEHICLES, TRAILERS, AND CYCLES",
      "value": "K023",
      "type": "psc_code"
   },
   {
      "label": "K023 - MOD OF VEHICLES-TRAILERS-CYC",
      "value": "K023",
      "type": "psc_code"
   },
   {
      "label": "K024 - MODIFICATION OF EQUIPMENT- TRACTORS",
      "value": "K024",
      "type": "psc_code"
   },
   {
      "label": "K024 - MODIFICATION OF EQUIPMENT: TRACTORS",
      "value": "K024",
      "type": "psc_code"
   },
   {
      "label": "K025 - MOD OF VEHICULAR EQ",
      "value": "K025",
      "type": "psc_code"
   },
   {
      "label": "K025 - MODIFICATION OF EQUIPMENT- VEHICULAR EQUIPMENT COMPONENTS",
      "value": "K025",
      "type": "psc_code"
   },
   {
      "label": "K026 - MODIFICATION OF EQUIPMENT- TIRES AND TUBES",
      "value": "K026",
      "type": "psc_code"
   },
   {
      "label": "K026 - MOD OF TIRES & TUBES",
      "value": "K026",
      "type": "psc_code"
   },
   {
      "label": "K028 - MODIFICATION OF EQUIPMENT- ENGINES, TURBINES, AND COMPONENTS",
      "value": "K028",
      "type": "psc_code"
   },
   {
      "label": "K028 - MOD OF ENGINES & TURBINES",
      "value": "K028",
      "type": "psc_code"
   },
   {
      "label": "K029 - MOD OF ENGINE ACCESSORIES",
      "value": "K029",
      "type": "psc_code"
   },
   {
      "label": "K029 - MODIFICATION OF EQUIPMENT- ENGINE ACCESSORIES",
      "value": "K029",
      "type": "psc_code"
   },
   {
      "label": "K030 - MOD OF MECH POWER TRANS EQ",
      "value": "K030",
      "type": "psc_code"
   },
   {
      "label": "K030 - MODIFICATION OF EQUIPMENT- MECHANICAL POWER TRANSMISSION EQUIPMENT",
      "value": "K030",
      "type": "psc_code"
   },
   {
      "label": "K031 - MODIFICATION OF EQUIPMENT- BEARINGS",
      "value": "K031",
      "type": "psc_code"
   },
   {
      "label": "K031 - MODIFICATION OF EQUIPMENT: BEARINGS",
      "value": "K031",
      "type": "psc_code"
   },
   {
      "label": "K032 - MODIFICATION OF EQUIPMENT- WOODWORKING MACHINERY AND EQUIPMENT",
      "value": "K032",
      "type": "psc_code"
   },
   {
      "label": "K032 - MOD OF WOODWORKING MACHINE",
      "value": "K032",
      "type": "psc_code"
   },
   {
      "label": "K034 - MOD OF METALWORK MACHINE",
      "value": "K034",
      "type": "psc_code"
   },
   {
      "label": "K034 - MODIFICATION OF EQUIPMENT- METALWORKING MACHINERY",
      "value": "K034",
      "type": "psc_code"
   },
   {
      "label": "K035 - MOD OF SERVICE & TRADE EQ",
      "value": "K035",
      "type": "psc_code"
   },
   {
      "label": "K035 - MODIFICATION OF EQUIPMENT- SERVICE AND TRADE EQUIPMENT",
      "value": "K035",
      "type": "psc_code"
   },
   {
      "label": "K036 - MOD OF SP INDUSTRY MACHINERY",
      "value": "K036",
      "type": "psc_code"
   },
   {
      "label": "K036 - MODIFICATION OF EQUIPMENT- SPECIAL INDUSTRY MACHINERY",
      "value": "K036",
      "type": "psc_code"
   },
   {
      "label": "K037 - MOD OF AGRICULTURE MACHINE",
      "value": "K037",
      "type": "psc_code"
   },
   {
      "label": "K037 - MODIFICATION OF EQUIPMENT- AGRICULTURAL MACHINERY AND EQUIPMENT",
      "value": "K037",
      "type": "psc_code"
   },
   {
      "label": "K038 - MODIFICATION OF EQUIPMENT- CONSTRUCTION, MINING, EXCAVATING, AND HIGHWAY MAINTENANCE EQUIPMENT",
      "value": "K038",
      "type": "psc_code"
   },
   {
      "label": "K038 - MOD OF CONTRUCT EQ",
      "value": "K038",
      "type": "psc_code"
   },
   {
      "label": "K039 - MOD OF MATERIALS HANDLING EQ",
      "value": "K039",
      "type": "psc_code"
   },
   {
      "label": "K039 - MODIFICATION OF EQUIPMENT- MATERIALS HANDLING EQUIPMENT",
      "value": "K039",
      "type": "psc_code"
   },
   {
      "label": "K040 - MOD OF ROPE-CABLE-CHAIN",
      "value": "K040",
      "type": "psc_code"
   },
   {
      "label": "K040 - MODIFICATION OF EQUIPMENT- ROPE, CABLE, CHAIN, AND FITTINGS",
      "value": "K040",
      "type": "psc_code"
   },
   {
      "label": "K041 - MODIFICATION OF EQUIPMENT- REFRIGERATION, AIR CONDITIONING, AND AIR CIRCULATING EQUIPMENT",
      "value": "K041",
      "type": "psc_code"
   },
   {
      "label": "K041 - MOD OF REFRIGERATION - AC EQ",
      "value": "K041",
      "type": "psc_code"
   },
   {
      "label": "K042 - MODIFICATION OF EQUIPMENT- FIRE FIGHTING/RESCUE/SAFETY EQUIPMENT; ENVIRON PROTECT EQUIPMENT/MATLS",
      "value": "K042",
      "type": "psc_code"
   },
   {
      "label": "K042 - MOD OF FIRE-RESCUE-SAFETY EQ",
      "value": "K042",
      "type": "psc_code"
   },
   {
      "label": "K043 - MOD OF PUMPS & COMPRESSORS",
      "value": "K043",
      "type": "psc_code"
   },
   {
      "label": "K043 - MODIFICATION OF EQUIPMENT- PUMPS AND COMPRESSORS",
      "value": "K043",
      "type": "psc_code"
   },
   {
      "label": "K044 - MODIFICATION OF EQUIPMENT- FURNACE, STEAM PLANT, AND DRYING EQUIPMENT; NUCLEAR REACTORS",
      "value": "K044",
      "type": "psc_code"
   },
   {
      "label": "K044 - MOD OF FURNACE-NUCLEAR REAC",
      "value": "K044",
      "type": "psc_code"
   },
   {
      "label": "K045 - MODIFICATION OF EQUIPMENT- PLUMBING, HEATING, AND WASTE DISPOSAL EQUIPMENT",
      "value": "K045",
      "type": "psc_code"
   },
   {
      "label": "K045 - MOD OF PLUMBING-HEATING EQ",
      "value": "K045",
      "type": "psc_code"
   },
   {
      "label": "K046 - MOD OF WATER PURIFICATION EQ",
      "value": "K046",
      "type": "psc_code"
   },
   {
      "label": "K046 - MODIFICATION OF EQUIPMENT- WATER PURIFICATION AND SEWAGE TREATMENT EQUIPMENT",
      "value": "K046",
      "type": "psc_code"
   },
   {
      "label": "K047 - MODIFICATION OF EQUIPMENT- PIPE, TUBING, HOSE, AND FITTINGS",
      "value": "K047",
      "type": "psc_code"
   },
   {
      "label": "K047 - MOD OF PIPE-TUBING-HOSE",
      "value": "K047",
      "type": "psc_code"
   },
   {
      "label": "K048 - MODIFICATION OF EQUIPMENT- VALVES",
      "value": "K048",
      "type": "psc_code"
   },
   {
      "label": "K048 - MODIFICATION OF EQUIPMENT: VALVES",
      "value": "K048",
      "type": "psc_code"
   },
   {
      "label": "K049 - MOD OF MAINT REPAIR SHOP EQ",
      "value": "K049",
      "type": "psc_code"
   },
   {
      "label": "K049 - MODIFICATION OF EQUIPMENT- MAINTENANCE AND REPAIR SHOP EQUIPMENT",
      "value": "K049",
      "type": "psc_code"
   },
   {
      "label": "K051 - MODIFICATION OF EQUIPMENT- HAND TOOLS",
      "value": "K051",
      "type": "psc_code"
   },
   {
      "label": "K051 - MOD OF HAND TOOLS",
      "value": "K051",
      "type": "psc_code"
   },
   {
      "label": "K052 - MODIFICATION OF EQUIPMENT- MEASURING TOOLS",
      "value": "K052",
      "type": "psc_code"
   },
   {
      "label": "K052 - MOD OF MEASURING TOOLS",
      "value": "K052",
      "type": "psc_code"
   },
   {
      "label": "K053 - MODIFICATION OF EQUIPMENT- HARDWARE AND ABRASIVES",
      "value": "K053",
      "type": "psc_code"
   },
   {
      "label": "K053 - MOD OF HARDWARE & ABRASIVES",
      "value": "K053",
      "type": "psc_code"
   },
   {
      "label": "K054 - MOD OF PREFAB STRUCTURES",
      "value": "K054",
      "type": "psc_code"
   },
   {
      "label": "K054 - MODIFICATION OF EQUIPMENT- PREFABRICATED STRUCTURES AND SCAFFOLDING",
      "value": "K054",
      "type": "psc_code"
   },
   {
      "label": "K055 - MOD OF LUMBER & MILLWORK",
      "value": "K055",
      "type": "psc_code"
   },
   {
      "label": "K055 - MODIFICATION OF EQUIPMENT- LUMBER, MILLWORK, PLYWOOD, AND VENEER",
      "value": "K055",
      "type": "psc_code"
   },
   {
      "label": "K056 - MODIFICATION OF EQUIPMENT- CONSTRUCTION AND BUILDING MATERIALS",
      "value": "K056",
      "type": "psc_code"
   },
   {
      "label": "K056 - MOD OF CONTRUCT MATERIAL",
      "value": "K056",
      "type": "psc_code"
   },
   {
      "label": "K058 - MODIFICATION OF EQUIPMENT- COMMUNICATION, DETECTION, AND COHERENT RADIATION EQUIPMENT",
      "value": "K058",
      "type": "psc_code"
   },
   {
      "label": "K058 - MOD OF COMMUNICATION EQ",
      "value": "K058",
      "type": "psc_code"
   },
   {
      "label": "K059 - MOD OF ELECT-ELCT EQ",
      "value": "K059",
      "type": "psc_code"
   },
   {
      "label": "K059 - MODIFICATION OF EQUIPMENT- ELECTRICAL AND ELECTRONIC EQUIPMENT COMPONENTS",
      "value": "K059",
      "type": "psc_code"
   },
   {
      "label": "K060 - MODIFICATION OF EQUIPMENT- FIBER OPTICS MATERIALS, COMPONENTS, ASSEMBLIES, AND ACCESSORIES",
      "value": "K060",
      "type": "psc_code"
   },
   {
      "label": "K060 - MOD OF FIBER OPTICS MATERIAL",
      "value": "K060",
      "type": "psc_code"
   },
   {
      "label": "K061 - MODIFICATION OF EQUIPMENT- ELECTRIC WIRE AND POWER DISTRIBUTION EQUIPMENT",
      "value": "K061",
      "type": "psc_code"
   },
   {
      "label": "K061 - MOD OF POWER DISTRIBUTION EQ",
      "value": "K061",
      "type": "psc_code"
   },
   {
      "label": "K062 - MODIFICATION OF EQUIPMENT- LIGHTING FIXTURES AND LAMPS",
      "value": "K062",
      "type": "psc_code"
   },
   {
      "label": "K062 - MOD OF LIGHTING FIXTURES",
      "value": "K062",
      "type": "psc_code"
   },
   {
      "label": "K063 - MOD OF ALARM & SIGNAL SYSTEM",
      "value": "K063",
      "type": "psc_code"
   },
   {
      "label": "K063 - MODIFICATION OF EQUIPMENT- ALARM, SIGNAL, AND SECURITY DETECTION SYSTEMS",
      "value": "K063",
      "type": "psc_code"
   },
   {
      "label": "K065 - MOD OF MEDICAL-DENTAL-VET EQ",
      "value": "K065",
      "type": "psc_code"
   },
   {
      "label": "K065 - MODIFICATION OF EQUIPMENT- MEDICAL, DENTAL, AND VETERINARY EQUIPMENT AND SUPPLIES",
      "value": "K065",
      "type": "psc_code"
   },
   {
      "label": "K066 - MODIFICATION OF EQUIPMENT- INSTRUMENTS AND LABORATORY EQUIPMENT",
      "value": "K066",
      "type": "psc_code"
   },
   {
      "label": "K066 - MOD OF INSTRUMENTS & LAB EQ",
      "value": "K066",
      "type": "psc_code"
   },
   {
      "label": "K067 - MODIFICATION OF EQUIPMENT- PHOTOGRAPHIC EQUIPMENT",
      "value": "K067",
      "type": "psc_code"
   },
   {
      "label": "K067 - MOD OF PHOTOGRAPHIC EQ",
      "value": "K067",
      "type": "psc_code"
   },
   {
      "label": "K068 - MODIFICATION OF EQUIPMENT- CHEMICALS AND CHEMICAL PRODUCTS",
      "value": "K068",
      "type": "psc_code"
   },
   {
      "label": "K068 - MOD OF CHEMICAL PRODUCTS",
      "value": "K068",
      "type": "psc_code"
   },
   {
      "label": "K069 - MODIFICATION OF EQUIPMENT- TRAINING AIDS AND DEVICES",
      "value": "K069",
      "type": "psc_code"
   },
   {
      "label": "K069 - MOD OF TRAINING AIDS-DEVICES",
      "value": "K069",
      "type": "psc_code"
   },
   {
      "label": "K070 - MODIFICATION OF EQUIPMENT- ADP EQUIPMENT/SOFTWARE/SUPPLIES/SUPPORT EQUIPMENT",
      "value": "K070",
      "type": "psc_code"
   },
   {
      "label": "K070 - MOD OF ADP EQ & SUPPLIES",
      "value": "K070",
      "type": "psc_code"
   },
   {
      "label": "K071 - MOD OF FURNITURE",
      "value": "K071",
      "type": "psc_code"
   },
   {
      "label": "K071 - MODIFICATION OF EQUIPMENT- FURNITURE",
      "value": "K071",
      "type": "psc_code"
   },
   {
      "label": "K072 - MODIFICATION OF EQUIPMENT- HOUSEHOLD AND COMMERCIAL FURNISHINGS AND APPLIANCES",
      "value": "K072",
      "type": "psc_code"
   },
   {
      "label": "K072 - MOD OF HOUSEHOLD FURNISHINGS",
      "value": "K072",
      "type": "psc_code"
   },
   {
      "label": "K073 - MODIFICATION OF EQUIPMENT- FOOD PREPARATION AND SERVING EQUIPMENT",
      "value": "K073",
      "type": "psc_code"
   },
   {
      "label": "K073 - MOD OF FOOD PREP-SERVING EQ",
      "value": "K073",
      "type": "psc_code"
   },
   {
      "label": "K074 - MODIFICATION OF EQUIPMENT- OFFICE MACHINES, TEXT PROCESSING SYSTEMS, AND VISIBLE RECORD EQUIPMENT",
      "value": "K074",
      "type": "psc_code"
   },
   {
      "label": "K074 - MOD OF OFFICE MACHINES",
      "value": "K074",
      "type": "psc_code"
   },
   {
      "label": "K075 - MODIFICATION OF EQUIPMENT- OFFICE SUPPLIES AND DEVICES",
      "value": "K075",
      "type": "psc_code"
   },
   {
      "label": "K075 - MOD OF OFFICE SUPPLIES",
      "value": "K075",
      "type": "psc_code"
   },
   {
      "label": "K076 - MOD OF BOOKS-MAPS-PUBS",
      "value": "K076",
      "type": "psc_code"
   },
   {
      "label": "K076 - MODIFICATION OF EQUIPMENT- BOOKS, MAPS, AND OTHER PUBLICATIONS",
      "value": "K076",
      "type": "psc_code"
   },
   {
      "label": "K077 - MOD OF MUSICAL INST-RADIO-TV",
      "value": "K077",
      "type": "psc_code"
   },
   {
      "label": "K077 - MODIFICATION OF EQUIPMENT- MUSICAL INST/PHONOGRAPH/HOME RADIO",
      "value": "K077",
      "type": "psc_code"
   },
   {
      "label": "K078 - MODIFICATION OF EQUIPMENT- RECREATIONAL AND ATHLETIC EQUIPMENT",
      "value": "K078",
      "type": "psc_code"
   },
   {
      "label": "K078 - MOD OF RECREATIONAL EQ",
      "value": "K078",
      "type": "psc_code"
   },
   {
      "label": "K079 - MODIFICATION OF EQUIPMENT- CLEANING EQUIPMENT AND SUPPLIES",
      "value": "K079",
      "type": "psc_code"
   },
   {
      "label": "K079 - MOD OF CLEANING EQ",
      "value": "K079",
      "type": "psc_code"
   },
   {
      "label": "K080 - MODIFICATION OF EQUIPMENT- BRUSHES, PAINTS, SEALERS, AND ADHESIVES",
      "value": "K080",
      "type": "psc_code"
   },
   {
      "label": "K080 - MOD OF BRUSHES-PAINTS-SEALER",
      "value": "K080",
      "type": "psc_code"
   },
   {
      "label": "K081 - MODIFICATION OF EQUIPMENT- CONTAINERS, PACKAGING, AND PACKING SUPPLIES",
      "value": "K081",
      "type": "psc_code"
   },
   {
      "label": "K081 - MOD OF CONTAINERS-PACKAGING",
      "value": "K081",
      "type": "psc_code"
   },
   {
      "label": "K083 - MOD OF TEXTILES-LEATHER-FURS",
      "value": "K083",
      "type": "psc_code"
   },
   {
      "label": "K083 - MODIFICATION OF EQUIPMENT- TEXTILES, LEATHER, FURS, APPAREL AND SHOE FINDINGS, TENTS AND FLAGS",
      "value": "K083",
      "type": "psc_code"
   },
   {
      "label": "K084 - MODIFICATION OF EQUIPMENT- CLOTHING, INDIVIDUAL EQUIPMENT, AND INSIGNIA",
      "value": "K084",
      "type": "psc_code"
   },
   {
      "label": "K084 - MOD OF CLOTHING - INDIVID EQ",
      "value": "K084",
      "type": "psc_code"
   },
   {
      "label": "K085 - MOD OF TOILETRIES",
      "value": "K085",
      "type": "psc_code"
   },
   {
      "label": "K085 - MODIFICATION OF EQUIPMENT- TOILETRIES",
      "value": "K085",
      "type": "psc_code"
   },
   {
      "label": "K087 - MOD OF AGRICULTURAL SUPPLIES",
      "value": "K087",
      "type": "psc_code"
   },
   {
      "label": "K087 - MODIFICATION OF EQUIPMENT- AGRICULTURAL SUPPLIES",
      "value": "K087",
      "type": "psc_code"
   },
   {
      "label": "K088 - MOD OF LIVE ANIMALS",
      "value": "K088",
      "type": "psc_code"
   },
   {
      "label": "K088 - MODIFICATION OF EQUIPMENT- LIVE ANIMALS",
      "value": "K088",
      "type": "psc_code"
   },
   {
      "label": "K089 - MODIFICATION OF EQUIPMENT- SUBSISTENCE",
      "value": "K089",
      "type": "psc_code"
   },
   {
      "label": "K089 - MOD OF SUBSISTENCE",
      "value": "K089",
      "type": "psc_code"
   },
   {
      "label": "K091 - MODIFICATION OF EQUIPMENT- FUELS, LUBRICANTS, OILS, AND WAXES",
      "value": "K091",
      "type": "psc_code"
   },
   {
      "label": "K091 - MOD OF FUELS-LUBRICANTS-OILS",
      "value": "K091",
      "type": "psc_code"
   },
   {
      "label": "K093 - MODIFICATION OF EQUIPMENT- NONMETALLIC FABRICATED MATERIALS",
      "value": "K093",
      "type": "psc_code"
   },
   {
      "label": "K093 - MOD OF NONMETALIC FAB MAT",
      "value": "K093",
      "type": "psc_code"
   },
   {
      "label": "K094 - MODIFICATION OF EQUIPMENT- NONMETALLIC CRUDE MATERIALS",
      "value": "K094",
      "type": "psc_code"
   },
   {
      "label": "K094 - MOD OF NONMETALIC CRUDE MAT",
      "value": "K094",
      "type": "psc_code"
   },
   {
      "label": "K095 - MODIFICATION OF EQUIPMENT- METAL BARS, SHEETS, AND SHAPES",
      "value": "K095",
      "type": "psc_code"
   },
   {
      "label": "K095 - MOD OF METAL BARS & SHEETS",
      "value": "K095",
      "type": "psc_code"
   },
   {
      "label": "K096 - MOD OF ORES & MINERALS",
      "value": "K096",
      "type": "psc_code"
   },
   {
      "label": "K096 - MODIFICATION OF EQUIPMENT- ORES, MINERALS, AND THEIR PRIMARY PRODUCTS",
      "value": "K096",
      "type": "psc_code"
   },
   {
      "label": "K099 - MOD OF MISC EQ",
      "value": "K099",
      "type": "psc_code"
   },
   {
      "label": "K099 - MODIFICATION OF EQUIPMENT- MISCELLANEOUS",
      "value": "K099",
      "type": "psc_code"
   },
   {
      "label": "L - TECHNICAL REPRESENTATIVE SVCS.",
      "value": "L",
      "type": "psc_code"
   },
   {
      "label": "L0 - TECHNICAL REPRESENTATIVE SERVICES",
      "value": "L0",
      "type": "psc_code"
   },
   {
      "label": "L010 - TECH REP SVCS/WEAPONS",
      "value": "L010",
      "type": "psc_code"
   },
   {
      "label": "L010 - TECHNICAL REPRESENTATIVE- WEAPONS",
      "value": "L010",
      "type": "psc_code"
   },
   {
      "label": "L011 - TECHNICAL REPRESENTATIVE- NUCLEAR ORDNANCE",
      "value": "L011",
      "type": "psc_code"
   },
   {
      "label": "L011 - TECH REP SVCS/NUCLEAR ORDNANCE",
      "value": "L011",
      "type": "psc_code"
   },
   {
      "label": "L012 - TECHNICAL REPRESENTATIVE- FIRE CONTROL EQUIPMENT",
      "value": "L012",
      "type": "psc_code"
   },
   {
      "label": "L012 - TECH REP SVCS/FIRE CONT EQ",
      "value": "L012",
      "type": "psc_code"
   },
   {
      "label": "L013 - TECHNICAL REPRESENTATIVE- AMMUNITION AND EXPLOSIVES",
      "value": "L013",
      "type": "psc_code"
   },
   {
      "label": "L013 - TECH REP SVCS/AMMO & EXPLOSIVES",
      "value": "L013",
      "type": "psc_code"
   },
   {
      "label": "L014 - TECHNICAL REPRESENTATIVE- GUIDED MISSILES",
      "value": "L014",
      "type": "psc_code"
   },
   {
      "label": "L014 - TECH REP SVCS/GUIDED MISSILES",
      "value": "L014",
      "type": "psc_code"
   },
   {
      "label": "L015 - TECHNICAL REPRESENTATIVE- AIRCRAFT AND AIRFRAME STRUCTURAL COMPONENTS",
      "value": "L015",
      "type": "psc_code"
   },
   {
      "label": "L015 - TECH REP SVCS/AIRCRAFT",
      "value": "L015",
      "type": "psc_code"
   },
   {
      "label": "L016 - TECH REP SVCS/AIRCRAFT COMPONENTS",
      "value": "L016",
      "type": "psc_code"
   },
   {
      "label": "L016 - TECHNICAL REPRESENTATIVE- AIRCRAFT COMPONENTS AND ACCESSORIES",
      "value": "L016",
      "type": "psc_code"
   },
   {
      "label": "L017 - TECHNICAL REPRESENTATIVE- AIRCRAFT LAUNCHING, LANDING, AND GROUND HANDLING EQUIPMENT",
      "value": "L017",
      "type": "psc_code"
   },
   {
      "label": "L017 - TECH REP SVCS/AIRCRAFT GROUND EQ",
      "value": "L017",
      "type": "psc_code"
   },
   {
      "label": "L018 - TECHNICAL REPRESENTATIVE- SPACE VEHICLES",
      "value": "L018",
      "type": "psc_code"
   },
   {
      "label": "L018 - TECH REP SVCS/SPACE VEHICLES",
      "value": "L018",
      "type": "psc_code"
   },
   {
      "label": "L019 - TECH REP SVCS/SHIPS-SML CRAFT-DOCKS",
      "value": "L019",
      "type": "psc_code"
   },
   {
      "label": "L019 - TECHNICAL REPRESENTATIVE- SHIPS, SMALL CRAFT, PONTOONS, AND FLOATING DOCKS",
      "value": "L019",
      "type": "psc_code"
   },
   {
      "label": "L020 - TECHNICAL REPRESENTATIVE- SHIP AND MARINE EQUIPMENT",
      "value": "L020",
      "type": "psc_code"
   },
   {
      "label": "L020 - TECH REP SVCS/SHIP & MARINE EQ",
      "value": "L020",
      "type": "psc_code"
   },
   {
      "label": "L022 - TECHNICAL REPRESENTATIVE- RAILWAY EQUIPMENT",
      "value": "L022",
      "type": "psc_code"
   },
   {
      "label": "L022 - TECH REP SVCS/RAILWAY EQ",
      "value": "L022",
      "type": "psc_code"
   },
   {
      "label": "L023 - TECH REP SVCS/VEHICLES-TRAILERS-CYC",
      "value": "L023",
      "type": "psc_code"
   },
   {
      "label": "L023 - TECHNICAL REPRESENTATIVE- GROUND EFFECT VEHICLES, MOTOR VEHICLES, TRAILERS, AND CYCLES",
      "value": "L023",
      "type": "psc_code"
   },
   {
      "label": "L024 - TECHNICAL REPRESENTATIVE- TRACTORS",
      "value": "L024",
      "type": "psc_code"
   },
   {
      "label": "L024 - TECH REP SVCS/TRACTORS",
      "value": "L024",
      "type": "psc_code"
   },
   {
      "label": "L025 - TECH REP SVCS/VEHICULAR EQ",
      "value": "L025",
      "type": "psc_code"
   },
   {
      "label": "L025 - TECHNICAL REPRESENTATIVE- VEHICULAR EQUIPMENT COMPONENTS",
      "value": "L025",
      "type": "psc_code"
   },
   {
      "label": "L026 - TECHNICAL REPRESENTATIVE- TIRES AND TUBES",
      "value": "L026",
      "type": "psc_code"
   },
   {
      "label": "L026 - TECH REP SVCS/TIRES & TUBES",
      "value": "L026",
      "type": "psc_code"
   },
   {
      "label": "L028 - TECHNICAL REPRESENTATIVE- ENGINES, TURBINES, AND COMPONENTS",
      "value": "L028",
      "type": "psc_code"
   },
   {
      "label": "L028 - TECH REP SVCS/ENGINES & TURBINES",
      "value": "L028",
      "type": "psc_code"
   },
   {
      "label": "L029 - TECH REP SVCS/ENGINE ACCESSORIES",
      "value": "L029",
      "type": "psc_code"
   },
   {
      "label": "L029 - TECHNICAL REPRESENTATIVE- ENGINE ACCESSORIES",
      "value": "L029",
      "type": "psc_code"
   },
   {
      "label": "L030 - TECHNICAL REPRESENTATIVE- MECHANICAL POWER TRANSMISSION EQUIPMENT",
      "value": "L030",
      "type": "psc_code"
   },
   {
      "label": "L030 - TECH REP SVCS/MECH POWER TRANS EQ",
      "value": "L030",
      "type": "psc_code"
   },
   {
      "label": "L031 - TECH REP SVCS/BEARINGS",
      "value": "L031",
      "type": "psc_code"
   },
   {
      "label": "L031 - TECHNICAL REPRESENTATIVE- BEARINGS",
      "value": "L031",
      "type": "psc_code"
   },
   {
      "label": "L032 - TECHNICAL REPRESENTATIVE- WOODWORKING MACHINERY AND EQUIPMENT",
      "value": "L032",
      "type": "psc_code"
   },
   {
      "label": "L032 - TECH REP SVCS/WOODWORKING MACHINE",
      "value": "L032",
      "type": "psc_code"
   },
   {
      "label": "L034 - TECHNICAL REPRESENTATIVE- METALWORKING MACHINERY",
      "value": "L034",
      "type": "psc_code"
   },
   {
      "label": "L034 - TECH REP SVCS/METALWORK MACHINE",
      "value": "L034",
      "type": "psc_code"
   },
   {
      "label": "L035 - TECHNICAL REPRESENTATIVE- SERVICE AND TRADE EQUIPMENT",
      "value": "L035",
      "type": "psc_code"
   },
   {
      "label": "L035 - TECH REP SVCS/SERVICE & TRADE EQ",
      "value": "L035",
      "type": "psc_code"
   },
   {
      "label": "L036 - TECH REP SVCS/SP INDUSTRY MACHINERY",
      "value": "L036",
      "type": "psc_code"
   },
   {
      "label": "L036 - TECHNICAL REPRESENTATIVE- SPECIAL INDUSTRY MACHINERY",
      "value": "L036",
      "type": "psc_code"
   },
   {
      "label": "L037 - TECHNICAL REPRESENTATIVE- AGRICULTURAL MACHINERY AND EQUIPMENT",
      "value": "L037",
      "type": "psc_code"
   },
   {
      "label": "L037 - TECH REP SVCS/AGRICULTURE MACHINE",
      "value": "L037",
      "type": "psc_code"
   },
   {
      "label": "L038 - TECHNICAL REPRESENTATIVE- CONSTRUCTION/MINING/EXCAVATING/HIGHWAY MAINT EQUIP",
      "value": "L038",
      "type": "psc_code"
   },
   {
      "label": "L038 - TECH REP SVCS/CONTRUCT EQ",
      "value": "L038",
      "type": "psc_code"
   },
   {
      "label": "L039 - TECH REP SVCS/MATERIALS HANDLING EQ",
      "value": "L039",
      "type": "psc_code"
   },
   {
      "label": "L039 - TECHNICAL REPRESENTATIVE- MATERIALS HANDLING EQUIPMENT",
      "value": "L039",
      "type": "psc_code"
   },
   {
      "label": "L040 - TECHNICAL REPRESENTATIVE- ROPE, CABLE, CHAIN, AND FITTINGS",
      "value": "L040",
      "type": "psc_code"
   },
   {
      "label": "L040 - TECH REP SVCS/ROPE-CABLE-CHAIN",
      "value": "L040",
      "type": "psc_code"
   },
   {
      "label": "L041 - TECHNICAL REPRESENTATIVE- REFRIGERATION, AIR CONDITIONING, AND AIR CIRCULATING EQUIPMENT",
      "value": "L041",
      "type": "psc_code"
   },
   {
      "label": "L041 - TECH REP SVCS/REFRIGERATION - AC EQ",
      "value": "L041",
      "type": "psc_code"
   },
   {
      "label": "L042 - TECHNICAL REP- FIRE FIGHTING/RESCUE/SAFETY EQUIPMENT; ENVIRON PROTECT EQUIPMENT/MATLS",
      "value": "L042",
      "type": "psc_code"
   },
   {
      "label": "L042 - TECH REP SVCS/FIRE-RESCUE-SAFETY EQ",
      "value": "L042",
      "type": "psc_code"
   },
   {
      "label": "L043 - TECHNICAL REPRESENTATIVE- PUMPS AND COMPRESSORS",
      "value": "L043",
      "type": "psc_code"
   },
   {
      "label": "L043 - TECH REP SVCS/PUMPS & COMPRESSORS",
      "value": "L043",
      "type": "psc_code"
   },
   {
      "label": "L044 - TECHNICAL REPRESENTATIVE- FURNACE, STEAM PLANT, AND DRYING EQUIPMENT; NUCLEAR REACTORS",
      "value": "L044",
      "type": "psc_code"
   },
   {
      "label": "L044 - TECH REP SVCS/FURNACE-NUCLEAR REAC",
      "value": "L044",
      "type": "psc_code"
   },
   {
      "label": "L045 - TECHNICAL REPRESENTATIVE- PLUMBING, HEATING, AND WASTE DISPOSAL EQUIPMENT",
      "value": "L045",
      "type": "psc_code"
   },
   {
      "label": "L045 - TECH REP SVCS/PLUMBING-HEATING EQ",
      "value": "L045",
      "type": "psc_code"
   },
   {
      "label": "L046 - TECHNICAL REPRESENTATIVE- WATER PURIFICATION AND SEWAGE TREATMENT EQUIPMENT",
      "value": "L046",
      "type": "psc_code"
   },
   {
      "label": "L046 - TECH REP SVCS/WATER PURIFICATION EQ",
      "value": "L046",
      "type": "psc_code"
   },
   {
      "label": "L047 - TECHNICAL REPRESENTATIVE- PIPE, TUBING, HOSE, AND FITTINGS",
      "value": "L047",
      "type": "psc_code"
   },
   {
      "label": "L047 - TECH REP SVCS/PIPE-TUBING-HOSE",
      "value": "L047",
      "type": "psc_code"
   },
   {
      "label": "L048 - TECHNICAL REPRESENTATIVE- VALVES",
      "value": "L048",
      "type": "psc_code"
   },
   {
      "label": "L048 - TECH REP SVCS/VALVES",
      "value": "L048",
      "type": "psc_code"
   },
   {
      "label": "L049 - TECH REP SVCS/MAINT REPAIR SHOP EQ",
      "value": "L049",
      "type": "psc_code"
   },
   {
      "label": "L049 - TECHNICAL REPRESENTATIVE- MAINTENANCE AND REPAIR SHOP EQUIPMENT",
      "value": "L049",
      "type": "psc_code"
   },
   {
      "label": "L051 - TECH REP SVCS/HAND TOOLS",
      "value": "L051",
      "type": "psc_code"
   },
   {
      "label": "L051 - TECHNICAL REPRESENTATIVE- HAND TOOLS",
      "value": "L051",
      "type": "psc_code"
   },
   {
      "label": "L052 - TECHNICAL REPRESENTATIVE- MEASURING TOOLS",
      "value": "L052",
      "type": "psc_code"
   },
   {
      "label": "L052 - TECH REP SVCS/MEASURING TOOLS",
      "value": "L052",
      "type": "psc_code"
   },
   {
      "label": "L053 - TECHNICAL REPRESENTATIVE- HARDWARE AND ABRASIVES",
      "value": "L053",
      "type": "psc_code"
   },
   {
      "label": "L053 - TECH REP SVCS/HARDWARE & ABRASIVES",
      "value": "L053",
      "type": "psc_code"
   },
   {
      "label": "L054 - TECHNICAL REPRESENTATIVE- PREFABRICATED STRUCTURES AND SCAFFOLDING",
      "value": "L054",
      "type": "psc_code"
   },
   {
      "label": "L054 - TECH REP SVCS/PREFAB STRUCTURES",
      "value": "L054",
      "type": "psc_code"
   },
   {
      "label": "L055 - TECH REP SVCS/LUMBER & MILLWORK",
      "value": "L055",
      "type": "psc_code"
   },
   {
      "label": "L055 - TECHNICAL REPRESENTATIVE- LUMBER, MILLWORK, PLYWOOD, AND VENEER",
      "value": "L055",
      "type": "psc_code"
   },
   {
      "label": "L056 - TECH REP SVCS/CONTRUCT MATERIAL",
      "value": "L056",
      "type": "psc_code"
   },
   {
      "label": "L056 - TECHNICAL REPRESENTATIVE- CONSTRUCTION AND BUILDING MATERIALS",
      "value": "L056",
      "type": "psc_code"
   },
   {
      "label": "L058 - TECHNICAL REPRESENTATIVE- COMMUNICATION, DETECTION, AND COHERENT RADIATION EQUIPMENT",
      "value": "L058",
      "type": "psc_code"
   },
   {
      "label": "L058 - TECH REP SVCS/COMMUNICATION EQ",
      "value": "L058",
      "type": "psc_code"
   },
   {
      "label": "L059 - TECH REP SVCS/ELECT-ELCT EQ",
      "value": "L059",
      "type": "psc_code"
   },
   {
      "label": "L059 - TECHNICAL REPRESENTATIVE- ELECTRICAL AND ELECTRONIC EQUIPMENT COMPONENTS",
      "value": "L059",
      "type": "psc_code"
   },
   {
      "label": "L060 - TECH REP SVCS/FIBER OPTICS MAT",
      "value": "L060",
      "type": "psc_code"
   },
   {
      "label": "L060 - TECHNICAL REPRESENTATIVE- FIBER OPTICS MATERIALS, COMPONENTS, ASSEMBLIES, AND ACCESSORIES",
      "value": "L060",
      "type": "psc_code"
   },
   {
      "label": "L061 - TECHNICAL REPRESENTATIVE- ELECTRIC WIRE AND POWER DISTRIBUTION EQUIPMENT",
      "value": "L061",
      "type": "psc_code"
   },
   {
      "label": "L061 - TECH REP SVCS/POWER DISTRIBUTION EQ",
      "value": "L061",
      "type": "psc_code"
   },
   {
      "label": "L062 - TECHNICAL REPRESENTATIVE- LIGHTING FIXTURES AND LAMPS",
      "value": "L062",
      "type": "psc_code"
   },
   {
      "label": "L062 - TECH REP SVCS/LIGHTING FIXTURES",
      "value": "L062",
      "type": "psc_code"
   },
   {
      "label": "L063 - TECH REP SVCS/ALARM & SIGNAL SYSTEM",
      "value": "L063",
      "type": "psc_code"
   },
   {
      "label": "L063 - TECHNICAL REPRESENTATIVE- ALARM, SIGNAL, AND SECURITY DETECTION SYSTEMS",
      "value": "L063",
      "type": "psc_code"
   },
   {
      "label": "L065 - TECHNICAL REPRESENTATIVE- MEDICAL, DENTAL, AND VETERINARY EQUIPMENT AND SUPPLIES",
      "value": "L065",
      "type": "psc_code"
   },
   {
      "label": "L065 - TECH REP SVCS/MEDICAL-DENTAL-VET EQ",
      "value": "L065",
      "type": "psc_code"
   },
   {
      "label": "L066 - TECHNICAL REPRESENTATIVE- INSTRUMENTS AND LABORATORY EQUIPMENT",
      "value": "L066",
      "type": "psc_code"
   },
   {
      "label": "L066 - TECH REP SVCS/INSTRUMENTS & LAB EQ",
      "value": "L066",
      "type": "psc_code"
   },
   {
      "label": "L067 - TECHNICAL REPRESENTATIVE- PHOTOGRAPHIC EQUIPMENT",
      "value": "L067",
      "type": "psc_code"
   },
   {
      "label": "L067 - TECH REP SVCS/PHOTOGRAPHIC EQ",
      "value": "L067",
      "type": "psc_code"
   },
   {
      "label": "L068 - TECHNICAL REPRESENTATIVE- CHEMICALS AND CHEMICAL PRODUCTS",
      "value": "L068",
      "type": "psc_code"
   },
   {
      "label": "L068 - TECH REP SVCS/CHEMICAL PRODUCTS",
      "value": "L068",
      "type": "psc_code"
   },
   {
      "label": "L069 - TECHNICAL REPRESENTATIVE- TRAINING AIDS AND DEVICES",
      "value": "L069",
      "type": "psc_code"
   },
   {
      "label": "L069 - TECH REP SVCS/TRAINING AIDS-DEVICES",
      "value": "L069",
      "type": "psc_code"
   },
   {
      "label": "L070 - TECH REP SVCS/ADP EQ & SUPPLIES",
      "value": "L070",
      "type": "psc_code"
   },
   {
      "label": "L070 - TECHNICAL REPRESENTATIVE- ADP EQUIPMENT/SOFTWARE/SUPPLIES/SUPPORT EQUIPMENT",
      "value": "L070",
      "type": "psc_code"
   },
   {
      "label": "L071 - TECHNICAL REPRESENTATIVE- FURNITURE",
      "value": "L071",
      "type": "psc_code"
   },
   {
      "label": "L071 - TECH REP SVCS/FURNITURE",
      "value": "L071",
      "type": "psc_code"
   },
   {
      "label": "L072 - TECHNICAL REPRESENTATIVE- HOUSEHOLD AND COMMERCIAL FURNISHINGS AND APPLIANCES",
      "value": "L072",
      "type": "psc_code"
   },
   {
      "label": "L072 - TECH REP SVCS/HOUSEHOLD FURNISHINGS",
      "value": "L072",
      "type": "psc_code"
   },
   {
      "label": "L073 - TECHNICAL REPRESENTATIVE- FOOD PREPARATION AND SERVING EQUIPMENT",
      "value": "L073",
      "type": "psc_code"
   },
   {
      "label": "L073 - TECH REP SVCS/FOOD PREP-SERVING EQ",
      "value": "L073",
      "type": "psc_code"
   },
   {
      "label": "L074 - TECH REP SVCS/OFFICE MACHINES",
      "value": "L074",
      "type": "psc_code"
   },
   {
      "label": "L074 - TECHNICAL REPRESENTATIVE- OFFICE MACHINES/TEXT PROCESSING SYS/VISIBLE RECORD EQUIPMENT",
      "value": "L074",
      "type": "psc_code"
   },
   {
      "label": "L075 - TECH REP SVCS/OFFICE SUPPLIES",
      "value": "L075",
      "type": "psc_code"
   },
   {
      "label": "L075 - TECHNICAL REPRESENTATIVE- OFFICE SUPPLIES AND DEVICES",
      "value": "L075",
      "type": "psc_code"
   },
   {
      "label": "L076 - TECH REP SVCS/BOOKS-MAPS-PUBS",
      "value": "L076",
      "type": "psc_code"
   },
   {
      "label": "L076 - TECHNICAL REPRESENTATIVE- BOOKS, MAPS, AND OTHER PUBLICATIONS",
      "value": "L076",
      "type": "psc_code"
   },
   {
      "label": "L077 - TECH REP SVCS/MUSICAL INST-RADIO-TV",
      "value": "L077",
      "type": "psc_code"
   },
   {
      "label": "L077 - TECHNICAL REPRESENTATIVE- MUSICAL INST/PHONOGRAPH/HOME RADIO",
      "value": "L077",
      "type": "psc_code"
   },
   {
      "label": "L078 - TECH REP SVCS/RECREATIONAL EQ",
      "value": "L078",
      "type": "psc_code"
   },
   {
      "label": "L078 - TECHNICAL REPRESENTATIVE- RECREATIONAL AND ATHLETIC EQUIPMENT",
      "value": "L078",
      "type": "psc_code"
   },
   {
      "label": "L079 - TECHNICAL REPRESENTATIVE- CLEANING EQUIPMENT AND SUPPLIES",
      "value": "L079",
      "type": "psc_code"
   },
   {
      "label": "L079 - TECH REP SVCS/CLEANING EQ",
      "value": "L079",
      "type": "psc_code"
   },
   {
      "label": "L080 - TECHNICAL REPRESENTATIVE- BRUSHES, PAINTS, SEALERS, AND ADHESIVES",
      "value": "L080",
      "type": "psc_code"
   },
   {
      "label": "L080 - TECH REP SVCS/BRUSHES-PAINTS-SEALER",
      "value": "L080",
      "type": "psc_code"
   },
   {
      "label": "L081 - TECHNICAL REPRESENTATIVE- CONTAINERS, PACKAGING, AND PACKING SUPPLIES",
      "value": "L081",
      "type": "psc_code"
   },
   {
      "label": "L081 - TECH REP SVCS/CONTAINERS-PACKAGING",
      "value": "L081",
      "type": "psc_code"
   },
   {
      "label": "L083 - TECH REP SVCS/TEXTILES-LEATHER-FURS",
      "value": "L083",
      "type": "psc_code"
   },
   {
      "label": "L083 - TECHNICAL REPRESENTATIVE- TEXTILES, LEATHER, FURS, APPAREL/SHOE FINDINGS, TENTS/FLAGS",
      "value": "L083",
      "type": "psc_code"
   },
   {
      "label": "L084 - TECH REP SVCS/CLOTHING - INDIVID EQ",
      "value": "L084",
      "type": "psc_code"
   },
   {
      "label": "L084 - TECHNICAL REPRESENTATIVE- CLOTHING, INDIVIDUAL EQUIPMENT, AND INSIGNIA",
      "value": "L084",
      "type": "psc_code"
   },
   {
      "label": "L085 - TECHNICAL REPRESENTATIVE- TOILETRIES",
      "value": "L085",
      "type": "psc_code"
   },
   {
      "label": "L085 - TECH REP SVCS/TOILETRIES",
      "value": "L085",
      "type": "psc_code"
   },
   {
      "label": "L087 - TECHNICAL REPRESENTATIVE- AGRICULTURAL SUPPLIES",
      "value": "L087",
      "type": "psc_code"
   },
   {
      "label": "L087 - TECH REP SVCS/AGRICULTURAL SUPPLIES",
      "value": "L087",
      "type": "psc_code"
   },
   {
      "label": "L088 - TECHNICAL REPRESENTATIVE- LIVE ANIMALS",
      "value": "L088",
      "type": "psc_code"
   },
   {
      "label": "L088 - TECH REP SVCS/LIVE ANIMALS",
      "value": "L088",
      "type": "psc_code"
   },
   {
      "label": "L089 - TECH REP SVCS/SUBSISTENCE",
      "value": "L089",
      "type": "psc_code"
   },
   {
      "label": "L089 - TECHNICAL REPRESENTATIVE- SUBSISTENCE",
      "value": "L089",
      "type": "psc_code"
   },
   {
      "label": "L091 - TECH REP SVCS/FUELS-LUBRICANTS-OILS",
      "value": "L091",
      "type": "psc_code"
   },
   {
      "label": "L091 - TECHNICAL REPRESENTATIVE- FUELS, LUBRICANTS, OILS, AND WAXES",
      "value": "L091",
      "type": "psc_code"
   },
   {
      "label": "L093 - TECH REP SVCS/NONMETALIC FAB MAT",
      "value": "L093",
      "type": "psc_code"
   },
   {
      "label": "L093 - TECHNICAL REPRESENTATIVE- NONMETALLIC FABRICATED MATERIALS",
      "value": "L093",
      "type": "psc_code"
   },
   {
      "label": "L094 - TECHNICAL REPRESENTATIVE- NONMETALLIC CRUDE MATERIALS",
      "value": "L094",
      "type": "psc_code"
   },
   {
      "label": "L094 - TECH REP SVCS/NONMETALIC CRUDE MAT",
      "value": "L094",
      "type": "psc_code"
   },
   {
      "label": "L095 - TECHNICAL REPRESENTATIVE- METAL BARS, SHEETS, AND SHAPES",
      "value": "L095",
      "type": "psc_code"
   },
   {
      "label": "L095 - TECH REP SVCS/METAL BARS & SHEETS",
      "value": "L095",
      "type": "psc_code"
   },
   {
      "label": "L096 - TECHNICAL REPRESENTATIVE- ORES, MINERALS, AND THEIR PRIMARY PRODUCTS",
      "value": "L096",
      "type": "psc_code"
   },
   {
      "label": "L096 - TECH REP SVCS/ORES & MINERALS",
      "value": "L096",
      "type": "psc_code"
   },
   {
      "label": "L099 - TECHNICAL REPRESENTATIVE- MISCELLANEOUS",
      "value": "L099",
      "type": "psc_code"
   },
   {
      "label": "L099 - TECH REP SVCS /MISC EQ",
      "value": "L099",
      "type": "psc_code"
   },
   {
      "label": "M - OPERATION OF GOVT OWNED FACILITY",
      "value": "M",
      "type": "psc_code"
   },
   {
      "label": "M1 - OPERATE GOVT OWNED BUILDINGS",
      "value": "M1",
      "type": "psc_code"
   },
   {
      "label": "M111 - OPERATION OF OFFICE BUILDINGS",
      "value": "M111",
      "type": "psc_code"
   },
   {
      "label": "M112 - OPER OF GOVT CONF SPACE & FAC",
      "value": "M112",
      "type": "psc_code"
   },
   {
      "label": "M119 - OPER OF GOVT OTHER ADMIN-SVC BLDGS",
      "value": "M119",
      "type": "psc_code"
   },
   {
      "label": "M121 - OPER OF GOVT AIR TRAFFIC TOWERS",
      "value": "M121",
      "type": "psc_code"
   },
   {
      "label": "M122 - OPER OF GOVT AIR TRAFFIC TNG FAC",
      "value": "M122",
      "type": "psc_code"
   },
   {
      "label": "M123 - OPER OF GOVT RADAR & NAV FACILITY",
      "value": "M123",
      "type": "psc_code"
   },
   {
      "label": "M124 - OPERATION OF AIRPORT RUNWAYS",
      "value": "M124",
      "type": "psc_code"
   },
   {
      "label": "M125 - OPERATION OF AIRPORT TERMINALS",
      "value": "M125",
      "type": "psc_code"
   },
   {
      "label": "M126 - OPER OF GOVT MISSILE SYSTEMS FAC",
      "value": "M126",
      "type": "psc_code"
   },
   {
      "label": "M127 - OPER OF GOVT ELCT & COMM SYS FAC",
      "value": "M127",
      "type": "psc_code"
   },
   {
      "label": "M129 - OPER OF GOVT OTHER AIRFIELD STRUCT",
      "value": "M129",
      "type": "psc_code"
   },
   {
      "label": "M131 - OPERATION OF SCHOOLS",
      "value": "M131",
      "type": "psc_code"
   },
   {
      "label": "M139 - OPER OF GOVT OTHER EDUCATIONAL BLDG",
      "value": "M139",
      "type": "psc_code"
   },
   {
      "label": "M141 - OPER OF GOVT HOSPITALS & INFIRMARY",
      "value": "M141",
      "type": "psc_code"
   },
   {
      "label": "M142 - OPER OF GOVT LABS & CLINICS",
      "value": "M142",
      "type": "psc_code"
   },
   {
      "label": "M149 - OPER OF GOVT OTHER HOSPITAL BLDGS",
      "value": "M149",
      "type": "psc_code"
   },
   {
      "label": "M151 - OPERATION OF AMMUNITION FACILITIES",
      "value": "M151",
      "type": "psc_code"
   },
   {
      "label": "M152 - OPERATION OF MAINTENANCE BUILDINGS",
      "value": "M152",
      "type": "psc_code"
   },
   {
      "label": "M153 - OPERATION OF PRODUCTION BUILDINGS",
      "value": "M153",
      "type": "psc_code"
   },
   {
      "label": "M154 - OPER OF GOVT SHIP CONST-REP FAC",
      "value": "M154",
      "type": "psc_code"
   },
   {
      "label": "M155 - OPER OF GOVT TANK AUTOMOTIVE FAC",
      "value": "M155",
      "type": "psc_code"
   },
   {
      "label": "M159 - OPER OF GOVT OTHER INDUSTRIAL BLDG",
      "value": "M159",
      "type": "psc_code"
   },
   {
      "label": "M161 - OPER OF GOVT FAMILY HOUSING",
      "value": "M161",
      "type": "psc_code"
   },
   {
      "label": "M162 - OPERATION OF RECREATIONAL BUILDINGS",
      "value": "M162",
      "type": "psc_code"
   },
   {
      "label": "M163 - OPER OF GOVT TROOP HOUSING",
      "value": "M163",
      "type": "psc_code"
   },
   {
      "label": "M164 - OPERATION OF DINING FACILITIES",
      "value": "M164",
      "type": "psc_code"
   },
   {
      "label": "M165 - OPERATION OF RELIGIOUS FACILITIES",
      "value": "M165",
      "type": "psc_code"
   },
   {
      "label": "M166 - OPERATION OF PENAL FACILITIES",
      "value": "M166",
      "type": "psc_code"
   },
   {
      "label": "M169 - OPER OF GOVT OTHER RESIDENTIAL BLDG",
      "value": "M169",
      "type": "psc_code"
   },
   {
      "label": "M171 - OPER OF GOVT AMMO STORAGE BLDGS",
      "value": "M171",
      "type": "psc_code"
   },
   {
      "label": "M172 - OPER OF GOVT FOOD STORAGE BLDGS",
      "value": "M172",
      "type": "psc_code"
   },
   {
      "label": "M173 - OPERATION OF FUEL STORAGE BUILDINGS",
      "value": "M173",
      "type": "psc_code"
   },
   {
      "label": "M174 - OPER OF GOVT OPEN STORAGE FAC",
      "value": "M174",
      "type": "psc_code"
   },
   {
      "label": "M179 - OPER OF GOVT OTHER WAREHOUSE BLDGS",
      "value": "M179",
      "type": "psc_code"
   },
   {
      "label": "M181 - OPER OF GOVT R&D GOCO FACILITIES",
      "value": "M181",
      "type": "psc_code"
   },
   {
      "label": "M182 - OPER OF GOVT R&D GOGO FACILITIES",
      "value": "M182",
      "type": "psc_code"
   },
   {
      "label": "M183 - OPER OF GOVT R&D GOCO ENVIR LABS",
      "value": "M183",
      "type": "psc_code"
   },
   {
      "label": "M184 - OPER OF GOVT R&D GOGO ENVIR LABS",
      "value": "M184",
      "type": "psc_code"
   },
   {
      "label": "M191 - OPER OF GOVT EXHIBITION BUILDINGS",
      "value": "M191",
      "type": "psc_code"
   },
   {
      "label": "M192 - OPER OF GOVT TEST BUILDINGS",
      "value": "M192",
      "type": "psc_code"
   },
   {
      "label": "M199 - OPER OF GOVT MISC BLDGS",
      "value": "M199",
      "type": "psc_code"
   },
   {
      "label": "M1AA - OPERATION OF OFFICE BUILDINGS",
      "value": "M1AA",
      "type": "psc_code"
   },
   {
      "label": "M1AB - OPERATION OF CONFERENCE SPACE AND FACILITIES",
      "value": "M1AB",
      "type": "psc_code"
   },
   {
      "label": "M1AZ - OPERATION OF OTHER ADMINISTRATIVE FACILITIES AND SERVICE BUILDINGS",
      "value": "M1AZ",
      "type": "psc_code"
   },
   {
      "label": "M1BA - OPERATION OF AIR TRAFFIC CONTROL TOWERS",
      "value": "M1BA",
      "type": "psc_code"
   },
   {
      "label": "M1BB - OPERATION OF AIR TRAFFIC CONTROL TRAINING FACILITIES",
      "value": "M1BB",
      "type": "psc_code"
   },
   {
      "label": "M1BC - OPERATION OF RADAR AND NAVIGATIONAL FACILITIES",
      "value": "M1BC",
      "type": "psc_code"
   },
   {
      "label": "M1BD - OPERATION OF AIRPORT RUNWAYS AND TAXIWAYS",
      "value": "M1BD",
      "type": "psc_code"
   },
   {
      "label": "M1BE - OPERATION OF AIRPORT TERMINALS",
      "value": "M1BE",
      "type": "psc_code"
   },
   {
      "label": "M1BF - OPERATION OF MISSILE SYSTEM FACILITIES",
      "value": "M1BF",
      "type": "psc_code"
   },
   {
      "label": "M1BG - OPERATION OF ELECTRONIC AND COMMUNICATIONS FACILITIES",
      "value": "M1BG",
      "type": "psc_code"
   },
   {
      "label": "M1BZ - OPERATION OF OTHER AIRFIELD STRUCTURES",
      "value": "M1BZ",
      "type": "psc_code"
   },
   {
      "label": "M1CA - OPERATION OF SCHOOLS",
      "value": "M1CA",
      "type": "psc_code"
   },
   {
      "label": "M1CZ - OPERATION OF OTHER EDUCATIONAL BUILDINGS",
      "value": "M1CZ",
      "type": "psc_code"
   },
   {
      "label": "M1DA - OPERATION OF HOSPITALS AND INFIRMARIES",
      "value": "M1DA",
      "type": "psc_code"
   },
   {
      "label": "M1DB - OPERATION OF LABORATORIES AND CLINICS",
      "value": "M1DB",
      "type": "psc_code"
   },
   {
      "label": "M1DZ - OPERATION OF OTHER HOSPITAL BUILDINGS",
      "value": "M1DZ",
      "type": "psc_code"
   },
   {
      "label": "M1EA - OPERATION OF AMMUNITION FACILITIES",
      "value": "M1EA",
      "type": "psc_code"
   },
   {
      "label": "M1EB - OPERATION OF MAINTENANCE BUILDINGS",
      "value": "M1EB",
      "type": "psc_code"
   },
   {
      "label": "M1EC - OPERATION OF PRODUCTION BUILDINGS",
      "value": "M1EC",
      "type": "psc_code"
   },
   {
      "label": "M1ED - OPERATION OF SHIP CONSTRUCTION AND REPAIR FACILITIES",
      "value": "M1ED",
      "type": "psc_code"
   },
   {
      "label": "M1EE - OPERATION OF TANK AUTOMOTIVE FACILITIES",
      "value": "M1EE",
      "type": "psc_code"
   },
   {
      "label": "M1EZ - OPERATION OF OTHER INDUSTRIAL BUILDINGS",
      "value": "M1EZ",
      "type": "psc_code"
   },
   {
      "label": "M1FA - OPERATION OF FAMILY HOUSING FACILITIES",
      "value": "M1FA",
      "type": "psc_code"
   },
   {
      "label": "M1FB - OPERATION OF RECREATIONAL BUILDINGS",
      "value": "M1FB",
      "type": "psc_code"
   },
   {
      "label": "M1FC - OPERATION OF TROOP HOUSING FACILITIES",
      "value": "M1FC",
      "type": "psc_code"
   },
   {
      "label": "M1FD - OPERATION OF DINING FACILITIES",
      "value": "M1FD",
      "type": "psc_code"
   },
   {
      "label": "M1FE - OPERATION OF RELIGIOUS FACILITIES",
      "value": "M1FE",
      "type": "psc_code"
   },
   {
      "label": "M1FF - OPERATION OF PENAL FACILITIES",
      "value": "M1FF",
      "type": "psc_code"
   },
   {
      "label": "M1FZ - OPERATION OF OTHER RESIDENTIAL BUILDINGS",
      "value": "M1FZ",
      "type": "psc_code"
   },
   {
      "label": "M1GA - OPERATION OF AMMUNITION STORAGE BUILDINGS",
      "value": "M1GA",
      "type": "psc_code"
   },
   {
      "label": "M1GB - OPERATION OF FOOD OR GRAIN STORAGE BUILDINGS",
      "value": "M1GB",
      "type": "psc_code"
   },
   {
      "label": "M1GC - OPERATION OF FUEL STORAGE BUILDINGS",
      "value": "M1GC",
      "type": "psc_code"
   },
   {
      "label": "M1GD - OPERATION OF OPEN STORAGE FACILITIES",
      "value": "M1GD",
      "type": "psc_code"
   },
   {
      "label": "M1GZ - OPERATION OF OTHER WAREHOUSE BUILDINGS",
      "value": "M1GZ",
      "type": "psc_code"
   },
   {
      "label": "M1HA - OPERATION OF GOVERNMENT-OWNED CONTRACTOR-OPERATED (GOCO) R&D FACILITIES",
      "value": "M1HA",
      "type": "psc_code"
   },
   {
      "label": "M1HB - OPERATION OF GOVERNMENT-OWNED GOVERNMENT-OPERATED (GOGO) R&D FACILITIES",
      "value": "M1HB",
      "type": "psc_code"
   },
   {
      "label": "M1HC - OPERATION OF GOVERNMENT-OWNED CONTRACTOR-OPERATED (GOCO) ENVIRONMENTAL LABORATORIES",
      "value": "M1HC",
      "type": "psc_code"
   },
   {
      "label": "M1HZ - OPERATION OF GOVERNMENT-OWNED GOVERNMENT-OPERATED (GOGO) ENVIRONMENTAL LABORATORIES",
      "value": "M1HZ",
      "type": "psc_code"
   },
   {
      "label": "M1JA - OPERATION OF MUSEUMS AND EXHIBITION BUILDINGS",
      "value": "M1JA",
      "type": "psc_code"
   },
   {
      "label": "M1JB - OPERATION OF TESTING AND MEASUREMENT BUILDINGS",
      "value": "M1JB",
      "type": "psc_code"
   },
   {
      "label": "M1JZ - OPERATION OF MISCELLANEOUS BUILDINGS",
      "value": "M1JZ",
      "type": "psc_code"
   },
   {
      "label": "M1KA - OPERATION OF DAMS",
      "value": "M1KA",
      "type": "psc_code"
   },
   {
      "label": "M1KB - OPERATION OF CANALS",
      "value": "M1KB",
      "type": "psc_code"
   },
   {
      "label": "M1KC - OPERATION OF MINE FIRE CONTROL FACILITIES",
      "value": "M1KC",
      "type": "psc_code"
   },
   {
      "label": "M1KD - OPERATION OF MINE SUBSIDENCE CONTROL FACILITIES",
      "value": "M1KD",
      "type": "psc_code"
   },
   {
      "label": "M1KE - OPERATION OF SURFACE MINE RECLAMATION FACILITIES",
      "value": "M1KE",
      "type": "psc_code"
   },
   {
      "label": "M1KF - OPERATION OF DREDGING FACILITIES",
      "value": "M1KF",
      "type": "psc_code"
   },
   {
      "label": "M1KZ - OPERATION OF OTHER CONSERVATION AND DEVELOPMENT FACILITIES",
      "value": "M1KZ",
      "type": "psc_code"
   },
   {
      "label": "M1LA - OPERATION OF AIRPORT SERVICE ROADS",
      "value": "M1LA",
      "type": "psc_code"
   },
   {
      "label": "M1LB - OPERATION OF HIGHWAYS, ROADS, STREETS, BRIDGES, AND RAILWAYS",
      "value": "M1LB",
      "type": "psc_code"
   },
   {
      "label": "M1LC - OPERATION OF TUNNELS AND SUBSURFACE STRUCTURES",
      "value": "M1LC",
      "type": "psc_code"
   },
   {
      "label": "M1LZ - OPERATION OF PARKING FACILITIES",
      "value": "M1LZ",
      "type": "psc_code"
   },
   {
      "label": "M1MA - OPERATION OF EPG FACILITIES - COAL",
      "value": "M1MA",
      "type": "psc_code"
   },
   {
      "label": "M1MB - OPERATION OF EPG FACILITIES - GAS",
      "value": "M1MB",
      "type": "psc_code"
   },
   {
      "label": "M1MC - OPERATION OF EPG FACILITIES - GEOTHERMAL",
      "value": "M1MC",
      "type": "psc_code"
   },
   {
      "label": "M1MD - OPERATION OF EPG FACILITIES - HYDRO",
      "value": "M1MD",
      "type": "psc_code"
   },
   {
      "label": "M1ME - OPERATION OF EPG FACILITIES - NUCLEAR",
      "value": "M1ME",
      "type": "psc_code"
   },
   {
      "label": "M1MF - OPERATION OF EPG FACILITIES - PETROLEUM",
      "value": "M1MF",
      "type": "psc_code"
   },
   {
      "label": "M1MG - OPERATION OF EPG FACILITIES - SOLAR",
      "value": "M1MG",
      "type": "psc_code"
   },
   {
      "label": "M1MH - OPERATION OF EPG FACILITIES - WIND",
      "value": "M1MH",
      "type": "psc_code"
   },
   {
      "label": "M1MZ - OPERATION OF EPG FACILITIES - OTHER, INCLUDING TRANSMISSION",
      "value": "M1MZ",
      "type": "psc_code"
   },
   {
      "label": "M1NA - OPERATION OF FUEL SUPPLY FACILITIES",
      "value": "M1NA",
      "type": "psc_code"
   },
   {
      "label": "M1NB - OPERATION OF HEATING AND COOLING PLANTS",
      "value": "M1NB",
      "type": "psc_code"
   },
   {
      "label": "M1NC - OPERATION OF POLLUTION ABATEMENT AND CONTROL FACILITIES",
      "value": "M1NC",
      "type": "psc_code"
   },
   {
      "label": "M1ND - OPERATION OF SEWAGE AND WASTE FACILITIES",
      "value": "M1ND",
      "type": "psc_code"
   },
   {
      "label": "M1NE - OPERATION OF WATER SUPPLY FACILITIES",
      "value": "M1NE",
      "type": "psc_code"
   },
   {
      "label": "M1NZ - OPERATION OF OTHER UTILITIES",
      "value": "M1NZ",
      "type": "psc_code"
   },
   {
      "label": "M1PA - OPERATION OF RECREATION FACILITIES (NON-BUILDING)",
      "value": "M1PA",
      "type": "psc_code"
   },
   {
      "label": "M1PB - OPERATION OF EXHIBIT DESIGN (NON-BUILDING)",
      "value": "M1PB",
      "type": "psc_code"
   },
   {
      "label": "M1PC - OPERATION OF UNIMPROVED REAL PROPERTY (LAND)",
      "value": "M1PC",
      "type": "psc_code"
   },
   {
      "label": "M1PD - OPERATION OF WASTE TREATMENT AND STORAGE FACILITIES",
      "value": "M1PD",
      "type": "psc_code"
   },
   {
      "label": "M1PZ - OPERATION OF OTHER NON-BUILDING FACILITIES",
      "value": "M1PZ",
      "type": "psc_code"
   },
   {
      "label": "M1QA - OPERATION OF RESTORATION OF REAL PROPERTY (PUBLIC OR PRIVATE)",
      "value": "M1QA",
      "type": "psc_code"
   },
   {
      "label": "M2 - OPERATION OF SHIPS, SMALL CRAFTS, PONTOONS AND FLOATING DOCKS",
      "value": "M2",
      "type": "psc_code"
   },
   {
      "label": "M2 - OPERATE GOV OWNED NONBUILDING FACIL",
      "value": "M2",
      "type": "psc_code"
   },
   {
      "label": "M2AA - HUSBANDING SERVICES\u2014COMMUNICATIONS SERVICES",
      "value": "M2AA",
      "type": "psc_code"
   },
   {
      "label": "M2AB - HUSBANDING SERVICES\u2014FORCE PROTECTION",
      "value": "M2AB",
      "type": "psc_code"
   },
   {
      "label": "M2AC - HUSBANDING SERVICES\u2014REMOVAL SERVICES",
      "value": "M2AC",
      "type": "psc_code"
   },
   {
      "label": "M2AD - HUSBANDING SERVICES\u2014MATERIAL HANDLING",
      "value": "M2AD",
      "type": "psc_code"
   },
   {
      "label": "M2AE - HUSBANDING SERVICES\u2014PURCHASING SERVICES",
      "value": "M2AE",
      "type": "psc_code"
   },
   {
      "label": "M2AF - HUSBANDING SERVICES\u2014INCIDENTAL SERVICES",
      "value": "M2AF",
      "type": "psc_code"
   },
   {
      "label": "M2BA - HUSBANDING SERVICES\u2014TRANSPORTATION SERVICES",
      "value": "M2BA",
      "type": "psc_code"
   },
   {
      "label": "M2BB - HUSBANDING SERVICES\u2014FUEL SERVICES",
      "value": "M2BB",
      "type": "psc_code"
   },
   {
      "label": "M2BZ - HUSBANDING SERVICES\u2014OTHER PORT SERVICES",
      "value": "M2BZ",
      "type": "psc_code"
   },
   {
      "label": "M2CA - SHIP HUSBANDING SERVICES\u2014MANAGEMENT/INTEGRATION SERVICE",
      "value": "M2CA",
      "type": "psc_code"
   },
   {
      "label": "M211 - OPERATION OF DAMS",
      "value": "M211",
      "type": "psc_code"
   },
   {
      "label": "M212 - OPERATION OF CANALS",
      "value": "M212",
      "type": "psc_code"
   },
   {
      "label": "M213 - OPER OF GOVT MINE FIRE CONT",
      "value": "M213",
      "type": "psc_code"
   },
   {
      "label": "M214 - OPER OF GOVT MINE SUBSIDENCE CONTR",
      "value": "M214",
      "type": "psc_code"
   },
   {
      "label": "M215 - OPER OF GOVT SURFACE MINE RECLAM",
      "value": "M215",
      "type": "psc_code"
   },
   {
      "label": "M216 - OPERATION OF DREDGING",
      "value": "M216",
      "type": "psc_code"
   },
   {
      "label": "M219 - OPER OF GOVT OTHER CONSV STRUCTURE",
      "value": "M219",
      "type": "psc_code"
   },
   {
      "label": "M221 - OPERATION OF AIRPORT SERVICE ROADS",
      "value": "M221",
      "type": "psc_code"
   },
   {
      "label": "M222 - OPER OF GOVT HWYS-RDS-STS-BRDGS-RAI",
      "value": "M222",
      "type": "psc_code"
   },
   {
      "label": "M223 - OPER OF GOVT TUNNELS-SUBSURF STRUC",
      "value": "M223",
      "type": "psc_code"
   },
   {
      "label": "M224 - OPER OF GOVT PARKING FACILITIES",
      "value": "M224",
      "type": "psc_code"
   },
   {
      "label": "M231 - OPERATION OF EPG FACILITIES - COAL",
      "value": "M231",
      "type": "psc_code"
   },
   {
      "label": "M232 - OPERATION OF EPG FACILITIES - GAS",
      "value": "M232",
      "type": "psc_code"
   },
   {
      "label": "M233 - OPER OF GOVT EPG - GEOTHERMAL",
      "value": "M233",
      "type": "psc_code"
   },
   {
      "label": "M234 - OPERATION OF EPG FACILITIES - HYDRO",
      "value": "M234",
      "type": "psc_code"
   },
   {
      "label": "M235 - OPER OF GOVT EPG - NUCLEAR",
      "value": "M235",
      "type": "psc_code"
   },
   {
      "label": "M236 - OPER OF GOVT EPG - PETROLEUM",
      "value": "M236",
      "type": "psc_code"
   },
   {
      "label": "M237 - OPERATION OF EPG FACILITIES - SOLAR",
      "value": "M237",
      "type": "psc_code"
   },
   {
      "label": "M239 - OPER OF GOVT EPG - OTHER",
      "value": "M239",
      "type": "psc_code"
   },
   {
      "label": "M241 - OPERATION OF FUEL SUPPLY FACILITIES",
      "value": "M241",
      "type": "psc_code"
   },
   {
      "label": "M242 - OPER OF GOVT HEATING & COOL PLANTS",
      "value": "M242",
      "type": "psc_code"
   },
   {
      "label": "M243 - OPER OF GOVT POLLUTION ABATEMENT",
      "value": "M243",
      "type": "psc_code"
   },
   {
      "label": "M244 - OPER OF GOVT SEWAGE & WASTE",
      "value": "M244",
      "type": "psc_code"
   },
   {
      "label": "M245 - OPER OF GOVT WATER SUPPLY",
      "value": "M245",
      "type": "psc_code"
   },
   {
      "label": "M249 - OPERATION OF OTHER UTILITIES",
      "value": "M249",
      "type": "psc_code"
   },
   {
      "label": "M291 - OPER OF GOVT RECREA NON-BLDG STRUC",
      "value": "M291",
      "type": "psc_code"
   },
   {
      "label": "M292 - OPER OF GOVT EXHIBIT (NON BLDG)",
      "value": "M292",
      "type": "psc_code"
   },
   {
      "label": "M293 - OPER OF GOVT UNIMPROVED REAL PROP",
      "value": "M293",
      "type": "psc_code"
   },
   {
      "label": "M294 - OPER OF GOVT WASTE TRMT-STORE FAC",
      "value": "M294",
      "type": "psc_code"
   },
   {
      "label": "M299 - OPER OF GOVT ALL OTH NON-BLDG FACS",
      "value": "M299",
      "type": "psc_code"
   },
   {
      "label": "M3 - OPERATE RESTORATION ACTIVITIES",
      "value": "M3",
      "type": "psc_code"
   },
   {
      "label": "M300 - OPER OF GOVT RESTORATION",
      "value": "M300",
      "type": "psc_code"
   },
   {
      "label": "N - INSTALLATION OF EQUIPMENT",
      "value": "N",
      "type": "psc_code"
   },
   {
      "label": "N0 - INSTALLATION OF EQUIPMENT",
      "value": "N0",
      "type": "psc_code"
   },
   {
      "label": "N010 - INSTALLATION OF EQUIPMENT- WEAPONS",
      "value": "N010",
      "type": "psc_code"
   },
   {
      "label": "N010 - INSTALLATION OF EQUIPMENT: WEAPONS",
      "value": "N010",
      "type": "psc_code"
   },
   {
      "label": "N011 - INSTALLATION OF EQUIPMENT- NUCLEAR ORDNANCE",
      "value": "N011",
      "type": "psc_code"
   },
   {
      "label": "N011 - INSTALL OF NUCLEAR ORDNANCE",
      "value": "N011",
      "type": "psc_code"
   },
   {
      "label": "N012 - INSTALLATION OF EQUIPMENT- FIRE CONTROL EQUIPMENT",
      "value": "N012",
      "type": "psc_code"
   },
   {
      "label": "N012 - INSTALL OF FIRE CONT EQ",
      "value": "N012",
      "type": "psc_code"
   },
   {
      "label": "N013 - INSTALLATION OF EQUIPMENT- AMMUNITION AND EXPLOSIVES",
      "value": "N013",
      "type": "psc_code"
   },
   {
      "label": "N013 - INSTALL OF AMMO & EXPLOSIVES",
      "value": "N013",
      "type": "psc_code"
   },
   {
      "label": "N014 - INSTALLATION OF EQUIPMENT- GUIDED MISSILES",
      "value": "N014",
      "type": "psc_code"
   },
   {
      "label": "N014 - INSTALL OF GUIDED MISSILES",
      "value": "N014",
      "type": "psc_code"
   },
   {
      "label": "N015 - INSTALLATION OF EQUIPMENT- AIRCRAFT AND AIRFRAME STRUCTURAL COMPONENTS",
      "value": "N015",
      "type": "psc_code"
   },
   {
      "label": "N015 - INSTALL OF AIRCRAFT",
      "value": "N015",
      "type": "psc_code"
   },
   {
      "label": "N016 - INSTALLATION OF EQUIPMENT- AIRCRAFT COMPONENTS AND ACCESSORIES",
      "value": "N016",
      "type": "psc_code"
   },
   {
      "label": "N016 - INSTALL OF AIRCRAFT COMPONENTS",
      "value": "N016",
      "type": "psc_code"
   },
   {
      "label": "N017 - INSTALLATION OF EQUIPMENT- AIRCRAFT LAUNCHING, LANDING, AND GROUND HANDLING EQUIPMENT",
      "value": "N017",
      "type": "psc_code"
   },
   {
      "label": "N017 - INSTALL OF AIRCRAFT GROUND EQ",
      "value": "N017",
      "type": "psc_code"
   },
   {
      "label": "N018 - INSTALLATION OF EQUIPMENT- SPACE VEHICLES",
      "value": "N018",
      "type": "psc_code"
   },
   {
      "label": "N018 - INSTALL OF SPACE VEHICLES",
      "value": "N018",
      "type": "psc_code"
   },
   {
      "label": "N019 - INSTALL OF SHIPS-SML CRAFT-DOCKS",
      "value": "N019",
      "type": "psc_code"
   },
   {
      "label": "N019 - INSTALLATION OF EQUIPMENT- SHIPS, SMALL CRAFT, PONTOONS, AND FLOATING DOCKS",
      "value": "N019",
      "type": "psc_code"
   },
   {
      "label": "N020 - INSTALL OF SHIP & MARINE EQ",
      "value": "N020",
      "type": "psc_code"
   },
   {
      "label": "N020 - INSTALLATION OF EQUIPMENT- SHIP AND MARINE EQUIPMENT",
      "value": "N020",
      "type": "psc_code"
   },
   {
      "label": "N022 - INSTALLATION OF EQUIPMENT- RAILWAY EQUIPMENT",
      "value": "N022",
      "type": "psc_code"
   },
   {
      "label": "N022 - INSTALL OF RAILWAY EQ",
      "value": "N022",
      "type": "psc_code"
   },
   {
      "label": "N023 - INSTALLATION OF EQUIPMENT- GROUND EFFECT VEHICLES, MOTOR VEHICLES, TRAILERS, AND CYCLES",
      "value": "N023",
      "type": "psc_code"
   },
   {
      "label": "N023 - INSTALL OF VEHICLES-TRAILERS-CYC",
      "value": "N023",
      "type": "psc_code"
   },
   {
      "label": "N024 - INSTALLATION OF EQUIPMENT- TRACTORS",
      "value": "N024",
      "type": "psc_code"
   },
   {
      "label": "N024 - INSTALLATION OF EQUIPMENT: TRACTORS",
      "value": "N024",
      "type": "psc_code"
   },
   {
      "label": "N025 - INSTALL OF VEHICULAR EQ",
      "value": "N025",
      "type": "psc_code"
   },
   {
      "label": "N025 - INSTALLATION OF EQUIPMENT- VEHICULAR EQUIPMENT COMPONENTS",
      "value": "N025",
      "type": "psc_code"
   },
   {
      "label": "N026 - INSTALL OF TIRES & TUBES",
      "value": "N026",
      "type": "psc_code"
   },
   {
      "label": "N026 - INSTALLATION OF EQUIPMENT- TIRES AND TUBES",
      "value": "N026",
      "type": "psc_code"
   },
   {
      "label": "N028 - INSTALLATION OF EQUIPMENT- ENGINES, TURBINES, AND COMPONENTS",
      "value": "N028",
      "type": "psc_code"
   },
   {
      "label": "N028 - INSTALL OF ENGINES & TURBINES",
      "value": "N028",
      "type": "psc_code"
   },
   {
      "label": "N029 - INSTALL OF ENGINE ACCESSORIES",
      "value": "N029",
      "type": "psc_code"
   },
   {
      "label": "N029 - INSTALLATION OF EQUIPMENT- ENGINE ACCESSORIES",
      "value": "N029",
      "type": "psc_code"
   },
   {
      "label": "N030 - INSTALL OF MECH POWER TRANS EQ",
      "value": "N030",
      "type": "psc_code"
   },
   {
      "label": "N030 - INSTALLATION OF EQUIPMENT- MECHANICAL POWER TRANSMISSION EQUIPMENT",
      "value": "N030",
      "type": "psc_code"
   },
   {
      "label": "N031 - INSTALLATION OF EQUIPMENT- BEARINGS",
      "value": "N031",
      "type": "psc_code"
   },
   {
      "label": "N031 - INSTALLATION OF EQUIPMENT: BEARINGS",
      "value": "N031",
      "type": "psc_code"
   },
   {
      "label": "N032 - INSTALLATION OF EQUIPMENT- WOODWORKING MACHINERY AND EQUIPMENT",
      "value": "N032",
      "type": "psc_code"
   },
   {
      "label": "N032 - INSTALL OF WOODWORKING MACHINE",
      "value": "N032",
      "type": "psc_code"
   },
   {
      "label": "N034 - INSTALL OF METALWORK MACHINE",
      "value": "N034",
      "type": "psc_code"
   },
   {
      "label": "N034 - INSTALLATION OF EQUIPMENT- METALWORKING MACHINERY",
      "value": "N034",
      "type": "psc_code"
   },
   {
      "label": "N035 - INSTALL OF SERVICE & TRADE EQ",
      "value": "N035",
      "type": "psc_code"
   },
   {
      "label": "N035 - INSTALLATION OF EQUIPMENT- SERVICE AND TRADE EQUIPMENT",
      "value": "N035",
      "type": "psc_code"
   },
   {
      "label": "N036 - INSTALL OF SP INDUSTRY MACHINERY",
      "value": "N036",
      "type": "psc_code"
   },
   {
      "label": "N036 - INSTALLATION OF EQUIPMENT- SPECIAL INDUSTRY MACHINERY",
      "value": "N036",
      "type": "psc_code"
   },
   {
      "label": "N037 - INSTALL OF AGRICULTURE MACHINE",
      "value": "N037",
      "type": "psc_code"
   },
   {
      "label": "N037 - INSTALLATION OF EQUIPMENT- AGRICULTURAL MACHINERY AND EQUIPMENT",
      "value": "N037",
      "type": "psc_code"
   },
   {
      "label": "N038 - INSTALLATION OF EQUIPMENT- CONSTRUCTION, MINING, EXCAVATING, AND HIGHWAY MAINTENANCE EQUIPMENT",
      "value": "N038",
      "type": "psc_code"
   },
   {
      "label": "N038 - INSTALL OF CONTRUCT EQ",
      "value": "N038",
      "type": "psc_code"
   },
   {
      "label": "N039 - INSTALL OF MATERIALS HANDLING EQ",
      "value": "N039",
      "type": "psc_code"
   },
   {
      "label": "N039 - INSTALLATION OF EQUIPMENT- MATERIALS HANDLING EQUIPMENT",
      "value": "N039",
      "type": "psc_code"
   },
   {
      "label": "N040 - INSTALL OF ROPE-CABLE-CHAIN",
      "value": "N040",
      "type": "psc_code"
   },
   {
      "label": "N040 - INSTALLATION OF EQUIPMENT- ROPE, CABLE, CHAIN, AND FITTINGS",
      "value": "N040",
      "type": "psc_code"
   },
   {
      "label": "N041 - INSTALLATION OF EQUIPMENT- REFRIGERATION, AIR CONDITIONING, AND AIR CIRCULATING EQUIPMENT",
      "value": "N041",
      "type": "psc_code"
   },
   {
      "label": "N041 - INSTALL OF REFRIGERATION - AC EQ",
      "value": "N041",
      "type": "psc_code"
   },
   {
      "label": "N042 - INSTALLATION OF EQUIPMENT- FIRE FIGHTING/RESCUE/SAFETY EQUIPMENT; ENVIRON PROTECT EQUIPMENT/MATLS",
      "value": "N042",
      "type": "psc_code"
   },
   {
      "label": "N042 - INSTALL OF FIRE-RESCUE-SAFETY EQ",
      "value": "N042",
      "type": "psc_code"
   },
   {
      "label": "N043 - INSTALL OF PUMPS & COMPRESSORS",
      "value": "N043",
      "type": "psc_code"
   },
   {
      "label": "N043 - INSTALLATION OF EQUIPMENT- PUMPS AND COMPRESSORS",
      "value": "N043",
      "type": "psc_code"
   },
   {
      "label": "N044 - INSTALL OF FURNACE-NUCLEAR REAC",
      "value": "N044",
      "type": "psc_code"
   },
   {
      "label": "N044 - INSTALLATION OF EQUIPMENT- FURNACE, STEAM PLANT, AND DRYING EQUIPMENT; NUCLEAR REACTORS",
      "value": "N044",
      "type": "psc_code"
   },
   {
      "label": "N045 - INSTALL OF PLUMBING-HEATING EQ",
      "value": "N045",
      "type": "psc_code"
   },
   {
      "label": "N045 - INSTALLATION OF EQUIPMENT- PLUMBING, HEATING, AND WASTE DISPOSAL EQUIPMENT",
      "value": "N045",
      "type": "psc_code"
   },
   {
      "label": "N046 - INSTALL OF WATER PURIFICATION EQ",
      "value": "N046",
      "type": "psc_code"
   },
   {
      "label": "N046 - INSTALLATION OF EQUIPMENT- WATER PURIFICATION AND SEWAGE TREATMENT EQUIPMENT",
      "value": "N046",
      "type": "psc_code"
   },
   {
      "label": "N047 - INSTALLATION OF EQUIPMENT- PIPE, TUBING, HOSE, AND FITTINGS",
      "value": "N047",
      "type": "psc_code"
   },
   {
      "label": "N047 - INSTALL OF PIPE-TUBING-HOSE",
      "value": "N047",
      "type": "psc_code"
   },
   {
      "label": "N048 - INSTALLATION OF EQUIPMENT: VALVES",
      "value": "N048",
      "type": "psc_code"
   },
   {
      "label": "N048 - INSTALLATION OF EQUIPMENT- VALVES",
      "value": "N048",
      "type": "psc_code"
   },
   {
      "label": "N049 - INSTALLATION OF EQUIPMENT- MAINTENANCE AND REPAIR SHOP EQUIPMENT",
      "value": "N049",
      "type": "psc_code"
   },
   {
      "label": "N049 - INSTALL OF MAINT REPAIR SHOP EQ",
      "value": "N049",
      "type": "psc_code"
   },
   {
      "label": "N051 - INSTALL OF HAND TOOLS",
      "value": "N051",
      "type": "psc_code"
   },
   {
      "label": "N051 - INSTALLATION OF EQUIPMENT- HAND TOOLS",
      "value": "N051",
      "type": "psc_code"
   },
   {
      "label": "N052 - INSTALLATION OF EQUIPMENT- MEASURING TOOLS",
      "value": "N052",
      "type": "psc_code"
   },
   {
      "label": "N052 - INSTALL OF MEASURING TOOLS",
      "value": "N052",
      "type": "psc_code"
   },
   {
      "label": "N053 - INSTALLATION OF EQUIPMENT- HARDWARE AND ABRASIVES",
      "value": "N053",
      "type": "psc_code"
   },
   {
      "label": "N053 - INSTALL OF HARDWARE & ABRASIVES",
      "value": "N053",
      "type": "psc_code"
   },
   {
      "label": "N054 - INSTALLATION OF EQUIPMENT- PREFABRICATED STRUCTURES AND SCAFFOLDING",
      "value": "N054",
      "type": "psc_code"
   },
   {
      "label": "N054 - INSTALL OF PREFAB STRUCTURES",
      "value": "N054",
      "type": "psc_code"
   },
   {
      "label": "N055 - INSTALL OF LUMBER & MILLWORK",
      "value": "N055",
      "type": "psc_code"
   },
   {
      "label": "N055 - INSTALLATION OF EQUIPMENT- LUMBER, MILLWORK, PLYWOOD, AND VENEER",
      "value": "N055",
      "type": "psc_code"
   },
   {
      "label": "N056 - INSTALL OF CONTRUCT MATERIAL",
      "value": "N056",
      "type": "psc_code"
   },
   {
      "label": "N056 - INSTALLATION OF EQUIPMENT- CONSTRUCTION AND BUILDING MATERIALS",
      "value": "N056",
      "type": "psc_code"
   },
   {
      "label": "N058 - INSTALLATION OF EQUIPMENT- COMMUNICATION, DETECTION, AND COHERENT RADIATION EQUIPMENT",
      "value": "N058",
      "type": "psc_code"
   },
   {
      "label": "N058 - INSTALL OF COMMUNICATION EQ",
      "value": "N058",
      "type": "psc_code"
   },
   {
      "label": "N059 - INSTALL OF ELECT-ELCT EQ",
      "value": "N059",
      "type": "psc_code"
   },
   {
      "label": "N059 - INSTALLATION OF EQUIPMENT- ELECTRICAL AND ELECTRONIC EQUIPMENT COMPONENTS",
      "value": "N059",
      "type": "psc_code"
   },
   {
      "label": "N060 - INSTALL OF FIBER OPTICS MATERIAL",
      "value": "N060",
      "type": "psc_code"
   },
   {
      "label": "N060 - INSTALLATION OF EQUIPMENT- FIBER OPTICS MATERIALS, COMPONENTS, ASSEMBLIES, AND ACCESSORIES",
      "value": "N060",
      "type": "psc_code"
   },
   {
      "label": "N061 - INSTALL OF POWER DISTRIBUTION EQ",
      "value": "N061",
      "type": "psc_code"
   },
   {
      "label": "N061 - INSTALLATION OF EQUIPMENT- ELECTRIC WIRE AND POWER DISTRIBUTION EQUIPMENT",
      "value": "N061",
      "type": "psc_code"
   },
   {
      "label": "N062 - INSTALL OF LIGHTING FIXTURES",
      "value": "N062",
      "type": "psc_code"
   },
   {
      "label": "N062 - INSTALLATION OF EQUIPMENT- LIGHTING FIXTURES AND LAMPS",
      "value": "N062",
      "type": "psc_code"
   },
   {
      "label": "N063 - INSTALLATION OF EQUIPMENT- ALARM, SIGNAL, AND SECURITY DETECTION SYSTEMS",
      "value": "N063",
      "type": "psc_code"
   },
   {
      "label": "N063 - INSTALL OF ALARM & SIGNAL SYSTEM",
      "value": "N063",
      "type": "psc_code"
   },
   {
      "label": "N065 - INSTALLATION OF EQUIPMENT- MEDICAL, DENTAL, AND VETERINARY EQUIPMENT AND SUPPLIES",
      "value": "N065",
      "type": "psc_code"
   },
   {
      "label": "N065 - INSTALL OF MEDICAL-DENTAL-VET EQ",
      "value": "N065",
      "type": "psc_code"
   },
   {
      "label": "N066 - INSTALL OF INSTRUMENTS & LAB EQ",
      "value": "N066",
      "type": "psc_code"
   },
   {
      "label": "N066 - INSTALLATION OF EQUIPMENT- INSTRUMENTS AND LABORATORY EQUIPMENT",
      "value": "N066",
      "type": "psc_code"
   },
   {
      "label": "N067 - INSTALLATION OF EQUIPMENT- PHOTOGRAPHIC EQUIPMENT",
      "value": "N067",
      "type": "psc_code"
   },
   {
      "label": "N067 - INSTALL OF PHOTOGRAPHIC EQ",
      "value": "N067",
      "type": "psc_code"
   },
   {
      "label": "N068 - INSTALL OF CHEMICAL PRODUCTS",
      "value": "N068",
      "type": "psc_code"
   },
   {
      "label": "N068 - INSTALLATION OF EQUIPMENT- CHEMICALS AND CHEMICAL PRODUCTS",
      "value": "N068",
      "type": "psc_code"
   },
   {
      "label": "N069 - INSTALL OF TRAINING AIDS-DEVICES",
      "value": "N069",
      "type": "psc_code"
   },
   {
      "label": "N069 - INSTALLATION OF EQUIPMENT- TRAINING AIDS AND DEVICES",
      "value": "N069",
      "type": "psc_code"
   },
   {
      "label": "N070 - INSTALLATION OF EQUIPMENT- ADP EQUIPMENT/SOFTWARE/SUPPLIES/SUPPORT EQUIPMENT",
      "value": "N070",
      "type": "psc_code"
   },
   {
      "label": "N070 - INSTALL OF ADP EQ & SUPPLIES",
      "value": "N070",
      "type": "psc_code"
   },
   {
      "label": "N071 - INSTALLATION OF EQUIPMENT- FURNITURE",
      "value": "N071",
      "type": "psc_code"
   },
   {
      "label": "N071 - INSTALL OF FURNITURE",
      "value": "N071",
      "type": "psc_code"
   },
   {
      "label": "N072 - INSTALLATION OF EQUIPMENT- HOUSEHOLD AND COMMERCIAL FURNISHINGS AND APPLIANCES",
      "value": "N072",
      "type": "psc_code"
   },
   {
      "label": "N072 - INSTALL OF HOUSEHOLD FURNISHINGS",
      "value": "N072",
      "type": "psc_code"
   },
   {
      "label": "N073 - INSTALLATION OF EQUIPMENT- FOOD PREPARATION AND SERVING EQUIPMENT",
      "value": "N073",
      "type": "psc_code"
   },
   {
      "label": "N073 - INSTALL OF FOOD PREP-SERVING EQ",
      "value": "N073",
      "type": "psc_code"
   },
   {
      "label": "N074 - INSTALLATION OF EQUIPMENT- OFFICE MACHINES, TEXT PROCESSING SYSTEMS, AND VISIBLE RECORD EQUIPMENT",
      "value": "N074",
      "type": "psc_code"
   },
   {
      "label": "N074 - INSTALL OF OFFICE MACHINES",
      "value": "N074",
      "type": "psc_code"
   },
   {
      "label": "N075 - INSTALLATION OF EQUIPMENT- OFFICE SUPPLIES AND DEVICES",
      "value": "N075",
      "type": "psc_code"
   },
   {
      "label": "N075 - INSTALL OF OFFICE SUPPLIES",
      "value": "N075",
      "type": "psc_code"
   },
   {
      "label": "N076 - INSTALLATION OF EQUIPMENT- BOOKS, MAPS, AND OTHER PUBLICATIONS",
      "value": "N076",
      "type": "psc_code"
   },
   {
      "label": "N076 - INSTALL OF BOOKS-MAPS-PUBS",
      "value": "N076",
      "type": "psc_code"
   },
   {
      "label": "N077 - INSTALLATION OF EQUIPMENT- MUSICAL INST/PHONOGRAPH/HOME RADIO",
      "value": "N077",
      "type": "psc_code"
   },
   {
      "label": "N077 - INSTALL OF MUSICAL INST-RADIO-TV",
      "value": "N077",
      "type": "psc_code"
   },
   {
      "label": "N078 - INSTALLATION OF EQUIPMENT- RECREATIONAL AND ATHLETIC EQUIPMENT",
      "value": "N078",
      "type": "psc_code"
   },
   {
      "label": "N078 - INSTALL OF RECREATIONAL EQ",
      "value": "N078",
      "type": "psc_code"
   },
   {
      "label": "N079 - INSTALL OF CLEANING EQ",
      "value": "N079",
      "type": "psc_code"
   },
   {
      "label": "N079 - INSTALLATION OF EQUIPMENT- CLEANING EQUIPMENT AND SUPPLIES",
      "value": "N079",
      "type": "psc_code"
   },
   {
      "label": "N080 - INSTALL OF BRUSHES-PAINTS-SEALER",
      "value": "N080",
      "type": "psc_code"
   },
   {
      "label": "N080 - INSTALLATION OF EQUIPMENT- BRUSHES, PAINTS, SEALERS, AND ADHESIVES",
      "value": "N080",
      "type": "psc_code"
   },
   {
      "label": "N081 - INSTALL OF CONTAINERS-PACKAGING",
      "value": "N081",
      "type": "psc_code"
   },
   {
      "label": "N081 - INSTALLATION OF EQUIPMENT- CONTAINERS, PACKAGING, AND PACKING SUPPLIES",
      "value": "N081",
      "type": "psc_code"
   },
   {
      "label": "N083 - INSTALL OF TEXTILES-LEATHER-FURS",
      "value": "N083",
      "type": "psc_code"
   },
   {
      "label": "N083 - INSTALLATION OF EQUIPMENT- TEXTILES, LEATHER, FURS, APPAREL AND SHOE FINDINGS, TENTS AND FLAGS",
      "value": "N083",
      "type": "psc_code"
   },
   {
      "label": "N084 - INSTALL OF CLOTHING - INDIVID EQ",
      "value": "N084",
      "type": "psc_code"
   },
   {
      "label": "N084 - INSTALLATION OF EQUIPMENT- CLOTHING, INDIVIDUAL EQUIPMENT, AND INSIGNIA",
      "value": "N084",
      "type": "psc_code"
   },
   {
      "label": "N085 - INSTALLATION OF EQUIPMENT- TOILETRIES",
      "value": "N085",
      "type": "psc_code"
   },
   {
      "label": "N085 - INSTALL OF TOILETRIES",
      "value": "N085",
      "type": "psc_code"
   },
   {
      "label": "N087 - INSTALLATION OF EQUIPMENT- AGRICULTURAL SUPPLIES",
      "value": "N087",
      "type": "psc_code"
   },
   {
      "label": "N087 - INSTALL OF AGRICULTURAL SUPPLIES",
      "value": "N087",
      "type": "psc_code"
   },
   {
      "label": "N088 - INSTALLATION OF EQUIPMENT- LIVE ANIMALS",
      "value": "N088",
      "type": "psc_code"
   },
   {
      "label": "N088 - INSTALL OF LIVE ANIMALS",
      "value": "N088",
      "type": "psc_code"
   },
   {
      "label": "N089 - INSTALLATION OF EQUIPMENT- SUBSISTENCE",
      "value": "N089",
      "type": "psc_code"
   },
   {
      "label": "N089 - INSTALL OF SUBSISTENCE",
      "value": "N089",
      "type": "psc_code"
   },
   {
      "label": "N091 - INSTALLATION OF EQUIPMENT- FUELS, LUBRICANTS, OILS, AND WAXES",
      "value": "N091",
      "type": "psc_code"
   },
   {
      "label": "N091 - INSTALL OF FUELS-LUBRICANTS-OILS",
      "value": "N091",
      "type": "psc_code"
   },
   {
      "label": "N093 - INSTALLATION OF EQUIPMENT- NONMETALLIC FABRICATED MATERIALS",
      "value": "N093",
      "type": "psc_code"
   },
   {
      "label": "N093 - INSTALL OF NONMETALIC FAB MAT",
      "value": "N093",
      "type": "psc_code"
   },
   {
      "label": "N094 - INSTALL OF NONMETALIC CRUDE MAT",
      "value": "N094",
      "type": "psc_code"
   },
   {
      "label": "N094 - INSTALLATION OF EQUIPMENT- NONMETALLIC CRUDE MATERIALS",
      "value": "N094",
      "type": "psc_code"
   },
   {
      "label": "N095 - INSTALLATION OF EQUIPMENT- METAL BARS, SHEETS, AND SHAPES",
      "value": "N095",
      "type": "psc_code"
   },
   {
      "label": "N095 - INSTALL OF METAL BARS & SHEETS",
      "value": "N095",
      "type": "psc_code"
   },
   {
      "label": "N096 - INSTALL OF ORES & MINERALS",
      "value": "N096",
      "type": "psc_code"
   },
   {
      "label": "N096 - INSTALLATION OF EQUIPMENT- ORES, MINERALS, AND THEIR PRIMARY PRODUCTS",
      "value": "N096",
      "type": "psc_code"
   },
   {
      "label": "N099 - INSTALL OF MISC EQ",
      "value": "N099",
      "type": "psc_code"
   },
   {
      "label": "N099 - INSTALLATION OF EQUIPMENT- MISCELLANEOUS",
      "value": "N099",
      "type": "psc_code"
   },
   {
      "label": "P - SALVAGE SERVICES",
      "value": "P",
      "type": "psc_code"
   },
   {
      "label": "P1 - DISPOSAL OF EXCESS/SURPLUS PROPERTY",
      "value": "P1",
      "type": "psc_code"
   },
   {
      "label": "P100 - DISPOSAL OF SURPLUS PROPERTY",
      "value": "P100",
      "type": "psc_code"
   },
   {
      "label": "P100 - SALVAGE- PREPARATION AND DISPOSAL OF EXCESS/SURPLUS PROPERTY",
      "value": "P100",
      "type": "psc_code"
   },
   {
      "label": "P2 - SALVAGE OF AIRCRAFT",
      "value": "P2",
      "type": "psc_code"
   },
   {
      "label": "P200 - SALVAGE OF AIRCRAFT",
      "value": "P200",
      "type": "psc_code"
   },
   {
      "label": "P200 - SALVAGE- AIRCRAFT",
      "value": "P200",
      "type": "psc_code"
   },
   {
      "label": "P3 - SALVAGE OF MARINE VESSELS",
      "value": "P3",
      "type": "psc_code"
   },
   {
      "label": "P300 - SALVAGE OF MARINE VESSELS",
      "value": "P300",
      "type": "psc_code"
   },
   {
      "label": "P300 - SALVAGE- MARINE VESSELS",
      "value": "P300",
      "type": "psc_code"
   },
   {
      "label": "P4 - DEMOLITION OF BUILDINGS",
      "value": "P4",
      "type": "psc_code"
   },
   {
      "label": "P400 - SALVAGE- DEMOLITION OF BUILDINGS",
      "value": "P400",
      "type": "psc_code"
   },
   {
      "label": "P400 - DEMOLITION OF BUILDINGS",
      "value": "P400",
      "type": "psc_code"
   },
   {
      "label": "P5 - DEMOLITION OF NONBUILDING FACILITY",
      "value": "P5",
      "type": "psc_code"
   },
   {
      "label": "P500 - DEMOLITION OF STRUCTURES/FACILITIES",
      "value": "P500",
      "type": "psc_code"
   },
   {
      "label": "P500 - SALVAGE- DEMOLITION OF STRUCTURES/FACILITIES (OTHER THAN BUILDINGS)",
      "value": "P500",
      "type": "psc_code"
   },
   {
      "label": "P9 - OTHER SALVAGE SERVICES",
      "value": "P9",
      "type": "psc_code"
   },
   {
      "label": "P999 - SALVAGE- OTHER",
      "value": "P999",
      "type": "psc_code"
   },
   {
      "label": "P999 - OTHER SALVAGE SERVICES",
      "value": "P999",
      "type": "psc_code"
   },
   {
      "label": "Q - MEDICAL SERVICES",
      "value": "Q",
      "type": "psc_code"
   },
   {
      "label": "Q1 - DEPENDENT MEDICARE SERVICES",
      "value": "Q1",
      "type": "psc_code"
   },
   {
      "label": "Q101 - MEDICAL- DEPENDENT MEDICARE",
      "value": "Q101",
      "type": "psc_code"
   },
   {
      "label": "Q101 - DEPENDENT MEDICARE SERVICES",
      "value": "Q101",
      "type": "psc_code"
   },
   {
      "label": "Q2 - GENERAL HEALTH CARE SERVICES",
      "value": "Q2",
      "type": "psc_code"
   },
   {
      "label": "Q201 - GENERAL HEALTH CARE SERVICES",
      "value": "Q201",
      "type": "psc_code"
   },
   {
      "label": "Q201 - MEDICAL- GENERAL HEALTH CARE",
      "value": "Q201",
      "type": "psc_code"
   },
   {
      "label": "Q3 - LABORATORY TESTING SERVICES",
      "value": "Q3",
      "type": "psc_code"
   },
   {
      "label": "Q301 - MEDICAL- LABORATORY TESTING",
      "value": "Q301",
      "type": "psc_code"
   },
   {
      "label": "Q301 - LABORATORY TESTING SERVICES",
      "value": "Q301",
      "type": "psc_code"
   },
   {
      "label": "Q4 - NURSING, NURSING HOME, EVAL/SCREEN",
      "value": "Q4",
      "type": "psc_code"
   },
   {
      "label": "Q401 - NURSING SERVICES",
      "value": "Q401",
      "type": "psc_code"
   },
   {
      "label": "Q401 - MEDICAL- NURSING",
      "value": "Q401",
      "type": "psc_code"
   },
   {
      "label": "Q402 - NURSING HOME CARE CONTRACTS",
      "value": "Q402",
      "type": "psc_code"
   },
   {
      "label": "Q402 - MEDICAL- NURSING HOME CARE CONTRACTS",
      "value": "Q402",
      "type": "psc_code"
   },
   {
      "label": "Q403 - EVALUATION AND SCREENING",
      "value": "Q403",
      "type": "psc_code"
   },
   {
      "label": "Q403 - MEDICAL- EVALUATION/SCREENING",
      "value": "Q403",
      "type": "psc_code"
   },
   {
      "label": "Q5 - MEDICAL, DENTAL, AND SURGICAL SVCS",
      "value": "Q5",
      "type": "psc_code"
   },
   {
      "label": "Q501 - ANESTHESIOLOGY SERVICES",
      "value": "Q501",
      "type": "psc_code"
   },
   {
      "label": "Q501 - MEDICAL- ANESTHESIOLOGY",
      "value": "Q501",
      "type": "psc_code"
   },
   {
      "label": "Q502 - CARDIO-VASCULAR SERVICES",
      "value": "Q502",
      "type": "psc_code"
   },
   {
      "label": "Q502 - MEDICAL- CARDIO-VASCULAR",
      "value": "Q502",
      "type": "psc_code"
   },
   {
      "label": "Q503 - MEDICAL- DENTISTRY",
      "value": "Q503",
      "type": "psc_code"
   },
   {
      "label": "Q503 - DENTISTRY SERVICES",
      "value": "Q503",
      "type": "psc_code"
   },
   {
      "label": "Q504 - MEDICAL- DERMATOLOGY",
      "value": "Q504",
      "type": "psc_code"
   },
   {
      "label": "Q504 - DERMATOLOGY SERVICES",
      "value": "Q504",
      "type": "psc_code"
   },
   {
      "label": "Q505 - MEDICAL- GASTROENTEROLOGY",
      "value": "Q505",
      "type": "psc_code"
   },
   {
      "label": "Q505 - GASTROENTEROLOGY SERVICES",
      "value": "Q505",
      "type": "psc_code"
   },
   {
      "label": "Q506 - MEDICAL- GERIATRIC",
      "value": "Q506",
      "type": "psc_code"
   },
   {
      "label": "Q506 - GERIATRIC SERVICES",
      "value": "Q506",
      "type": "psc_code"
   },
   {
      "label": "Q507 - MEDICAL- GYNECOLOGY",
      "value": "Q507",
      "type": "psc_code"
   },
   {
      "label": "Q507 - GYNECOLOGY SERVICES",
      "value": "Q507",
      "type": "psc_code"
   },
   {
      "label": "Q508 - MEDICAL- HEMATOLOGY",
      "value": "Q508",
      "type": "psc_code"
   },
   {
      "label": "Q508 - HEMATOLOGY SERVICES",
      "value": "Q508",
      "type": "psc_code"
   },
   {
      "label": "Q509 - INTERNAL MEDICINE SERVICES",
      "value": "Q509",
      "type": "psc_code"
   },
   {
      "label": "Q509 - MEDICAL- INTERNAL MEDICINE",
      "value": "Q509",
      "type": "psc_code"
   },
   {
      "label": "Q510 - MEDICAL- NEUROLOGY",
      "value": "Q510",
      "type": "psc_code"
   },
   {
      "label": "Q510 - NEUROLOGY SERVICES",
      "value": "Q510",
      "type": "psc_code"
   },
   {
      "label": "Q511 - MEDICAL- OPHTHALMOLOGY",
      "value": "Q511",
      "type": "psc_code"
   },
   {
      "label": "Q511 - OPHTHALMOLOGY SERVICES",
      "value": "Q511",
      "type": "psc_code"
   },
   {
      "label": "Q512 - OPTOMETRY SERVICES",
      "value": "Q512",
      "type": "psc_code"
   },
   {
      "label": "Q512 - MEDICAL- OPTOMETRY",
      "value": "Q512",
      "type": "psc_code"
   },
   {
      "label": "Q513 - ORTHOPEDIC SERVICES",
      "value": "Q513",
      "type": "psc_code"
   },
   {
      "label": "Q513 - MEDICAL- ORTHOPEDIC",
      "value": "Q513",
      "type": "psc_code"
   },
   {
      "label": "Q514 - MEDICAL- OTOLARYNGOLOGY",
      "value": "Q514",
      "type": "psc_code"
   },
   {
      "label": "Q514 - OTOLARYNGOLOGY SERVICES",
      "value": "Q514",
      "type": "psc_code"
   },
   {
      "label": "Q515 - PATHOLOGY SERVICES",
      "value": "Q515",
      "type": "psc_code"
   },
   {
      "label": "Q515 - MEDICAL- PATHOLOGY",
      "value": "Q515",
      "type": "psc_code"
   },
   {
      "label": "Q516 - PEDIATRIC SERVICES",
      "value": "Q516",
      "type": "psc_code"
   },
   {
      "label": "Q516 - MEDICAL- PEDIATRIC",
      "value": "Q516",
      "type": "psc_code"
   },
   {
      "label": "Q517 - MEDICAL- PHARMACOLOGY",
      "value": "Q517",
      "type": "psc_code"
   },
   {
      "label": "Q517 - PHARMACOLOGY SERVICES",
      "value": "Q517",
      "type": "psc_code"
   },
   {
      "label": "Q518 - PHYSICAL MEDICINE & REHABILITATION",
      "value": "Q518",
      "type": "psc_code"
   },
   {
      "label": "Q518 - MEDICAL- PHYSICAL MEDICINE/REHABILITATION",
      "value": "Q518",
      "type": "psc_code"
   },
   {
      "label": "Q519 - MEDICAL- PSYCHIATRY",
      "value": "Q519",
      "type": "psc_code"
   },
   {
      "label": "Q519 - PSYCHIATRY SERVICES",
      "value": "Q519",
      "type": "psc_code"
   },
   {
      "label": "Q520 - MEDICAL- PODIATRY",
      "value": "Q520",
      "type": "psc_code"
   },
   {
      "label": "Q520 - PODIATRY SERVICES",
      "value": "Q520",
      "type": "psc_code"
   },
   {
      "label": "Q521 - MEDICAL- PULMONARY",
      "value": "Q521",
      "type": "psc_code"
   },
   {
      "label": "Q521 - PULMONARY SERVICES",
      "value": "Q521",
      "type": "psc_code"
   },
   {
      "label": "Q522 - MEDICAL- RADIOLOGY",
      "value": "Q522",
      "type": "psc_code"
   },
   {
      "label": "Q522 - RADIOLOGY SERVICES",
      "value": "Q522",
      "type": "psc_code"
   },
   {
      "label": "Q523 - MEDICAL- SURGERY",
      "value": "Q523",
      "type": "psc_code"
   },
   {
      "label": "Q523 - SURGERY SERVICES",
      "value": "Q523",
      "type": "psc_code"
   },
   {
      "label": "Q524 - MEDICAL- THORACIC",
      "value": "Q524",
      "type": "psc_code"
   },
   {
      "label": "Q524 - THORACIC SERVICES",
      "value": "Q524",
      "type": "psc_code"
   },
   {
      "label": "Q525 - UROLOGY SERVICES",
      "value": "Q525",
      "type": "psc_code"
   },
   {
      "label": "Q525 - MEDICAL- UROLOGY",
      "value": "Q525",
      "type": "psc_code"
   },
   {
      "label": "Q526 - MEDICAL/PSYCH CONSULTATION SVCS",
      "value": "Q526",
      "type": "psc_code"
   },
   {
      "label": "Q526 - MEDICAL- MEDICAL/PSYCHIATRIC CONSULTATION",
      "value": "Q526",
      "type": "psc_code"
   },
   {
      "label": "Q527 - NUCLEAR MEDICINE",
      "value": "Q527",
      "type": "psc_code"
   },
   {
      "label": "Q527 - MEDICAL- NUCLEAR MEDICINE",
      "value": "Q527",
      "type": "psc_code"
   },
   {
      "label": "Q6 - CERTIFIED MEDICAL SUPPORT",
      "value": "Q6",
      "type": "psc_code"
   },
   {
      "label": "Q601 - MEDICAL CODING AND AUDITING",
      "value": "Q601",
      "type": "psc_code"
   },
   {
      "label": "Q602 - MEDICAL RECORDS",
      "value": "Q602",
      "type": "psc_code"
   },
   {
      "label": "Q603 - MEDICAL TRANSCRIPTIONS",
      "value": "Q603",
      "type": "psc_code"
   },
   {
      "label": "Q7 - SPECIALIZED TECHNICAL/ MEDICAL SUPPORT",
      "value": "Q7",
      "type": "psc_code"
   },
   {
      "label": "Q701 - SPECIALIZED MEDICAL SUPPORT",
      "value": "Q701",
      "type": "psc_code"
   },
   {
      "label": "Q702 - TECHNICAL MEDICAL SUPPORT",
      "value": "Q702",
      "type": "psc_code"
   },
   {
      "label": "Q8 - CLERICAL MEDICAL SUPPORT",
      "value": "Q8",
      "type": "psc_code"
   },
   {
      "label": "Q801 - MEDICAL APPOINTING SERVICES",
      "value": "Q801",
      "type": "psc_code"
   },
   {
      "label": "Q802 - CLERICAL MEDICAL SUPPORT",
      "value": "Q802",
      "type": "psc_code"
   },
   {
      "label": "Q9 - OTHER MEDICAL SERVICES",
      "value": "Q9",
      "type": "psc_code"
   },
   {
      "label": "Q901 - ASEPTIC HOUSEKEEPING SERVICES",
      "value": "Q901",
      "type": "psc_code"
   },
   {
      "label": "Q901 - HEALTHCARE ENVIRONMENTAL CLEANING",
      "value": "Q901",
      "type": "psc_code"
   },
   {
      "label": "Q999 - OTHER MEDICAL SERVICES",
      "value": "Q999",
      "type": "psc_code"
   },
   {
      "label": "Q999 - MEDICAL- OTHER",
      "value": "Q999",
      "type": "psc_code"
   },
   {
      "label": "R - SUPPORT SVCS (PROF, ADMIN, MGMT)",
      "value": "R",
      "type": "psc_code"
   },
   {
      "label": "R1 - BUILDINGS AND FACILITIES (OBSOLETE)",
      "value": "R1",
      "type": "psc_code"
   },
   {
      "label": "R111 - BLDGS & FAC / ADMIN & SVC BLDGS",
      "value": "R111",
      "type": "psc_code"
   },
   {
      "label": "R112 - BLDGS & FAC / AIRFIELD & MSL FAC",
      "value": "R112",
      "type": "psc_code"
   },
   {
      "label": "R113 - BLDGS & FAC / EDUCATIONAL BLDGS",
      "value": "R113",
      "type": "psc_code"
   },
   {
      "label": "R114 - BLDGS & FAC / HOSPITAL BUILDINGS",
      "value": "R114",
      "type": "psc_code"
   },
   {
      "label": "R115 - BLDGS & FAC / INDUSTRIAL BLDGS",
      "value": "R115",
      "type": "psc_code"
   },
   {
      "label": "R116 - BLDGS & FAC / RESIDENTIAL BLDGS",
      "value": "R116",
      "type": "psc_code"
   },
   {
      "label": "R117 - BLDGS & FAC / WAREHOUSE BLDGS",
      "value": "R117",
      "type": "psc_code"
   },
   {
      "label": "R118 - BLDGS & FAC / R&D FACILITIES",
      "value": "R118",
      "type": "psc_code"
   },
   {
      "label": "R119 - BLDGS & FAC / OTHER BUILDINGS",
      "value": "R119",
      "type": "psc_code"
   },
   {
      "label": "R121 - NON-BLDG / CONSERVATION & DEV",
      "value": "R121",
      "type": "psc_code"
   },
   {
      "label": "R122 - NON-BLDG / HWYS-ROADS-BRIDGES",
      "value": "R122",
      "type": "psc_code"
   },
   {
      "label": "R123 - NON-BLDG / ELECTRIC POWER GENR",
      "value": "R123",
      "type": "psc_code"
   },
   {
      "label": "R124 - NON-BLDG / UTILITIES",
      "value": "R124",
      "type": "psc_code"
   },
   {
      "label": "R129 - NON-BLDG / OTHER",
      "value": "R129",
      "type": "psc_code"
   },
   {
      "label": "",
      "value": "R199",
      "type": "psc_code"
   },
   {
      "label": "R2 - ARCHITECT-ENGINEER (OBSOLETE CODES)",
      "value": "R2",
      "type": "psc_code"
   },
   {
      "label": "R211 - A/E - NON CONTRUCT - GENERAL",
      "value": "R211",
      "type": "psc_code"
   },
   {
      "label": "R212 - A/E - DRAFTING ENGINEERING",
      "value": "R212",
      "type": "psc_code"
   },
   {
      "label": "R213 - A/E - INSPECT - NON CONTRUCT",
      "value": "R213",
      "type": "psc_code"
   },
   {
      "label": "R214 - A/E - MANAGEMENT ENGINEERING",
      "value": "R214",
      "type": "psc_code"
   },
   {
      "label": "R215 - A/E - PRODUCTION ENGINEERING",
      "value": "R215",
      "type": "psc_code"
   },
   {
      "label": "R216 - A/E - MARINE ENGINEERING",
      "value": "R216",
      "type": "psc_code"
   },
   {
      "label": "R219 - OTHER ARCHITECTS & ENGIN GEN",
      "value": "R219",
      "type": "psc_code"
   },
   {
      "label": "R3 - ADP SERVICES (OBSOLETE CODES)",
      "value": "R3",
      "type": "psc_code"
   },
   {
      "label": "R301 - ADP FACILITY MANAGEMENT",
      "value": "R301",
      "type": "psc_code"
   },
   {
      "label": "R302 - ADP SYSTEMS DEVELOP & PROGRAMMING",
      "value": "R302",
      "type": "psc_code"
   },
   {
      "label": "R303 - ADP SVCS/DATA ENTRY",
      "value": "R303",
      "type": "psc_code"
   },
   {
      "label": "R304 - ADP SVCS/DATA TRANSMISSION",
      "value": "R304",
      "type": "psc_code"
   },
   {
      "label": "R305 - ADP TELEPROCESSING SVCS",
      "value": "R305",
      "type": "psc_code"
   },
   {
      "label": "R306 - ADP SYSTEM ANALYSIS",
      "value": "R306",
      "type": "psc_code"
   },
   {
      "label": "R307 - AUTOMATED INFORMATION SYSTEM SVCS",
      "value": "R307",
      "type": "psc_code"
   },
   {
      "label": "R399 - OTHER ADP SVCS",
      "value": "R399",
      "type": "psc_code"
   },
   {
      "label": "R4 - PROFESSIONAL SERVICES",
      "value": "R4",
      "type": "psc_code"
   },
   {
      "label": "R401 - PERSONAL CARE SERVICES",
      "value": "R401",
      "type": "psc_code"
   },
   {
      "label": "R401 - SUPPORT- PROFESSIONAL: PERSONAL CARE (NON-MEDICAL)",
      "value": "R401",
      "type": "psc_code"
   },
   {
      "label": "R402 - REAL ESTATE BROKERAGE SERVICES",
      "value": "R402",
      "type": "psc_code"
   },
   {
      "label": "R402 - SUPPORT- PROFESSIONAL: REAL ESTATE BROKERAGE",
      "value": "R402",
      "type": "psc_code"
   },
   {
      "label": "R403 - REAL ESTATE BROKERAGE SERVICES",
      "value": "R403",
      "type": "psc_code"
   },
   {
      "label": "R404 - PROF SVCS/LAND SURVEYS - CADASTRAL",
      "value": "R404",
      "type": "psc_code"
   },
   {
      "label": "R404 - SUPPORT- PROFESSIONAL: LAND SURVEYS-CADASTRAL (NON-CONSTRUCTION)",
      "value": "R404",
      "type": "psc_code"
   },
   {
      "label": "R405 - SUPPORT- PROFESSIONAL: OPERATIONS RESEARCH/QUANTITATIVE ANALYSIS",
      "value": "R405",
      "type": "psc_code"
   },
   {
      "label": "R405 - OPERATIONS RESEARCH & QUANTITATIVE",
      "value": "R405",
      "type": "psc_code"
   },
   {
      "label": "R406 - SUPPORT- PROFESSIONAL: POLICY REVIEW/DEVELOPMENT",
      "value": "R406",
      "type": "psc_code"
   },
   {
      "label": "R406 - POLICY REVIEW/DEVELOPMENT SERVICES",
      "value": "R406",
      "type": "psc_code"
   },
   {
      "label": "R407 - PROGRAM EVALUATION SERVICES",
      "value": "R407",
      "type": "psc_code"
   },
   {
      "label": "R408 - SUPPORT- PROFESSIONAL: PROGRAM MANAGEMENT/SUPPORT",
      "value": "R408",
      "type": "psc_code"
   },
   {
      "label": "R408 - PROGRAM MANAGEMENT/SUPPORT SERVICES",
      "value": "R408",
      "type": "psc_code"
   },
   {
      "label": "R409 - PROGRAM REVIEW/DEVELOPMENT SERVICES",
      "value": "R409",
      "type": "psc_code"
   },
   {
      "label": "R410 - SUPPORT- PROFESSIONAL: PROGRAM EVALUATION/REVIEW/DEVELOPMENT",
      "value": "R410",
      "type": "psc_code"
   },
   {
      "label": "R411 - SUPPORT- PROFESSIONAL: REAL PROPERTY APPRAISALS",
      "value": "R411",
      "type": "psc_code"
   },
   {
      "label": "R411 - PROF SVCS/REAL PROPERTY APPRAISALS",
      "value": "R411",
      "type": "psc_code"
   },
   {
      "label": "R412 - SUPPORT- PROFESSIONAL: SIMULATION",
      "value": "R412",
      "type": "psc_code"
   },
   {
      "label": "R412 - SIMULATION",
      "value": "R412",
      "type": "psc_code"
   },
   {
      "label": "R413 - SUPPORT- PROFESSIONAL: SPECIFICATIONS DEVELOPMENT",
      "value": "R413",
      "type": "psc_code"
   },
   {
      "label": "R413 - SPECIFICATIONS DEVELOPMENT SERVICES",
      "value": "R413",
      "type": "psc_code"
   },
   {
      "label": "R414 - SYSTEMS ENGINEERING SERVICES",
      "value": "R414",
      "type": "psc_code"
   },
   {
      "label": "R415 - PROF SVCS/TECH SHARING-UTIL",
      "value": "R415",
      "type": "psc_code"
   },
   {
      "label": "R415 - SUPPORT- PROFESSIONAL: TECHNOLOGY SHARING/UTILIZATION",
      "value": "R415",
      "type": "psc_code"
   },
   {
      "label": "R416 - SUPPORT- PROFESSIONAL: VETERINARY/ANIMAL CARE",
      "value": "R416",
      "type": "psc_code"
   },
   {
      "label": "R416 - VETERINARY/ANIMAL CARE SERVICES",
      "value": "R416",
      "type": "psc_code"
   },
   {
      "label": "R418 - LEGAL SERVICES",
      "value": "R418",
      "type": "psc_code"
   },
   {
      "label": "R418 - SUPPORT- PROFESSIONAL: LEGAL",
      "value": "R418",
      "type": "psc_code"
   },
   {
      "label": "R419 - EDUCATIONAL SERVICES",
      "value": "R419",
      "type": "psc_code"
   },
   {
      "label": "R420 - SUPPORT- PROFESSIONAL: CERTIFICATIONS AND ACCREDITATIONS (OTHER THAN EDUC OR INFO TECH C&A)",
      "value": "R420",
      "type": "psc_code"
   },
   {
      "label": "R420 - CERTIFICATIONS & ACCREDIT PROD & IN",
      "value": "R420",
      "type": "psc_code"
   },
   {
      "label": "R421 - TECHNICAL ASSISTANCE",
      "value": "R421",
      "type": "psc_code"
   },
   {
      "label": "R422 - MARKET RESEARCH & PUBLIC OPINION SE",
      "value": "R422",
      "type": "psc_code"
   },
   {
      "label": "R422 - SUPPORT- PROFESSIONAL: MARKET RESEARCH/PUBLIC OPINION",
      "value": "R422",
      "type": "psc_code"
   },
   {
      "label": "R423 - SUPPORT- PROFESSIONAL: INTELLIGENCE",
      "value": "R423",
      "type": "psc_code"
   },
   {
      "label": "R423 - INTELLIGENCE SERVICES",
      "value": "R423",
      "type": "psc_code"
   },
   {
      "label": "R424 - SUPPORT- PROFESSIONAL: EXPERT WITNESS",
      "value": "R424",
      "type": "psc_code"
   },
   {
      "label": "R424 - EXPERT WITNESS",
      "value": "R424",
      "type": "psc_code"
   },
   {
      "label": "R425 - ENGINEERING AND TECHNICAL SERVICES",
      "value": "R425",
      "type": "psc_code"
   },
   {
      "label": "R425 - SUPPORT- PROFESSIONAL: ENGINEERING/TECHNICAL",
      "value": "R425",
      "type": "psc_code"
   },
   {
      "label": "R426 - SUPPORT- PROFESSIONAL: COMMUNICATIONS",
      "value": "R426",
      "type": "psc_code"
   },
   {
      "label": "R426 - COMMUNICATIONS SERVICES",
      "value": "R426",
      "type": "psc_code"
   },
   {
      "label": "R427 - PROF SVCS/WEATHER RPT-OBSERV",
      "value": "R427",
      "type": "psc_code"
   },
   {
      "label": "R427 - SUPPORT- PROFESSIONAL: WEATHER REPORTING/OBSERVATION",
      "value": "R427",
      "type": "psc_code"
   },
   {
      "label": "R428 - SUPPORT- PROFESSIONAL: INDUSTRIAL HYGIENICS",
      "value": "R428",
      "type": "psc_code"
   },
   {
      "label": "R428 - INDUSTRIAL HYGIENICS",
      "value": "R428",
      "type": "psc_code"
   },
   {
      "label": "R429 - SUPPORT- PROFESSIONAL: EMERGENCY RESPONSE/DISASTER PLANNING/PREPAREDNESS SUPPORT",
      "value": "R429",
      "type": "psc_code"
   },
   {
      "label": "R430 - SUPPORT- PROFESSIONAL: PHYSICAL SECURITY AND BADGING",
      "value": "R430",
      "type": "psc_code"
   },
   {
      "label": "R431 - SUPPORT- PROFESSIONAL: HUMAN RESOURCES",
      "value": "R431",
      "type": "psc_code"
   },
   {
      "label": "R497 - PERSONAL SERVICES CONTRACTS",
      "value": "R497",
      "type": "psc_code"
   },
   {
      "label": "R497 - SUPPORT- PROFESSIONAL: PERSONAL SERVICES CONTRACTS",
      "value": "R497",
      "type": "psc_code"
   },
   {
      "label": "R498 - SUPPORT- PROFESSIONAL: PATENT AND TRADEMARK",
      "value": "R498",
      "type": "psc_code"
   },
   {
      "label": "R498 - PATENT AND TRADEMARK SERVICES",
      "value": "R498",
      "type": "psc_code"
   },
   {
      "label": "R499 - OTHER PROFESSIONAL SERVICES",
      "value": "R499",
      "type": "psc_code"
   },
   {
      "label": "R499 - SUPPORT- PROFESSIONAL: OTHER",
      "value": "R499",
      "type": "psc_code"
   },
   {
      "label": "R5 - STUDIES (OBSOLETE CODES)",
      "value": "R5",
      "type": "psc_code"
   },
   {
      "label": "R502 - STUDY/AIR QUALITY",
      "value": "R502",
      "type": "psc_code"
   },
   {
      "label": "R503 - STUDY/ARCHEOLOGICAL-PALEONTOLOGICAL",
      "value": "R503",
      "type": "psc_code"
   },
   {
      "label": "R504 - STUDY/CHEMICAL-BIOLOGICAL",
      "value": "R504",
      "type": "psc_code"
   },
   {
      "label": "R505 - STUDY/COST BENEFIT",
      "value": "R505",
      "type": "psc_code"
   },
   {
      "label": "R506 - STUDY/DATA - OTHER THAN SCIENTIFIC",
      "value": "R506",
      "type": "psc_code"
   },
   {
      "label": "R507 - STUDY/ECONOMIC/SOCIO-ECO AND LABOR",
      "value": "R507",
      "type": "psc_code"
   },
   {
      "label": "R509 - STUDY/ENDANGERED SPECIES-PLANT/ANIM",
      "value": "R509",
      "type": "psc_code"
   },
   {
      "label": "R510 - STUDY/ENVIRONMENTAL ASSESSMENTS",
      "value": "R510",
      "type": "psc_code"
   },
   {
      "label": "R511 - STUDY/ENVIRONMENTAL BASELINE",
      "value": "R511",
      "type": "psc_code"
   },
   {
      "label": "R512 - STUDY/ENVIRONMENTAL IMPACT",
      "value": "R512",
      "type": "psc_code"
   },
   {
      "label": "R513 - STUDY/FEASIBILITY - NON-CONTRUCT",
      "value": "R513",
      "type": "psc_code"
   },
   {
      "label": "R516 - STUDY/FISHERIES AND ANIMAL",
      "value": "R516",
      "type": "psc_code"
   },
   {
      "label": "R517 - STUDY/GEOLOGICAL",
      "value": "R517",
      "type": "psc_code"
   },
   {
      "label": "R518 - STUDY/GEOPHYSICAL",
      "value": "R518",
      "type": "psc_code"
   },
   {
      "label": "R519 - STUDY/GEOTECHNICAL",
      "value": "R519",
      "type": "psc_code"
   },
   {
      "label": "R520 - STUDY/GRAZING-RANGE USE",
      "value": "R520",
      "type": "psc_code"
   },
   {
      "label": "R521 - STUDY/HISTORICAL",
      "value": "R521",
      "type": "psc_code"
   },
   {
      "label": "R522 - STUDY/LEGAL-LITIGATION",
      "value": "R522",
      "type": "psc_code"
   },
   {
      "label": "R524 - STUDY/MATHEMATICAL-STATISTICAL",
      "value": "R524",
      "type": "psc_code"
   },
   {
      "label": "R525 - STUDY/NATURAL RESOURCE",
      "value": "R525",
      "type": "psc_code"
   },
   {
      "label": "R526 - STUDY/OCEANOLOGICAL",
      "value": "R526",
      "type": "psc_code"
   },
   {
      "label": "R527 - STUDY/RECREATION",
      "value": "R527",
      "type": "psc_code"
   },
   {
      "label": "R528 - STUDY/REGULATORY",
      "value": "R528",
      "type": "psc_code"
   },
   {
      "label": "R529 - STUDY/SCIENTIFIC DATA",
      "value": "R529",
      "type": "psc_code"
   },
   {
      "label": "R530 - STUDY/SEISMOLOGICAL",
      "value": "R530",
      "type": "psc_code"
   },
   {
      "label": "R532 - STUDY/SOILS",
      "value": "R532",
      "type": "psc_code"
   },
   {
      "label": "R533 - STUDY/WATER QUALITY",
      "value": "R533",
      "type": "psc_code"
   },
   {
      "label": "R534 - STUDY/WILDLIFE",
      "value": "R534",
      "type": "psc_code"
   },
   {
      "label": "R537 - STUDY/MEDICAL AND HEALTH",
      "value": "R537",
      "type": "psc_code"
   },
   {
      "label": "R538 - STUDY/INTELLIGENCE",
      "value": "R538",
      "type": "psc_code"
   },
   {
      "label": "R539 - STUDY/AERONAUTIC/SPACE",
      "value": "R539",
      "type": "psc_code"
   },
   {
      "label": "R540 - STUDY/BUILDING TECHNOLOGY",
      "value": "R540",
      "type": "psc_code"
   },
   {
      "label": "R541 - STUDY/DEFENSE",
      "value": "R541",
      "type": "psc_code"
   },
   {
      "label": "R542 - STUDY/EDUCATIONAL",
      "value": "R542",
      "type": "psc_code"
   },
   {
      "label": "R543 - STUDY/ENERGY",
      "value": "R543",
      "type": "psc_code"
   },
   {
      "label": "R544 - STUDY/TECHNOLOGY",
      "value": "R544",
      "type": "psc_code"
   },
   {
      "label": "R545 - STUDY/HOUSING AND COMMUNITY DEVELOP",
      "value": "R545",
      "type": "psc_code"
   },
   {
      "label": "R546 - STUDY/SECURITY (PHYSICAL & PERSONAL",
      "value": "R546",
      "type": "psc_code"
   },
   {
      "label": "R547 - STUDY/ACCOUNTING/FINANCIAL MGT",
      "value": "R547",
      "type": "psc_code"
   },
   {
      "label": "R548 - STUDY/TRADE ISSUES",
      "value": "R548",
      "type": "psc_code"
   },
   {
      "label": "R549 - STUDY/FOREIGN & NAT'L SECURITY POL",
      "value": "R549",
      "type": "psc_code"
   },
   {
      "label": "R550 - STUDY/ORGANIZATION/ADMIN/PERSONNEL",
      "value": "R550",
      "type": "psc_code"
   },
   {
      "label": "R551 - STUDY/MOBILIZATION/PREPAREDNESS",
      "value": "R551",
      "type": "psc_code"
   },
   {
      "label": "R552 - STUDY/MANPOWER",
      "value": "R552",
      "type": "psc_code"
   },
   {
      "label": "R553 - STUDY/COMMUNICATIONS",
      "value": "R553",
      "type": "psc_code"
   },
   {
      "label": "R554 - STUDY/ACQUISITION POLICY/PROCEDURES",
      "value": "R554",
      "type": "psc_code"
   },
   {
      "label": "R599 - OTHER SPECIAL STUDY & Analysis",
      "value": "R599",
      "type": "psc_code"
   },
   {
      "label": "R6 - ADMINISTRATIVE SUPPORT SERVICES",
      "value": "R6",
      "type": "psc_code"
   },
   {
      "label": "R601 - ADMIN SVCS/MATERIAL MANAGEMENT",
      "value": "R601",
      "type": "psc_code"
   },
   {
      "label": "R602 - SUPPORT- ADMINISTRATIVE: COURIER/MESSENGER",
      "value": "R602",
      "type": "psc_code"
   },
   {
      "label": "R602 - COURIER AND MESSENGER SERVICES",
      "value": "R602",
      "type": "psc_code"
   },
   {
      "label": "R603 - TRANSCRIPTION SERVICES",
      "value": "R603",
      "type": "psc_code"
   },
   {
      "label": "R603 - SUPPORT- ADMINISTRATIVE: TRANSCRIPTION",
      "value": "R603",
      "type": "psc_code"
   },
   {
      "label": "R604 - SUPPORT- ADMINISTRATIVE: MAILING/DISTRIBUTION",
      "value": "R604",
      "type": "psc_code"
   },
   {
      "label": "R604 - MAILING AND DISTRIBUTION SERVICES",
      "value": "R604",
      "type": "psc_code"
   },
   {
      "label": "R605 - SUPPORT- ADMINISTRATIVE: LIBRARY",
      "value": "R605",
      "type": "psc_code"
   },
   {
      "label": "R605 - LIBRARY SERVICES",
      "value": "R605",
      "type": "psc_code"
   },
   {
      "label": "R606 - COURT REPORTING SERVICES",
      "value": "R606",
      "type": "psc_code"
   },
   {
      "label": "R606 - SUPPORT- ADMINISTRATIVE: COURT REPORTING",
      "value": "R606",
      "type": "psc_code"
   },
   {
      "label": "R607 - WORD PROCESSING/TYPING SERVICES",
      "value": "R607",
      "type": "psc_code"
   },
   {
      "label": "R607 - SUPPORT- ADMINISTRATIVE: WORD PROCESSING/TYPING",
      "value": "R607",
      "type": "psc_code"
   },
   {
      "label": "R608 - SUPPORT- ADMINISTRATIVE: TRANSLATION AND INTERPRETING",
      "value": "R608",
      "type": "psc_code"
   },
   {
      "label": "R608 - ADMIN SVCS/TRANSLATION-SIGN LANGUAG",
      "value": "R608",
      "type": "psc_code"
   },
   {
      "label": "R609 - STENOGRAPHIC SERVICES",
      "value": "R609",
      "type": "psc_code"
   },
   {
      "label": "R609 - SUPPORT- ADMINISTRATIVE: STENOGRAPHIC",
      "value": "R609",
      "type": "psc_code"
   },
   {
      "label": "R610 - SUPPORT- ADMINISTRATIVE:- PERSONAL PROPERTY MANAGEMENT",
      "value": "R610",
      "type": "psc_code"
   },
   {
      "label": "R610 - PERSONAL PROPERTY MANAGEMENT SERVIC",
      "value": "R610",
      "type": "psc_code"
   },
   {
      "label": "R611 - SUPPORT- ADMINISTRATIVE: CREDIT REPORTING",
      "value": "R611",
      "type": "psc_code"
   },
   {
      "label": "R611 - CREDIT REPORTING SERVICES",
      "value": "R611",
      "type": "psc_code"
   },
   {
      "label": "R612 - SUPPORT- ADMINISTRATIVE: INFORMATION RETRIEVAL",
      "value": "R612",
      "type": "psc_code"
   },
   {
      "label": "R612 - INFORMATION RETRIEVAL",
      "value": "R612",
      "type": "psc_code"
   },
   {
      "label": "R613 - POST OFFICE SERVICES",
      "value": "R613",
      "type": "psc_code"
   },
   {
      "label": "R613 - SUPPORT- ADMINISTRATIVE: POST OFFICE",
      "value": "R613",
      "type": "psc_code"
   },
   {
      "label": "R614 - PAPER SHREDDING SERVICES",
      "value": "R614",
      "type": "psc_code"
   },
   {
      "label": "R614 - SUPPORT- ADMINISTRATIVE: PAPER SHREDDING",
      "value": "R614",
      "type": "psc_code"
   },
   {
      "label": "R615 - SUPPORT- ADMINISTRATIVE: BACKGROUND INVESTIGATION",
      "value": "R615",
      "type": "psc_code"
   },
   {
      "label": "R616 - SUPPORT \u2013 ADMINISTRATIVE: PHYSICAL RECORDS MANAGEMENT SERVICES",
      "value": "R616",
      "type": "psc_code"
   },
   {
      "label": "R617 - SUPPORT \u2013 ADMINISTRATIVE: ELECTRONIC RECORDS MANAGEMENT SERVICES",
      "value": "R617",
      "type": "psc_code"
   },
   {
      "label": "R699 - OTHER ADMINISTRATIVE SUPPORT SVCS",
      "value": "R699",
      "type": "psc_code"
   },
   {
      "label": "R699 - SUPPORT- ADMINISTRATIVE: OTHER",
      "value": "R699",
      "type": "psc_code"
   },
   {
      "label": "R7 - MANAGEMENT SUPPORT SERVICES",
      "value": "R7",
      "type": "psc_code"
   },
   {
      "label": "R701 - ADVERTISING SERVICES",
      "value": "R701",
      "type": "psc_code"
   },
   {
      "label": "R701 - SUPPORT- MANAGEMENT: ADVERTISING",
      "value": "R701",
      "type": "psc_code"
   },
   {
      "label": "R702 - DATA COLLECTION SERVICES",
      "value": "R702",
      "type": "psc_code"
   },
   {
      "label": "R702 - SUPPORT- MANAGEMENT: DATA COLLECTION",
      "value": "R702",
      "type": "psc_code"
   },
   {
      "label": "R703 - ACCOUNTING SERVICES",
      "value": "R703",
      "type": "psc_code"
   },
   {
      "label": "R703 - SUPPORT- MANAGEMENT: ACCOUNTING",
      "value": "R703",
      "type": "psc_code"
   },
   {
      "label": "R704 - SUPPORT- MANAGEMENT: AUDITING",
      "value": "R704",
      "type": "psc_code"
   },
   {
      "label": "R704 - AUDITING SERVICES",
      "value": "R704",
      "type": "psc_code"
   },
   {
      "label": "R705 - SUPPORT- MANAGEMENT: DEBT COLLECTION",
      "value": "R705",
      "type": "psc_code"
   },
   {
      "label": "R705 - DEBT COLLECTION SERVICES",
      "value": "R705",
      "type": "psc_code"
   },
   {
      "label": "R706 - LOGISTICS SUPPORT SERVICES",
      "value": "R706",
      "type": "psc_code"
   },
   {
      "label": "R706 - SUPPORT- MANAGEMENT: LOGISTICS SUPPORT",
      "value": "R706",
      "type": "psc_code"
   },
   {
      "label": "R707 - SUPPORT- MANAGEMENT: CONTRACT/PROCUREMENT/ACQUISITION SUPPORT",
      "value": "R707",
      "type": "psc_code"
   },
   {
      "label": "R707 - MGT SVCS/CONTRACT & PROCUREMENT SUP",
      "value": "R707",
      "type": "psc_code"
   },
   {
      "label": "R708 - SUPPORT- MANAGEMENT: PUBLIC RELATIONS",
      "value": "R708",
      "type": "psc_code"
   },
   {
      "label": "R708 - PUBLIC RELATIONS SERVICES",
      "value": "R708",
      "type": "psc_code"
   },
   {
      "label": "R709 - ONGOING AUDIT OPERATIONS SUPPORT",
      "value": "R709",
      "type": "psc_code"
   },
   {
      "label": "R710 - FINANCIAL SERVICES",
      "value": "R710",
      "type": "psc_code"
   },
   {
      "label": "R710 - SUPPORT- MANAGEMENT: FINANCIAL",
      "value": "R710",
      "type": "psc_code"
   },
   {
      "label": "R711 - BANKING SERVICES",
      "value": "R711",
      "type": "psc_code"
   },
   {
      "label": "R711 - SUPPORT- MANAGEMENT: BANKING",
      "value": "R711",
      "type": "psc_code"
   },
   {
      "label": "R712 - SUPPORT- MANAGEMENT: COIN MINTING",
      "value": "R712",
      "type": "psc_code"
   },
   {
      "label": "R712 - COIN MINTING",
      "value": "R712",
      "type": "psc_code"
   },
   {
      "label": "R713 - SUPPORT- MANAGEMENT: BANKNOTE PRINTING",
      "value": "R713",
      "type": "psc_code"
   },
   {
      "label": "R713 - BANKNOTE PRINTING",
      "value": "R713",
      "type": "psc_code"
   },
   {
      "label": "R799 - OTHER MANAGEMENT SUPPORT SERVICES",
      "value": "R799",
      "type": "psc_code"
   },
   {
      "label": "R799 - SUPPORT- MANAGEMENT: OTHER",
      "value": "R799",
      "type": "psc_code"
   },
   {
      "label": "S - UTILITIES AND HOUSEKEEPING",
      "value": "S",
      "type": "psc_code"
   },
   {
      "label": "S1 - UTILITIES",
      "value": "S1",
      "type": "psc_code"
   },
   {
      "label": "S111 - UTILITIES- GAS",
      "value": "S111",
      "type": "psc_code"
   },
   {
      "label": "S111 - GAS SERVICES",
      "value": "S111",
      "type": "psc_code"
   },
   {
      "label": "S112 - UTILITIES- ELECTRIC",
      "value": "S112",
      "type": "psc_code"
   },
   {
      "label": "S112 - ELECTRIC SERVICES",
      "value": "S112",
      "type": "psc_code"
   },
   {
      "label": "S113 - TELEPHONE AND-OR COMMUNICATIONS SER",
      "value": "S113",
      "type": "psc_code"
   },
   {
      "label": "S114 - WATER SERVICES",
      "value": "S114",
      "type": "psc_code"
   },
   {
      "label": "S114 - UTILITIES- WATER",
      "value": "S114",
      "type": "psc_code"
   },
   {
      "label": "S119 - UTILITIES- OTHER",
      "value": "S119",
      "type": "psc_code"
   },
   {
      "label": "S119 - OTHER UTILITIES",
      "value": "S119",
      "type": "psc_code"
   },
   {
      "label": "S2 - HOUSEKEEPING SERVICES",
      "value": "S2",
      "type": "psc_code"
   },
   {
      "label": "S201 - HOUSEKEEPING- CUSTODIAL JANITORIAL",
      "value": "S201",
      "type": "psc_code"
   },
   {
      "label": "S201 - CUSTODIAL JANITORIAL SERVICES",
      "value": "S201",
      "type": "psc_code"
   },
   {
      "label": "S202 - FIRE PROTECTION SERVICES",
      "value": "S202",
      "type": "psc_code"
   },
   {
      "label": "S202 - HOUSEKEEPING- FIRE PROTECTION",
      "value": "S202",
      "type": "psc_code"
   },
   {
      "label": "S203 - HOUSEKEEPING- FOOD",
      "value": "S203",
      "type": "psc_code"
   },
   {
      "label": "S203 - FOOD SERVICES",
      "value": "S203",
      "type": "psc_code"
   },
   {
      "label": "S204 - HOUSEKEEPING- FUELING AND OTHER PETROLEUM",
      "value": "S204",
      "type": "psc_code"
   },
   {
      "label": "S204 - FUELING SERVICE",
      "value": "S204",
      "type": "psc_code"
   },
   {
      "label": "S205 - TRASH/GARBAGE COLLECTION",
      "value": "S205",
      "type": "psc_code"
   },
   {
      "label": "S205 - HOUSEKEEPING- TRASH/GARBAGE COLLECTION",
      "value": "S205",
      "type": "psc_code"
   },
   {
      "label": "S206 - HOUSEKEEPING- GUARD",
      "value": "S206",
      "type": "psc_code"
   },
   {
      "label": "S206 - GUARD SERVICES",
      "value": "S206",
      "type": "psc_code"
   },
   {
      "label": "S207 - INSECT AND RODENT CONTROL SERVICES",
      "value": "S207",
      "type": "psc_code"
   },
   {
      "label": "S207 - HOUSEKEEPING- INSECT/RODENT CONTROL",
      "value": "S207",
      "type": "psc_code"
   },
   {
      "label": "S208 - HOUSEKEEPING- LANDSCAPING/GROUNDSKEEPING",
      "value": "S208",
      "type": "psc_code"
   },
   {
      "label": "S208 - LANDSCAPING/GROUNDSKEEPING SERVICES",
      "value": "S208",
      "type": "psc_code"
   },
   {
      "label": "S209 - LAUNDRY AND DRYCLEANING SERVICES",
      "value": "S209",
      "type": "psc_code"
   },
   {
      "label": "S209 - HOUSEKEEPING- LAUNDRY/DRYCLEANING",
      "value": "S209",
      "type": "psc_code"
   },
   {
      "label": "S211 - SURVEILLANCE SERVICES",
      "value": "S211",
      "type": "psc_code"
   },
   {
      "label": "S211 - HOUSEKEEPING- SURVEILLANCE",
      "value": "S211",
      "type": "psc_code"
   },
   {
      "label": "S212 - HOUSEKEEPING- SOLID FUEL HANDLING",
      "value": "S212",
      "type": "psc_code"
   },
   {
      "label": "S212 - SOLID FUEL HANDLING SERVICES",
      "value": "S212",
      "type": "psc_code"
   },
   {
      "label": "S213 - MESSENGER SVCS",
      "value": "S213",
      "type": "psc_code"
   },
   {
      "label": "S214 - HOUSEKEEPING- CARPET LAYING/CLEANING",
      "value": "S214",
      "type": "psc_code"
   },
   {
      "label": "S214 - CARPET LAYING AND CLEANING",
      "value": "S214",
      "type": "psc_code"
   },
   {
      "label": "S215 - WAREHOUSING AND STORAGE SERVICES",
      "value": "S215",
      "type": "psc_code"
   },
   {
      "label": "S215 - HOUSEKEEPING- WAREHOUSING/STORAGE",
      "value": "S215",
      "type": "psc_code"
   },
   {
      "label": "S216 - HOUSEKEEPING- FACILITIES OPERATIONS SUPPORT",
      "value": "S216",
      "type": "psc_code"
   },
   {
      "label": "S216 - FACILITIES OPERATIONS SUPPORT SVCS",
      "value": "S216",
      "type": "psc_code"
   },
   {
      "label": "S217 - INTERIOR PLANTSCAPING",
      "value": "S217",
      "type": "psc_code"
   },
   {
      "label": "S217 - HOUSEKEEPING- INTERIOR PLANTSCAPING",
      "value": "S217",
      "type": "psc_code"
   },
   {
      "label": "S218 - HOUSEKEEPING- SNOW REMOVAL/SALT",
      "value": "S218",
      "type": "psc_code"
   },
   {
      "label": "S218 - SNOW REMOVAL/SALT SERVICE",
      "value": "S218",
      "type": "psc_code"
   },
   {
      "label": "S219 - ASBESTOS REMOVAL",
      "value": "S219",
      "type": "psc_code"
   },
   {
      "label": "S220 - TRAVEL AGENT SERVICES",
      "value": "S220",
      "type": "psc_code"
   },
   {
      "label": "S221 - HAZARDOUS WASTE REMOVAL",
      "value": "S221",
      "type": "psc_code"
   },
   {
      "label": "S222 - WASTE TREATMENT AND STORAGE",
      "value": "S222",
      "type": "psc_code"
   },
   {
      "label": "S222 - HOUSEKEEPING- WASTE TREATMENT/STORAGE",
      "value": "S222",
      "type": "psc_code"
   },
   {
      "label": "S299 - HOUSEKEEPING- OTHER",
      "value": "S299",
      "type": "psc_code"
   },
   {
      "label": "S299 - OTHER HOUSEKEEPING SERVICES",
      "value": "S299",
      "type": "psc_code"
   },
   {
      "label": "T - PHOTO, MAP, PRINT, PUBLICATION",
      "value": "T",
      "type": "psc_code"
   },
   {
      "label": "T0 - PHOTOGR, MAPPING, PRINTING, PUBLISH",
      "value": "T0",
      "type": "psc_code"
   },
   {
      "label": "T001 - ARTS/GRAPHICS SERVICES",
      "value": "T001",
      "type": "psc_code"
   },
   {
      "label": "T001 - PHOTO/MAP/PRINT/PUBLICATION- ARTS/GRAPHICS",
      "value": "T001",
      "type": "psc_code"
   },
   {
      "label": "T002 - PHOTO/MAP/PRINT/PUBLICATION- CARTOGRAPHY",
      "value": "T002",
      "type": "psc_code"
   },
   {
      "label": "T002 - CARTOGRAPHY SERVICES",
      "value": "T002",
      "type": "psc_code"
   },
   {
      "label": "T003 - CATALOGING SERVICES",
      "value": "T003",
      "type": "psc_code"
   },
   {
      "label": "T003 - PHOTO/MAP/PRINT/PUBLICATION- CATALOGING",
      "value": "T003",
      "type": "psc_code"
   },
   {
      "label": "T004 - CHARTING SERVICES",
      "value": "T004",
      "type": "psc_code"
   },
   {
      "label": "T004 - PHOTO/MAP/PRINT/PUBLICATION- CHARTING",
      "value": "T004",
      "type": "psc_code"
   },
   {
      "label": "T005 - PHOTO/MAP/PRINT/PUBLICATION- FILM PROCESSING",
      "value": "T005",
      "type": "psc_code"
   },
   {
      "label": "T005 - FILM PROCESSING SERVICES",
      "value": "T005",
      "type": "psc_code"
   },
   {
      "label": "T006 - PHOTO/MAP/PRINT/PUBLICATION- FILM/VIDEO TAPE PRODUCTION",
      "value": "T006",
      "type": "psc_code"
   },
   {
      "label": "T006 - FILM/VIDEO TAPE PRODUCTION SERVICES",
      "value": "T006",
      "type": "psc_code"
   },
   {
      "label": "T007 - PHOTO/MAP/PRINT/PUBLICATION- MICROFORM",
      "value": "T007",
      "type": "psc_code"
   },
   {
      "label": "T007 - MICROFORM SERVICES",
      "value": "T007",
      "type": "psc_code"
   },
   {
      "label": "T008 - PHOTOGRAMMETRY SERVICES",
      "value": "T008",
      "type": "psc_code"
   },
   {
      "label": "T008 - PHOTO/MAP/PRINT/PUBLICATION- PHOTOGRAMMETRY",
      "value": "T008",
      "type": "psc_code"
   },
   {
      "label": "T009 - AERIAL PHOTOGRAPHIC SERVICES",
      "value": "T009",
      "type": "psc_code"
   },
   {
      "label": "T009 - PHOTO/MAP/PRINT/PUBLICATION- AERIAL PHOTOGRAPHIC",
      "value": "T009",
      "type": "psc_code"
   },
   {
      "label": "T010 - PHOTO/MAP/PRINT/PUBLICATION- GENERAL PHOTOGRAPHIC: STILL",
      "value": "T010",
      "type": "psc_code"
   },
   {
      "label": "T010 - PHOTOGRAPHIC - GENERAL - STILL",
      "value": "T010",
      "type": "psc_code"
   },
   {
      "label": "T011 - PRINT/BINDING SERVICES",
      "value": "T011",
      "type": "psc_code"
   },
   {
      "label": "T011 - PHOTO/MAP/PRINT/PUBLICATION- PRINT/BINDING",
      "value": "T011",
      "type": "psc_code"
   },
   {
      "label": "T012 - REPRODUCTION SERVICES",
      "value": "T012",
      "type": "psc_code"
   },
   {
      "label": "T012 - PHOTO/MAP/PRINT/PUBLICATION- REPRODUCTION",
      "value": "T012",
      "type": "psc_code"
   },
   {
      "label": "T013 - PHOTO/MAP/PRINT/PUBLICATION- TECHNICAL WRITING",
      "value": "T013",
      "type": "psc_code"
   },
   {
      "label": "T013 - TECHNICAL WRITING SERVICES",
      "value": "T013",
      "type": "psc_code"
   },
   {
      "label": "T014 - TOPOGRAPHY SERVICES",
      "value": "T014",
      "type": "psc_code"
   },
   {
      "label": "T014 - PHOTO/MAP/PRINT/PUBLICATION- TOPOGRAPHY",
      "value": "T014",
      "type": "psc_code"
   },
   {
      "label": "T015 - PHOTO/MAP/PRINT/PUBLICATION- GENERAL PHOTOGRAPHIC: MOTION",
      "value": "T015",
      "type": "psc_code"
   },
   {
      "label": "T015 - PHOTOGRAPHIC - GENERAL - MOTION",
      "value": "T015",
      "type": "psc_code"
   },
   {
      "label": "T016 - PHOTO/MAP/PRINT/PUBLICATION- AUDIO/VISUAL",
      "value": "T016",
      "type": "psc_code"
   },
   {
      "label": "T016 - AUDIO/VISUAL SERVICES",
      "value": "T016",
      "type": "psc_code"
   },
   {
      "label": "T099 - PHOTO/MAP/PRINT/PUBLICATION- OTHER",
      "value": "T099",
      "type": "psc_code"
   },
   {
      "label": "T099 - OTHER PHOTO MAPPING PRINTING SVC",
      "value": "T099",
      "type": "psc_code"
   },
   {
      "label": "U - EDUCATION AND TRAINING",
      "value": "U",
      "type": "psc_code"
   },
   {
      "label": "U0 - EDUCATION AND TRAINING SERVICES",
      "value": "U0",
      "type": "psc_code"
   },
   {
      "label": "U001 - LECTURES FOR TRAINING",
      "value": "U001",
      "type": "psc_code"
   },
   {
      "label": "U001 - EDUCATION/TRAINING- LECTURES",
      "value": "U001",
      "type": "psc_code"
   },
   {
      "label": "U002 - PERSONNEL TESTING",
      "value": "U002",
      "type": "psc_code"
   },
   {
      "label": "U002 - EDUCATION/TRAINING- PERSONNEL TESTING",
      "value": "U002",
      "type": "psc_code"
   },
   {
      "label": "U003 - RESERVE TRAINING (MILITARY)",
      "value": "U003",
      "type": "psc_code"
   },
   {
      "label": "U003 - EDUCATION/TRAINING- RESERVE TRAINING (MILITARY)",
      "value": "U003",
      "type": "psc_code"
   },
   {
      "label": "U004 - SCIENTIFIC AND MANAGEMENT EDUCATION",
      "value": "U004",
      "type": "psc_code"
   },
   {
      "label": "U004 - EDUCATION/TRAINING- SCIENTIFIC/MANAGEMENT",
      "value": "U004",
      "type": "psc_code"
   },
   {
      "label": "U005 - EDUCATION/TRAINING- TUITION/REGISTRATION/MEMBERSHIP FEES",
      "value": "U005",
      "type": "psc_code"
   },
   {
      "label": "U005 - TUITION/REG/MEMB FEES",
      "value": "U005",
      "type": "psc_code"
   },
   {
      "label": "U006 - EDUCATION/TRAINING- VOCATIONAL/TECHNICAL",
      "value": "U006",
      "type": "psc_code"
   },
   {
      "label": "U006 - VOCATIONAL/TECHNICAL",
      "value": "U006",
      "type": "psc_code"
   },
   {
      "label": "U007 - FACULTY SALARIES DEPENDENT SCHOOLS",
      "value": "U007",
      "type": "psc_code"
   },
   {
      "label": "U007 - EDUCATION/TRAINING- FACULTY SALARIES FOR DEPENDENT SCHOOLS",
      "value": "U007",
      "type": "psc_code"
   },
   {
      "label": "U008 - TRAINING/CURRICULUM DEVELOPMENT",
      "value": "U008",
      "type": "psc_code"
   },
   {
      "label": "U008 - EDUCATION/TRAINING- TRAINING/CURRICULUM DEVELOPMENT",
      "value": "U008",
      "type": "psc_code"
   },
   {
      "label": "U009 - EDUCATION/TRAINING- GENERAL",
      "value": "U009",
      "type": "psc_code"
   },
   {
      "label": "U009 - EDUCATION SERVICES",
      "value": "U009",
      "type": "psc_code"
   },
   {
      "label": "U010 - EDUCATION/TRAINING- CERTIFICATIONS/ACCREDITATIONS FOR EDUCATIONAL INSTITUTIONS",
      "value": "U010",
      "type": "psc_code"
   },
   {
      "label": "U010 - CERT & ACCREDIATIONS FOR EDUCATIONA",
      "value": "U010",
      "type": "psc_code"
   },
   {
      "label": "U011 - EDUCATION/TRAINING- AIDS/HIV",
      "value": "U011",
      "type": "psc_code"
   },
   {
      "label": "U011 - AIDS TRAINING",
      "value": "U011",
      "type": "psc_code"
   },
   {
      "label": "U012 - INFORMATION TRAINING",
      "value": "U012",
      "type": "psc_code"
   },
   {
      "label": "U012 - EDUCATION/TRAINING- INFORMATION TECHNOLOGY/TELECOMMUNICATIONS TRAINING",
      "value": "U012",
      "type": "psc_code"
   },
   {
      "label": "U013 - EDUCATION/TRAINING- COMBAT",
      "value": "U013",
      "type": "psc_code"
   },
   {
      "label": "U014 - EDUCATION/TRAINING- SECURITY",
      "value": "U014",
      "type": "psc_code"
   },
   {
      "label": "U099 - OTHER ED & TRNG SVCS",
      "value": "U099",
      "type": "psc_code"
   },
   {
      "label": "U099 - EDUCATION/TRAINING- OTHER",
      "value": "U099",
      "type": "psc_code"
   },
   {
      "label": "V - TRANSPORT, TRAVEL, RELOCATION",
      "value": "V",
      "type": "psc_code"
   },
   {
      "label": "V0 - MOTOR POOL OR PACKING/CRATING",
      "value": "V0",
      "type": "psc_code"
   },
   {
      "label": "V001 - GBL & GTR PROCUREMENTS",
      "value": "V001",
      "type": "psc_code"
   },
   {
      "label": "V001 - TRANSPORTATION/TRAVEL/RELOCATION- MOTOR POOL AND PACKING/CRATING: GBL/GTR PROCS",
      "value": "V001",
      "type": "psc_code"
   },
   {
      "label": "V002 - TRANSPORTATION/TRAVEL/RELOCATION- MOTOR POOL AND PACKING/CRATING: MOTOR POOL OPERATIONS",
      "value": "V002",
      "type": "psc_code"
   },
   {
      "label": "V002 - MOTOR POOL OPERATIONS",
      "value": "V002",
      "type": "psc_code"
   },
   {
      "label": "V003 - PACKING/CRATING SERVICES",
      "value": "V003",
      "type": "psc_code"
   },
   {
      "label": "V003 - TRANSPORTATION/TRAVEL/RELOCATION- MOTOR POOL AND PACKING/CRATING: PACKING/CRATING",
      "value": "V003",
      "type": "psc_code"
   },
   {
      "label": "V1 - TRANSPORTATION OF THINGS",
      "value": "V1",
      "type": "psc_code"
   },
   {
      "label": "V111 - AIR FREIGHT",
      "value": "V111",
      "type": "psc_code"
   },
   {
      "label": "V111 - TRANSPORTATION/TRAVEL/RELOCATION- TRANSPORTATION: AIR FREIGHT",
      "value": "V111",
      "type": "psc_code"
   },
   {
      "label": "V112 - TRANSPORTATION/TRAVEL/RELOCATION- TRANSPORTATION: MOTOR FREIGHT",
      "value": "V112",
      "type": "psc_code"
   },
   {
      "label": "V112 - MOTOR FREIGHT",
      "value": "V112",
      "type": "psc_code"
   },
   {
      "label": "V113 - TRANSPORTATION/TRAVEL/RELOCATION- TRANSPORTATION: RAIL FREIGHT",
      "value": "V113",
      "type": "psc_code"
   },
   {
      "label": "V113 - RAIL FREIGHT",
      "value": "V113",
      "type": "psc_code"
   },
   {
      "label": "V114 - STEVEDORING",
      "value": "V114",
      "type": "psc_code"
   },
   {
      "label": "V114 - TRANSPORTATION/TRAVEL/RELOCATION- TRANSPORTATION: STEVEDORING",
      "value": "V114",
      "type": "psc_code"
   },
   {
      "label": "V115 - TRANSPORTATION/TRAVEL/RELOCATION- TRANSPORTATION: VESSEL FREIGHT",
      "value": "V115",
      "type": "psc_code"
   },
   {
      "label": "V115 - VESSEL FREIGHT",
      "value": "V115",
      "type": "psc_code"
   },
   {
      "label": "V119 - OTHER CARGO AND FREIGHT SERVICES",
      "value": "V119",
      "type": "psc_code"
   },
   {
      "label": "V119 - TRANSPORTATION/TRAVEL/RELOCATION- TRANSPORTATION: OTHER",
      "value": "V119",
      "type": "psc_code"
   },
   {
      "label": "V121 - TRANSPORTATION/TRAVEL/RELOCATION- TRANSPORTATION: AIR CHARTER",
      "value": "V121",
      "type": "psc_code"
   },
   {
      "label": "V121 - AIR CHARTER FOR THINGS",
      "value": "V121",
      "type": "psc_code"
   },
   {
      "label": "V122 - TRANSPORTATION/TRAVEL/RELOCATION- TRANSPORTATION: MOTOR CHARTER",
      "value": "V122",
      "type": "psc_code"
   },
   {
      "label": "V122 - MOTOR CHARTER FOR THINGS",
      "value": "V122",
      "type": "psc_code"
   },
   {
      "label": "V123 - RAIL CHARTER FOR THINGS",
      "value": "V123",
      "type": "psc_code"
   },
   {
      "label": "V123 - TRANSPORTATION/TRAVEL/RELOCATION- TRANSPORTATION: RAIL CHARTER",
      "value": "V123",
      "type": "psc_code"
   },
   {
      "label": "V124 - MARINE CHARTER FOR THINGS",
      "value": "V124",
      "type": "psc_code"
   },
   {
      "label": "V124 - TRANSPORTATION/TRAVEL/RELOCATION- TRANSPORTATION: MARINE CHARTER",
      "value": "V124",
      "type": "psc_code"
   },
   {
      "label": "V125 - TRANSPORTATION/TRAVEL/RELOCATION- TRANSPORTATION: VESSEL TOWING",
      "value": "V125",
      "type": "psc_code"
   },
   {
      "label": "V125 - VESSEL TOWING SERVICE",
      "value": "V125",
      "type": "psc_code"
   },
   {
      "label": "V126 - SPACE TRANSP & LAUNCH",
      "value": "V126",
      "type": "psc_code"
   },
   {
      "label": "V126 - TRANSPORTATION/TRAVEL/RELOCATION- TRANSPORTATION: SPACE TRANSPORTATION/LAUNCH",
      "value": "V126",
      "type": "psc_code"
   },
   {
      "label": "V127 - TRANSPORTATION/TRAVEL/RELOCATION- TRANSPORTATION: SECURITY VEHICLE",
      "value": "V127",
      "type": "psc_code"
   },
   {
      "label": "V127 - SECURITY VEHICLE SERVICES",
      "value": "V127",
      "type": "psc_code"
   },
   {
      "label": "V129 - TRANSPORTATION/TRAVEL/RELOCATION- TRANSPORTATION: OTHER",
      "value": "V129",
      "type": "psc_code"
   },
   {
      "label": "V129 - OTHER VEHICLE CHARTER FOR THINGS",
      "value": "V129",
      "type": "psc_code"
   },
   {
      "label": "V2 - TRAVEL, LODGING, RECRUITMENT SVCS",
      "value": "V2",
      "type": "psc_code"
   },
   {
      "label": "V211 - AIR PASSENGER SERVICE",
      "value": "V211",
      "type": "psc_code"
   },
   {
      "label": "V211 - TRANSPORTATION/TRAVEL/RELOCATION- TRAVEL/LODGING/RECRUITMENT: AIR PASSENGER",
      "value": "V211",
      "type": "psc_code"
   },
   {
      "label": "V212 - TRANSPORTATION/TRAVEL/RELOCATION- TRAVEL/LODGING/RECRUITMENT: MOTOR PASSENGER",
      "value": "V212",
      "type": "psc_code"
   },
   {
      "label": "V212 - MOTOR PASSENGER SERVICES",
      "value": "V212",
      "type": "psc_code"
   },
   {
      "label": "V213 - RAIL PASSENGER SERVICE",
      "value": "V213",
      "type": "psc_code"
   },
   {
      "label": "V213 - TRANSPORTATION/TRAVEL/RELOCATION- TRAVEL/LODGING/RECRUITMENT: RAIL PASSENGER",
      "value": "V213",
      "type": "psc_code"
   },
   {
      "label": "V214 - MARINE PASSENGER SERVICE",
      "value": "V214",
      "type": "psc_code"
   },
   {
      "label": "V214 - TRANSPORTATION/TRAVEL/RELOCATION- TRAVEL/LODGING/RECRUITMENT: MARINE PASSENGER",
      "value": "V214",
      "type": "psc_code"
   },
   {
      "label": "V221 - PASSENGER AIR CHARTER SERVICE",
      "value": "V221",
      "type": "psc_code"
   },
   {
      "label": "V221 - TRANSPORTATION/TRAVEL/RELOCATION- TRAVEL/LODGING/RECRUITMENT: PASSENGER AIR CHARTER",
      "value": "V221",
      "type": "psc_code"
   },
   {
      "label": "V222 - TRANSPORTATION/TRAVEL/RELOCATION- TRAVEL/LODGING/RECRUITMENT: PASSENGER MOTOR CHARTER",
      "value": "V222",
      "type": "psc_code"
   },
   {
      "label": "V222 - PASSENGER MOTOR CHARTER SERVICE",
      "value": "V222",
      "type": "psc_code"
   },
   {
      "label": "V223 - PASSENGER RAIL CHARTER SERVICE",
      "value": "V223",
      "type": "psc_code"
   },
   {
      "label": "V223 - TRANSPORTATION/TRAVEL/RELOCATION- TRAVEL/LODGING/RECRUITMENT: PASSENGER RAIL CHARTER",
      "value": "V223",
      "type": "psc_code"
   },
   {
      "label": "V224 - TRANSPORTATION/TRAVEL/RELOCATION- TRAVEL/LODGING/RECRUITMENT: PASSENGER MARINE CHARTER",
      "value": "V224",
      "type": "psc_code"
   },
   {
      "label": "V224 - PASSENGER MARINE CHARTER SERVICE",
      "value": "V224",
      "type": "psc_code"
   },
   {
      "label": "V225 - TRANSPORTATION/TRAVEL/RELOCATION- TRAVEL/LODGING/RECRUITMENT: AMBULANCE",
      "value": "V225",
      "type": "psc_code"
   },
   {
      "label": "V225 - AMBULANCE SERVICE",
      "value": "V225",
      "type": "psc_code"
   },
   {
      "label": "V226 - TRANSPORTATION/TRAVEL/RELOCATION- TRAVEL/LODGING/RECRUITMENT: TAXICAB",
      "value": "V226",
      "type": "psc_code"
   },
   {
      "label": "V226 - TAXICAB SERVICES",
      "value": "V226",
      "type": "psc_code"
   },
   {
      "label": "V227 - TRANSPORTATION/TRAVEL/RELOCATION- TRAVEL/LODGING/RECRUITMENT: NAVIGATIONAL AID AND PILOTAGE",
      "value": "V227",
      "type": "psc_code"
   },
   {
      "label": "V227 - NAVIGATIONAL AID & PILOTAGE SVCS",
      "value": "V227",
      "type": "psc_code"
   },
   {
      "label": "V228 - TRANSPORTATION/TRAVEL/RELOCATION- TRAVEL/LODGING/RECRUITMENT: PORT OPERATIONS",
      "value": "V228",
      "type": "psc_code"
   },
   {
      "label": "V229 - TRANSPORT/TRAVEL/RELOCATION- TRAVEL/LODGING/RECRUIT: PURCH OF TRANSIT/PUBLIC TRANSPORT FARE MEDIA",
      "value": "V229",
      "type": "psc_code"
   },
   {
      "label": "",
      "value": "V230",
      "type": "psc_code"
   },
   {
      "label": "V231 - TRANSPORTATION/TRAVEL/RELOCATION- TRAVEL/LODGING/RECRUITMENT: LODGING, HOTEL/MOTEL",
      "value": "V231",
      "type": "psc_code"
   },
   {
      "label": "V231 - LODGING - HOTEL/MOTEL",
      "value": "V231",
      "type": "psc_code"
   },
   {
      "label": "V241 - TRANSPORTATION/TRAVEL/RELOCATION- TRAVEL/LODGING/RECRUITMENT: MILITARY PERSONNEL RECRUITMENT",
      "value": "V241",
      "type": "psc_code"
   },
   {
      "label": "V241 - MILITARY PERSONNEL RECRUITMENT",
      "value": "V241",
      "type": "psc_code"
   },
   {
      "label": "V251 - TRANSPORTATION/TRAVEL/RELOCATION- TRAVEL/LODGING/RECRUITMENT: CIVILIAN PERSONNEL RECRUITMENT",
      "value": "V251",
      "type": "psc_code"
   },
   {
      "label": "V251 - CIVILIAN PERSONNEL RECRUITMENT",
      "value": "V251",
      "type": "psc_code"
   },
   {
      "label": "V3 - RELOCATION OR TRAVEL AGENT SERVICES",
      "value": "V3",
      "type": "psc_code"
   },
   {
      "label": "V301 - RELOCATION SERVICES",
      "value": "V301",
      "type": "psc_code"
   },
   {
      "label": "V301 - TRANSPORTATION/TRAVEL/RELOCATION- RELOCATION: RELOCATION",
      "value": "V301",
      "type": "psc_code"
   },
   {
      "label": "V302 - TRAVEL AGENT SERVICES",
      "value": "V302",
      "type": "psc_code"
   },
   {
      "label": "V302 - TRANSPORTATION/TRAVEL/RELOCATION- RELOCATION: TRAVEL AGENT",
      "value": "V302",
      "type": "psc_code"
   },
   {
      "label": "V9 - OTHER TRANSPORT, TRAVEL, RELOCAT SV",
      "value": "V9",
      "type": "psc_code"
   },
   {
      "label": "V999 - OTHER TRAVEL SVCS",
      "value": "V999",
      "type": "psc_code"
   },
   {
      "label": "V999 - TRANSPORTATION/TRAVEL/RELOCATION- OTHER: OTHER",
      "value": "V999",
      "type": "psc_code"
   },
   {
      "label": "W - LEASE/RENT EQUIPMENT",
      "value": "W",
      "type": "psc_code"
   },
   {
      "label": "W0 - LEASE OR RENTAL OF EQUIPMENT",
      "value": "W0",
      "type": "psc_code"
   },
   {
      "label": "W010 - LEASE OR RENTAL OF EQUIPMENT- WEAPONS",
      "value": "W010",
      "type": "psc_code"
   },
   {
      "label": "W010 - LEASE-RENT OF WEAPONS",
      "value": "W010",
      "type": "psc_code"
   },
   {
      "label": "W011 - LEASE-RENT OF NUCLEAR ORDNANCE",
      "value": "W011",
      "type": "psc_code"
   },
   {
      "label": "W011 - LEASE OR RENTAL OF EQUIPMENT- NUCLEAR ORDNANCE",
      "value": "W011",
      "type": "psc_code"
   },
   {
      "label": "W012 - LEASE OR RENTAL OF EQUIPMENT- FIRE CONTROL EQUIPMENT",
      "value": "W012",
      "type": "psc_code"
   },
   {
      "label": "W012 - LEASE-RENT OF FIRE CONT EQ",
      "value": "W012",
      "type": "psc_code"
   },
   {
      "label": "W013 - LEASE-RENT OF AMMO & EXPLOSIVES",
      "value": "W013",
      "type": "psc_code"
   },
   {
      "label": "W013 - LEASE OR RENTAL OF EQUIPMENT- AMMUNITION AND EXPLOSIVES",
      "value": "W013",
      "type": "psc_code"
   },
   {
      "label": "W014 - LEASE-RENT OF GUIDED MISSILES",
      "value": "W014",
      "type": "psc_code"
   },
   {
      "label": "W014 - LEASE OR RENTAL OF EQUIPMENT- GUIDED MISSILES",
      "value": "W014",
      "type": "psc_code"
   },
   {
      "label": "W015 - LEASE-RENT OF AIRCRAFT",
      "value": "W015",
      "type": "psc_code"
   },
   {
      "label": "W015 - LEASE OR RENTAL OF EQUIPMENT- AIRCRAFT AND AIRFRAME STRUCTURAL COMPONENTS",
      "value": "W015",
      "type": "psc_code"
   },
   {
      "label": "W016 - LEASE-RENT OF AIRCRAFT COMPONENTS",
      "value": "W016",
      "type": "psc_code"
   },
   {
      "label": "W016 - LEASE OR RENTAL OF EQUIPMENT- AIRCRAFT COMPONENTS AND ACCESSORIES",
      "value": "W016",
      "type": "psc_code"
   },
   {
      "label": "W017 - LEASE OR RENTAL OF EQUIPMENT- AIRCRAFT LAUNCHING, LANDING, AND GROUND HANDLING EQUIPMENT",
      "value": "W017",
      "type": "psc_code"
   },
   {
      "label": "W017 - LEASE-RENT OF AIRCRAFT GROUND EQ",
      "value": "W017",
      "type": "psc_code"
   },
   {
      "label": "W018 - LEASE-RENT OF SPACE VEHICLES",
      "value": "W018",
      "type": "psc_code"
   },
   {
      "label": "W018 - LEASE OR RENTAL OF EQUIPMENT- SPACE VEHICLES",
      "value": "W018",
      "type": "psc_code"
   },
   {
      "label": "W019 - LEASE-RENT OF SHIPS-SML CRAFT-DOCKS",
      "value": "W019",
      "type": "psc_code"
   },
   {
      "label": "W019 - LEASE OR RENTAL OF EQUIPMENT- SHIPS, SMALL CRAFT, PONTOONS, AND FLOATING DOCKS",
      "value": "W019",
      "type": "psc_code"
   },
   {
      "label": "W020 - LEASE-RENT OF SHIP & MARINE EQ",
      "value": "W020",
      "type": "psc_code"
   },
   {
      "label": "W020 - LEASE OR RENTAL OF EQUIPMENT- SHIP AND MARINE EQUIPMENT",
      "value": "W020",
      "type": "psc_code"
   },
   {
      "label": "W022 - LEASE OR RENTAL OF EQUIPMENT- RAILWAY EQUIPMENT",
      "value": "W022",
      "type": "psc_code"
   },
   {
      "label": "W022 - LEASE-RENT OF RAILWAY EQ",
      "value": "W022",
      "type": "psc_code"
   },
   {
      "label": "W023 - LEASE OR RENTAL OF EQUIPMENT- GROUND EFFECT VEHICLES, MOTOR VEHICLES, TRAILERS, AND CYCLES",
      "value": "W023",
      "type": "psc_code"
   },
   {
      "label": "W023 - LEASE-RENT OF VEHICLES-TRAILERS-CYC",
      "value": "W023",
      "type": "psc_code"
   },
   {
      "label": "W024 - LEASE OR RENTAL OF EQUIPMENT- TRACTORS",
      "value": "W024",
      "type": "psc_code"
   },
   {
      "label": "W024 - LEASE-RENT OF TRACTORS",
      "value": "W024",
      "type": "psc_code"
   },
   {
      "label": "W025 - LEASE-RENT OF VEHICULAR EQ",
      "value": "W025",
      "type": "psc_code"
   },
   {
      "label": "W025 - LEASE OR RENTAL OF EQUIPMENT- VEHICULAR EQUIPMENT COMPONENTS",
      "value": "W025",
      "type": "psc_code"
   },
   {
      "label": "W026 - LEASE-RENT OF TIRES & TUBES",
      "value": "W026",
      "type": "psc_code"
   },
   {
      "label": "W026 - LEASE OR RENTAL OF EQUIPMENT- TIRES AND TUBES",
      "value": "W026",
      "type": "psc_code"
   },
   {
      "label": "W028 - LEASE OR RENTAL OF EQUIPMENT- ENGINES, TURBINES, AND COMPONENTS",
      "value": "W028",
      "type": "psc_code"
   },
   {
      "label": "W028 - LEASE-RENT OF ENGINES & TURBINES",
      "value": "W028",
      "type": "psc_code"
   },
   {
      "label": "W029 - LEASE-RENT OF ENGINE ACCESSORIES",
      "value": "W029",
      "type": "psc_code"
   },
   {
      "label": "W029 - LEASE OR RENTAL OF EQUIPMENT- ENGINE ACCESSORIES",
      "value": "W029",
      "type": "psc_code"
   },
   {
      "label": "W030 - LEASE-RENT OF MECH POWER TRANS EQ",
      "value": "W030",
      "type": "psc_code"
   },
   {
      "label": "W030 - LEASE OR RENTAL OF EQUIPMENT- MECHANICAL POWER TRANSMISSION EQUIPMENT",
      "value": "W030",
      "type": "psc_code"
   },
   {
      "label": "W031 - LEASE-RENT OF BEARINGS",
      "value": "W031",
      "type": "psc_code"
   },
   {
      "label": "W031 - LEASE OR RENTAL OF EQUIPMENT- BEARINGS",
      "value": "W031",
      "type": "psc_code"
   },
   {
      "label": "W032 - LEASE OR RENTAL OF EQUIPMENT- WOODWORKING MACHINERY AND EQUIPMENT",
      "value": "W032",
      "type": "psc_code"
   },
   {
      "label": "W032 - LEASE-RENT OF WOODWORKING MACHINE",
      "value": "W032",
      "type": "psc_code"
   },
   {
      "label": "W034 - LEASE OR RENTAL OF EQUIPMENT- METALWORKING MACHINERY",
      "value": "W034",
      "type": "psc_code"
   },
   {
      "label": "W034 - LEASE-RENT OF METALWORK MACHINE",
      "value": "W034",
      "type": "psc_code"
   },
   {
      "label": "W035 - LEASE OR RENTAL OF EQUIPMENT- SERVICE AND TRADE EQUIPMENT",
      "value": "W035",
      "type": "psc_code"
   },
   {
      "label": "W035 - LEASE-RENT OF SERVICE & TRADE EQ",
      "value": "W035",
      "type": "psc_code"
   },
   {
      "label": "W036 - LEASE-RENT OF SP INDUSTRY MACHINERY",
      "value": "W036",
      "type": "psc_code"
   },
   {
      "label": "W036 - LEASE OR RENTAL OF EQUIPMENT- SPECIAL INDUSTRY MACHINERY",
      "value": "W036",
      "type": "psc_code"
   },
   {
      "label": "W037 - LEASE OR RENTAL OF EQUIPMENT- AGRICULTURAL MACHINERY AND EQUIPMENT",
      "value": "W037",
      "type": "psc_code"
   },
   {
      "label": "W037 - LEASE-RENT OF AGRICULTURE MACHINE",
      "value": "W037",
      "type": "psc_code"
   },
   {
      "label": "W038 - LEASE OR RENTAL OF EQUIPMENT- CONSTRUCTION, MINING, EXCAVATING, AND HIGHWAY MAINTENANCE EQUIPMENT",
      "value": "W038",
      "type": "psc_code"
   },
   {
      "label": "W038 - LEASE-RENT OF CONTRUCT EQ",
      "value": "W038",
      "type": "psc_code"
   },
   {
      "label": "W039 - LEASE-RENT OF MATERIALS HANDLING EQ",
      "value": "W039",
      "type": "psc_code"
   },
   {
      "label": "W039 - LEASE OR RENTAL OF EQUIPMENT- MATERIALS HANDLING EQUIPMENT",
      "value": "W039",
      "type": "psc_code"
   },
   {
      "label": "W040 - LEASE-RENT OF ROPE-CABLE-CHAIN",
      "value": "W040",
      "type": "psc_code"
   },
   {
      "label": "W040 - LEASE OR RENTAL OF EQUIPMENT- ROPE, CABLE, CHAIN, AND FITTINGS",
      "value": "W040",
      "type": "psc_code"
   },
   {
      "label": "W041 - LEASE OR RENTAL OF EQUIPMENT- REFRIGERATION, AIR CONDITIONING, AND AIR CIRCULATING EQUIPMENT",
      "value": "W041",
      "type": "psc_code"
   },
   {
      "label": "W041 - LEASE-RENT OF REFRIGERATION - AC EQ",
      "value": "W041",
      "type": "psc_code"
   },
   {
      "label": "W042 - LEASE OR RENTAL OF EQUIPMENT- FIRE FIGHTING/RESCUE/SAFETY EQUIPMENT; ENVIRON PROTECT EQUIPMENT/MATLS",
      "value": "W042",
      "type": "psc_code"
   },
   {
      "label": "W042 - LEASE-RENT OF FIRE-RESCUE-SAFETY EQ",
      "value": "W042",
      "type": "psc_code"
   },
   {
      "label": "W043 - LEASE-RENT OF PUMPS & COMPRESSORS",
      "value": "W043",
      "type": "psc_code"
   },
   {
      "label": "W043 - LEASE OR RENTAL OF EQUIPMENT- PUMPS AND COMPRESSORS",
      "value": "W043",
      "type": "psc_code"
   },
   {
      "label": "W044 - LEASE-RENT OF FURNACE-NUCLEAR REAC",
      "value": "W044",
      "type": "psc_code"
   },
   {
      "label": "W044 - LEASE OR RENTAL OF EQUIPMENT- FURNACE, STEAM PLANT, AND DRYING EQUIPMENT; NUCLEAR REACTORS",
      "value": "W044",
      "type": "psc_code"
   },
   {
      "label": "W045 - LEASE-RENT OF PLUMBING-HEATING EQ",
      "value": "W045",
      "type": "psc_code"
   },
   {
      "label": "W045 - LEASE OR RENTAL OF EQUIPMENT- PLUMBING, HEATING, AND WASTE DISPOSAL EQUIPMENT",
      "value": "W045",
      "type": "psc_code"
   },
   {
      "label": "W046 - LEASE-RENT OF WATER PURIFICATION EQ",
      "value": "W046",
      "type": "psc_code"
   },
   {
      "label": "W046 - LEASE OR RENTAL OF EQUIPMENT- WATER PURIFICATION AND SEWAGE TREATMENT EQUIPMENT",
      "value": "W046",
      "type": "psc_code"
   },
   {
      "label": "W047 - LEASE OR RENTAL OF EQUIPMENT- PIPE, TUBING, HOSE, AND FITTINGS",
      "value": "W047",
      "type": "psc_code"
   },
   {
      "label": "W047 - LEASE-RENT OF PIPE-TUBING-HOSE",
      "value": "W047",
      "type": "psc_code"
   },
   {
      "label": "W048 - LEASE-RENT OF VALVES",
      "value": "W048",
      "type": "psc_code"
   },
   {
      "label": "W048 - LEASE OR RENTAL OF EQUIPMENT- VALVES",
      "value": "W048",
      "type": "psc_code"
   },
   {
      "label": "W049 - LEASE-RENT OF MAINT REPAIR SHOP EQ",
      "value": "W049",
      "type": "psc_code"
   },
   {
      "label": "W049 - LEASE OR RENTAL OF EQUIPMENT- MAINTENANCE AND REPAIR SHOP EQUIPMENT",
      "value": "W049",
      "type": "psc_code"
   },
   {
      "label": "W051 - LEASE-RENT OF HAND TOOLS",
      "value": "W051",
      "type": "psc_code"
   },
   {
      "label": "W051 - LEASE OR RENTAL OF EQUIPMENT- HAND TOOLS",
      "value": "W051",
      "type": "psc_code"
   },
   {
      "label": "W052 - LEASE OR RENTAL OF EQUIPMENT- MEASURING TOOLS",
      "value": "W052",
      "type": "psc_code"
   },
   {
      "label": "W052 - LEASE-RENT OF MEASURING TOOLS",
      "value": "W052",
      "type": "psc_code"
   },
   {
      "label": "W053 - LEASE OR RENTAL OF EQUIPMENT- HARDWARE AND ABRASIVES",
      "value": "W053",
      "type": "psc_code"
   },
   {
      "label": "W053 - LEASE-RENT OF HARDWARE & ABRASIVES",
      "value": "W053",
      "type": "psc_code"
   },
   {
      "label": "W054 - LEASE OR RENTAL OF EQUIPMENT- PREFABRICATED STRUCTURES AND SCAFFOLDING",
      "value": "W054",
      "type": "psc_code"
   },
   {
      "label": "W054 - LEASE-RENT OF PREFAB STRUCTURES",
      "value": "W054",
      "type": "psc_code"
   },
   {
      "label": "W055 - LEASE-RENT OF LUMBER & MILLWORK",
      "value": "W055",
      "type": "psc_code"
   },
   {
      "label": "W055 - LEASE OR RENTAL OF EQUIPMENT- LUMBER, MILLWORK, PLYWOOD, AND VENEER",
      "value": "W055",
      "type": "psc_code"
   },
   {
      "label": "W056 - LEASE-RENT OF CONTRUCT MATERIAL",
      "value": "W056",
      "type": "psc_code"
   },
   {
      "label": "W056 - LEASE OR RENTAL OF EQUIPMENT- CONSTRUCTION AND BUILDING MATERIALS",
      "value": "W056",
      "type": "psc_code"
   },
   {
      "label": "W058 - LEASE OR RENTAL OF EQUIPMENT- COMMUNICATION, DETECTION, AND COHERENT RADIATION EQUIPMENT",
      "value": "W058",
      "type": "psc_code"
   },
   {
      "label": "W058 - LEASE-RENT OF COMMUNICATION EQ",
      "value": "W058",
      "type": "psc_code"
   },
   {
      "label": "W059 - LEASE-RENT OF ELECT-ELCT EQ",
      "value": "W059",
      "type": "psc_code"
   },
   {
      "label": "W059 - LEASE OR RENTAL OF EQUIPMENT- ELECTRICAL AND ELECTRONIC EQUIPMENT COMPONENTS",
      "value": "W059",
      "type": "psc_code"
   },
   {
      "label": "W060 - LEASE-RENT OF FIBER OPTICS MAT",
      "value": "W060",
      "type": "psc_code"
   },
   {
      "label": "W060 - LEASE OR RENTAL OF EQUIPMENT- FIBER OPTICS MATERIALS, COMPONENTS, ASSEMBLIES, AND ACCESSORIES",
      "value": "W060",
      "type": "psc_code"
   },
   {
      "label": "W061 - LEASE OR RENTAL OF EQUIPMENT- ELECTRIC WIRE AND POWER DISTRIBUTION EQUIPMENT",
      "value": "W061",
      "type": "psc_code"
   },
   {
      "label": "W061 - LEASE-RENT OF POWER DISTRIBUTION EQ",
      "value": "W061",
      "type": "psc_code"
   },
   {
      "label": "W062 - LEASE-RENT OF LIGHTING FIXTURES",
      "value": "W062",
      "type": "psc_code"
   },
   {
      "label": "W062 - LEASE OR RENTAL OF EQUIPMENT- LIGHTING FIXTURES AND LAMPS",
      "value": "W062",
      "type": "psc_code"
   },
   {
      "label": "W063 - LEASE OR RENTAL OF EQUIPMENT- ALARM, SIGNAL, AND SECURITY DETECTION SYSTEMS",
      "value": "W063",
      "type": "psc_code"
   },
   {
      "label": "W063 - LEASE-RENT OF ALARM & SIGNAL SYSTEM",
      "value": "W063",
      "type": "psc_code"
   },
   {
      "label": "W065 - LEASE OR RENTAL OF EQUIPMENT- MEDICAL, DENTAL, AND VETERINARY EQUIPMENT AND SUPPLIES",
      "value": "W065",
      "type": "psc_code"
   },
   {
      "label": "W065 - LEASE-RENT OF MEDICAL-DENTAL-VET EQ",
      "value": "W065",
      "type": "psc_code"
   },
   {
      "label": "W066 - LEASE-RENT OF INSTRUMENTS & LAB EQ",
      "value": "W066",
      "type": "psc_code"
   },
   {
      "label": "W066 - LEASE OR RENTAL OF EQUIPMENT- INSTRUMENTS AND LABORATORY EQUIPMENT",
      "value": "W066",
      "type": "psc_code"
   },
   {
      "label": "W067 - LEASE OR RENTAL OF EQUIPMENT- PHOTOGRAPHIC EQUIPMENT",
      "value": "W067",
      "type": "psc_code"
   },
   {
      "label": "W067 - LEASE-RENT OF PHOTOGRAPHIC EQ",
      "value": "W067",
      "type": "psc_code"
   },
   {
      "label": "W068 - LEASE-RENT OF CHEMICAL PRODUCTS",
      "value": "W068",
      "type": "psc_code"
   },
   {
      "label": "W068 - LEASE OR RENTAL OF EQUIPMENT- CHEMICALS AND CHEMICAL PRODUCTS",
      "value": "W068",
      "type": "psc_code"
   },
   {
      "label": "W069 - LEASE-RENT OF TRAINING AIDS-DEVICES",
      "value": "W069",
      "type": "psc_code"
   },
   {
      "label": "W069 - LEASE OR RENTAL OF EQUIPMENT- TRAINING AIDS AND DEVICES",
      "value": "W069",
      "type": "psc_code"
   },
   {
      "label": "W070 - LEASE-RENT OF ADP EQ & SUPPLIES",
      "value": "W070",
      "type": "psc_code"
   },
   {
      "label": "W070 - LEASE OR RENTAL OF EQUIPMENT- ADP EQUIPMENT/SOFTWARE/SUPPLIES/SUPPORT EQUIPMENT",
      "value": "W070",
      "type": "psc_code"
   },
   {
      "label": "W070 - LEASE OR RENTAL OF EQUIPMENT- INFORMATION TECHNOLOGY/SOFTWARE/SUPPLIES/SUPPORT EQUIPMENT",
      "value": "W070",
      "type": "psc_code"
   },
   {
      "label": "W071 - LEASE OR RENTAL OF EQUIPMENT- FURNITURE",
      "value": "W071",
      "type": "psc_code"
   },
   {
      "label": "W071 - LEASE-RENT OF FURNITURE",
      "value": "W071",
      "type": "psc_code"
   },
   {
      "label": "W072 - LEASE OR RENTAL OF EQUIPMENT- HOUSEHOLD AND COMMERCIAL FURNISHINGS AND APPLIANCES",
      "value": "W072",
      "type": "psc_code"
   },
   {
      "label": "W072 - LEASE-RENT OF HOUSEHOLD FURNISHINGS",
      "value": "W072",
      "type": "psc_code"
   },
   {
      "label": "W073 - LEASE OR RENTAL OF EQUIPMENT- FOOD PREPARATION AND SERVING EQUIPMENT",
      "value": "W073",
      "type": "psc_code"
   },
   {
      "label": "W073 - LEASE-RENT OF FOOD PREP-SERVING EQ",
      "value": "W073",
      "type": "psc_code"
   },
   {
      "label": "W074 - LEASE OR RENTAL OF EQUIPMENT- OFFICE MACHINES, TEXT PROCESSING SYSTEMS, AND VISIBLE RECORD EQUIPMENT",
      "value": "W074",
      "type": "psc_code"
   },
   {
      "label": "W074 - LEASE-RENT OF OFFICE MACHINES",
      "value": "W074",
      "type": "psc_code"
   },
   {
      "label": "W075 - LEASE OR RENTAL OF EQUIPMENT- OFFICE SUPPLIES AND DEVICES",
      "value": "W075",
      "type": "psc_code"
   },
   {
      "label": "W075 - LEASE-RENT OF OFFICE SUPPLIES",
      "value": "W075",
      "type": "psc_code"
   },
   {
      "label": "W076 - LEASE-RENT OF BOOKS-MAPS-PUBS",
      "value": "W076",
      "type": "psc_code"
   },
   {
      "label": "W076 - LEASE OR RENTAL OF EQUIPMENT- BOOKS, MAPS, AND OTHER PUBLICATIONS",
      "value": "W076",
      "type": "psc_code"
   },
   {
      "label": "W077 - LEASE OR RENTAL OF EQUIPMENT- MUSICAL INST/PHONOGRAPH/HOME RADIO",
      "value": "W077",
      "type": "psc_code"
   },
   {
      "label": "W077 - LEASE-RENT OF MUSICAL INST-RADIO-TV",
      "value": "W077",
      "type": "psc_code"
   },
   {
      "label": "W078 - LEASE OR RENTAL OF EQUIPMENT- RECREATIONAL AND ATHLETIC EQUIPMENT",
      "value": "W078",
      "type": "psc_code"
   },
   {
      "label": "W078 - LEASE-RENT OF RECREATIONAL EQ",
      "value": "W078",
      "type": "psc_code"
   },
   {
      "label": "W079 - LEASE-RENT OF CLEANING EQ",
      "value": "W079",
      "type": "psc_code"
   },
   {
      "label": "W079 - LEASE OR RENTAL OF EQUIPMENT- CLEANING EQUIPMENT AND SUPPLIES",
      "value": "W079",
      "type": "psc_code"
   },
   {
      "label": "W080 - LEASE-RENT OF BRUSHES-PAINTS-SEALER",
      "value": "W080",
      "type": "psc_code"
   },
   {
      "label": "W080 - LEASE OR RENTAL OF EQUIPMENT- BRUSHES, PAINTS, SEALERS, AND ADHESIVES",
      "value": "W080",
      "type": "psc_code"
   },
   {
      "label": "W081 - LEASE OR RENTAL OF EQUIPMENT- CONTAINERS, PACKAGING, AND PACKING SUPPLIES",
      "value": "W081",
      "type": "psc_code"
   },
   {
      "label": "W081 - LEASE-RENT OF CONTAINERS-PACKAGING",
      "value": "W081",
      "type": "psc_code"
   },
   {
      "label": "W083 - LEASE OR RENTAL OF EQUIPMENT- TEXTILES, LEATHER, FURS, APPAREL AND SHOE FINDINGS, TENTS AND FLAGS",
      "value": "W083",
      "type": "psc_code"
   },
   {
      "label": "W083 - LEASE-RENT OF TEXTILES-LEATHER-FURS",
      "value": "W083",
      "type": "psc_code"
   },
   {
      "label": "W084 - LEASE-RENT OF CLOTHING - INDIVID EQ",
      "value": "W084",
      "type": "psc_code"
   },
   {
      "label": "W084 - LEASE OR RENTAL OF EQUIPMENT- CLOTHING, INDIVIDUAL EQUIPMENT, AND INSIGNIA",
      "value": "W084",
      "type": "psc_code"
   },
   {
      "label": "W085 - LEASE OR RENTAL OF EQUIPMENT- TOILETRIES",
      "value": "W085",
      "type": "psc_code"
   },
   {
      "label": "W085 - LEASE-RENT OF TOILETRIES",
      "value": "W085",
      "type": "psc_code"
   },
   {
      "label": "W087 - LEASE-RENT OF AGRICULTURAL SUPPLIES",
      "value": "W087",
      "type": "psc_code"
   },
   {
      "label": "W087 - LEASE OR RENTAL OF EQUIPMENT- AGRICULTURAL SUPPLIES",
      "value": "W087",
      "type": "psc_code"
   },
   {
      "label": "W088 - LEASE OR RENTAL OF EQUIPMENT- LIVE ANIMALS",
      "value": "W088",
      "type": "psc_code"
   },
   {
      "label": "W088 - LEASE-RENT OF LIVE ANIMALS",
      "value": "W088",
      "type": "psc_code"
   },
   {
      "label": "W089 - LEASE OR RENTAL OF EQUIPMENT- SUBSISTENCE",
      "value": "W089",
      "type": "psc_code"
   },
   {
      "label": "W089 - LEASE-RENT OF SUBSISTENCE",
      "value": "W089",
      "type": "psc_code"
   },
   {
      "label": "W091 - LEASE OR RENTAL OF EQUIPMENT- FUELS, LUBRICANTS, OILS, AND WAXES",
      "value": "W091",
      "type": "psc_code"
   },
   {
      "label": "W091 - LEASE-RENT OF FUELS-LUBRICANTS-OILS",
      "value": "W091",
      "type": "psc_code"
   },
   {
      "label": "W093 - LEASE OR RENTAL OF EQUIPMENT- NONMETALLIC FABRICATED MATERIALS",
      "value": "W093",
      "type": "psc_code"
   },
   {
      "label": "W093 - LEASE-RENT OF NONMETALIC FAB MAT",
      "value": "W093",
      "type": "psc_code"
   },
   {
      "label": "W094 - LEASE-RENT OF NONMETALIC CRUDE MAT",
      "value": "W094",
      "type": "psc_code"
   },
   {
      "label": "W094 - LEASE OR RENTAL OF EQUIPMENT- NONMETALLIC CRUDE MATERIALS",
      "value": "W094",
      "type": "psc_code"
   },
   {
      "label": "W095 - LEASE OR RENTAL OF EQUIPMENT- METAL BARS, SHEETS, AND SHAPES",
      "value": "W095",
      "type": "psc_code"
   },
   {
      "label": "W095 - LEASE-RENT OF METAL BARS & SHEETS",
      "value": "W095",
      "type": "psc_code"
   },
   {
      "label": "W096 - LEASE-RENT OF ORES & MINERALS",
      "value": "W096",
      "type": "psc_code"
   },
   {
      "label": "W096 - LEASE OR RENTAL OF EQUIPMENT- ORES, MINERALS, AND THEIR PRIMARY PRODUCTS",
      "value": "W096",
      "type": "psc_code"
   },
   {
      "label": "W099 - LEASE-RENT OF MISC EQ",
      "value": "W099",
      "type": "psc_code"
   },
   {
      "label": "W099 - LEASE OR RENTAL OF EQUIPMENT- MISCELLANEOUS",
      "value": "W099",
      "type": "psc_code"
   },
   {
      "label": "X - LEASE/RENT FACILITIES",
      "value": "X",
      "type": "psc_code"
   },
   {
      "label": "X1 - LEASE/RENTAL OF BUILDINGS",
      "value": "X1",
      "type": "psc_code"
   },
   {
      "label": "X111 - LEASE/RENTAL OF OFFICE BUILDINGS",
      "value": "X111",
      "type": "psc_code"
   },
   {
      "label": "X112 - LEASE-RENT OF CONF SPACE & FAC",
      "value": "X112",
      "type": "psc_code"
   },
   {
      "label": "X119 - LEASE-RENT OF OTHER ADMIN-SVC BLDGS",
      "value": "X119",
      "type": "psc_code"
   },
   {
      "label": "X121 - LEASE-RENT OF AIR TRAFFIC TOWERS",
      "value": "X121",
      "type": "psc_code"
   },
   {
      "label": "X122 - LEASE-RENT OF AIR TRAFFIC TNG FAC",
      "value": "X122",
      "type": "psc_code"
   },
   {
      "label": "X123 - LEASE-RENT OF RADAR & NAV FACILITY",
      "value": "X123",
      "type": "psc_code"
   },
   {
      "label": "X124 - LEASE/RENTAL OF AIRPORT RUNWAYS",
      "value": "X124",
      "type": "psc_code"
   },
   {
      "label": "X125 - LEASE/RENTAL OF AIRPORT TERMINALS",
      "value": "X125",
      "type": "psc_code"
   },
   {
      "label": "X126 - LEASE-RENT OF MISSILE SYSTEMS FAC",
      "value": "X126",
      "type": "psc_code"
   },
   {
      "label": "X127 - LEASE-RENT OF ELCT & COMM SYS FAC",
      "value": "X127",
      "type": "psc_code"
   },
   {
      "label": "X129 - LEASE-RENT OF OTHER AIRFIELD STRUCT",
      "value": "X129",
      "type": "psc_code"
   },
   {
      "label": "X131 - LEASE/RENTAL OF SCHOOLS",
      "value": "X131",
      "type": "psc_code"
   },
   {
      "label": "X139 - LEASE-RENT OF OTHER EDUCATIONAL BLD",
      "value": "X139",
      "type": "psc_code"
   },
   {
      "label": "X141 - LEASE-RENT OF HOSPITALS & INFIRMARY",
      "value": "X141",
      "type": "psc_code"
   },
   {
      "label": "X142 - LEASE-RENT OF LABS & CLINICS",
      "value": "X142",
      "type": "psc_code"
   },
   {
      "label": "X149 - LEASE-RENT OF OTHER HOSPITAL BLDGS",
      "value": "X149",
      "type": "psc_code"
   },
   {
      "label": "X151 - LEASE-RENT OF AMMUNITION FACILITIES",
      "value": "X151",
      "type": "psc_code"
   },
   {
      "label": "X152 - LEASE-RENT OF MAINT BLDGS",
      "value": "X152",
      "type": "psc_code"
   },
   {
      "label": "X153 - LEASE-RENT OF PRODUCTION BLDGS",
      "value": "X153",
      "type": "psc_code"
   },
   {
      "label": "X154 - LEASE-RENT OF SHIP CONST-REP FAC",
      "value": "X154",
      "type": "psc_code"
   },
   {
      "label": "X155 - LEASE-RENT OF TANK AUTOMOTIVE FAC",
      "value": "X155",
      "type": "psc_code"
   },
   {
      "label": "X159 - LEASE-RENT OF OTHER INDUSTRIAL BLDG",
      "value": "X159",
      "type": "psc_code"
   },
   {
      "label": "X161 - LEASE-RENT OF FAMILY HOUSING",
      "value": "X161",
      "type": "psc_code"
   },
   {
      "label": "X162 - LEASE-RENT OF RECREATIONAL BLDGS",
      "value": "X162",
      "type": "psc_code"
   },
   {
      "label": "X163 - LEASE-RENT OF TROOP HOUSING",
      "value": "X163",
      "type": "psc_code"
   },
   {
      "label": "X164 - LEASE/RENTAL OF DINING FACILITIES",
      "value": "X164",
      "type": "psc_code"
   },
   {
      "label": "X165 - LEASE-RENT OF RELIGIOUS",
      "value": "X165",
      "type": "psc_code"
   },
   {
      "label": "X166 - LEASE/RENTAL OF PENAL FACILITIES",
      "value": "X166",
      "type": "psc_code"
   },
   {
      "label": "X169 - LEASE-RENT OF OTHER RESIDENTIAL BLD",
      "value": "X169",
      "type": "psc_code"
   },
   {
      "label": "X171 - LEASE-RENT OF AMMO STORAGE BLDGS",
      "value": "X171",
      "type": "psc_code"
   },
   {
      "label": "X172 - LEASE-RENT OF FOOD STORAGE BLDGS",
      "value": "X172",
      "type": "psc_code"
   },
   {
      "label": "X173 - LEASE-RENT OF FUEL STORAGE BLDGS",
      "value": "X173",
      "type": "psc_code"
   },
   {
      "label": "X174 - LEASE-RENT OF OPEN STORAGE FAC",
      "value": "X174",
      "type": "psc_code"
   },
   {
      "label": "X179 - LEASE-RENT OF OTHER WAREHOUSE BLDGS",
      "value": "X179",
      "type": "psc_code"
   },
   {
      "label": "X181 - LEASE-RENT OF R&D GOCO FACILITIES",
      "value": "X181",
      "type": "psc_code"
   },
   {
      "label": "X182 - LEASE-RENT OF R&D GOGO FACILITIES",
      "value": "X182",
      "type": "psc_code"
   },
   {
      "label": "X183 - LEASE-RENT OF R&D GOCO ENVIR LAB",
      "value": "X183",
      "type": "psc_code"
   },
   {
      "label": "X184 - LEASE-RENT OF R&D GOGO ENVIR LAB",
      "value": "X184",
      "type": "psc_code"
   },
   {
      "label": "X191 - LEASE-RENT OF EXHIBITION BUILDINGS",
      "value": "X191",
      "type": "psc_code"
   },
   {
      "label": "X192 - LEASE-RENT OF TEST BUILDINGS",
      "value": "X192",
      "type": "psc_code"
   },
   {
      "label": "X199 - LEASE-RENT OF MISC BLDGS",
      "value": "X199",
      "type": "psc_code"
   },
   {
      "label": "X1AA - LEASE/RENTAL OF OFFICE BUILDINGS",
      "value": "X1AA",
      "type": "psc_code"
   },
   {
      "label": "X1AB - LEASE/RENTAL OF CONFERENCE SPACE AND FACILITIES",
      "value": "X1AB",
      "type": "psc_code"
   },
   {
      "label": "X1AZ - LEASE/RENTAL OF OTHER ADMINISTRATIVE FACILITIES AND SERVICE BUILDINGS",
      "value": "X1AZ",
      "type": "psc_code"
   },
   {
      "label": "X1BA - LEASE/RENTAL OF AIR TRAFFIC CONTROL TOWERS",
      "value": "X1BA",
      "type": "psc_code"
   },
   {
      "label": "X1BB - LEASE/RENTAL OF AIR TRAFFIC CONTROL TRAINING FACILITIES",
      "value": "X1BB",
      "type": "psc_code"
   },
   {
      "label": "X1BC - LEASE/RENTAL OF RADAR AND NAVIGATIONAL FACILITIES",
      "value": "X1BC",
      "type": "psc_code"
   },
   {
      "label": "X1BD - LEASE/RENTAL OF AIRPORT RUNWAYS AND TAXIWAYS",
      "value": "X1BD",
      "type": "psc_code"
   },
   {
      "label": "X1BE - LEASE/RENTAL OF AIRPORT TERMINALS",
      "value": "X1BE",
      "type": "psc_code"
   },
   {
      "label": "X1BF - LEASE/RENTAL OF MISSILE SYSTEM FACILITIES",
      "value": "X1BF",
      "type": "psc_code"
   },
   {
      "label": "X1BG - LEASE/RENTAL OF ELECTRONIC AND COMMUNICATIONS FACILITIES",
      "value": "X1BG",
      "type": "psc_code"
   },
   {
      "label": "X1BZ - LEASE/RENTAL OF OTHER AIRFIELD STRUCTURES",
      "value": "X1BZ",
      "type": "psc_code"
   },
   {
      "label": "X1CA - LEASE/RENTAL OF SCHOOLS",
      "value": "X1CA",
      "type": "psc_code"
   },
   {
      "label": "X1CZ - LEASE/RENTAL OF OTHER EDUCATIONAL BUILDINGS",
      "value": "X1CZ",
      "type": "psc_code"
   },
   {
      "label": "X1DA - LEASE/RENTAL OF HOSPITALS AND INFIRMARIES",
      "value": "X1DA",
      "type": "psc_code"
   },
   {
      "label": "X1DB - LEASE/RENTAL OF LABORATORIES AND CLINICS",
      "value": "X1DB",
      "type": "psc_code"
   },
   {
      "label": "X1DZ - LEASE/RENTAL OF OTHER HOSPITAL BUILDINGS",
      "value": "X1DZ",
      "type": "psc_code"
   },
   {
      "label": "X1EA - LEASE/RENTAL OF AMMUNITION FACILITIES",
      "value": "X1EA",
      "type": "psc_code"
   },
   {
      "label": "X1EB - LEASE/RENTAL OF MAINTENANCE BUILDINGS",
      "value": "X1EB",
      "type": "psc_code"
   },
   {
      "label": "X1EC - LEASE/RENTAL OF PRODUCTION BUILDINGS",
      "value": "X1EC",
      "type": "psc_code"
   },
   {
      "label": "X1ED - LEASE/RENTAL OF SHIP CONSTRUCTION AND REPAIR FACILITIES",
      "value": "X1ED",
      "type": "psc_code"
   },
   {
      "label": "X1EE - LEASE/RENTAL OF TANK AUTOMOTIVE FACILITIES",
      "value": "X1EE",
      "type": "psc_code"
   },
   {
      "label": "X1EZ - LEASE/RENTAL OF OTHER INDUSTRIAL BUILDINGS",
      "value": "X1EZ",
      "type": "psc_code"
   },
   {
      "label": "X1FA - LEASE/RENTAL OF FAMILY HOUSING FACILITIES",
      "value": "X1FA",
      "type": "psc_code"
   },
   {
      "label": "X1FB - LEASE/RENTAL OF RECREATIONAL BUILDINGS",
      "value": "X1FB",
      "type": "psc_code"
   },
   {
      "label": "X1FC - LEASE/RENTAL OF TROOP HOUSING FACILITIES",
      "value": "X1FC",
      "type": "psc_code"
   },
   {
      "label": "X1FD - LEASE/RENTAL OF DINING FACILITIES",
      "value": "X1FD",
      "type": "psc_code"
   },
   {
      "label": "X1FE - LEASE/RENTAL OF RELIGIOUS FACILITIES",
      "value": "X1FE",
      "type": "psc_code"
   },
   {
      "label": "X1FF - LEASE/RENTAL OF PENAL FACILITIES",
      "value": "X1FF",
      "type": "psc_code"
   },
   {
      "label": "X1FZ - LEASE/RENTAL OF OTHER RESIDENTIAL BUILDINGS",
      "value": "X1FZ",
      "type": "psc_code"
   },
   {
      "label": "X1GA - LEASE/RENTAL OF AMMUNITION STORAGE BUILDINGS",
      "value": "X1GA",
      "type": "psc_code"
   },
   {
      "label": "X1GB - LEASE/RENTAL OF FOOD OR GRAIN STORAGE BUILDINGS",
      "value": "X1GB",
      "type": "psc_code"
   },
   {
      "label": "X1GC - LEASE/RENTAL OF FUEL STORAGE BUILDINGS",
      "value": "X1GC",
      "type": "psc_code"
   },
   {
      "label": "X1GD - LEASE/RENTAL OF OPEN STORAGE FACILITIES",
      "value": "X1GD",
      "type": "psc_code"
   },
   {
      "label": "X1GZ - LEASE/RENTAL OF OTHER WAREHOUSE BUILDINGS",
      "value": "X1GZ",
      "type": "psc_code"
   },
   {
      "label": "X1HA - LEASE/RENTAL OF GOVERNMENT-OWNED CONTRACTOR-OPERATED (GOCO) R&D FACILITIES",
      "value": "X1HA",
      "type": "psc_code"
   },
   {
      "label": "X1HB - LEASE/RENTAL OF GOVERNMENT-OWNED GOVERNMENT-OPERATED (GOGO) R&D FACILITIES",
      "value": "X1HB",
      "type": "psc_code"
   },
   {
      "label": "X1HC - LEASE/RENTAL OF GOVERNMENT-OWNED CONTRACTOR-OPERATED (GOCO) ENVIRONMENTAL LABORATORIES",
      "value": "X1HC",
      "type": "psc_code"
   },
   {
      "label": "X1HZ - LEASE/RENTAL OF GOVERNMENT-OWNED GOVERNMENT-OPERATED (GOGO) ENVIRONMENTAL LABORATORIES",
      "value": "X1HZ",
      "type": "psc_code"
   },
   {
      "label": "X1JA - LEASE/RENTAL OF MUSEUMS AND EXHIBITION BUILDINGS",
      "value": "X1JA",
      "type": "psc_code"
   },
   {
      "label": "X1JB - LEASE/RENTAL OF TESTING AND MEASUREMENT BUILDINGS",
      "value": "X1JB",
      "type": "psc_code"
   },
   {
      "label": "X1JZ - LEASE/RENTAL OF MISCELLANEOUS BUILDINGS",
      "value": "X1JZ",
      "type": "psc_code"
   },
   {
      "label": "X1KA - LEASE/RENTAL OF DAMS",
      "value": "X1KA",
      "type": "psc_code"
   },
   {
      "label": "X1KB - LEASE/RENTAL OF CANALS",
      "value": "X1KB",
      "type": "psc_code"
   },
   {
      "label": "X1KC - LEASE/RENTAL OF MINE FIRE CONTROL FACILITIES",
      "value": "X1KC",
      "type": "psc_code"
   },
   {
      "label": "X1KD - LEASE/RENTAL OF MINE SUBSIDENCE CONTROL FACILITIES",
      "value": "X1KD",
      "type": "psc_code"
   },
   {
      "label": "X1KE - LEASE/RENTAL OF SURFACE MINE RECLAMATION FACILITIES",
      "value": "X1KE",
      "type": "psc_code"
   },
   {
      "label": "X1KF - LEASE/RENTAL OF DREDGING FACILITIES",
      "value": "X1KF",
      "type": "psc_code"
   },
   {
      "label": "X1KZ - LEASE/RENTAL OF OTHER CONSERVATION AND DEVELOPMENT FACILITIES",
      "value": "X1KZ",
      "type": "psc_code"
   },
   {
      "label": "X1LA - LEASE/RENTAL OF AIRPORT SERVICE ROADS",
      "value": "X1LA",
      "type": "psc_code"
   },
   {
      "label": "X1LB - LEASE/RENTAL OF HIGHWAYS, ROADS, STREETS, BRIDGES, AND RAILWAYS",
      "value": "X1LB",
      "type": "psc_code"
   },
   {
      "label": "X1LC - LEASE/RENTAL OF TUNNELS AND SUBSURFACE STRUCTURES",
      "value": "X1LC",
      "type": "psc_code"
   },
   {
      "label": "X1LZ - LEASE/RENTAL OF PARKING FACILITIES",
      "value": "X1LZ",
      "type": "psc_code"
   },
   {
      "label": "X1MA - LEASE/RENTAL OF EPG FACILITIES - COAL",
      "value": "X1MA",
      "type": "psc_code"
   },
   {
      "label": "X1MB - LEASE/RENTAL OF EPG FACILITIES - GAS",
      "value": "X1MB",
      "type": "psc_code"
   },
   {
      "label": "X1MC - LEASE/RENTAL OF EPG FACILITIES - GEOTHERMAL",
      "value": "X1MC",
      "type": "psc_code"
   },
   {
      "label": "X1MD - LEASE/RENTAL OF EPG FACILITIES - HYDRO",
      "value": "X1MD",
      "type": "psc_code"
   },
   {
      "label": "X1ME - LEASE/RENTAL OF EPG FACILITIES - NUCLEAR",
      "value": "X1ME",
      "type": "psc_code"
   },
   {
      "label": "X1MF - LEASE/RENTAL OF EPG FACILITIES - PETROLEUM",
      "value": "X1MF",
      "type": "psc_code"
   },
   {
      "label": "X1MG - LEASE/RENTAL OF EPG FACILITIES - SOLAR",
      "value": "X1MG",
      "type": "psc_code"
   },
   {
      "label": "X1MH - LEASE/RENTAL OF EPG FACILITIES - WIND",
      "value": "X1MH",
      "type": "psc_code"
   },
   {
      "label": "X1MZ - LEASE/RENTAL OF EPG FACILITIES - OTHER, INCLUDING TRANSMISSION",
      "value": "X1MZ",
      "type": "psc_code"
   },
   {
      "label": "X1NA - LEASE/RENTAL OF FUEL SUPPLY FACILITIES",
      "value": "X1NA",
      "type": "psc_code"
   },
   {
      "label": "X1NB - LEASE/RENTAL OF HEATING AND COOLING PLANTS",
      "value": "X1NB",
      "type": "psc_code"
   },
   {
      "label": "X1NC - LEASE/RENTAL OF POLLUTION ABATEMENT AND CONTROL FACILITIES",
      "value": "X1NC",
      "type": "psc_code"
   },
   {
      "label": "X1ND - LEASE/RENTAL OF SEWAGE AND WASTE FACILITIES",
      "value": "X1ND",
      "type": "psc_code"
   },
   {
      "label": "X1NE - LEASE/RENTAL OF WATER SUPPLY FACILITIES",
      "value": "X1NE",
      "type": "psc_code"
   },
   {
      "label": "X1NZ - LEASE/RENTAL OF OTHER UTILITIES",
      "value": "X1NZ",
      "type": "psc_code"
   },
   {
      "label": "X1PA - LEASE/RENTAL OF RECREATION FACILITIES (NON-BUILDING)",
      "value": "X1PA",
      "type": "psc_code"
   },
   {
      "label": "X1PB - LEASE/RENTAL OF EXHIBIT DESIGN (NON-BUILDING)",
      "value": "X1PB",
      "type": "psc_code"
   },
   {
      "label": "X1PC - LEASE/RENTAL OF UNIMPROVED REAL PROPERTY (LAND)",
      "value": "X1PC",
      "type": "psc_code"
   },
   {
      "label": "X1PD - LEASE/RENTAL OF WASTE TREATMENT AND STORAGE FACILITIES",
      "value": "X1PD",
      "type": "psc_code"
   },
   {
      "label": "X1PZ - LEASE/RENTAL OF OTHER NON-BUILDING FACILITIES",
      "value": "X1PZ",
      "type": "psc_code"
   },
   {
      "label": "X1QA - LEASE/RENTAL OF RESTORATION OF REAL PROPERTY (PUBLIC OR PRIVATE)",
      "value": "X1QA",
      "type": "psc_code"
   },
   {
      "label": "X2 - LEASE/RENTAL NONBUILDING FACILITIES",
      "value": "X2",
      "type": "psc_code"
   },
   {
      "label": "X211 - LEASE/RENTAL OF DAMS",
      "value": "X211",
      "type": "psc_code"
   },
   {
      "label": "X212 - LEASE/RENTAL OF CANALS",
      "value": "X212",
      "type": "psc_code"
   },
   {
      "label": "X213 - LEASE-RENT OF MINE FIRE CONT",
      "value": "X213",
      "type": "psc_code"
   },
   {
      "label": "X214 - LEASE-RENT OF MINE SUBSIDENCE CONT",
      "value": "X214",
      "type": "psc_code"
   },
   {
      "label": "X215 - LEASE-RENT OF SURFACE MINE RECLAM",
      "value": "X215",
      "type": "psc_code"
   },
   {
      "label": "X216 - LEASE/RENTAL OF DREDGING",
      "value": "X216",
      "type": "psc_code"
   },
   {
      "label": "X219 - LEASE-RENT OF OTHER CONSV STRUCTURE",
      "value": "X219",
      "type": "psc_code"
   },
   {
      "label": "X221 - LEASE-RENT OF AIRPORT SERVICE ROADS",
      "value": "X221",
      "type": "psc_code"
   },
   {
      "label": "X222 - LEASE-RENT OF HWYS-RDS-STS-BRDGS-RA",
      "value": "X222",
      "type": "psc_code"
   },
   {
      "label": "X223 - LEASE-RENT OF TUNNELS-SUBSURF STRUC",
      "value": "X223",
      "type": "psc_code"
   },
   {
      "label": "X224 - LEASE-RENT OF PARKING FACILITIES",
      "value": "X224",
      "type": "psc_code"
   },
   {
      "label": "X231 - LEASE-RENT OF EPG - COAL",
      "value": "X231",
      "type": "psc_code"
   },
   {
      "label": "X232 - LEASE-RENT OF EPG - GAS",
      "value": "X232",
      "type": "psc_code"
   },
   {
      "label": "X233 - LEASE-RENT OF EPG - GEOTHERMAL",
      "value": "X233",
      "type": "psc_code"
   },
   {
      "label": "X234 - LEASE-RENT OF EPG - HYDRO",
      "value": "X234",
      "type": "psc_code"
   },
   {
      "label": "X235 - LEASE-RENT OF EPG - NUCLEAR",
      "value": "X235",
      "type": "psc_code"
   },
   {
      "label": "X236 - LEASE-RENT OF EPG - PETROLEUM",
      "value": "X236",
      "type": "psc_code"
   },
   {
      "label": "X237 - LEASE-RENT OF EPG - SOLAR",
      "value": "X237",
      "type": "psc_code"
   },
   {
      "label": "X239 - LEASE-RENT OF EPG - OTHER",
      "value": "X239",
      "type": "psc_code"
   },
   {
      "label": "X241 - LEASE-RENT OF FUEL SUPPLY",
      "value": "X241",
      "type": "psc_code"
   },
   {
      "label": "X242 - LEASE-RENT OF HEATING & COOL PLANTS",
      "value": "X242",
      "type": "psc_code"
   },
   {
      "label": "X243 - LEASE-RENT OF POLLUTION ABATEMENT",
      "value": "X243",
      "type": "psc_code"
   },
   {
      "label": "X244 - LEASE-RENT OF SEWAGE & WASTE",
      "value": "X244",
      "type": "psc_code"
   },
   {
      "label": "X245 - LEASE-RENT OF WATER SUPPLY",
      "value": "X245",
      "type": "psc_code"
   },
   {
      "label": "X249 - LEASE/RENTAL OF OTHER UTILITIES",
      "value": "X249",
      "type": "psc_code"
   },
   {
      "label": "X291 - LEASE-RENT OF RECREA NON-BLDG STRUC",
      "value": "X291",
      "type": "psc_code"
   },
   {
      "label": "X292 - LEASE-RENT OF EXHIBIT (NON-BLDG)",
      "value": "X292",
      "type": "psc_code"
   },
   {
      "label": "X293 - LEASE-RENT OF UNIMPROVED REAL PROP",
      "value": "X293",
      "type": "psc_code"
   },
   {
      "label": "X294 - LEASE-RENT OF WASTE TRMT-STORE FAC",
      "value": "X294",
      "type": "psc_code"
   },
   {
      "label": "X299 - LEASE-RENT OF ALL OTH NON-BLDG FACS",
      "value": "X299",
      "type": "psc_code"
   },
   {
      "label": "X3 - LEASE/RENTAL OF RESTORATION ACTIVS",
      "value": "X3",
      "type": "psc_code"
   },
   {
      "label": "X300 - LEASE-RENT OF RESTORATION",
      "value": "X300",
      "type": "psc_code"
   },
   {
      "label": "Y - CONSTRUCT OF STRUCTURES/FACILITIES",
      "value": "Y",
      "type": "psc_code"
   },
   {
      "label": "Y1 - CONSTRUCTION OF BUILDINGS",
      "value": "Y1",
      "type": "psc_code"
   },
   {
      "label": "Y111 - CONSTRUCTION OF OFFICE BUILDINGS",
      "value": "Y111",
      "type": "psc_code"
   },
   {
      "label": "Y112 - CONSTRUCT/CONF SPACE & FAC",
      "value": "Y112",
      "type": "psc_code"
   },
   {
      "label": "Y119 - CONSTRUCT/OTHER ADMIN & SVCS BLDGS",
      "value": "Y119",
      "type": "psc_code"
   },
   {
      "label": "Y121 - CONSTRUCT/AIR TRAFFIC CONT TOWERS",
      "value": "Y121",
      "type": "psc_code"
   },
   {
      "label": "Y122 - CONSTRUCT/AIR TRAFFIC TRAINING FAC",
      "value": "Y122",
      "type": "psc_code"
   },
   {
      "label": "Y123 - CONSTRUCT/RADAR & NAVIGATIONAL FAC",
      "value": "Y123",
      "type": "psc_code"
   },
   {
      "label": "Y124 - CONSTRUCTION OF AIRPORT RUNWAYS",
      "value": "Y124",
      "type": "psc_code"
   },
   {
      "label": "Y125 - CONSTRUCTION OF AIRPORT TERMINALS",
      "value": "Y125",
      "type": "psc_code"
   },
   {
      "label": "Y126 - CONSTRUCT/MISSILE SYSTEM FACILITIES",
      "value": "Y126",
      "type": "psc_code"
   },
   {
      "label": "Y127 - CONSTRUCT/ELCT & COMM SYSTEM FAC",
      "value": "Y127",
      "type": "psc_code"
   },
   {
      "label": "Y129 - CONSTRUCT/OTHER AIRFIELD STRUCTURES",
      "value": "Y129",
      "type": "psc_code"
   },
   {
      "label": "Y131 - CONSTRUCTION OF SCHOOLS",
      "value": "Y131",
      "type": "psc_code"
   },
   {
      "label": "Y139 - CONSTRUCT/OTHER EDUCATIONAL BLDGS",
      "value": "Y139",
      "type": "psc_code"
   },
   {
      "label": "Y141 - CONSTRUCT/HOSPITALS & INFIRMARIES",
      "value": "Y141",
      "type": "psc_code"
   },
   {
      "label": "Y142 - CONSTRUCT/LABORATORIES & CLINICS",
      "value": "Y142",
      "type": "psc_code"
   },
   {
      "label": "Y149 - CONSTRUCT/OTHER HOSPITAL BLDGS",
      "value": "Y149",
      "type": "psc_code"
   },
   {
      "label": "Y151 - CONSTRUCT/AMMUNITION FACILITIES",
      "value": "Y151",
      "type": "psc_code"
   },
   {
      "label": "Y152 - CONSTRUCT/MAINT BLDGS",
      "value": "Y152",
      "type": "psc_code"
   },
   {
      "label": "Y153 - CONSTRUCT/PRODUCTION BLDGS",
      "value": "Y153",
      "type": "psc_code"
   },
   {
      "label": "Y154 - CONSTRUCT/SHIP CONSTR & REP FAC",
      "value": "Y154",
      "type": "psc_code"
   },
   {
      "label": "Y155 - CONSTRUCT/TANK AUTOMOTIVE FAC",
      "value": "Y155",
      "type": "psc_code"
   },
   {
      "label": "Y159 - CONSTRUCT/OTHER INDUSTRIAL BLDGS",
      "value": "Y159",
      "type": "psc_code"
   },
   {
      "label": "Y161 - CONSTRUCT/FAMILY HOUSING",
      "value": "Y161",
      "type": "psc_code"
   },
   {
      "label": "Y162 - CONSTRUCT/RECREATIONAL BLDGS",
      "value": "Y162",
      "type": "psc_code"
   },
   {
      "label": "Y163 - CONSTRUCT/TROOP HOUSING",
      "value": "Y163",
      "type": "psc_code"
   },
   {
      "label": "Y164 - CONSTRUCTION OF DINING FACILITIES",
      "value": "Y164",
      "type": "psc_code"
   },
   {
      "label": "Y165 - CONSTRUCT/RELIGIOUS FACILITIES",
      "value": "Y165",
      "type": "psc_code"
   },
   {
      "label": "Y166 - CONSTRUCTION OF PENAL FACILITIES",
      "value": "Y166",
      "type": "psc_code"
   },
   {
      "label": "Y169 - CONSTRUCT/OTHER RESIDENTIAL BLDGS",
      "value": "Y169",
      "type": "psc_code"
   },
   {
      "label": "Y171 - CONSTRUCT/AMMUNITION STORAGE BLDG",
      "value": "Y171",
      "type": "psc_code"
   },
   {
      "label": "Y172 - CONSTRUCT/FOOD/GRAIN STORAGE BLDGS",
      "value": "Y172",
      "type": "psc_code"
   },
   {
      "label": "Y173 - CONSTRUCT/FUEL STORAGE BLDGS",
      "value": "Y173",
      "type": "psc_code"
   },
   {
      "label": "Y174 - CONSTRUCT/OPEN STORAGE FACILITIES",
      "value": "Y174",
      "type": "psc_code"
   },
   {
      "label": "Y179 - CONSTRUCT/OTHER WAREHOUSE BLDGS",
      "value": "Y179",
      "type": "psc_code"
   },
   {
      "label": "Y181 - CONSTRUCT/R&D - GOCO FACILITIES",
      "value": "Y181",
      "type": "psc_code"
   },
   {
      "label": "Y182 - CONSTRUCT/R&D - GOGO FACILITIES",
      "value": "Y182",
      "type": "psc_code"
   },
   {
      "label": "Y183 - CONSTRUCT/R&D-GOCO ENVIR LABS",
      "value": "Y183",
      "type": "psc_code"
   },
   {
      "label": "Y184 - CONSTRUCT/R&D-GOGO ENVIR LABS",
      "value": "Y184",
      "type": "psc_code"
   },
   {
      "label": "Y191 - CONSTRUCT/MUSEUMS & EXHIBITION BLDG",
      "value": "Y191",
      "type": "psc_code"
   },
   {
      "label": "Y192 - CONSTRUCT/TEST & MEASUREMENT BLDGS",
      "value": "Y192",
      "type": "psc_code"
   },
   {
      "label": "Y199 - CONSTRUCT/MISC BLDGS",
      "value": "Y199",
      "type": "psc_code"
   },
   {
      "label": "Y1AA - CONSTRUCTION OF OFFICE BUILDINGS",
      "value": "Y1AA",
      "type": "psc_code"
   },
   {
      "label": "Y1AB - CONSTRUCTION OF CONFERENCE SPACE AND FACILITIES",
      "value": "Y1AB",
      "type": "psc_code"
   },
   {
      "label": "Y1AZ - CONSTRUCTION OF OTHER ADMINISTRATIVE FACILITIES AND SERVICE BUILDINGS",
      "value": "Y1AZ",
      "type": "psc_code"
   },
   {
      "label": "Y1BA - CONSTRUCTION OF AIR TRAFFIC CONTROL TOWERS",
      "value": "Y1BA",
      "type": "psc_code"
   },
   {
      "label": "Y1BB - CONSTRUCTION OF AIR TRAFFIC CONTROL TRAINING FACILITIES",
      "value": "Y1BB",
      "type": "psc_code"
   },
   {
      "label": "Y1BC - CONSTRUCTION OF RADAR AND NAVIGATIONAL FACILITIES",
      "value": "Y1BC",
      "type": "psc_code"
   },
   {
      "label": "Y1BD - CONSTRUCTION OF AIRPORT RUNWAYS AND TAXIWAYS",
      "value": "Y1BD",
      "type": "psc_code"
   },
   {
      "label": "Y1BE - CONSTRUCTION OF AIRPORT TERMINALS",
      "value": "Y1BE",
      "type": "psc_code"
   },
   {
      "label": "Y1BF - CONSTRUCTION OF MISSILE SYSTEM FACILITIES",
      "value": "Y1BF",
      "type": "psc_code"
   },
   {
      "label": "Y1BG - CONSTRUCTION OF ELECTRONIC AND COMMUNICATIONS FACILITIES",
      "value": "Y1BG",
      "type": "psc_code"
   },
   {
      "label": "Y1BZ - CONSTRUCTION OF OTHER AIRFIELD STRUCTURES",
      "value": "Y1BZ",
      "type": "psc_code"
   },
   {
      "label": "Y1CA - CONSTRUCTION OF SCHOOLS",
      "value": "Y1CA",
      "type": "psc_code"
   },
   {
      "label": "Y1CZ - CONSTRUCTION OF OTHER EDUCATIONAL BUILDINGS",
      "value": "Y1CZ",
      "type": "psc_code"
   },
   {
      "label": "Y1DA - CONSTRUCTION OF HOSPITALS AND INFIRMARIES",
      "value": "Y1DA",
      "type": "psc_code"
   },
   {
      "label": "Y1DB - CONSTRUCTION OF LABORATORIES AND CLINICS",
      "value": "Y1DB",
      "type": "psc_code"
   },
   {
      "label": "Y1DZ - CONSTRUCTION OF OTHER HOSPITAL BUILDINGS",
      "value": "Y1DZ",
      "type": "psc_code"
   },
   {
      "label": "Y1EA - CONSTRUCTION OF AMMUNITION FACILITIES",
      "value": "Y1EA",
      "type": "psc_code"
   },
   {
      "label": "Y1EB - CONSTRUCTION OF MAINTENANCE BUILDINGS",
      "value": "Y1EB",
      "type": "psc_code"
   },
   {
      "label": "Y1EC - CONSTRUCTION OF PRODUCTION BUILDINGS",
      "value": "Y1EC",
      "type": "psc_code"
   },
   {
      "label": "Y1ED - CONSTRUCTION OF SHIP CONSTRUCTION AND REPAIR FACILITIES",
      "value": "Y1ED",
      "type": "psc_code"
   },
   {
      "label": "Y1EE - CONSTRUCTION OF TANK AUTOMOTIVE FACILITIES",
      "value": "Y1EE",
      "type": "psc_code"
   },
   {
      "label": "Y1EZ - CONSTRUCTION OF OTHER INDUSTRIAL BUILDINGS",
      "value": "Y1EZ",
      "type": "psc_code"
   },
   {
      "label": "Y1FA - CONSTRUCTION OF FAMILY HOUSING FACILITIES",
      "value": "Y1FA",
      "type": "psc_code"
   },
   {
      "label": "Y1FB - CONSTRUCTION OF RECREATIONAL BUILDINGS",
      "value": "Y1FB",
      "type": "psc_code"
   },
   {
      "label": "Y1FC - CONSTRUCTION OF TROOP HOUSING FACILITIES",
      "value": "Y1FC",
      "type": "psc_code"
   },
   {
      "label": "Y1FD - CONSTRUCTION OF DINING FACILITIES",
      "value": "Y1FD",
      "type": "psc_code"
   },
   {
      "label": "Y1FE - CONSTRUCTION OF RELIGIOUS FACILITIES",
      "value": "Y1FE",
      "type": "psc_code"
   },
   {
      "label": "Y1FF - CONSTRUCTION OF PENAL FACILITIES",
      "value": "Y1FF",
      "type": "psc_code"
   },
   {
      "label": "Y1FZ - CONSTRUCTION OF OTHER RESIDENTIAL BUILDINGS",
      "value": "Y1FZ",
      "type": "psc_code"
   },
   {
      "label": "Y1GA - CONSTRUCTION OF AMMUNITION STORAGE BUILDINGS",
      "value": "Y1GA",
      "type": "psc_code"
   },
   {
      "label": "Y1GB - CONSTRUCTION OF FOOD OR GRAIN STORAGE BUILDINGS",
      "value": "Y1GB",
      "type": "psc_code"
   },
   {
      "label": "Y1GC - CONSTRUCTION OF FUEL STORAGE BUILDINGS",
      "value": "Y1GC",
      "type": "psc_code"
   },
   {
      "label": "Y1GD - CONSTRUCTION OF OPEN STORAGE FACILITIES",
      "value": "Y1GD",
      "type": "psc_code"
   },
   {
      "label": "Y1GZ - CONSTRUCTION OF OTHER WAREHOUSE BUILDINGS",
      "value": "Y1GZ",
      "type": "psc_code"
   },
   {
      "label": "Y1HA - CONSTRUCTION OF GOVERNMENT-OWNED CONTRACTOR-OPERATED (GOCO) R&D FACILITIES",
      "value": "Y1HA",
      "type": "psc_code"
   },
   {
      "label": "Y1HB - CONSTRUCTION OF GOVERNMENT-OWNED GOVERNMENT-OPERATED (GOGO) R&D FACILITIES",
      "value": "Y1HB",
      "type": "psc_code"
   },
   {
      "label": "Y1HC - CONSTRUCTION OF GOVERNMENT-OWNED CONTRACTOR-OPERATED (GOCO) ENVIRONMENTAL LABORATORIES",
      "value": "Y1HC",
      "type": "psc_code"
   },
   {
      "label": "Y1HZ - CONSTRUCTION OF GOVERNMENT-OWNED GOVERNMENT-OPERATED (GOGO) ENVIRONMENTAL LABORATORIES",
      "value": "Y1HZ",
      "type": "psc_code"
   },
   {
      "label": "Y1JA - CONSTRUCTION OF MUSEUMS AND EXHIBITION BUILDINGS",
      "value": "Y1JA",
      "type": "psc_code"
   },
   {
      "label": "Y1JB - CONSTRUCTION OF TESTING AND MEASUREMENT BUILDINGS",
      "value": "Y1JB",
      "type": "psc_code"
   },
   {
      "label": "Y1JZ - CONSTRUCTION OF MISCELLANEOUS BUILDINGS",
      "value": "Y1JZ",
      "type": "psc_code"
   },
   {
      "label": "Y1KA - CONSTRUCTION OF DAMS",
      "value": "Y1KA",
      "type": "psc_code"
   },
   {
      "label": "Y1KB - CONSTRUCTION OF CANALS",
      "value": "Y1KB",
      "type": "psc_code"
   },
   {
      "label": "Y1KC - CONSTRUCTION OF MINE FIRE CONTROL FACILITIES",
      "value": "Y1KC",
      "type": "psc_code"
   },
   {
      "label": "Y1KD - CONSTRUCTION OF MINE SUBSIDENCE CONTROL FACILITIES",
      "value": "Y1KD",
      "type": "psc_code"
   },
   {
      "label": "Y1KE - CONSTRUCTION OF SURFACE MINE RECLAMATION FACILITIES",
      "value": "Y1KE",
      "type": "psc_code"
   },
   {
      "label": "Y1KF - CONSTRUCTION OF DREDGING FACILITIES",
      "value": "Y1KF",
      "type": "psc_code"
   },
   {
      "label": "Y1KZ - CONSTRUCTION OF OTHER CONSERVATION AND DEVELOPMENT FACILITIES",
      "value": "Y1KZ",
      "type": "psc_code"
   },
   {
      "label": "Y1LA - CONSTRUCTION OF AIRPORT SERVICE ROADS",
      "value": "Y1LA",
      "type": "psc_code"
   },
   {
      "label": "Y1LB - CONSTRUCTION OF HIGHWAYS, ROADS, STREETS, BRIDGES, AND RAILWAYS",
      "value": "Y1LB",
      "type": "psc_code"
   },
   {
      "label": "Y1LC - CONSTRUCTION OF TUNNELS AND SUBSURFACE STRUCTURES",
      "value": "Y1LC",
      "type": "psc_code"
   },
   {
      "label": "Y1LZ - CONSTRUCTION OF PARKING FACILITIES",
      "value": "Y1LZ",
      "type": "psc_code"
   },
   {
      "label": "Y1MA - CONSTRUCTION OF EPG FACILITIES - COAL",
      "value": "Y1MA",
      "type": "psc_code"
   },
   {
      "label": "Y1MB - CONSTRUCTION OF EPG FACILITIES - GAS",
      "value": "Y1MB",
      "type": "psc_code"
   },
   {
      "label": "Y1MC - CONSTRUCTION OF EPG FACILITIES - GEOTHERMAL",
      "value": "Y1MC",
      "type": "psc_code"
   },
   {
      "label": "Y1MD - CONSTRUCTION OF EPG FACILITIES - HYDRO",
      "value": "Y1MD",
      "type": "psc_code"
   },
   {
      "label": "Y1ME - CONSTRUCTION OF EPG FACILITIES - NUCLEAR",
      "value": "Y1ME",
      "type": "psc_code"
   },
   {
      "label": "Y1MF - CONSTRUCTION OF EPG FACILITIES - PETROLEUM",
      "value": "Y1MF",
      "type": "psc_code"
   },
   {
      "label": "Y1MG - CONSTRUCTION OF EPG FACILITIES - SOLAR",
      "value": "Y1MG",
      "type": "psc_code"
   },
   {
      "label": "Y1MH - CONSTRUCTION OF EPG FACILITIES - WIND",
      "value": "Y1MH",
      "type": "psc_code"
   },
   {
      "label": "Y1MZ - CONSTRUCTION OF EPG FACILITIES - OTHER, INCLUDING TRANSMISSION",
      "value": "Y1MZ",
      "type": "psc_code"
   },
   {
      "label": "Y1NA - CONSTRUCTION OF FUEL SUPPLY FACILITIES",
      "value": "Y1NA",
      "type": "psc_code"
   },
   {
      "label": "Y1NB - CONSTRUCTION OF HEATING AND COOLING PLANTS",
      "value": "Y1NB",
      "type": "psc_code"
   },
   {
      "label": "Y1NC - CONSTRUCTION OF POLLUTION ABATEMENT AND CONTROL FACILITIES",
      "value": "Y1NC",
      "type": "psc_code"
   },
   {
      "label": "Y1ND - CONSTRUCTION OF SEWAGE AND WASTE FACILITIES",
      "value": "Y1ND",
      "type": "psc_code"
   },
   {
      "label": "Y1NE - CONSTRUCTION OF WATER SUPPLY FACILITIES",
      "value": "Y1NE",
      "type": "psc_code"
   },
   {
      "label": "Y1NZ - CONSTRUCTION OF OTHER UTILITIES",
      "value": "Y1NZ",
      "type": "psc_code"
   },
   {
      "label": "Y1PA - CONSTRUCTION OF RECREATION FACILITIES (NON-BUILDING)",
      "value": "Y1PA",
      "type": "psc_code"
   },
   {
      "label": "Y1PB - CONSTRUCTION OF EXHIBIT DESIGN (NON-BUILDING)",
      "value": "Y1PB",
      "type": "psc_code"
   },
   {
      "label": "Y1PC - CONSTRUCTION OF UNIMPROVED REAL PROPERTY (LAND)",
      "value": "Y1PC",
      "type": "psc_code"
   },
   {
      "label": "Y1PD - CONSTRUCTION OF WASTE TREATMENT AND STORAGE FACILITIES",
      "value": "Y1PD",
      "type": "psc_code"
   },
   {
      "label": "Y1PZ - CONSTRUCTION OF OTHER NON-BUILDING FACILITIES",
      "value": "Y1PZ",
      "type": "psc_code"
   },
   {
      "label": "Y1QA - CONSTRUCTION OF RESTORATION OF REAL PROPERTY (PUBLIC OR PRIVATE)",
      "value": "Y1QA",
      "type": "psc_code"
   },
   {
      "label": "Y2 - CONSTRUCT NONBUILDING FACILITIES",
      "value": "Y2",
      "type": "psc_code"
   },
   {
      "label": "Y211 - CONSTRUCTION OF DAMS",
      "value": "Y211",
      "type": "psc_code"
   },
   {
      "label": "Y212 - CONSTRUCTION OF CANALS",
      "value": "Y212",
      "type": "psc_code"
   },
   {
      "label": "Y213 - CONSTRUCT/MINE FIRE CONT",
      "value": "Y213",
      "type": "psc_code"
   },
   {
      "label": "Y214 - CONSTRUCT/MINE SUBSIDENCE CONT",
      "value": "Y214",
      "type": "psc_code"
   },
   {
      "label": "Y215 - CONSTRUCT/SURFACE MINE RECLAMATION",
      "value": "Y215",
      "type": "psc_code"
   },
   {
      "label": "Y216 - CONSTRUCTION OF DREDGING",
      "value": "Y216",
      "type": "psc_code"
   },
   {
      "label": "Y217 - Dredging, incl. Dustpan and Sea-Going Hoppers",
      "value": "Y217",
      "type": "psc_code"
   },
   {
      "label": "Y219 - CONSTRUCT/OTHER CONSERVATION",
      "value": "Y219",
      "type": "psc_code"
   },
   {
      "label": "Y221 - CONSTRUCT/AIRPORT SERVICE ROADS",
      "value": "Y221",
      "type": "psc_code"
   },
   {
      "label": "Y222 - CONSTRUCT/HIGHWAYS-RDS-STS-BRDGS-RA",
      "value": "Y222",
      "type": "psc_code"
   },
   {
      "label": "Y223 - CONSTRUCT/TUNNEL & SUBSURF STRUCT",
      "value": "Y223",
      "type": "psc_code"
   },
   {
      "label": "Y224 - CONSTRUCT/PARKING FACILITIES",
      "value": "Y224",
      "type": "psc_code"
   },
   {
      "label": "Y231 - CONSTRUCT/EPG - COAL",
      "value": "Y231",
      "type": "psc_code"
   },
   {
      "label": "Y232 - CONSTRUCT/EPG - GAS",
      "value": "Y232",
      "type": "psc_code"
   },
   {
      "label": "Y233 - CONSTRUCT/EPG - GEOTHERMAL",
      "value": "Y233",
      "type": "psc_code"
   },
   {
      "label": "Y234 - CONSTRUCT/EPG - HYDRO",
      "value": "Y234",
      "type": "psc_code"
   },
   {
      "label": "Y235 - CONSTRUCT/EPG - NUCLEAR",
      "value": "Y235",
      "type": "psc_code"
   },
   {
      "label": "Y236 - CONSTRUCT/EPG - PETROLEUM",
      "value": "Y236",
      "type": "psc_code"
   },
   {
      "label": "Y237 - CONSTRUCT/EPG - SOLAR",
      "value": "Y237",
      "type": "psc_code"
   },
   {
      "label": "Y239 - CONSTRUCT/EPG - OTHER INCL TRANSM",
      "value": "Y239",
      "type": "psc_code"
   },
   {
      "label": "Y241 - CONSTRUCT/FUEL SUPPLY",
      "value": "Y241",
      "type": "psc_code"
   },
   {
      "label": "Y242 - CONSTRUCT/HEATING & COOLING PLANTS",
      "value": "Y242",
      "type": "psc_code"
   },
   {
      "label": "Y243 - CONSTRUCT/POLLUTE ABATEMENT & CONTR",
      "value": "Y243",
      "type": "psc_code"
   },
   {
      "label": "Y244 - CONSTRUCT/SEWAGE & WASTE",
      "value": "Y244",
      "type": "psc_code"
   },
   {
      "label": "Y245 - CONSTRUCT/WATER SUPPLY",
      "value": "Y245",
      "type": "psc_code"
   },
   {
      "label": "Y249 - CONSTRUCTION OF OTHER UTILITIES",
      "value": "Y249",
      "type": "psc_code"
   },
   {
      "label": "Y291 - CONSTRUCT/REC NON-BLDG STRUCTS",
      "value": "Y291",
      "type": "psc_code"
   },
   {
      "label": "Y292 - CONSTRUCT/EXHIBIT (NON-BLDG)",
      "value": "Y292",
      "type": "psc_code"
   },
   {
      "label": "Y293 - CONSTRUCT/UNIMPROVED REAL PROP",
      "value": "Y293",
      "type": "psc_code"
   },
   {
      "label": "Y294 - CONSTRUCT/WASTE TRMT-STORE FAC",
      "value": "Y294",
      "type": "psc_code"
   },
   {
      "label": "Y299 - CONTRUCT/ALL OTHER NON-BLDG FACS",
      "value": "Y299",
      "type": "psc_code"
   },
   {
      "label": "Y3 - CONSTRUCT OF RESTORATION ACTIVITIES",
      "value": "Y3",
      "type": "psc_code"
   },
   {
      "label": "Y300 - CONSTRUCT/RESTORATION",
      "value": "Y300",
      "type": "psc_code"
   },
   {
      "label": "Z - MAINT, REPAIR, ALTER REAL PROPERTY",
      "value": "Z",
      "type": "psc_code"
   },
   {
      "label": "Z1 - MAINT, ALTER, REPAIR BUILDINGS",
      "value": "Z1",
      "type": "psc_code"
   },
   {
      "label": "Z111 - MAINT-REP-ALT/OFFICE BLDGS",
      "value": "Z111",
      "type": "psc_code"
   },
   {
      "label": "Z112 - MAINT-REP-ALT/CONF SPACE & FAC",
      "value": "Z112",
      "type": "psc_code"
   },
   {
      "label": "Z119 - MAINT-REP-ALT/OTHER ADMIN BLDGS",
      "value": "Z119",
      "type": "psc_code"
   },
   {
      "label": "Z121 - MAINT-REP-ALT/AIR TRAFFIC TOWERS",
      "value": "Z121",
      "type": "psc_code"
   },
   {
      "label": "Z122 - MAINT-REP-ALT/AIR TRAFFIC TNG FAC",
      "value": "Z122",
      "type": "psc_code"
   },
   {
      "label": "Z123 - MAINT-REP-ALT/RADAR & NAV FAC",
      "value": "Z123",
      "type": "psc_code"
   },
   {
      "label": "Z124 - MAINT-REP-ALT/AIRPORT RUNWAYS",
      "value": "Z124",
      "type": "psc_code"
   },
   {
      "label": "Z125 - MAINT-REP-ALT/AIRPORT TERMINALS",
      "value": "Z125",
      "type": "psc_code"
   },
   {
      "label": "Z126 - MAINT-REP-ALT/MISSILE SYSTEMS FAC",
      "value": "Z126",
      "type": "psc_code"
   },
   {
      "label": "Z127 - MAINT-REP-ALT/ELCT & COMM SYS FAC",
      "value": "Z127",
      "type": "psc_code"
   },
   {
      "label": "",
      "value": "Z128",
      "type": "psc_code"
   },
   {
      "label": "Z129 - MAINT-REP-ALT/OTH AIRFIELD STRUCT",
      "value": "Z129",
      "type": "psc_code"
   },
   {
      "label": "Z131 - MAINT-REP-ALT/SCHOOLS",
      "value": "Z131",
      "type": "psc_code"
   },
   {
      "label": "Z139 - MAINT-REP-ALT/OTHER EDUCATIONAL BLD",
      "value": "Z139",
      "type": "psc_code"
   },
   {
      "label": "Z141 - MAINT-REP-ALT/HOSPITALS & INFIRMARY",
      "value": "Z141",
      "type": "psc_code"
   },
   {
      "label": "Z142 - MAINT-REP-ALT/LABS & CLINICS",
      "value": "Z142",
      "type": "psc_code"
   },
   {
      "label": "Z149 - MAINT-REP-ALT/OTHER HOSPITAL BLDGS",
      "value": "Z149",
      "type": "psc_code"
   },
   {
      "label": "Z151 - MAINT-REP-ALT/AMMUNITION FACILITIES",
      "value": "Z151",
      "type": "psc_code"
   },
   {
      "label": "Z152 - MAINT-REP-ALT/MAINT BLDGS",
      "value": "Z152",
      "type": "psc_code"
   },
   {
      "label": "Z153 - MAINT-REP-ALT/PRODUCTION BLDGS",
      "value": "Z153",
      "type": "psc_code"
   },
   {
      "label": "Z154 - MAINT-REP-ALT/SHIP CONST-REPAIR FAC",
      "value": "Z154",
      "type": "psc_code"
   },
   {
      "label": "Z155 - MAINT-REP-ALT/TANK AUTOMOTIVE FAC",
      "value": "Z155",
      "type": "psc_code"
   },
   {
      "label": "Z159 - MAINT-REP-ALT/OTHER INDUSTRIAL BLDG",
      "value": "Z159",
      "type": "psc_code"
   },
   {
      "label": "Z161 - MAINT-REP-ALT/FAMILY HOUSING",
      "value": "Z161",
      "type": "psc_code"
   },
   {
      "label": "Z162 - MAINT-REP-ALT/RECREATIONAL BLDGS",
      "value": "Z162",
      "type": "psc_code"
   },
   {
      "label": "Z163 - MAINT-REP-ALT/TROOP HOUSING",
      "value": "Z163",
      "type": "psc_code"
   },
   {
      "label": "Z164 - MAINT-REP-ALT/DINING FACILITIES",
      "value": "Z164",
      "type": "psc_code"
   },
   {
      "label": "Z165 - MAINT-REP-ALT/RELIGIOUS FACILITIES",
      "value": "Z165",
      "type": "psc_code"
   },
   {
      "label": "Z166 - MAINT-REP-ALT/PENAL FACILITIES",
      "value": "Z166",
      "type": "psc_code"
   },
   {
      "label": "Z169 - MAINT-REP-ALT/OTHER RESIDENTIAL BLD",
      "value": "Z169",
      "type": "psc_code"
   },
   {
      "label": "Z171 - MAINT-REP-ALT/AMMO STORAGE BLDGS",
      "value": "Z171",
      "type": "psc_code"
   },
   {
      "label": "Z172 - MAINT-REP-ALT/FOOD STORAGE BLDGS",
      "value": "Z172",
      "type": "psc_code"
   },
   {
      "label": "Z173 - MAINT-REP-ALT/FUEL STORAGE BLDGS",
      "value": "Z173",
      "type": "psc_code"
   },
   {
      "label": "Z174 - MAINT-REP-ALT/OPEN STORAGE FAC",
      "value": "Z174",
      "type": "psc_code"
   },
   {
      "label": "Z179 - MAINT-REP-ALT/OTHER WAREHOUSE BLDGS",
      "value": "Z179",
      "type": "psc_code"
   },
   {
      "label": "Z181 - MAINT-REP-ALT/R&D GOCO FACILITIES",
      "value": "Z181",
      "type": "psc_code"
   },
   {
      "label": "Z182 - MAINT-REP-ALT/R&D GOGO FACILITIES",
      "value": "Z182",
      "type": "psc_code"
   },
   {
      "label": "Z183 - MAINT-REP-ALT/R&D GOCO ENVIR LABS",
      "value": "Z183",
      "type": "psc_code"
   },
   {
      "label": "Z184 - MAINT-REP-ALT/R&D GOGO ENVIR LABS",
      "value": "Z184",
      "type": "psc_code"
   },
   {
      "label": "Z191 - MAINT-REP-ALT/EXHIBITION BUILDINGS",
      "value": "Z191",
      "type": "psc_code"
   },
   {
      "label": "Z192 - MAINT-REP-ALT/TEST BUILDINGS",
      "value": "Z192",
      "type": "psc_code"
   },
   {
      "label": "Z199 - MAINT-REP-ALT/MISC BLDGS",
      "value": "Z199",
      "type": "psc_code"
   },
   {
      "label": "Z1AA - MAINTENANCE OF OFFICE BUILDINGS",
      "value": "Z1AA",
      "type": "psc_code"
   },
   {
      "label": "Z1AB - MAINTENANCE OF CONFERENCE SPACE AND FACILITIES",
      "value": "Z1AB",
      "type": "psc_code"
   },
   {
      "label": "Z1AZ - MAINTENANCE OF OTHER ADMINISTRATIVE FACILITIES AND SERVICE BUILDINGS",
      "value": "Z1AZ",
      "type": "psc_code"
   },
   {
      "label": "Z1BA - MAINTENANCE OF AIR TRAFFIC CONTROL TOWERS",
      "value": "Z1BA",
      "type": "psc_code"
   },
   {
      "label": "Z1BB - MAINTENANCE OF AIR TRAFFIC CONTROL TRAINING FACILITIES",
      "value": "Z1BB",
      "type": "psc_code"
   },
   {
      "label": "Z1BC - MAINTENANCE OF RADAR AND NAVIGATIONAL FACILITIES",
      "value": "Z1BC",
      "type": "psc_code"
   },
   {
      "label": "Z1BD - MAINTENANCE OF AIRPORT RUNWAYS AND TAXIWAYS",
      "value": "Z1BD",
      "type": "psc_code"
   },
   {
      "label": "Z1BE - MAINTENANCE OF AIRPORT TERMINALS",
      "value": "Z1BE",
      "type": "psc_code"
   },
   {
      "label": "Z1BF - MAINTENANCE OF MISSILE SYSTEM FACILITIES",
      "value": "Z1BF",
      "type": "psc_code"
   },
   {
      "label": "Z1BG - MAINTENANCE OF ELECTRONIC AND COMMUNICATIONS FACILITIES",
      "value": "Z1BG",
      "type": "psc_code"
   },
   {
      "label": "Z1BZ - MAINTENANCE OF OTHER AIRFIELD STRUCTURES",
      "value": "Z1BZ",
      "type": "psc_code"
   },
   {
      "label": "Z1CA - MAINTENANCE OF SCHOOLS",
      "value": "Z1CA",
      "type": "psc_code"
   },
   {
      "label": "Z1CZ - MAINTENANCE OF OTHER EDUCATIONAL BUILDINGS",
      "value": "Z1CZ",
      "type": "psc_code"
   },
   {
      "label": "Z1DA - MAINTENANCE OF HOSPITALS AND INFIRMARIES",
      "value": "Z1DA",
      "type": "psc_code"
   },
   {
      "label": "Z1DB - MAINTENANCE OF LABORATORIES AND CLINICS",
      "value": "Z1DB",
      "type": "psc_code"
   },
   {
      "label": "Z1DZ - MAINTENANCE OF OTHER HOSPITAL BUILDINGS",
      "value": "Z1DZ",
      "type": "psc_code"
   },
   {
      "label": "Z1EA - MAINTENANCE OF AMMUNITION FACILITIES",
      "value": "Z1EA",
      "type": "psc_code"
   },
   {
      "label": "Z1EB - MAINTENANCE OF MAINTENANCE BUILDINGS",
      "value": "Z1EB",
      "type": "psc_code"
   },
   {
      "label": "Z1EC - MAINTENANCE OF PRODUCTION BUILDINGS",
      "value": "Z1EC",
      "type": "psc_code"
   },
   {
      "label": "Z1ED - MAINTENANCE OF SHIP CONSTRUCTION AND REPAIR FACILITIES",
      "value": "Z1ED",
      "type": "psc_code"
   },
   {
      "label": "Z1EE - MAINTENANCE OF TANK AUTOMOTIVE FACILITIES",
      "value": "Z1EE",
      "type": "psc_code"
   },
   {
      "label": "Z1EZ - MAINTENANCE OF OTHER INDUSTRIAL BUILDINGS",
      "value": "Z1EZ",
      "type": "psc_code"
   },
   {
      "label": "Z1FA - MAINTENANCE OF FAMILY HOUSING FACILITIES",
      "value": "Z1FA",
      "type": "psc_code"
   },
   {
      "label": "Z1FB - MAINTENANCE OF RECREATIONAL BUILDINGS",
      "value": "Z1FB",
      "type": "psc_code"
   },
   {
      "label": "Z1FC - MAINTENANCE OF TROOP HOUSING FACILITIES",
      "value": "Z1FC",
      "type": "psc_code"
   },
   {
      "label": "Z1FD - MAINTENANCE OF DINING FACILITIES",
      "value": "Z1FD",
      "type": "psc_code"
   },
   {
      "label": "Z1FE - MAINTENANCE OF RELIGIOUS FACILITIES",
      "value": "Z1FE",
      "type": "psc_code"
   },
   {
      "label": "Z1FF - MAINTENANCE OF PENAL FACILITIES",
      "value": "Z1FF",
      "type": "psc_code"
   },
   {
      "label": "Z1FZ - MAINTENANCE OF OTHER RESIDENTIAL BUILDINGS",
      "value": "Z1FZ",
      "type": "psc_code"
   },
   {
      "label": "Z1GA - MAINTENANCE OF AMMUNITION STORAGE BUILDINGS",
      "value": "Z1GA",
      "type": "psc_code"
   },
   {
      "label": "Z1GB - MAINTENANCE OF FOOD OR GRAIN STORAGE BUILDINGS",
      "value": "Z1GB",
      "type": "psc_code"
   },
   {
      "label": "Z1GC - MAINTENANCE OF FUEL STORAGE BUILDINGS",
      "value": "Z1GC",
      "type": "psc_code"
   },
   {
      "label": "Z1GD - MAINTENANCE OF OPEN STORAGE FACILITIES",
      "value": "Z1GD",
      "type": "psc_code"
   },
   {
      "label": "Z1GZ - MAINTENANCE OF OTHER WAREHOUSE BUILDINGS",
      "value": "Z1GZ",
      "type": "psc_code"
   },
   {
      "label": "Z1HA - MAINTENANCE OF GOVERNMENT-OWNED CONTRACTOR-OPERATED (GOCO) R&D FACILITIES",
      "value": "Z1HA",
      "type": "psc_code"
   },
   {
      "label": "Z1HB - MAINTENANCE OF GOVERNMENT-OWNED GOVERNMENT-OPERATED (GOGO) R&D FACILITIES",
      "value": "Z1HB",
      "type": "psc_code"
   },
   {
      "label": "Z1HC - MAINTENANCE OF GOVERNMENT-OWNED CONTRACTOR-OPERATED (GOCO) ENVIRONMENTAL LABORATORIES",
      "value": "Z1HC",
      "type": "psc_code"
   },
   {
      "label": "Z1HZ - MAINTENANCE OF GOVERNMENT-OWNED GOVERNMENT-OPERATED (GOGO) ENVIRONMENTAL LABORATORIES",
      "value": "Z1HZ",
      "type": "psc_code"
   },
   {
      "label": "Z1JA - MAINTENANCE OF MUSEUMS AND EXHIBITION BUILDINGS",
      "value": "Z1JA",
      "type": "psc_code"
   },
   {
      "label": "Z1JB - MAINTENANCE OF TESTING AND MEASUREMENT BUILDINGS",
      "value": "Z1JB",
      "type": "psc_code"
   },
   {
      "label": "Z1JZ - MAINTENANCE OF MISCELLANEOUS BUILDINGS",
      "value": "Z1JZ",
      "type": "psc_code"
   },
   {
      "label": "Z1KA - MAINTENANCE OF DAMS",
      "value": "Z1KA",
      "type": "psc_code"
   },
   {
      "label": "Z1KB - MAINTENANCE OF CANALS",
      "value": "Z1KB",
      "type": "psc_code"
   },
   {
      "label": "Z1KC - MAINTENANCE OF MINE FIRE CONTROL FACILITIES",
      "value": "Z1KC",
      "type": "psc_code"
   },
   {
      "label": "Z1KD - MAINTENANCE OF MINE SUBSIDENCE CONTROL FACILITIES",
      "value": "Z1KD",
      "type": "psc_code"
   },
   {
      "label": "Z1KE - MAINTENANCE OF SURFACE MINE RECLAMATION FACILITIES",
      "value": "Z1KE",
      "type": "psc_code"
   },
   {
      "label": "Z1KF - MAINTENANCE OF DREDGING FACILITIES",
      "value": "Z1KF",
      "type": "psc_code"
   },
   {
      "label": "Z1KZ - MAINTENANCE OF OTHER CONSERVATION AND DEVELOPMENT FACILITIES",
      "value": "Z1KZ",
      "type": "psc_code"
   },
   {
      "label": "Z1LA - MAINTENANCE OF AIRPORT SERVICE ROADS",
      "value": "Z1LA",
      "type": "psc_code"
   },
   {
      "label": "Z1LB - MAINTENANCE OF HIGHWAYS/ROADS/STREETS/BRIDGES/RAILWAYS",
      "value": "Z1LB",
      "type": "psc_code"
   },
   {
      "label": "Z1LC - MAINTENANCE OF TUNNELS AND SUBSURFACE STRUCTURES",
      "value": "Z1LC",
      "type": "psc_code"
   },
   {
      "label": "Z1LZ - MAINTENANCE OF PARKING FACILITIES",
      "value": "Z1LZ",
      "type": "psc_code"
   },
   {
      "label": "Z1MA - MAINTENANCE OF EPG FACILITIES - COAL",
      "value": "Z1MA",
      "type": "psc_code"
   },
   {
      "label": "Z1MB - MAINTENANCE OF EPG FACILITIES - GAS",
      "value": "Z1MB",
      "type": "psc_code"
   },
   {
      "label": "Z1MC - MAINTENANCE OF EPG FACILITIES - GEOTHERMAL",
      "value": "Z1MC",
      "type": "psc_code"
   },
   {
      "label": "Z1MD - MAINTENANCE OF EPG FACILITIES - HYDRO",
      "value": "Z1MD",
      "type": "psc_code"
   },
   {
      "label": "Z1ME - MAINTENANCE OF EPG FACILITIES - NUCLEAR",
      "value": "Z1ME",
      "type": "psc_code"
   },
   {
      "label": "Z1MF - MAINTENANCE OF EPG FACILITIES - PETROLEUM",
      "value": "Z1MF",
      "type": "psc_code"
   },
   {
      "label": "Z1MG - MAINTENANCE OF EPG FACILITIES - SOLAR",
      "value": "Z1MG",
      "type": "psc_code"
   },
   {
      "label": "Z1MH - MAINTENANCE OF EPG FACILITIES - WIND",
      "value": "Z1MH",
      "type": "psc_code"
   },
   {
      "label": "Z1MZ - MAINTENANCE OF EPG FACILITIES - OTHER, INCLUDING TRANSMISSION",
      "value": "Z1MZ",
      "type": "psc_code"
   },
   {
      "label": "Z1NA - MAINTENANCE OF FUEL SUPPLY FACILITIES",
      "value": "Z1NA",
      "type": "psc_code"
   },
   {
      "label": "Z1NB - MAINTENANCE OF HEATING AND COOLING PLANTS",
      "value": "Z1NB",
      "type": "psc_code"
   },
   {
      "label": "Z1NC - MAINTENANCE OF POLLUTION ABATEMENT AND CONTROL FACILITIES",
      "value": "Z1NC",
      "type": "psc_code"
   },
   {
      "label": "Z1ND - MAINTENANCE OF SEWAGE AND WASTE FACILITIES",
      "value": "Z1ND",
      "type": "psc_code"
   },
   {
      "label": "Z1NE - MAINTENANCE OF WATER SUPPLY FACILITIES",
      "value": "Z1NE",
      "type": "psc_code"
   },
   {
      "label": "Z1NZ - MAINTENANCE OF OTHER UTILITIES",
      "value": "Z1NZ",
      "type": "psc_code"
   },
   {
      "label": "Z1PA - MAINTENANCE OF RECREATION FACILITIES (NON-BUILDING)",
      "value": "Z1PA",
      "type": "psc_code"
   },
   {
      "label": "Z1PB - MAINTENANCE OF EXHIBIT DESIGN (NON-BUILDING)",
      "value": "Z1PB",
      "type": "psc_code"
   },
   {
      "label": "Z1PC - MAINTENANCE OF UNIMPROVED REAL PROPERTY (LAND)",
      "value": "Z1PC",
      "type": "psc_code"
   },
   {
      "label": "Z1PD - MAINTENANCE OF WASTE TREATMENT AND STORAGE FACILITIES",
      "value": "Z1PD",
      "type": "psc_code"
   },
   {
      "label": "Z1PZ - MAINTENANCE OF OTHER NON-BUILDING FACILITIES",
      "value": "Z1PZ",
      "type": "psc_code"
   },
   {
      "label": "Z1QA - MAINTENANCE OF RESTORATION OF REAL PROPERTY (PUBLIC OR PRIVATE)",
      "value": "Z1QA",
      "type": "psc_code"
   },
   {
      "label": "Z2 - MAINT, ALTER, REPAIR NONBUILDINGS",
      "value": "Z2",
      "type": "psc_code"
   },
   {
      "label": "Z211 - MAINT-REP-ALT/DAMS",
      "value": "Z211",
      "type": "psc_code"
   },
   {
      "label": "Z212 - MAINT-REP-ALT/CANALS",
      "value": "Z212",
      "type": "psc_code"
   },
   {
      "label": "Z213 - MAINT-REP-ALT/MINE FIRE CONT",
      "value": "Z213",
      "type": "psc_code"
   },
   {
      "label": "Z214 - MAINT-REP-ALT/MINE SUBSIDENCE CONT",
      "value": "Z214",
      "type": "psc_code"
   },
   {
      "label": "Z215 - MAINT-REP-ALT/SURFACE MINE RECLAM",
      "value": "Z215",
      "type": "psc_code"
   },
   {
      "label": "Z216 - MAINT-REP-ALT/DREDGING",
      "value": "Z216",
      "type": "psc_code"
   },
   {
      "label": "Z217 - Dredging, incl. Dustpan and Sea-Going Hoppers",
      "value": "Z217",
      "type": "psc_code"
   },
   {
      "label": "Z219 - MAINT-REP-ALT/OTHER CONSV STRUCTURE",
      "value": "Z219",
      "type": "psc_code"
   },
   {
      "label": "Z221 - MAINT-REP-ALT/AIRPORT SERVICE ROADS",
      "value": "Z221",
      "type": "psc_code"
   },
   {
      "label": "Z222 - MAINT-REP-ALT/HWYS-RDS-STS-BRDGS-RA",
      "value": "Z222",
      "type": "psc_code"
   },
   {
      "label": "Z223 - MAINT-REP-ALT/TUNNELS-SUBSURF STRUC",
      "value": "Z223",
      "type": "psc_code"
   },
   {
      "label": "Z224 - MAINT-REP-ALT/PARKING FACILITIES",
      "value": "Z224",
      "type": "psc_code"
   },
   {
      "label": "Z231 - MAINT-REP-ALT/EPG - COAL",
      "value": "Z231",
      "type": "psc_code"
   },
   {
      "label": "Z232 - MAINT-REP-ALT/EPG - GAS",
      "value": "Z232",
      "type": "psc_code"
   },
   {
      "label": "Z233 - MAINT-REP-ALT/EPG - GEOTHERMAL",
      "value": "Z233",
      "type": "psc_code"
   },
   {
      "label": "Z234 - MAINT-REP-ALT/EPG - HYDRO",
      "value": "Z234",
      "type": "psc_code"
   },
   {
      "label": "Z235 - MAINT-REP-ALT/EPG - NUCLEAR",
      "value": "Z235",
      "type": "psc_code"
   },
   {
      "label": "Z236 - MAINT-REP-ALT/EPG - PETROLEUM",
      "value": "Z236",
      "type": "psc_code"
   },
   {
      "label": "Z237 - MAINT-REP-ALT/EPG - SOLAR",
      "value": "Z237",
      "type": "psc_code"
   },
   {
      "label": "Z239 - MAINT-REP-ALT/EPG - OTHER",
      "value": "Z239",
      "type": "psc_code"
   },
   {
      "label": "Z241 - MAINT-REP-ALT/FUEL SUPPLY",
      "value": "Z241",
      "type": "psc_code"
   },
   {
      "label": "Z242 - MAINT-REP-ALT/HEATING & COOL PLANTS",
      "value": "Z242",
      "type": "psc_code"
   },
   {
      "label": "Z243 - MAINT-REP-ALT/POLLUTION ABATEMENT",
      "value": "Z243",
      "type": "psc_code"
   },
   {
      "label": "Z244 - MAINT-REP-ALT/SEWAGE & WASTE",
      "value": "Z244",
      "type": "psc_code"
   },
   {
      "label": "Z245 - MAINT-REP-ALT/WATER SUPPLY",
      "value": "Z245",
      "type": "psc_code"
   },
   {
      "label": "Z249 - MAINT-REP-ALT/OTHER UTILITIES",
      "value": "Z249",
      "type": "psc_code"
   },
   {
      "label": "Z291 - MAINT-REP-ALT/RECREA NON-BLDG STRUC",
      "value": "Z291",
      "type": "psc_code"
   },
   {
      "label": "Z292 - MAINT-REP-ALT/EXHIBIT (NON-BLDG)",
      "value": "Z292",
      "type": "psc_code"
   },
   {
      "label": "Z293 - MAINT-REP-ALT/UNIMPROVED REAL PROP",
      "value": "Z293",
      "type": "psc_code"
   },
   {
      "label": "Z294 - MAINT-REP-ALT/WASTE TRMT-STORE FAC",
      "value": "Z294",
      "type": "psc_code"
   },
   {
      "label": "Z299 - MAINT, REP/ALTER/ALL OTHER",
      "value": "Z299",
      "type": "psc_code"
   },
   {
      "label": "Z2AA - REPAIR OR ALTERATION OF OFFICE BUILDINGS",
      "value": "Z2AA",
      "type": "psc_code"
   },
   {
      "label": "Z2AB - REPAIR OR ALTERATION OF CONFERENCE SPACE AND FACILITIES",
      "value": "Z2AB",
      "type": "psc_code"
   },
   {
      "label": "Z2AZ - REPAIR OR ALTERATION OF OTHER ADMINISTRATIVE FACILITIES AND SERVICE BUILDINGS",
      "value": "Z2AZ",
      "type": "psc_code"
   },
   {
      "label": "Z2BA - REPAIR OR ALTERATION OF AIR TRAFFIC CONTROL TOWERS",
      "value": "Z2BA",
      "type": "psc_code"
   },
   {
      "label": "Z2BB - REPAIR OR ALTERATION OF AIR TRAFFIC CONTROL TRAINING FACILITIES",
      "value": "Z2BB",
      "type": "psc_code"
   },
   {
      "label": "Z2BC - REPAIR OR ALTERATION OF RADAR AND NAVIGATIONAL FACILITIES",
      "value": "Z2BC",
      "type": "psc_code"
   },
   {
      "label": "Z2BD - REPAIR OR ALTERATION OF AIRPORT RUNWAYS AND TAXIWAYS",
      "value": "Z2BD",
      "type": "psc_code"
   },
   {
      "label": "Z2BE - REPAIR OR ALTERATION OF AIRPORT TERMINALS",
      "value": "Z2BE",
      "type": "psc_code"
   },
   {
      "label": "Z2BF - REPAIR OR ALTERATION OF MISSILE SYSTEM FACILITIES",
      "value": "Z2BF",
      "type": "psc_code"
   },
   {
      "label": "Z2BG - REPAIR OR ALTERATION OF ELECTRONIC AND COMMUNICATIONS FACILITIES",
      "value": "Z2BG",
      "type": "psc_code"
   },
   {
      "label": "Z2BZ - REPAIR OR ALTERATION OF OTHER AIRFIELD STRUCTURES",
      "value": "Z2BZ",
      "type": "psc_code"
   },
   {
      "label": "Z2CA - REPAIR OR ALTERATION OF SCHOOLS",
      "value": "Z2CA",
      "type": "psc_code"
   },
   {
      "label": "Z2CZ - REPAIR OR ALTERATION OF OTHER EDUCATIONAL BUILDINGS",
      "value": "Z2CZ",
      "type": "psc_code"
   },
   {
      "label": "Z2DA - REPAIR OR ALTERATION OF HOSPITALS AND INFIRMARIES",
      "value": "Z2DA",
      "type": "psc_code"
   },
   {
      "label": "Z2DB - REPAIR OR ALTERATION OF LABORATORIES AND CLINICS",
      "value": "Z2DB",
      "type": "psc_code"
   },
   {
      "label": "Z2DZ - REPAIR OR ALTERATION OF OTHER HOSPITAL BUILDINGS",
      "value": "Z2DZ",
      "type": "psc_code"
   },
   {
      "label": "Z2EA - REPAIR OR ALTERATION OF AMMUNITION FACILITIES",
      "value": "Z2EA",
      "type": "psc_code"
   },
   {
      "label": "Z2EB - REPAIR OR ALTERATION OF MAINTENANCE BUILDINGS",
      "value": "Z2EB",
      "type": "psc_code"
   },
   {
      "label": "Z2EC - REPAIR OR ALTERATION OF PRODUCTION BUILDINGS",
      "value": "Z2EC",
      "type": "psc_code"
   },
   {
      "label": "Z2ED - REPAIR OR ALTERATION OF SHIP CONSTRUCTION AND REPAIR FACILITIES",
      "value": "Z2ED",
      "type": "psc_code"
   },
   {
      "label": "Z2EE - REPAIR OR ALTERATION OF TANK AUTOMOTIVE FACILITIES",
      "value": "Z2EE",
      "type": "psc_code"
   },
   {
      "label": "Z2EZ - REPAIR OR ALTERATION OF OTHER INDUSTRIAL BUILDINGS",
      "value": "Z2EZ",
      "type": "psc_code"
   },
   {
      "label": "Z2FA - REPAIR OR ALTERATION OF FAMILY HOUSING FACILITIES",
      "value": "Z2FA",
      "type": "psc_code"
   },
   {
      "label": "Z2FB - REPAIR OR ALTERATION OF RECREATIONAL BUILDINGS",
      "value": "Z2FB",
      "type": "psc_code"
   },
   {
      "label": "Z2FC - REPAIR OR ALTERATION OF TROOP HOUSING FACILITIES",
      "value": "Z2FC",
      "type": "psc_code"
   },
   {
      "label": "Z2FD - REPAIR OR ALTERATION OF DINING FACILITIES",
      "value": "Z2FD",
      "type": "psc_code"
   },
   {
      "label": "Z2FE - REPAIR OR ALTERATION OF RELIGIOUS FACILITIES",
      "value": "Z2FE",
      "type": "psc_code"
   },
   {
      "label": "Z2FF - REPAIR OR ALTERATION OF PENAL FACILITIES",
      "value": "Z2FF",
      "type": "psc_code"
   },
   {
      "label": "Z2FZ - REPAIR OR ALTERATION OF OTHER RESIDENTIAL BUILDINGS",
      "value": "Z2FZ",
      "type": "psc_code"
   },
   {
      "label": "Z2GA - REPAIR OR ALTERATION OF AMMUNITION STORAGE BUILDINGS",
      "value": "Z2GA",
      "type": "psc_code"
   },
   {
      "label": "Z2GB - REPAIR OR ALTERATION OF FOOD OR GRAIN STORAGE BUILDINGS",
      "value": "Z2GB",
      "type": "psc_code"
   },
   {
      "label": "Z2GC - REPAIR OR ALTERATION OF FUEL STORAGE BUILDINGS",
      "value": "Z2GC",
      "type": "psc_code"
   },
   {
      "label": "Z2GD - REPAIR OR ALTERATION OF OPEN STORAGE FACILITIES",
      "value": "Z2GD",
      "type": "psc_code"
   },
   {
      "label": "Z2GZ - REPAIR OR ALTERATION OF OTHER WAREHOUSE BUILDINGS",
      "value": "Z2GZ",
      "type": "psc_code"
   },
   {
      "label": "Z2HA - REPAIR OR ALTERATION OF GOVERNMENT-OWNED CONTRACTOR-OPERATED (GOCO) R&D FACILITIES",
      "value": "Z2HA",
      "type": "psc_code"
   },
   {
      "label": "Z2HB - REPAIR OR ALTERATION OF GOVERNMENT-OWNED GOVERNMENT-OPERATED (GOGO) R&D FACILITIES",
      "value": "Z2HB",
      "type": "psc_code"
   },
   {
      "label": "Z2HC - REPAIR OR ALTERATION OF GOVERNMENT-OWNED CONTRACTOR-OPERATED (GOCO) ENVIRONMENTAL LABORATORIES",
      "value": "Z2HC",
      "type": "psc_code"
   },
   {
      "label": "Z2HZ - REPAIR OR ALTERATION OF GOVERNMENT-OWNED GOVERNMENT-OPERATED (GOGO) ENVIRONMENTAL LABORATORIES",
      "value": "Z2HZ",
      "type": "psc_code"
   },
   {
      "label": "Z2JA - REPAIR OR ALTERATION OF MUSEUMS AND EXHIBITION BUILDINGS",
      "value": "Z2JA",
      "type": "psc_code"
   },
   {
      "label": "Z2JB - REPAIR OR ALTERATION OF TESTING AND MEASUREMENT BUILDINGS",
      "value": "Z2JB",
      "type": "psc_code"
   },
   {
      "label": "Z2JZ - REPAIR OR ALTERATION OF MISCELLANEOUS BUILDINGS",
      "value": "Z2JZ",
      "type": "psc_code"
   },
   {
      "label": "Z2KA - REPAIR OR ALTERATION OF DAMS",
      "value": "Z2KA",
      "type": "psc_code"
   },
   {
      "label": "Z2KB - REPAIR OR ALTERATION OF CANALS",
      "value": "Z2KB",
      "type": "psc_code"
   },
   {
      "label": "Z2KC - REPAIR OR ALTERATION OF MINE FIRE CONTROL FACILITIES",
      "value": "Z2KC",
      "type": "psc_code"
   },
   {
      "label": "Z2KD - REPAIR OR ALTERATION OF MINE SUBSIDENCE CONTROL FACILITIES",
      "value": "Z2KD",
      "type": "psc_code"
   },
   {
      "label": "Z2KE - REPAIR OR ALTERATION OF SURFACE MINE RECLAMATION FACILITIES",
      "value": "Z2KE",
      "type": "psc_code"
   },
   {
      "label": "Z2KF - REPAIR OR ALTERATION OF DREDGING FACILITIES",
      "value": "Z2KF",
      "type": "psc_code"
   },
   {
      "label": "Z2KZ - REPAIR OR ALTERATION OF OTHER CONSERVATION AND DEVELOPMENT FACILITIES",
      "value": "Z2KZ",
      "type": "psc_code"
   },
   {
      "label": "Z2LA - REPAIR OR ALTERATION OF AIRPORT SERVICE ROADS",
      "value": "Z2LA",
      "type": "psc_code"
   },
   {
      "label": "Z2LB - REPAIR OR ALTERATION OF HIGHWAYS/ROADS/STREETS/BRIDGES/RAILWAYS",
      "value": "Z2LB",
      "type": "psc_code"
   },
   {
      "label": "Z2LC - REPAIR OR ALTERATION OF TUNNELS AND SUBSURFACE STRUCTURES",
      "value": "Z2LC",
      "type": "psc_code"
   },
   {
      "label": "Z2LZ - REPAIR OR ALTERATION OF PARKING FACILITIES",
      "value": "Z2LZ",
      "type": "psc_code"
   },
   {
      "label": "Z2MA - REPAIR OR ALTERATION OF EPG FACILITIES - COAL",
      "value": "Z2MA",
      "type": "psc_code"
   },
   {
      "label": "Z2MB - REPAIR OR ALTERATION OF EPG FACILITIES - GAS",
      "value": "Z2MB",
      "type": "psc_code"
   },
   {
      "label": "Z2MC - REPAIR OR ALTERATION OF EPG FACILITIES - GEOTHERMAL",
      "value": "Z2MC",
      "type": "psc_code"
   },
   {
      "label": "Z2MD - REPAIR OR ALTERATION OF EPG FACILITIES - HYDRO",
      "value": "Z2MD",
      "type": "psc_code"
   },
   {
      "label": "Z2ME - REPAIR OR ALTERATION OF EPG FACILITIES - NUCLEAR",
      "value": "Z2ME",
      "type": "psc_code"
   },
   {
      "label": "Z2MF - REPAIR OR ALTERATION OF EPG FACILITIES - PETROLEUM",
      "value": "Z2MF",
      "type": "psc_code"
   },
   {
      "label": "Z2MG - REPAIR OR ALTERATION OF EPG FACILITIES - SOLAR",
      "value": "Z2MG",
      "type": "psc_code"
   },
   {
      "label": "Z2MH - REPAIR OR ALTERATION OF EPG FACILITIES - WIND",
      "value": "Z2MH",
      "type": "psc_code"
   },
   {
      "label": "Z2MZ - REPAIR OR ALTERATION OF EPG FACILITIES - OTHER, INCLUDING TRANSMISSION",
      "value": "Z2MZ",
      "type": "psc_code"
   },
   {
      "label": "Z2NA - REPAIR OR ALTERATION OF FUEL SUPPLY FACILITIES",
      "value": "Z2NA",
      "type": "psc_code"
   },
   {
      "label": "Z2NB - REPAIR OR ALTERATION OF HEATING AND COOLING PLANTS",
      "value": "Z2NB",
      "type": "psc_code"
   },
   {
      "label": "Z2NC - REPAIR OR ALTERATION OF POLLUTION ABATEMENT AND CONTROL FACILITIES",
      "value": "Z2NC",
      "type": "psc_code"
   },
   {
      "label": "Z2ND - REPAIR OR ALTERATION OF SEWAGE AND WASTE FACILITIES",
      "value": "Z2ND",
      "type": "psc_code"
   },
   {
      "label": "Z2NE - REPAIR OR ALTERATION OF WATER SUPPLY FACILITIES",
      "value": "Z2NE",
      "type": "psc_code"
   },
   {
      "label": "Z2NZ - REPAIR OR ALTERATION OF OTHER UTILITIES",
      "value": "Z2NZ",
      "type": "psc_code"
   },
   {
      "label": "Z2PA - REPAIR OR ALTERATION OF RECREATION FACILITIES (NON-BUILDING)",
      "value": "Z2PA",
      "type": "psc_code"
   },
   {
      "label": "Z2PB - REPAIR OR ALTERATION OF EXHIBIT DESIGN (NON-BUILDING)",
      "value": "Z2PB",
      "type": "psc_code"
   },
   {
      "label": "Z2PC - REPAIR OR ALTERATION OF UNIMPROVED REAL PROPERTY (LAND)",
      "value": "Z2PC",
      "type": "psc_code"
   },
   {
      "label": "Z2PD - REPAIR OR ALTERATION OF WASTE TREATMENT AND STORAGE FACILITIES",
      "value": "Z2PD",
      "type": "psc_code"
   },
   {
      "label": "Z2PZ - REPAIR OR ALTERATION OF OTHER NON-BUILDING FACILITIES",
      "value": "Z2PZ",
      "type": "psc_code"
   },
   {
      "label": "Z2QA - REPAIR OR ALTERATION OF RESTORATION OF REAL PROPERTY (PUBLIC OR PRIVATE)",
      "value": "Z2QA",
      "type": "psc_code"
   },
   {
      "label": "Z3 - MAINT, ALTER, REPAIR RESTOR ACVIVS",
      "value": "Z3",
      "type": "psc_code"
   },
   {
      "label": "Z300 - MAINT, REP-ALT/RESTORATION",
      "value": "Z300",
      "type": "psc_code"
   }
]
