import React from 'react';
import classes from '../Dashboard/index.module.scss'

const TableHeader = ({ selectedColums }) => {
  return (
    <tr>
      {selectedColums.map((item) =>
        item.status ? (
          <th 
            className={`${classes.No_Wrap} text-center`}
            key={item.name}
          >
            {item.name}
          </th>
        ) : null
      )}
    </tr>
  )
}

export default TableHeader;