import React, { useState, useContext, useEffect } from "react";
import { adminGlobalSettingsPost, adminDashboardGet } from '../../../api';
import Loading from '../../Loading';
import { UserContext } from "../../../context/User";
import { useHistory } from 'react-router';
import { somethingWentWrongNotice } from '../../UI/Notifications';
import { Container, Row, Col } from "react-bootstrap";
import QuickStats from "./QuickStats";
import Support from './Support';
import RecentRegistrations from './RecentRegistrations';
import DashboardBanner from "components/UpdateDashboardMessage/DashboardBanner";
import SampleAdminDashboard from './SampleAdminDashboard'
import { NAICS_LIST, PSC_LIST } from 'constans'
import Modal from '../../UI/Modal';
import DeleteEntityModalContent from '../../ModalContents/DeleteEntityModalContent';
import AddEntityModalContent from '../../ModalContents/AddEntityModalContent'
import { useForm } from 'react-hook-form';
import ChangeBudgetLimitContent from '../../ModalContents/ChangeBudgetLimitContent'
import ExludedEntity from "./ExcludedEntity";
import EntityBox from './EntityBox'
import ExludeEntityModalContent from "components/ModalContents/ExludeEntityModalContent";
import SetAsideFilter from "./SetAsideFilter";
import { Link } from 'react-router-dom';

const DEFAULT_INPUT_VALUES = {
  naics: "",
  psc_code: "",
  zip: ""
}

const AdminDashboard = () => {
  const { setUser, setIsLoggedIn } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [entityList, setEntityList] = useState([]);
  const { handleSubmit, register } = useForm();
  const [entity, setEntity] = useState('');
  const [action, setAction] = useState('');
  const [inputValue, setInputValue] = useState(DEFAULT_INPUT_VALUES);
  const [selectValue, setSelectValue] = useState([]);
  const [inputName, setInputName] = useState('');
  const [requestTrigger, setRequestTrigger] = useState(false);
  const [value, setValue] = useState(null);
  const [exludedSelectValue, setExludedSelectValue] = useState([]);
  const [setasideFilterSelectValue, setSetasideFilteExludedSelectValue] = useState([]);
  const [filterSetasideData, setFilterSetasideData] = useState()

  const handleClose = () => {
    setSelectValue([]);
    setInputName('');
    setAction('')
    setEntity('')
    setShow(false);
    setValue(data.matches_limit)
  }
  const handleShow = () => {
    setShow(true);
  }  

  const logout = () => {
    setIsLoggedIn(false);
    setUser({});
    history.push('/');
  }

  const handleDefaultStatusCodes = (status_code) => {
    if (status_code === 440 || status_code === 401) {
      logout()
    } else {
      somethingWentWrongNotice("Dashboard");
    }
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    adminDashboardGet(filterSetasideData && filterSetasideData)
      .then(response => {
        setData(response.data);
        setValue(response.data.matches_limit)
        setLoading(false);
      })
      .catch(error => {
        handleDefaultStatusCodes(error.response.status);
      })
  }, [requestTrigger])

  const transformData = () => {
    if (entity === "NAICS") return 'selected_naics'
    if (entity === "PSCs") return 'selected_pscs'
    if (entity === "ZIP") return 'selected_zips'
  }

  const onSubmit = () => {
    const entityName = transformData()
    const transformedData = selectValue.map(item => item.value)
    const addData = [...new Set([...data[entityName], ...transformedData])];
    const deleteData = entityList
  
    const transformedParams = {
      [entityName]: action === 'add' ? addData : deleteData
    }

    adminGlobalSettingsPost(transformedParams)
      .then(response => {
        setLoading(false);
        setRequestTrigger(!requestTrigger)
        handleClose()
      })
      .catch(error => {
        handleDefaultStatusCodes(error.response.status);
        handleClose()
        setLoading(false);
      })
  }

  const changeBudget = () => {
    setAction('change')
    handleShow();
  }

  const setModalButtonLabel = action => {
    if (action === "add") return 'Add'
    if (action === "delete") return 'Delete'
    if (action === "change") return 'Change'
  }

  const onChangeSingleValue = (event, name) => {
    setValue(event.target.value)
  }

  const adminGlobalSettingsPostHandler = (obj) => {
    adminGlobalSettingsPost(obj)
    .then(response => {
      setLoading(false);
      setRequestTrigger(!requestTrigger)
      handleClose()
    })
    .catch(error => {
      handleDefaultStatusCodes(error.response.status);
      handleClose()
      setLoading(false);
    })
  }

  const onBudgetSubmit = () => {
    const obj = {
      matches_limit: +value,
    }

    adminGlobalSettingsPostHandler(obj)
  }

  const onSetasideFilterSubmit = () => {
    const arr = setasideFilterSelectValue.map(item => item.value);
    const params = {
      filter_set_aside: arr
    }
    setFilterSetasideData(params)
    setRequestTrigger(!requestTrigger)
  }

  const onExludeEntitySubmit = () => {
    const arr = exludedSelectValue.map(item => item.value);
    const obj = {
      excluded_matching_sources: arr
    }
    adminGlobalSettingsPostHandler(obj)
  }

  const excludeEntityHandler = () => {
    setAction('exluded')
    handleShow();
  }

  const setActionType = () => {
    switch(action) {
      case 'change':
        return onBudgetSubmit
      case 'exluded':
        return onExludeEntitySubmit
      case 'add':
      case 'delete':
        return onSubmit
    }
  }

  const numberWithCommas = (value = "") => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } 

  return (
    <section className="section-spaces">
      <Container>
        <Row>
          <Col>
            <div className="mb-5 admin-dashboard-heading">
              <div>
                <h3 className="mb-0">
                  Admin Dashboard
                </h3>
                <span className="admin-dashboard-subtitle">(Build & Manage Your Economic Ecosystem)</span>
              </div>
              <button
                className="btnSizes filledBtn link"
                onClick={ () => changeBudget() }
              >
                Change Matching Budget Limit
              </button>
            </div>
            <DashboardBanner />  
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="Admin_Dashboard_Entity_Wrapper">
              <h2 className="Admin_Dashboard_Entity_Title">My Set Up</h2>
              <Row>
                <Col lg={6}>
                  <div className="Admin_Dashboard_Entity_Box">
                    <ExludedEntity 
                      title="NAICS"
                      setAction={ setAction }
                      setEntity={ setEntity }
                      setInputName={ setInputName }
                      handleShow={ handleShow }
                      tooltip="North American Industry Classification System codes you want to exclude."
                      data={ data }
                      entityData={ data.selected_naics }
                      list={ NAICS_LIST }
                      setEntityList={ setEntityList }
                      limit={ Boolean(data.selected_naics && data.selected_naics.length) ? 2 : 0 }
                      type="default"
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="Admin_Dashboard_Entity_Box">
                    <ExludedEntity 
                      title="PSCs"
                      setAction={ setAction }
                      setEntity={ setEntity }
                      setInputName={ setInputName }
                      handleShow={ handleShow }
                      tooltip="Product Service Codes you want to exclude."
                      data={ data }
                      entityData={ data.selected_pscs }
                      list={ PSC_LIST }
                      setEntityList={ setEntityList }
                      limit={ Boolean(data.selected_pscs && data.selected_pscs.length) ? 2 : 0 }
                      type="default"
                    />
                  </div>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col lg={6}>
                  <div className="text-center">
                    <button className="btnSizes filledBtn link" onClick={ () => excludeEntityHandler() }>Excluded Matching Sources</button>
                  </div>
                </Col>
                <Col lg={6}>
                  { Object.keys(data).length && (
                    <div className="Admin_Dashboard_Entity_Box">
                      <div className="Single_Dashboard_Entity_Box"> 
                        <h3 className="Value">{`$${numberWithCommas(data.matches_limit)} / Mo`}</h3>
                        <h2 className="Title">Monthly Budget</h2>
                        <span className="Tooltip">
                          The monthly budget spend limit for G2B contract matches set by the Administrator.
                        </span>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <SetAsideFilter 
              setasideFilterSelectValue={ setasideFilterSelectValue }
              setSetasideFilteExludedSelectValue={ setSetasideFilteExludedSelectValue }
              data={ data.excluded_matching_sources } 
              handleSubmit={ handleSubmit }
              onSetasideFilterSubmit={ onSetasideFilterSubmit }  
            />
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <div className="Admin_Dashboard_Entity_Wrapper">
              <h2 className="Admin_Dashboard_Entity_Title">My Instance</h2>
              { Object.keys(data).length && (
                <>
                  <EntityBox 
                    value={ data.users_registered }
                    title="Registered Users"
                    tooltip="Total number of Registered Users including businesses, governments, investors, media and employment seekers."
                  />
                  <EntityBox 
                    value={ data.sam_opps }
                    title="Total Contract & Grant Opportunities"
                    tooltip="Total number of G2B contracting & grant opportunities."
                  />
                  <EntityBox 
                    value={ data.contract_matches }
                    title="Matches"
                    tooltip="Total number of contract & grant possible matched opportunties to registered users for the current billing period."
                  />
                  <EntityBox 
                    value={ data.budgeted_matches }
                    title="Budgeted Matches"
                    tooltip="Total number of available matches based on your current budget."
                  />
                  <EntityBox 
                    value={`$${data.spend}`}
                    title="Spend"
                    tooltip="Total amount spent for this billing period."
                  />
                  <EntityBox 
                    value={ data.missed_opps }
                    title="Missed Opportunities"
                    tooltip="Missed opportunties based on budget limit."
                  />
                </>
              )}
            </div>
          </Col>
          <Col lg={6}>
            <div className="Admin_Dashboard_Entity_Wrapper">
              <h2 className="Admin_Dashboard_Entity_Title">My Performance</h2>
              <SampleAdminDashboard data={data} numberWithCommas={ numberWithCommas } />
              <div className="text-center mb-3">
                <Link to="/awards-details" className="btnSizes filledBtn link awardBtn">View Award Data</Link>
              </div>
            </div>
          </Col>
          <Col lg={3}>
            <div className="Admin_Dashboard_Entity_Wrapper">
              <h2 className="Admin_Dashboard_Entity_Title">Coming Soon</h2>
              { Object.keys(data).length && (
                <>
                  <div className="Admin_Dashboard_Entity_Box">
                    <div className="Single_Dashboard_Entity_Box">
                      <h2 className="Title">Ecommerce Module</h2>
                      <span className="Tooltip">
                        Enable transactions, invoices, PO's, digital contracts & payment processing.
                      </span>
                    </div>
                  </div>
                  <div className="Admin_Dashboard_Entity_Box">
                    <div className="Single_Dashboard_Entity_Box">
                      <h2 className="Title">Advertising Module</h2>
                      <span className="Tooltip">
                        (Coming Soon) An ad module that allows Registered Users or 3rd Parties to advertise across the Instance to other Registered Users as a monetization option for the Administrator.
                      </span>
                    </div>
                  </div>
                  <div className="Admin_Dashboard_Entity_Box">
                    <div className="Single_Dashboard_Entity_Box">
                      <h2 className="Title">Marketplace</h2>
                      <span className="Tooltip">
                        (Coming Soon) A virtual marketplace where Registered Users can connect, team, buy and sell to one another.
                      </span>
                    </div>
                  </div>
                  <div className="Admin_Dashboard_Entity_Box">
                    <div className="Single_Dashboard_Entity_Box">
                      <h2 className="Title">My AI Assistant</h2>
                      <span className="Tooltip">
                        (Coming Soon) A customized Artificial Intelligence entity that assists Registered Users with optimizing their opportunities, developing profiles, drafting proposals and negotiating and bidding on contracts.
                      </span>
                    </div>
                  </div>
                  <div className="Admin_Dashboard_Entity_Box">
                    <div className="Single_Dashboard_Entity_Box">
                      <h2 className="Title">GovTide 3.0</h2>
                    </div>
                  </div>
                </>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <QuickStats />
            <Support />
          </Col>
          <Col lg={6}>
            <RecentRegistrations 
              setLoading={setLoading}
              handleDefaultStatusCodes={handleDefaultStatusCodes}
            />
          </Col>
        </Row>
      </Container>
      <Modal 
        handleClose={handleClose} 
        show={show}
        handleSubmit={handleSubmit}
        onSubmit={setActionType()}
        buttonLabel={setModalButtonLabel(action)}
      >
        { action === 'add' && (
           <AddEntityModalContent 
            entity={ entity } 
            inputValue={ inputValue } 
            setInputValue={ setInputValue }
            selectValue={ selectValue }
            setSelectValue={ setSelectValue }
            inputName={ inputName }
          />
        )} 
        { action === 'delete' && (
          <DeleteEntityModalContent entity={ entity } />
        )}
        { action === 'change' && (
          <ChangeBudgetLimitContent register={register} value={value} onChangeSingleValue={ onChangeSingleValue } />
        )}
        { action === 'exluded' && (
          <ExludeEntityModalContent
            data={ data.excluded_matching_sources } 
            setExludedSelectValue={ setExludedSelectValue }
            exludedSelectValue={ exludedSelectValue }
          />
        )}
      </Modal>
    {loading && <Loading />}
    </section>
  )
}

export default AdminDashboard;