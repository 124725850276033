import React, { useState, useEffect } from 'react';
import TextInput from '../UI/TextInput';
import MultiSelect from '../UI/MultiSelect'
import Select from '../UI/Select'
import { useForm } from 'react-hook-form';
import Spinner from 'react-bootstrap/Spinner'
import { Container, Row, Col } from 'react-bootstrap';
import classes from './index.module.scss'
import Button from '../UI/Button';
import { 
  NAICS_LIST, 
  PSC_LIST,
  CITY_LIST, 
  COUNTY_LIST, 
  SETASIDE_LIST, 
  BUSINESS_TYPE_LIST,
  ZIP_LIST,
  SETASIDE_CODE_LIST,
  OPP_CLASS_LIST,
  STATE_LIST
} from '../../constans';

const DEFAULT_INPUT_VALUES = {
  organization: "",
  setaside_code: "",
  naics: "",
  psc_code: "",
  zip: "",
}

const Home = () => {
  const { register, handleSubmit, errors } = useForm();
  const [check, setCheck] = useState(true);
  const [inputValue, setInputValue] = useState(DEFAULT_INPUT_VALUES)
  const [selectValue, setSelectValue] = useState([]);
  const [list, setList] = useState([]);
  const [firstRender, setFirstRender] = useState(true);
  const [img, setImg] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('Initializing deployment, stand by');
  const [start, setStart] = useState(false)
  const [finalMwssage, setFinalMessage] = useState(false)
  const [error, setError] = useState('')

  const messages = [
    "Allocating resources, this may take a moment",
    "Configuring services, we're getting there",
    "Deployment complete, happy exploring!",
    "Starting up new instance, please wait",
    "Connecting to all necessary services, almost there",
  ]

  const final = "New instance fully deployed"

  const handleMultiDelete = (name, valuesFunc, valuesValue) => {
    switch (name.action) {
      case 'remove-value':
        valuesFunc([...valuesValue.filter(el => el.value !== name.removedValue.value)]);
        break;
      case 'clear':
        valuesFunc([...valuesValue.filter(el => el.type !== name.name)]);
        break;
      default:
    }
  }

  const handleMultiAdd = (item, valuesFunc, valuesValue) => {
    switch (item.type) {
      case 'city':
      case 'naics':
      case 'county':
      case 'set_aside':
      case 'setaside_code':
      case 'type':
      case 'zip':
      case 'opp_type':
      case 'psc_code':
        valuesFunc([...valuesValue, item]);
        break;
      default:
        valuesFunc([...valuesValue.filter(i => i.type !== item.type), item]);
    }
  }

  const setLimitSelectValues = (data, value, blackList = null) => {
    let i = 0;

    if (blackList === null) blackList = [];

    const newList = data.filter(item => {
      if (item.label.toLowerCase().includes(value.toLowerCase()) && i < 10) {
        if (!blackList.includes(item.value)) i++;
        return item
      }
    })

    setList(newList);
  }

  const onChangeHandler = (value, name ,action) => {
    setError('')
    switch (name) {
      case 'city':
        setList(CITY_LIST);
        break;
      case 'county':
        setList(COUNTY_LIST);
        break;
      case 'set_aside':
        setList(SETASIDE_LIST);
        break;
      case 'setaside_code':
        setList(SETASIDE_CODE_LIST);
        break;
      case 'type':
        setList(BUSINESS_TYPE_LIST);
        break;
      case 'opp_type':
        setList(OPP_CLASS_LIST);
        break;
      case 'naics':
        setLimitSelectValues(NAICS_LIST, value);
        break;
      case 'zip':
        setLimitSelectValues(ZIP_LIST, value);
        break;
      case 'psc_code':
        setLimitSelectValues(PSC_LIST, value);
        break
    }

    if (action && action.action === "input-change") {
      setInputValue({ ...inputValue, [name]: value });
    }

    if (!value) setList([]);
  }
  
  const str2bool = (value) => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
    }
    return value;
  }

  const onChangeSingleValue = (event, name) => {
    setError('')
    setInputValue({
      ...inputValue,
      [name]: str2bool(event.target.value)
    })
  }

  const handleMultiChange = (event, name) => {
    setError('')
    const item = Array.isArray(event) ? event[event.length - 1] : "";

    if (name.removedValue || name.removedValues) {
      handleMultiDelete(name, setSelectValue, selectValue);
    } else if (item) {
      handleMultiAdd(item, setSelectValue, selectValue);
    } else if (event && event.type) {
      setSelectValue([...selectValue, event]);
    }
  }

  useEffect(() => {
    
    if (!firstRender) {
      setInputValue({
        ...inputValue,
        [inputValue.name]: ""
      })

      setSelectValue(
        selectValue.filter(item => item.type !== inputValue.name)
      )
    }
    setFirstRender(false);
  }, [check]);

  const onSubmit = (data, e) => {
    e.preventDefault();
    
    const naics = selectValue.find(element => element.type === 'naics');
    const pscCode = selectValue.find(element => element.type === 'psc_code');
    const setasideCode = selectValue.find(element => element.type === 'setaside_code');
    const zipCode = selectValue.find(element => element.type === 'zip');
    
    if (naics && pscCode && setasideCode && inputValue.organization && img && zipCode) {
      setStart(true)
      setError('')
    } else {
      setError('All fields are required.')
    }
  }

  useEffect(() => {
    if (start) {
      let index = 0;
      setLoading(true)
      const loop = setInterval(() => { 
        if (index === messages.length - 1) { 
          clearInterval(loop) 
          setFinalMessage(true)
          setMessage('')
          setLoading(false)
          setStart(false)
        } 
        setMessage(messages[index++])
      }, 5000);
    }
  }, [start])

  const onImageChange = (e) => {
    const [file] = e.target.files;
    setImg(URL.createObjectURL(file));
    setError('')
  };

  return (
    <>
      <section className="section-spaces">
        <Container>
          <Row>
            <Col lg={{span: 6, offset: 3}}>
              <div style={{textAlign: "center", marginBottom: "40px"}}><br /><br /><br /><br /><h1>Self Service Administrative Set-up</h1></div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <label htmlFor="organization">Organization</label>
                <TextInput 
                  name="organization" 
                  type="text" 
                  register={register()}
                  check={check}
                  value={inputValue.organization}
                  onChange={onChangeSingleValue}
                  placeholder="Organization"
                  setInputValue={setInputValue}
                  inputValue={inputValue}
                />
                <label htmlFor="naics">Naics choice (keywords that describe your organization)</label>
                <MultiSelect 
                  onChangeHandler={onChangeHandler}
                  handleMultiChange={handleMultiChange}
                  name="naics"
                  list={list}
                  inputValue={inputValue && inputValue.naics}
                  selectValue={selectValue.filter(item => item.type === 'naics')}
                  styles="react-select-container"
                  prefixStyles="react-select"
                  indicator={false}
                  check={check}
                />
                <label htmlFor="psc_code">PSC choice (products & services you sell)</label>
                <MultiSelect 
                  onChangeHandler={onChangeHandler}
                  handleMultiChange={handleMultiChange}
                  name="psc_code"
                  list={list}
                  inputValue={inputValue && inputValue.psc_code}
                  selectValue={selectValue.filter(item => item.type === 'psc_code')}
                  styles="react-select-container"
                  prefixStyles="react-select"
                  indicator={false}
                  check={check}
                />
                <label htmlFor="setaside_code">Set-Asides (woman, veteran owned, ect)</label>
                <MultiSelect 
                  onChangeHandler={onChangeHandler}
                  handleMultiChange={handleMultiChange}
                  name="setaside_code"
                  list={list}
                  inputValue={inputValue && inputValue.setaside_code}
                  selectValue={selectValue.filter(item => item.type === 'setaside_code')}
                  styles="react-select-container"
                  prefixStyles="react-select"
                  indicator={false}
                  check={check}
                />
                <label htmlFor="state">Location choice</label>
                <Select
                  name="state"
                  isSearchable={false}
                  placeholder="State"
                  defaultValue={{ value: "North Carolina", label: "North Carolina" }}
                  isClear={false}
                  list={STATE_LIST}
                  handleMultiChange={handleMultiChange}
                  styles="react-select-input-container"
                  prefixStyles="react-select-input"
                  indicator={false}
                />
                <label htmlFor="zip">Zip Code</label>
                <Select
                  name="zip" 
                  isSearchable={false}
                  placeholder="Zip Code"
                  onChangeHandler={onChangeHandler}
                  // defaultValue={{ value: "North Carolina", label: "North Carolina" }}
                  isClear={false}
                  list={ZIP_LIST}
                  handleMultiChange={handleMultiChange}
                  styles="react-select-input-container"
                  prefixStyles="react-select-input"
                  indicator={false}
                />
                {/* <TextInput 
                  name="zip_code" 
                  type="text" 
                  check={check}
                  value={inputValue.zip_code}
                  onChange={onChangeSingleValue}
                  placeholder="Zip Code"
                  setInputValue={setInputValue}
                  inputValue={inputValue && inputValue.zip_code}
                /> */}
                <label htmlFor="logo">Logo</label>
                <input type="file" onChange={onImageChange} />
                <img className="uploaded-image-demo" src={img} alt="" />
                {error && <p className="warnMessage">{error}</p>}
                <button
                  className={`btnSizes filledBtn w-100`}
                  type="submit"
                >
                  Submit
                </button>
              </form>
              {start && (
                <div className={classes.Final}>
                  {loading && (
                    <div className={classes.Spinner_Wrapper_Wrapper}>
                      <div className={classes.Custom_Spinner_Wrap}>
                        <Spinner className={classes.Custom_Spinner} animation="border" variant="dark" role="status" />
                      </div>
                      <div>
                        {<p>{message}</p>}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {finalMwssage && (
                <div className={classes.Final}>
                  {`${final} to - `}<a href="https://construction-econ-dev.govtide.com/">https://construction-econ-dev.govtide.com/</a>, have fun!
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>     
    </>
  )
}

export default Home;