import { store } from 'react-notifications-component';

export  const somethingWentWrongNotice = (operationName) => {
  store.addNotification({
    title: operationName,
    message: "Something went wrong. Please try again later.",
    type: "danger",
    container: "top-right",
    animationIn: ["animate__animated", "animate__slideInRight"],
    animationOut: ["animate__animated", "animate__slideOutRight"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  });
};

export const successNotice = (operationName) => {
  store.addNotification({
    title: operationName,
    message: "You are successfully logged in.",
    type: "success",
    container: "top-right",
    animationIn: ["animate__animated", "animate__slideInRight"],
    animationOut: ["animate__animated", "animate__slideOutRight"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  })
};

export const emailPhoneExistingsNotice = () => {
  store.addNotification({
    title: "Registration",
    message: "This Email or Username Is Already Taken.",
    type: "danger",
    container: "top-right",
    animationIn: ["animate__animated", "animate__slideInRight"],
    animationOut: ["animate__animated", "animate__slideOutRight"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  });
};

export const usernameExistingsNotice = () => {
  store.addNotification({
    title: "Change Username",
    message: "This Username Is Already Taken.",
    type: "danger",
    container: "top-right",
    animationIn: ["animate__animated", "animate__slideInRight"],
    animationOut: ["animate__animated", "animate__slideOutRight"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  });
};

export const wrongCredentialsNotice = () => {
  store.addNotification({
    title: "Sign In",
    message: "Wrong username, email or password.",
    type: "danger",
    container: "top-right",
    animationIn: ["animate__animated", "animate__slideInRight"],
    animationOut: ["animate__animated", "animate__slideOutRight"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  });
};

export const notRegisteredNotice = () => {
  store.addNotification({
    title: "Registration",
    message: "This account is not registered in Govtide",
    type: "info",
    container: "top-right",
    animationIn: ["animate__animated", "animate__slideInRight"],
    animationOut: ["animate__animated", "animate__slideOutRight"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  });
}

export const logoutNotice = () => {
  store.addNotification({
    title: "Logout",
    message: "You are successfully logged out.",
    type: "success",
    container: "top-right",
    animationIn: ["animate__animated", "animate__slideInRight"],
    animationOut: ["animate__animated", "animate__slideOutRight"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  });
}

export const recoverPasswordNotice = message => {
  store.addNotification({
    title: "Recover Password",
    message: message,
    type: "info",
    container: "top-right",
    animationIn: ["animate__animated", "animate__slideInRight"],
    animationOut: ["animate__animated", "animate__slideOutRight"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  });
}

export const userNotFoundNotice = () => {
  store.addNotification({
    title: "Recover Password",
    message: "User Not Found.",
    type: "danger",
    container: "top-right",
    animationIn: ["animate__animated", "animate__slideInRight"],
    animationOut: ["animate__animated", "animate__slideOutRight"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  });
}

export const sendAgainEmailNotice = () => {
  store.addNotification({
    title: "Email Verification",
    message: "Please check your email to activate your Govtide account and to verify your email address.",
    type: "info",
    container: "top-right",
    animationIn: ["animate__animated", "animate__slideInRight"],
    animationOut: ["animate__animated", "animate__slideOutRight"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  });
}

export const sendAgainPhoneForVerificationNotice = () => {
  store.addNotification({
    title: "Phone Verification",
    message: "Please check your phone to activate your Govtide account and to verify your phone number.",
    type: "info",
    container: "top-right",
    animationIn: ["animate__animated", "animate__slideInRight"],
    animationOut: ["animate__animated", "animate__slideOutRight"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  });
}

export const sendAgainPhoneNotice = () => {
  store.addNotification({
    title: "Recover Password",
    message: "Please check your phone to reset your password.",
    type: "info",
    container: "top-right",
    animationIn: ["animate__animated", "animate__slideInRight"],
    animationOut: ["animate__animated", "animate__slideOutRight"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  });
}

export const successPasswordChangeNotice = title => {
  store.addNotification({
    title: title,
    message: "Password has been changed.",
    type: "success",
    container: "top-right",
    animationIn: ["animate__animated", "animate__slideInRight"],
    animationOut: ["animate__animated", "animate__slideOutRight"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  });
}

export const successUserChangeNotice = title => {
  store.addNotification({
    title: title,
    message: "Username has been changed.",
    type: "success",
    container: "top-right",
    animationIn: ["animate__animated", "animate__slideInRight"],
    animationOut: ["animate__animated", "animate__slideOutRight"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  });
}

export const deleteUserNotice = () => {
  store.addNotification({
    title: "Delete User",
    message: "User has been deleted.",
    type: "success",
    container: "top-right",
    animationIn: ["animate__animated", "animate__slideInRight"],
    animationOut: ["animate__animated", "animate__slideOutRight"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  });
}

export const deleteMessageNotice = () => {
  store.addNotification({
    title: "Inbox",
    message: "Message has been deleted.",
    type: "success",
    container: "top-right",
    animationIn: ["animate__animated", "animate__slideInRight"],
    animationOut: ["animate__animated", "animate__slideOutRight"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  });
}

export const successAccountTypeChangeNotice = title => {
  store.addNotification({
    title: title,
    message: "Your account type has been changed.",
    type: "success",
    container: "top-right",
    animationIn: ["animate__animated", "animate__slideInRight"],
    animationOut: ["animate__animated", "animate__slideOutRight"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  });
}

export const invalidKeyNotice = (operationName) => {
  store.addNotification({
    title: operationName,
    message: "Invalid Key",
    type: "danger",
    container: "top-right",
    animationIn: ["animate__animated", "animate__slideInRight"],
    animationOut: ["animate__animated", "animate__slideOutRight"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  });
}

export const successPhoneVerificationNotice = () => {
  store.addNotification({
    title: "Phone Verification",
    message: "Your phone number has been verified.",
    type: "success",
    container: "top-right",
    animationIn: ["animate__animated", "animate__slideInRight"],
    animationOut: ["animate__animated", "animate__slideOutRight"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  });
}

export const successEmailVerificationNotice = () => {
  store.addNotification({
    title: "Email Verification",
    message: "Your email has been verified.",
    type: "success",
    container: "top-right",
    animationIn: ["animate__animated", "animate__slideInRight"],
    animationOut: ["animate__animated", "animate__slideOutRight"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  });
}

export const wrongLinkNotice = () => {
  store.addNotification({
    title: "Email Verification",
    message: "You followed the wrong link. Please try again.",
    type: "danger",
    container: "top-right",
    animationIn: ["animate__animated", "animate__slideInRight"],
    animationOut: ["animate__animated", "animate__slideOutRight"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  });
}

export const minOneFieldNotice = (message) => {
  store.addNotification({
    title: "Match Builder",
    message: message,
    type: "danger",
    container: "top-right",
    animationIn: ["animate__animated", "animate__slideInRight"],
    animationOut: ["animate__animated", "animate__slideOutRight"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  });
}

export const successMatchesNotice = () => {
  store.addNotification({
    title: "Match Builder",
    message: "Matching has been successful.",
    type: "success",
    container: "top-right",
    animationIn: ["animate__animated", "animate__slideInRight"],
    animationOut: ["animate__animated", "animate__slideOutRight"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  });
}

export const noMatchesNotice = () => {
  store.addNotification({
    title: "Match Builder",
    message: "No matches were created.",
    type: "info",
    container: "top-right",
    animationIn: ["animate__animated", "animate__slideInRight"],
    animationOut: ["animate__animated", "animate__slideOutRight"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  });
}

export const settingsNotFoundNotice = () => {
  store.addNotification({
    title: "Match Builder",
    message: "Settings not found.",
    type: "info",
    container: "top-right",
    animationIn: ["animate__animated", "animate__slideInRight"],
    animationOut: ["animate__animated", "animate__slideOutRight"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  });
}

export const oneSelectNotice = (message) => {
  store.addNotification({
    title: "Business Registration",
    message: message,
    type: "danger",
    container: "top-right",
    animationIn: ["animate__animated", "animate__slideInRight"],
    animationOut: ["animate__animated", "animate__slideOutRight"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  });
}

export const editedNotice = () => {
  store.addNotification({
    title: "Edit Profile",
    message: "Your profile has been changed.",
    type: "success",
    container: "top-right",
    animationIn: ["animate__animated", "animate__slideInRight"],
    animationOut: ["animate__animated", "animate__slideOutRight"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  });
}

export const dateRequireNotice = message => {
  store.addNotification({
    title: "Post",
    message: message,
    type: "danger",
    container: "top-right",
    animationIn: ["animate__animated", "animate__slideInRight"],
    animationOut: ["animate__animated", "animate__slideOutRight"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  });
}

export const userIsDisabled = message => {
  store.addNotification({
    title: "Users",
    message: message,
    type: "info",
    container: "top-right",
    animationIn: ["animate__animated", "animate__slideInRight"],
    animationOut: ["animate__animated", "animate__slideOutRight"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  });
}

export const deleteReportNotice = () => {
  store.addNotification({
    title: "Delete Report",
    message: "Report has been deleted.",
    type: "success",
    container: "top-right",
    animationIn: ["animate__animated", "animate__slideInRight"],
    animationOut: ["animate__animated", "animate__slideOutRight"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  });
}

export const replyMessageNotice = (title) => {
  store.addNotification({
    title: title,
    message: "Message has been sent.",
    type: "success",
    container: "top-right",
    animationIn: ["animate__animated", "animate__slideInRight"],
    animationOut: ["animate__animated", "animate__slideOutRight"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  });
}

export const userNotFound = (title) => {
  store.addNotification({
    title: title,
    message: "User not found.",
    type: "danger",
    container: "top-right",
    animationIn: ["animate__animated", "animate__slideInRight"],
    animationOut: ["animate__animated", "animate__slideOutRight"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  });
}

export const messageSentNotice = (operationName) => {
  store.addNotification({
    title: operationName,
    message: "Message sent",
    type: "success",
    container: "top-right",
    animationIn: ["animate__animated", "animate__slideInRight"],
    animationOut: ["animate__animated", "animate__slideOutRight"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  })
};

export const reportsFields = (operationName) => {
  store.addNotification({
    title: operationName,
    message: "Please select one or more fields for export.",
    type: "info",
    container: "top-right",
    animationIn: ["animate__animated", "animate__slideInRight"],
    animationOut: ["animate__animated", "animate__slideOutRight"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  })
};

export const linkExpireNotice = (operationName) => {
  store.addNotification({
    title: operationName,
    message: "This link is expired or not valid.",
    type: "danger",
    container: "top-right",
    animationIn: ["animate__animated", "animate__slideInRight"],
    animationOut: ["animate__animated", "animate__slideOutRight"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  });
};
