import React, { useContext, useState } from "react";
import SignInForm from './SignInForm';
import { loginPost, getVerify } from '../../api';
import { useHistory } from "react-router-dom";
import { UserContext } from '../../context/User';
import classes from './index.module.scss';
import { Container, Row, Col } from "react-bootstrap";
import { HashLink } from 'react-router-hash-link';
import { somethingWentWrongNotice, successNotice, wrongCredentialsNotice } from '../UI/Notifications';
import WhatsNewBanner from "components/UpdateWhatsNewMessage/WhatsNewBanner";
import Logo from '../Logo';
import { ReactComponent as LogoIcon } from '../../assets/icons/black-logo.svg';
import { Link } from 'react-router-dom';
import Select from '../UI/Select';
import YouTubeEmbed from "components/YouTubeEmbed";

const SignIn = () => {
  const [loading, setLoading] = useState(false);
  const { setUser, setIsLoggedIn } = useContext(UserContext);
  const history = useHistory();
  const querystring = require('querystring');
  const [inputValue, setInputValue] = useState({
    gov_relations: ""
  })
  const [selectValue, setSelectValue] = useState([{ value: "Business_Organization", label: "Business Organization", type: "gov_relations" }])

  const login = (userData) => {
    setIsLoggedIn(true);
    setUser(userData);
    history.push("/dashboard");
  }

  const verification = (responseData) => {
    setLoading(true);
    const params = {
      user_id: responseData.user_id,
      hash_data: responseData.hash_data,
      link: `${window.origin}/user-verification`,
      gov_relations: selectValue.find(item => item.type === 'gov_relations').value
    }

    getVerify(params)
      .then(() => {
        const queryString = querystring.stringify(params);
        setLoading(false);
        history.push(`/send-again?${queryString}`);
      })
      .catch(() => {
        somethingWentWrongNotice("Sign In");
        setLoading(false);
      });
  }

  const loginPostRequest = (dataObj) => {
    setLoading(true);
    loginPost(dataObj)
    .then(response => {
      setLoading(false);
      login(response.data);
      successNotice("Sign In");
    })
    .catch(error => {
      if (error.response.status === 404) {
        wrongCredentialsNotice();
        setLoading(false);
      }
      if (error.response.status === 401) verification(error.response.data);
      if (![401, 404].includes(error.response.status)) {
        somethingWentWrongNotice("Sign In");
        setLoading(false);
      }
    })
  }

  const onSubmit = (data, e) => {
    setLoading(true)
    e.preventDefault();
    e.target.reset();
    
    loginPostRequest(data);
  };

  const handleMultiAdd = (item, valuesFunc, valuesValue) => {
    switch (item.type) {
      case 'gov_relations':
        valuesFunc([...valuesValue, item]);
        break;
      default:
        valuesFunc([...valuesValue.filter(i => i.type !== item.type), item]);
    }
  }

  const onChangeHandler = (value, name, action) => {
    if (action && action.action === "input-change") {
      setInputValue({ ...inputValue, [name]: value });
    }
  }

  const handleMultiChange = (event, name) => {
    const item = Array.isArray(event) ? event[event.length - 1] : "";

    if (name.removedValue || name.removedValues) {
      handleMultiDelete(name, setSelectValue, selectValue);
    } else if (item) {
      handleMultiAdd(item, setSelectValue, selectValue);
    } else if (event && event.type) {
      setSelectValue([event]);
    }
  }

  const handleMultiDelete = (name, valuesFunc, valuesValue) => {
    switch (name.action) {
      case 'remove-value':
        valuesFunc([...valuesValue.filter(el => el.value !== name.removedValue.value)]);
        break;
      case 'clear':
        valuesFunc([...valuesValue.filter(el => el.type !== name.name)]);
        break;
      default:
    }
  }

  return (
    <Container fluid style={{
      marginTop: "80px",
      marginBottom: "80px"
    }}>
      <Row>
        <Col lg={{span: 6}} md={{span: 5}}>
          <div className={classes.HeaderWrapper}>
            <Logo
              path="/"
            >
              <LogoIcon />
            </Logo>
            <h2 className={classes.HeaderDesc}>Matching You With Contract and Grant Opportunities and Awards</h2>
            <form>
              <fieldset>
                <label className="homepage_gov_relations_label" htmlFor="gov_relations">I am a: <span>*</span></label>
                <Select 
                  onChangeHandler={onChangeHandler}
                  handleMultiChange={handleMultiChange}
                  name="gov_relations"
                  list={
                    [
                      { value: "Business_Organization", label: "Business Organization", type: "gov_relations" },
                      { value: "Government_Entity", label: "Government Entity", type: "gov_relations" },
                      { value: "Contracting_Consultant", label: "Contracting Consultant", type: "gov_relations" },
                      { value: "Lobbyist_Group", label: "Lobbyist Group", type: "gov_relations" },
                      { value: "Lender_Institution", label: "Lender Institution", type: "gov_relations" },
                      { value: "Financial_Investor", label: "Financial Investor", type: "gov_relations" },
                      { value: "Individual_Employee", label: "Individual Employee", type: "gov_relations" },
                      { value: "Media_Entity", label: "Media Entity", type: "gov_relations" },
                      { value: "Elected_Official", label: "Elected Official", type: "gov_relations" },
                    ]
                  }
                  inputValue={inputValue.gov_relations}
                  selectValue={selectValue.filter(item => item.type === 'gov_relations')}
                  styles="react-select-input-container"
                  prefixStyles="react-select-input"
                  indicator={false}
                  check={true}
                  placeholder="I am a..."
                  isSearchable={false}
                  defaultValue={{ value: "Business_Organization", label: "Business Organization", type: "gov_relations" }}
                />
              </fieldset>
            </form>
            <Link
              className='btnSizes filledBtn'
              to={`/registration?role=business&gov_relations=${selectValue.find(item => item.type === 'gov_relations').value}`}
            >
              Procced with Registration
            </Link>
            <YouTubeEmbed embedId="nH1_DJviqq4" />
          </div>
        </Col>
        <Col lg={{span: 5, offset: 1}} md={{span: 7, offset: 0}}>
          <div className={classes.Sign_In_Form_Wrap}>
            <h2 className="text-center">Log into GovTide</h2>
            {/* <h6 className="text-center">
              Don’t have an account?
              <HashLink smooth to="/#basic-info">Register</HashLink>
            </h6> */}
            <WhatsNewBanner styleClass="Whats_New" />
            <SignInForm
              onSubmit={onSubmit}
              loading={loading}
            />
            <p className={classes.Build}>Build Your Profile & Start Receiving Free Matching Contract, Grant & Investment Opportunities Today!</p>
          </div>
        </Col>
      </Row>
    </Container>
   
  );
}

export default SignIn;
