import React, { useEffect, useState, useContext } from 'react';
import Loading from '../Loading';
import { useForm } from 'react-hook-form';
import { Container, Row, Col } from 'react-bootstrap';
import EditProfileTabOptions from './EditProfileTabOptions';
import { useHistory } from 'react-router-dom';
import { EDIT_USER_INFO_LIST } from '../../constans';
import { TabContext } from '../../context/TabContext';
import ActionContainer from './ActionContainer';
import { profileGet, userPost } from '../../api';
import { UserContext } from '../../context/User';
import { editedNotice, somethingWentWrongNotice } from '../UI/Notifications';

const DEFAULT_INPUT_VALUES = {
  user: {
    username: "",
    email: "",
    password: "",
    user_message: false,
    user_contact: false
  }
}

const AdminEditProfile = () => {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const [inputValue, setInputValue] = useState(DEFAULT_INPUT_VALUES)
  const { user, setUser, setIsLoggedIn } = useContext(UserContext);
  const history = useHistory();
  const [currentInfoTrigger, setCurrentInfoTrigger] = useState(false);

  const logout = () => {
    setIsLoggedIn(false);
    setUser({});
    history.push('/');
  }

  const handleDefaultStatusCodes = (status_code) => {
    if (status_code === 440 || status_code === 401) {
      logout()
    } else {
      somethingWentWrongNotice("Search");
    }

    setLoading(false);
  }

  const getInputCurrentUserData = (response) => {
    setInputValue({
      ...inputValue,
      user: {
        ...inputValue.user,
        username: response.username,
        user_contact: response.user_contact,
        email: response.email,
        user_message: response.user_message
      }
    })
  }

  const currentInfoRequest = request => {
    setLoading(true)
    const user_id = user.id;

    request({user_id})
      .then(response => {
        getInputCurrentUserData(response.data);
        setLoading(false);
      })
      .catch(error => {
        handleDefaultStatusCodes(error.response.status);
      })
  }

  useEffect(() => {
    currentInfoRequest(profileGet)
  }, [currentInfoTrigger]);

  const onChangeSingleValue = (event, name, infoType) => {
    setInputValue({
      ...inputValue,
      [infoType]: {
        ...inputValue[infoType],
        [name]: event.target.value
      }
    })
  }

  const dataTransformation = params => {
    for (let prop in params) {
      if (
        params[prop] === null || 
        params[prop] === undefined ||
        prop === "password" && !params[prop]
      ) {
        delete params[prop];
      }

      if (params[prop] === 'false') {
        params[prop] = false
      } else if (params[prop] === 'true') {
        params[prop] = true
      }
    }

    return params;
  }

  const onSubmit = (data, e) => {
    e.preventDefault();
    setLoading(true);

    const params = {
      ...data
    }

    const transformedParams = dataTransformation(params);
    request(transformedParams)
  };
  
  const request = transformedParams => {
    userPost(transformedParams)
      .then(response => {
        editedNotice();
        setUser(response.data);
        setLoading(false);
      })
      .catch(error => {
        handleDefaultStatusCodes(error.response.status);
        setCurrentInfoTrigger(true);
        setLoading(false);
      })
  }

  return (
    <section className="section-spaces">
      <Container>
        <Row>
          <Col>
            <h3 className="mb-5">
              Edit Profile
            </h3>
          </Col>
        </Row>
        <TabContext.Provider value={{
          register,
          inputValue, 
          setInputValue, 
          onChangeSingleValue,
          setLoading,
          errors
        }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col>
                <EditProfileTabOptions inputList={EDIT_USER_INFO_LIST} />
              </Col>
            </Row>
            <Row>
              <Col>
                <ActionContainer />
              </Col>
            </Row>
          </form>
        </TabContext.Provider>
      </Container>
      {loading && <Loading />}
    </section>
  )
}

export default AdminEditProfile;