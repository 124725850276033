import React, { useState, useEffect } from "react";
import LocationAutocomplete from "components/LocationAutoComplete";
import { csv } from "d3-fetch";
import haversine from "haversine-distance";
import { GoogleMap, Circle } from "@react-google-maps/api";


const LocationRadiusFilter = ({ onFilterUpdate, onFilterUpdateLocation }) => {
  const [unit, setUnit] = useState("km");
  const [locations, setLocations] = useState([]);
  const defaultCenter = {
    lat: 39.8283,
    lng: -98.5795,
  };
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const [mapZoom, setMapZoom] = useState(4);
  const [map, setMap] = useState(null);

  const mapStyles = {
    height: "400px",
    width: "100%",
  };


  const fitBounds = (map, locations) => {
    const bounds = new window.google.maps.LatLngBounds();

    locations.forEach((location) => {
      if (location.coordinates) {
        bounds.extend(location.coordinates);
      }
    });

    map.fitBounds(bounds);
    const listener = window.google.maps.event.addListener(
      map,
      "bounds_changed",
      () => {
        if (map.getZoom() > 8) {
          map.setZoom(8); // Set the desired maximum zoom level
        }
        window.google.maps.event.removeListener(listener);
      }
    );
  };

  useEffect(() => {
    if (locations.length > 0) {
      fetchZipCodes(locations);
      if (map) {
        fitBounds(map, locations);
      }
    }
  }, [locations]);

  const fetchZipCodes = async (locations) => {
    const data = await csv("/zips.csv");
    const filteredZipCodes = new Set();

    locations.forEach((location) => {
      const { coordinates, radius } = location;
      if (!coordinates) return; // Skip the iteration if coordinates are null

      const radiusInMeters =
        unit === "miles" ? radius * 1609.34 : radius * 1000;

      data.forEach((zip) => {
        const zipCoordinates = { lat: +zip.lat, lng: +zip.lng };
        const distance = haversine(coordinates, zipCoordinates);

        if (distance <= radiusInMeters) {
          filteredZipCodes.add(zip);
        }
      });
    });

    onFilterUpdate([...filteredZipCodes]);
  };

  const handleLocationsChange = (updatedLocations) => {
    setLocations(updatedLocations);
    onFilterUpdate(updatedLocations);
  };

  return (
    <div className="location-radius-filter">
      <LocationAutocomplete
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_DROPDOWN_API_KEY}
        onLocationsChange={handleLocationsChange}
        map={map}
        onFilterUpdateLocation={onFilterUpdateLocation}
      />
      <GoogleMap
        onLoad={setMap}
        onUnmount={() => setMap(null)}
        mapContainerStyle={mapStyles}
        zoom={mapZoom}
        center={mapCenter}
      >
        {/* {locations &&
          locations.map((location, index) => (
            <Circle
              key={index}
              center={location.coordinates}
              radius={location.radius * 1000}
              options={{
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FF0000",
                fillOpacity: 0.35,
                clickable: false,
                draggable: false,
                editable: false,
                visible: true,
                zIndex: 1,
              }}
            />
          ))} */}
      </GoogleMap>
    </div>
  );
};

export default LocationRadiusFilter;
