import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import classes from './index.module.scss';

const About = () => {
  return (
    <section className={classes.About}>
      <Container>
        <Row>
          <Col>
            <h2 className={classes.About_Title}>
              About Match Force
            </h2>
            <p className={classes.About_Description}>
              MatchForce.org is a web-based tool provided by the State of North Carolina and the North Carolina Military Business Center 
              <a target="_blank" rel="noreferrer" href="https://www.ncmbc.us/">(NCMBC)</a>, under direction of Fayetteville Technical Community College, 
              to individuals and government purchasers worldwide and to companies in North Carolina. 
            </p>
            <p className={classes.About_Description}>
              The tool matches government purchasers to NC suppliers, and NC businesses to federal business opportunities and potential federal contract teaming partners.

            </p>
            <p className={classes.About_Description}>
              The portal supports the NCMBC's goals to: expand federal contracting with North Carolina businesses; integrate transitioning 
              military and military family members into the state workforce; and recruit additional defense-related businesses to North Carolina. 
            </p>
            <p className={classes.About_Description}>
              MatchForce.org is powered by  <a target="_blank" rel="noreferrer" href="https://govtide.com">GovTide</a>, a revolutionary tool developed by <a target="_blank" rel="noreferrer" href="https://www.ait.com/">Advanced Internet Technologies, Inc.</a> 
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default About;