import React, { useContext, useEffect, useState } from 'react'
import Logo from '../Logo';
import { Container, Row, Col } from 'react-bootstrap';
import { Link, useHistory, useLocation } from "react-router-dom";
import Dropdown from '../UI/Dropdown';
import { ReactComponent as LogoIcon } from '../../assets/icons/black-logo.svg';
import classes from './index.module.scss';
import { ReactComponent as SearchIcon } from '../../assets/icons/search-input-icon.svg';
import Logout from '../Logout';
import { UserContext } from '../../context/User';
import { ReactComponent as InboxIcon } from '../../assets/icons/inbox-icon.svg';
import { myMessagesGet, profileGet, userGet } from '../../api';
import { somethingWentWrongNotice } from 'components/UI/Notifications';
import ProgressBar from 'components/ProgressBar';

const HEADER_SEARCH_DROPDOWN_ITEMS = [
  {
    name: "Advanced Search",
    link: "/advanced-search"
  },
  {
    name: "Contract Opportunities",
    link: "/search?entity=contract_opportunities"
  },
  {
    name: "B2B Opportunities",
    link: "/search?entity=b2b_opportunities"
  },
  // {
  //   name: "Grants",
  //   link: "/search?entity=grants"
  // },
  {
    name: "Businesses",
    link: "/search?entity=businesses"
  },
  {
    name: "Find Local Opportunities",
    link: "/search?entity=micro_purchases"
  }
];

const HEADER_USER_OPTIONS = [
  {
    name: "Dashboard",
    link: "/dashboard"
  },
  {
    name: "Edit Profile",
    link: "/edit-profile"
  },
  {
    name: "Support",
    link: "/contact-us"
  },
  {
    name: "Logout",
    component: <Logout />
  }
];

const LoginHeader = ({trigger}) => {
  const { user, setIsLoggedIn, setUser } = useContext(UserContext);
  const [isntReadMessages, setIsntReadMessages] = useState(0); 

  const [showMobileMenuButton, setShowMobileMenuButton] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [profilePct, setProfilePct] = useState(0);

  const history = useHistory();
  const location = useLocation();

  const showProgressBar = location.pathname !== '/business-registration' && location.pathname !== '/edit-profile'
  const role = user && user.roles && user.roles[0].role_description;
   
  const logout = () => {
    setIsLoggedIn(false);
    setUser({});
    history.push('/');
  }

  const handleDefaultStatusCodes = (status_code) => {
    if (status_code === 440 || status_code === 401) {
      logout()
    } else {
      somethingWentWrongNotice("Header Messages");
    }
  }

  useEffect(() => {
    userGet()
      .then(response => {
        setProfilePct(response.data.profile_pct);
      })
      .catch(error => {
        handleDefaultStatusCodes(error.response.status)
      })

    myMessagesGet({
      limit: 1,
      only_unread: true
    })
      .then(response => {
        setIsntReadMessages(response.data.total_count);
      })
      .catch(error => {
        if (error.response.status !== 404) {
          handleDefaultStatusCodes(error.response.status);
        } else {
          setIsntReadMessages(0);
        }
      })
  }, [trigger]);

  useEffect(() => {
    if(window.screen.width <= 575) { 
      setShowMobileMenuButton(true);
    }
  }, [showMobileMenuButton])

  return (
    <header className={classes.Header}>
      <Container>
        <Row>
          <Col className={classes.Header_Col}>
              <Logo
                path="/dashboard"
              >
                <LogoIcon />
              </Logo>
              {((window.screen.width <= 575 && showMobileMenu) || window.screen.width > 575) && 
              <div className={classes.Main_Nav}>
                <Link
                  to="/dashboard"
                  className="defaultBtn outlineBtn"
                >
                  Dashboard
                </Link>
                <Dropdown 
                  title="Search"
                  icon={<SearchIcon />}
                  dropDownList={HEADER_SEARCH_DROPDOWN_ITEMS}
                  className="outlineBtn"
                />
                <Link
                  to="/post"
                  className="defaultBtn outlineBtn"
                >
                  Post
                </Link>
              </div>
              }
            <div
              className={classes.Headers_Inbox_Wrap}
            >
              <Link
                to="/inbox"
                className={`${classes.Inbox_Link} defaultBtn outlineBtn`}
              >
                <InboxIcon />
                {!!isntReadMessages && <div className={classes.Inbox_Unread_Number}>{isntReadMessages}</div>}
                <span>Messages</span>
              </Link>
              <Dropdown 
                title="My Account"
                type="user"
                dropDownList={HEADER_USER_OPTIONS}
                className="outlineBtn"
              />
            </div>
            { showMobileMenuButton && 
              <div className={classes.Main_Nav_Mobile_Button} onClick={() => setShowMobileMenu(!showMobileMenu)}>
                <span className={classes.Main_Nav_Mobile_Icon}>&#9776;</span>
              </div>
            }
          </Col>
        </Row>
        {role === "business" && profilePct !== 100 && showProgressBar &&
          <Row>
            <ProgressBar profilePct={profilePct} onClick={() => history.push({
              pathname: '/edit-profile',
              state: { tab: 'match_info' }
            })} 
            />
          </Row> 
        }  
      </Container>
    </header>
  )
}

export default LoginHeader;