import React from "react";
import TextInput from '../UI/TextInput'
import classes from './index.module.scss'
import { ReactComponent as SearchIcon } from '../../assets/icons/search-input-icon.svg';

const AwardsSearch = ({ onChangeSearchHandler, inputValue }) => {
  return (
    <div className={classes.Search_Wrapper}>
      <TextInput 
        name="award-search"
        type="text"
        placeholder="Search for an award"
        onChange={onChangeSearchHandler}
        value={inputValue.search}
      />
      <SearchIcon />
    </div>
  )
}

export default AwardsSearch