import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import classes from './index.module.scss'
import date from 'date-and-time';
import { instanceAwardsPost } from '../../api';
import Loading from '../Loading';
import { useHistory } from 'react-router-dom';
import { somethingWentWrongNotice } from '../UI/Notifications';
import { UserContext } from '../../context/User';
import AwardsSearch from './AwardsSearch';
import TotalAwards from './TotalAwards';
import AwardsDateRange from './AwardsDateRange';
import AwardsCategorySelect from './AwardsCategorySelect';
import TableContainer from './TableContainer';
import SearchSettings from "../SearchSettings";
import Pagination from "../Pagination";
import PageSwitcher from "../PageSwitcher";
import { SearchContext } from '../../context/Search';
import DownloadXLSXButton from './DownloadXLSXButton';
import AwardsColumns from './AwardsColumns';

const MILISECONDS_IN_DAY = 86400000;
const DAYS_IN_MONTH = 30;
const CURRENT_DATE = new Date();
const INITIAL_START_DATE = date.format(new Date(+CURRENT_DATE - DAYS_IN_MONTH * MILISECONDS_IN_DAY), 'YYYY-MM-DD');
const INITIAL_END_DATE =  date.format(new Date(), 'YYYY-MM-DD');

const AwardsDetails = () => {
  const pre_month = date.format(new Date(+CURRENT_DATE - DAYS_IN_MONTH * MILISECONDS_IN_DAY), 'MM/DD/YYYY');
  const [inputValue, setInputValue] = useState({
    search: "",
    awards_daterange: {
      start_date: INITIAL_START_DATE,
      end_date: INITIAL_END_DATE
    }
  });
  const [category, setCategory] = useState("instance")
  const [data, setData] = useState([]);
  const [filterDateFrom, setFilterDateFrom] = useState(pre_month);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { setUser, setIsLoggedIn } = useContext(UserContext);
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [forcePage, setForcePage] = useState(0);
  const [limitedData, setLimitedData] = useState([]);
  const [selectedColums, setSelectedColums] = useState([
    { name: "Contract Title", status: true },
    { name: "City", status: true },
    { name: "Contract", status: true },
    { name: "Solicitation", status: true },
    { name: "Source", status: true },
    { name: "Amount", status: true }
  ]);
  
  const logout = () => {
    setIsLoggedIn(false);
    setUser({});
    history.push('/');
  }

  const handleDefaultStatusCodes = (status_code) => {
    if (status_code === 440 || status_code === 401) {
      logout()
    } else {
      somethingWentWrongNotice("Awards Details");
    }

    setLoading(false);
  }

  const onChangeSearchHandler = e => {
    const value = e.target.value;

    setInputValue({
      ...inputValue,
      search: value
    })

    if (value) {
      const searchedData = data.filter(item => {
        for (const i in item) {
          if (item[i]) {
            if (item[i].toString().toLowerCase().includes(value.toLowerCase())) {
              return item;
            }
          }
        }
      })
      setTotalCount(searchedData.length);
      const limitedSearchedData = searchedData.slice(offset, limit + offset);
      setLimitedData(limitedSearchedData);
    }
  }
  
  useEffect(() => {
    setInputValue({
      ...inputValue,
      search: ""
    })
    setLoading(true);
    if (category === 'instance') {
      instanceAwardsPost({ awards_daterange: inputValue.awards_daterange, filter: category })
      .then(response => {
        setData(response.data);
        setLoading(false);
      })
      .catch(error => {
        if (error.response.status === 404) {
          setData([]);
          setLoading(false);
        } else {
          handleDefaultStatusCodes(error.response.status);
        }
      })
    } else {
      setData([]);
      setLoading(false);
    }
  }, [category, inputValue.awards_daterange])

  useEffect(() => {
    if (!inputValue.search) {
      const limited = data && data.slice(offset, limit + offset);
      setTotalCount(data.length);
      setLimitedData(limited);
    }
  }, [data, offset, limit, inputValue.search])

  useEffect(() => {
    if (category === "grants") {
      setSelectedColums([
        { name: "Contract Title", status: true },
        { name: "City", status: true },
        { name: "Contract", status: true },
        { name: "Solicitation", status: true },
        { name: "Source", status: true },
        { name: "Amount", status: true },
      ]);
    } else {
      setSelectedColums([
        { name: "Contract Title", status: true },
        { name: "City", status: true },
        { name: "Contract", status: true },
        { name: "Solicitation", status: true },
        { name: "Source", status: true },
        { name: "Amount", status: true },
        { name: "Duns", status: true },
        { name: "UEI", status: true },
        { name: "Tax ID", status: true },
        { name: "Contact info", status: true },
      ]);
    }
  }, [category])

  const handleColumnChange = (element) => {
    const newArr = [];
    selectedColums.forEach((el) => {
      if (el["name"] === element["name"]) {
        newArr.push({
          ...element,
          status: !el["status"],
        });
      } else {
        newArr.push(el);
      }
    });
    setSelectedColums(newArr);
  };
  
  return (
    <section className="section-spaces">
      <Container>
        <Row>
          <Col>
            <h3 className="mb-5">
              Awards Details
            </h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <AwardsSearch 
              onChangeSearchHandler={ onChangeSearchHandler }
              inputValue={ inputValue }
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TotalAwards
              category={ category } 
              setLoading={ setLoading }
              handleDefaultStatusCodes={ handleDefaultStatusCodes }
            />
          </Col>
        </Row>
        <Row className='mb-5'>
          <Col>
            <Row>
              <Col lg={4}>
                <AwardsDateRange 
                  setInputValue={ setInputValue }
                  inputValue={ inputValue }
                  filterDateFrom={ filterDateFrom } 
                  initialStartDate={ INITIAL_START_DATE }
                  initialEndDate={ INITIAL_END_DATE }
                />
              </Col>
              <Col lg={4}>
                <AwardsColumns 
                  selectedColums={ selectedColums } 
                  handleColumnChange={ handleColumnChange }
                />
              </Col>
              <Col lg={4}>
                <AwardsCategorySelect 
                  setCategory={ setCategory }
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <SearchContext.Provider value={{
          totalCount,
          limit,
          offset,
          setLimit,
          setOffset,
          forcePage,
          setForcePage,
          pageCount,
          setPageCount,
          loading,
          setLoading
        }}>
          { Boolean(limitedData.length) && (
            <SearchSettings />
          )}
          { Boolean(limitedData.length) && (
            <Row>
              <Col>
                <TableContainer 
                  data={ limitedData }
                  selectedColums={ selectedColums }
                  handleDefaultStatusCodes={ handleDefaultStatusCodes }
                  setLoading={ setLoading }
                />
              </Col>
            </Row>
          )}
          {Boolean(limitedData.length) && (
            <div className="pagination-wrap pt-2 pb-5">
              <Pagination />
              <PageSwitcher />
            </div>
          )}
        </SearchContext.Provider>
        { !Boolean(limitedData.length) && (
          <Row>
            <Col>
              No data
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <DownloadXLSXButton 
              daterange={ inputValue.awards_daterange }
              category={ category }
            />
          </Col>
        </Row>
      </Container>
      {loading && <Loading />}
    </section>
  )
}

export default AwardsDetails;