import React, { useContext } from 'react';
import { Switch, useHistory } from 'react-router-dom';
import { GuardProvider, GuardedRoute } from 'react-router-guards';
import Loading from './components/Loading';
import { UserContext } from './context/User';
import { userGet } from './api';
import { loginRoutes } from './constans';

import HomePage from "./Pages/HomePage";
import SearchPage from './Pages/SearchPage';
import ContactUsPage from './Pages/ContactUsPage';
import AboutPage from './Pages/AboutPage';
import VideosPage from './Pages/VideosPage';
import SignInPage from './Pages/SignInPage';
import RegistrationPage from "./Pages/UserRegistrationPage";
import RegistrationPreviewPage from './Pages/RegistrationPreviewPage';
import BusinessRegistrationPage from './Pages/BusinessRegistrationPage';
import GovernmentRegistrationPage from './Pages/GovernmentRegistrationPage';
import AdvancedSearchPage from './Pages/AdvancedSearchPage';
import VerifyPage from './Pages/VerifyPage';
import SendAgainPage from './Pages/SendAgainPage';
import DashboardPage from './Pages/DashboardPage';
import AdvansedResultsPage from './Pages/AdvansedResultsPage';
import BusinessSinglePage from './Pages/BusinessSinglePage';
import OpportunitySinglePage from './Pages/OpportunitySinglePage';
import EditProfilePage from './Pages/EditProfilePage';
import SetAdminPasswordPage from "./Pages/SetAdminPasswordPage";
import SetPasswordPage from "./Pages/SetPasswordPage";
import RecoverPasswordPage from './Pages/RecoverPasswordPage';
import PostPage from './Pages/PostPage';
import PurchaseSinglePage from './Pages/PurchaseSinglePage';
import FutureOpportunitySinglePage from './Pages/FutureOpportunitySinglePage';
import B2BSinglePage from './Pages/B2BSinglePage';
import UsersPage from './Pages/UsersPage';
import CommunicationPage from './Pages/CommunicationPage';
import InboxPage from './Pages/InboxPage';
import MessagePage from './Pages/MessagePage';
import UpdateDashboardMessagePage from './Pages/UpdateDashboardMessagePage';
import UpdateWhatsNewMessagePage from './Pages/UpdateWhatsNewMessagePage';
import FeedbackReportsPage from './Pages/FeedbackReportsPage';
import SendSpecificMessagePage from 'Pages/SendSpecificMessagePage';
import ReportsPage from 'Pages/ReportsPage';
import ReportResultPage from 'Pages/ReportResultPage';
import MatchingReportsPage from 'Pages/MatchingReportsPage';
import ContractingOfficerSinglePage from './Pages/ContractingOfficerSinglePage';
import BusinessReportsPage from './Pages/BusinessReportsPage';
import OpportunityReportsPage from './Pages/OpportunityReportsPage'
import SelfServicePage from 'Pages/SelfServicePage';
import ManualOpportunitySinglePage from './Pages/ManualOpportunitySinglePage'
import GrantSinglePage from './Pages/GrantSinglePage'
import AdminPostsPage from './Pages/AdminPostsPage';
import ExludedEntityPage from './Pages/ExludedEntityPage'
import AwardsDetailsPage from './Pages/AwardsDetailsPage';

const Router = () => {
  const history = useHistory();
  const { setIsLoggedIn, setUser, isLoggedIn, user } = useContext(UserContext);

  const historyPush = (route, next) => {
    history.push(route);
    next.redirect(route);
    next();
  }
    
  const requireExternalId = (to, from, next, User) => {
    if (User.roles[0].external_id === null && User.roles[0].role_description === "business") {
      historyPush("/business-registration", next);
    } else if (User.roles[0].external_id === null && User.roles[0].role_description === "officer") {
      historyPush("/government-registration", next);
    } else if (
      to.location.pathname === '/business-registration' && User.roles[0].external_id ||
      to.location.pathname === '/government-registration' && User.roles[0].external_id
    ) {
      historyPush("/dashboard", next);
    } else if (User.roles[0].external_id === null && User.roles[0].role_description === "admin") {
      next();
    } else {
      next();
    }
  }

  const requireLogin = (to, from, next) => {
    if (isLoggedIn) {
      if (loginRoutes.includes(to.location.pathname)) {
        requireExternalId(to, from, next, user);
        next();
      } else if (to.location.pathname === '/search' || to.location.pathname === '/contact-us') {
        next();
      } else {
        historyPush("/dashboard", next);
      }
    }

    if (!isLoggedIn) {
      userGet()
        .then(response => {
          setIsLoggedIn(true);
          setUser(response.data);
          if (loginRoutes.includes(to.location.pathname)) {
            requireExternalId(to, from, next, response.data);
            if (
              to.location.pathname === '/business-registration' && response.data.roles[0].external_id ||
              to.location.pathname === '/government-registration' && response.data.roles[0].external_id
            ) {
              historyPush("/dashboard", next);
            } else {
              next();
            }
          } else if (to.location.pathname === '/search' || to.location.pathname === '/contact-us') {
            next();
          } else {
            historyPush("/dashboard", next);
          }
        })
        .catch(error => {
          if (loginRoutes.includes(to.location.pathname)) {
            historyPush("/", next);
          } else if (to.location.pathname === '/search') {
            next();
          } else {
            next();
          }
        });
    }
  };

  const invalidPage = (to, from, next) => {
    if (isLoggedIn) {
      historyPush("/dashboard", next);
    } else {
      historyPush("/", next);
    }
  };
  

  return (
    <GuardProvider loading={Loading}>
      <Switch>
        <GuardedRoute
          path="/"
          exact
          component={HomePage}
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/dashboard"
          exact
          component={DashboardPage}
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/search"
          exact
          component={SearchPage}
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/about-us"
          exact
          component={AboutPage}
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/videos"
          exact
          component={VideosPage}
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/sign-in"
          exact
          component={SignInPage}
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/registration"
          exact
          component={RegistrationPage}
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/registration-preview"
          exact
          component={RegistrationPreviewPage}
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/business-registration"
          exact
          component={BusinessRegistrationPage}
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/government-registration"
          exact
          component={GovernmentRegistrationPage}
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/advanced-search"
          exact
          component={AdvancedSearchPage}
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/advanced-search/results"
          exact
          component={AdvansedResultsPage}
          guards={[requireLogin]}
        />
        <GuardedRoute
          component={VerifyPage}
          exact
          path="/user-verification"
          guards={[requireLogin]}
        /> 
        <GuardedRoute
          path="/send-again"
          component={SendAgainPage}
          exact
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/business"
          component={BusinessSinglePage}
          exact
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/opportunity"
          component={OpportunitySinglePage}
          exact
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/edit-profile"
          component={EditProfilePage}
          exact
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/recover-password"
          component={RecoverPasswordPage}
          exact
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/recover-password/set-password"
          component={SetPasswordPage}
          exact
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/set-admin-password"
          component={SetAdminPasswordPage}
          exact
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/post"
          component={PostPage}
          exact
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/purchase"
          component={PurchaseSinglePage}
          exact
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/b2b"
          component={B2BSinglePage}
          exact
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/manual-opportunity"
          component={ManualOpportunitySinglePage}
          exact
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/admin-posts"
          component={AdminPostsPage}
          exact
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/grant"
          component={GrantSinglePage}
          exact
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/future"
          component={FutureOpportunitySinglePage}
          exact
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/users"
          component={UsersPage}
          exact
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/user-feedback"
          exact
          component={FeedbackReportsPage}
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/contact-us"
          exact
          component={ContactUsPage}
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/communication"
          component={CommunicationPage}
          exact
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/inbox"
          component={InboxPage}
          exact
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/message"
          component={MessagePage}
          exact
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/update-dashboard-message"
          component={UpdateDashboardMessagePage}
          exact
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/whats-new-message"
          component={UpdateWhatsNewMessagePage}
          exact
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/specific-message"
          component={SendSpecificMessagePage}
          exact
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/matching-reports"
          component={MatchingReportsPage}
          exact
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/reports"
          component={ReportsPage}
          exact
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/contracting-officer"
          component={ContractingOfficerSinglePage}
          exact
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/report-result"
          component={ReportResultPage}
          exact
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/business-reports"
          component={BusinessReportsPage}
          exact
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/opportunity-reports"
          component={OpportunityReportsPage}
          exact
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/self-service"
          component={SelfServicePage}
          exact
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/excluded-entity"
          component={ExludedEntityPage}
          exact
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="/awards-details"
          component={AwardsDetailsPage}
          exact
          guards={[requireLogin]}
        />
        <GuardedRoute
          path="*"
          guards={[invalidPage]}
          exact
        />
      </Switch>
    </GuardProvider>
  );
}

export default Router;
