import React from "react";
import SelfService from "../../components/SelfService";
import DemoHeader from '../../components/Headers/DemoHeader';
import LogoutFooter from '../../components/Footers/LogoutFooter';
import classes from "./index.module.scss";

const SelfServicePage = () => {
  return (
    <div className={classes.SelfServicePage}>
      <DemoHeader />
      <main>
        <SelfService />
      </main>
      <LogoutFooter />
    </div>
  );
};

export default SelfServicePage;