import React from 'react';
import classes from './index.module.scss';
import { apiUrl } from "../../api/config";

const DownloadXLSXButton = ({ daterange, category }) => {
  const params = new URLSearchParams({ start_date: daterange.start_date, end_date: daterange.end_date, filter: category });

  return (
    <div className={classes.XLSX_Wrapper}>
      <a href={`${apiUrl}/awards/export-xlsx?${params.toString()}`} download className='btnSizes filledBtn'>Download XLSX</a>
    </div>
  )
}

export default DownloadXLSXButton;
