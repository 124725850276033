import React, { useState, useEffect } from 'react'
import Logo from '../Logo';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Dropdown from '../UI/Dropdown';
import { ReactComponent as LogoIcon } from '../../assets/icons/black-logo.svg';
import classes from './index.module.scss';
import { ReactComponent as SearchIcon } from '../../assets/icons/search-input-icon.svg';

const HEADER_SEARCH_DROPDOWN_ITEMS = [
  {
    name: "Contract Opportunities",
    link: "/search?entity=contract_opportunities"
  },
  {
    name: "B2B Opportunities",
    link: "/search?entity=b2b_opportunities"
  },
  {
    name: "Future Opportunities",
    link: "/search?entity=future_opportunities"
  },
  {
    name: "Businesses",
    link: "/search?entity=businesses"
  },
  {
    name: "Find Local Opportunities",
    link: "/search?entity=micro_purchases"
  }
];

const HEADER_REGISTER_DROPDOWN_ITEMS = [
  {
    name: "Business",
    link: "/registration-preview"
  },
  {
    name: "Government Agency",
    link: "/registration?role=officer"
  }
];

const DemoHeader = () => {
  const [showMobileMenuButton, setShowMobileMenuButton] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);


  useEffect(() => {
    if(window.screen.width <= 575) {
      setShowMobileMenuButton(true);
    }
  }, [showMobileMenuButton])

  return (
    <header className={classes.Header}>
      <Container>
        <Row>
          <Col className={classes.Header_Col}>
            <Logo
              path="/self-service"
            >
              <LogoIcon/>
            </Logo>
          </Col>
        </Row>
      </Container>
    </header>
  )
}

export default DemoHeader;