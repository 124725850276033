import React from 'react';
import classes from './index.module.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook.svg';
import { ReactComponent as TwitterIcon } from '../../assets/icons/twitter.svg';
import { ReactComponent as LinkedinIcon } from '../../assets/icons/linkedin.svg';
import { HashLink } from 'react-router-hash-link';

const Footer = () => {
  return (
    <footer className={classes.Footer}>
      <Container>
        <Row className="mb-5">
          {/* <Col lg={7} md={5}>
            <h2 className={classes.Sub_Title}>Are you ready?</h2>
            <h1 className={classes.Title}>
              Let’s get started
            </h1>
            <HashLink className="btnSizes filledBtn px-5 py-3" smooth to="/registration?role=business&gov_relations=Business_Organization">Get Started</HashLink>
          </Col>
          <Col lg={3} md={4}>
            <ul className={classes.Search_Types}>
              <li>
                <Link to="/search?entity=contract_opportunities">
                  Contract Opportinities
                </Link>
              </li>
              <li>
                <Link to="/search?entity=b2b_opportunities">
                  B2B Opportunities
                </Link>
              </li> */}
              {/* <li>
                <Link to="/search?entity=grants">
                  Grants
                </Link>
              </li> */}
              {/* <li>
                <Link to="/search?entity=businesses">
                  Businesses
                </Link>
              </li>
            </ul>
          </Col> */}
          <Col>
            <ul className={classes.Footer_Links_List}>
              <li>
                <Link to="#">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/contact-us">
                  Contact Us
                </Link>
              </li>
              {/* <li>
                <a target="_blank" rel="noreferrer" href="https://govtide.com">GovTide</a>
              </li>
              <li>
                <a target="_blank" rel="noreferrer" href="https://www.ait.com/">AIT, Inc.</a>
              </li> */}
            </ul>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={classes.Bottom_Footer_Inner}>
              <span>Powered by <a href="https://govtide.com">GovTide</a>®. 2023 All Rights Reserved</span>
              {/* <ul className={classes.Social_List}>
                <li className={classes.Social_List_Item}>
                  <a target="_blank" rel="noreferrer" href="#">
                    <FacebookIcon />
                  </a>
                </li>
                <li className={classes.Social_List_Item}>
                  <a target="_blank" rel="noreferrer" href="#">
                    <TwitterIcon />
                  </a>
                </li>
                <li className={classes.Social_List_Item}>
                  <a target="_blank" rel="noreferrer" href="#">
                    <LinkedinIcon />
                  </a>
                </li>
              </ul> */}
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer;