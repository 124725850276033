import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import FrameOne from './FrameOne';
import FrameTwo from './FrameTwo';
import FrameThree from './FrameThree';
import FrameFour from './FrameFour';
import { useHistory } from 'react-router';
import { NAICS_LIST, PSC_LIST } from '../../constans';
import { businessRegistrationPost, userGet } from '../../api';
import { oneSelectNotice } from '../UI/Notifications';
import Loading from '../Loading';
import { UserContext } from '../../context/User';

const GOV_RELATIONS_LIST = [
  { value: "Business_Organization", label: "Business Organization", type: "gov_relations" },
  { value: "Government_Entity", label: "Government Entity", type: "gov_relations" },
  { value: "Contracting_Consultant", label: "Contracting Consultant", type: "gov_relations" },
  { value: "Lobbyist_Group", label: "Lobbyist Group", type: "gov_relations" },
  { value: "Lender_Institution", label: "Lender Institution", type: "gov_relations" },
  { value: "Financial_Investor", label: "Financial Investor", type: "gov_relations" },
  { value: "Individual_Employee", label: "Individual Employee", type: "gov_relations" },
  { value: "Media_Entity", label: "Media Entity", type: "gov_relations" },
  { value: "Elected_Official", label: "Elected Official", type: "gov_relations" },
]

const BusinessRegistration = () => {
  const { setUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [address, setAddress] = useState({})
  const [location, setLocation] = useState({})
  const [inputValue, setInputValue] = useState({
    name: "",
    phone: "",
    url: "",
    uei: "",
    description: "",
    city: "",
    state: "",
    zip: "",
    keywords: "",
    business_type: "",
    gov_relations: "",
    address1: "",
    naics: "",
    location_address: "",
    location_country: "",
    location_state: "",
    location_zip: "",
    geo_fencing_zips: [],
    geo_fencing_locations: [],
  })
  const [selectValue, setSelectValue] = useState([{ value: "Business_Organization", label: "Business Organization", type: "gov_relations" }]);
  const [step, setStep] = useState(1);
  const [filledData, setFilledData] = useState({});
  const [list, setList] = useState([]);
  const [sendRequest, setSendRequest] = useState(false);
  const [searchTrigger, setSearchTrigger] = useState(false);

  useEffect(() => {
    const gov_relations = localStorage.getItem('gov_relations')
    setSelectValue([GOV_RELATIONS_LIST.find(item => item.value === gov_relations)])
  }, [])

  const handleMultiDelete = (name, valuesFunc, valuesValue) => {
    switch (name.action) {
      case 'remove-value':
        valuesFunc([...valuesValue.filter(el => el.value !== name.removedValue.value)]);
        break;
      case 'clear':
        valuesFunc([...valuesValue.filter(el => el.type !== name.name)]);
        break;
      default:  
    }
  }

  const handleMultiAdd = (item, valuesFunc, valuesValue) => {
    switch (item.type) {
      case 'type':
      case 'naics':
      case 'payment_method':
      case 'gov_relations':
      case 'set_aside':
      case 'psc_code':
      case 'business_type':
        valuesFunc([...valuesValue, item]);
        break;
      default:
        valuesFunc([...valuesValue.filter(i => i.type !== item.type), item]);
    }
  }

  const setLimitSelectValues = (data, value, blackList = null) => {
    let i = 0;

    if (blackList === null) blackList = [];

    const newList = data.filter(item => {
      if (item.label.toLowerCase().includes(value.toLowerCase()) && i < 10) {
        if (!blackList.includes(item.value)) i++;
        return item
      }
    })

    setList(newList);
  }

  const onChangeHandler = (value, name) => {
    if (name === 'naics') setLimitSelectValues(NAICS_LIST, value);
    if (name === 'psc_code') setLimitSelectValues(PSC_LIST, value);

    setInputValue({
      ...inputValue,
      [name]: value
    })

    if (!value) setList([]);
  }

  const onChangeSingleValue = (event, name) => {
    setInputValue({
      ...inputValue,
      [name]: event.target.value
    })
  }

  const handleMultiChange = (event, name) => {
    const item = Array.isArray(event) ? event[event.length - 1] : "";
    
    if (name.removedValue || name.removedValues) {
      handleMultiDelete(name, setSelectValue, selectValue); 
    } else if (item) {
      handleMultiAdd(item, setSelectValue, selectValue); 
    } else if (event && event.type) {
      setSelectValue([...selectValue, event]);
    }

    if (event.type === 'gov_relations') {
      const transformedSelectValue = selectValue.filter(item => item.type !== 'gov_relations')
      setSelectValue([...transformedSelectValue, event]);
    }
    
    if (name.name) {
      setInputValue({
        ...inputValue,
        [name.name]: event.value
      })
    }
  }

  const chooseCategoryHandler = selectedOption => {
    setInputValue({
      ...inputValue,
      business_type: selectedOption.value
    })
  }

  const handleFilterUpdate = (zipCodes) => {
    const zipValues = zipCodes.map(zip => zip.zip);
    
    setInputValue({
      ...inputValue,
      geo_fencing_zips: zipValues
    })
  };

  const handleFilterUpdateLocation = (locations) => {
    const locationValues = locations.map(location => ({
      coordinates: {
        lat: location.coordinates.lat.toString(),
        lng: location.coordinates.lng.toString()
      },
      label: location.label,
      placeId: location.value, // use value as placeId
      radius: location.radius,
      value: location.value,
    }));

    setInputValue({
      ...inputValue,
      geo_fencing_locations: locationValues
    })
  };


  useEffect(() => sendRequest && registerBusinessRequest(), [searchTrigger])

  const registerBusinessRequest = () => {
    setLoading(true)
    const params = {
      ...filledData
    };

    for (let prop in params) {
      if (params[prop] === null || params[prop] === undefined || params[prop] === "" || params[prop].length === 0) {
        delete params[prop];
      }

      if (params[prop] === 'false') {
        params[prop] = false
      } else if (params[prop] === 'true') {
        params[prop] = true
      }

      if (params[prop] && prop === "keywords") {
        params[prop] = params[prop].split(',').map(item => item.trim());
      }
    }

    businessRegistrationPost(params) 
    .then(response => {
      setLoading(false);
      userGet()
        .then(response => {
          setUser(response.data);
          setLoading(false);
          history.push('/dashboard');
        })
        .catch(error => {
          setLoading(false);
        })
    })
  }

  const next = () => {
    setStep(step => step + 1)
  }

  const prev = () => {
    setStep(step => step - 1)
  }

  const onSubmit = (data = null, e = null) => {
    const obj = {
      gov_relations: [],
      naics: [],
      business_type: []
    };
    
    selectValue.forEach((item) => {
      if (item.type === 'gov_relations') {
        obj[item.type] = item.label
      } else {
        obj[item.type].push(item.value);
      }
    })

    setFilledData({...filledData, ...inputValue, ...obj, ...address, ...location});
    
    if (step === 2 && inputValue.name && inputValue.phone) {
      setStep(3)
    }
    const bt = selectValue.some(item => item.type === 'business_type')
    if (step === 3 && bt) {
      setStep(4)
    } else if (step === 3 && !bt) {
      oneSelectNotice("You need to choose at least one organization type.");
    }

    if (step === 4) {
      setSendRequest(true);
      setSearchTrigger(!searchTrigger);
    }
  }
  
  // const onChange = e => {
  //   const value = e.target.value;
  //   const name = e.target.name;

  //   setInputValue({
  //     ...inputValue,
  //     [name]: value
  //   })
  // }

  const transformAddress = (place) => {
    const obj = {
      address1: "",
      city: "",
      state: "",
      zip: "",
      country: ""
    }

    const compIsType = (t, s) => { 
      for(let z = 0; z < t.length; ++z) {
        if(t[z] == s) {
          return true;
        }

        return false;
      }
    }

    if (place.address_components !== undefined) {
      let addrComp = place.address_components;
      for(let i = 0; i < addrComp.length; ++i) {
        var typ = addrComp[i].types;

        if(compIsType(typ, 'administrative_area_level_1')) {
          obj.state = addrComp[i].long_name; //store the state
        } else if(compIsType(typ, 'locality')) {
          obj.city = addrComp[i].long_name; //store the city
        } else if(compIsType(typ, 'postal_code')) {
          obj.zip = addrComp[i].long_name; //store the country  
        } else if (compIsType(typ, 'street_number')) {
          obj.address1 = addrComp[i].long_name
        } else if (compIsType(typ, 'route')) {
          obj.address1 += ` ${addrComp[i].long_name}`
        } else if (compIsType(typ, 'country')) {
          obj.country = `${addrComp[i].short_name}`
        }
      }
    }
    setAddress(obj);
  }

  const transformLocation = (place) => {
    const obj = {
      location_address: "",
      location_country: "",
      location_state: "",
      location_zip: "",
      location_city: ""
    }

    const compIsType = (t, s) => { 
      for(let z = 0; z < t.length; ++z) {
        if(t[z] == s) {
          return true;
        }

        return false;
      }
    }


    if (place.address_components !== undefined) {
      let addrComp = place.address_components;
      for(let i = 0; i < addrComp.length; ++i) {
        var typ = addrComp[i].types;

        if(compIsType(typ, 'administrative_area_level_1')) {
          obj.location_state = addrComp[i].long_name; //store the state
        } else if(compIsType(typ, 'country')) {
          obj.location_country = addrComp[i].short_name; //store the city
        } else if(compIsType(typ, 'postal_code')) {
          obj.location_zip = addrComp[i].long_name; //store the country  
        } else if (compIsType(typ, 'street_number')) {
          obj.location_address = addrComp[i].long_name
        } else if (compIsType(typ, 'route')) {
          obj.location_address += ` ${addrComp[i].long_name}`
        } else if (compIsType(typ, 'locality')) {
          obj.location_city = `${addrComp[i].long_name}`
        }
      }
    }
    setLocation(obj);
  }

  return (
    <section className="section-spaces">
      <Container>
        <Row>
          {
            step === 1 && (
              <Col lg={{ span: 8, offset: 2 }}>
                <FrameOne 
                  onSubmit={onSubmit}
                  next={next}
                  setStep={setStep}
                />
              </Col>
            )
          }
          {
            step === 2 && (
              <Col lg={{ span: 8, offset: 2 }}>
                <FrameTwo 
                  prev={prev}
                  setStep={setStep}
                  onSubmit={onSubmit} 
                  inputValue={inputValue}
                  onChangeSingleValue={onChangeSingleValue}
                  transformAddress={transformAddress}
                />
              </Col>
            )
          }
          {
            step === 3 && (
              <Col lg={{ span: 8, offset: 2 }}>
                <FrameThree 
                  prev={prev}
                  setStep={setStep}
                  onSubmit={onSubmit} 
                  transformLocation={transformLocation}
                  selectValue={selectValue}
                  inputValue={inputValue}
                  onChangeSingleValue={onChangeSingleValue}
                  onChangeHandler={onChangeHandler}
                  handleMultiChange={handleMultiChange}
                  handleFilterUpdate={handleFilterUpdate}
                  handleFilterUpdateLocation={handleFilterUpdateLocation}
                  list={ GOV_RELATIONS_LIST }
                />
              </Col>
            )
          }
          {
            step === 4 && (
              <Col lg={{ span: 8, offset: 2 }}>
                <FrameFour
                  list={list}
                  prev={prev}
                  setStep={setStep}
                  onSubmit={onSubmit} 
                  selectValue={selectValue}
                  onChangeHandler={onChangeHandler}
                  inputValue={inputValue}
                  handleMultiChange={handleMultiChange}
                />
              </Col>
            )
          }
          {/* {
            frame === "frame_five" && (
              <Col lg={{ span: 6, offset: 1 }}>
                <FrameFive
                  onSubmit={onSubmit} 
                  inputValue={inputValue}
                  onChangeHandler={onChangeHandler}
                  handleMultiChange={handleMultiChange}
                  selectValue={selectValue}
                  setTerms={setTerms}
                  terms={terms}
                />
              </Col>
            )
          } */}
        </Row>
        {loading && <Loading />}
      </Container>
    </section>
  )
}

export default BusinessRegistration;