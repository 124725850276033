import React from 'react';
import moment from 'moment';
import classes from './index.module.scss';
import { Link } from 'react-router-dom';

const BusinessPostTableBody = ({ list, deletePost }) => {
  return (
    <>
      {
        list.map(item => {
          const { posted_date, name, id } = item;
          return (
            <tr 
              key={id}
            >
              <td className={`${classes.Title_Normal} ${classes.Vertical_Middle}`}>{moment(posted_date).format('YYYY-MM-DD')}</td>
              <td className={`${classes.Title_Normal} ${classes.Vertical_Middle}`}>{name}</td>
              <td className={classes.Vertical_Middle}>
                <Link
                  to={`/b2b?id=${id}`}
                  className="btnSizes filledBtn"
                >
                  View
                </Link>
                <button
                  className="btnSizes transparentBtn ml-4"
                  onClick={() => deletePost(id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          )
        })
      }
    </>
  )
}

export default BusinessPostTableBody;