import React, { useEffect, useState } from 'react';
import { awardsDetailsPost } from '../../api';
import { Container, Row, Col } from 'react-bootstrap';
import classes from '../AwardsDetails/index.module.scss';

const AwardsDetailsContent = ({ solicitaion, handleDefaultStatusCodes, setLoading }) => {
  const [awardsDetails, setAwardsDetails] = useState({});

  useEffect(() => {
    setLoading(true);

    awardsDetailsPost({ sol_no: solicitaion })
      .then(response => {
        setAwardsDetails(response.data[0]);
        setLoading(false);
      })
      .catch(error => {
        handleDefaultStatusCodes(error.response.status);
      })
  }, []);

  return (
    <section>
      <Container>
        <Row>
          <Col className='text-center'>
            <h5 className="mb-4">{ awardsDetails.title || '-' }</h5>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex" lg={3} md={6}>
            <div className={classes.Awards_Details_Wrapper}>
              <h6 className={`${classes.Contact_Title} mb-2`}>City</h6>
              <h4 className={classes.Awards_Entity}>{ (awardsDetails.city_name !== 'null' && awardsDetails.city_name) || <span className={classes.No_Data}>No data</span> }</h4>
            </div>
          </Col>
          <Col className="d-flex" lg={3} md={6}>
            <div className={classes.Awards_Details_Wrapper}>
              <h6 className={`${classes.Contact_Title} mb-2`}>Country</h6>
              <h4 className={classes.Awards_Entity}>{ (awardsDetails.country_name !== 'null' && awardsDetails.country_name ) || <span className={classes.No_Data}>No data</span> }</h4>
            </div>
          </Col>
          <Col className="d-flex" lg={3} md={6}>
            <div className={classes.Awards_Details_Wrapper}>
              <h6 className={`${classes.Contact_Title} mb-2`}>Vendor</h6>
              <h4 className={classes.Awards_Entity}>{ (awardsDetails.business_name !== 'null' && awardsDetails.business_name ) || <span className={classes.No_Data}>No data</span> }</h4>
            </div>
          </Col>
          <Col className="d-flex" lg={3} md={6}>
            <div className={classes.Awards_Details_Wrapper}>
              <h6 className={`${classes.Contact_Title} mb-2`}>Contract Amount</h6>
              <h4 className={classes.Awards_Entity}>{ (awardsDetails.amount !== 'null' && awardsDetails.amount ) || <span className={classes.No_Data}>No data</span> }</h4>
            </div>
          </Col>
          <Col className="d-flex" lg={3} md={6}>
            <div className={classes.Awards_Details_Wrapper}>
              <h6 className={`${classes.Contact_Title} mb-2`}>Solicitation Number</h6>
              <h4 className={classes.Awards_Entity}>{ (awardsDetails.sol_no !== 'null' && awardsDetails.sol_no ) || <span className={classes.No_Data}>No data</span> }</h4>
            </div>
          </Col>
          <Col className="d-flex" lg={3} md={6}>
            <div className={classes.Awards_Details_Wrapper}>
              <h6 className={`${classes.Contact_Title} mb-2`}>Contract Number</h6>
              <h4 className={classes.Awards_Entity}>{ (awardsDetails.contract_no !== 'null' && awardsDetails.contract_no ) || <span className={classes.No_Data}>No data</span> }</h4>
            </div>
          </Col>
          <Col className="d-flex" lg={3} md={6}>
            <div className={classes.Awards_Details_Wrapper}>
              <h6 className={`${classes.Contact_Title} mb-2`}>Source</h6>
              <h4 className={classes.Awards_Entity}>{ (awardsDetails.department !== 'null' && awardsDetails.department ) || <span className={classes.No_Data}>No data</span> }</h4>
            </div>
          </Col>
          <Col className="d-flex" lg={3} md={6}>
            <div className={classes.Awards_Details_Wrapper}>
              <h6 className={`${classes.Contact_Title} mb-2`}>NAICS Code</h6>
              <h4 className={classes.Awards_Entity}>{ (awardsDetails.naics !== 'null' && awardsDetails.naics ) || <span className={classes.No_Data}>No data</span> }</h4>
            </div>
          </Col>
          <Col className="d-flex" lg={3} md={6}>
            <div className={classes.Awards_Details_Wrapper}>
              <h6 className={`${classes.Contact_Title} mb-2`}>DUNS Code</h6>
              <h4 className={classes.Awards_Entity}>{ (awardsDetails.duns !== 'null' && awardsDetails.duns ) || <span className={classes.No_Data}>No data</span> }</h4>
            </div>
          </Col>
          <Col className="d-flex" lg={3} md={6}>
            <div className={classes.Awards_Details_Wrapper}>
              <h6 className={`${classes.Contact_Title} mb-2`}>UEI Code</h6>
              <h4 className={classes.Awards_Entity}>{ (awardsDetails.uei !== 'null' && awardsDetails.uei ) || <span className={classes.No_Data}>No data</span> }</h4>
            </div>
          </Col>
          <Col className="d-flex" lg={3} md={6}>
            <div className={classes.Awards_Details_Wrapper}>
              <h6 className={`${classes.Contact_Title} mb-2`}>Sam Link</h6>
              <h4 className={classes.Awards_Entity}>{ <a href={ awardsDetails.sam_link } target="_blank">Click Here!</a> || <span className={classes.No_Data}>No data</span> }</h4>
            </div>
          </Col>
          <Col className="d-flex" lg={3} md={6}>
            <div className={classes.Awards_Details_Wrapper}>
              <h6 className={`${classes.Contact_Title} mb-2`}>Date</h6>
              <h4 className={classes.Awards_Entity}>{ (awardsDetails.award_date !== 'null' && awardsDetails.award_date ) || <span className={classes.No_Data}>No data</span> }</h4>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default AwardsDetailsContent;