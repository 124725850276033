import React from 'react';

const TextArea = ({ 
  name, 
  register,
  value,
  onChange,
  placeholder,
  info_type,
  check = true,
  required = false,
  className = ""
}) => {
  return (
    <textarea 
      name={name}
      id={name}
      ref={register}
      value={value && value}
      onChange={onChange && ((event) => onChange(event, name, info_type))}
      placeholder={placeholder}
      disabled={!check ? "disabled" : ""}
      required={required}
      className={className}
    ></textarea>
  )
}

export default TextArea;