import React from 'react';
import DateRange from '../UI/DateRange';
import date from 'date-and-time';

const AwardsDateRange = ({ setInputValue, inputValue, filterDateFrom, initialStartDate, initialEndDate }) => {
  const handleTimeCallback = (start, end, name) => {
    const start_date = date.format(start._d, 'YYYY-MM-DD');
    const end_date = date.format(end._d, 'YYYY-MM-DD');

    setInputValue({
      ...inputValue,
      [name]: {
        start_date,
        end_date
      }
    })
  }

  return (
    <>
      <label>From - To</label>
      <DateRange
        handleTimeCallback={handleTimeCallback}
        name="awards_daterange"
        id="awards_daterange"
        wrapStyle="advanced-picker-wrap awards-details"
        cancelCallback = {() => {
          setInputValue({
            ...inputValue,
            awards_daterange: {
              start_date: initialStartDate,
              end_date: initialEndDate
            }
          })
        }}
        isPicked = {()=>Object.keys(inputValue.awards_daterange).length === 0}
        autoUpdateInput={true}
        sDate={filterDateFrom}
      />
    </>
  )
}

export default AwardsDateRange;