import React from 'react'
import { Table } from "react-bootstrap";
import { ReactComponent as DeleteIcon } from '../../../assets/icons/not-check.svg';
import { Link } from 'react-router-dom';
import addClasses from './index.module.scss'
import classes from '../index.module.scss';

const ExludedEntity = ({ title, setAction, setEntity, setInputName, handleShow, tooltip, data, list, setEntityList, entityData, limit, type }) => {
  const deleteEntityHandler = (item, entity) => {
    setAction('delete')
    if (entity === 'NAICS') {
      setEntityList(data.selected_naics.filter(naics => naics !== item))
      setEntity(entity)
    }

    if (entity === 'PSCs') {
      setEntityList(data.selected_pscs.filter(pcs => pcs !== item))
      setEntity(entity)
    }
    handleShow();
  }

  const findValue = (item, list) => {
    const findedValue = list.filter(value => value.value === item)
    const getLabel = findedValue.length ? findedValue[0].label : ""
    const word = ' - ';
    const index = getLabel.indexOf(word);
    const length = word.length;
    const result = getLabel.slice(index + length);

    return result || '-'
  }

  const addEntityHandler = (entity) => {
    setAction('add')
    setEntity(entity)
    if (entity === 'NAICS') setInputName('naics')
    if (entity === 'PSCs') setInputName('psc_code')
    handleShow();
  }

  return (
    <div className={`${type === 'default' && classes.Border_Bottom}`}>
      <div className={`${addClasses.Heading_Wrap} mb-3`}>
        <div className="tooltip-trigger">
          <h6 className="mb-0 Admin_Dashboard_Entity_Sub_Title">Excluded { title }</h6>
          <span className="Tooltip">
            { tooltip }
          </span>
        </div>
        <button
          className="btnSizes filledBtn link"
          onClick={ () => addEntityHandler(title) }
        >
          Add
        </button>
      </div>
      <Table striped responsive className={`${classes.Table} mb-4`}>
        <thead className={classes.Table_Head}>
          <tr>
            <th>Code</th>
            <th>Description</th>
            <th className='text-right'>Action</th>
          </tr>
        </thead>
        <tbody className={classes.Table_Body}>
          {
            Object.keys(data).length && entityData.filter((_, index) => index <= limit - 1).map(item => {
              return (
                <tr key={item}>
                  <td className={`${classes.Title_Normal} ${classes.Vertical_Middle}`}>{item}</td>
                  <td className={`${classes.Title_Normal} ${classes.Vertical_Middle}`}>
                    {
                      findValue(item, list)
                    }
                  </td>
                  <td className={`${classes.Title_Normal} ${classes.Vertical_Middle} text-right`}>
                    {<DeleteIcon 
                      onClick={() => deleteEntityHandler(item, title)}
                      className={classes.Delete_Icon}
                    />}
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </Table>
      { type === 'default' && entityData && entityData.length > 2 && (
        <div className='text-center mb-4'>
          <Link
            to={`/excluded-entity?entity=${title}`}
            className="btnSizes filledBtn"
          >
            Show More
          </Link>
        </div>
      )}
    </div>
  )
}

export default ExludedEntity;