import React, { useState } from 'react';

const ProgressBar = ({ profilePct, onClick }) => {
  const gradient = `linear-gradient(to right, #4CAF50 0%, #4CAF50 ${profilePct}%, #eee ${profilePct}%, #eee 100%)`;

  return (
    <div className="progress-bar" onClick={onClick} style={{ background: gradient }}>
      <div className="progress-bar-inner" style={{ width: `${profilePct}%` }} />
      <div className="progress-bar-label">{`${profilePct}% completed`}</div>
    </div>
  );
};

export default ProgressBar;
