import React, { useRef, useEffect, useState } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar-icon.svg';

const DateRange = ({ handleTimeCallback, name, id, inputStyle, wrapStyle, check = true, cancelCallback, isPicked, infoType, autoUpdateInput = false, sDate }) => {
  const dateRangeRef = useRef();
  const [start, setStart] = useState()
  const [end, setEnd] = useState()

  const handleApply = (event, picker) => {
    picker.element.val(
      picker.startDate.format('MM/DD/YYYY') +
        ' - ' +
        picker.endDate.format('MM/DD/YYYY')
    );
  };

  useEffect(() => {
    if (!check) {
      handleCancel();
    }
  }, [check]);

  const handleCancel = (event, picker) => {
    dateRangeRef.current.ref.value = "";
    dateRangeRef.current.setStartDate(sDate ? sDate : new Date());
    dateRangeRef.current.setEndDate(new Date());
    cancelCallback();
  };

  return (
    <div className={`${wrapStyle} date-range-wrap-general`}>
      <DateRangePicker
        ref={dateRangeRef}
        onApply={(event, picker) => {
          handleApply(event, picker);
          handleTimeCallback(start, end, name, id, infoType)
        }}
        onCallback={(startValue, endValue) => {setStart(startValue); setEnd(endValue)}}
        initialSettings={{
          autoUpdateInput: autoUpdateInput,
          locale: {
            cancelLabel: 'Clear',
          },
          startDate: sDate
        }}
        onCancel={handleCancel}
      >
        <input 
          type="text" 
          name={name}
          placeholder="15/01/2020 - 15/02/2020" 
          className={inputStyle}
          disabled={!check ? "disabled" : ""}
       
        />
      </DateRangePicker>
      <CalendarIcon fill={isPicked() ? "#B6B6B6" : "#233539"} />
    </div>
  )
}

export default DateRange;