import React, { useState, useContext } from "react";
import { myManualGet, manualOpportunityDelete } from '../../api';
import Loading from '../Loading';
import { UserContext } from "../../context/User";
import { useHistory } from 'react-router';
import { somethingWentWrongNotice } from '../UI/Notifications';
import { Container, Row, Col } from "react-bootstrap";
import PostTableContainer from '../Dashboard/PostTableContainer';

const POST_HEADERS = [
  "Date",
  "Name",
  "Type",
  "Agency",
  "Options"
];

const PURCHASE_SELECT_LIST = [
  "name",
  "id",
  "posted_date",
  "agency_name"
];
const AdminPosts = ({ role }) => {
  const [loading, setLoading] = useState(false);
  const [deleteTriggerPurchases, setDeleteTriggerPurchases] = useState(false);

  return (
    <section className="section-spaces">
      <Container>
        <Row>
          <Col>
            <h3 className="mb-5">
              My Postings
            </h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <PostTableContainer 
              setLoading={setLoading}
              setDeleteTrigger={setDeleteTriggerPurchases}
              deleteTrigger={deleteTriggerPurchases}
              postHeaders={POST_HEADERS}
              selectList={PURCHASE_SELECT_LIST}
              requests={{
                delete: manualOpportunityDelete,
                get: myManualGet
              }}
              role={role}
            />
          </Col>
        </Row>
      </Container>
    {loading && <Loading />}
    </section>
  )
}

export default AdminPosts;
