import React from "react";
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Button from '../UI/Button';
import Loading from '../Loading';
import TextInput from '../UI/TextInput';
import PasswordInput from '../UI/PasswordInput';
import EmailInput from "../UI/EmailInput";
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { ReactComponent as TooltipIcon } from '../../assets/icons/tooltip.svg';
import classes from './index.module.scss'

const SignUpForm = ({ onSubmit, loading }) => {
  const { register, handleSubmit, formState: { errors }, watch } = useForm();

  return (
    <>
      <form className={classes.Sign_Up_Form_Wrap} onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <h2 className="text-center">Registration</h2>
            <fieldset>
              <label htmlFor="username">Username <span>*</span></label>
              <div className="tooltip-container">
                <TextInput 
                  name="username"
                  type="text"
                  register={register({ required: true })}
                />
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id="button-tooltip">
                      Username should only contain letters, numbers, underscores or periods. This can be something like the company name, or your full name.
                    </Tooltip>
                  }
                >
                  <TooltipIcon />
                </OverlayTrigger>
              </div>
              {errors.username && (
                <p className="warnMessage">This field is required</p>
              )}
            </fieldset>
          </Col>
        </Row>
        <Row>
          <Col>
            <fieldset>
              <label htmlFor="email">Email address <span>*</span></label>
              <div className="tooltip-container">
                <EmailInput 
                  name="email"
                  type="email"
                  register={register({ required: true })}
                />
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id="button-tooltip">
                      A valid email address: joe@gmail.com  This will be the primary email address for your account.
                    </Tooltip>
                  }
                >
                  <TooltipIcon />
                </OverlayTrigger>
              </div>
              {errors.email && (
                <p className="warnMessage">This field is required</p>
              )}
            </fieldset>
          </Col>
        </Row>
        <Row>
          <Col>
            <fieldset>
              <label htmlFor="password">Password <span>*</span></label>
              <div className="tooltip-container">
                <PasswordInput 
                  regitration={false}
                  name="password"
                  type="password"
                  register={register({ 
                    required: "Password is required", 
                    pattern: {
                      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9])[A-Za-z\d@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*.{8,}$/,
                      message: "Password must contain at least 8 characters, 1 uppercase, 1 lowercase, 1 number, and 1 special character"
                    }
                  })}
                />
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id="button-tooltip">
                      Write your password down for later use.
                    </Tooltip>
                  }
                >
                  <TooltipIcon />
                </OverlayTrigger>
              </div>
              { errors.password && <p className="warnMessage">{errors.password.message}</p> }
            </fieldset>
          </Col>
        </Row>
        <Row>
          <Col>
            <fieldset>
              <label htmlFor="password">Confirm Password <span>*</span></label>
              <div className="tooltip-container">
                <input
                  name="passwordConfirmation"
                  type="password"
                  ref={register({
                    required: "This field is required",
                    validate: (val) => {
                      if (watch('password') != val) {
                        return "Your passwords do no match";
                      }
                    },
                  })}
                />
                {/* <PasswordInput 
                  regitration={false}
                  name="password"
                  type="password"
                  register={register({ 
                    required: "You must specify a password", 
                    minLength: {
                      value: 8,
                      message: "Password must have at least 8 characters"
                    }
                  })}
                /> */}
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id="button-tooltip">
                      Write your password down for later use.
                    </Tooltip>
                  }
                >
                  <TooltipIcon />
                </OverlayTrigger>
              </div>
              {errors.passwordConfirmation && (
                <p className="warnMessage">
                  {errors.passwordConfirmation.message}
                </p>
              )}
            </fieldset>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Button 
              type="submit"
              width="py-3 w-100"
              label="Sign Up"
            />
          </Col>
        </Row>
        <p className={classes.Build}>Build Your Profile & Start Receiving Free Matching Contract, Grant & Investment Opportunities Today!</p>
      </form>
      {loading && <Loading />}
    </>
  );
};

export default SignUpForm;