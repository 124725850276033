import React, { useState } from "react";
import Loading from '../Loading';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from "react-bootstrap";
import TabBar from '../TabBar';
import MatchesTableContainer from "./MatchesTableContainer";
import PostTableContainer from './PostTableContainer';
import { myContractOppGet, contractOppDelete } from '../../api';
import DashboardBanner from "components/UpdateDashboardMessage/DashboardBanner";

const OFFICER_DASHBOARD_TAB_BAR_ITEMS = [
  {
    label: "My Micro Purchase Postings",
    value: "officer_postings"
  }
];

const POST_HEADERS = [
  "Date",
  "Micro Purchase Name",
  "Type",
  "Agency",
  "Options"
];

const BUSINESS_SELECT_LIST = [
  "name",
  "id",
  "posted_date",
  "agency_name"
];

const OfficerDashboard = ({ role }) => {
  const [active, setActive] = useState("officer_postings");
  const [loading, setLoading] = useState(false);
  const [deleteTriggerPurchases, setDeleteTriggerPurchases] = useState(false);
  const [deleteTriggerMatches, setDeleteTriggerMatches] = useState(false);
  
  return (
    <section className="section-spaces">
      <Container>
        <Row>
          <Col>
            <h3 className="mb-5">
              Contracting Officer Dashboard
            </h3>
            <DashboardBanner />
          </Col>
        </Row>
        <Row>
          <Col>
            <TabBar
              active={active}
              setActive={setActive}
              tabItems={OFFICER_DASHBOARD_TAB_BAR_ITEMS}
            />
          </Col>
        </Row>
        {active === 'officer_postings' && (
          <>
            <Row>
              <Col>
                <Link
                  to="/post"
                  className="btnSizes filledBtn mb-5"
                >
                  + Post Micro Purchase
                </Link>
              </Col>
            </Row>
            <Row>
              <Col>
                <PostTableContainer 
                  setLoading={setLoading}
                  setDeleteTrigger={setDeleteTriggerPurchases}
                  deleteTrigger={deleteTriggerPurchases}
                  postHeaders={POST_HEADERS}
                  selectList={BUSINESS_SELECT_LIST}
                  requests={{
                    delete: contractOppDelete,
                    get: myContractOppGet
                  }}
                  role={role}
                />
              </Col>
            </Row>
          </>
        )}
        {active === 'business_matches' && (
          <Row>
            <Col>
              {/* <MatchesTableContainer 
                setLoading={setLoading}
                setDeleteTrigger={setDeleteTriggerMatches}
                deleteTrigger={deleteTriggerMatches}
                selectList={OPP_SELECT_LIST}
                matchesHeaders={OPPORTUNITY_MATCHES}
                requests={{
                  delete: contractOppDelete,
                  get: myContractOppGet
                }}
                role={role}
              /> */}
            </Col>
          </Row>
        )}
      </Container>
    {loading && <Loading />}
    </section>
  )
}

export default OfficerDashboard;
