import React, { useState, useEffect, useRef } from "react";
import MultiSelect from "../UI/MultiSelect";
import { Loader } from "@googlemaps/js-api-loader";

const LocationAutocomplete = ({
  apiKey,
  onLocationsChange,
  map,
  onFilterUpdateLocation,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [radiusValues, setRadiusValues] = useState({});

  const circles = useRef([]);

  useEffect(() => {
    const loader = new Loader(apiKey, {
      libraries: ["places"],
    });

    loader.load().then((google) => {
      const autoComplete = new google.maps.places.AutocompleteService();

      if (inputValue) {
        autoComplete.getPlacePredictions(
          {
            input: inputValue,
            types: ["(cities)"],
          },
          (predictions) => {
            if (predictions) {
              const newOptions = predictions.map((prediction) => ({
                value: prediction.place_id,
                label: prediction.description,
                placeId: prediction.place_id,
              }));
              setOptions(newOptions);
            }
          }
        );
      }
    });
  }, [inputValue, apiKey]);

  useEffect(() => {
    onFilterUpdateLocation(selectedLocations);
  }, [selectedLocations]);

  const handleInputChange = (input) => {
    setInputValue(input);
  };

  const handleRadiusChange = (event, placeId) => {
    const milesToMeters = (miles) => miles * 1609.34;
    const radiusInMiles = parseInt(event.target.value);

    setRadiusValues({
      ...radiusValues,
      [placeId]: radiusInMiles,
    });

    setSelectedLocations((prevState) =>
      prevState.map((location) =>
        location.placeId === placeId
          ? { ...location, radius: radiusInMiles }
          : location
      )
    );

    if (!map) {
      return;
    }

    const circle = circles.current.find((circle) => circle.placeId === placeId);

    if (circle) {
      circle.setRadius(milesToMeters(radiusInMiles));
    } else {
      const location = selectedLocations.find(
        (location) => location.placeId === placeId
      );

      if (location) {
        const newCircle = new window.google.maps.Circle({
          center: location.coordinates,
          radius: milesToMeters(radiusInMiles),
          map: map,
          placeId: placeId,
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
          clickable: false,
          draggable: false,
          editable: false,
          visible: true,
          zIndex: 1,
        });

        circles.current.push(newCircle);
      }
    }
  };

  const handleMultiChange = async (selected) => {
    const loader = new Loader(apiKey, {
      libraries: ["places"],
    });

    const google = await loader.load();
    const geocoder = new google.maps.Geocoder();

    const fetchCoordinates = async (placeId) => {
      return new Promise((resolve, reject) => {
        geocoder.geocode({ placeId }, (results, status) => {
          if (status === "OK") {
            const lat = results[0].geometry.location.lat();
            const lng = results[0].geometry.location.lng();
            resolve({ lat, lng });
          } else {
            reject(
              `Geocode was not successful for the following reason: ${status}`
            );
          }
        });
      });
    };

    const newSelectedLocations = [];

    for (const location of selected) {
      try {
        const coordinates = await fetchCoordinates(location.placeId);
        const radius = radiusValues[location.placeId] || 1;
        const newLocation = {
          ...location,
          coordinates,
          radius,
        };
        newSelectedLocations.push(newLocation);

        handleRadiusChange({ target: { value: radius } }, location.placeId);
      } catch (error) {
        console.error(error);
      }
    }

    setSelectedLocations(newSelectedLocations);
    onLocationsChange(newSelectedLocations);
  };

  return (
    <>
      <MultiSelect
        list={options}
        handleMultiChange={handleMultiChange}
        onChangeHandler={handleInputChange}
        defaultValue={selectedLocations}
        styles="react-select-container"
        prefixStyles="react-select"
        indicator={false}
        placeholder="Search for a location"
      />
      {selectedLocations.map((location) => (
        <div key={location.placeId} className="radius-slider">
          <label style={{ marginRight: "10px" }}>{location.label}</label>
          <input
            type="range"
            min="1"
            max="50"
            value={radiusValues[location.placeId] || 1}
            onChange={(event) => handleRadiusChange(event, location.placeId)}
            className="slider"
          />
          <label>{radiusValues[location.placeId] || 1} mi</label>
        </div>
      ))}
    </>
  );
};

export default LocationAutocomplete;
