import React, { useEffect, useState, useContext } from 'react';
import { Table } from 'react-bootstrap';
import classes from './index.module.scss';
import MatchesTableHeader from './MatchesTableHeader';
import MatchesTableBody from './MatchesTableBody';
import { Row, Col } from 'react-bootstrap';
import { UserContext } from '../../context/User';
import { useHistory } from 'react-router';
import { somethingWentWrongNotice } from '../UI/Notifications';
import { SearchContext } from '../../context/Search';
import Pagination from '../Pagination';
import PageSwitcher from '../PageSwitcher';

const INITIAL_CHECK = {
  contract_opportunities: [],
  purchases: [],
  opportunities: [],
  manual_opportunities: [],
  grants: []
}

const MatchesTableContainer = ({ setLoading, setDeleteTrigger, deleteTrigger, matchesHeaders, requests }) => {
  const [offset, setOffset] = useState(0);
  const [matches, setMatches] = useState([]);
  const [isCheck, setIsCheck] = useState(INITIAL_CHECK);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const { setUser, setIsLoggedIn } = useContext(UserContext);
  const history = useHistory();
  const [totalCount, setTotalCount] = useState(0);
  const [forcePage, setForcePage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const limit = 10;

  const logout = () => {
    setIsLoggedIn(false);
    setUser({});
    history.push('/');
  }

  const handleDefaultStatusCodes = (status_code) => {
    if (status_code === 440 || status_code === 401) {
      logout()
    } else {
      somethingWentWrongNotice("Dashboard");
    }
    setLoading(false);
  }

  const noResults = () => {
    setTotalCount(0);
    setMatches([]);
    setLoading(false);
  }

  const getMatches = () => {
    setLoading(true);
    requests.get({
      offset: offset,
      limit: limit
    })
      .then(response => {
        setMatches(response.data.results);
        setTotalCount(response.data.total_count);
        setLoading(false);
      })
      .catch(error => {
        error.response.status === 404 ?
        noResults() :
        handleDefaultStatusCodes();
      })
  }

  useEffect(() => {
    getMatches()
  }, [offset, deleteTrigger])

  const checkAllHandler = () => {
    let checkObj = {
      contract_opportunities: [],
      purchases: [],
      opportunities: []
    };
    setIsCheckAll(!isCheckAll);
    
    if (isCheckAll) {
      setIsCheck(INITIAL_CHECK);
    } else {
      matches.map(item => {
        checkObj = {
          ...checkObj,
          [item.entity]: [...checkObj[item.entity] ,item.reference]
        }
      })
      setIsCheck(checkObj);
    }
  }

  const handleClick = (e, entity) => {
    const { id, checked } = e.target;

    if (!checked) {
      setIsCheck({
        ...isCheck,
        [entity]: isCheck[entity].filter(item => item !== id)
      });
    } else {
      setIsCheck({
        ...isCheck,
        [entity]: [...isCheck[entity], id]
      });
    }
  }; 

  const deleteOppMatches = () => {
    setLoading(true);

    for (let prop in isCheck) {
      if( isCheck.hasOwnProperty(prop)) {
        if (isCheck[prop].length) {
          requests.delete(
            {reference: isCheck[prop]},
            prop
          )
            .then(response => {
              getMatches();
              setIsCheckAll(false);
              setIsCheck(INITIAL_CHECK);
              setDeleteTrigger(!deleteTrigger);
            })
            .catch(error => {
      
            })
        }
      }
    }
  }

  return (
    <>
      {!totalCount && (
        <Row>
          <Col>
            <h3>
              No results
            </h3>
          </Col>
        </Row>
      )}
      {!!totalCount && (
        <div className={classes.Table_Wrapper}>
        {Object.keys(isCheck).some(key => Array.isArray(isCheck[key]) && isCheck[key].length !== 0) && (
          <Row>
            <Col>
              <button
                className="btnSizes transparentBtn mb-5"
                onClick={deleteOppMatches}
              >
                X  Delete Selected
              </button>
            </Col>
          </Row>
        )}
        <Table striped responsive className={classes.Table}>
          <thead className={classes.Table_Head}>
            <MatchesTableHeader 
              list={matchesHeaders}
              dashboard={true}
              checkAllHandler={checkAllHandler}
              isCheckAll={isCheckAll}
            />
          </thead>
          <tbody className={classes.Table_Body}>
            <MatchesTableBody 
              list={matches}
              dashboard={true}
              handleClick={handleClick}
              isCheck={isCheck}
            /> 
          </tbody>
        </Table>
        <SearchContext.Provider value={{offset, setOffset, totalCount, limit, setForcePage, forcePage, setPageCount, pageCount}}>
          {matches && !!matches.length && (
            <Row>
              <Col>
                <div className="pagination-wrap">
                  <Pagination />
                  <PageSwitcher />
                </div>
              </Col>
            </Row>
          )}
        </SearchContext.Provider>
      </div>
      )}
    </>
  )
}

export default MatchesTableContainer;