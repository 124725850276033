import React, { useContext } from "react";
import LoginFooter from '../../components/Footers/LoginFooter';
import AdminHeader from '../../components/Headers/AdminHeader';
import AwardsDetails from "../../components/AwardsDetails";
import { UserContext } from '../../context/User';

const AwardsDetailsPage = () => {
  const { user } = useContext(UserContext);
  const role = user && user.roles && user.roles[0].role_description;

  return (
    <>  
      {role === "admin" && <AdminHeader />}
      <main>
        {role === "admin" && <AwardsDetails />}
      </main>
      <LoginFooter />
    </>
  );
}

export default AwardsDetailsPage;