import React, { useState, useContext } from "react";
import { myPurchaseGet, oppMatchesGet, userGet, purchaseDelete, oppMatchesDelete } from '../../api';
import Loading from '../Loading';
import { UserContext } from "../../context/User";
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { somethingWentWrongNotice } from '../UI/Notifications';
import { Container, Row, Col } from "react-bootstrap";
import InfoBoard from "../InfoBoard";
import TabBar from '../TabBar';
import MatchesTableContainer from "./MatchesTableContainer";
import PostTableContainer from './PostTableContainer';
import InfoBoardItem from "../InfoBoard/InfoBoardItem";
import DashboardBanner from "components/UpdateDashboardMessage/DashboardBanner";

const BUSINESS_DASHBOARD_TAB_BAR_ITEMS = [
  {
    label: "My Opportunity Matches",
    value: "opp_matches"
  },
  {
    label: "My B2B Opportunity Postings",
    value: "business_postings"
  }
];

const POST_HEADERS = [
  "Date",
  "Title",
  "Options"
];

const PURCHASE_SELECT_LIST = [
  "name",
  "id",
  "posted_date"
];

const OPPORTUNITY_MATCHES = [
  "Match Date",
  "Opportunity Date",
  "Solicitation Number",
  "Opportunity Name",
  "Options"
];

const OPP_SELECT_LIST = [
  'posted_date',
  'sol_no',
  'title',
  'id'
];

const BusinessDashboard = ({ role }) => {
  const { setUser, setIsLoggedIn } = useContext(UserContext);
  const [active, setActive] = useState('opp_matches');
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [deleteTriggerPurchases, setDeleteTriggerPurchases] = useState(false);
  const [deleteTriggerMatches, setDeleteTriggerMatches] = useState(false);
  
  const logout = () => {
    setIsLoggedIn(false);
    setUser({});
    history.push('/');
  }

  const handleDefaultStatusCodes = (status_code) => {
    if (status_code === 440 || status_code === 401) {
      logout()
    } else {
      somethingWentWrongNotice("Dashboard");
    }
    setLoading(false);
  }

  const getPurchases = setCounter => {
    setLoading(true);
    myPurchaseGet({
      select: ["id"],
      limit: 1
    })
    .then(response => {
      setCounter(response.data.total_count);
      setLoading(false);
    })
    .catch(error => {
      if (error.response.status !== 404) {
        handleDefaultStatusCodes(error.response.status);
      }
      setCounter(0);
      setLoading(false); 
    })
  }

  const getMatches = setCounter => {
    setLoading(true);
    oppMatchesGet({
      limit: 1
    })
    .then(response => {
      setCounter(response.data.total_count);
      setLoading(false);
    })
    .catch(error => {
      if (error.response.status !== 404) {
        handleDefaultStatusCodes(error.response.status);
      }
      setCounter(0);
      setLoading(false); 
    })
  }

  const getViewCounted = setCounter => {
    setLoading(true);
    userGet()
      .then(response => {
        setCounter(response.data.views_count);
        setLoading(false);
      })
      .catch(error => {
        if (error.response.status !== 404) {
          handleDefaultStatusCodes(error.response.status);
        }
        setCounter(0);
        setLoading(false); 
      })
  }

  return (
    <section className="section-spaces">
      <Container>
        <Row>
          <Col>
            <h3 className="mb-5">
              Business Dashboard
            </h3>
            <DashboardBanner />
          </Col>
        </Row>
        <Row>
          <Col>
            <InfoBoard>
              <InfoBoardItem request={getViewCounted} header="Profile Views" />
              <InfoBoardItem request={getMatches} header="My Opportunity Matches" deleteTrigger={deleteTriggerMatches} />
              <InfoBoardItem request={getPurchases} header="My B2B Opportunity Posting" deleteTrigger={deleteTriggerPurchases} />
            </InfoBoard>
          </Col>
        </Row>
        <Row>
          <Col>
            <TabBar
              active={active}
              setActive={setActive}
              tabItems={BUSINESS_DASHBOARD_TAB_BAR_ITEMS}
            />
          </Col>
        </Row>
        {active === 'business_postings' && (
          <>
          <Row>
            <Col>
              <Link
                to="/post"
                className="btnSizes filledBtn mb-5"
              >
                + Post B2B Opportunity
              </Link>
            </Col>
          </Row>
          <Row>
            <Col>
              <PostTableContainer 
                setLoading={setLoading}
                setDeleteTrigger={setDeleteTriggerPurchases}
                deleteTrigger={deleteTriggerPurchases}
                postHeaders={POST_HEADERS}
                selectList={PURCHASE_SELECT_LIST}
                requests={{
                  delete: purchaseDelete,
                  get: myPurchaseGet
                }}
                role={role}
              />
            </Col>
          </Row>
        </>
        )}
        {active === 'opp_matches' && (
          <Row>
            <Col>
              <MatchesTableContainer 
                setLoading={setLoading}
                setDeleteTrigger={setDeleteTriggerMatches}
                deleteTrigger={deleteTriggerMatches}
                selectList={OPP_SELECT_LIST}
                matchesHeaders={OPPORTUNITY_MATCHES}
                requests={{
                  delete: oppMatchesDelete,
                  get: oppMatchesGet
                }}
              />
            </Col>
          </Row>
        )}
      </Container>
    {loading && <Loading />}
    </section>
  )
}

export default BusinessDashboard;
