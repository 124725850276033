import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from '../../context/User';
import { useLocation, useHistory } from 'react-router-dom';
import { postVerify } from '../../api';
import Loading from '../../components/Loading';
import { successEmailVerificationNotice, somethingWentWrongNotice, wrongLinkNotice } from '../../components/UI/Notifications';

const VerifyPage = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const { setUser, setIsLoggedIn } = useContext(UserContext);

  const login = (responseData) => {
    setIsLoggedIn(true);
    setUser(responseData);
    history.push("/dashboard");
  }

  useEffect(() => {
    setLoading(true);
    const queryParams = {};
    let local_gov_relations;

    query.forEach((value, key) => {
      if (key !== 'gov_relations') {
        queryParams[key] = value;
      } else {
        local_gov_relations = value
      }
    });

    postVerify(queryParams)
      .then(response => {
        localStorage.setItem('gov_relations', local_gov_relations)
        successEmailVerificationNotice();
        setLoading(false);
        login(response.data);
      })
      .catch(error => {
        if (error.response.status === 400) {
          setLoading(false);
          history.push("/registration");
          wrongLinkNotice();
        } else {
          setLoading(false);
          somethingWentWrongNotice("Email Verification");
        }
      })
  }, [])

  return (
    <>
      {loading && <Loading />}
    </>
  );
}

export default VerifyPage;
