// @ts-nocheck
import React from 'react';
import classes from './index.module.scss';

const SampleAdminDashboard = ({ data = {}, numberWithCommas }) => {
  const {
    sam_opps, 
    awards, 
    awards_value,
    contract_opps,
    micro_purchases,
    manual_opps,
    b2b_awards,
    b2b_amount,
    local_awards,
    local_amount,
    admin_awards,
    admin_amount,
    jobs_created,
  } = data;
  
  return (
    <div className={`${classes.Wrapper} mb-5`}>
      <div className={classes.Item1}> 
        <h3 className={classes.Value}>{ sam_opps || '0' }</h3>
        <h2 className={classes.Title}>G2B Opportunities</h2>
        <span className={classes.Tooltip}>
          The number of G2B contract & grant opportunities that match registered users for this billing period.
        </span>
      </div>
      <div className={classes.Item2}> 
        <h3 className={classes.Value}>{ awards || '0' }</h3>
        <h2 className={classes.Title}>G2B Awards</h2>
        <span className={classes.Tooltip}>The number of G2B Contracts & Grants awarded to Registered Users.</span>
      </div>
      <div className={classes.Item3}> 
        <h3 className={classes.Value}>{`$${numberWithCommas(Math.floor(awards_value) || 0)}`}</h3>
        <h2 className={classes.Title}>G2B Award Amount</h2>
        <span className={classes.Tooltip}>The G2B dollar contract amount awarded to Registered Users.</span>
      </div>
      <div className={classes.Item4}> 
        <h3 className={classes.Value}>{ contract_opps || '0' }</h3>
        <h2 className={classes.Title}>B2B Opportunities</h2>
        <span className={classes.Tooltip}>
          Total number of B2B contract & grant opportunities.
        </span>
      </div>
      <div className={classes.Item5}> 
        <h3 className={classes.Value}>{ b2b_awards || '0' }</h3>
        <h2 className={classes.Title}>B2B Interactions</h2>
        <span className={classes.Tooltip}>
          The number of communications initiatives between Registered Users regarding contract & grant opportunities.
        </span>
      </div>
      <div className={classes.Item6}> 
        <h3 className={classes.Value}>{`$${numberWithCommas(Math.floor(b2b_amount) || 0)}`}</h3>
        <h2 className={classes.Title}>B2B Award Amount</h2>
        <span className={classes.Tooltip}>
          Coming Soon with E-commerce capability.
        </span>
      </div>
      <div className={classes.Item7}> 
        <h3 className={classes.Value}>{ micro_purchases || '0' }</h3>
        <h2 className={classes.Title}>Local Opportunities</h2>
        <span className={classes.Tooltip}>
          Non GSA Contracting Opportunities posted by local contracting officers to the Instance.
        </span>
      </div>
      <div className={classes.Item8}> 
        <h3 className={classes.Value}>{ local_awards || '0' }</h3>
        <h2 className={classes.Title}>Local Awards</h2>
        <span className={classes.Tooltip}>
          Non GSA Contracting Awards posted by Local Contracting Officers to this instance.
        </span>
      </div>
      <div className={classes.Item9}> 
        <h3 className={classes.Value}>{`$${numberWithCommas(Math.floor(local_amount) || 0)}`}</h3>
        <h2 className={classes.Title}>Local Award Amount</h2>
        <span className={classes.Tooltip}>
          Coming Soon with E-commerce capability.
        </span>
      </div>
      <div className={classes.Item10}> 
        <h3 className={classes.Value}>{ manual_opps || '0' }</h3>
        <h2 className={classes.Title}>Admin Opportunities</h2>
        <span className={classes.Tooltip}>
          Opportunities manually inputted into platform by the Admin User to generate economic opportunities.
        </span>
      </div> 
      <div className={classes.Item11}> 
        <h3 className={classes.Value}>{ admin_awards || '0' }</h3>
        <h2 className={classes.Title}>Admin Awards</h2>
        <span className={classes.Tooltip}>
          Awards manually posted by the Admin User.
        </span>
      </div>
      <div className={classes.Item12}> 
        <h3 className={classes.Value}>{`$${numberWithCommas(Math.floor(admin_amount) || 0)}`}</h3>
        <h2 className={classes.Title}>Admin Award Amount</h2>
        <span className={classes.Tooltip}>
          Coming Soon with E-commerce capability.
        </span>
      </div>
      <div className={classes.Item13}> 
        <h3 className={classes.Value}>{ sam_opps + contract_opps + micro_purchases + manual_opps }</h3>
        <h2 className={classes.Title}>Total Opportunities</h2>
        <span className={classes.Tooltip}>
          Total number of opportunites.
        </span>
      </div>
      <div className={classes.Item14}> 
        <h3 className={classes.Value}>{ awards + b2b_awards + local_awards + admin_awards || '0' }</h3>
        <h2 className={classes.Title}>Total Awards</h2>
        <span className={classes.Tooltip}>
          Total number of Awards.
        </span>
      </div>
      <div className={classes.Item15}> 
        <h3 className={classes.Value}>{`$${numberWithCommas(Math.floor(awards_value) + Math.floor(b2b_amount) + Math.floor(local_amount) + Math.floor(admin_amount) || 0)}`}</h3>
        <h2 className={classes.Title}>Total Amount Awarded</h2>
        <span className={classes.Tooltip}>
          The dollar amount awarded to Registered Users.
        </span>
      </div>
      <div className={classes.Item16}> 
        <h3 className={classes.Value}>{jobs_created}</h3>
        <h2 className={classes.Title}>Total Jobs Created</h2>
        {/* <span className={classes.Tooltip}>
          The dollar amount awarded to Registered Users.
        </span> */}
      </div>
    </div>
  )
}

export default SampleAdminDashboard;