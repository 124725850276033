import React, { useEffect, useState } from 'react';
import MultiSelect from '../../UI/MultiSelect';
import { SETASIDE_CODE_FILTER_LIST } from 'constans'

const EXCLUDED_MATCHING_SOURCES_TEMPLATE = {
  "b2b": {
    value: "b2b",
    label: "B2B",
    type: "filters_set_aside"
  },
  "g2b": {
    value: "g2b",
    label: "G2B",
    type: "filters_set_aside"
  },
  "local": {
    value: "local",
    label: "Local",
    type: "filters_set_aside"
  },
  "admin": {
    value: "admin",
    label: "Admin",
    type: "filters_set_aside"
  },
  "grants": {
    value: "grants",
    label: "Grants",
    type: "filters_set_aside"
  }
}

const DEFAULT_INPUT_VALUES = {
  filters_set_aside: ""
}

const SetAsideFilter = ({ data = [], setasideFilterSelectValue, setSetasideFilteExludedSelectValue, handleSubmit, onSetasideFilterSubmit }) => {
  const [inputValue, setInputValue] = useState(DEFAULT_INPUT_VALUES);

  useEffect(() => {
    
  }, [])

  const gerCurrentSelectValue = ({ data }) => {
    return data.map(item => {
      return EXCLUDED_MATCHING_SOURCES_TEMPLATE[item]
    })
  }

  const handleMultiDelete = (name, valuesFunc, valuesValue) => {
    switch (name.action) {
      case 'remove-value':
        valuesFunc([...valuesValue.filter(el => el.value !== name.removedValue.value)]);
        break;
      case 'clear':
        valuesFunc([...valuesValue.filter(el => el.type !== name.name)]);
        break;
      default:
    }
  }

  const handleMultiAdd = (item, valuesFunc, valuesValue) => {
    switch (item.type) {
      case 'filters_set_aside':
        valuesFunc([...valuesValue, item]);
        break;
      default:
        valuesFunc([...valuesValue.filter(i => i.type !== item.type), item]);
    }
  }

  const onChangeHandler = (value, name, action) => {
    if (action && action.action === "input-change") {
      setInputValue({ ...inputValue, [name]: value });
    }
  }

  const handleMultiChange = (event, name) => {
    const item = Array.isArray(event) ? event[event.length - 1] : "";

    if (name.removedValue || name.removedValues) {
      handleMultiDelete(name, setSetasideFilteExludedSelectValue, setasideFilterSelectValue);
    } else if (item) {
      handleMultiAdd(item, setSetasideFilteExludedSelectValue, setasideFilterSelectValue);
    } else if (event && event.type) {
      setSetasideFilteExludedSelectValue([...setasideFilterSelectValue, event]);
    }
  }

  return (
    <div className="Admin_Dashboard_Entity_Wrapper">
      <h2 className="Admin_Dashboard_Entity_Title">Filter by Set-Aside</h2>
      <form onSubmit={handleSubmit(onSetasideFilterSubmit)}>
        <MultiSelect 
          onChangeHandler={onChangeHandler}
          handleMultiChange={handleMultiChange}
          name="filters_set_aside"
          list={SETASIDE_CODE_FILTER_LIST}
          selectValue={setasideFilterSelectValue}
          styles="react-select-container w-100"
          prefixStyles="react-select"
          indicator={false}
        />
        <div className='text-center mb-3'>
          <button
            type="submit"
            className='btnSizes filledBtn'
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  )
}

export default SetAsideFilter;