import React from 'react';
import { Dropdown } from 'react-bootstrap';
import classes from './index.module.scss'

const AwardsColumns = ({ selectedColums, handleColumnChange }) => {
  return (
    <>
      <label>Columns</label>
      <Dropdown className='w-100'>
        <Dropdown.Toggle id="dropdown-basic" className={classes.ColumnDropdown}>
          Columns
        </Dropdown.Toggle>

        <Dropdown.Menu className='px-0 py-0'>
          {selectedColums.map(item => {
            return (
              <div key={ item.name } className="Checkbox_Wrap px-2 py-2">
                <input
                  type="checkbox"
                  name={item.name}
                  id={item.name} 
                  checked={item.status}
                  onClick={() => handleColumnChange(item)}
                />
                <label htmlFor={item.name}>{ item.name }</label>
              </div>
            )
          })}
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}

export default AwardsColumns;