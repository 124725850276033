import React, { useContext, useEffect } from "react";
import LoginFooter from '../../components/Footers/LoginFooter';
import AdminHeader from '../../components/Headers/AdminHeader';
import AdminPosts from '../../components/AdminPosts';
import { useHistory } from "react-router";
import { UserContext } from '../../context/User';

const AdminPostsPage = () => {
  const { user } = useContext(UserContext);
  const role = user && user.roles && user.roles[0].role_description;
  const history = useHistory();

  useEffect(() => {
    if (role !== "admin") {
      history.push("/dashboard");
    }
  }, []);

  return (
    <>  
      <AdminHeader />
      <main>
        {role === "admin" && <AdminPosts role={ role } />}
      </main>
      <LoginFooter />
    </>
  );
}

export default AdminPostsPage;