import { assertTSExpressionWithTypeArguments } from "@babel/types";
import axios from "axios";
import { apiUrl } from "./config";
var qs = require('qs');

axios.defaults.withCredentials = true;
axios.defaults.headers["Content-Type"] = 'application/json';

export const FBOSearchPost = (params, data, headers) => {
  return axios.post(
    apiUrl + '/opportunities/search',
    data,
    {
      params: params, paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
      headers
    }
  )
}

export const contractOpportunititesPost = (params, data, headers) => {
  return axios.post(
    apiUrl + '/contract_opportunity/search',
    data,
    {
      params: params, paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
      headers
    }
  )
}

export const futureOpportunitiesSearchPost = params => {
  return axios.post(
    apiUrl + '/future_opportunities/search',
    {},
    {
      params: params, paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
  )
}

export const futureOpportunitiesGet = params => {
  return axios.get(
    apiUrl + '/future_opportunities',
    {
      params: params, paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
  )
}

export const businessSearchGet = params => {
  return axios.get(
    apiUrl + '/business/search',
    {
      params: params, paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
  )
}

export const businessSearchPost = (params, data, headers) => {
  return axios.post(
    apiUrl + '/business/search',
    data,
    {
      params: params, paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
      headers
    }
  )
}

export const contactUsPost = data => {
  return axios.post(
    apiUrl + '/support',
    data,
  )
}

export const contactUsAdminPost = data => {
  return axios.post(
    apiUrl + '/admin-support',
    data,
  )
}

export const register = (data) => {
  return axios.post(
    apiUrl + "/register", 
    data
  )
};

export const getVerify = (params) => {
  return axios.get(
    apiUrl + "/user/verify", 
    { params: params }
  )
};

export const logoutGet = () => {
  return axios.get(
    apiUrl + "/logout"
  )
};

export const loginPost = (data) => {
  return axios.post(
    apiUrl + "/login",
    data
  )
};

export const postVerify = (data) => {
  return axios.post(
    apiUrl + "/user/verify",
    data
  )
};

export const recoverPassGet = (params) => {
  return axios.get(
    apiUrl + "/recover_password", 
    { params: params }
  )
};

export const recoverPassPost = (data) => {
  return axios.post(
    apiUrl + "/recover_password", 
    data
  )
};

export const userGet = () => {
  return axios.get(
    apiUrl + "/user", 
    { withCredentials: true }
  )
};

export const userPost = (data) => {
  return axios.post(
    apiUrl + "/user", 
    data
  )
};

export const verifyBusinessGet = (params) => {
  return axios.get(
    apiUrl + "/verify_business", 
    { params: params }
  )
};

export const businessRegistrationPost = data => {
  return axios.post(
    apiUrl + "/register/business",
    data
  )
};

export const profileGet = (params) => {
  return axios.get(
    apiUrl + "/profile", 
    { params: params }
  )
};

export const profilePost = (params) => {
  return axios.post(
    apiUrl + "/user", 
    { params: params }
  )
};

export const businessPut = (data) => {
  return axios.put(
    apiUrl + "/business", 
    data
  )
};

export const businessGet = (params) => {
  return axios.get(
    apiUrl + "/business", 
    { params: params }
  )
};

export const officerPut = (data) => {
  return axios.put(
    apiUrl + "/contracting_officer", 
    data
  )
};

export const officerGet = (data) => {
  return axios.get(
    apiUrl + "/contracting_officer", 
    data
  )
};

export const opportunitiesGet = (params) => {
  return axios.get(
    apiUrl + "/opportunities", 
    { params: params }
  )
};

export const purchasePost = (params) => {
  return axios.post(
    apiUrl + "/purchase", 
    params
  )
};

export const contractOpportunityPost = (params) => {
  return axios.post(
    apiUrl + "/contract_opportunity", 
    params
  )
};

export const purchaseGet = (params) => {
  return axios.get(
    apiUrl + "/purchase", 
    { params: params }
  )
};

export const contractOpportunityGet = (params) => {
  return axios.get(
    apiUrl + "/contract_opportunity", 
    { params: params }
  )
};

export const myPurchaseGet = (params) => {
  return axios.get(
    apiUrl + "/purchase/my", 
    {
      params: params, paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
  )
};

export const myContractOppGet = (params) => {
  return axios.get(
    apiUrl + "/contract_opportunity/my", 
    {
      params: params, paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
  )
};

export const purchaseDelete = (params) => {
  return axios.delete(
    apiUrl + "/purchase", 
    { params: params }
  )
}

export const contractOppDelete = (params) => {
  return axios.delete(
    apiUrl + "/contract_opportunity", 
    { params: params }
  )
}

export const purchaseSearchPost = (params, data, headers) => {
  return axios.post(
    apiUrl + "/purchase/search", 
    data,
    {
      params: params, paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
      headers
    }
  )
}

export const matchSettingsGet = () => {
  return axios.get(
    apiUrl + "/match_builder/settings/opportunities",
  )
};

export const matchSettingsPut = (data, params) => {
  return axios.put(
    apiUrl + "/match_builder/settings/opportunities",
    data,
    { params: params }
  )
};

export const oppMatchesGet = (params) => {
  return axios.get(
    apiUrl + "/match_builder/matches",
    {
      params: params, paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
  )
};

export const oppMatchesDelete = (params, entity) => {
  return axios.delete(
    apiUrl + `/match_builder/matches/${entity}`, 
    {
      params: params, paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
  )
}

export const officerRegistrationPost = data => {
  return axios.post(
    apiUrl + "/register/contracting_officer",
    data
  )
};

export const usersSearchPost = (params, data) => {
  return axios.post(
    apiUrl + "/user/search",
    data,
    {
      params: params, paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
  )
}

export const deleteUserDelete = params => {
  return axios.delete(
    apiUrl + "/admin/user",
    {
      params: params, paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
  )
}

export const loginAsUserGet = (params) => {
  return axios.get(
    apiUrl + "/admin/login_as",
    {
      params: params, paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
  )
};

export const adminUserPut = (data, params) => {
  return axios.put(
    apiUrl + "/admin/user",
    data,
    { params: params }
  )
};

export const quickStatsGet = () => {
  return axios.get(
    apiUrl + "/admin/quick_stats"
  )
};

export const myMessagesGet = (params) => {
  return axios.get(
    apiUrl + "/message/my",
    {
      params: params, paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
  )
}

export const messageDelete = (params) => {
  return axios.delete(
    apiUrl + "/message",
    {
      params: params, paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
  )
}

export const messageGet = params => {
  return axios.get(
    apiUrl + '/message',
    {
      params: params, paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
  )
}

export const messagePost = (data) => {
  return axios.post(
    apiUrl + '/message',
    data
  )
};

/* User feedback reports */

export const feedbackReportsSearchPost = (params, data) => {
  return axios.post(
    apiUrl + '/feedback/search',
    data,
    {
      params: params, paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
  )
}

export const feedbackReportsGet = params => {
  return axios.get(
    apiUrl + '/feedback',
    {
      params: params, 
    }
  )
}

export const feedbackReportsDelete = params => {
  return axios.delete(
    apiUrl + "/feedback", 
    {
      params: params, 
    }
  )
}

export const feedbackReportsPost = (params, data) => {
  return axios.post(
    apiUrl + '/feedback',
    data,
    {
      params: params, paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
  )
};

export const messageMassPost = (params, data) => {
  return axios.post(
    apiUrl + `/message/mass/${params}`,
    data,
    {
      params: params, paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
  )
};

/* Alert banners */
 
export const bannerDashboardPut = (data) => {
  return axios.put(
    apiUrl + "/banner/dashboard", 
    data
  )
};

export const bannerWhatsNewPut = (data) => {
  return axios.put(
    apiUrl + "/banner/whats_new", 
    data
  )
};

export const bannerDashboardGet = (data) => {
  return axios.get(
    apiUrl + "/banner/dashboard", 
    data
  )
};

export const bannerWhatsNewGet = (data) => {
  return axios.get(
    apiUrl + "/banner/whats_new", 
    data
  )
};


/* Contracting officer */

export const contractingOfficerSearchPost = (params, data, headers) => {
  return axios.post(
    apiUrl + '/contracting_officer/search',
    data,
    {
      params: params, paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
      headers
    }
  )
}

export const contractingOfficerGet = params => {
  return axios.get(
    apiUrl + '/contracting_officer',
    {
      params: params, 
    }
  )
}

export const matchingReportsGet = params => {
  return axios.get(
    apiUrl + '/admin/matches/report',
    {
      params: params, paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
  )
}

export const businessReportsGet = params => {
  return axios.get(
    apiUrl + '/admin/business/report',
    {
      params: params, paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
  )
}

export const opportunityReportsGet = params => {
  return axios.get(
    apiUrl + '/admin/opportunities/report',
    {
      params: params, paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
  )
}

export const adminDashboardGet = params => {
  return axios.get(
    apiUrl + '/admin/dashboard',
    {
      params: params, paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
  )
}

export const manualOpportunityGet = (params) => {
  return axios.get(
    apiUrl + "/manual_opportunity", 
    { params: params }
  )
};

export const grantGet = (params) => {
  return axios.get(
    apiUrl + "/grants", 
    { params: params }
  )
};

export const manualOpportunityPost = (params) => {
  return axios.post(
    apiUrl + "/manual_opportunity", 
    params
  )
};

export const myManualGet = (params) => {
  return axios.get(
    apiUrl + "/manual_opportunity/my", 
    {
      params: params, paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
  )
};

export const manualOpportunityDelete = (params) => {
  return axios.delete(
    apiUrl + "/manual_opportunity", 
    {
      params: params, 
    }
  )
};

export const adminGlobalSettingsPost = (params) => {
  return axios.post(
    apiUrl + "/admin/global_settings", 
    params
  )
};

export const grantsSearchPost = params => {
  return axios.post(
    apiUrl + '/grants/search',
    {},
    {
      params: params, paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
  )
}

export const instanceAwardsPost = (params) => {
  return axios.post(
    apiUrl + "/awards", 
    params
  )
};

export const totalAwardsPost = (params) => {
  return axios.post(
    apiUrl + "/awards/total", 
    params
  )
};

export const awardsDetailsPost = params => {
  return axios.post(
    apiUrl + "/awards/details", 
    params
  )
}
