import React, { useState } from 'react';
import classes from '../Dashboard/index.module.scss'
import AwardsClasses from './index.module.scss';
import Modal from '../UI/Modal';
import AwardsDetailsContent from '../ModalContents/AwardsDetailsContent';
import { useForm } from 'react-hook-form';

const TableBody = ({ list, selectedColums, handleDefaultStatusCodes, setLoading }) => {
  const [show, setShow] = useState(false);
  const { handleSubmit } = useForm();
  const [solicitaion, setSolicitation] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = ({ sol_no }) => {
    setShow(true);
    setSolicitation(sol_no);
  }
  const onSubmit = () => handleClose();
  return (
    <>
      {
        Boolean(list) && list.map((item, i) => { 
          const { 
            title = "", 
            city_name = "", 
            contract_no = "",
            sol_no = "", 
            department = "", 
            amount = "", 
            duns = "", 
            uei = "", 
            tax_id = "", 
            name = "", 
            email = "", 
            phone = ""
          } = item || {};

          return (
            <tr 
              key={tax_id + amount + i}
              onClick={ () => handleShow({ sol_no }) }
              className={AwardsClasses.Awards_TR_Focus}
            >
              { 
                selectedColums.map(el => {
                  switch(el.name) {
                    case 'Contract Title':
                      return el.status ? <td key={el["name"]} className={`${classes.Vertical_Middle} text-center`}><h5 className={AwardsClasses.Awards_Title}>{ title || <span className={AwardsClasses.No_Data}>No data</span> }</h5></td> : null
                    case 'City':
                      return el.status ? <td key={el["name"]} className={`${classes.Vertical_Middle} text-center`}><h5 className={AwardsClasses.Awards_Entity}>{ city_name || <span className={AwardsClasses.No_Data}>No data</span> }</h5></td> : null
                    case 'Contract':
                      return el.status ? <td key={el["name"]} className={`${classes.Vertical_Middle} text-center`}><h5 className={AwardsClasses.Awards_Entity}>{ contract_no || <span className={AwardsClasses.No_Data}>No data</span> }</h5></td> : null
                    case 'Solicitation':
                      return el.status ? <td key={el["name"]} className={`${classes.Vertical_Middle} text-center`}><h5 className={AwardsClasses.Awards_Entity}>{ sol_no || <span className={AwardsClasses.No_Data}>No data</span> }</h5></td> : null
                    case 'Source':
                      return el.status ? <td key={el["name"]} className={`${classes.Vertical_Middle} text-center`}><h5 className={AwardsClasses.Awards_Entity}>{ department || <span className={AwardsClasses.No_Data}>No data</span> }</h5></td> : null
                    case 'Amount':
                      return el.status ? <td key={el["name"]} className={`${classes.Vertical_Middle} text-center`}><h5 className={AwardsClasses.Awards_Entity}>{ amount || <span className={AwardsClasses.No_Data}>No data</span> }</h5></td> : null
                    case 'Duns':
                      return el.status ? <td key={el["name"]} className={`${classes.Vertical_Middle} text-center`}><h5 className={AwardsClasses.Awards_Entity}>{ duns || <span className={AwardsClasses.No_Data}>No data</span> }</h5></td> : null
                    case 'UEI':
                      return el.status ? <td key={el["name"]} className={`${classes.Vertical_Middle} text-center`}><h5 className={AwardsClasses.Awards_Entity}>{ uei || <span className={AwardsClasses.No_Data}>No data</span> }</h5></td> : null
                    case 'Tax ID':
                      return el.status ? <td key={el["name"]} className={`${classes.Vertical_Middle} text-center`}><h5 className={AwardsClasses.Awards_Entity}>{ tax_id || <span className={AwardsClasses.No_Data}>No data</span> }</h5></td> : null
                    case 'Contact info':
                      return el.status ? 
                      <td key={el["name"]} className={`${classes.Vertical_Middle} text-center`}>
                        <h6 className={AwardsClasses.Contact_Title}>Name:</h6>
                        <h5 className={AwardsClasses.Contact_Entity}>{ name || <span className={AwardsClasses.No_Data}>No data</span> }</h5>
                        <h6 className={AwardsClasses.Contact_Title}>Email:</h6>
                        <h5 className={AwardsClasses.Contact_Entity}>{ email || <span className={AwardsClasses.No_Data}>No data</span> }</h5>
                        <h6 className={AwardsClasses.Contact_Title}>Phone:</h6>
                        <h5 className={AwardsClasses.Contact_Entity}>{ phone || <span className={AwardsClasses.No_Data}>No data</span> }</h5>
                    </td> : null
                  }
                })
              }
            </tr>
          )
        })
      }
      <Modal 
        handleClose={handleClose} 
        show={show}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        buttonLabel="Close"
        singleActionBtn={ true }
      >
        <AwardsDetailsContent 
          solicitaion={ solicitaion } 
          handleDefaultStatusCodes={ handleDefaultStatusCodes }
          setLoading={ setLoading }
        />
      </Modal>
    </>
  )
}

export default TableBody;