import React, { useState, useEffect } from "react";
import Select from '../UI/Select'
import classes from './index.module.scss'
import { totalAwardsPost } from '../../api';

const TotalAwards = ({ category, setLoading, handleDefaultStatusCodes }) => {
  const [period, setPeriod] = useState('month_3');
  const [data, setData] = useState({});

  useEffect(() => {
    setLoading(true)
    if (category === 'instance') {
      totalAwardsPost({ filter: category, period })
      .then(response => {
        setData(response.data);
        setLoading(false);
      })
      .catch(error => {
        if (error.response.status === 404) {
          setData({});
          setLoading(false);
        } else {
          handleDefaultStatusCodes(error.response.status);
        }
      })
    } else {
      setData({});
      setLoading(false);
    }
  
  }, [period, category])

  const handleMultiChange = (period) => {
    setPeriod(period.value)
  }

  return (
    <div className={classes.Total_Awards_Wrapper}>
      <h5 className='mb-0'>Total</h5>
      <div className={classes.Wrapper}>
        <div className={classes.Item1}> 
          <h3 className={classes.Value}>{data.total_awards ? data.total_awards : 0 }</h3>
          <h2 className={classes.Title}>Awards</h2>
          <span className={classes.Tooltip}>The number of G2B Contracts & Grants awarded to Registered Users.</span>
        </div>
        <div className={classes.Item2}> 
          <h3 className={classes.Value}>{data.total_amount ? `$${data.total_amount}` : 0}</h3>
          <h2 className={classes.Title}>Amount</h2>
          <span className={classes.Tooltip}>The amount of G2B Contracts & Grants awarded to Registered Users.</span>
        </div>
      </div>
      <form>
        <Select
          handleMultiChange={handleMultiChange}
          name="total_by_date"
          list={
            [
              { value: "day", label: "This Day" },
              { value: "month", label: "This Month" },
              { value: "month_3", label: "3 Months" },
              { value: "month_6", label: "6 Months" },
              { value: "year", label: "Year" }
            ]
          }
          styles="react-select-input-container awards-details"
          prefixStyles="react-select-input"
          indicator={false}
          isSearchable={false}
          isClear={false}
          defaultValue={{ value: "month_3", label: "3 Months" }}
        />
      </form>
    </div>
  )
}

export default TotalAwards