import React from 'react';
import SelectInput from 'react-select';

const changeColor = state => {
  switch(true) {
    case state.isFocused:
      return '#B69D73 !important'
    case state.isDisabled:
      return 'gray !important'
  }
}

const customStyle = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: changeColor(state),
    color: state.isFocused && 'white !important',
  })
}

const Select = ({ 
  onChangeHandler, 
  handleMultiChange, 
  list,  
  placeholder,  
  styles, 
  prefixStyles,
  name, 
  defaultValue, 
  isClear = true, 
  disabled = false, 
  isSearchable = true,
  indicator = true,
  info_type,
  components
}) => {
  return (
    <SelectInput
      onInputChange={onChangeHandler && ((event) => onChangeHandler(event, name, info_type))}
      onChange={(value) => handleMultiChange(value, name, info_type)}
      name={name}
      id={name}
      isSearchable={isSearchable}
      options={list}
      className={styles}
      placeholder={placeholder}
      classNamePrefix={prefixStyles}
      isClearable={isClear}
      components={components}
      defaultValue={defaultValue}
      isDisabled={disabled}
      indicator={indicator}
      styles={customStyle}
    />
  )
}

export default Select;