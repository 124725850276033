import React, { useState, useEffect } from 'react';
import { GoogleMap, Circle } from '@react-google-maps/api';
import { csv } from "d3-fetch";
import haversine from "haversine-distance";

import MultiSelect from '../UI/MultiSelect';

const GeoFencingMap = ({
  geoFencingLocations = [],
  apiKey,
  handleFilterUpdateLocation,
  handleFilterZip
}) => {
  // Constants
  const mapStyles = { height: '50vh', width: '100%' };
  const defaultCenter =
    geoFencingLocations && geoFencingLocations.length
      ? {
          lat: parseFloat(geoFencingLocations[0].coordinates.lat),
          lng: parseFloat(geoFencingLocations[0].coordinates.lng),
        }
      : { lat: 39.8283, lng: -98.5795 }; // Default coordinates if none provided

  // State Variables
  const [map, setMap] = useState(null);
  const [input, setInput] = useState('');
  const [options, setOptions] = useState([]);
  const [shouldFitBounds, setShouldFitBounds] = useState(true);
  const [radiusValues, setRadiusValues] = useState(() =>
    geoFencingLocations
      ? geoFencingLocations.reduce(
          (acc, { placeId, radius }) => ({ ...acc, [placeId]: radius }),
          {}
        )
      : {}
  );
  const [locations, setLocations] = useState(() =>
    geoFencingLocations
      ? geoFencingLocations.map(({ coordinates, ...location }) => ({
          ...location,
          coordinates: {
            lat: Number(coordinates.lat),
            lng: Number(coordinates.lng),
          },
        }))
      : []
  );

  // Functions
  const convertRadius = (radius) => radius * 1609.34; // Convert miles to meters
  const fitBounds = (map, locations) => {
    const bounds = new window.google.maps.LatLngBounds();

    locations.forEach((location) => {
      if (location.coordinates) {
        bounds.extend(location.coordinates);
      }
    });

    map.fitBounds(bounds);
    const listener = window.google.maps.event.addListener(
      map,
      'bounds_changed',
      () => {
        if (map.getZoom() > 8) {
          map.setZoom(8); // Set the desired maximum zoom level
        }
        window.google.maps.event.removeListener(listener);
      }
    );
  };

  const handleMultiChange = (selectedOptions) => {
    const service = new google.maps.places.PlacesService(
      document.createElement('div')
    );

    const newLocations = selectedOptions.map((option) => {
      return new Promise((resolve, reject) => {
        service.getDetails({ placeId: option.value }, (place, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            const existingLocation = locations.find(
              (location) => location.placeId === option.value
            );

            const radius = existingLocation ? existingLocation.radius : 1;
            const newLocation = {
              placeId: option.value,
              label: option.label,
              coordinates: {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              },
              radius: radius,
              value: option.value,
            };
            resolve(newLocation);
          } else {
            reject(status);
          }
        });
      });
    });

    Promise.all(newLocations).then((resolvedLocations) => {
      updateLocationsAndFitBounds(resolvedLocations);
      if (resolvedLocations.length === 0) {
        handleFilterZip([]);
      } else {
        fetchZipCodes(resolvedLocations);
      }    });
  };

  const fetchZipCodes = async (locations) => {
    if (!locations.length) return []; // Skip if there are no locations
    const data = await csv("/zips.csv");
    const filteredZipCodes = new Set();

    locations.forEach((location) => {
      const { coordinates, radius } = location;
      if (!coordinates) return; // Skip the iteration if coordinates are null

      const radiusInMeters =
        radius * 1609.34 

      data.forEach((zip) => {
        const zipCoordinates = { lat: +zip.lat, lng: +zip.lng };
        const distance = haversine(coordinates, zipCoordinates);

        if (distance <= radiusInMeters) {
          filteredZipCodes.add(zip);
        }
      });
    });

    handleFilterZip([...filteredZipCodes]);

  };

  const handleInputChange = (value) => {
    setInput(value);
  };

  const handleRadiusChange = (event, placeId) => {
    const newRadius = event.target.value;
    setLocations((prevLocations) => {
      const updatedLocations = prevLocations.map((location) => {
        if (location.placeId === placeId) {
          return { ...location, radius: newRadius };
        }
        return location;
      });
      fetchZipCodes(updatedLocations);
      return updatedLocations;
    });
    setRadiusValues((prevValues) => {
      return { ...prevValues, [placeId]: newRadius };
    });
  };

  const updateLocationsAndFitBounds = (newLocations) => {
    setLocations(newLocations);
    setShouldFitBounds(true);
  };

  // Effects
  useEffect(() => {
    if (window.google && input) {
      const autoComplete = new window.google.maps.places.AutocompleteService();

      autoComplete.getPlacePredictions(
        {
          input: input,
          types: ['(cities)'],
        },
        (predictions) => {
          if (predictions) {
            const newOptions = predictions.map((prediction) => ({
              value: prediction.place_id,
              label: prediction.description,
              placeId: prediction.place_id,
            }));
            setOptions(newOptions);
          }
        }
      );
    }
  }, [input]);


  useEffect(() => {
    handleFilterUpdateLocation(locations);
    // fetchZipCodes(locations);   
  }, [locations]); // This useEffect runs whenever `locations` changes


  useEffect(() => {
    if (shouldFitBounds && locations.length > 0 && map) {
      fitBounds(map, locations);
      setShouldFitBounds(false); // Reset after adjusting
    }
  }, [shouldFitBounds, locations, map]);

  return (
    <div>
      <MultiSelect
        list={options}
        handleMultiChange={handleMultiChange}
        onChangeHandler={handleInputChange}
        selectValue={locations.map((location) => ({
          value: location.placeId,
          label: location.label,
          placeId: location.placeId,
        }))}
        styles="react-select-container"
        prefixStyles="react-select"
        indicator={false}
        placeholder="Search for a location"
      />
      {locations.map((location) => (
        <div key={location.placeId} className="radius-slider">
          <label style={{ marginRight: '10px' }}>{location.label}</label>
          <input
            type="range"
            min="1"
            max="50"
            value={location.radius}
            onChange={(event) => handleRadiusChange(event, location.placeId)}
            className="slider"
          />
          <label>{radiusValues[location.placeId] || 1} mi</label>
        </div>
      ))}

      <div style={{ height: '50vh', width: '100%' }}>
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={8}
          center={defaultCenter}
          onLoad={setMap}
          onUnmount={() => setMap(null)}
        >
          {locations.map((location, index) => (
            <Circle
              key={location.placeId}
              center={location.coordinates}
              radius={convertRadius(location.radius)}
              options={{
                strokeColor: '#FF0000',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#FF0000',
                fillOpacity: 0.35,
                clickable: false,
                draggable: false,
                editable: false,
                visible: true,
                zIndex: 5,
              }}
            />
          ))}
        </GoogleMap>
      </div>
    </div>
  );
};

export default GeoFencingMap;
