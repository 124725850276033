import React from 'react';
import TextInput from 'components/UI/TextInput';

const ChangeBudgetLimitContent = ({ value, register, onChangeSingleValue }) => {
  return (
    <>
      <h5 className="mb-4">Change Matching Budget Limit</h5>
      <TextInput 
        name="matches_limit" 
        type="number" 
        register={register()}
        value={value}
        onChange={onChangeSingleValue}
        placeholder="Budget Limit"
      />
    </>
  )
}

export default ChangeBudgetLimitContent;