import React, { useEffect, useState, useContext } from "react";
import { adminGlobalSettingsPost, adminDashboardGet } from '../../api';
import Loading from '../Loading';
import Modal from '../UI/Modal';
import { NAICS_LIST, PSC_LIST } from 'constans'
import { UserContext } from "../../context/User";
import { useHistory, useLocation } from 'react-router';
import { somethingWentWrongNotice } from '../UI/Notifications';
import { Container, Row, Col } from "react-bootstrap";
import ExludedEntity from "components/Dashboard/Admin/ExcludedEntity";
import { useForm } from 'react-hook-form';
import DeleteEntityModalContent from '../ModalContents/DeleteEntityModalContent';
import AddEntityModalContent from '../ModalContents/AddEntityModalContent'

const GET_LIST = {
  'NAICS': NAICS_LIST,
  'PSCs': PSC_LIST
}

const DEFAULT_INPUT_VALUES = {
  naics: "",
  psc_code: "",
  zip: ""
}

const AdvancedExludedEntity = () => {
  const [loading, setLoading] = useState(false);
  const { handleSubmit } = useForm();
  const [data, setData] = useState({});
  const [requestTrigger, setRequestTrigger] = useState(false);
  const [entityList, setEntityList] = useState([]);
  const [action, setAction] = useState('')
  const [show, setShow] = useState(false);
  const [inputValue, setInputValue] = useState(DEFAULT_INPUT_VALUES)
  const [inputName, setInputName] = useState('')
  const [selectValue, setSelectValue] = useState([]);
  const [entity, setEntity] = useState('')
  const { setUser, setIsLoggedIn } = useContext(UserContext);
  const history = useHistory();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const name = query.get("entity");

  const handleClose = () => {
    setSelectValue([]);
    setInputName('');
    setAction('')
    setEntity('')
    setShow(false);
  }

  const handleShow = () => {
    setShow(true);
  }  

  const logout = () => {
    setIsLoggedIn(false);
    setUser({});
    history.push('/');
  }

  const handleDefaultStatusCodes = (status_code) => {
    if (status_code === 440 || status_code === 401) {
      logout()
    } else {
      somethingWentWrongNotice("Dashboard");
    }
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    adminDashboardGet()
      .then(response => {
        setData(response.data);
        setLoading(false);
      })
      .catch(error => {
        handleDefaultStatusCodes(error.response.status);
      })
  }, [requestTrigger])

  const getEntityData = ({ name }) => {
    switch(name) {
      case 'NAICS':
        return data.selected_naics
      case 'PSCs':
        return data.selected_pscs
    }
  }

  const transformData = () => {
    if (entity === "NAICS") return 'selected_naics'
    if (entity === "PSCs") return 'selected_pscs'
    if (entity === "ZIP") return 'selected_zips'
  }

  const onSubmit = () => {
    const entityName = transformData()
    const transformedData = selectValue.map(item => item.value)
    const addData = [...new Set([...data[entityName], ...transformedData])];
    const deleteData = entityList
  
    const transformedParams = {
      [entityName]: action === 'add' ? addData : deleteData
    }

    adminGlobalSettingsPost(transformedParams)
      .then(response => {
        setLoading(false);
        setRequestTrigger(!requestTrigger)
        handleClose()
      })
      .catch(error => {
        handleDefaultStatusCodes(error.response.status);
        handleClose()
        setLoading(false);
      })
  }

  const setModalButtonLabel = action => {
    if (action === "add") return 'Add'
    if (action === "delete") return 'Delete'
  }

  const setTooltipMessage = ({ name }) => {
    switch(name) {
      case 'NAICS':
        return "North American Industry Classification System codes you want to exclude."
      case 'PSCs':
        return "Product Service Codes you want to exclude."
    }
  }

  return (
    <section>
      <Container>
        <Row>
          <Col>
            <div className="Admin_Dashboard_Entity_Wrapper mt-5">
              <div className="Admin_Dashboard_Entity_Box">
                <ExludedEntity 
                  title={ name }
                  setAction={ setAction }
                  setEntity={ setEntity }
                  setInputName={ setInputName }
                  handleShow={ handleShow }
                  tooltip={ setTooltipMessage({ name }) }
                  data={ data }
                  entityData={ getEntityData({ name }) }
                  list={ GET_LIST[name] }
                  setEntityList={ setEntityList }
                  limit={ getEntityData({ name }) && getEntityData({ name }).length }
                  type="advanced"
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal 
        handleClose={handleClose} 
        show={show}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        buttonLabel={setModalButtonLabel(action)}
      >
        { action === 'add' && (
           <AddEntityModalContent 
            entity={ entity } 
            inputValue={ inputValue } 
            setInputValue={ setInputValue }
            selectValue={ selectValue }
            setSelectValue={ setSelectValue }
            inputName={ inputName }
          />
        )} 
        { action === 'delete' && (
          <DeleteEntityModalContent entity={ entity } />
        )}
      </Modal>
      {loading && <Loading />}
    </section>
  )
}

export default AdvancedExludedEntity;