import React, { useState, useContext } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import SignUpForm from "./SignUpForm";
import { register, getVerify } from '../../api';
import { UserContext } from '../../context/User';
import { Container, Row, Col } from 'react-bootstrap';
import classes from './index.module.scss';
import { somethingWentWrongNotice, successNotice, emailPhoneExistingsNotice } from '../UI/Notifications';
import Logo from "components/Logo";
import { ReactComponent as LogoIcon } from '../../assets/icons/black-logo.svg';

const UserRegistration = () => {
  const [loading, setLoading] = useState(false);
  const { setUser, setIsLoggedIn } = useContext(UserContext);
  const history = useHistory();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const querystring = require('querystring');
  const role = query.get('role');
  const gov_relations = query.get('gov_relations');
 
  const login = (userData) => {
    setIsLoggedIn(true);
    setUser(userData);
    history.push("/dashboard");
  }

  const verification = (responseData) => {
    const params = {
      user_id: responseData.user_id,
      hash_data: responseData.hash_data,
      link: `${window.origin}/user-verification`,
      gov_relations
    }

    getVerify(params)
      .then(() => {
        const queryString = querystring.stringify(params);
        setLoading(false);
        history.push(`/send-again?${queryString}`);
      })
      .catch(() => {
        somethingWentWrongNotice("Registration");
        setLoading(false);
      });
  }

  const registrationPost = (newObj) => {
    register(newObj)
      .then(response => {
        setLoading(false);
        login(response.data);
        successNotice("Registration");
      })
      .catch(error => {
        if (error.response.status === 409) {
          emailPhoneExistingsNotice();
          setLoading(false);
        }
        if (error.response.status === 401) {
          verification(error.response.data);
        }
        if (![401, 409].includes(error.response.status)) {
          somethingWentWrongNotice("Registration");
          setLoading(false);
        }
      });
  }

  const onSubmit = (data, e) => {
    e.preventDefault();
    e.target.reset();
    setLoading(true);

    const newObj = {
      ...data,
      userRole: role
    };

    delete newObj.passwordConfirmation

    registrationPost(newObj);
  }

  

  return (
    <section>
      <Container fluid style={{
        marginTop: "80px",
        marginBottom: "80px"
      }}>
        <Row>
          <Col lg={{span: 6}} md={{span: 5}}>
            <div className={classes.HeaderWrapper}>
              <Logo
                path="/"
              >
                <LogoIcon />
              </Logo>
              <h2 className={classes.HeaderDesc}>Matching You With Contract and Grant Opportunities and Awards</h2>
              <h5 className="mb-3">
                Please read before you begin registration
              </h5>
              <p>
                By registering on this website, your organization will receive contract and grant opportunities, and the ability 
                to search for business partners, employees and investor opportunities.
              </p>
            </div>
          </Col>
          <Col lg={{span: 5, offset: 1}} md={{span: 7, offset: 0}}>
            <SignUpForm
              onSubmit={onSubmit}
              loading={loading}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default UserRegistration;