import React from 'react';
import Select from 'components/UI/Select';

const AwardsCategorySelect = ({ setCategory }) => {
  const handleMultiChange = category => {
    setCategory(category.value)
  }

  return (
    <>
        <label>Category</label>
        <form>
          <Select
            handleMultiChange={handleMultiChange}
            name="total_by_date"
            list={
              [
                { value: "instance", label: "Instance Contract Awards" },
                { value: "grant", label: "Instance Grant Awards", isDisabled: true },
                { value: "loan", label: "Instance Loan Awards", isDisabled: true }
              ]
            }
            styles="react-select-input-container awards-details"
            prefixStyles="react-select-input"
            indicator={false}
            isSearchable={false}
            isClear={false}
            defaultValue={{ value: "MatchForce Awards", label: "Instance Contract Awards" }}

          />
        </form>
    </>
  )
}

export default AwardsCategorySelect;