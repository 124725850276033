import React from 'react';
import PasswordInput from '../UI/PasswordInput';

const ResetPasswordModalContent = ({ register, errors }) => {
  return (
    <>
      <h5 className="mb-4">New Password</h5>
      <PasswordInput 
        name="password" 
        register={register({ 
          required: "Password is required",
          pattern: {
            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9])[A-Za-z\d@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*.{8,}$/, 
            message: "Password must contain at least 8 characters, 1 uppercase, 1 lowercase, 1 number, and 1 special character"
          }
        })}
        placeholder="Password"
      />
      { errors.password && <p className="warnMessage">{errors.password.message}</p> }
    </>
  )
}

export default ResetPasswordModalContent;