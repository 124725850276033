import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { UserContext } from '../../context/User';
import { useLocation, useHistory } from "react-router-dom";
import { grantGet } from '../../api';
import Loading from '../Loading';
import { somethingWentWrongNotice } from '../UI/Notifications';
import SinglePageItemText from '../SinglePageItemTypes/SinglePageItemText';
import SinglePageItemArray from '../SinglePageItemTypes/SinglePageItemArray';
import SinglePageItemBool from '../SinglePageItemTypes/SinglePageItemBool';

const GrantSingle = () => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const id = query.get("id");
  const [businessData, setBusinessData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setUser, setIsLoggedIn } = useContext(UserContext);
  const history = useHistory();

  const logout = () => {
    setIsLoggedIn(false);
    setUser({});
    history.push('/');
  }

  const handleDefaultStatusCodes = (status_code) => {
    if (status_code === 440 || status_code === 401) {
      logout()
    } else {
      somethingWentWrongNotice("Search");
    }

    setLoading(false);
  }

  useEffect(() => {
    if (id) {
      setLoading(true);
      grantGet({grant_id: id})
        .then(response => {
          setBusinessData(response.data);
          setLoading(false);
        })
        .catch(error => {
          handleDefaultStatusCodes(error.response.status);
          setLoading(false);
        })
    }
  }, [])

  const convertDate = (str) => {
    const date = str.slice(0, 10);
    return date
  }

  return (
    <section className="section-spaces">
       <Container>
        <Row>
          <Col>
            <div className="single-page-wrap">
              <h3>{businessData.name}</h3>
              <SinglePageItemText 
                title="Funding Opportunity Title"
                value={businessData.title}
              />
              <SinglePageItemText 
                title="Agency"
                value={businessData.agency}
              />
              <SinglePageItemText 
                title="Status"
                value={businessData.opp_status}
              />
              <SinglePageItemText 
                title="Agency Code"
                value={businessData.agency_code}
              />
              <SinglePageItemText 
                title="Funding Opportunity Number"
                value={businessData.number}
              />
              <SinglePageItemArray 
                title="CFDA"
                type="pm"
                value={businessData.cfda_list}
              />
              <SinglePageItemText 
                title="Deadline"
                value={businessData.close_date}
              />
              <SinglePageItemText 
                title="Document Type"
                value={businessData.doc_type}
              />
              <SinglePageItemText 
                title="Open Date"
                value={businessData.open_date}
              />
              <SinglePageItemArray 
                title="NAICS Code"
                type="pm"
                value={businessData.naics}
              />
              <div className="single-page-item align-items-center">
                <h4>
                 Aplicable Link
                </h4>
                <a className="btnSizes filledBtn" target="_blank" rel="noreferrer" href={`https://www.grants.gov/web/grants/view-opportunity.html?oppId=${businessData.opp_id}`}>View Grant</a>
              </div>
            </div>
          </Col>
        </Row>
        {loading && <Loading />}
      </Container>    
    </section>
  )
}

export default GrantSingle;