import React from 'react';
import { useForm } from 'react-hook-form';
import classes from './index.module.scss'
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow-right.svg';
import TextInput from '../UI/TextInput';
import Autocomplete from "react-google-autocomplete";

const FrameTwo = ({ onSubmit, inputValue, prev, setStep, onChangeSingleValue, transformAddress }) => {
  const { register, handleSubmit, errors } = useForm();

  return (
    <div className={classes.Step_Wrapper}>
      <div className={classes.Step_Header}>
        <h3 className='mb-0'>Complete your profile</h3>
        <div className={classes.Arrow_Wrapper}  onClick={handleSubmit(onSubmit)}>
          <ArrowIcon className={classes.Arrow_Icon} />
        </div>
      </div>
      <div className={classes.Step_Content}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset>
            <label htmlFor="name">Display name <span>*</span></label>
            <TextInput 
              name="name" 
              type="text" 
              register={register({ required: true })}
              value={inputValue.name}
              onChange={onChangeSingleValue}
              placeholder="Add your name..."
            />
            {errors.name && errors.name.type === "required" &&
              <p className="warnMessage">This field is required</p>
            }
          </fieldset>
          <fieldset>
            <label htmlFor="phone">Phone number <span>*</span></label>
            <input 
              name="phone" 
              type="tel"
              ref={register({ required: true })}
              value={inputValue.phone && inputValue.phone}
              onChange={((event) => onChangeSingleValue(event, "phone"))}
              placeholder="Add your phone number..."
            />
            {errors.phone && errors.phone.type === "required" &&
              <p className="warnMessage">This field is required</p>
            }
          </fieldset>
          <fieldset>
            <label htmlFor="url">Website</label>
            <input 
              name="url" 
              type="text"
              ref={register()}
              value={inputValue.url && inputValue.url}
              onChange={((event) => onChangeSingleValue(event, "url"))}
              placeholder="Add your website here..."
            />
          </fieldset>
          <fieldset>
            <label htmlFor="address1">Address</label>
            <Autocomplete
              placeholder='Add your address here...'
              options={{
                types: ["geocode", "establishment"],
                componentRestrictions: { country: "usa" }
              }}
              language={ "en" }
              apiKey={process.env.REACT_APP_GOOGLE_MAPS_DROPDOWN_API_KEY}
              onPlaceSelected={(place, inputRef, autocomplete) => {
                transformAddress(place)
              }}
            />
          </fieldset>
          <fieldset>
            <label htmlFor="name">UEI (Unique Entity Identifier)</label>
            <TextInput 
              name="uei" 
              type="text" 
              register={register()}
              value={inputValue.uei}
              onChange={onChangeSingleValue}
              placeholder="Add your UEI..."
            />
          </fieldset>
          <fieldset>
            <label htmlFor="description">Bio</label>
            <textarea 
              name="description"
              ref={register()}
              value={inputValue.description && inputValue.description}
              onChange={((event) => onChangeSingleValue(event, "description"))}
              placeholder="Add your bio..."
            />
          </fieldset>
        </form>
      </div>
      <div className={classes.Step_Footer}>
        <span className={classes.Prev} onClick={prev}>Prev</span>
        <div className={classes.Dots_Container}>
          <div className={classes.Inactive_Dot}></div>
          <div className={classes.Active_Dot}></div>
          <div className={classes.Inactive_Dot}></div>
          <div className={classes.Inactive_Dot}></div>
        </div>
        <button 
          className={classes.Next}
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          Next
        </button>
      </div>
    </div>

  //   <form onSubmit={handleSubmit(onSubmit)}>
  //     <fieldset>
  //       <label htmlFor="naics">NAICS codes for your business</label>
  //       <div className="tooltip-container-select">
  //         <MultySelect 
  //           onChangeHandler={onChangeHandler}
  //           handleMultiChange={handleMultiChange}
  //           name="naics"
  //           list={list}
  //           inputValue={inputValue.naics}
  //           selectValue={selectValue.filter(item => item.type === 'naics')}
  //           styles="react-select-container"
  //           prefixStyles="react-select"
  //           indicator={false}
  //           check={true}
  //         />
  //         <OverlayTrigger
  //           placement="right"
  //           delay={{ show: 250, hide: 400 }}
  //           overlay={
  //             <Tooltip id="button-tooltip">
  //               Choose your NAICS (North American Industrial Classification System) code. If you are not familar with the right codes for the products or services you sell, review them at http://www.census.gov/eos/www/naics/
  //             </Tooltip>
  //           }
  //         >
  //           <TooltipIcon />
  //         </OverlayTrigger>
  //       </div>
  //     </fieldset>
  //     <fieldset>
  //       <label htmlFor="psc_code">PSC codes for your business</label>
  //       <div className="tooltip-container-select">
  //         <MultySelect 
  //           onChangeHandler={onChangeHandler}
  //           handleMultiChange={handleMultiChange}
  //           name="psc_code"
  //           list={list}
  //           inputValue={inputValue.psc_code}
  //           selectValue={selectValue.filter(item => item.type === 'psc_code')}
  //           styles="react-select-container"
  //           prefixStyles="react-select"
  //           indicator={false}
  //           check={true}
  //         />
  //         <OverlayTrigger
  //           placement="right"
  //           delay={{ show: 250, hide: 400 }}
  //           overlay={
  //             <Tooltip id="button-tooltip">
  //               Choose your PSC code.
  //             </Tooltip>
  //           }
  //         >
  //           <TooltipIcon />
  //         </OverlayTrigger>
  //       </div>
  //     </fieldset>
  //     <fieldset>
  //       <label htmlFor="set_aside">Socio-Economic Type</label>
  //       <div className="tooltip-container-select">
  //         <MultySelect 
  //           onChangeHandler={onChangeHandler}
  //           handleMultiChange={handleMultiChange}
  //           name="set_aside"
  //           list={SETASIDE_LIST}
  //           inputValue={inputValue.set_aside}
  //           selectValue={selectValue.filter(item => item.type === 'set_aside')}
  //           styles="react-select-container"
  //           prefixStyles="react-select"
  //           indicator={false}
  //           check={true}
  //         />
  //          <OverlayTrigger
  //             placement="right"
  //             delay={{ show: 250, hide: 400 }}
  //             overlay={
  //               <Tooltip id="button-tooltip">
  //                 Select all that apply.
  //               </Tooltip>
  //             }
  //           >
  //             <TooltipIcon />
  //           </OverlayTrigger>
  //       </div>
  //     </fieldset>
  //     <fieldset>
  //       <label htmlFor="type">Industry</label>
  //       <div className="tooltip-container-select">
  //         <MultySelect 
  //           onChangeHandler={onChangeHandler}
  //           handleMultiChange={handleMultiChange}
  //           name="type"
  //           list={BUSINESS_TYPE_LIST}
  //           inputValue={inputValue.type}
  //           selectValue={selectValue.filter(item => item.type === 'type')}
  //           styles="react-select-container"
  //           prefixStyles="react-select"
  //           indicator={false}
  //           check={true}
  //         />
  //          <OverlayTrigger
  //             placement="right"
  //             delay={{ show: 250, hide: 400 }}
  //             overlay={
  //               <Tooltip id="button-tooltip">
  //                 Select all that apply.
  //               </Tooltip>
  //             }
  //           >
  //             <TooltipIcon />
  //           </OverlayTrigger>
  //       </div>
  //     </fieldset>
  //     <fieldset>
  //       <label htmlFor="description">Business Description <span>*</span></label>
  //       <div className="tooltip-container">
  //         <TextArea 
  //           name="description"
  //           register={register({ 
  //             required: true, 
  //             maxLength: {
  //               value: 600,
  //               message: "Up to 600 characters"
  //             }
  //           })}
  //         />
  //         <OverlayTrigger
  //           placement="right"
  //           delay={{ show: 250, hide: 400 }}
  //           overlay={
  //             <Tooltip id="button-tooltip">
  //               There is a limit of 600 characters for this field.  Provide a short description of your business that contains the products or services you offer.
  //             </Tooltip>
  //           }
  //         >
  //           <TooltipIcon />
  //         </OverlayTrigger>
  //       </div>
  //       {errors.description && errors.description.type === "required" &&
  //         <p className="warnMessage">This field is required</p>
  //       }
  //       {errors.description && errors.description.type === "maxLength" && 
  //         <p className="warnMessage">Up to 600 characters</p>
  //       }
  //     </fieldset>
  //     <fieldset>
  //       <label htmlFor="business_keywords">Business Keywords</label>
  //       <div className="tooltip-container">
  //         <TextArea 
  //           name="keywords"
  //           value={inputValue.keywords}
  //           onChange={onChange}
  //           register={register({ 
  //             maxLength: {
  //               value: 600,
  //               message: "Up to 600 characters"
  //             }
  //           })}
  //         />
  //         <OverlayTrigger
  //           placement="right"
  //           delay={{ show: 250, hide: 400 }}
  //           overlay={
  //             <Tooltip id="button-tooltip">
  //               Here you will type your keywords that relate to your business. There is a limit of 600 characters for this field. Please separate keywords with commas or commas with space for better matching.
  //             </Tooltip>
  //           }
  //         >
  //           <TooltipIcon />
  //         </OverlayTrigger>
  //       </div>
  //       {errors.keywords && errors.keywords.type === "maxLength" && 
  //         <p className="warnMessage">Up to 600 characters</p>
  //       }
  //     </fieldset>
  //     <button 
  //       type="submit"
  //       className="filledBtn btnSizes"
  //     >
  //       Save & Continue
  //     </button>
  //   </form>
  )
}

export default FrameTwo;