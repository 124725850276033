import React from 'react'

const EntityBox = ({ value, title, tooltip }) => {
  return (
    <div className="Admin_Dashboard_Entity_Box">
      <div className={`Single_Dashboard_Entity_Box`}> 
        <h3 className="Value">{value || "0"}</h3>
        <h2 className="Title">{ title }</h2>
        <span className="Tooltip">
          { tooltip }
        </span>
      </div>
    </div>
  )
}

export default EntityBox;